import heroPicture from './heroPictureWithGradient.jpeg';
import MagGlass from '../../icons/magGlass.svg';
import DownIcon from '../../icons/downIcon.svg';
import { Header } from '../header/Header';
import { Footer } from '../footer/Footer';
import { useState } from 'react';
import { _data } from '../../data';

export function HomePage({ props }: any) {

    const [searchString, setSearchString] = useState("")
    const [products, setProducts] = useState(Object.values(_data));

    const SearchItem = ({ item }: any) => {
        const [hover, setHover] = useState(false);

        return (
            <div
                style={{
                    ...{
                        padding: '10px',
                        cursor: 'pointer',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',

                    },
                    ...(hover ? ({
                        backgroundColor: '#f2f2f2',
                        borderRadius: '10px',
                    }) :
                        {})
                }}
                onMouseEnter={() => {
                    setHover(true);
                }}
                onMouseLeave={() => {
                    setHover(false);
                }}
                onClick={() => {
                    window.location.href = `/product/${item.id}`
                }}
            >
                <div>
                    {`${item?.name}`}
                </div>
                <div style={{ marginLeft: '10px', color: 'gray' }}>{`Locations:  ${item?.rowLength} `}</div>
            </div>
        )
    }

    return (
        <div
            style={{
                // width: '100%',
                // height: '100%',
            }}
        >

            {/* Header */}
            <Header />

            {/* Hero */}
            <div style={{
                background: `url(${heroPicture})`,
                // background: `url(${heroPicture}) no-repeat left top, linear-gradient(#eb01a5, #d13531)`,
                // background: `linear-gradient(to bottom, rgba(255,255,255,0) 80%, transparent, url(${heroPicture})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                height: '868px',
                width: '100%',
                overflowX: 'hidden',
            }} >
                <div style={{
                    // paddingTop: '100px',
                    height: '500px',
                }}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        flex: 1,
                        marginTop: '238px',
                    }}>

                        <h1
                            style={{
                                fontSize: '70px',
                                fontWeight: 'bold',
                                color: '#2a9d8f',
                            }}
                        >
                            Explore our datasets
                        </h1>
                        <div
                            style={{
                                fontSize: '25px',
                                fontWeight: '400',
                                color: '#343434',
                            }}
                        >
                            Don't see what you're looking for? <a style={{ textDecoration: 'underline', color: '#343434', fontStyle: 'italic' }} href="/contact">Request a dataset</a>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginTop: '73px',
                                alignItems: 'flex-start',
                            }}
                        >
                            {/* <div
                                style={{
                                    width: '650px',
                                    height: '140px',
                                    borderRadius: '10px',
                                    border: 'none',
                                    backgroundColor: 'red',
                                    marginRight: '10px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            > */}
                            <div
                                style={{
                                    width: '650px',
                                    height: '70px',
                                    borderRadius: '10px',
                                    border: 'none',
                                    backgroundColor: 'white',
                                    marginRight: '10px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >

                                <img src={MagGlass}
                                    style={{
                                        width: '30px',
                                        height: '30px',
                                        marginLeft: '20px',
                                        marginRight: '20px',
                                    }}
                                />
                                <input
                                    type="text"
                                    name="data search"
                                    placeholder="Search data"
                                    style={{
                                        width: '100%',
                                        height: '90%',
                                        borderRadius: '10px',
                                        border: 'none',
                                        backgroundColor: 'white',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        outline: 'none',
                                        fontSize: '18px',
                                    }}
                                    onChange={(e) => setSearchString(e.target.value)}
                                />
                            </div>


                            {
                                searchString.length > 0 &&
                                <div
                                    style={{
                                        position: 'absolute',
                                        marginTop: '75px',
                                        backgroundColor: 'white',
                                        width: '650px',
                                        borderRadius: '10px',
                                    }}
                                >
                                    {
                                        (
                                            searchString.length === 0 ?
                                                products
                                                :
                                                products.filter(
                                                    (location: any) =>
                                                        location?.name
                                                            ?.toLowerCase()
                                                            .includes(
                                                                searchString.toLowerCase(),
                                                            ) ||
                                                        location?.name
                                                            ?.toLowerCase()
                                                            .replaceAll(/\s|-/g, '')
                                                            .includes(
                                                                searchString.toLowerCase(),
                                                            )
                                                )

                                        ).map((item: any, index: number) => {
                                            return <SearchItem item={item} />

                                        })
                                    }
                                </div>
                            }
                            {/* </div> */}
                            <div
                                style={{
                                    width: '140px',
                                    height: '70px',
                                    backgroundColor: '#2a9d8f',
                                    borderRadius: '10px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: 'white',
                                    fontSize: '20px',
                                    fontWeight: 'medium',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    console.log('clicked');
                                }}
                            >
                                Search
                            </div>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                height: '100%',
                            }}
                        >
                            <img src={DownIcon}
                                style={{
                                    width: '30px',
                                    height: '30px',
                                    marginLeft: '20px',
                                    marginRight: '20px',
                                    marginTop: '220px',
                                }}
                            />
                        </div>
                    </div>


                </div>
            </div>

            {/* Footer */}
            <Footer />

        </div >
    );
}
