import logo from './insomnia-data--logo-transparent-background.png';

export function Footer({ props }: any) {

    return (
        <div
            style={{
                height: '540px',
                backgroundColor: '#343434',
                paddingLeft: '292px',
                paddingRight: '292px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <img
                style={{
                    height: '80px'
                }}
                src={logo}
                alt="logo"
            />
            <div
                style={{
                    color: 'white',
                    fontSize: '15px',
                    marginTop: '80px',
                    textAlign: 'center'
                }}
            >
                Legal Disclaimer: InsomniaData is an equal opportunity data service provider. We only provide the technologies and data pipes to scrape publicly available data for our customers. The mention of any company names, trademarks or data sets on our site does not imply we can or will scrape them. They are listed only as an illustration of the types of requests we get. Any code provided in our tutorials is for learning only, we are not responsible for how it is used. Access to this website is subject to the Website Terms of Use.
            </div>
        </div>
    );
}
