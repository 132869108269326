export const _data: any = {
    "journeys": {
        "__id": 1,
        "id": "journeys",
        "name": "Journeys",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'name', 'opening_hours', 'phone', 'website']",
        "rowLength": 1045,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763439,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of journeys locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 696,
                "address_city": "\"North Little Rock\"",
                "address_country": "\"US\"",
                "address_full": "\"3929 McCain Blvd\"",
                "address_postcode": "\"72116\"",
                "address_state": "\"AR\"",
                "name": "\"McCain Mall\"",
                "opening_hours": "\"Mo-Th 11:00-20:00; Fr-Sa 10:00-21:00; Su 12:00-18:00\"",
                "phone": "\"501-812-5730\"",
                "website": "\"https://www.journeys.com/store/journeys-mccain-mall-\""
            },
            {
                "__id": 431,
                "address_city": "\"Sioux Falls\"",
                "address_country": "\"US\"",
                "address_full": "\"4001 W 41st St\"",
                "address_postcode": "\"57106\"",
                "address_state": "\"SD\"",
                "name": "\"Empire Mall\"",
                "opening_hours": "\"Mo-Th 10:00-20:00; Fr-Sa 10:00-21:00; Su 12:00-18:00\"",
                "phone": "\"605-362-2568\"",
                "website": "\"https://www.journeys.com/store/kidz-empire-mall\""
            },
            {
                "__id": 164,
                "address_city": "\"Monroe\"",
                "address_country": "\"US\"",
                "address_full": "\"4700 Mill Haven\"",
                "address_postcode": "\"71203-7027\"",
                "address_state": "\"LA\"",
                "name": "\"Pecanland Mall\"",
                "opening_hours": "\"Mo-Th 11:00-19:00; Fr-Sa 11:00-20:00; Su 12:00-18:00\"",
                "phone": "\"318-330-9709\"",
                "website": "\"https://www.journeys.com/store/underground-pecanland-mall\""
            },
            {
                "__id": 53,
                "address_city": "\"Atlanta\"",
                "address_country": "\"US\"",
                "address_full": "\"2860 Cumberland Mall SE\"",
                "address_postcode": "\"30339\"",
                "address_state": "\"GA\"",
                "name": "\"Cumberland Mall\"",
                "opening_hours": "\"Mo-Sa 11:00-20:00; Su 12:00-19:00\"",
                "phone": "\"678-309-1111\"",
                "website": "\"https://www.journeys.com/store/journeys-cumberland-mall\""
            },
            {
                "__id": 551,
                "address_city": "\"Arlington\"",
                "address_country": "\"US\"",
                "address_full": "\"3811 S Cooper St\"",
                "address_postcode": "\"76015-4148\"",
                "address_state": "\"TX\"",
                "name": "\"The Parks at Arlington\"",
                "opening_hours": "\"Mo-Th 11:00-20:00; Fr-Sa 11:00-21:00; Su 12:00-18:00\"",
                "phone": "\"817-375-1152\"",
                "website": "\"https://www.journeys.com/store/kidz-the-parks-at-arlington\""
            },
            {
                "__id": 278,
                "address_city": "\"Union Gap\"",
                "address_country": "\"US\"",
                "address_full": "\"2529 Main St\"",
                "address_postcode": "\"98903\"",
                "address_state": "\"WA\"",
                "name": "\"Valley Mall\"",
                "opening_hours": "\"Mo-Th 10:00-20:00; Fr-Sa 10:00-21:00; Su 11:00-19:00\"",
                "phone": "\"509-388-9810\"",
                "website": "\"https://www.journeys.com/store/kidz-valley-mall-union-gap\""
            },
            {
                "__id": 820,
                "address_city": "\"Tallahassee\"",
                "address_country": "\"US\"",
                "address_full": "\"1500 Apalachee Pkwy\"",
                "address_postcode": "\"32301\"",
                "address_state": "\"FL\"",
                "name": "\"Governors Square\"",
                "opening_hours": "\"Mo-Th 11:00-19:00; Fr-Sa 11:00-20:00; Su 12:00-18:00\"",
                "phone": "\"850-219-3826\"",
                "website": "\"https://www.journeys.com/store/kidz-governors-square\""
            },
            {
                "__id": 587,
                "address_city": "\"St. George\"",
                "address_country": "\"US\"",
                "address_full": "\"1770 Red Cliffs Dr\"",
                "address_postcode": "\"84790-8159\"",
                "address_state": "\"UT\"",
                "name": "\"Red Cliffs Mall\"",
                "opening_hours": "\"Mo-Th 10:00-19:00; Fr-Sa 10:00-21:00; Su 12:00-17:00\"",
                "phone": "\"435-688-0684\"",
                "website": "\"https://www.journeys.com/store/journeys-red-cliffs-mall\""
            },
            {
                "__id": 301,
                "address_city": "\"Strongsville\"",
                "address_country": "\"US\"",
                "address_full": "\"1116 Southpark Center\"",
                "address_postcode": "\"44136\"",
                "address_state": "\"OH\"",
                "name": "\"Southpark Center\"",
                "opening_hours": "\"Mo-Sa 10:00-20:00; Su 12:00-18:00\"",
                "phone": "\"440-846-5110\"",
                "website": "\"https://www.journeys.com/store/journeys-southpark-center\""
            },
            {
                "__id": 1027,
                "address_city": "\"Bismarck\"",
                "address_country": "\"US\"",
                "address_full": "\"746 Kirkwood Mall\"",
                "address_postcode": "\"58504\"",
                "address_state": "\"ND\"",
                "name": "\"Kirkwood Mall\"",
                "opening_hours": "\"Mo-Fr 10:00-21:00; Sa 10:00-19:00; Su 12:00-18:00\"",
                "phone": "\"701-258-1400\"",
                "website": "\"https://www.journeys.com/store/journeys-kirkwood-mall\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "stbank": {
        "__id": 2,
        "id": "stbank",
        "name": "St. Bank",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'fax', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'website']",
        "rowLength": 78,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763443,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of stbank locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 75,
                "address_city": "\"York\"",
                "address_country": "\"US\"",
                "address_full": "\"2 South George St.\"",
                "address_postcode": "\"17401\"",
                "address_state": "\"PA\"",
                "fax": "\"\"",
                "latitude": 39.9620719,
                "longitude": -76.7277978,
                "name": "\"Downtown York\"",
                "opening_hours": "\"\"",
                "phone": "\"+17177188000\"",
                "website": "\"https://www.stbank.com/locations/downtown-york\""
            },
            {
                "__id": 19,
                "address_city": "\"Greensburg\"",
                "address_country": "\"US\"",
                "address_full": "\"701 East Pittsburgh St.\"",
                "address_postcode": "\"15601\"",
                "address_state": "\"PA\"",
                "fax": "\"+17248535939\"",
                "latitude": 40.3034397,
                "longitude": -79.5286648,
                "name": "\"Greensburg\"",
                "opening_hours": "\"Mo-Th 09:00-16:00; Fr 09:00-18:00; Sa 09:00-12:00\"",
                "phone": "\"+17248535932\"",
                "website": "\"https://www.stbank.com/locations/greensburg\""
            },
            {
                "__id": 8,
                "address_city": "\"West Chester\"",
                "address_country": "\"US\"",
                "address_full": "\"300 Oakland Rd\"",
                "address_postcode": "\"19382\"",
                "address_state": "\"PA\"",
                "fax": "\"+16103619428\"",
                "latitude": 39.886435,
                "longitude": -75.55194,
                "name": "\"Chadds Ford\"",
                "opening_hours": "\"\"",
                "phone": "\"+14843593228\"",
                "website": "\"https://www.stbank.com/locations/chadds-ford\""
            },
            {
                "__id": 76,
                "address_city": "\"West Chester\"",
                "address_country": "\"US\"",
                "address_full": "\"1349 Wilmington Pike\"",
                "address_postcode": "\"19382\"",
                "address_state": "\"PA\"",
                "fax": "\"+16103619428\"",
                "latitude": 39.902429,
                "longitude": -75.563919,
                "name": "\"Dilworthtown Crossing\"",
                "opening_hours": "\"Mo-Th 09:00-16:00; Fr 09:00-18:00; Sa 09:00-12:00\"",
                "phone": "\"+14843593228\"",
                "website": "\"https://www.stbank.com/locations/dilworthtown-crossing\""
            },
            {
                "__id": 54,
                "address_city": "\"Cuyahoga Falls\"",
                "address_country": "\"US\"",
                "address_full": "\"2032 Front Street\"",
                "address_postcode": "\"44221\"",
                "address_state": "\"OH\"",
                "fax": "\"\"",
                "latitude": 41.1346753,
                "longitude": -81.4831468,
                "name": "\"Cuyahoga\"",
                "opening_hours": "\"\"",
                "phone": "\"+13309682013\"",
                "website": "\"https://www.stbank.com/locations/cuyahoga\""
            },
            {
                "__id": 13,
                "address_city": "\"Verona\"",
                "address_country": "\"US\"",
                "address_full": "\"2190 Hulton Road\"",
                "address_postcode": "\"15147\"",
                "address_state": "\"PA\"",
                "fax": "\"+14128284785\"",
                "latitude": 40.5269154,
                "longitude": -79.8159528,
                "name": "\"East Oakmont\"",
                "opening_hours": "\"Mo-Th 09:00-16:00; Fr 09:00-17:00; Sa 09:00-12:00\"",
                "phone": "\"+14128281341\"",
                "website": "\"https://www.stbank.com/locations/east-oakmont\""
            },
            {
                "__id": 20,
                "address_city": "\"Punxsutawney\"",
                "address_country": "\"US\"",
                "address_full": "\"232 Hampton Ave.\"",
                "address_postcode": "\"15767\"",
                "address_state": "\"PA\"",
                "fax": "\"+18149381130\"",
                "latitude": 40.9467895,
                "longitude": -78.967411,
                "name": "\"Hampton Ave\"",
                "opening_hours": "\"Mo-Th 09:00-16:00; Fr 09:00-18:00; Sa 09:00-12:00\"",
                "phone": "\"+18149381101\"",
                "website": "\"https://www.stbank.com/locations/hampton-ave\""
            },
            {
                "__id": 17,
                "address_city": "\"Lancaster\"",
                "address_country": "\"US\"",
                "address_full": "\"2055 Fruitville Pike\"",
                "address_postcode": "\"17601\"",
                "address_state": "\"PA\"",
                "fax": "\"+17173581961\"",
                "latitude": 40.07658,
                "longitude": -76.32425,
                "name": "\"Fruitville Pike\"",
                "opening_hours": "\"Mo-Th 09:00-16:00; Fr 09:00-17:00\"",
                "phone": "\"+17173581960\"",
                "website": "\"https://www.stbank.com/locations/fruitville-pike\""
            },
            {
                "__id": 40,
                "address_city": "\"Philadelphia\"",
                "address_country": "\"US\"",
                "address_full": "\"6137 Ridge Ave\"",
                "address_postcode": "\"19128\"",
                "address_state": "\"PA\"",
                "fax": "\"+12154829407\"",
                "latitude": 40.034637,
                "longitude": -75.216098,
                "name": "\"Roxborough\"",
                "opening_hours": "\"Mo-Th 09:00-16:00; Fr 09:00-18:00; Sa 09:00-12:00\"",
                "phone": "\"+12154829401\"",
                "website": "\"https://www.stbank.com/locations/roxborough\""
            },
            {
                "__id": 43,
                "address_city": "\"Saltsburg\"",
                "address_country": "\"US\"",
                "address_full": "\"602 Salt St\"",
                "address_postcode": "\"15681\"",
                "address_state": "\"PA\"",
                "fax": "\"\"",
                "latitude": 40.4851148,
                "longitude": -79.450653,
                "name": "\"Saltsburg\"",
                "opening_hours": "\"Mo-Th 09:00-16:00; Fr 09:00-17:00; Sa 09:00-12:00\"",
                "phone": "\"+17246399100\"",
                "website": "\"https://www.stbank.com/locations/saltsburg\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "talbots": {
        "__id": 3,
        "id": "talbots",
        "name": "Talbots",
        "columnNames": "['address_full', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'website']",
        "rowLength": 466,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763448,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of talbots locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 365,
                "address_full": "\"BOISE TOWNE SQUARE356 NORTH MILWAUKEE STREETBOISE, ID 83704\"",
                "latitude": 43.6083,
                "longitude": -116.2841,
                "name": "\"BOISE\"",
                "opening_hours": "\"Mo-Sa 10:00-18:00; Su 12:00-17:00\"",
                "phone": "\"208-344-4202\"",
                "shop": "\"clothes\"",
                "website": "\"https://www.talbots.com/store?StoreID=00232\""
            },
            {
                "__id": 367,
                "address_full": "\"INDIAN HILL SHOP CTR9739 UNIVERSITY AVECLIVE, IA 50325\"",
                "latitude": 41.6005,
                "longitude": -93.7515,
                "name": "\"DES MOINES\"",
                "opening_hours": "\"Mo-Fr 10:00-19:00; Sa 10:00-18:00; Su 12:00-17:00\"",
                "phone": "\"515-222-9080\"",
                "shop": "\"clothes\"",
                "website": "\"https://www.talbots.com/store?StoreID=00209\""
            },
            {
                "__id": 403,
                "address_full": "\"WESTFIELD SIESTA KEY214 SOUTHGATE PLAZASARASOTA, FL 34239\"",
                "latitude": 27.2998,
                "longitude": -82.5295,
                "name": "\"SARASOTA PETITES\"",
                "opening_hours": "\"Mo-Sa 10:00-18:00; Su 12:00-18:00\"",
                "phone": "\"941-917-0544\"",
                "shop": "\"clothes\"",
                "website": "\"https://www.talbots.com/store?StoreID=00427\""
            },
            {
                "__id": 157,
                "address_full": "\"2125 NW EXPRESSWAYOKLAHOMA CITY, OK 73112\"",
                "latitude": 35.5233,
                "longitude": -97.5481,
                "name": "\"OKLAHOMA CITY\"",
                "opening_hours": "\"Mo-Sa 09:00-19:00; Su 12:00-18:00\"",
                "phone": "\"405-879-4211\"",
                "shop": "\"clothes\"",
                "website": "\"https://www.talbots.com/store?StoreID=00147\""
            },
            {
                "__id": 448,
                "address_full": "\"1604 REDWOOD HIGHWAY SP D038CORTE MADERA, CA 94925\"",
                "latitude": 37.9287,
                "longitude": -122.5118,
                "name": "\"CORTE MADERA\"",
                "opening_hours": "\"Mo-Sa 10:00-19:00; Su 11:00-18:00\"",
                "phone": "\"415-927-3793\"",
                "shop": "\"clothes\"",
                "website": "\"https://www.talbots.com/store?StoreID=00081\""
            },
            {
                "__id": 119,
                "address_full": "\"BARRINGTON SHOPPINGROUTE 114 180 COUNTY ROBARRINGTON, RI 02806\"",
                "latitude": 41.7373,
                "longitude": -71.304,
                "name": "\"BARRINGTON\"",
                "opening_hours": "\"Mo-Sa 10:00-19:00; Su 11:00-17:00\"",
                "phone": "\"401-247-4511\"",
                "shop": "\"clothes\"",
                "website": "\"https://www.talbots.com/store?StoreID=01016\""
            },
            {
                "__id": 1,
                "address_full": "\"STADIUM PLACE SHP PLAZA3030 S MEMORIAL PKWYHUNTSVILLE, AL 35801\"",
                "latitude": 34.7009,
                "longitude": -86.5864,
                "name": "\"HUNTSVILLE\"",
                "opening_hours": "\"Mo-Sa 10:00-19:00; Su 12:00-18:00\"",
                "phone": "\"256-880-2030\"",
                "shop": "\"clothes\"",
                "website": "\"https://www.talbots.com/store?StoreID=00248\""
            },
            {
                "__id": 211,
                "address_full": "\"17 SOUTH WALNUT STREETRIDGEWOOD, NJ 07450\"",
                "latitude": 40.9791,
                "longitude": -74.117,
                "name": "\"RIDGEWOOD\"",
                "opening_hours": "\"Mo-Sa 10:00-19:00\"",
                "phone": "\"201-670-0260\"",
                "shop": "\"clothes\"",
                "website": "\"https://www.talbots.com/store?StoreID=00057\""
            },
            {
                "__id": 458,
                "address_full": "\"THE VILLAGE AT ARROWHEAD20022 NORTH 67TH AVE,SUITE 106GLENDALE, AZ 85308\"",
                "latitude": 33.6659,
                "longitude": -112.203,
                "name": "\"GLENDALE\"",
                "opening_hours": "\"Mo-Sa 09:00-18:00; Su 11:00-17:00\"",
                "phone": "\"623-566-2895\"",
                "shop": "\"clothes\"",
                "website": "\"https://www.talbots.com/store?StoreID=01089\""
            },
            {
                "__id": 173,
                "address_full": "\"WESTFIELD FRANKLIN PARK5001 MONROE ST. SUITE 1727TOLEDO, OH 43623\"",
                "latitude": 41.6976,
                "longitude": -83.6405,
                "name": "\"TOLEDO\"",
                "opening_hours": "\"Mo-Sa 10:00-17:00\"",
                "phone": "\"419-292-1223\"",
                "shop": "\"clothes\"",
                "website": "\"https://www.talbots.com/store?StoreID=00286\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "jerseymikes": {
        "__id": 4,
        "id": "jerseymikes",
        "name": "Jersey Mike's",
        "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
        "rowLength": 494,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763453,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 168,
                "address_city": "\"Chantilly\"",
                "address_full": "\"5005 Westone Plaza Blvd.\"",
                "address_postcode": "\"20151-4207\"",
                "address_state": "\"VA\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"sandwich\"",
                "latitude": 38.870263,
                "longitude": -77.44448,
                "name": "\"5005 Westone Plaza Blvd., Chantilly, VA - Jersey Mike's Subs\"",
                "opening_hours": "\"10am - 9pm\"",
                "phone": "\" \"",
                "short_name": "\"Jersey Mike's\"",
                "takeaway": "\"yes\"",
                "website": "\"https://www.jerseymikes.com/6029/chantilly-va\""
            },
            {
                "__id": 414,
                "address_city": "\"Christiana\"",
                "address_full": "\"3250 Fashion Center Blvd.\"",
                "address_postcode": "\"19702-3254\"",
                "address_state": "\"DE\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"sandwich\"",
                "latitude": 39.673272,
                "longitude": -75.648885,
                "name": "\"3250 Fashion Center Blvd., Christiana, DE - Jersey Mike's Subs\"",
                "opening_hours": "\"10am - 9pm\"",
                "phone": "\" \"",
                "short_name": "\"Jersey Mike's\"",
                "takeaway": "\"yes\"",
                "website": "\"https://www.jerseymikes.com/35004/christiana-de\""
            },
            {
                "__id": 476,
                "address_city": "\"Spirit Lake\"",
                "address_full": "\"1306 18th Street\"",
                "address_postcode": "\"51360-1163\"",
                "address_state": "\"IA\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"sandwich\"",
                "latitude": 43.423031,
                "longitude": -95.110254,
                "name": "\"1306 18th Street, Spirit Lake, IA - Jersey Mike's Subs\"",
                "opening_hours": "\"10am - 9pm\"",
                "phone": "\" \"",
                "short_name": "\"Jersey Mike's\"",
                "takeaway": "\"yes\"",
                "website": "\"https://www.jerseymikes.com/38014/spirit-lake-ia\""
            },
            {
                "__id": 235,
                "address_city": "\"Smithfield\"",
                "address_full": "\"473 Putnam Pike\"",
                "address_postcode": "\"02828-3004\"",
                "address_state": "\"RI\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"sandwich\"",
                "latitude": 41.87079,
                "longitude": -71.532256,
                "name": "\"473 Putnam Pike, Smithfield, RI - Jersey Mike's Subs\"",
                "opening_hours": "\"10am - 9pm\"",
                "phone": "\" \"",
                "short_name": "\"Jersey Mike's\"",
                "takeaway": "\"yes\"",
                "website": "\"https://www.jerseymikes.com/14006/smithfield-ri\""
            },
            {
                "__id": 16,
                "address_city": "\"Eagan\"",
                "address_full": "\"3405 Promenade Ave.\"",
                "address_postcode": "\"55123-4420\"",
                "address_state": "\"MN\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"sandwich\"",
                "latitude": 44.833185,
                "longitude": -93.152666,
                "name": "\"3405 Promenade Ave., Eagan, MN - Jersey Mike's Subs\"",
                "opening_hours": "\"10am - 9pm\"",
                "phone": "\" \"",
                "short_name": "\"Jersey Mike's\"",
                "takeaway": "\"yes\"",
                "website": "\"https://www.jerseymikes.com/24016/eagan-mn\""
            },
            {
                "__id": 134,
                "address_city": "\"Oklahoma City\"",
                "address_full": "\"6719 North May Avenue\"",
                "address_postcode": "\"73116-3431\"",
                "address_state": "\"OK\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"sandwich\"",
                "latitude": 35.540015,
                "longitude": -97.565943,
                "name": "\"6719 North May Avenue, Oklahoma City, OK - Jersey Mike's Subs\"",
                "opening_hours": "\"10am - 9pm\"",
                "phone": "\" \"",
                "short_name": "\"Jersey Mike's\"",
                "takeaway": "\"yes\"",
                "website": "\"https://www.jerseymikes.com/23009/oklahoma-city-ok\""
            },
            {
                "__id": 60,
                "address_city": "\"Las Vegas\"",
                "address_full": "\"5757 Wayne Newton Blvd.\"",
                "address_postcode": "\"89111-8037\"",
                "address_state": "\"NV\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"sandwich\"",
                "latitude": 36.085129,
                "longitude": -115.15065,
                "name": "\"5757 Wayne Newton Blvd., Las Vegas, NV - Jersey Mike's Subs\"",
                "opening_hours": "\"\"",
                "phone": "\" \"",
                "short_name": "\"Jersey Mike's\"",
                "takeaway": "\"yes\"",
                "website": "\"https://www.jerseymikes.com/17010/las-vegas-nv\""
            },
            {
                "__id": 14,
                "address_city": "\"Webster Groves\"",
                "address_full": "\"8567 Watson Rd.\"",
                "address_postcode": "\"63119-5251\"",
                "address_state": "\"MO\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"sandwich\"",
                "latitude": 38.565601,
                "longitude": -90.357756,
                "name": "\"8567 Watson Rd., Webster Groves, MO - Jersey Mike's Subs\"",
                "opening_hours": "\"10am - 9pm\"",
                "phone": "\" \"",
                "short_name": "\"Jersey Mike's\"",
                "takeaway": "\"yes\"",
                "website": "\"https://www.jerseymikes.com/25001/webster-groves-mo\""
            },
            {
                "__id": 368,
                "address_city": "\"Anthem\"",
                "address_full": "\"39512 N. Daisy Mountain Drive\"",
                "address_postcode": "\"85086-6062\"",
                "address_state": "\"AZ\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"sandwich\"",
                "latitude": 33.844167,
                "longitude": -112.132205,
                "name": "\"39512 N. Daisy Mountain Drive, Anthem, AZ - Jersey Mike's Subs\"",
                "opening_hours": "\"10am - 9pm\"",
                "phone": "\" \"",
                "short_name": "\"Jersey Mike's\"",
                "takeaway": "\"yes\"",
                "website": "\"https://www.jerseymikes.com/9062/anthem-az\""
            },
            {
                "__id": 306,
                "address_city": "\"Bothell\"",
                "address_full": "\"24016 Bothell Everett Hwy.\"",
                "address_postcode": "\"98021-9361\"",
                "address_state": "\"WA\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"sandwich\"",
                "latitude": 47.780033,
                "longitude": -122.220101,
                "name": "\"24016 Bothell Everett Hwy., Bothell, WA - Jersey Mike's Subs\"",
                "opening_hours": "\"10am - 9pm\"",
                "phone": "\" \"",
                "short_name": "\"Jersey Mike's\"",
                "takeaway": "\"yes\"",
                "website": "\"https://www.jerseymikes.com/18043/bothell-wa\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "hallmark": {
        "__id": 5,
        "id": "hallmark",
        "name": "Hallmark",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'address_street', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'website']",
        "rowLength": 1397,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763463,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of hallmark locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 1115,
                "address_city": "\"Rehoboth Beach\"",
                "address_country": "\"US\"",
                "address_postcode": "\"19971-6117\"",
                "address_state": "\"DE\"",
                "address_street": "\"19266 Coastal Hwy Unit 10 The Shops At Sea Coast\"",
                "latitude": 38.705239,
                "longitude": -75.086477,
                "name": "\"Pam's Hallmark Shoppe\"",
                "opening_hours": "\"Mo-Fr 10:00-19:00; Sa 09:00-19:00; Su 11:00-17:00\"",
                "phone": "\"(302) 227-6522\"",
                "shop": "\"gift\"",
                "website": "\"https://stores.hallmark.com/de/rehobothbeach/pams-hallmark-shoppe-rehobothbeach-de-009486.html\""
            },
            {
                "__id": 302,
                "address_city": "\"Wauwatosa\"",
                "address_country": "\"US\"",
                "address_postcode": "\"53213-2805\"",
                "address_state": "\"WI\"",
                "address_street": "\"1525 N 68Th St\"",
                "latitude": 43.05094,
                "longitude": -87.997197,
                "name": "\"Wauwatosa Ace Hardware\"",
                "opening_hours": "\"\"",
                "phone": "\"(414) 476-0833\"",
                "shop": "\"gift\"",
                "website": "\"https://stores.hallmark.com/wi/wauwatosa/wauwatosa-ace-hardware-wauwatosa-wi-046941.html\""
            },
            {
                "__id": 935,
                "address_city": "\"Myrtle Beach\"",
                "address_country": "\"US\"",
                "address_postcode": "\"29588-7700\"",
                "address_state": "\"SC\"",
                "address_street": "\"8654 Highway 17 Byp  S Ste 250 South Strand Commons\"",
                "latitude": 33.644380995939,
                "longitude": -78.98605282109,
                "name": "\"Kendall's Hallmark Shop-Curbside Pick Up Available\"",
                "opening_hours": "\"Mo-Sa 10:00-18:00; Su 12:00-17:00\"",
                "phone": "\"(843) 215-8274\"",
                "shop": "\"gift\"",
                "website": "\"https://stores.hallmark.com/sc/myrtlebeach/kendalls-hallmark-shop-curbside-pick-up-available-myrtlebeach-sc-035084.html\""
            },
            {
                "__id": 599,
                "address_city": "\"Sierra Vista\"",
                "address_country": "\"US\"",
                "address_postcode": "\"85635-2736\"",
                "address_state": "\"AZ\"",
                "address_street": "\"2160 E Fry Blvd Ste C2 Sierra Vista Plaza\"",
                "latitude": 31.554595157504,
                "longitude": -110.27659490704,
                "name": "\"Ashwina's Hallmark Shop-Curbside Pick Up Available\"",
                "opening_hours": "\"Mo-Fr 09:00-18:00; Sa 10:00-18:00; Su 11:00-16:00\"",
                "phone": "\"(520) 458-0922\"",
                "shop": "\"gift\"",
                "website": "\"https://stores.hallmark.com/az/sierravista/ashwinas-hallmark-shop-curbside-pick-up-available-sierravista-az-054528.html\""
            },
            {
                "__id": 873,
                "address_city": "\"Foothill Ranch\"",
                "address_country": "\"US\"",
                "address_postcode": "\"92610-1768\"",
                "address_state": "\"CA\"",
                "address_street": "\"26736 Portola Pkwy Ste 6A Foothill Rnch Towne Center\"",
                "latitude": 33.678648,
                "longitude": -117.665488,
                "name": "\"Jen's Hallmark Shop-Curbside Pick Up Available\"",
                "opening_hours": "\"Mo-Fr 10:00-19:00; Sa 10:00-18:00; Su 11:00-17:00\"",
                "phone": "\"(949) 855-8616\"",
                "shop": "\"gift\"",
                "website": "\"https://stores.hallmark.com/ca/foothillranch/jens-hallmark-shop-curbside-pick-up-available-foothillranch-ca-063664.html\""
            },
            {
                "__id": 69,
                "address_city": "\"Warr Acres\"",
                "address_country": "\"US\"",
                "address_postcode": "\"73132-5230\"",
                "address_state": "\"OK\"",
                "address_street": "\"5539 Nw Expressway Market Place Shopping Center\"",
                "latitude": 35.546519458294,
                "longitude": -97.611863091588,
                "name": "\"Donna's Hallmark Shop-Curbside Pick Up Available\"",
                "opening_hours": "\"Mo-Fr 10:00-20:00; Sa 10:00-18:00; Su 13:00-17:00\"",
                "phone": "\"(405) 728-6771\"",
                "shop": "\"gift\"",
                "website": "\"https://stores.hallmark.com/ok/warracres/donnas-hallmark-shop-curbside-pick-up-available-warracres-ok-001299.html\""
            },
            {
                "__id": 1290,
                "address_city": "\"Sechelt\"",
                "address_country": "\"CA\"",
                "address_postcode": "\"V0N 3A0\"",
                "address_state": "\"BC\"",
                "address_street": "\"5740 Teredo St\"",
                "latitude": 49.471883,
                "longitude": -123.759807,
                "name": "\"Rx Drug Mart 1002\"",
                "opening_hours": "\"Mo-Su 08:00-20:00\"",
                "phone": "\"604-885-9833\"",
                "shop": "\"gift\"",
                "website": "\"https://stores.hallmark.com/bc/sechelt/rx-drug-mart-1002-sechelt-bc-030075.html\""
            },
            {
                "__id": 455,
                "address_city": "\"Fairfield\"",
                "address_country": "\"US\"",
                "address_postcode": "\"45014-8137\"",
                "address_state": "\"OH\"",
                "address_street": "\"675 Deis Dr Ste R Village Green Shopping Center\"",
                "latitude": 39.336212158203,
                "longitude": -84.564308166504,
                "name": "\"Reichley's Hallmark Shop-Curbside Available\"",
                "opening_hours": "\"Mo-Fr 10:00-20:00; Sa 10:00-19:00; Su 12:00-17:00\"",
                "phone": "\"(513) 829-6600\"",
                "shop": "\"gift\"",
                "website": "\"https://stores.hallmark.com/oh/fairfield/reichleys-hallmark-shop-curbside-available-fairfield-oh-014793.html\""
            },
            {
                "__id": 1041,
                "address_city": "\"Glen Mills\"",
                "address_country": "\"US\"",
                "address_postcode": "\"19342-3344\"",
                "address_state": "\"PA\"",
                "address_street": "\"200 Town Centre Dr Ste 5 Concordville Town Center\"",
                "latitude": 39.882920533419,
                "longitude": -75.527410358191,
                "name": "\"Norman's Hallmark Shop-Curbside Pick Up Available\"",
                "opening_hours": "\"Mo-Sa 10:00-18:00; Su 11:00-17:00\"",
                "phone": "\"(610) 358-0852\"",
                "shop": "\"gift\"",
                "website": "\"https://stores.hallmark.com/pa/glenmills/normans-hallmark-shop-curbside-pick-up-available-glenmills-pa-021371.html\""
            },
            {
                "__id": 1131,
                "address_city": "\"Trenton\"",
                "address_country": "\"US\"",
                "address_postcode": "\"08691-2103\"",
                "address_state": "\"NJ\"",
                "address_street": "\"148 Marketplace Blvd Hamilton Marketplace\"",
                "latitude": 40.191490575671,
                "longitude": -74.644510298967,
                "name": "\"Norman's Hallmark Shop-Curbside Pick Up Available\"",
                "opening_hours": "\"Mo-Sa 10:00-20:00; Su 11:00-17:00\"",
                "phone": "\"(609) 585-9100\"",
                "shop": "\"gift\"",
                "website": "\"https://stores.hallmark.com/nj/trenton/normans-hallmark-shop-curbside-pick-up-available-trenton-nj-006162.html\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "brookdale": {
        "__id": 6,
        "id": "brookdale",
        "name": "Brookdale",
        "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'phone', 'street_address', 'website']",
        "rowLength": 656,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763466,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of brookdale locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 389,
                "address_city": "\"Inver Grove Heights\"",
                "address_postcode": "\"550764414\"",
                "address_state": "\"MN\"",
                "latitude": 44.863281,
                "longitude": -93.040429,
                "name": "\"Brookdale Inver Grove Heights\"",
                "phone": "\"651-306-0919\"",
                "street_address": "\"5891 Carmen Avenue\"",
                "website": "\"https://www.brookdale.com/en/communities/brookdale-inver-grove-heights.html\""
            },
            {
                "__id": 606,
                "address_city": "\"Jensen Beach\"",
                "address_postcode": "\"34957\"",
                "address_state": "\"FL\"",
                "latitude": 27.224116,
                "longitude": -80.214026,
                "name": "\"Brookdale Jensen Beach\"",
                "phone": "\"772-225-1355\"",
                "street_address": "\"1700 Northeast Indian River Drive\"",
                "website": "\"https://www.brookdale.com/en/communities/brookdale-jensen-beach.html\""
            },
            {
                "__id": 579,
                "address_city": "\"Mesa\"",
                "address_postcode": "\"85206\"",
                "address_state": "\"AZ\"",
                "latitude": 33.408556,
                "longitude": -111.700501,
                "name": "\"Brookdale Springs Mesa\"",
                "phone": "\"480-985-8900\"",
                "street_address": "\"6220 East Broadway Road\"",
                "website": "\"https://www.brookdale.com/en/communities/brookdale-springs-mesa.html\""
            },
            {
                "__id": 513,
                "address_city": "\"McMinnville\"",
                "address_postcode": "\"971282157\"",
                "address_state": "\"OR\"",
                "latitude": 45.228954,
                "longitude": -123.191396,
                "name": "\"Brookdale McMinnville Town Center\"",
                "phone": "\"503-472-3130\"",
                "street_address": "\"775 North East 27th Street\"",
                "website": "\"https://www.brookdale.com/en/communities/brookdale-mcminnville-town-center.html\""
            },
            {
                "__id": 644,
                "address_city": "\"Sarasota\"",
                "address_postcode": "\"34233\"",
                "address_state": "\"FL\"",
                "latitude": 27.298663,
                "longitude": -82.474084,
                "name": "\"Brookdale Colonial Park\"",
                "phone": "\"941-378-4414\"",
                "street_address": "\"4730 Bee Ridge Road\"",
                "website": "\"https://www.brookdale.com/en/communities/brookdale-colonial-park.html\""
            },
            {
                "__id": 361,
                "address_city": "\"Ithaca\"",
                "address_postcode": "\"148509052\"",
                "address_state": "\"NY\"",
                "latitude": 42.459168,
                "longitude": -76.529156,
                "name": "\"Brookdale Ithaca\"",
                "phone": "\"607-256-5094\"",
                "street_address": "\"101 Bundy Road\"",
                "website": "\"https://www.brookdale.com/en/communities/brookdale-ithaca-mc.html\""
            },
            {
                "__id": 633,
                "address_city": "\"Southfield\"",
                "address_postcode": "\"48034\"",
                "address_state": "\"MI\"",
                "latitude": 42.487631,
                "longitude": -83.298766,
                "name": "\"Brookdale Southfield\"",
                "phone": "\"248-727-2000\"",
                "street_address": "\"25800 Eleven Mile Road\"",
                "website": "\"https://www.brookdale.com/en/communities/brookdale-southfield.html\""
            },
            {
                "__id": 547,
                "address_city": "\"Bristol\"",
                "address_postcode": "\"24201\"",
                "address_state": "\"VA\"",
                "latitude": 36.6038927,
                "longitude": -82.2146563,
                "name": "\"Brookdale Bristol\"",
                "phone": "\"276-669-1111\"",
                "street_address": "\"375 Liberty Place\"",
                "website": "\"https://www.brookdale.com/en/communities/brookdale-bristol.html\""
            },
            {
                "__id": 299,
                "address_city": "\"Walla Walla\"",
                "address_postcode": "\"99362\"",
                "address_state": "\"WA\"",
                "latitude": 46.047899,
                "longitude": -118.3586,
                "name": "\"Brookdale Walla Walla\"",
                "phone": "\"509-525-4990\"",
                "street_address": "\"1460 Dalles Military Road\"",
                "website": "\"https://www.brookdale.com/en/communities/brookdale-walla-walla.html\""
            },
            {
                "__id": 630,
                "address_city": "\"Lakewood\"",
                "address_postcode": "\"80215\"",
                "address_state": "\"CO\"",
                "latitude": 39.74508,
                "longitude": -105.117924,
                "name": "\"Brookdale Meridian Westland\"",
                "phone": "\"303-232-7100\"",
                "street_address": "\"10695 W. 17th Avenue\"",
                "website": "\"https://www.brookdale.com/en/communities/brookdale-meridian-westland.html\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "cava": {
        "__id": 7,
        "id": "cava",
        "name": "Cava",
        "columnNames": "['address_city', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'name', 'opening_hours', 'phone', 'street_address']",
        "rowLength": 235,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763468,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of cava locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 168,
                "address_city": "\"Cypress\"",
                "address_postcode": "\"77433\"",
                "address_state": "\"TX\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"mediterranean\"",
                "name": "\"CAVA\"",
                "opening_hours": "\"Mo-Su 10:45-22:00\"",
                "phone": "\"346-818-2945\"",
                "street_address": "\"28920 Highway 290, Suite H01, Cypress\""
            },
            {
                "__id": 145,
                "address_city": "\"Rittenhouse\"",
                "address_postcode": "\"19103\"",
                "address_state": "\"PA\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"mediterranean\"",
                "name": "\"CAVA\"",
                "opening_hours": "\"Mo-Su 10:45-22:00\"",
                "phone": "\"215-259-3266\"",
                "street_address": "\"1713 Chestnut Street, Philadelphia\""
            },
            {
                "__id": 51,
                "address_city": "\"Winter Park\"",
                "address_postcode": "\"32789\"",
                "address_state": "\"FL\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"mediterranean\"",
                "name": "\"CAVA\"",
                "opening_hours": "\"Mo-Su 10:45-22:00\"",
                "phone": "\"689-232-0204\"",
                "street_address": "\"501 N Orlando Ave, Suite 323, Winter Park\""
            },
            {
                "__id": 96,
                "address_city": "\"Cameron Village\"",
                "address_postcode": "\"27605\"",
                "address_state": "\"NC\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"mediterranean\"",
                "name": "\"CAVA\"",
                "opening_hours": "\"Mo-Su 10:45-22:00\"",
                "phone": "\"919-278-7597\"",
                "street_address": "\"403 Daniels Street,  Raleigh\""
            },
            {
                "__id": 198,
                "address_city": "\"Vintage\"",
                "address_postcode": "\"77070\"",
                "address_state": "\"TX\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"mediterranean\"",
                "name": "\"CAVA\"",
                "opening_hours": "\"Mo-Su 10:45-22:00\"",
                "phone": "\"346-246-2561\"",
                "street_address": "\"10850 Louetta Rd, Ste 100, Houston\""
            },
            {
                "__id": 189,
                "address_city": "\"Sugar Land\"",
                "address_postcode": "\"77479\"",
                "address_state": "\"TX\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"mediterranean\"",
                "name": "\"CAVA\"",
                "opening_hours": "\"Mo-Su 10:45-22:00\"",
                "phone": "\"346-450-5763\"",
                "street_address": "\"2260 Lone Star Drive, Sugar Land\""
            },
            {
                "__id": 57,
                "address_city": "\"Cumming\"",
                "address_postcode": "\"30041\"",
                "address_state": "\"GA\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"mediterranean\"",
                "name": "\"CAVA\"",
                "opening_hours": "\"Mo-Su 10:45-22:00\"",
                "phone": "\"470-239-0797\"",
                "street_address": "\"410 Peachtree Pkwy, Suite  #260, Cumming\""
            },
            {
                "__id": 98,
                "address_city": "\"Cary\"",
                "address_postcode": "\"27511\"",
                "address_state": "\"NC\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"mediterranean\"",
                "name": "\"CAVA\"",
                "opening_hours": "\"Mo-Su 10:45-22:00\"",
                "phone": "\"919-342-5937\"",
                "street_address": "\"2001 Walnut Street,  Suite 100, Cary\""
            },
            {
                "__id": 167,
                "address_city": "\"Custer Star\"",
                "address_postcode": "\"75035\"",
                "address_state": "\"TX\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"mediterranean\"",
                "name": "\"CAVA\"",
                "opening_hours": "\"Mo-Su 10:45-22:00\"",
                "phone": "\"469-815-6521\"",
                "street_address": "\"16120 W Eldorado Pkwy, Suite 300, Frisco\""
            },
            {
                "__id": 18,
                "address_city": "\"La Jolla\"",
                "address_postcode": "\"92037\"",
                "address_state": "\"CA\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"mediterranean\"",
                "name": "\"CAVA\"",
                "opening_hours": "\"Mo-Su 10:45-22:00\"",
                "phone": "\"858-433-0346\"",
                "street_address": "\"8849 Villa La Jolla Drive, Unit 301, La Jolla\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "84lumber": {
        "__id": 8,
        "id": "84lumber",
        "name": "84 Lumber",
        "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
        "rowLength": 231,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763470,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 138,
                "address_city": "\"GREENWOOD\"",
                "address_postcode": "\"46143-9509\"",
                "address_state": "\"IN\"",
                "latitude": 39.5867271,
                "longitude": -86.09864,
                "name": "\"GREENWOOD\"",
                "opening_hours": "\"Mo-Fr 07:00-18:00; Sa 08:00-12:00\"",
                "phone": "\"3178818689\"",
                "shop": "\"trade\"",
                "street_address": "\"484 E WORTHSVILLE RD\"",
                "trade": "\"building_supplies\"",
                "website": "\"https://www.84lumber.com/store-locator/store-detail/?storeId=709\""
            },
            {
                "__id": 158,
                "address_city": "\"PLAINVILLE\"",
                "address_postcode": "\"06062-2051\"",
                "address_state": "\"CT\"",
                "latitude": 41.67224,
                "longitude": -72.83761,
                "name": "\"PLAINVILLE\"",
                "opening_hours": "\"Mo-Fr 07:00-18:00; Sa 08:00-12:00\"",
                "phone": "\"8607930284\"",
                "shop": "\"trade\"",
                "street_address": "\"84 CROOKED ST\"",
                "trade": "\"building_supplies\"",
                "website": "\"https://www.84lumber.com/store-locator/store-detail/?storeId=3801\""
            },
            {
                "__id": 206,
                "address_city": "\"BATON ROUGE\"",
                "address_postcode": "\"70815\"",
                "address_state": "\"LA\"",
                "latitude": 30.4718056,
                "longitude": -91.09651,
                "name": "\"BATON ROUGE\"",
                "opening_hours": "\"Mo-Fr 07:00-17:00\"",
                "phone": "\"2259262084\"",
                "shop": "\"trade\"",
                "street_address": "\"8675 S CHOCTAW DR\"",
                "trade": "\"building_supplies\"",
                "website": "\"https://www.84lumber.com/store-locator/store-detail/?storeId=2712\""
            },
            {
                "__id": 101,
                "address_city": "\"ITHACA\"",
                "address_postcode": "\"14850\"",
                "address_state": "\"NY\"",
                "latitude": 42.4797363,
                "longitude": -76.43105,
                "name": "\"ITHACA\"",
                "opening_hours": "\"Mo-Fr 07:00-18:00; Sa 08:00-12:00\"",
                "phone": "\"6072578484\"",
                "shop": "\"trade\"",
                "street_address": "\"1818 HANSHAW ROAD\"",
                "trade": "\"building_supplies\"",
                "website": "\"https://www.84lumber.com/store-locator/store-detail/?storeId=642\""
            },
            {
                "__id": 122,
                "address_city": "\"KELLER\"",
                "address_postcode": "\"23401\"",
                "address_state": "\"VA\"",
                "latitude": 37.61961,
                "longitude": -75.7619553,
                "name": "\"KELLER\"",
                "opening_hours": "\"Mo-Fr 07:00-18:00; Sa 08:00-17:00; Su 09:00-15:00\"",
                "phone": "\"7577879484\"",
                "shop": "\"trade\"",
                "street_address": "\"18308 N R NORTH STREET\"",
                "trade": "\"building_supplies\"",
                "website": "\"https://www.84lumber.com/store-locator/store-detail/?storeId=1721\""
            },
            {
                "__id": 222,
                "address_city": "\"ALAMOGORDO\"",
                "address_postcode": "\"88310\"",
                "address_state": "\"NM\"",
                "latitude": 32.9368134,
                "longitude": -105.96521,
                "name": "\"ALAMOGORDO\"",
                "opening_hours": "\"Mo-Fr 07:00-17:00\"",
                "phone": "\"5754343850\"",
                "shop": "\"trade\"",
                "street_address": "\"3401 N WHITE SANDS BLV\"",
                "trade": "\"building_supplies\"",
                "website": "\"https://www.84lumber.com/store-locator/store-detail/?storeId=3603\""
            },
            {
                "__id": 30,
                "address_city": "\"MOOREFIELD\"",
                "address_postcode": "\"26836\"",
                "address_state": "\"WV\"",
                "latitude": 39.0469437,
                "longitude": -78.98077,
                "name": "\"MOOREFIELD\"",
                "opening_hours": "\"Mo-Fr 07:00-18:00; Sa 08:00-16:00; Su 09:00-16:00\"",
                "phone": "\"3045386612\"",
                "shop": "\"trade\"",
                "street_address": "\"41 FISHER ROAD\"",
                "trade": "\"building_supplies\"",
                "website": "\"https://www.84lumber.com/store-locator/store-detail/?storeId=434\""
            },
            {
                "__id": 22,
                "address_city": "\"CAMBRIDGE\"",
                "address_postcode": "\"43725\"",
                "address_state": "\"OH\"",
                "latitude": 40.00121,
                "longitude": -81.5743256,
                "name": "\"CAMBRIDGE\"",
                "opening_hours": "\"Mo-Fr 07:00-18:00; Sa 07:00-16:00; Su 08:00-15:00\"",
                "phone": "\"7404395574\"",
                "shop": "\"trade\"",
                "street_address": "\"2251 SOUTHGATE PARKWAY\"",
                "trade": "\"building_supplies\"",
                "website": "\"https://www.84lumber.com/store-locator/store-detail/?storeId=326\""
            },
            {
                "__id": 211,
                "address_city": "\"GARLAND\"",
                "address_postcode": "\"75041\"",
                "address_state": "\"TX\"",
                "latitude": 32.8943977,
                "longitude": -96.6732,
                "name": "\"GARLAND\"",
                "opening_hours": "\"Mo-Fr 07:00-17:00\"",
                "phone": "\"9728648411\"",
                "shop": "\"trade\"",
                "street_address": "\"2906 W MILLER RD\"",
                "trade": "\"building_supplies\"",
                "website": "\"https://www.84lumber.com/store-locator/store-detail/?storeId=1929\""
            },
            {
                "__id": 2,
                "address_city": "\"BRIDGEVILLE\"",
                "address_postcode": "\"15107\"",
                "address_state": "\"PA\"",
                "latitude": 40.3575325,
                "longitude": -80.13137,
                "name": "\"BRIDGEVILLE\"",
                "opening_hours": "\"Mo-Fr 07:00-18:00; Sa 08:00-16:00\"",
                "phone": "\"4122211188\"",
                "shop": "\"trade\"",
                "street_address": "\"8400 PRESTO SYGAN\"",
                "trade": "\"building_supplies\"",
                "website": "\"https://www.84lumber.com/store-locator/store-detail/?storeId=217\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "cookout": {
        "__id": 9,
        "id": "cookout",
        "name": "Cook Out",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
        "rowLength": 326,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763487,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 197,
                "address_city": "\"Tarboro\"",
                "address_country": "\"US\"",
                "address_full": "\"104 River Oaks Dr\"",
                "address_postcode": "\"27886\"",
                "address_state": "\"NC\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"american\"",
                "latitude": 35.882983,
                "longitude": -77.547525,
                "name": "\"TARBORO\"",
                "opening_hours": "\"Mo-Th 10:30-03:00; Fr-Sa 10:30-04:00; Su 10:30-03:00\"",
                "takeaway": "\"yes\"",
                "website": "\"\""
            },
            {
                "__id": 304,
                "address_city": "\"Hinesville\"",
                "address_country": "\"US\"",
                "address_full": "\"705 W Oglethorpe Hwy\"",
                "address_postcode": "\"31313\"",
                "address_state": "\"GA\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"american\"",
                "latitude": 31.822584,
                "longitude": -81.599528,
                "name": "\"Hinesville\"",
                "opening_hours": "\"Mo-Th 10:30-03:00; Fr-Sa 10:30-04:00; Su 10:30-03:00\"",
                "takeaway": "\"yes\"",
                "website": "\"\""
            },
            {
                "__id": 149,
                "address_city": "\"Durham\"",
                "address_country": "\"US\"",
                "address_full": "\"2103 Allendown Drive\"",
                "address_postcode": "\"27713\"",
                "address_state": "\"NC\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"american\"",
                "latitude": 35.899145,
                "longitude": -78.895939,
                "name": "\"RTP\"",
                "opening_hours": "\"Mo-Th 10:30-03:30; Fr-Sa 10:30-04:00; Su 10:30-03:30\"",
                "takeaway": "\"yes\"",
                "website": "\"\""
            },
            {
                "__id": 208,
                "address_city": "\"Lexington\"",
                "address_country": "\"US\"",
                "address_full": "\"515 W Main St\"",
                "address_postcode": "\"29072\"",
                "address_state": "\"SC\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"american\"",
                "latitude": 33.985624,
                "longitude": -81.246459,
                "name": "\"Lexington\"",
                "opening_hours": "\"Mo-Th 10:30-02:00; Fr-Sa 10:30-03:30; Su 10:30-02:00\"",
                "takeaway": "\"yes\"",
                "website": "\"https://www.facebook.com/Cook-Out-Lexington-SC-284045831758011/\""
            },
            {
                "__id": 126,
                "address_city": "\"Oxford\"",
                "address_country": "\"US\"",
                "address_full": "\"525 E. Industry Dr.\"",
                "address_postcode": "\"27565\"",
                "address_state": "\"NC\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"american\"",
                "latitude": 36.298627,
                "longitude": -78.584446,
                "name": "\"Oxford\"",
                "opening_hours": "\"Mo-Th 10:30-03:00; Fr-Sa 10:30-04:00; Su 10:30-03:00\"",
                "takeaway": "\"yes\"",
                "website": "\"\""
            },
            {
                "__id": 258,
                "address_city": "\"Florence\"",
                "address_country": "\"US\"",
                "address_full": "\"570 S Court St\"",
                "address_postcode": "\"35630\"",
                "address_state": "\"AL\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"american\"",
                "latitude": 34.795797,
                "longitude": -87.673774,
                "name": "\"FLORENCE\"",
                "opening_hours": "\"Mo-Th 10:30-03:00; Fr-Sa 10:30-04:00; Su 10:30-03:00\"",
                "takeaway": "\"yes\"",
                "website": "\"\""
            },
            {
                "__id": 231,
                "address_city": "\"Winterville\"",
                "address_country": "\"US\"",
                "address_full": "\"4145 S Memorial Dr\"",
                "address_postcode": "\"28590\"",
                "address_state": "\"NC\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"american\"",
                "latitude": 35.549855,
                "longitude": -77.404662,
                "name": "\"Winterville\"",
                "opening_hours": "\"Mo-Th 10:30-03:00; Fr-Sa 10:30-04:00; Su 10:30-03:00\"",
                "takeaway": "\"yes\"",
                "website": "\"\""
            },
            {
                "__id": 98,
                "address_city": "\"Gastonia\"",
                "address_country": "\"US\"",
                "address_full": "\"2810 East Franklin Blvd.\"",
                "address_postcode": "\"28056\"",
                "address_state": "\"NC\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"american\"",
                "latitude": 35.260405,
                "longitude": -81.12998,
                "name": "\"Franklin Blvd\"",
                "opening_hours": "\"Mo-Th 10:30-02:00; Fr-Sa 10:30-03:30; Su 10:30-02:00\"",
                "takeaway": "\"yes\"",
                "website": "\"\""
            },
            {
                "__id": 67,
                "address_city": "\"Greensboro\"",
                "address_country": "\"US\"",
                "address_full": "\"5406 Sapp Road\"",
                "address_postcode": "\"27409\"",
                "address_state": "\"NC\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"american\"",
                "latitude": 36.055689,
                "longitude": -79.905148,
                "name": "\"Wendover\"",
                "opening_hours": "\"Mo-Th 10:30-03:00; Fr-Sa 10:30-04:00; Su 10:30-03:00\"",
                "takeaway": "\"yes\"",
                "website": "\"\""
            },
            {
                "__id": 33,
                "address_city": "\"COLLINSVILLE\"",
                "address_country": "\"US\"",
                "address_full": "\"2439 VIRGINIA AVE\"",
                "address_postcode": "\"24078\"",
                "address_state": "\"VA\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"american\"",
                "latitude": 36.713881,
                "longitude": -79.909103,
                "name": "\"Collinsville\"",
                "opening_hours": "\"Mo-Th 10:30-03:00; Fr-Sa 10:30-04:00; Su 10:30-03:00\"",
                "takeaway": "\"yes\"",
                "website": "\"\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "alnatura_de": {
        "__id": 10,
        "id": "alnatura_de",
        "name": "Alnatura",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_street', 'latitude', 'longitude', 'name', 'opening_hours', 'organic', 'phone', 'shop']",
        "rowLength": 148,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763439,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of alnatura_de locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 85,
                "address_city": "\"Leipzig\"",
                "address_country": "\"Deutschland\"",
                "address_postcode": "\"4109\"",
                "address_street": "\"Willy-Brandt-Platz 7\"",
                "latitude": 51.344048,
                "longitude": 12.382224,
                "name": "\"Alnatura Super Natur Markt\"",
                "opening_hours": "\"Mo-Sa 08:00-21:00\"",
                "organic": "\"only\"",
                "phone": "\"0341/30868416\"",
                "shop": "\"supermarket\""
            },
            {
                "__id": 113,
                "address_city": "\"Hannover\"",
                "address_country": "\"Deutschland\"",
                "address_postcode": "\"30171\"",
                "address_street": "\"An der Weide 27-31\"",
                "latitude": 52.361514,
                "longitude": 9.764293,
                "name": "\"Alnatura Super Natur Markt\"",
                "opening_hours": "\"Mo-Sa 08:00-21:00\"",
                "organic": "\"only\"",
                "phone": "\"0511-89833772\"",
                "shop": "\"supermarket\""
            },
            {
                "__id": 34,
                "address_city": "\"Berlin\"",
                "address_country": "\"Deutschland\"",
                "address_postcode": "\"13465\"",
                "address_street": "\"Ludolfinger Platz 8a\"",
                "latitude": 52.632404,
                "longitude": 13.286874,
                "name": "\"Alnatura Super Natur Markt\"",
                "opening_hours": "\"Mo-Sa 08:00-20:00\"",
                "organic": "\"only\"",
                "phone": "\"030/40104139\"",
                "shop": "\"supermarket\""
            },
            {
                "__id": 128,
                "address_city": "\"Eschborn\"",
                "address_country": "\"Deutschland\"",
                "address_postcode": "\"65760\"",
                "address_street": "\"Ginnheimer Str. 18\"",
                "latitude": 50.142462,
                "longitude": 8.581421,
                "name": "\"Alnatura Super Natur Markt\"",
                "opening_hours": "\"Mo-Sa 08:00-21:00\"",
                "organic": "\"only\"",
                "phone": "\"06196/8827989\"",
                "shop": "\"supermarket\""
            },
            {
                "__id": 118,
                "address_city": "\"Mannheim\"",
                "address_country": "\"Deutschland\"",
                "address_postcode": "\"68161\"",
                "address_street": "\"N7, 12\"",
                "latitude": 49.48331,
                "longitude": 8.47324,
                "name": "\"Alnatura Super Natur Markt\"",
                "opening_hours": "\"Mo-Sa 07:00-21:00\"",
                "organic": "\"only\"",
                "phone": "\"0621/152690\"",
                "shop": "\"supermarket\""
            },
            {
                "__id": 106,
                "address_city": "\"Ettlingen\"",
                "address_country": "\"Deutschland\"",
                "address_postcode": "\"76275\"",
                "address_street": "\"Sch\u00f6llbronner Str. 21-25\"",
                "latitude": 48.938851,
                "longitude": 8.411982,
                "name": "\"Alnatura Super Natur Markt\"",
                "opening_hours": "\"Mo-Sa 08:18-20:00\"",
                "organic": "\"only\"",
                "phone": "\"07243/9468043\"",
                "shop": "\"supermarket\""
            },
            {
                "__id": 48,
                "address_city": "\"M\u00fcnchen\"",
                "address_country": "\"Deutschland\"",
                "address_postcode": "\"80939\"",
                "address_street": "\"Ingolst\u00e4dter Stra\u00dfe 172\"",
                "latitude": 48.20503,
                "longitude": 11.58646,
                "name": "\"Alnatura Super Natur Markt\"",
                "opening_hours": "\"Mo-Sa 08:00-20:00\"",
                "organic": "\"only\"",
                "phone": "\"089/31907239\"",
                "shop": "\"supermarket\""
            },
            {
                "__id": 26,
                "address_city": "\"M\u00fcnchen\"",
                "address_country": "\"Deutschland\"",
                "address_postcode": "\"81667\"",
                "address_street": "\"Wei\u00dfenburger Stra\u00dfe 20 \"",
                "latitude": 48.128382,
                "longitude": 11.597732,
                "name": "\"Alnatura Super Natur Markt\"",
                "opening_hours": "\"Mo-Sa 07:00-20:00\"",
                "organic": "\"only\"",
                "phone": "\"089/20329188\"",
                "shop": "\"supermarket\""
            },
            {
                "__id": 19,
                "address_city": "\"Unterhaching\"",
                "address_country": "\"Deutschland\"",
                "address_postcode": "\"82008\"",
                "address_street": "\"Karl-Hermann-Weg 4\"",
                "latitude": 48.05541,
                "longitude": 11.60558,
                "name": "\"Alnatura Super Natur Markt\"",
                "opening_hours": "\"Mo-Sa 08:00-20:00\"",
                "organic": "\"only\"",
                "phone": "\"089/23716961\"",
                "shop": "\"supermarket\""
            },
            {
                "__id": 100,
                "address_city": "\"Stuttgart\"",
                "address_country": "\"Deutschland\"",
                "address_postcode": "\"70192\"",
                "address_street": "\"Am H\u00f6henpark 4\"",
                "latitude": 48.799747,
                "longitude": 9.17102,
                "name": "\"Alnatura Super Natur Markt\"",
                "opening_hours": "\"Mo-Sa 08:00-20:00\"",
                "organic": "\"only\"",
                "phone": "\"0711/25974742\"",
                "shop": "\"supermarket\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "carrabbasitaliangrill": {
        "__id": 11,
        "id": "carrabbasitaliangrill",
        "name": "Carrabba's Italian Grill",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'amenity', 'contact_email', 'cuisine', 'image', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'street_address', 'website']",
        "rowLength": 220,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763440,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of carrabbasitaliangrill locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 36,
                "address_city": "\"San Antonio\"",
                "address_country": "\"US\"",
                "address_postcode": "\"78230\"",
                "address_state": "\"TX\"",
                "amenity": "\"restaurant\"",
                "contact_email": "\"CA4405@carrabbas.com\"",
                "cuisine": "\"italian\"",
                "image": "\"https://locations.carrabbas.com/permanent-b0b701/assets/images/logo_carrabbas.217a7fd1.png\"",
                "latitude": 29.558156,
                "longitude": -98.5880277,
                "name": "\"Carrabba's San Antonio, TX\"",
                "opening_hours": "\"Mo-Th 11:00-22:00; Fr-Sa 11:00-23:00; Su 11:00-22:00\"",
                "phone": "\"(210) 694-4191\"",
                "street_address": "\"12507 I-10 West\"",
                "website": "\"https://locations.carrabbas.com/texas/san-antonio/12507-i-10-west\""
            },
            {
                "__id": 82,
                "address_city": "\"Wilmington\"",
                "address_country": "\"US\"",
                "address_postcode": "\"28403\"",
                "address_state": "\"NC\"",
                "amenity": "\"restaurant\"",
                "contact_email": "\"CA8404@carrabbas.com\"",
                "cuisine": "\"italian\"",
                "image": "\"https://locations.carrabbas.com/permanent-b0b701/assets/images/logo_carrabbas.217a7fd1.png\"",
                "latitude": 34.2450609,
                "longitude": -77.8731273,
                "name": "\"Carrabba's Wilmington, NC\"",
                "opening_hours": "\"Mo-Th 11:00-22:00; Fr-Sa 11:00-23:00; Su 11:00-22:00\"",
                "phone": "\"(910) 794-9094\"",
                "street_address": "\"15 Van Campen Blvd\"",
                "website": "\"https://locations.carrabbas.com/north-carolina/wilmington/15-van-campen-blvd\""
            },
            {
                "__id": 39,
                "address_city": "\"Hurst\"",
                "address_country": "\"US\"",
                "address_postcode": "\"76053\"",
                "address_state": "\"TX\"",
                "amenity": "\"restaurant\"",
                "contact_email": "\"CA9419@carrabbas.com\"",
                "cuisine": "\"italian\"",
                "image": "\"https://locations.carrabbas.com/permanent-b0b701/assets/images/logo_carrabbas.217a7fd1.png\"",
                "latitude": 32.8300206515212,
                "longitude": -97.20026016149136,
                "name": "\"Carrabba's Hurst, TX\"",
                "opening_hours": "\"Mo-Th 11:00-22:00; Fr-Sa 11:00-23:00; Su 11:00-22:00\"",
                "phone": "\"(817) 595-3345\"",
                "street_address": "\"1101 Melbourne Rd Suite 6200\"",
                "website": "\"https://locations.carrabbas.com/texas/hurst/1101-melbourne-rd\""
            },
            {
                "__id": 216,
                "address_city": "\"Chandler\"",
                "address_country": "\"US\"",
                "address_postcode": "\"85226\"",
                "address_state": "\"AZ\"",
                "amenity": "\"restaurant\"",
                "contact_email": "\"CA5303@carrabbas.com\"",
                "cuisine": "\"italian\"",
                "image": "\"https://locations.carrabbas.com/permanent-b0b701/assets/images/logo_carrabbas.217a7fd1.png\"",
                "latitude": 33.3211424,
                "longitude": -111.9697599,
                "name": "\"Carrabba's Chandler, AZ\"",
                "opening_hours": "\"Mo-Th 15:00-21:00; Fr-Sa 12:00-22:00; Su 11:00-21:00\"",
                "phone": "\"(480) 785-8586\"",
                "street_address": "\"1060 North 54th Street\"",
                "website": "\"https://locations.carrabbas.com/arizona/chandler/1060-north-54th-street\""
            },
            {
                "__id": 161,
                "address_city": "\"Tallahassee\"",
                "address_country": "\"US\"",
                "address_postcode": "\"32308\"",
                "address_state": "\"FL\"",
                "amenity": "\"restaurant\"",
                "contact_email": "\"CA6021@carrabbas.com\"",
                "cuisine": "\"italian\"",
                "image": "\"https://locations.carrabbas.com/permanent-b0b701/assets/images/logo_carrabbas.217a7fd1.png\"",
                "latitude": 30.4902143,
                "longitude": -84.2403513,
                "name": "\"Carrabba's Tallahassee, FL\"",
                "opening_hours": "\"Mo-Th 11:00-22:00; Fr-Sa 11:00-23:00; Su 11:00-21:00\"",
                "phone": "\"(850) 297-1100\"",
                "street_address": "\"2779 Capital Circle NE\"",
                "website": "\"https://locations.carrabbas.com/florida/tallahassee/2779-capital-circle-ne\""
            },
            {
                "__id": 210,
                "address_city": "\"Aurora\"",
                "address_country": "\"US\"",
                "address_postcode": "\"80014\"",
                "address_state": "\"CO\"",
                "amenity": "\"restaurant\"",
                "contact_email": "\"test@test.com\"",
                "cuisine": "\"italian\"",
                "image": "\"https://locations.carrabbas.com/permanent-b0b701/assets/images/logo_carrabbas.217a7fd1.png\"",
                "latitude": 39.678324212276216,
                "longitude": -104.8274311722944,
                "name": "\"Carrabba's Aurora, CO\"",
                "opening_hours": "\"Mo-Th 16:00-21:00; Fr 11:30-22:00; Sa 12:00-22:00; Su 11:30-20:30\"",
                "phone": "\"(303) 338-8600\"",
                "street_address": "\"2088 S Abilene Street\"",
                "website": "\"https://locations.carrabbas.com/colorado/aurora/2088-s-abilene-street\""
            },
            {
                "__id": 184,
                "address_city": "\"Orlando\"",
                "address_country": "\"US\"",
                "address_postcode": "\"32812\"",
                "address_state": "\"FL\"",
                "amenity": "\"restaurant\"",
                "contact_email": "\"CA6067@carrabbas.com\"",
                "cuisine": "\"italian\"",
                "image": "\"https://locations.carrabbas.com/permanent-b0b701/assets/images/logo_carrabbas.217a7fd1.png\"",
                "latitude": 28.4563436,
                "longitude": -81.3109725,
                "name": "\"Carrabba's Orlando, FL\"",
                "opening_hours": "\"Mo-Th 11:00-22:00; Fr-Sa 11:00-23:00; Su 11:00-22:00\"",
                "phone": "\"(407) 251-8479\"",
                "street_address": "\"5475 Gateway Village Circle Suite 103\"",
                "website": "\"https://locations.carrabbas.com/florida/orlando/5475-gateway-village-circle\""
            },
            {
                "__id": 189,
                "address_city": "\"Miami\"",
                "address_country": "\"US\"",
                "address_postcode": "\"33172\"",
                "address_state": "\"FL\"",
                "amenity": "\"restaurant\"",
                "contact_email": "\"CA6066@carrabbas.com\"",
                "cuisine": "\"italian\"",
                "image": "\"https://locations.carrabbas.com/permanent-b0b701/assets/images/logo_carrabbas.217a7fd1.png\"",
                "latitude": 25.7695912,
                "longitude": -80.3463318,
                "name": "\"Carrabba's Miami, FL\"",
                "opening_hours": "\"Mo-Th 11:00-21:00; Fr-Sa 11:00-22:00; Su 11:00-21:00\"",
                "phone": "\"(305) 226-9430\"",
                "street_address": "\"9231 W Flagler Street\"",
                "website": "\"https://locations.carrabbas.com/florida/miami/9231-w-flagler-street\""
            },
            {
                "__id": 183,
                "address_city": "\"Orlando\"",
                "address_country": "\"US\"",
                "address_postcode": "\"32819\"",
                "address_state": "\"FL\"",
                "amenity": "\"restaurant\"",
                "contact_email": "\"CA6062@carrabbas.com\"",
                "cuisine": "\"italian\"",
                "image": "\"https://locations.carrabbas.com/permanent-b0b701/assets/images/logo_carrabbas.217a7fd1.png\"",
                "latitude": 28.4442844,
                "longitude": -81.4699864,
                "name": "\"Carrabba's Orlando, FL\"",
                "opening_hours": "\"Mo-Th 11:00-22:00; Fr-Sa 11:00-23:00; Su 11:00-21:00\"",
                "phone": "\"(407) 351-5494\"",
                "street_address": "\"8355 International Drive\"",
                "website": "\"https://locations.carrabbas.com/florida/orlando/8355-international-drive\""
            },
            {
                "__id": 185,
                "address_city": "\"Ocala\"",
                "address_country": "\"US\"",
                "address_postcode": "\"34474\"",
                "address_state": "\"FL\"",
                "amenity": "\"restaurant\"",
                "contact_email": "\"CA6028@carrabbas.com\"",
                "cuisine": "\"italian\"",
                "image": "\"https://locations.carrabbas.com/permanent-b0b701/assets/images/logo_carrabbas.217a7fd1.png\"",
                "latitude": 29.170163,
                "longitude": -82.1630542,
                "name": "\"Carrabba's Ocala, FL\"",
                "opening_hours": "\"Mo-Th 11:00-22:00; Fr-Sa 11:00-23:00; Su 11:00-21:00\"",
                "phone": "\"(352) 867-0240\"",
                "street_address": "\"2370 SW College Rd\"",
                "website": "\"https://locations.carrabbas.com/florida/ocala/2370-sw-college-rd\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "national": {
        "__id": 12,
        "id": "national",
        "name": "National",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'latitude', 'longitude', 'name', 'official_name', 'phone']",
        "rowLength": 1506,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763441,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of national locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 596,
                "address_city": "\"Rio De Janeiro\"",
                "address_country": "\"BR\"",
                "address_full": "\"['Av Vinte De Janeiro']\"",
                "address_postcode": "\"21941-570\"",
                "address_state": "\"\"",
                "amenity": "\"car_rental\"",
                "latitude": -22.816705,
                "longitude": -43.238753,
                "name": "\"Rio De Janeiro Galeao Airport\"",
                "official_name": "\"National Car Rental\"",
                "phone": "\"08009792020\""
            },
            {
                "__id": 1400,
                "address_city": "\"Dominical\"",
                "address_country": "\"CR\"",
                "address_full": "\"['100m East From Main Entrance', 'DOMINICAL BEACH']\"",
                "address_postcode": "\"60504\"",
                "address_state": "\"\"",
                "amenity": "\"car_rental\"",
                "latitude": 9.256285,
                "longitude": -83.863334,
                "name": "\"Dominical\"",
                "official_name": "\"National Car Rental\"",
                "phone": "\"27874228\""
            },
            {
                "__id": 333,
                "address_city": "\"Uzein\"",
                "address_country": "\"FR\"",
                "address_full": "\"['Enterprise Rent A Car', 'AEROPORT DE PAU PYRENEES']\"",
                "address_postcode": "\"64230\"",
                "address_state": "\"B\"",
                "amenity": "\"car_rental\"",
                "latitude": 43.380828,
                "longitude": -0.415461,
                "name": "\"Pau Pyrenees Airport\"",
                "official_name": "\"National Car Rental\"",
                "phone": "\"0559608867\""
            },
            {
                "__id": 238,
                "address_city": "\"Sacramento\"",
                "address_country": "\"US\"",
                "address_full": "\"['6327 Aviation Dr']\"",
                "address_postcode": "\"95837\"",
                "address_state": "\"CA\"",
                "amenity": "\"car_rental\"",
                "latitude": 38.68163,
                "longitude": -121.58726,
                "name": "\"Sacramento International Airport\"",
                "official_name": "\"National Car Rental\"",
                "phone": "\"8338235642\""
            },
            {
                "__id": 336,
                "address_city": "\"Palencia\"",
                "address_country": "\"ES\"",
                "address_full": "\"['Estacion De Tren Palencia', 'PLAZA DE LOS JARDINILLOS, S N']\"",
                "address_postcode": "\"34003\"",
                "address_state": "\"P\"",
                "amenity": "\"car_rental\"",
                "latitude": 42.01551,
                "longitude": -4.53386,
                "name": "\"Palencia Estacion Tren\"",
                "official_name": "\"National Car Rental\"",
                "phone": "\"979307949\""
            },
            {
                "__id": 368,
                "address_city": "\"Puertollano\"",
                "address_country": "\"ES\"",
                "address_full": "\"['Enterprise Rent A Car', 'ESTACION AVE PUERTOLLANO']\"",
                "address_postcode": "\"13500\"",
                "address_state": "\"LE\"",
                "amenity": "\"car_rental\"",
                "latitude": 38.691188,
                "longitude": -4.11044,
                "name": "\"Puertollano Train Station\"",
                "official_name": "\"National Car Rental\"",
                "phone": "\"926430950\""
            },
            {
                "__id": 1131,
                "address_city": "\"Quito\"",
                "address_country": "\"EC\"",
                "address_full": "\"['Parroquia Tababela S N', 'VIA A YARUQUI']\"",
                "address_postcode": "\"170907\"",
                "address_state": "\"\"",
                "amenity": "\"car_rental\"",
                "latitude": -0.136442,
                "longitude": -78.358821,
                "name": "\"Quito International Airport\"",
                "official_name": "\"National Car Rental\"",
                "phone": "\"23932120\""
            },
            {
                "__id": 47,
                "address_city": "\"Fletcher\"",
                "address_country": "\"US\"",
                "address_full": "\"['61 Terminal Dr Ste 21']\"",
                "address_postcode": "\"28732\"",
                "address_state": "\"NC\"",
                "amenity": "\"car_rental\"",
                "latitude": 35.407249,
                "longitude": -82.559575,
                "name": "\"Asheville Regional Airport\"",
                "official_name": "\"National Car Rental\"",
                "phone": "\"8443662654\""
            },
            {
                "__id": 817,
                "address_city": "\"Balneario Camboriu\"",
                "address_country": "\"BR\"",
                "address_full": "\"['Rua 3000 685']\"",
                "address_postcode": "\"88330 336\"",
                "address_state": "\"\"",
                "amenity": "\"car_rental\"",
                "latitude": -27.000504,
                "longitude": -48.630542,
                "name": "\"Balneario Camboriu\"",
                "official_name": "\"National Car Rental\"",
                "phone": "\"08009792020\""
            },
            {
                "__id": 1212,
                "address_city": "\"Mesaria\"",
                "address_country": "\"GR\"",
                "address_full": "\"['Louria Region', 'MESARIA']\"",
                "address_postcode": "\"84700\"",
                "address_state": "\"\"",
                "amenity": "\"car_rental\"",
                "latitude": 36.406034,
                "longitude": 25.459256,
                "name": "\"Santorini Airport\"",
                "official_name": "\"National Car Rental\"",
                "phone": "\"2286037000\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "south_carolina": {
        "__id": 13,
        "id": "south_carolina",
        "name": "South Carolina Government Locations",
        "columnNames": "['address_country', 'address_full', 'address_postcode', 'category', 'latitude', 'longitude', 'name', 'phone']",
        "rowLength": 489,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763442,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of south_carolina locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 37,
                "address_country": "\"US\"",
                "address_full": "\"Oconee Station Building, 500 Oconee Station Rd, Walhalla, SC \"",
                "address_postcode": "\"29691 \"",
                "category": "\"State Park\"",
                "latitude": 34.8463949,
                "longitude": -83.0708156,
                "name": "\"Oconee Station State Park\"",
                "phone": "\"864-638-0079\""
            },
            {
                "__id": 68,
                "address_country": "\"US\"",
                "address_full": "\"509 S Dargan St, Florence, SC \"",
                "address_postcode": "\"29506\"",
                "category": "\"Library\"",
                "latitude": 34.1897308,
                "longitude": -79.7664475,
                "name": "\"Florence County Library System\"",
                "phone": "\"843-662-8424\""
            },
            {
                "__id": 140,
                "address_country": "\"US\"",
                "address_full": "\"122 Park Rd, Lexington, SC\"",
                "address_postcode": "\"29072\"",
                "category": "\"Department of Motor Vehicles\"",
                "latitude": 33.9970327568,
                "longitude": -81.2720561656,
                "name": "\"Lexington DMV\"",
                "phone": "\"803-356-8537\""
            },
            {
                "__id": 459,
                "address_country": "\"US\"",
                "address_full": "\"1003 US-52, Moncks Corner, SC \"",
                "address_postcode": "\"29461\"",
                "category": "\"Library\"",
                "latitude": 33.164,
                "longitude": -80.01,
                "name": "\"Berkeley County Library - Moncks Corner\"",
                "phone": "\"(843) 719-4223\""
            },
            {
                "__id": 104,
                "address_country": "\"US\"",
                "address_full": "\"107 Knox St, Clover, SC \"",
                "address_postcode": "\"29710\"",
                "category": "\"Library\"",
                "latitude": 35.1091282,
                "longitude": -81.2265393,
                "name": "\"Clover Public Library\"",
                "phone": "\"803-222-3474\""
            },
            {
                "__id": 483,
                "address_country": "\"US\"",
                "address_full": "\"3416, 1100 Friend St, Newberry, SC\"",
                "address_postcode": "\"29108\"",
                "category": "\"Library\"",
                "latitude": 34.283,
                "longitude": -81.616,
                "name": "\"Newberry County Library System, Hal Kohn Memorial Library\"",
                "phone": "\"(803) 276-0854\""
            },
            {
                "__id": 386,
                "address_country": "\"US\"",
                "address_full": "\"9 Hillside Road Warrenville, SC\"",
                "address_postcode": "\" 29851\"",
                "category": "\"Library\"",
                "latitude": 33.53,
                "longitude": -81.817,
                "name": "\"Midland Valley Branch Library\"",
                "phone": "\"(803) 593-7379\""
            },
            {
                "__id": 432,
                "address_country": "\"US\"",
                "address_full": "\"800 West Butler Road Greenville, SC \"",
                "address_postcode": "\"29607\"",
                "category": "\"Library\"",
                "latitude": 34.826,
                "longitude": -82.341,
                "name": "\"Mauldin Library\"",
                "phone": "\"864-277-7397\""
            },
            {
                "__id": 475,
                "address_country": "\"US\"",
                "address_full": "\"203 Armory Street Batesburg, SC \"",
                "address_postcode": "\"29006\"",
                "category": "\"Library\"",
                "latitude": 33.873,
                "longitude": -81.552,
                "name": "\"BATESBURG-LEESVILLE\"",
                "phone": "\"(803) 532-9223\""
            },
            {
                "__id": 173,
                "address_country": "\"US\"",
                "address_full": "\"5200 E Jim Bilton Blvd St, George, SC\"",
                "address_postcode": "\"29477\"",
                "category": "\"Court House\"",
                "latitude": 33.1825124,
                "longitude": -80.5590356,
                "name": "\"Dorchester County Courthouse\"",
                "phone": "\"843-563-0160\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "cex": {
        "__id": 14,
        "id": "cex",
        "name": "CeX",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'image', 'latitude', 'longitude', 'name', 'opening_hours', 'shop', 'street_address', 'website']",
        "rowLength": 391,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763442,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of cex locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 280,
                "address_city": "\"London\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"NW6 6JE\"",
                "address_state": "\"London\"",
                "image": "\"https://uk.static.webuy.com/store_images/182/182.jpg\"",
                "latitude": 51.53837,
                "longitude": -0.19387,
                "name": "\"Kilburn\"",
                "opening_hours": "\"Mo-Sa 10:00-19:00; Su 11:00-17:00\"",
                "shop": "\"electronics\"",
                "street_address": "\"91 Kilburn High Road, Hampstead\"",
                "website": "\"https://uk.webuy.com/site/storeDetail/?branchId=182\""
            },
            {
                "__id": 143,
                "address_city": "\"Truro\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"TR1 2SQ\"",
                "address_state": "\"Cornwall\"",
                "image": "\"https://uk.static.webuy.com/store_images/3003/3003.jpg\"",
                "latitude": 50.26337,
                "longitude": -5.05353,
                "name": "\"Truro\"",
                "opening_hours": "\"Mo-Sa 09:00-18:00; Su 11:00-16:00\"",
                "shop": "\"electronics\"",
                "street_address": "\"3 River Street\"",
                "website": "\"https://uk.webuy.com/site/storeDetail/?branchId=3003\""
            },
            {
                "__id": 342,
                "address_city": "\"Exeter\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"EX4 3HP\"",
                "address_state": "\"Devon\"",
                "image": "\"https://uk.static.webuy.com/store_images/104/104.jpg\"",
                "latitude": 50.72355,
                "longitude": -3.53263,
                "name": "\"Exeter\"",
                "opening_hours": "\"Mo-Sa 09:00-19:00; Su 10:30-16:30\"",
                "shop": "\"electronics\"",
                "street_address": "\"Unit 6-7, Guildhall Shopping Centre\"",
                "website": "\"https://uk.webuy.com/site/storeDetail/?branchId=104\""
            },
            {
                "__id": 270,
                "address_city": "\"Banbury\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"OX16 5JJ\"",
                "address_state": "\"Oxfordshire\"",
                "image": "\"https://uk.static.webuy.com/store_images/192/192.jpg\"",
                "latitude": 52.0607,
                "longitude": -1.3378,
                "name": "\"Banbury\"",
                "opening_hours": "\"Mo-Fr 09:30-18:00; Sa 09:00-18:00; Su 10:30-16:30\"",
                "shop": "\"electronics\"",
                "street_address": "\"67 High Street\"",
                "website": "\"https://uk.webuy.com/site/storeDetail/?branchId=192\""
            },
            {
                "__id": 152,
                "address_city": "\"Orpington\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"BR6 0TW\"",
                "address_state": "\"Kent\"",
                "image": "\"https://uk.static.webuy.com/store_images/335/335.jpg\"",
                "latitude": 51.37743,
                "longitude": 0.1009,
                "name": "\"Orpington\"",
                "opening_hours": "\"Mo-Fr 09:30-18:00; Sa 09:00-18:00; Su 10:30-17:00\"",
                "shop": "\"electronics\"",
                "street_address": "\"Unit 49, The Walnuts Shopping Centre\"",
                "website": "\"https://uk.webuy.com/site/storeDetail/?branchId=335\""
            },
            {
                "__id": 245,
                "address_city": "\"Bayswater\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"W2 4SJ\"",
                "address_state": "\"London\"",
                "image": "\"https://uk.static.webuy.com/store_images/219/219.jpg\"",
                "latitude": 51.512995,
                "longitude": -0.187646,
                "name": "\"Bayswater\"",
                "opening_hours": "\"Mo-Sa 10:00-19:00; Su 11:00-18:00\"",
                "shop": "\"electronics\"",
                "street_address": "\"109 Queensway\"",
                "website": "\"https://uk.webuy.com/site/storeDetail/?branchId=219\""
            },
            {
                "__id": 148,
                "address_city": "\"Waltham Cross\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"EN8 7BW\"",
                "address_state": "\"Hertfordshire\"",
                "image": "\"https://uk.static.webuy.com/store_images/340/340.jpg\"",
                "latitude": 51.68631,
                "longitude": -0.03217,
                "name": "\"Waltham Cross\"",
                "opening_hours": "\"Mo-Sa 09:00-18:00; Su 10:30-16:30\"",
                "shop": "\"electronics\"",
                "street_address": "\"Unit 57-58, Pavillions Shopping Centre\"",
                "website": "\"https://uk.webuy.com/site/storeDetail/?branchId=340\""
            },
            {
                "__id": 246,
                "address_city": "\"Stirling\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"FK8 2ED\"",
                "address_state": "\"Stirlingshire\"",
                "image": "\"https://uk.static.webuy.com/store_images/217/217.jpg\"",
                "latitude": 56.118405,
                "longitude": -3.935766,
                "name": "\"Stirling\"",
                "opening_hours": "\"Mo-Fr 09:00-17:30; Sa 09:00-18:00; Su 10:00-17:00\"",
                "shop": "\"electronics\"",
                "street_address": "\"Unit 24, Orchard Croft, The Thistles Shopping Centre\"",
                "website": "\"https://uk.webuy.com/site/storeDetail/?branchId=217\""
            },
            {
                "__id": 257,
                "address_city": "\"Salford\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"M6 5HW\"",
                "address_state": "\"Lancashire\"",
                "image": "\"https://uk.static.webuy.com/store_images/206/206.jpg\"",
                "latitude": 53.48848,
                "longitude": -2.287838,
                "name": "\"Salford\"",
                "opening_hours": "\"Mo-Sa 09:00-17:30; Su 10:00-16:00\"",
                "shop": "\"electronics\"",
                "street_address": "\"56 Fitzgerald Way\"",
                "website": "\"https://uk.webuy.com/site/storeDetail/?branchId=206\""
            },
            {
                "__id": 33,
                "address_city": "\"Beverley\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"HU17 9AP\"",
                "address_state": "\"East Yorkshire\"",
                "image": "\"https://uk.static.webuy.com/store_images/3188/3188.jpg\"",
                "latitude": 53.841814,
                "longitude": -0.430582,
                "name": "\"Beverley\"",
                "opening_hours": "\"Mo-Sa 09:00-17:30; Su 10:30-16:30\"",
                "shop": "\"electronics\"",
                "street_address": "\"22 Toll Gavel\"",
                "website": "\"https://uk.webuy.com/site/storeDetail/?branchId=3188\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "petparadise": {
        "__id": 15,
        "id": "petparadise",
        "name": "Pet Paradise",
        "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'phone', 'website']",
        "rowLength": 53,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763444,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of petparadise locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 41,
                "address_city": "\"Greenville\"",
                "address_full": "\"615 Congaree Road\"",
                "address_postcode": "\"29607\"",
                "address_state": "\"SC\"",
                "latitude": 34.846863,
                "longitude": -82.324564,
                "name": "\"SC - Greenville\"",
                "phone": "\"864.315.3396\"",
                "website": "\"https://www.petparadise.com/greenville.htm\""
            },
            {
                "__id": 22,
                "address_city": "\"Bunnell\"",
                "address_full": "\"23 Enterprise Drive\"",
                "address_postcode": "\"32110\"",
                "address_state": "\"FL\"",
                "latitude": 29.529022,
                "longitude": -81.264767,
                "name": "\"FL - Palm Coast\"",
                "phone": "\"386.446.8443\"",
                "website": "\"https://www.petparadise.com/palm-coast.htm\""
            },
            {
                "__id": 10,
                "address_city": "\"Jacksonville\"",
                "address_full": "\"14272 Pecan Park Road\"",
                "address_postcode": "\"32218\"",
                "address_state": "\"FL\"",
                "latitude": 30.496013,
                "longitude": -81.670119,
                "name": "\"FL - Jacksonville Airport\"",
                "phone": "\"904.741.0050\"",
                "website": "\"https://www.petparadise.com/jacksonville-airport.htm\""
            },
            {
                "__id": 52,
                "address_city": "\"Richmond\"",
                "address_full": "\"4101 Williamsburg Rd.\"",
                "address_postcode": "\"23231\"",
                "address_state": "\"VA\"",
                "latitude": 37.51747019,
                "longitude": -77.35706572,
                "name": "\"VA -Richmond Airport\"",
                "phone": "\"804.806.3640\"",
                "website": "\"https://www.petparadise.com/richmond-airport.htm\""
            },
            {
                "__id": 33,
                "address_city": "\"Rio Rancho\"",
                "address_full": "\"580 Quantum Road NE\"",
                "address_postcode": "\"87124\"",
                "address_state": "\"NM\"",
                "latitude": 35.267379,
                "longitude": -106.635168,
                "name": "\"NM - Rio Rancho\"",
                "phone": "\"505.891.4100\"",
                "website": "\"https://www.petparadise.com/rio-rancho.htm\""
            },
            {
                "__id": 42,
                "address_city": "\"Georgetown\"",
                "address_full": "\"716 S. Interstate 35\"",
                "address_postcode": "\"78628\"",
                "address_state": "\"TX\"",
                "latitude": 30.64336,
                "longitude": -97.689487,
                "name": "\"TX - Georgetown\"",
                "phone": "\"512.298.3400\"",
                "website": "\"https://www.petparadise.com/georgetown.htm\""
            },
            {
                "__id": 3,
                "address_city": "\"Jacksonville\"",
                "address_full": "\"14976 Walden Springs Way\"",
                "address_postcode": "\"32258\"",
                "address_state": "\"FL\"",
                "latitude": 30.10495,
                "longitude": -81.483558,
                "name": "\"FL - Bartram\"",
                "phone": "\"904.527.5335\"",
                "website": "\"https://www.petparadise.com/bartram-park.htm\""
            },
            {
                "__id": 19,
                "address_city": "\"Ormond Beach\"",
                "address_full": "\"1211 Golf Ave.\"",
                "address_postcode": "\"32174\"",
                "address_state": "\"FL\"",
                "latitude": 29.253095,
                "longitude": -81.072051,
                "name": "\"FL - Ormond Beach\"",
                "phone": "\"386.673.8041\"",
                "website": "\"https://www.petparadise.com/ormond-beach.htm\""
            },
            {
                "__id": 46,
                "address_city": "\"Irving\"",
                "address_full": "\"6150 Riverside Dr\"",
                "address_postcode": "\"75039\"",
                "address_state": "\"TX\"",
                "latitude": 32.892719,
                "longitude": -96.93495,
                "name": "\"TX - Las Colinas\"",
                "phone": "\"972.401.3647\"",
                "website": "\"https://www.petparadise.com/las-colinas.htm\""
            },
            {
                "__id": 29,
                "address_city": "\"Sharpsburg\"",
                "address_full": "\"35 Bailey Station Way\"",
                "address_postcode": "\"30277\"",
                "address_state": "\"GA\"",
                "latitude": 33.43367,
                "longitude": -84.65133,
                "name": "\"GA - Peachtree City\"",
                "phone": "\"470.460.5446\"",
                "website": "\"https://www.petparadise.com/peachtree-city.htm\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "completude": {
        "__id": 16,
        "id": "completude",
        "name": "Completude",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'latitude', 'longitude', 'phone', 'website']",
        "rowLength": 31,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763444,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of completude locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 14,
                "address_city": "\"NANTES \"",
                "address_country": "\"FR\"",
                "address_full": "\"1 quai de la Fosse\"",
                "address_postcode": "\"44000\"",
                "latitude": 47.21205,
                "longitude": -1.559421,
                "phone": "\"02 40 35 97 97\"",
                "website": "\"https://www.completude.com/agence/nantes/\""
            },
            {
                "__id": 6,
                "address_city": "\"STRASBOURG \"",
                "address_country": "\"FR\"",
                "address_full": "\"61 rue du Faubourg de Pierre\"",
                "address_postcode": "\"67000\"",
                "latitude": 48.589787,
                "longitude": 7.745044,
                "phone": "\"03 88 10 80 06\"",
                "website": "\"https://www.completude.com/agence/strasbourg/\""
            },
            {
                "__id": 22,
                "address_city": "\"LILLE \"",
                "address_country": "\"FR\"",
                "address_full": "\"78 rue Nationale\"",
                "address_postcode": "\"59000\"",
                "latitude": 50.6358665,
                "longitude": 3.0598283,
                "phone": "\"03 20 15 15 15\"",
                "website": "\"https://www.completude.com/agence/lille/\""
            },
            {
                "__id": 2,
                "address_city": "\"PARIS \"",
                "address_country": "\"FR\"",
                "address_full": "\"38 rue Blomet\"",
                "address_postcode": "\"75015\"",
                "latitude": 48.842829,
                "longitude": 2.306257,
                "phone": "\"01 56 58 58 58\"",
                "website": "\"https://www.completude.com/agence/paris/\""
            },
            {
                "__id": 17,
                "address_city": "\"METZ \"",
                "address_country": "\"FR\"",
                "address_full": "\"14 rue Gambetta\"",
                "address_postcode": "\"57000\"",
                "latitude": 49.110443,
                "longitude": 6.1748017,
                "phone": "\"03 87 65 54 70\"",
                "website": "\"https://www.completude.com/agence/metz/\""
            },
            {
                "__id": 19,
                "address_city": "\"LYON \"",
                "address_country": "\"FR\"",
                "address_full": "\"17 rue Dunoir\"",
                "address_postcode": "\"69003\"",
                "latitude": 45.761068637205,
                "longitude": 4.8470731484795,
                "phone": "\"04 72 84 62 62\"",
                "website": "\"https://www.completude.com/agence/lyon/\""
            },
            {
                "__id": 8,
                "address_city": "\"ROUEN \"",
                "address_country": "\"FR\"",
                "address_full": "\"19 rue de la R\u00e9publique\"",
                "address_postcode": "\"76000\"",
                "latitude": 49.439989,
                "longitude": 1.097357,
                "phone": "\"02 35 71 51 54\"",
                "website": "\"https://www.completude.com/agence/rouen/\""
            },
            {
                "__id": 27,
                "address_city": "\"BREST \"",
                "address_country": "\"FR\"",
                "address_full": "\"23 rue Jean Mac\u00e9\"",
                "address_postcode": "\"29200\"",
                "latitude": 48.386824424559,
                "longitude": -4.4879573312283,
                "phone": "\"02 98 41 25 54\"",
                "website": "\"https://www.completude.com/agence/brest/\""
            },
            {
                "__id": 26,
                "address_city": "\"CAEN \"",
                "address_country": "\"FR\"",
                "address_full": "\"24 avenue du six juin\"",
                "address_postcode": "\"14000\"",
                "latitude": 49.181557932587,
                "longitude": -0.35719291955078,
                "phone": "\"02 31 06 04 91\"",
                "website": "\"https://www.completude.com/agence/caen/\""
            },
            {
                "__id": 5,
                "address_city": "\"TOULON \"",
                "address_country": "\"FR\"",
                "address_full": "\"176 avenue Vauban\"",
                "address_postcode": "\"83000\"",
                "latitude": 43.12671,
                "longitude": 5.928899,
                "phone": "\"04 94 15 10 76\"",
                "website": "\"https://www.completude.com/agence/toulon/\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "carhartt_wip": {
        "__id": 17,
        "id": "carhartt_wip",
        "name": "Carhartt",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'contact_email', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'street_address', 'website']",
        "rowLength": 82,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763445,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of carhartt_wip locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 9,
                "address_city": "\"Barcelona\"",
                "address_country": "\"ES\"",
                "address_postcode": "\"08003\"",
                "contact_email": "\"elborn.barcelona@carhartt-wip.com\"",
                "latitude": 41.384613892765685,
                "longitude": 2.18370206200575,
                "name": "\"Carhartt WIP Store Barcelona El Born\"",
                "opening_hours": "\"Mo-Sa 11:00-21:00\"",
                "phone": "\"+34933105231\"",
                "street_address": "\"Carrer del Rec 71\"",
                "website": "\"https://www.carhartt-wip.com/stores/4527\""
            },
            {
                "__id": 40,
                "address_city": "\"Kings Cross\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"N1C4AG\"",
                "contact_email": "\"kingscross@carhartt-wip.com\"",
                "latitude": 51.53369866847951,
                "longitude": -0.12481317790980029,
                "name": "\"Carhartt WIP Store London Kings Cross\"",
                "opening_hours": "\"Mo-Sa 11:00-19:00; Su 11:00-17:00\"",
                "phone": "\"+442039576815\"",
                "street_address": "\"Unit 3, 2 Pancras Square\"",
                "website": "\"https://www.carhartt-wip.com/stores/9579\""
            },
            {
                "__id": 6,
                "address_city": "\"Amsterdam\"",
                "address_country": "\"NL\"",
                "address_postcode": "\"1017 VK\"",
                "contact_email": "\"amsterdam_us@carhartt-wip.com\"",
                "latitude": 52.36271088959858,
                "longitude": 4.898905583912324,
                "name": "\"Carhartt WIP Store Amsterdam Utrechtsestraat\"",
                "opening_hours": "\"Mo 12:00-18:00; Tu-Sa 10:00-18:00; Su 12:00-18:00\"",
                "phone": "\"+31206222017\"",
                "street_address": "\"Utrechtsestraat 99\"",
                "website": "\"https://www.carhartt-wip.com/stores/9572\""
            },
            {
                "__id": 36,
                "address_city": "\"Kyoto\"",
                "address_country": "\"JP\"",
                "address_postcode": "\"604-8047\"",
                "contact_email": "\"info@carhartt-wip.jp\"",
                "latitude": 35.00639687117228,
                "longitude": 135.76711641104507,
                "name": "\"Carhartt WIP Store Kyoto\"",
                "opening_hours": "\"Mo-Su 11:00-20:00\"",
                "phone": "\"+81752569810\"",
                "street_address": "\"496-1 Shinkyogokudori Takoyakushiagaru Nakasujicho Nakagyoku Kyoto-Shi\"",
                "website": "\"https://www.carhartt-wip.com/stores/9593\""
            },
            {
                "__id": 77,
                "address_city": "\"Valencia\"",
                "address_country": "\"ES\"",
                "address_postcode": "\"46004\"",
                "contact_email": "\"valencia@carhartt-wip.com\"",
                "latitude": 39.46930898801405,
                "longitude": -0.3684803848049114,
                "name": "\"Carhartt WIP Store Valencia\"",
                "opening_hours": "\"Mo-Sa 10:00-21:00\"",
                "phone": "\"+34963943916\"",
                "street_address": "\"Calle Martinez Ferrando 3 (frente al mercado de Col+\u00a6n)\"",
                "website": "\"https://www.carhartt-wip.com/stores/4386\""
            },
            {
                "__id": 62,
                "address_city": "\"Rennes\"",
                "address_country": "\"FR\"",
                "address_postcode": "\"35000\"",
                "contact_email": "\"rennes@carhartt-wip.com\"",
                "latitude": 48.112403869628906,
                "longitude": -1.6788082122802734,
                "name": "\"Carhartt WIP Store Rennes\"",
                "opening_hours": "\"\"",
                "phone": "\"\"",
                "street_address": "\"9 Rue Nationale\"",
                "website": "\"https://www.carhartt-wip.comNone\""
            },
            {
                "__id": 32,
                "address_city": "\"Hamburg\"",
                "address_country": "\"DE\"",
                "address_postcode": "\"20357\"",
                "contact_email": "\"hamburg@carhartt-wip.com\"",
                "latitude": 53.56033022056967,
                "longitude": 9.964624593858616,
                "name": "\"Carhartt WIP Store Hamburg Schanzenstra\u00dfe\"",
                "opening_hours": "\"Mo-Sa 11:00-20:00\"",
                "phone": "\"+494023518082\"",
                "street_address": "\"Schanzenstrasse 27\"",
                "website": "\"https://www.carhartt-wip.com/stores/12168\""
            },
            {
                "__id": 31,
                "address_city": "\"Hamburg\"",
                "address_country": "\"DE\"",
                "address_postcode": "\"20354\"",
                "contact_email": "\"cs_hamburg_ps@carhartt-wip.com\"",
                "latitude": 53.55430850805998,
                "longitude": 9.988423289959249,
                "name": "\"Carhartt WIP Store Hamburg Poststra\u00dfe\"",
                "opening_hours": "\"Mo-Sa 11:00-20:00\"",
                "phone": "\"\"",
                "street_address": "\"Poststr. 20\"",
                "website": "\"https://www.carhartt-wip.com/stores/9614\""
            },
            {
                "__id": 27,
                "address_city": "\"Fukuoka\"",
                "address_country": "\"JP\"",
                "address_postcode": "\"810-0041\"",
                "contact_email": "\"info@carhartt-wip.jp\"",
                "latitude": 33.58609335869781,
                "longitude": 130.39536948502064,
                "name": "\"Carhartt WIP Store Fukuoka\"",
                "opening_hours": "\"Mo-Su 11:00-20:00\"",
                "phone": "\"+81927169810\"",
                "street_address": "\"1-2-51 Daimyo Chuo-ku\"",
                "website": "\"https://www.carhartt-wip.com/stores/9001\""
            },
            {
                "__id": 69,
                "address_city": "\"Seoul\"",
                "address_country": "\"KR\"",
                "address_postcode": "\"04068\"",
                "contact_email": "\"info@carhartt-wip.co.kr\"",
                "latitude": 37.549008,
                "longitude": 126.9232188,
                "name": "\"Carhartt WIP Store Seoul Hongdae\"",
                "opening_hours": "\"Mo-Su 12:00-21:00\"",
                "phone": "\"+8223378333\"",
                "street_address": "\"52, Wausan-ro, Mapo-gu\"",
                "website": "\"https://www.carhartt-wip.com/stores/12160\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "kpmg_fr": {
        "__id": 18,
        "id": "kpmg_fr",
        "name": "KPMG",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'latitude', 'longitude', 'name', 'office', 'phone', 'website']",
        "rowLength": 3,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763446,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of kpmg_fr locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 1,
                "address_city": "\"Saint-Malo\"",
                "address_country": "\"FR\"",
                "address_full": "\"25 rue Arkansas\"",
                "address_postcode": "\"35400\"",
                "latitude": 48.637956,
                "longitude": -1.990467,
                "name": "\"Bureau KPMG Saint-Malo\"",
                "office": "\"consulting\"",
                "phone": "\"+33 (0)2 23 18 00 35\"",
                "website": "\"https://home.kpmg/fr/fr/home/a-propos-de-kpmg/offices/saint-malo-cedex.html\""
            },
            {
                "__id": 2,
                "address_city": "\"Paris\"",
                "address_country": "\"FR\"",
                "address_full": "\"5 rue du Faubourg Saint-Antoine\"",
                "address_postcode": "\"75011\"",
                "latitude": 48.853124,
                "longitude": 2.371217,
                "name": "\"Bureau KPMG Paris \u2013 rue du Faubourg Saint-Antoine\"",
                "office": "\"consulting\"",
                "phone": "\"+33 (0)1 56 95 46 80\"",
                "website": "\"https://home.kpmg/fr/fr/home/a-propos-de-kpmg/offices/paris-rue-du-faubourg-saint-antoine.html\""
            },
            {
                "__id": 3,
                "address_city": "\"Morteau\"",
                "address_country": "\"FR\"",
                "address_full": "\"8 avenue Charles de Gaulle\"",
                "address_postcode": "\"25503\"",
                "latitude": 47.056835,
                "longitude": 6.612248,
                "name": "\"Bureau KPMG Morteau\"",
                "office": "\"consulting\"",
                "phone": "\"+33 (0)3 81 67 19 23\"",
                "website": "\"https://home.kpmg/fr/fr/home/a-propos-de-kpmg/offices/morteau-cedex-111.html\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "jiffylube": {
        "__id": 19,
        "id": "jiffylube",
        "name": "Jiffy Lube",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'phone', 'shop', 'street_address', 'website']",
        "rowLength": 1962,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763447,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of jiffylube locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 1377,
                "address_city": "\"CLIVE\"",
                "address_country": "\"US\"",
                "address_postcode": "\"50325-7104\"",
                "address_state": "\"IA\"",
                "latitude": 41.608724,
                "longitude": -93.736881,
                "name": "\"Jiffy Lube\"",
                "phone": "\"5152700068\"",
                "shop": "\"car_repair\"",
                "street_address": "\"1846 NW 86TH ST\"",
                "website": "\"https://www.jiffylube.com/locations/IA/CLIVE/1293\""
            },
            {
                "__id": 943,
                "address_city": "\"NASHVILLE\"",
                "address_country": "\"US\"",
                "address_postcode": "\"37203-2908\"",
                "address_state": "\"TN\"",
                "latitude": 36.158862,
                "longitude": -86.79937,
                "name": "\"Jiffy Lube\"",
                "phone": "\"6153207325\"",
                "shop": "\"car_repair\"",
                "street_address": "\"1710 CHARLOTTE AVE\"",
                "website": "\"https://www.jiffylube.com/locations/TN/NASHVILLE/2263\""
            },
            {
                "__id": 101,
                "address_city": "\"HOLLY SPRINGS\"",
                "address_country": "\"US\"",
                "address_postcode": "\"27540\"",
                "address_state": "\"NC\"",
                "latitude": 35.665859,
                "longitude": -78.795721,
                "name": "\"Jiffy Lube\"",
                "phone": "\"9193621470\"",
                "shop": "\"car_repair\"",
                "street_address": "\"100 MATTHEWS DRIVE\"",
                "website": "\"https://www.jiffylube.com/locations/NC/HOLLY-SPRINGS/3837\""
            },
            {
                "__id": 806,
                "address_city": "\"Naperville\"",
                "address_country": "\"US\"",
                "address_postcode": "\"60565-6185\"",
                "address_state": "\"IL\"",
                "latitude": 41.747695,
                "longitude": -88.160814,
                "name": "\"Jiffy Lube\"",
                "phone": "\"6309953829\"",
                "shop": "\"car_repair\"",
                "street_address": "\"944 W 75th St\"",
                "website": "\"https://www.jiffylube.com/locations/IL/Naperville/2506\""
            },
            {
                "__id": 701,
                "address_city": "\"MASHPEE\"",
                "address_country": "\"US\"",
                "address_postcode": "\"02649-2721\"",
                "address_state": "\"MA\"",
                "latitude": 41.633598,
                "longitude": -70.460191,
                "name": "\"Jiffy Lube\"",
                "phone": "\"5085398888\"",
                "shop": "\"car_repair\"",
                "street_address": "\"60 FALMOUTH RD\"",
                "website": "\"https://www.jiffylube.com/locations/MA/MASHPEE/2650\""
            },
            {
                "__id": 618,
                "address_city": "\"GREENSBORO\"",
                "address_country": "\"US\"",
                "address_postcode": "\"27408-4005\"",
                "address_state": "\"NC\"",
                "latitude": 36.10964,
                "longitude": -79.829705,
                "name": "\"Jiffy Lube\"",
                "phone": "\"3365450599\"",
                "shop": "\"car_repair\"",
                "street_address": "\"2625 BATTLEGROUND AVE\"",
                "website": "\"https://www.jiffylube.com/locations/NC/GREENSBORO/2777\""
            },
            {
                "__id": 1921,
                "address_city": "\"AUDUBON\"",
                "address_country": "\"US\"",
                "address_postcode": "\"08106-1302\"",
                "address_state": "\"NJ\"",
                "latitude": 39.898109,
                "longitude": -75.073041,
                "name": "\"Jiffy Lube\"",
                "phone": "\"8565477188\"",
                "shop": "\"car_repair\"",
                "street_address": "\"55 N WHITE HORSE PIKE\"",
                "website": "\"https://www.jiffylube.com/locations/NJ/AUDUBON/114\""
            },
            {
                "__id": 1068,
                "address_city": "\"GOODLETTSVILLE\"",
                "address_country": "\"US\"",
                "address_postcode": "\"37072-1443\"",
                "address_state": "\"TN\"",
                "latitude": 36.323788,
                "longitude": -86.708016,
                "name": "\"Jiffy Lube\"",
                "phone": "\"6158515520\"",
                "shop": "\"car_repair\"",
                "street_address": "\"333 CARTWRIGHT ST\"",
                "website": "\"https://www.jiffylube.com/locations/TN/GOODLETTSVILLE/1985\""
            },
            {
                "__id": 1777,
                "address_city": "\"CLEARWATER\"",
                "address_country": "\"US\"",
                "address_postcode": "\"33765-1866\"",
                "address_state": "\"FL\"",
                "latitude": 27.981937,
                "longitude": -82.729919,
                "name": "\"Jiffy Lube\"",
                "phone": "\"7277965830\"",
                "shop": "\"car_repair\"",
                "street_address": "\"23034 US HIGHWAY 19 N\"",
                "website": "\"https://www.jiffylube.com/locations/FL/CLEARWATER/378\""
            },
            {
                "__id": 564,
                "address_city": "\"HONOLULU\"",
                "address_country": "\"US\"",
                "address_postcode": "\"96817-4658\"",
                "address_state": "\"HI\"",
                "latitude": 21.31864,
                "longitude": -157.865408,
                "name": "\"Jiffy Lube\"",
                "phone": "\"8088430115\"",
                "shop": "\"car_repair\"",
                "street_address": "\"555 N KING ST\"",
                "website": "\"https://www.jiffylube.com/locations/HI/HONOLULU/2893\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "party_city": {
        "__id": 20,
        "id": "party_city",
        "name": "Party City",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'phone', 'shop', 'website']",
        "rowLength": 827,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763448,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of party_city locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 356,
                "address_city": "\"Newington\"",
                "address_country": "\"US\"",
                "address_full": "\"2064 Woodbury Avenue\"",
                "address_postcode": "\"03801\"",
                "address_state": "\"NH\"",
                "latitude": 43.096161,
                "longitude": -70.801003,
                "name": "\"Fox Run Mall\"",
                "phone": "\"(603) 368-8295\"",
                "shop": "\"party\"",
                "website": "\"https://stores.partycity.com/us/nh/newington/party-store-pc6044.html\""
            },
            {
                "__id": 405,
                "address_city": "\"Ellisville\"",
                "address_country": "\"US\"",
                "address_full": "\"15909 Manchester Road\"",
                "address_postcode": "\"63011\"",
                "address_state": "\"MO\"",
                "latitude": 38.593758,
                "longitude": -90.587324,
                "name": "\"Ellisville Square\"",
                "phone": "\"(636) 591-1728\"",
                "shop": "\"party\"",
                "website": "\"https://stores.partycity.com/us/mo/ellisville/party-store-pc839.html\""
            },
            {
                "__id": 22,
                "address_city": "\"Onalaska\"",
                "address_country": "\"US\"",
                "address_full": "\"1228 Crossing Meadows Drive\"",
                "address_postcode": "\"54650\"",
                "address_state": "\"WI\"",
                "latitude": 43.8703759,
                "longitude": -91.2114067,
                "name": "\"Crossing Meadows Shopping Center\"",
                "phone": "\"(608) 406-3214\"",
                "shop": "\"party\"",
                "website": "\"https://stores.partycity.com/us/wi/onalaska/party-store-pc4005.html\""
            },
            {
                "__id": 772,
                "address_city": "\"Lodi\"",
                "address_country": "\"US\"",
                "address_full": "\"2350 W Kettleman Lane Suite 130\"",
                "address_postcode": "\"95242\"",
                "address_state": "\"CA\"",
                "latitude": 38.112968,
                "longitude": -121.303281,
                "name": "\"Sunwest Plaza\"",
                "phone": "\"(209) 642-4026\"",
                "shop": "\"party\"",
                "website": "\"https://stores.partycity.com/us/ca/lodi/party-store-pc935.html\""
            },
            {
                "__id": 760,
                "address_city": "\"Pasadena\"",
                "address_country": "\"US\"",
                "address_full": "\"3353 East Foothill Boulevard\"",
                "address_postcode": "\"91107\"",
                "address_state": "\"CA\"",
                "latitude": 34.152292,
                "longitude": -118.08144,
                "name": "\"Hastings Village Shopping Center\"",
                "phone": "\"(818) 949-3443\"",
                "shop": "\"party\"",
                "website": "\"https://stores.partycity.com/us/ca/pasadena/party-store-pc573.html\""
            },
            {
                "__id": 67,
                "address_city": "\"Chesapeake\"",
                "address_country": "\"US\"",
                "address_full": "\"4107 Portsmouth Boulevard\"",
                "address_postcode": "\"23321\"",
                "address_state": "\"VA\"",
                "latitude": 36.821897,
                "longitude": -76.411978,
                "name": "\"The Crossroads at Chesapeake Square\"",
                "phone": "\"(757) 447-3090\"",
                "shop": "\"party\"",
                "website": "\"https://stores.partycity.com/us/va/chesapeake/party-store-pc843.html\""
            },
            {
                "__id": 720,
                "address_city": "\"Tustin\"",
                "address_country": "\"US\"",
                "address_full": "\"2826 El Camino Real\"",
                "address_postcode": "\"92782\"",
                "address_state": "\"CA\"",
                "latitude": 33.7233815,
                "longitude": -117.796934,
                "name": "\"The Market Place\"",
                "phone": "\"(657) 205-5810\"",
                "shop": "\"party\"",
                "website": "\"https://stores.partycity.com/us/ca/tustin/party-store-pc548.html\""
            },
            {
                "__id": 113,
                "address_city": "\"Humble\"",
                "address_country": "\"US\"",
                "address_full": "\"6705 FM 1960 East\"",
                "address_postcode": "\"77346\"",
                "address_state": "\"TX\"",
                "latitude": 29.999666,
                "longitude": -95.17418,
                "name": "\"FM 1960 E\"",
                "phone": "\"(832) 412-4713\"",
                "shop": "\"party\"",
                "website": "\"https://stores.partycity.com/us/tx/humble/party-store-pc683.html\""
            },
            {
                "__id": 790,
                "address_city": "\"Downey\"",
                "address_country": "\"US\"",
                "address_full": "\"7171 Firestone Boulevard\"",
                "address_postcode": "\"90241\"",
                "address_state": "\"CA\"",
                "latitude": 33.948336,
                "longitude": -118.15497,
                "name": "\"Firestone Blvd\"",
                "phone": "\"(562) 372-9105\"",
                "shop": "\"party\"",
                "website": "\"https://stores.partycity.com/us/ca/downey/party-store-pc607.html\""
            },
            {
                "__id": 807,
                "address_city": "\"Brentwood\"",
                "address_country": "\"US\"",
                "address_full": "\"2470 Sand Creek Road\"",
                "address_postcode": "\"94513\"",
                "address_state": "\"CA\"",
                "latitude": 37.940597,
                "longitude": -121.737785,
                "name": "\"Sand Creek Crossings\"",
                "phone": "\"(925) 626-0949\"",
                "shop": "\"party\"",
                "website": "\"https://stores.partycity.com/us/ca/brentwood/party-store-pc444.html\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "quest_diagnostics": {
        "__id": 21,
        "id": "quest_diagnostics",
        "name": "Quest Diagnostics",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'fax', 'healthcare', 'latitude', 'longitude', 'name', 'phone', 'website']",
        "rowLength": 2253,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763450,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of quest_diagnostics locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 1347,
                "address_city": "\"Covina\"",
                "address_country": "\"US\"",
                "address_full": "\"554 E San Bernardino Rd, Ste 103\"",
                "address_postcode": "\"91723\"",
                "address_state": "\"CA\"",
                "fax": "\"626-858-8683\"",
                "healthcare": "\"laboratory\"",
                "latitude": 34.0906557,
                "longitude": -117.8800491,
                "name": "\"Quest Diagnostics - Covina East San Bernardino  Road\"",
                "phone": "\"626-430-8943\"",
                "website": "\"https://www.questdiagnostics.com/locations/detail.html/CZQ/91723/75/1\""
            },
            {
                "__id": 2098,
                "address_city": "\"Wareham\"",
                "address_country": "\"US\"",
                "address_full": "\"106 Main Street, Suite 4\"",
                "address_postcode": "\"02571\"",
                "address_state": "\"MA\"",
                "fax": "\"508-295-8098\"",
                "healthcare": "\"laboratory\"",
                "latitude": 41.7597804,
                "longitude": -70.7172575999,
                "name": "\"Quest Diagnostics - Wareham\"",
                "phone": "\"508-295-0477\"",
                "website": "\"https://www.questdiagnostics.com/locations/detail.html/QWH/02571/75/1\""
            },
            {
                "__id": 1405,
                "address_city": "\"Latham\"",
                "address_country": "\"US\"",
                "address_full": "\"579 Troy Schenectady Rd, Ste 222, Latham Farms Shopping Center\"",
                "address_postcode": "\"12110\"",
                "address_state": "\"NY\"",
                "fax": "\"518-786-9799\"",
                "healthcare": "\"laboratory\"",
                "latitude": 42.7571713,
                "longitude": -73.7613688,
                "name": "\"Quest Diagnostics - Latham \"",
                "phone": "\"518-785-7765\"",
                "website": "\"https://www.questdiagnostics.com/locations/detail.html/LTH/12110/75/1\""
            },
            {
                "__id": 1103,
                "address_city": "\"Rowlett\"",
                "address_country": "\"US\"",
                "address_full": "\"8805 Lakeview Pkwy\"",
                "address_postcode": "\"75088\"",
                "address_state": "\"TX\"",
                "fax": "\"214-607-1611\"",
                "healthcare": "\"laboratory\"",
                "latitude": 32.9204619,
                "longitude": -96.5163426,
                "name": "\"Quest Diagnostics - Inside Rowlett Tom Thumb Store - Employer Drug Testing Not Offered\"",
                "phone": "\"214-607-1608\"",
                "website": "\"https://www.questdiagnostics.com/locations/detail.html/B2Y/75088/75/1\""
            },
            {
                "__id": 414,
                "address_city": "\"Pace\"",
                "address_country": "\"US\"",
                "address_full": "\"3874 Highway 90, Attention: Laboratory\"",
                "address_postcode": "\"32571\"",
                "address_state": "\"FL\"",
                "fax": "\"850-995-5908\"",
                "healthcare": "\"laboratory\"",
                "latitude": 30.5993002,
                "longitude": -87.1627407,
                "name": "\"Baptist Medical Park Pace - PSA Collection Site\"",
                "phone": "\"850-437-8820\"",
                "website": "\"https://www.questdiagnostics.com/locations/detail.html/G35/32571/75/1\""
            },
            {
                "__id": 1771,
                "address_city": "\"Dallas\"",
                "address_country": "\"US\"",
                "address_full": "\"1411 N Beckley Ave, Pavilion III, Suite 450\"",
                "address_postcode": "\"75203\"",
                "address_state": "\"TX\"",
                "fax": "\"214-943-5531\"",
                "healthcare": "\"laboratory\"",
                "latitude": 32.7604174,
                "longitude": -96.8257915,
                "name": "\"Quest Diagnostics - Methodist Pavilion III - Employer Drug Testing Not Offered\"",
                "phone": "\"214-943-5529\"",
                "website": "\"https://www.questdiagnostics.com/locations/detail.html/TXE/75203/75/1\""
            },
            {
                "__id": 1706,
                "address_city": "\"Fresno\"",
                "address_country": "\"US\"",
                "address_full": "\"7050 North Recreation Avenue, Suite 104\"",
                "address_postcode": "\"93720\"",
                "address_state": "\"CA\"",
                "fax": "\"559-299-9428\"",
                "healthcare": "\"laboratory\"",
                "latitude": 36.8380152,
                "longitude": -119.7399008,
                "name": "\"Quest Diagnostics - Fresno North Recreation Avenue - Employer Drug Testing Not Offered\"",
                "phone": "\"559-299-4865\"",
                "website": "\"https://www.questdiagnostics.com/locations/detail.html/GFJ/93720/75/1\""
            },
            {
                "__id": 938,
                "address_city": "\"Syosset\"",
                "address_country": "\"US\"",
                "address_full": "\"269 Jericho Tpke\"",
                "address_postcode": "\"11791\"",
                "address_state": "\"NY\"",
                "fax": "\"516-677-5499\"",
                "healthcare": "\"laboratory\"",
                "latitude": 40.8103067,
                "longitude": -73.5060743999,
                "name": "\"Quest Diagnostics - Syosset\"",
                "phone": "\"516-677-5355\"",
                "website": "\"https://www.questdiagnostics.com/locations/detail.html/J3D/11791/75/1\""
            },
            {
                "__id": 1959,
                "address_city": "\"Downey\"",
                "address_country": "\"US\"",
                "address_full": "\"10800 Paramount Blvd, Ste 103\"",
                "address_postcode": "\"90241\"",
                "address_state": "\"CA\"",
                "fax": "\"562-869-0261\"",
                "healthcare": "\"laboratory\"",
                "latitude": 33.945913,
                "longitude": -118.135175,
                "name": "\"Quest Diagnostics - Downey South Paramount - Employer Drug Testing Not Offered\"",
                "phone": "\"562-299-8417\"",
                "website": "\"https://www.questdiagnostics.com/locations/detail.html/CVF/90241/75/1\""
            },
            {
                "__id": 2170,
                "address_city": "\"MESA\"",
                "address_country": "\"US\"",
                "address_full": "\"6106 E BROWN RD, STE 101\"",
                "address_postcode": "\"85205\"",
                "address_state": "\"AZ\"",
                "fax": "\"602-744-8921\"",
                "healthcare": "\"laboratory\"",
                "latitude": 33.438181,
                "longitude": -111.699701,
                "name": "\"Sonora Quest Laboratories - Mesa - Recker &amp; Brown - Employer Drug Testing Not Offered\"",
                "phone": "\"480-981-4036\"",
                "website": "\"https://www.questdiagnostics.com/locations/detail.html/PRQ/85205/75/1\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "mcdonalds_hk": {
        "__id": 22,
        "id": "mcdonalds_hk",
        "name": "McDonald's Hong Kong",
        "columnNames": "['address_country', 'address_full', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'phone', 'takeaway', 'website']",
        "rowLength": 251,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763450,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of mcdonalds_hk locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 112,
                "address_country": "\"HK\"",
                "address_full": "\"\u5c16\u6c99\u5480\u67ef\u58eb\u7538\u905322\u865f\u4e5d\u9f8d\u516c\u5712\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 22.301755,
                "longitude": 114.1705215,
                "name": "\"McDonald's \u4e5d\u9f8d\u516c\u5712\"",
                "phone": "\"23171527\"",
                "takeaway": "\"yes\"",
                "website": "\"https://www.mcdonalds.com.hk/\""
            },
            {
                "__id": 105,
                "address_country": "\"HK\"",
                "address_full": "\"\u4e5d\u9f8d\u9577\u6c99\u7063\u5e78\u7965\u88578\u865f\u5e78\u798f\u90a8\u5e78\u798f\u5546\u58341\u6a13111\u8216\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 22.335339,
                "longitude": 114.154595,
                "name": "\"McDonald's \u5e78\u798f\u5546\u5834\"",
                "phone": "\"27583292\"",
                "takeaway": "\"yes\"",
                "website": "\"https://www.mcdonalds.com.hk/\""
            },
            {
                "__id": 47,
                "address_country": "\"HK\"",
                "address_full": "\"\u89c0\u5858\u9053388\u865f\u5275\u7d00\u4e4b\u57ce1A\u5730\u4e0b\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 22.3146929,
                "longitude": 114.2195682,
                "name": "\"McDonald's \u5275\u7d00\u4e4b\u57ce\"",
                "phone": "\"22671638\"",
                "takeaway": "\"yes\"",
                "website": "\"https://www.mcdonalds.com.hk/\""
            },
            {
                "__id": 134,
                "address_country": "\"HK\"",
                "address_full": "\"\u7f8e\u5b5a\u65b0\u90a8\u842c\u4e8b\u9054\u5ee3\u5834\u5730\u4e0bN60A\u865f\u5e97\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 22.3374675,
                "longitude": 114.1394451,
                "name": "\"McDonald's \u7f8e\u5b5a\u65b0\u90a8\"",
                "phone": "\"27862178\"",
                "takeaway": "\"yes\"",
                "website": "\"https://www.mcdonalds.com.hk/\""
            },
            {
                "__id": 19,
                "address_country": "\"HK\"",
                "address_full": "\"\u65b0\u754c\u7c89\u5dba\u7687\u540e\u5c71\u90a8\u7687\u540e\u5c71\u5546\u5834\u5730\u4e0b11\u865f\u8216\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 22.5039957,
                "longitude": 114.1547464,
                "name": "\"McDonald's \u7687\u540e\u5c71\"",
                "phone": "\"26872223\"",
                "takeaway": "\"yes\"",
                "website": "\"https://www.mcdonalds.com.hk/\""
            },
            {
                "__id": 76,
                "address_country": "\"HK\"",
                "address_full": "\"\u8343\u7063\u8343\u666f\u570d94-98\u865f\u8343\u7063\u4e2d\u5fc3\u7b2c\u4e8c\u671f\u5730\u4e0b6\u865f\u8216\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 22.3785816,
                "longitude": 114.1078291,
                "name": "\"McDonald's \u8343\u7063\u4e2d\u5fc3\"",
                "phone": "\"24055857\"",
                "takeaway": "\"yes\"",
                "website": "\"https://www.mcdonalds.com.hk/\""
            },
            {
                "__id": 40,
                "address_country": "\"HK\"",
                "address_full": "\"\u897f\u74b0\u5351\u8def\u4e4d\u88578\u865f\u897f\u5bf6\u57ce\u4f4e\u5c64\u5730\u4e0bLG07\u865f\u8216\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 22.284968,
                "longitude": 114.1332523,
                "name": "\"McDonald's \u897f\u5bf6\u57ce\"",
                "phone": "\"25427361\"",
                "takeaway": "\"yes\"",
                "website": "\"https://www.mcdonalds.com.hk/\""
            },
            {
                "__id": 73,
                "address_country": "\"HK\"",
                "address_full": "\"\u6c99\u7530\u5fb7\u539a\u8857\u79be\u8f0b\u90a8\u79be\u8f0b\u5546\u5834\u4e8c\u6a13245-247\u865f\u8216\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 22.3879585,
                "longitude": 114.1952465,
                "name": "\"McDonald's \u79be\u8f0b\u90a8\"",
                "phone": "\"26998365\"",
                "takeaway": "\"yes\"",
                "website": "\"https://www.mcdonalds.com.hk/\""
            },
            {
                "__id": 106,
                "address_country": "\"HK\"",
                "address_full": "\"\u6df1\u6c34\u57d7\u9752\u5c71\u9053212-214\u865f\u745e\u9e97\u95a3\u5730\u4e0b\u53ca\u4e00\u6a13\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 22.3368433,
                "longitude": 114.1586822,
                "name": "\"McDonald's \u9752\u5c71\u9053\"",
                "phone": "\"27088169\"",
                "takeaway": "\"yes\"",
                "website": "\"https://www.mcdonalds.com.hk/\""
            },
            {
                "__id": 174,
                "address_country": "\"HK\"",
                "address_full": "\"\u8475\u6d8c\u8475\u6d8c\u90531001\u865f\u5fb7\u660c\u5927\u5ec8\u4e00\u6a133\u865f\u8216\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 22.3672988,
                "longitude": 114.1332523,
                "name": "\"McDonald's \u5fb7\u660c\u5927\u5ec8\"",
                "phone": "\"27854939\"",
                "takeaway": "\"yes\"",
                "website": "\"https://www.mcdonalds.com.hk/\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "aubonpain": {
        "__id": 23,
        "id": "aubonpain",
        "name": "Au Bon Pain",
        "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'takeaway', 'website']",
        "rowLength": 106,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763451,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of aubonpain locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 60,
                "address_city": "\"Elmhurst\"",
                "address_full": "\"79-01 Broadway\"",
                "address_postcode": "\"11371\"",
                "address_state": "\"NY\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"sandwich\"",
                "latitude": 40.7451,
                "longitude": -73.8855,
                "name": "\"Au Bon Pain\"",
                "opening_hours": "\"24/7\"",
                "phone": "\"718-779-2033\"",
                "takeaway": "\"yes\"",
                "website": "\"https://www.aubonpain.com/stores/elmhurst-hospital\""
            },
            {
                "__id": 50,
                "address_city": "\"Philadelphia\"",
                "address_full": "\"1625 Chestnut Street\"",
                "address_postcode": "\"19103\"",
                "address_state": "\"PA\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"sandwich\"",
                "latitude": 39.9515705,
                "longitude": -75.1680038,
                "name": "\"Au Bon Pain\"",
                "opening_hours": "\"Mo-Fr 07:00-18:00\"",
                "phone": "\"215-567-9005\"",
                "takeaway": "\"yes\"",
                "website": "\"http://www.aubonpain.com/stores/the-shops-at-liberty-place-temporarily-closed\""
            },
            {
                "__id": 18,
                "address_city": "\"Farmville\"",
                "address_full": "\"201 High Street\"",
                "address_postcode": "\"23909\"",
                "address_state": "\"VA\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"sandwich\"",
                "latitude": 37.3011542,
                "longitude": -78.39444329,
                "name": "\"Au Bon Pain\"",
                "opening_hours": "\"Mo-Fr 07:30-20:00\"",
                "phone": "\"434-395-2640\"",
                "takeaway": "\"yes\"",
                "website": "\"https://www.aubonpain.com/stores/longwood-university-franchise\""
            },
            {
                "__id": 1,
                "address_city": "\"Auburn\"",
                "address_full": "\"120 PO Davis Drive\"",
                "address_postcode": "\"36849\"",
                "address_state": "\"AL\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"sandwich\"",
                "latitude": 32.6019,
                "longitude": -85.4868,
                "name": "\"Au Bon Pain\"",
                "opening_hours": "\"Mo-Fr 07:30-16:00\"",
                "phone": "\"334-224-1027\"",
                "takeaway": "\"yes\"",
                "website": "\"https://www.aubonpain.com/stores/auburn-university\""
            },
            {
                "__id": 76,
                "address_city": "\"St. Louis\"",
                "address_full": "\"701 Convention Plaza\"",
                "address_postcode": "\"63101\"",
                "address_state": "\"MO\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"sandwich\"",
                "latitude": 38.6311,
                "longitude": -90.1911,
                "name": "\"Au Bon Pain\"",
                "opening_hours": "\"\"",
                "phone": "\"314-342-5157\"",
                "takeaway": "\"yes\"",
                "website": "\"https://www.aubonpain.com/stores/st-louis-convention-center\""
            },
            {
                "__id": 49,
                "address_city": "\"New York\"",
                "address_full": "\"525 E 68th Street, Basement\"",
                "address_postcode": "\"10065\"",
                "address_state": "\"NY\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"sandwich\"",
                "latitude": 40.7643962,
                "longitude": -73.9539303,
                "name": "\"Au Bon Pain\"",
                "opening_hours": "\"\"",
                "phone": "\"212-746-7640\"",
                "takeaway": "\"yes\"",
                "website": "\"https://www.aubonpain.com/stores/ny-presbyterian-starr-basement\""
            },
            {
                "__id": 67,
                "address_city": "\"New Hyde Park\"",
                "address_full": "\"270-05 76th Ave\"",
                "address_postcode": "\"11040\"",
                "address_state": "\"NY\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"sandwich\"",
                "latitude": 40.7532584,
                "longitude": -73.7072296,
                "name": "\"Au Bon Pain\"",
                "opening_hours": "\"Mo-Fr 07:00-15:00\"",
                "phone": "\"718-343-3209\"",
                "takeaway": "\"yes\"",
                "website": "\"http://www.aubonpain.com/stores/lij-hillside\""
            },
            {
                "__id": 86,
                "address_city": "\"Baltimore\"",
                "address_full": "\"22 S. Green Street\"",
                "address_postcode": "\"21201\"",
                "address_state": "\"MD\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"sandwich\"",
                "latitude": 39.2883,
                "longitude": -76.6239,
                "name": "\"Au Bon Pain\"",
                "opening_hours": "\"24/7\"",
                "phone": "\"410-685-1976\"",
                "takeaway": "\"yes\"",
                "website": "\"https://www.aubonpain.com/stores/university-maryland-mc\""
            },
            {
                "__id": 13,
                "address_city": "\"Miami\"",
                "address_full": "\"Terminal G\"",
                "address_postcode": "\"33159\"",
                "address_state": "\"FL\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"sandwich\"",
                "latitude": 25.7975,
                "longitude": -80.2832,
                "name": "\"Au Bon Pain\"",
                "opening_hours": "\"Mo-Su 05:30-14:00\"",
                "phone": "\"786-295-4097\"",
                "takeaway": "\"yes\"",
                "website": "\"https://www.aubonpain.com/stores/miami-intl-airport-concourse-g\""
            },
            {
                "__id": 102,
                "address_city": "\"Baltimore\"",
                "address_full": "\"One South St.\"",
                "address_postcode": "\"21202\"",
                "address_state": "\"MD\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"sandwich\"",
                "latitude": 39.2892709,
                "longitude": -76.6107647,
                "name": "\"Au Bon Pain\"",
                "opening_hours": "\"Mo-Fr 07:00-16:00\"",
                "phone": "\"410-837-9814\"",
                "takeaway": "\"yes\"",
                "website": "\"http://www.aubonpain.com/stores/commerce-place\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "teds_montana_grill": {
        "__id": 24,
        "id": "teds_montana_grill",
        "name": "Ted's Montana Grill",
        "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'name', 'phone', 'street_address']",
        "rowLength": 39,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763452,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of teds_montana_grill locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 13,
                "address_city": "\"Jacksonville\"",
                "address_full": "\"10281 Midtown Parkway, Suite 221, Jacksonville, FL 32246\"",
                "address_postcode": "\"Jacksonville, FL 32246\"",
                "address_state": "\"Florida\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american\"",
                "name": "\"Ted's Montana Grill\"",
                "phone": "\"904-998-0010\"",
                "street_address": "\"10281 Midtown Parkway, Suite 221\""
            },
            {
                "__id": 8,
                "address_city": "\"Westminster\"",
                "address_full": "\"11950 Bradburn Blvd., Westminster, CO 80031\"",
                "address_postcode": "\"Westminster, CO 80031\"",
                "address_state": "\"Colorado\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american\"",
                "name": "\"Ted's Montana Grill\"",
                "phone": "\"303-410-8337\"",
                "street_address": "\"11950 Bradburn Blvd.\""
            },
            {
                "__id": 31,
                "address_city": "\"Bozeman\"",
                "address_full": "\"105 W. Main Street, Suite B, Bozeman, MT 59715\"",
                "address_postcode": "\"Bozeman, MT 59715\"",
                "address_state": "\"Montana\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american\"",
                "name": "\"Ted's Montana Grill\"",
                "phone": "\"406-587-6000\"",
                "street_address": "\"105 W. Main Street, Suite B\""
            },
            {
                "__id": 7,
                "address_city": "\"Littleton\"",
                "address_full": "\"7301 S. Santa Fe Drive, Suite 610, Littleton, CO 80120\"",
                "address_postcode": "\"Littleton, CO 80120\"",
                "address_state": "\"Colorado\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american\"",
                "name": "\"Ted's Montana Grill\"",
                "phone": "\"720-283-2303\"",
                "street_address": "\"7301 S. Santa Fe Drive, Suite 610\""
            },
            {
                "__id": 11,
                "address_city": "\"Estero\"",
                "address_full": "\"8017 Plaza Del Lago Drive, Estero, FL 33928\"",
                "address_postcode": "\"Estero, FL 33928\"",
                "address_state": "\"Florida\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american\"",
                "name": "\"Ted's Montana Grill\"",
                "phone": "\"239-947-9318\"",
                "street_address": "\"8017 Plaza Del Lago Drive\""
            },
            {
                "__id": 9,
                "address_city": "\"South Windsor\"",
                "address_full": "\"500 Evergreen Way, Suite 500, South Windsor, CT 06074\"",
                "address_postcode": "\"South Windsor, CT 06074\"",
                "address_state": "\"Connecticut\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american\"",
                "name": "\"Ted's Montana Grill\"",
                "phone": "\"860-648-1100\"",
                "street_address": "\"500 Evergreen Way, Suite 500\""
            },
            {
                "__id": 17,
                "address_city": "\"Atlanta\"",
                "address_full": "\"2250 Marietta Blvd NW, Atlanta, GA 30318\"",
                "address_postcode": "\"Atlanta, GA 30318\"",
                "address_state": "\"Georgia\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american\"",
                "name": "\"Ted's Montana Grill\"",
                "phone": "\"404-343-3406\"",
                "street_address": "\"2250 Marietta Blvd NW\""
            },
            {
                "__id": 14,
                "address_city": "\"Tallahassee\"",
                "address_full": "\"1954 Village Green Way, Tallahassee, FL 32308\"",
                "address_postcode": "\"Tallahassee, FL 32308\"",
                "address_state": "\"Florida\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american\"",
                "name": "\"Ted's Montana Grill\"",
                "phone": "\"850-561-8337\"",
                "street_address": "\"1954 Village Green Way\""
            },
            {
                "__id": 30,
                "address_city": "\"Gaithersburg\"",
                "address_full": "\"105 Ellington Boulevard, Gaithersburg, MD 20878\"",
                "address_postcode": "\"Gaithersburg, MD 20878\"",
                "address_state": "\"Maryland\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american\"",
                "name": "\"Ted's Montana Grill\"",
                "phone": "\"301-330-0777\"",
                "street_address": "\"105 Ellington Boulevard\""
            },
            {
                "__id": 10,
                "address_city": "\"Newark\"",
                "address_full": "\"3194 Fashion Center Boulevard, Newark, DE 19702\"",
                "address_postcode": "\"Newark, DE 19702\"",
                "address_state": "\"Delaware\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american\"",
                "name": "\"Ted's Montana Grill\"",
                "phone": "\"302-366-1601 \"",
                "street_address": "\"3194 Fashion Center Boulevard\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "millercarter": {
        "__id": 25,
        "id": "millercarter",
        "name": "Miller & Carter",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'street_address', 'twitter', 'website']",
        "rowLength": 117,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763452,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of millercarter locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 8,
                "address_city": "\"Hatfield\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"AL9 6NA\"",
                "address_state": "\"Hertfordshire\"",
                "latitude": 51.731977,
                "longitude": -0.18495,
                "name": "\"Miller & Carter Brookmans Park Potters Bar\"",
                "opening_hours": "\"Mo-Sa 12:00-23:00; Su 12:00-22:30\"",
                "phone": "\"01707 647429\"",
                "street_address": "\"Great North Road, Brookmans Park\"",
                "twitter": "\"sysadmin_ds@caci.co.uk\"",
                "website": "\"https://www.millerandcarter.co.uk/restaurants/east-of-england/millerandcarterbrookmanspark\""
            },
            {
                "__id": 17,
                "address_city": "\"Sheffield\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"S9 2EP\"",
                "address_state": "\"South Yorkshire\"",
                "latitude": 53.401467,
                "longitude": -1.415713,
                "name": "\"Miller & Carter Sheffield Centertainment Sheffield\"",
                "opening_hours": "\"Mo-Sa 12:00-23:00; Su 12:00-22:00\"",
                "phone": "\"0114 261 9836\"",
                "street_address": "\"Valley Centertainment, Broughton Lane\"",
                "twitter": "\"sysadmin_ds@caci.co.uk\"",
                "website": "\"https://www.millerandcarter.co.uk/restaurants/yorkshire-and-the-humber/millerandcartersheffieldcentertainment\""
            },
            {
                "__id": 53,
                "address_city": "\"Reading\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"RG1 2AG\"",
                "address_state": "\"Berkshire\"",
                "latitude": 51.453333,
                "longitude": -0.969506,
                "name": "\"Miller & Carter Oracle Reading\"",
                "opening_hours": "\"Mo-Th 12:00-22:00; Fr-Sa 12:00-22:30; Su 12:00-22:00\"",
                "phone": "\"0118 950 9961\"",
                "street_address": "\"Unit 5, The Oracle Centre\"",
                "twitter": "\"sysadmin_ds@caci.co.uk\"",
                "website": "\"https://www.millerandcarter.co.uk/restaurants/south-east/millerandcarteroracle\""
            },
            {
                "__id": 11,
                "address_city": "\"Telford\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"TF3 4JG\"",
                "address_state": "\"Shropshire\"",
                "latitude": 52.673781,
                "longitude": -2.448386,
                "name": "\"Miller & Carter Telford Telford\"",
                "opening_hours": "\"Mo-Sa 12:00-23:00; Su 12:00-22:00\"",
                "phone": "\"01952 200345\"",
                "street_address": "\"Unit 2a, Southwater Square\"",
                "twitter": "\"sysadmin_ds@caci.co.uk\"",
                "website": "\"https://www.millerandcarter.co.uk/restaurants/east-and-west-midlands/millerandcartertelford\""
            },
            {
                "__id": 84,
                "address_city": "\"Dartford\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"DA2 6NQ\"",
                "address_state": "\"Kent\"",
                "latitude": 51.437615,
                "longitude": 0.24069,
                "name": "\"Miller & Carter Dartford Dartford\"",
                "opening_hours": "\"Mo-Sa 12:00-23:00; Su 12:00-22:30\"",
                "phone": "\"01322 287766\"",
                "street_address": "\"Princes Road\"",
                "twitter": "\"sysadmin_ds@caci.co.uk\"",
                "website": "\"https://www.millerandcarter.co.uk/restaurants/london/millerandcarterdartford\""
            },
            {
                "__id": 64,
                "address_city": "\"Edinburgh\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"EH2 2ND\"",
                "address_state": "\"\"",
                "latitude": 55.952736,
                "longitude": -3.199928,
                "name": "\"Miller & Carter Edinburgh City\"",
                "opening_hours": "\"Mo-Sa 12:00-23:00; Su 12:00-22:00\"",
                "phone": "\"0131 603 6080\"",
                "street_address": "\"29-31 Frederick Street\"",
                "twitter": "\"sysadmin_ds@caci.co.uk\"",
                "website": "\"https://www.millerandcarter.co.uk/restaurants/scotland-and-northern-ireland/millerandcarteredinburghcitycentre\""
            },
            {
                "__id": 7,
                "address_city": "\"Chelmsford\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"CM2 6NL\"",
                "address_state": "\"Essex\"",
                "latitude": 51.731269,
                "longitude": 0.496933,
                "name": "\"Miller & Carter Chelmsford Chelmsford\"",
                "opening_hours": "\"Mo-Sa 12:00-23:00; Su 12:00-22:30\"",
                "phone": "\"01245 495336\"",
                "street_address": "\"Barnes Mill Road\"",
                "twitter": "\"sysadmin_ds@caci.co.uk\"",
                "website": "\"https://www.millerandcarter.co.uk/restaurants/east-of-england/millerandcarterchelmsford\""
            },
            {
                "__id": 100,
                "address_city": "\"Leigh-On-Sea\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"SS9 5HR\"",
                "address_state": "\"Essex\"",
                "latitude": 51.574792,
                "longitude": 0.634728,
                "name": "\"Miller & Carter Leigh-on-Sea Essex\"",
                "opening_hours": "\"Mo-Su 12:00-23:00\"",
                "phone": "\"01702 522357\"",
                "street_address": "\"629 Rayleigh Road, Eastwood\"",
                "twitter": "\"sysadmin_ds@caci.co.uk\"",
                "website": "\"https://www.millerandcarter.co.uk/restaurants/east-of-england/millerandcarterleighonsea\""
            },
            {
                "__id": 3,
                "address_city": "\"Ipswich\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"IP10 0DF\"",
                "address_state": "\"Suffolk\"",
                "latitude": 52.02824,
                "longitude": 1.231108,
                "name": "\"Miller & Carter Ipswich Ipswich\"",
                "opening_hours": "\"Mo-Sa 12:00-23:00; Su 12:00-22:30\"",
                "phone": "\"01473 659210\"",
                "street_address": "\"Felixstowe Road, Nacton\"",
                "twitter": "\"sysadmin_ds@caci.co.uk\"",
                "website": "\"https://www.millerandcarter.co.uk/restaurants/east-of-england/millerandcarteripswich\""
            },
            {
                "__id": 56,
                "address_city": "\"Fleet\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"GU51 2SH\"",
                "address_state": "\"Hampshire\"",
                "latitude": 51.293553,
                "longitude": -0.804995,
                "name": "\"Miller & Carter Fleet Fleet\"",
                "opening_hours": "\"Mo-Sa 12:00-23:00; Su 12:00-22:00\"",
                "phone": "\"01252 816655\"",
                "street_address": "\"Cove Road\"",
                "twitter": "\"sysadmin_ds@caci.co.uk\"",
                "website": "\"https://www.millerandcarter.co.uk/restaurants/south-east/millerandcarterfleet\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "bq": {
        "__id": 26,
        "id": "bq",
        "name": "B&Q",
        "columnNames": "['address_country', 'address_full', 'address_postcode', 'contact_email', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'website']",
        "rowLength": 314,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763454,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of bq locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 115,
                "address_country": "\"GB\"",
                "address_full": "\"Bath Road, Chippenham, WILTSHIRE\"",
                "address_postcode": "\"SN14 0AT\"",
                "contact_email": "\"\"",
                "latitude": 51.4496,
                "longitude": -2.13715,
                "name": "\"B&Q Chippenham\"",
                "opening_hours": "\"Mo-Sa 07:00-20:00; Su 10:00-16:00\"",
                "phone": "\"01249 651144\"",
                "shop": "\"doityourself\"",
                "street_address": "\"Bath Road\"",
                "website": "\"https://www.diy.com/store/1330\""
            },
            {
                "__id": 259,
                "address_country": "\"GB\"",
                "address_full": "\"Bob Hardisty Drive, Newgate Park, Bishop Auckland, DURHAM\"",
                "address_postcode": "\"DL14 7TW\"",
                "contact_email": "\"\"",
                "latitude": 54.6572,
                "longitude": -1.679664,
                "name": "\"B&Q Bishop Auckland\"",
                "opening_hours": "\"Mo-Sa 07:00-20:00; Su 10:00-16:00\"",
                "phone": "\"01388 601577\"",
                "shop": "\"doityourself\"",
                "street_address": "\"Bob Hardisty Drive, Newgate Park\"",
                "website": "\"https://www.diy.com/store/1120\""
            },
            {
                "__id": 313,
                "address_country": "\"GB\"",
                "address_full": "\"Longman Road, Inverness, HIGHLANDS\"",
                "address_postcode": "\"IV1 1RY\"",
                "contact_email": "\"\"",
                "latitude": 57.4873,
                "longitude": -4.222441,
                "name": "\"B&Q Inverness\"",
                "opening_hours": "\"Mo-Sa 07:00-20:00; Su 09:00-18:00\"",
                "phone": "\"01463 238800\"",
                "shop": "\"doityourself\"",
                "street_address": "\"Longman Road\"",
                "website": "\"https://www.diy.com/store/1364\""
            },
            {
                "__id": 34,
                "address_country": "\"GB\"",
                "address_full": "\"Tangent Link, Harold Hill, Romford, LONDON\"",
                "address_postcode": "\"RM3 8GZ\"",
                "contact_email": "\"\"",
                "latitude": 51.595,
                "longitude": 0.219046,
                "name": "\"B&Q Romford - Tangent Link\"",
                "opening_hours": "\"Mo-Sa 07:00-20:00; Su 10:00-16:00\"",
                "phone": "\"01708 336600\"",
                "shop": "\"doityourself\"",
                "street_address": "\"Tangent Link, Harold Hill\"",
                "website": "\"https://www.diy.com/store/1257\""
            },
            {
                "__id": 239,
                "address_country": "\"GB\"",
                "address_full": "\"Old Towy Sawmills, Glanyrafon Road, Carmarthen, CARMARTHENSHIRE\"",
                "address_postcode": "\"SA31 3AS\"",
                "contact_email": "\"\"",
                "latitude": 51.8515,
                "longitude": -4.31667,
                "name": "\"B&Q Carmarthen\"",
                "opening_hours": "\"Mo-Sa 08:00-20:00; Su 10:00-16:00\"",
                "phone": "\"01267 220420\"",
                "shop": "\"doityourself\"",
                "street_address": "\"Old Towy Sawmills, Glanyrafon Road\"",
                "website": "\"https://www.diy.com/store/1197\""
            },
            {
                "__id": 25,
                "address_country": "\"GB\"",
                "address_full": "\"Asda Store, Merrielands Crescent, Dagenham\"",
                "address_postcode": "\"RM9 6SJ\"",
                "contact_email": "\"\"",
                "latitude": 51.5305057,
                "longitude": 0.1417745,
                "name": "\"B&Q Dagenham (Located inside Asda)\"",
                "opening_hours": "\"Mo-Sa 08:00-20:00; Su 11:00-17:00\"",
                "phone": "\"0208 5938020\"",
                "shop": "\"doityourself\"",
                "street_address": "\"Asda Store, Merrielands Crescent\"",
                "website": "\"https://www.diy.com/store/2671\""
            },
            {
                "__id": 45,
                "address_country": "\"GB\"",
                "address_full": "\"Pipps Hill Retail Park, Miles Gray Road, Upper Mayne, Basildon, ESSEX\"",
                "address_postcode": "\"SS14 3AF\"",
                "contact_email": "\"\"",
                "latitude": 51.5799,
                "longitude": 0.444408,
                "name": "\"B&Q Basildon\"",
                "opening_hours": "\"Mo-Fr 07:00-20:00; Sa 08:00-20:00; Su 10:00-16:00\"",
                "phone": "\"01268 534884\"",
                "shop": "\"doityourself\"",
                "street_address": "\"Pipps Hill Retail Park, Miles Gray Road, Upper Mayne\"",
                "website": "\"https://www.diy.com/store/1199\""
            },
            {
                "__id": 14,
                "address_country": "\"GB\"",
                "address_full": "\"Unit 3, Gateway Retail Park, 8 Clapsgate Lane, Beckton Triangle, Beckton, London, LONDON\"",
                "address_postcode": "\"E6 6LG\"",
                "contact_email": "\"\"",
                "latitude": 51.5195,
                "longitude": 0.071325,
                "name": "\"B&Q Beckton\"",
                "opening_hours": "\"Mo-Sa 07:00-20:00; Su 10:00-16:00\"",
                "phone": "\"0208 709 1100\"",
                "shop": "\"doityourself\"",
                "street_address": "\"Unit 3, Gateway Retail Park, 8 Clapsgate Lane, Beckton Triangle, Beckton\"",
                "website": "\"https://www.diy.com/store/1271\""
            },
            {
                "__id": 230,
                "address_country": "\"GB\"",
                "address_full": "\"Frith Street, Wigan, GREATER MANCHESTER\"",
                "address_postcode": "\"WN5 0XN\"",
                "contact_email": "\"\"",
                "latitude": 53.5428,
                "longitude": -2.647727,
                "name": "\"B&Q Wigan\"",
                "opening_hours": "\"Mo-Sa 07:00-20:00; Su 10:00-16:00\"",
                "phone": "\"01942 821567\"",
                "shop": "\"doityourself\"",
                "street_address": "\"Frith Street\"",
                "website": "\"https://www.diy.com/store/1207\""
            },
            {
                "__id": 88,
                "address_country": "\"GB\"",
                "address_full": "\"Nene Valley Retail Park, Towcester Road, Northampton, NORTHAMPTONSHIRE\"",
                "address_postcode": "\"NN1 1BQ\"",
                "contact_email": "\"\"",
                "latitude": 52.2319,
                "longitude": -0.903252,
                "name": "\"B&Q Northampton\"",
                "opening_hours": "\"Mo-Sa 07:00-20:00; Su 10:00-16:00\"",
                "phone": "\"01604 823100\"",
                "shop": "\"doityourself\"",
                "street_address": "\"Nene Valley Retail Park, Towcester Road\"",
                "website": "\"https://www.diy.com/store/1293\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "anthonys_restaurants": {
        "__id": 27,
        "id": "anthonys_restaurants",
        "name": "Anthony's Restaurants",
        "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'phone', 'website']",
        "rowLength": 35,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763455,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of anthonys_restaurants locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 7,
                "address_city": "\"Boise\"",
                "address_full": "\"959 W. Front Street\"",
                "address_postcode": "\"83702\"",
                "address_state": "\"ID\"",
                "latitude": 43.614901617916615,
                "longitude": -116.20677325959325,
                "name": "\"Anthony\u2019s Blend ~ Boise\"",
                "phone": "\"+12082022468\"",
                "website": "\"https://www.anthonys.com/restaurant/anthonys-boise-coffee-bar/\""
            },
            {
                "__id": 8,
                "address_city": "\"Seattle\"",
                "address_full": "\"2201 Alaskan Way\"",
                "address_postcode": "\"98121\"",
                "address_state": "\"WA\"",
                "latitude": 47.610817,
                "longitude": -122.348189,
                "name": "\"Anthony\u2019s Bell Street Diner\"",
                "phone": "\"+12064486688\"",
                "website": "\"https://www.anthonys.com/restaurant/anthonys-bell-street-diner/\""
            },
            {
                "__id": 12,
                "address_city": "\"Spokane\"",
                "address_full": "\"510 North Lincoln\"",
                "address_postcode": "\"99201\"",
                "address_state": "\"WA\"",
                "latitude": 47.662849,
                "longitude": -117.424571,
                "name": "\"Anthony\u2019s at Spokane Falls\"",
                "phone": "\"+15093289009\"",
                "website": "\"https://www.anthonys.com/restaurant/anthonys-at-spokane-falls/\""
            },
            {
                "__id": 20,
                "address_city": "\"Everett\"",
                "address_full": "\"11830 19th Ave SE\"",
                "address_postcode": "\"98208\"",
                "address_state": "\"WA\"",
                "latitude": 47.8898318,
                "longitude": -122.2094727,
                "name": "\"Emory\u2019s on Silver Lake\"",
                "phone": "\"\"",
                "website": "\"https://www.anthonys.com/restaurant/emorys-on-silver-lake/\""
            },
            {
                "__id": 5,
                "address_city": "\"Seattle\"",
                "address_full": "\"2201 Alaskan Way\"",
                "address_postcode": "\"98121\"",
                "address_state": "\"WA\"",
                "latitude": 47.610817,
                "longitude": -122.348189,
                "name": "\"Anthony\u2019s Fish Bar\"",
                "phone": "\"+12064486688\"",
                "website": "\"https://www.anthonys.com/restaurant/anthonys-fish-bar/\""
            },
            {
                "__id": 9,
                "address_city": "\"Edmonds\"",
                "address_full": "\"456 Admiral Way\"",
                "address_postcode": "\"98020\"",
                "address_state": "\"WA\"",
                "latitude": 47.807976,
                "longitude": -122.391032,
                "name": "\"Anthony\u2019s Beach Cafe\"",
                "phone": "\"+14257714400\"",
                "website": "\"https://www.anthonys.com/restaurant/anthonys-beach-cafe/\""
            },
            {
                "__id": 35,
                "address_city": "\"Boise\"",
                "address_full": "\"959 W. Front Street\"",
                "address_postcode": "\"83702\"",
                "address_state": "\"ID\"",
                "latitude": 43.614901617916615,
                "longitude": -116.20677325959325,
                "name": "\"Anthony\u2019s at\u00a0Boise\"",
                "phone": "\"+12082022468\"",
                "website": "\"https://www.anthonys.com/restaurant/anthonys-at-boise/\""
            },
            {
                "__id": 23,
                "address_city": "\"Seattle\"",
                "address_full": "\"1900 W. Nickerson St\"",
                "address_postcode": "\"98119\"",
                "address_state": "\"WA\"",
                "latitude": 47.655645,
                "longitude": -122.381131,
                "name": "\"Chinook\u2019s at Salmon Bay,  Fishermen\u2019s Terminal \u2013 Ballard\"",
                "phone": "\"+12062834665\"",
                "website": "\"https://www.anthonys.com/restaurant/chinooks-at-salmon-bay/\""
            },
            {
                "__id": 24,
                "address_city": "\"Richland\"",
                "address_full": "\"450 Columbia Point Drive\"",
                "address_postcode": "\"99352\"",
                "address_state": "\"WA\"",
                "latitude": 46.264376,
                "longitude": -119.253331,
                "name": "\"Budd\u2019s Broiler\"",
                "phone": "\"+15099468178\"",
                "website": "\"https://www.anthonys.com/restaurant/budds-broiler/\""
            },
            {
                "__id": 32,
                "address_city": "\"Edmonds\"",
                "address_full": "\"456 Admiral Way\"",
                "address_postcode": "\"98020\"",
                "address_state": "\"WA\"",
                "latitude": 47.807976,
                "longitude": -122.391032,
                "name": "\"Anthony\u2019s HomePort Edmonds\"",
                "phone": "\"+14257714400\"",
                "website": "\"https://www.anthonys.com/restaurant/anthonys-homeport-edmonds/\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "sparkasse": {
        "__id": 28,
        "id": "sparkasse",
        "name": "Sparkasse",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_street', 'contact_email', 'fax', 'image', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'website']",
        "rowLength": 8818,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763456,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of sparkasse locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 7381,
                "address_city": "\"Unterspreewald\"",
                "address_country": "\"DE\"",
                "address_postcode": "\"15910\"",
                "address_street": "\"Dorfstra\u00dfe \"",
                "contact_email": "\"kontakt@mbs.de\"",
                "fax": "\"\"",
                "image": "\"/etc/designs/shared/static/images/filialfinder/filiale.png\"",
                "latitude": 51.9354548,
                "longitude": 13.7816683,
                "name": "\"Mittelbrandenburgische Sparkasse in Potsdam - Fahrbare Gesch\u00e4ftsstelle Niewitz\"",
                "opening_hours": "\"We 10:15-10:30\"",
                "phone": "\"+49331/89 89 89 \"",
                "website": "\"https://www.sparkasse.de/filialen/u/mittelbrandenburgische-sparkasse-in-potsdam-fahrbare-geschaeftsstelle-niewitz/171695.html\""
            },
            {
                "__id": 8623,
                "address_city": "\"Rheinberg\"",
                "address_country": "\"DE\"",
                "address_postcode": "\"47495\"",
                "address_street": "\"Bahnhofstra\u00dfe 5\"",
                "contact_email": "\"bahnhofstrasse@sk-an.de\"",
                "fax": "\"+492843/177-573071\"",
                "image": "\"https://www.sparkasse.de/filialfinder-images/109885/1/Objektbild.jpg\"",
                "latitude": 51.5437781,
                "longitude": 6.5990094,
                "name": "\"Sparkasse am Niederrhein - Gesch\u00e4ftsstelle Bahnhofstra\u00dfe\"",
                "opening_hours": "\"Mo-We 09:00-13:00,14:00-16:30; Th 09:00-13:00,14:00-18:00; Fr 09:00-13:00,14:00-16:30\"",
                "phone": "\"+492843/177-3070\"",
                "website": "\"https://www.sparkasse.de/filialen/r/sparkasse-am-niederrhein-geschaeftsstelle-bahnhofstrasse/109885.html\""
            },
            {
                "__id": 397,
                "address_city": "\"Ohorn\"",
                "address_country": "\"DE\"",
                "address_postcode": "\"01896\"",
                "address_street": "\"Schulstra\u00dfe 2\"",
                "contact_email": "\"e-mail@sparkasse-dresden.de\"",
                "fax": "\"\"",
                "image": "\"https://www.sparkasse.de/filialfinder-images/161703/1/fahrbare_filiale.jpg\"",
                "latitude": 51.1756217,
                "longitude": 14.0486965,
                "name": "\"Osts\u00e4chsische Sparkasse Dresden - Sparkassenmobil Ohorn\"",
                "opening_hours": "\"Tu 11:30-12:00\"",
                "phone": "\"+49351/455-0\"",
                "website": "\"https://www.sparkasse.de/filialen/o/ostsaechsische-sparkasse-dresden-sparkassenmobil-ohorn/161703.html\""
            },
            {
                "__id": 6875,
                "address_city": "\"Weyhe\"",
                "address_country": "\"DE\"",
                "address_postcode": "\"28844\"",
                "address_street": "\"Bremer Stra\u00dfe 48a\"",
                "contact_email": "\"erichshof-melchiorshausen@ksk-syke.de\"",
                "fax": "\"+494242/161-7171\"",
                "image": "\"https://www.sparkasse.de/filialfinder-images/109522/1/ErichshofMelchiorshausen223x178.jpg\"",
                "latitude": 52.9891207,
                "longitude": 8.7863706,
                "name": "\"Kreissparkasse Syke - Gesch\u00e4ftsstelle Erichshof-Melchiorshausen\"",
                "opening_hours": "\"Mo-Tu 09:00-12:30,14:00-17:00; We 09:00-12:30; Th 09:00-12:30,14:00-18:00; Fr 09:00-14:00\"",
                "phone": "\"+494242/161-6171\"",
                "website": "\"https://www.sparkasse.de/filialen/w/kreissparkasse-syke-geschaeftsstelle-erichshof-melchiorshausen/109522.html\""
            },
            {
                "__id": 7136,
                "address_city": "\"Wandlitz\"",
                "address_country": "\"DE\"",
                "address_postcode": "\"16348\"",
                "address_street": "\"Prenzlauer Chaussee 155\"",
                "contact_email": "\"if@sparkasse-barnim.de\"",
                "fax": "\"+493334/20059 -1700\"",
                "image": "\"https://www.sparkasse.de/filialfinder-images/124570/1/Wandlitz.JPG\"",
                "latitude": 52.75422,
                "longitude": 13.47277,
                "name": "\"Sparkasse Barnim - Beratungs-Center Wandlitz\"",
                "opening_hours": "\"Mo 09:00-13:00,14:00-16:00; Tu 09:00-13:00,14:00-18:00; We 09:00-13:00; Th 09:00-13:00,14:00-18:00; Fr 09:00-13:00\"",
                "phone": "\"+493334/200 -1700\"",
                "website": "\"https://www.sparkasse.de/filialen/w/sparkasse-barnim-beratungs-center-wandlitz/124570.html\""
            },
            {
                "__id": 6132,
                "address_city": "\"Bad Ems\"",
                "address_country": "\"DE\"",
                "address_postcode": "\"56130\"",
                "address_street": "\"R\u00f6merstr. 91\"",
                "contact_email": "\"info@naspa.de\"",
                "fax": "\"+49611/364-55299\"",
                "image": "\"https://www.sparkasse.de/filialfinder-images/60248/1/552.jpg\"",
                "latitude": 50.3333595,
                "longitude": 7.71665138,
                "name": "\"Nassauische Sparkasse - Finanz-Center Bad Ems\"",
                "opening_hours": "\"Mo 09:00-12:30,14:00-18:00; Tu-We 09:00-12:30,14:00-16:00; Th 09:00-12:30,14:00-18:00; Fr 09:00-12:30,14:00-16:00\"",
                "phone": "\"+49611/364-55202\"",
                "website": "\"https://www.sparkasse.de/filialen/b/nassauische-sparkasse-finanz-center-bad-ems/60248.html\""
            },
            {
                "__id": 7460,
                "address_city": "\"\u00dcberherrn\"",
                "address_country": "\"DE\"",
                "address_postcode": "\"66802\"",
                "address_street": "\"Auf der Spees 16\"",
                "contact_email": "\"service@ksk-saarlouis.de\"",
                "fax": "\"+496831/4421000\"",
                "image": "\"https://www.sparkasse.de/filialfinder-images/7241/1/Objektbild.jpg\"",
                "latitude": 49.2731804,
                "longitude": 6.7082112,
                "name": "\"Kreissparkasse Saarlouis - SB-Standort Altforweiler\"",
                "opening_hours": "\"Mo-Su 06:00-22:00\"",
                "phone": "\"+496831/4420\"",
                "website": "\"https://www.sparkasse.de/filialen/u/kreissparkasse-saarlouis-sb-standort-altforweiler/7241.html\""
            },
            {
                "__id": 4584,
                "address_city": "\"D\u00fcsseldorf\"",
                "address_country": "\"DE\"",
                "address_postcode": "\"40225\"",
                "address_street": "\"Moorenstra\u00dfe 5\"",
                "contact_email": "\"service@sskduesseldorf.de\"",
                "fax": "\"+49211/8781748\"",
                "image": "\"/etc/designs/shared/static/images/filialfinder/sbcenter.png\"",
                "latitude": 51.1972531,
                "longitude": 6.79219239,
                "name": "\"Stadtsparkasse D\u00fcsseldorf - SB-Center Universit\u00e4tsklinikum\"",
                "opening_hours": "\"Mo-Su 06:00-24:00\"",
                "phone": "\"+49211/8782211\"",
                "website": "\"https://www.sparkasse.de/filialen/d/stadtsparkasse-duesseldorf-sb-center-universitaetsklinikum/174293.html\""
            },
            {
                "__id": 8689,
                "address_city": "\"Reinholdshain\"",
                "address_country": "\"DE\"",
                "address_postcode": "\"01744\"",
                "address_street": "\"Industriering 2\"",
                "contact_email": "\"e-mail@sparkasse-dresden.de\"",
                "fax": "\"\"",
                "image": "\"/etc/designs/shared/static/images/filialfinder/sbcenter.png\"",
                "latitude": 50.89745,
                "longitude": 13.694165,
                "name": "\"Osts\u00e4chsische Sparkasse Dresden - SB-Filiale Reinholdshain\"",
                "opening_hours": "\"24/7\"",
                "phone": "\"+49351/455-0\"",
                "website": "\"https://www.sparkasse.de/filialen/r/ostsaechsische-sparkasse-dresden-sb-filiale-reinholdshain/174488.html\""
            },
            {
                "__id": 8775,
                "address_city": "\"Ratingen\"",
                "address_country": "\"DE\"",
                "address_postcode": "\"40880\"",
                "address_street": "\"Westtangente 69\"",
                "contact_email": "\"info@sparkasse-hrv.de\"",
                "fax": "\"+492051/315-42590\"",
                "image": "\"https://www.sparkasse.de/filialfinder-images/6516/1/objektehrv223x178.png\"",
                "latitude": 51.2945743,
                "longitude": 6.8288354,
                "name": "\"Sparkasse Hilden-Ratingen-Velbert - Filiale Westtangente\"",
                "opening_hours": "\"Mo-We 09:00-13:00; Th 09:00-13:00,14:00-18:00; Fr 09:00-13:00\"",
                "phone": "\"+492051/315-0\"",
                "website": "\"https://www.sparkasse.de/filialen/r/sparkasse-hilden-ratingen-velbert-filiale-westtangente/6516.html\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "cafe_zupas": {
        "__id": 29,
        "id": "cafe_zupas",
        "name": "Cafe Zupas",
        "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'facebook', 'image', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'website']",
        "rowLength": 61,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763457,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of cafe_zupas locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 50,
                "address_city": "\"Sandy\"",
                "address_full": "\"9242 South Village Shop Drive\"",
                "address_postcode": "\"84094\"",
                "address_state": "\"UT\"",
                "facebook": "\"https://www.facebook.com/Cafe-Zupas-195915417648574\"",
                "image": "\"https://cafezupas.com/Locations/12/location-12.webp\"",
                "latitude": 40.5835148,
                "longitude": -111.8631348,
                "name": "\"Quarry Bend\"",
                "opening_hours": "\"Mo-Th 11:00-21:00; Fr-Sa 11:00-22:00\"",
                "phone": "\"385-212-2612\"",
                "website": "\"https://cafezupas.com/locationcopy/info/quarry-bend\""
            },
            {
                "__id": 22,
                "address_city": "\"Edina\"",
                "address_full": "\"2950 W. 66th St\"",
                "address_postcode": "\"55423\"",
                "address_state": "\"MN\"",
                "facebook": "\"https://www.facebook.com/Cafe-Zupas-125237671638612\"",
                "image": "\"https://cafezupas.com/Locations/77/location-77.webp\"",
                "latitude": 44.8848898,
                "longitude": -93.3183676,
                "name": "\"Edina\"",
                "opening_hours": "\"Mo-Th 11:00-21:00; Fr-Sa 11:00-22:00\"",
                "phone": "\"612-568-1407\"",
                "website": "\"https://cafezupas.com/locationcopy/info/edina\""
            },
            {
                "__id": 16,
                "address_city": "\"Schaumburg\"",
                "address_full": "\"1422 E. Golf Rd.\"",
                "address_postcode": "\"60173\"",
                "address_state": "\"IL\"",
                "facebook": "\"https://www.facebook.com/Cafe-Zupas-137602746953515\"",
                "image": "\"https://cafezupas.com/Locations/65/location-65.webp\"",
                "latitude": 42.0509032,
                "longitude": -88.0429218,
                "name": "\"Schaumburg\"",
                "opening_hours": "\"Mo-Th 11:00-21:00; Fr-Sa 11:00-22:00\"",
                "phone": "\"630-259-4062\"",
                "website": "\"https://cafezupas.com/locationcopy/info/schaumburg\""
            },
            {
                "__id": 20,
                "address_city": "\"Eagan\"",
                "address_full": "\"3340 Central Park Village Dr.\"",
                "address_postcode": "\"55121\"",
                "address_state": "\"MN\"",
                "facebook": "\"https://www.facebook.com/Cafe-Zupas-969948763155113\"",
                "image": "\"https://cafezupas.com/Locations/68/location-68.webp\"",
                "latitude": 44.836463,
                "longitude": -93.1697688,
                "name": "\"Eagan\"",
                "opening_hours": "\"Mo-Th 11:00-21:00; Fr-Sa 11:00-22:00\"",
                "phone": "\"763-412-1514\"",
                "website": "\"https://cafezupas.com/locationcopy/info/eagan\""
            },
            {
                "__id": 25,
                "address_city": "\"Woodbury\"",
                "address_full": "\"365 Radio Drive Suite E\"",
                "address_postcode": "\"55125\"",
                "address_state": "\"MN\"",
                "facebook": "\"https://www.facebook.com/pages/Cafe-Zupas/166598330608022?_fb_noscript=1\"",
                "image": "\"https://cafezupas.com/Locations/51/location-51.webp\"",
                "latitude": 44.9423211,
                "longitude": -92.9321492,
                "name": "\"Woodbury\"",
                "opening_hours": "\"Mo-Th 11:00-21:00; Fr-Sa 11:00-22:00\"",
                "phone": "\"763-277-4521\"",
                "website": "\"https://cafezupas.com/locationcopy/info/woodbury\""
            },
            {
                "__id": 60,
                "address_city": "\"Menomonee Falls WI\"",
                "address_full": "\"N 91 W 15909 Falls Parkway\"",
                "address_postcode": "\"53051\"",
                "address_state": "\"WI\"",
                "facebook": "\"https://www.facebook.com/Cafe-Zupas-1168494309962147\"",
                "image": "\"https://cafezupas.com/Locations/75/location-75.webp\"",
                "latitude": 43.1844698,
                "longitude": -88.1077899,
                "name": "\"Menomonee Falls\"",
                "opening_hours": "\"Mo-Th 11:00-21:00; Fr-Sa 11:00-22:00\"",
                "phone": "\"262-415-9051\"",
                "website": "\"https://cafezupas.com/locationcopy/info/menomonee-falls\""
            },
            {
                "__id": 14,
                "address_city": "\"Deerfield\"",
                "address_full": "\"711 Deerfield Road, Unit C\"",
                "address_postcode": "\"60015\"",
                "address_state": "\"IL\"",
                "facebook": "\"https://www.facebook.com/pages/Cafe-Zupas/2097768593675021?_fb_noscript=1\"",
                "image": "\"https://cafezupas.com/Locations/84/location-84.webp\"",
                "latitude": 42.1671042,
                "longitude": -87.8464958,
                "name": "\"Deerfield\"",
                "opening_hours": "\"Mo-Th 11:00-21:00; Fr-Sa 11:00-22:00\"",
                "phone": "\"224-551-6001\"",
                "website": "\"https://cafezupas.com/locationcopy/info/deerfield\""
            },
            {
                "__id": 9,
                "address_city": "\"Boise\"",
                "address_full": "\"129 Broadway St.\"",
                "address_postcode": "\"83702\"",
                "address_state": "\"ID\"",
                "facebook": "\"https://www.facebook.com/Cafe-Zupas-1786101221413975\"",
                "image": "\"https://cafezupas.com/Locations/55/location-55.webp\"",
                "latitude": 43.6099011,
                "longitude": -116.1939154,
                "name": "\"Boise Downtown\"",
                "opening_hours": "\"Mo-Th 11:00-21:00; Fr-Sa 11:00-22:00\"",
                "phone": "\"208-401-1414\"",
                "website": "\"https://cafezupas.com/locationcopy/info/boise-downtown\""
            },
            {
                "__id": 37,
                "address_city": "\"Salt Lake City\"",
                "address_full": "\"613 East 400 South\"",
                "address_postcode": "\"84102\"",
                "address_state": "\"UT\"",
                "facebook": "\"\"",
                "image": "\"https://cafezupas.com/Locations/31/location-31.webp\"",
                "latitude": 40.7616341,
                "longitude": -111.8733471,
                "name": "\"Downtown\"",
                "opening_hours": "\"Mo-Th 11:00-21:00; Fr-Sa 11:00-22:00\"",
                "phone": "\"801-746-0138\"",
                "website": "\"https://cafezupas.com/locationcopy/info/downtown\""
            },
            {
                "__id": 32,
                "address_city": "\"Las Vegas\"",
                "address_full": "\"1381 West Sunset Road\"",
                "address_postcode": "\"89014\"",
                "address_state": "\"NV\"",
                "facebook": "\"\"",
                "image": "\"https://cafezupas.com/Locations/45/location-45.webp\"",
                "latitude": 36.0710314,
                "longitude": -115.1731257,
                "name": "\"Stephanie\"",
                "opening_hours": "\"Mo-Th 11:00-21:00; Fr-Sa 11:00-22:00\"",
                "phone": "\"702-978-4444\"",
                "website": "\"https://cafezupas.com/locationcopy/info/stephanie\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "therapie_clinic": {
        "__id": 30,
        "id": "therapie_clinic",
        "name": "Therapie Clinic",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'latitude', 'longitude', 'name', 'phone', 'street_address', 'website']",
        "rowLength": 68,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763457,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of therapie_clinic locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 60,
                "address_city": "\"Dublin\"",
                "address_country": "\"IE\"",
                "address_postcode": "\"\"",
                "latitude": 53.34119,
                "longitude": -6.25734,
                "name": "\"Molesworth St.\"",
                "phone": "\"01 2238180\"",
                "street_address": "\"8-9 Molesworth St\"",
                "website": "\"https://www.therapieclinic.com/our-clinics/dublin/molesworth-street/\""
            },
            {
                "__id": 24,
                "address_city": "\"Milton Keynes\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"MK9 3GB\"",
                "latitude": 52.04195499999999,
                "longitude": -0.7577847999999999,
                "name": "\"Milton Keynes\"",
                "phone": "\"08000 121 565\"",
                "street_address": "\"Unit 30 Midsummer Arcade\"",
                "website": "\"https://www.therapieclinic.com/our-clinics/milton-keynes/the-centre-mk/\""
            },
            {
                "__id": 25,
                "address_city": "\"Newcastle\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"NE11 9YG\"",
                "latitude": 54.956618,
                "longitude": -1.668843,
                "name": "\"Newcastle\"",
                "phone": "\"08000 121 565\"",
                "street_address": "\"Metro Centre, Gateshead,\"",
                "website": "\"https://www.therapieclinic.com/our-clinics/newcastle/metro-centre/\""
            },
            {
                "__id": 33,
                "address_city": "\"London\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"SW1E 5NH\"",
                "latitude": 51.4969273,
                "longitude": -0.1417033,
                "name": "\"Victoria\"",
                "phone": "\"08000 121 565\"",
                "street_address": "\"147 Victoria Street\"",
                "website": "\"https://www.therapieclinic.com/our-clinics/london/victoria/\""
            },
            {
                "__id": 50,
                "address_city": "\"Dublin\"",
                "address_country": "\"IE\"",
                "address_postcode": "\"Dublin 18\"",
                "latitude": 53.2507218,
                "longitude": -6.1839369,
                "name": "\"Carrickmines\"",
                "phone": "\"01 223 8180\"",
                "street_address": "\"Holbourne Building, Block 60\"",
                "website": "\"https://www.therapieclinic.com/our-clinics/dublin/carrickmines/\""
            },
            {
                "__id": 58,
                "address_city": "\"Limerick\"",
                "address_country": "\"IE\"",
                "address_postcode": "\"\"",
                "latitude": 52.66382,
                "longitude": -8.6256,
                "name": "\"Limerick - Cruises\"",
                "phone": "\"061788652\"",
                "street_address": "\"Cruises Street\"",
                "website": "\"https://www.therapieclinic.com/our-clinics/limerick/cruises-street/\""
            },
            {
                "__id": 40,
                "address_city": "\"Glasgow\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"G2 5TF\"",
                "latitude": 55.861413,
                "longitude": -4.256068,
                "name": "\"Glasgow\"",
                "phone": "\"08000 121 565\"",
                "street_address": "\"85 St Vincent Street\"",
                "website": "\"https://www.therapieclinic.com/our-clinics/glasgow/vincent-street/\""
            },
            {
                "__id": 26,
                "address_city": "\"Newcastle\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"NE1 7JB\"",
                "latitude": 54.9754904,
                "longitude": -1.6174051,
                "name": "\"Newcastle (Eldon Sq)\"",
                "phone": "\"08000 121 565\"",
                "street_address": "\"Unit E7 Blackett Bridge\"",
                "website": "\"https://www.therapieclinic.com/our-clinics/newcastle/eldon-square/\""
            },
            {
                "__id": 54,
                "address_city": "\"Galway\"",
                "address_country": "\"IE\"",
                "address_postcode": "\"\"",
                "latitude": 53.27315,
                "longitude": -9.05205,
                "name": "\"Galway\"",
                "phone": "\"091 530773\"",
                "street_address": "\"42 William Street\"",
                "website": "\"https://www.therapieclinic.com/our-clinics/galway/galway-city/\""
            },
            {
                "__id": 2,
                "address_city": "\"London\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"SW1W9LS\"",
                "latitude": 51.494381,
                "longitude": -0.14712,
                "name": "\"Belgravia\"",
                "phone": "\"08000 121 565\"",
                "street_address": "\"Chantrey House, 4 Eccleston St, Belgravia\"",
                "website": "\"https://www.therapieclinic.com/our-clinics/london/belgravia/\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "wyndham": {
        "__id": 31,
        "id": "wyndham",
        "name": "Wyndham",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'image', 'latitude', 'longitude', 'name', 'official_name', 'phone', 'short_name', 'street_address', 'tourism', 'website']",
        "rowLength": 8292,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763459,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of wyndham locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 1862,
                "address_city": "\"Zhuozhou\"",
                "address_country": "\"CN\"",
                "address_postcode": "\"\"",
                "address_state": "\"\"",
                "image": "\"https://www.wyndhamhotels.com/content/dam/property-images/en-us/se/cn/others/zhuozhou/49269/49269_exterior_view_1.jpg\"",
                "latitude": 39.491275,
                "longitude": 116.002901,
                "name": "\"Super 8 by Wyndham Zhuozhou Fan Yang Lu\"",
                "official_name": "\"\"",
                "phone": "\"+86-10-85502288\"",
                "short_name": "\"\"",
                "street_address": "\"75 Middle Fan Yang Road\"",
                "tourism": "\"hotel\"",
                "website": "\"https://www.wyndhamhotels.com/super-8/zhuozhou-china/super-8-zhuozhou-fan-yang-lu/overview\""
            },
            {
                "__id": 3957,
                "address_city": "\"Port Elgin\"",
                "address_country": "\"CA\"",
                "address_postcode": "\"N0H 2C1\"",
                "address_state": "\"ON\"",
                "image": "\"https://www.wyndhamhotels.com/content/dam/property-images/en-us/tl/ca/on/port-elgin/50691/50691_exterior_view_4.jpg\"",
                "latitude": 44.42717,
                "longitude": -81.39509,
                "name": "\"Travelodge by Wyndham Port Elgin\"",
                "official_name": "\"\"",
                "phone": "\"+1-519-832-2021\"",
                "short_name": "\"\"",
                "street_address": "\"235 Goderich St\"",
                "tourism": "\"hotel\"",
                "website": "\"https://www.wyndhamhotels.com/travelodge/port-elgin-ontario/travelodge-port-elgin/overview\""
            },
            {
                "__id": 7243,
                "address_city": "\"Helena\"",
                "address_country": "\"US\"",
                "address_postcode": "\"59601\"",
                "address_state": "\"MT\"",
                "image": "\"https://www.wyndhamhotels.com/content/dam/property-images/en-us/di/us/mt/helena/06750/06750_exterior_view_1.jpg\"",
                "latitude": 46.590805,
                "longitude": -112.004639,
                "name": "\"Days Inn by Wyndham Helena\"",
                "official_name": "\"\"",
                "phone": "\"+1-406-204-3728\"",
                "short_name": "\"\"",
                "street_address": "\"2001 Prospect Avenue\"",
                "tourism": "\"hotel\"",
                "website": "\"https://www.wyndhamhotels.com/days-inn/helena-montana/days-inn-helena/overview\""
            },
            {
                "__id": 4302,
                "address_city": "\"Edmonds\"",
                "address_country": "\"US\"",
                "address_postcode": "\"98026\"",
                "address_state": "\"WA\"",
                "image": "\"https://www.wyndhamhotels.com/content/dam/property-images/en-us/tl/us/wa/edmonds/09538/09538_exterior_view_1.jpg\"",
                "latitude": 47.782662,
                "longitude": -122.343744,
                "name": "\"Travelodge by Wyndham Seattle North/Edmonds\"",
                "official_name": "\"\"",
                "phone": "\"+1-425-771-8008\"",
                "short_name": "\"\"",
                "street_address": "\"23825 Highway 99\"",
                "tourism": "\"hotel\"",
                "website": "\"https://www.wyndhamhotels.com/travelodge/edmonds-washington/travelodge-seattle-north-edmonds/overview\""
            },
            {
                "__id": 6957,
                "address_city": "\"Sarnia\"",
                "address_country": "\"CA\"",
                "address_postcode": "\"N7T5R8\"",
                "address_state": "\"ON\"",
                "image": "\"https://www.wyndhamhotels.com/content/dam/property-images/en-us/di/ca/on/sarnia/55101/55101_exterior_view_3.jpg\"",
                "latitude": 42.9837,
                "longitude": -82.40892,
                "name": "\"Days Inn by Wyndham Sarnia Harbourfront\"",
                "official_name": "\"\"",
                "phone": "\"+1-519-337-5434\"",
                "short_name": "\"\"",
                "street_address": "\"505 Harbour Rd\"",
                "tourism": "\"hotel\"",
                "website": "\"https://www.wyndhamhotels.com/days-inn/sarnia-ontario/days-inn-sarnia-harbourfront/overview\""
            },
            {
                "__id": 5395,
                "address_city": "\"S. San Francisco\"",
                "address_country": "\"US\"",
                "address_postcode": "\"94080-6515\"",
                "address_state": "\"CA\"",
                "image": "\"https://www.wyndhamhotels.com/content/dam/property-images/en-us/lq/us/ca/s-san-francisco/52821/52821_suite_3.jpg\"",
                "latitude": 37.650684,
                "longitude": -122.407538,
                "name": "\"La Quinta Inn & Suites by Wyndham San Francisco Airport N\"",
                "official_name": "\"\"",
                "phone": "\"+1-650-583-2223\"",
                "short_name": "\"\"",
                "street_address": "\"20 Airport Boulevard\"",
                "tourism": "\"\"",
                "website": "\"https://www.wyndhamhotels.com/laquinta/s-san-francisco-california/la-quinta-san-francisco-airport-north/overview\""
            },
            {
                "__id": 5836,
                "address_city": "\"Weatherford\"",
                "address_country": "\"US\"",
                "address_postcode": "\"76086\"",
                "address_state": "\"TX\"",
                "image": "\"https://www.wyndhamhotels.com/content/dam/property-images/en-us/lq/us/tx/weatherford/53440/53440_exterior_view_2.jpg\"",
                "latitude": 32.732303,
                "longitude": -97.790068,
                "name": "\"La Quinta Inn & Suites by Wyndham Weatherford\"",
                "official_name": "\"\"",
                "phone": "\"+1-817-594-4481\"",
                "short_name": "\"\"",
                "street_address": "\"1915 Wall Street\"",
                "tourism": "\"\"",
                "website": "\"https://www.wyndhamhotels.com/laquinta/weatherford-texas/la-quinta-weatherford/overview\""
            },
            {
                "__id": 1407,
                "address_city": "\"Galena\"",
                "address_country": "\"US\"",
                "address_postcode": "\"61036\"",
                "address_state": "\"IL\"",
                "image": "\"https://www.wyndhamhotels.com/content/dam/property-images/en-us/ra/us/il/galena/15408/15408_exterior_day_1.jpg\"",
                "latitude": 42.439755,
                "longitude": -90.453481,
                "name": "\"Ramada by Wyndham Galena Hotel & Day Spa\"",
                "official_name": "\"\"",
                "phone": "\"+1-815-777-2043\"",
                "short_name": "\"\"",
                "street_address": "\"11383 US Highway 20\"",
                "tourism": "\"hotel\"",
                "website": "\"https://www.wyndhamhotels.com/ramada/galena-illinois/ramada-hotel-and-day-spa-galena/overview\""
            },
            {
                "__id": 6281,
                "address_city": "\"Kirkland\"",
                "address_country": "\"US\"",
                "address_postcode": "\"98034\"",
                "address_state": "\"WA\"",
                "image": "\"https://www.wyndhamhotels.com/content/dam/property-images/en-us/bu/us/wa/kirkland/17835/17835_exterior_view_1.jpg\"",
                "latitude": 47.702782,
                "longitude": -122.178291,
                "name": "\"Baymont by Wyndham Seattle/Kirkland WA\"",
                "official_name": "\"Baymont by Wyndham\"",
                "phone": "\"+1-425-947-1030\"",
                "short_name": "\"\"",
                "street_address": "\"12223 North East 116th Street\"",
                "tourism": "\"hotel\"",
                "website": "\"https://www.wyndhamhotels.com/baymont/kirkland-washington/baymont-inn-and-suites-seattle-kirkland-wa/overview\""
            },
            {
                "__id": 1655,
                "address_city": "\"Beijing\"",
                "address_country": "\"CN\"",
                "address_postcode": "\"100011\"",
                "address_state": "\"\"",
                "image": "\"https://www.wyndhamhotels.com/content/dam/assets/clientlibs/super-8/graphics/SE_generic_propertyimg.jpg\"",
                "latitude": 39.892761,
                "longitude": 116.253418,
                "name": "\"Super 8 by Wyndham Beijing Ji Shui Tan Jiao Tong Shu Niu\"",
                "official_name": "\"\"",
                "phone": "\"+86-10-57115111\"",
                "short_name": "\"\"",
                "street_address": "\"South Gate of Yard 67 Ande Rd.\"",
                "tourism": "\"hotel\"",
                "website": "\"https://www.wyndhamhotels.com/super-8/beijing-china/super-8-beijing-ji-shui-tan-jiao-tong-shu-niu/overview\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "edible_arrangements": {
        "__id": 32,
        "id": "edible_arrangements",
        "name": "Edible Arrangements",
        "columnNames": "['address_full', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'website']",
        "rowLength": 890,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763460,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of edible_arrangements locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 619,
                "address_full": "\"1652 Richmond Avenue, Staten Island, NY 10314\"",
                "latitude": 40.596148,
                "longitude": -74.161613,
                "name": "\"Edible Arrangements - 146\"",
                "opening_hours": "\"Mo-Sa 09:00-17:00; Su 10:00-15:00\"",
                "phone": "\"718-370-7302\"",
                "shop": "\"gift\"",
                "website": "\"https://www.ediblearrangements.com/stores/1652-richmond-ave-staten-island-ny-10314\""
            },
            {
                "__id": 688,
                "address_full": "\"288 Mamaroneck Avenue, White Plains, NY 10605\"",
                "latitude": 41.007673,
                "longitude": -73.743984,
                "name": "\"Edible Arrangements - 1337\"",
                "opening_hours": "\"Mo-Sa 09:00-17:00; Su 10:00-15:00\"",
                "phone": "\"914-949-7400\"",
                "shop": "\"gift\"",
                "website": "\"https://www.ediblearrangements.com/stores/288-mamaroneck-avenue-white-plains-ny-10605\""
            },
            {
                "__id": 102,
                "address_full": "\"1508 Essington Rd, Joliet, IL 60435\"",
                "latitude": 41.558392,
                "longitude": -88.121876,
                "name": "\"Edible Arrangements - 455\"",
                "opening_hours": "\"Mo-Fr 09:00-18:00; Sa 09:00-17:00; Su 10:00-15:00\"",
                "phone": "\"815-729-9399\"",
                "shop": "\"gift\"",
                "website": "\"https://www.ediblearrangements.com/stores/1508-essington-rd-joliet-il-60435\""
            },
            {
                "__id": 542,
                "address_full": "\"1415 City Avenue, Wynnewood, PA 19096\"",
                "latitude": 39.998806,
                "longitude": -75.271335,
                "name": "\"Edible Arrangements - 645\"",
                "opening_hours": "\"Mo-Sa 09:00-17:00; Su 10:00-15:00\"",
                "phone": "\"610-896-3595\"",
                "shop": "\"gift\"",
                "website": "\"https://www.ediblearrangements.com/stores/1415-city-avenue-wynnewood-pa-19096\""
            },
            {
                "__id": 233,
                "address_full": "\"4780 Ashford Dunwoody Road, Dunwoody, GA 30338\"",
                "latitude": 33.944716,
                "longitude": -84.318059,
                "name": "\"Edible Arrangements - 1217\"",
                "opening_hours": "\"Mo-Sa 09:00-17:00; Su 10:00-15:00\"",
                "phone": "\"770-698-8500\"",
                "shop": "\"gift\"",
                "website": "\"https://www.ediblearrangements.com/stores/4780-ashford-dunwoody-road-dunwoody-ga-30338\""
            },
            {
                "__id": 789,
                "address_full": "\"16127 S Western Ave, Gardena, CA 90249\"",
                "latitude": 33.894633,
                "longitude": -118.299208,
                "name": "\"Edible Arrangements - 694\"",
                "opening_hours": "\"Mo-Sa 09:00-17:00; Su 10:00-15:00\"",
                "phone": "\"310-324-2244\"",
                "shop": "\"gift\"",
                "website": "\"https://www.ediblearrangements.com/stores/16127-s-western-ave-gardena-ca-90249\""
            },
            {
                "__id": 703,
                "address_full": "\"12180 Ridgecrest Rd STE 518, Victorville, CA 92395\"",
                "latitude": 34.517792,
                "longitude": -117.298964,
                "name": "\"Edible Arrangements - 520\"",
                "opening_hours": "\"Mo-Sa 09:00-17:00; Su 10:00-15:00\"",
                "phone": "\"760-269-0260\"",
                "shop": "\"gift\"",
                "website": "\"https://www.ediblearrangements.com/stores/12180-ridgecrest-rd-victorville-ca-92395\""
            },
            {
                "__id": 222,
                "address_full": "\"3805 Dallas Highway SW County, Marietta, GA 30064\"",
                "latitude": 33.927555,
                "longitude": -84.620166,
                "name": "\"Edible Arrangements - 1695\"",
                "opening_hours": "\"Mo-Sa 09:00-17:00; Su 09:00-14:00\"",
                "phone": "\"678-924-8277\"",
                "shop": "\"gift\"",
                "website": "\"https://www.ediblearrangements.com/stores/3805-dallas-highway-sw-county-marietta-ga-30064\""
            },
            {
                "__id": 752,
                "address_full": "\"893 East Main St, Meriden, CT 06450\"",
                "latitude": 41.537229,
                "longitude": -72.779368,
                "name": "\"Edible Arrangements - 1704\"",
                "opening_hours": "\"Mo-Fr 09:00-18:00; Sa 09:00-17:00; Su 10:00-15:00\"",
                "phone": "\"203-440-4315\"",
                "shop": "\"gift\"",
                "website": "\"https://www.ediblearrangements.com/stores/893-east-main-st-meriden-ct-06450\""
            },
            {
                "__id": 154,
                "address_full": "\"5000 Whitesburg Dr S , Huntsville, AL 35802\"",
                "latitude": 34.669181,
                "longitude": -86.56031,
                "name": "\"Edible Arrangements - 819\"",
                "opening_hours": "\"Mo-Fr 09:00-17:00; Su 10:00-15:00\"",
                "phone": "\"256-881-7575\"",
                "shop": "\"gift\"",
                "website": "\"https://www.ediblearrangements.com/stores/5000-whitesburg-dr-s-huntsville-al-35802\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "heart_of_england_cooperative": {
        "__id": 33,
        "id": "heart_of_england_cooperative",
        "name": "Heart of England Co-operative",
        "columnNames": "['address_country', 'address_full', 'address_postcode', 'latitude', 'longitude', 'name', 'phone', 'website']",
        "rowLength": 32,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763461,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of heart_of_england_cooperative locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 13,
                "address_country": "\"GB\"",
                "address_full": "\"Chesterton Drive  Nuneaton,   CV10 9RP\"",
                "address_postcode": "\"CV10 9RP\"",
                "latitude": 52.5244608,
                "longitude": -1.530875,
                "name": "\"Galley Common\"",
                "phone": "\"02476 393 962\"",
                "website": "\"\""
            },
            {
                "__id": 7,
                "address_country": "\"GB\"",
                "address_full": "\"School Road    CV12 9JB\"",
                "address_postcode": "\"CV12 9JB\"",
                "latitude": 52.476333,
                "longitude": -1.423615,
                "name": "\"Bulkington\"",
                "phone": "\"024 7631 2146\"",
                "website": "\"\""
            },
            {
                "__id": 18,
                "address_country": "\"GB\"",
                "address_full": "\"Main Street    CV130JN\"",
                "address_postcode": "\"\"",
                "latitude": 52.62435,
                "longitude": -1.401783,
                "name": "\"Market Bosworth\"",
                "phone": "\"01455 290231\"",
                "website": "\"\""
            },
            {
                "__id": 24,
                "address_country": "\"GB\"",
                "address_full": "\"Church Street    LE9 4FG\"",
                "address_postcode": "\"LE9 4FG\"",
                "latitude": 52.536263,
                "longitude": -1.280553,
                "name": "\"Sapcote\"",
                "phone": "\"01455 272257\"",
                "website": "\"\""
            },
            {
                "__id": 14,
                "address_country": "\"GB\"",
                "address_full": "\"High Street    CV33 9HW\"",
                "address_postcode": "\"CV33 9HW\"",
                "latitude": 52.236704,
                "longitude": -1.455635,
                "name": "\"Harbury\"",
                "phone": "\"01926 612252\"",
                "website": "\"\""
            },
            {
                "__id": 29,
                "address_country": "\"GB\"",
                "address_full": "\"Cressida Close  Wariwick,   CV34 6DZ\"",
                "address_postcode": "\"CV34 6DZ\"",
                "latitude": 52.268567,
                "longitude": -1.538275,
                "name": "\"Warwick Gates\"",
                "phone": "\"01926 426506\"",
                "website": "\"\""
            },
            {
                "__id": 25,
                "address_country": "\"GB\"",
                "address_full": "\"Coventry Street    CV47 0EA\"",
                "address_postcode": "\"CV47 0EA\"",
                "latitude": 52.253886,
                "longitude": -1.38793,
                "name": "\"Southam\"",
                "phone": "\"01926 812308\"",
                "website": "\"\""
            },
            {
                "__id": 16,
                "address_country": "\"GB\"",
                "address_full": "\"Church Road    CV47 9PN\"",
                "address_postcode": "\"CV47 9PN\"",
                "latitude": 52.2830996,
                "longitude": -1.3984383,
                "name": "\"Long Itchington\"",
                "phone": "\"01926 812411\"",
                "website": "\"\""
            },
            {
                "__id": 28,
                "address_country": "\"GB\"",
                "address_full": "\"Tile Hill Lane  Coventry,   CV49DU\"",
                "address_postcode": "\"\"",
                "latitude": 52.403899,
                "longitude": -1.562805,
                "name": "\"Tile HIll\"",
                "phone": "\"024 7646 6170\"",
                "website": "\"\""
            },
            {
                "__id": 8,
                "address_country": "\"GB\"",
                "address_full": "\"Copper Beech Road  Nuneaton,   CV10 9FB\"",
                "address_postcode": "\"CV10 9FB\"",
                "latitude": 52.531474,
                "longitude": -1.50603,
                "name": "\"Camp Hill\"",
                "phone": "\"024 7639 2438\"",
                "website": "\"\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "lidl_de": {
        "__id": 34,
        "id": "lidl_de",
        "name": "Lidl",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'latitude', 'longitude', 'name', 'opening_hours', 'shop', 'street_address']",
        "rowLength": 239,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763462,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of lidl_de locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 37,
                "address_city": "\"V\u00f6lklingen\"",
                "address_country": "\"DE\"",
                "address_postcode": "\"66333\"",
                "latitude": 49.23074,
                "longitude": 6.82865,
                "name": "\"Lidl\"",
                "opening_hours": "\"Mo-Sa 07:00-20:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Am Hammergraben 7\""
            },
            {
                "__id": 74,
                "address_city": "\"Oberhausen\"",
                "address_country": "\"DE\"",
                "address_postcode": "\"46149\"",
                "latitude": 51.51986,
                "longitude": 6.82936,
                "name": "\"Lidl\"",
                "opening_hours": "\"Mo-Sa 07:00-21:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Weierstr. 87\""
            },
            {
                "__id": 47,
                "address_city": "\"Neuwied\"",
                "address_country": "\"DE\"",
                "address_postcode": "\"56566\"",
                "latitude": 50.44273,
                "longitude": 7.55458,
                "name": "\"Lidl\"",
                "opening_hours": "\"Mo-Sa 07:00-21:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Sayner Str. 60\""
            },
            {
                "__id": 20,
                "address_city": "\"Erfurt\"",
                "address_country": "\"DE\"",
                "address_postcode": "\"99091\"",
                "latitude": 51.00334,
                "longitude": 11.00165,
                "name": "\"Lidl\"",
                "opening_hours": "\"Mo-Fr 07:00-21:00; Sa 07:00-20:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Grenzweg 10\""
            },
            {
                "__id": 118,
                "address_city": "\"D\u00fcsseldorf\"",
                "address_country": "\"DE\"",
                "address_postcode": "\"40489\"",
                "latitude": 51.32363,
                "longitude": 6.77016,
                "name": "\"Lidl\"",
                "opening_hours": "\"Mo-Sa 07:00-21:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Pfarrer-Holl-Weg 21\""
            },
            {
                "__id": 42,
                "address_city": "\"Zweibr\u00fccken\"",
                "address_country": "\"DE\"",
                "address_postcode": "\"66482\"",
                "latitude": 49.23134,
                "longitude": 7.35403,
                "name": "\"Lidl\"",
                "opening_hours": "\"Mo-Sa 07:00-21:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Etzelweg 219\""
            },
            {
                "__id": 32,
                "address_city": "\"Pirna\"",
                "address_country": "\"DE\"",
                "address_postcode": "\"01796\"",
                "latitude": 50.96819,
                "longitude": 13.93264,
                "name": "\"Lidl\"",
                "opening_hours": "\"Mo-Sa 07:00-21:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Rudolf-Renner-Str. 10\""
            },
            {
                "__id": 158,
                "address_city": "\"G\u00f6ttingen\"",
                "address_country": "\"DE\"",
                "address_postcode": "\"37079\"",
                "latitude": 51.54005,
                "longitude": 9.89472,
                "name": "\"Lidl\"",
                "opening_hours": "\"Mo-Sa 07:00-21:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Industriestr. 4a\""
            },
            {
                "__id": 2,
                "address_city": "\"Berlin-Charlottenburg-Wilmersdorf\"",
                "address_country": "\"DE\"",
                "address_postcode": "\"14059\"",
                "latitude": 52.51981,
                "longitude": 13.28035,
                "name": "\"Lidl\"",
                "opening_hours": "\"Mo-Sa 07:00-22:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"F\u00fcrstenbrunner Weg 14-18\""
            },
            {
                "__id": 33,
                "address_city": "\"Mei\u00dfen\"",
                "address_country": "\"DE\"",
                "address_postcode": "\"01662\"",
                "latitude": 51.16171,
                "longitude": 13.47583,
                "name": "\"Lidl\"",
                "opening_hours": "\"Mo-Sa 07:00-21:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Poststr. 11\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "family_dollar": {
        "__id": 35,
        "id": "family_dollar",
        "name": "Family Dollar",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'contact_email', 'image', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'website']",
        "rowLength": 5742,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763464,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of family_dollar locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 3485,
                "address_city": "\"Industry\"",
                "address_country": "\"US\"",
                "address_postcode": "\"78944\"",
                "address_state": "\"TX\"",
                "contact_email": "\"ddirect@dollartree.com\"",
                "image": "\"https://www.familydollar.com/locations/images/FD_LocalPages_Storefront.jpg\"",
                "latitude": 29.9716867,
                "longitude": -96.50091119,
                "name": "\"FAMILY DOLLAR #11373\"",
                "opening_hours": "\"Mo-Su 08:00-10:00\"",
                "phone": "\"979-627-6029\"",
                "shop": "\"variety_store\"",
                "street_address": "\"16835 Fordtran Blvd\"",
                "website": "\"https://www.familydollar.com/locations/tx/industry/31373/\""
            },
            {
                "__id": 3963,
                "address_city": "\"Corpus Christi\"",
                "address_country": "\"US\"",
                "address_postcode": "\"78416\"",
                "address_state": "\"TX\"",
                "contact_email": "\"ddirect@dollartree.com\"",
                "image": "\"https://www.familydollar.com/locations/images/FD_LocalPages_Storefront.jpg\"",
                "latitude": 27.76029159,
                "longitude": -97.43874756,
                "name": "\"FAMILY DOLLAR #10053\"",
                "opening_hours": "\"Mo-Su 08:00-10:00\"",
                "phone": "\"361-730-2371\"",
                "shop": "\"variety_store\"",
                "street_address": "\"1225 Horne Rd.\"",
                "website": "\"https://www.familydollar.com/locations/tx/corpus-christi/30053/\""
            },
            {
                "__id": 3692,
                "address_city": "\"Grand Prairie\"",
                "address_country": "\"US\"",
                "address_postcode": "\"75051\"",
                "address_state": "\"TX\"",
                "contact_email": "\"ddirect@dollartree.com\"",
                "image": "\"https://www.familydollar.com/locations/images/FD_LocalPages_Storefront.jpg\"",
                "latitude": 32.719433,
                "longitude": -96.99387,
                "name": "\"FAMILY DOLLAR #12107\"",
                "opening_hours": "\"Mo-Sa 09:00-10:00; Su 09:00-09:00\"",
                "phone": "\"469-789-7733\"",
                "shop": "\"variety_store\"",
                "street_address": "\"2010 S Belt Line Rd.\"",
                "website": "\"https://www.familydollar.com/locations/tx/grand-prairie/32107/\""
            },
            {
                "__id": 3325,
                "address_city": "\"Nacogdoches\"",
                "address_country": "\"US\"",
                "address_postcode": "\"75965\"",
                "address_state": "\"TX\"",
                "contact_email": "\"ddirect@dollartree.com\"",
                "image": "\"https://www.familydollar.com/locations/images/FD_LocalPages_Storefront.jpg\"",
                "latitude": 31.701841,
                "longitude": -94.676254,
                "name": "\"FAMILY DOLLAR #11797\"",
                "opening_hours": "\"Mo-Su 08:00-10:00\"",
                "phone": "\"936-585-7543\"",
                "shop": "\"variety_store\"",
                "street_address": "\"9959 Us Highway 259\"",
                "website": "\"https://www.familydollar.com/locations/tx/nacogdoches/31797/\""
            },
            {
                "__id": 2162,
                "address_city": "\"Union Springs\"",
                "address_country": "\"US\"",
                "address_postcode": "\"36089-1412\"",
                "address_state": "\"AL\"",
                "contact_email": "\"ddirect@dollartree.com\"",
                "image": "\"https://www.familydollar.com/locations/images/FD_LocalPages_Storefront.jpg\"",
                "latitude": 32.14685076,
                "longitude": -85.71183242,
                "name": "\"FAMILY DOLLAR #8720\"",
                "opening_hours": "\"Mo-Su 08:00-10:00\"",
                "phone": "\"334-777-0699\"",
                "shop": "\"variety_store\"",
                "street_address": "\"205 Conecuh Ave E\"",
                "website": "\"https://www.familydollar.com/locations/al/union-springs/28720/\""
            },
            {
                "__id": 5408,
                "address_city": "\"East Point\"",
                "address_country": "\"US\"",
                "address_postcode": "\"30344-2338\"",
                "address_state": "\"GA\"",
                "contact_email": "\"ddirect@dollartree.com\"",
                "image": "\"https://www.familydollar.com/locations/images/FD_LocalPages_Storefront.jpg\"",
                "latitude": 33.68909,
                "longitude": -84.45818,
                "name": "\"FAMILY DOLLAR #1788\"",
                "opening_hours": "\"Mo-Su 08:00-10:00\"",
                "phone": "\"404-942-1395\"",
                "shop": "\"variety_store\"",
                "street_address": "\"2433 Delowe Drive\"",
                "website": "\"https://www.familydollar.com/locations/ga/east-point/21788/\""
            },
            {
                "__id": 4159,
                "address_city": "\"Ogden\"",
                "address_country": "\"US\"",
                "address_postcode": "\"84403-2005\"",
                "address_state": "\"UT\"",
                "contact_email": "\"ddirect@dollartree.com\"",
                "image": "\"https://www.familydollar.com/locations/images/FD_LocalPages_Storefront.jpg\"",
                "latitude": 41.198689,
                "longitude": -111.949192,
                "name": "\"FAMILY DOLLAR #6141\"",
                "opening_hours": "\"Mo-Sa 09:00-10:00; Su 09:00-09:00\"",
                "phone": "\"801-605-4245\"",
                "shop": "\"variety_store\"",
                "street_address": "\"3531 Harrison Blvd S\"",
                "website": "\"https://www.familydollar.com/locations/ut/ogden/26141/\""
            },
            {
                "__id": 4364,
                "address_city": "\"Fort Chiswell\"",
                "address_country": "\"US\"",
                "address_postcode": "\"24360-3266\"",
                "address_state": "\"VA\"",
                "contact_email": "\"ddirect@dollartree.com\"",
                "image": "\"https://www.familydollar.com/locations/images/FD_LocalPages_Storefront.jpg\"",
                "latitude": 36.93383354,
                "longitude": -80.94507784,
                "name": "\"FAMILY DOLLAR #9265\"",
                "opening_hours": "\"Mo-Su 08:00-10:00\"",
                "phone": "\"276-764-6001\"",
                "shop": "\"variety_store\"",
                "street_address": "\"555 Fort Chiswell Road\"",
                "website": "\"https://www.familydollar.com/locations/va/fort-chiswell/29265/\""
            },
            {
                "__id": 2888,
                "address_city": "\"Memphis\"",
                "address_country": "\"US\"",
                "address_postcode": "\"38125\"",
                "address_state": "\"TN\"",
                "contact_email": "\"ddirect@dollartree.com\"",
                "image": "\"https://www.familydollar.com/locations/images/FD_LocalPages_Storefront.jpg\"",
                "latitude": 35.033476,
                "longitude": -89.794817,
                "name": "\"FAMILY DOLLAR #8953\"",
                "opening_hours": "\"Mo-Sa 09:00-10:00; Su 09:00-09:00\"",
                "phone": "\"901-801-6533\"",
                "shop": "\"variety_store\"",
                "street_address": "\"4202 Hacks Cross Rd\"",
                "website": "\"https://www.familydollar.com/locations/tn/memphis/28953/\""
            },
            {
                "__id": 2494,
                "address_city": "\"Del City\"",
                "address_country": "\"US\"",
                "address_postcode": "\"73115\"",
                "address_state": "\"OK\"",
                "contact_email": "\"ddirect@dollartree.com\"",
                "image": "\"https://www.familydollar.com/locations/images/FD_LocalPages_Storefront.jpg\"",
                "latitude": 35.45065,
                "longitude": -97.42978,
                "name": "\"FAMILY DOLLAR #3108\"",
                "opening_hours": "\"Mo-Sa 09:00-10:00; Su 09:00-09:00\"",
                "phone": "\"405-898-6911\"",
                "shop": "\"variety_store\"",
                "street_address": "\"4713 Se 15th St\"",
                "website": "\"https://www.familydollar.com/locations/ok/del-city/23108/\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "vans": {
        "__id": 36,
        "id": "vans",
        "name": "Vans",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'phone', 'shop']",
        "rowLength": 569,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763465,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of vans locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 96,
                "address_city": "\"M\u00e9xico\"",
                "address_country": "\"MX\"",
                "address_full": "\"Centro Comercial Nezahualcoyotl, Av. Bordo de Xochiaca, N\u00ba 3, Col. Ciudad Jard\u00edn Bicentenario, Munic\"",
                "address_postcode": "\"57205\"",
                "address_state": "\"Estado de M\u00e9xico\"",
                "latitude": 19.4248071,
                "longitude": -99.0237445,
                "name": "\"Vans Store Ciudad Jard\u00edn\"",
                "phone": "\"55 1558 4179\"",
                "shop": "\"shoes\""
            },
            {
                "__id": 283,
                "address_city": "\"Troy\"",
                "address_country": "\"US\"",
                "address_full": "\"2800 W. Beaver Rd, Space W314\"",
                "address_postcode": "\"48084\"",
                "address_state": "\"MI\"",
                "latitude": 42.5629234,
                "longitude": -83.183815,
                "name": "\"Vans Store - Somerset Collection\"",
                "phone": "\"(248) 458-0871\"",
                "shop": "\"shoes\""
            },
            {
                "__id": 122,
                "address_city": "\"PUEBLA\"",
                "address_country": "\"MX\"",
                "address_full": "\"BLVD.  HEROES  DEL 5  DE  MAYO      No 3510\"",
                "address_postcode": "\"72530\"",
                "address_state": "\"Puebla\"",
                "latitude": 19.0280853,
                "longitude": -98.2032413,
                "name": "\"VANS PLAZA DORADA\"",
                "phone": "\"222-211-3355\"",
                "shop": "\"shoes\""
            },
            {
                "__id": 515,
                "address_city": "\"Burlington\"",
                "address_country": "\"US\"",
                "address_full": "\"75 Middlesex Turnpike Suite #1303\"",
                "address_postcode": "\"01803\"",
                "address_state": "\"MA\"",
                "latitude": 42.4822898,
                "longitude": -71.2135439,
                "name": "\"Vans Store - Burlington Mall\"",
                "phone": "\"(781) 229-7700\"",
                "shop": "\"shoes\""
            },
            {
                "__id": 427,
                "address_city": "\"Bensalem\"",
                "address_country": "\"US\"",
                "address_full": "\"719 Neshaminy Mall Suite #719\"",
                "address_postcode": "\"19020\"",
                "address_state": "\"PA\"",
                "latitude": 40.1396221,
                "longitude": -74.9559365,
                "name": "\"Vans Store - Neshaminy Mall\"",
                "phone": "\"(215) 355-1671\"",
                "shop": "\"shoes\""
            },
            {
                "__id": 381,
                "address_city": "\"Goleta\"",
                "address_country": "\"US\"",
                "address_full": "\"7054 Marketplace Dr\"",
                "address_postcode": "\"93117\"",
                "address_state": "\"CA\"",
                "latitude": 34.4292925,
                "longitude": -119.8733173,
                "name": "\"Vans Store - Camino Real Market Place\"",
                "phone": "\"(805) 562-0112\"",
                "shop": "\"shoes\""
            },
            {
                "__id": 435,
                "address_city": "\"Sacramento\"",
                "address_country": "\"US\"",
                "address_full": "\"1689 Arden Way Suite #2114\"",
                "address_postcode": "\"95815\"",
                "address_state": "\"CA\"",
                "latitude": 38.6012295,
                "longitude": -121.4260871,
                "name": "\"Vans Store - ARDEN FAIR\"",
                "phone": "\"(916) 923-2377\"",
                "shop": "\"shoes\""
            },
            {
                "__id": 471,
                "address_city": "\"New York\"",
                "address_country": "\"US\"",
                "address_full": "\"15 Union Sq. West\"",
                "address_postcode": "\"10003\"",
                "address_state": "\"NY\"",
                "latitude": 40.7358934,
                "longitude": -73.9917033,
                "name": "\"Vans Store - Union Sq.\"",
                "phone": "\"(212) 804-5890\"",
                "shop": "\"shoes\""
            },
            {
                "__id": 223,
                "address_city": "\"Riverton\"",
                "address_country": "\"US\"",
                "address_full": "\"13303 S. Teal Ridge Way, Suite M-120\"",
                "address_postcode": "\"84096\"",
                "address_state": "\"UT\"",
                "latitude": 40.5089316,
                "longitude": -111.9948201,
                "name": "\"Vans Store - Mountain View Village\"",
                "phone": "\"(385) 887-8048\"",
                "shop": "\"shoes\""
            },
            {
                "__id": 215,
                "address_city": "\"Roseville\"",
                "address_country": "\"US\"",
                "address_full": "\"102 Rosedale Center Space 548\"",
                "address_postcode": "\"55113\"",
                "address_state": "\"MN\"",
                "latitude": 45.01264,
                "longitude": -93.1722643,
                "name": "\"Vans Store - Rosedale Shopping Center\"",
                "phone": "\"(651) 631-0151\"",
                "shop": "\"shoes\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "viva_chicken": {
        "__id": 37,
        "id": "viva_chicken",
        "name": "Viva Chicken",
        "columnNames": "['address_full', 'latitude', 'longitude', 'name']",
        "rowLength": 15,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763466,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of viva_chicken locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 13,
                "address_full": "\"9121 Sam Furr Rd, Huntersville, NC, United States\"",
                "latitude": 35.44294,
                "longitude": -80.87247,
                "name": "\"Huntersville\""
            },
            {
                "__id": 12,
                "address_full": "\"8694 Concord Mills Blvd, Concord, NC, United States\"",
                "latitude": 35.37514,
                "longitude": -80.72996,
                "name": "\"Concord\""
            },
            {
                "__id": 8,
                "address_full": "\"3624 Pelham Rd, Greenville, 29615, Greenville, SC, United States\"",
                "latitude": 34.8600274,
                "longitude": -82.2690859,
                "name": "\"Pelham\""
            },
            {
                "__id": 3,
                "address_full": "\"11641 Waverly Center Dr, Charlotte, NC, United States\"",
                "latitude": 35.05305,
                "longitude": -80.7687,
                "name": "\"Waverly\""
            },
            {
                "__id": 9,
                "address_full": "\"4500 Park Rd, Charlotte, NC, United States\"",
                "latitude": 35.16991,
                "longitude": -80.85116,
                "name": "\"Park Road\""
            },
            {
                "__id": 5,
                "address_full": "\"12206 Copper Way, Charlotte, NC, United States\"",
                "latitude": 35.0707,
                "longitude": -80.84427,
                "name": "\"Ballantyne\""
            },
            {
                "__id": 7,
                "address_full": "\"3493 Burke Mill Rd, Winston-Salem, NC, United States\"",
                "latitude": 36.0590131,
                "longitude": -80.3131318,
                "name": "\"Winston-Salem\""
            },
            {
                "__id": 6,
                "address_full": "\"2205 E Franklin Blvd, Gastonia, NC, United States\"",
                "latitude": 35.26143,
                "longitude": -81.14026,
                "name": "\"Gastonia\""
            },
            {
                "__id": 2,
                "address_full": "\"1139 Woodruff Rd, Greenville, SC, United States\"",
                "latitude": 34.82539,
                "longitude": -82.29487,
                "name": "\"Woodruff\""
            },
            {
                "__id": 1,
                "address_full": "\"1617 Elizabeth Ave, Charlotte, NC, 28204, United States\"",
                "latitude": 35.21345,
                "longitude": -80.82594,
                "name": "\"Elizabeth\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "nandos_zw": {
        "__id": 38,
        "id": "nandos_zw",
        "name": "Nando's Zimbabwe",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'phone', 'website']",
        "rowLength": 14,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763467,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of nandos_zw locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 9,
                "address_city": "\"Harare\"",
                "address_country": "\"ZW\"",
                "address_full": "\"Pomona Shopping Centre, 54 Edinburgh Road, Pomona, Harare, Harare, hre\"",
                "address_state": "\"Harare\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": -17.762289,
                "longitude": 31.077474,
                "name": "\"Nando's Pomona (Dine-in, Delivery & Takeaway)\"",
                "phone": "\"\"",
                "website": "\"https://www.nandos.co.zw/eat/restaurant/nandos-pomona\""
            },
            {
                "__id": 6,
                "address_city": "\"Harare\"",
                "address_country": "\"ZW\"",
                "address_full": "\"Sam Levy Shopping Centre, Borrowdale, Harare, Harare\"",
                "address_state": "\"Harare\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": -17.7607552,
                "longitude": 31.0846015,
                "name": "\"Nando's Sam Levy (Dine-in, Delivery & Takeaway)\"",
                "phone": "\"\"",
                "website": "\"https://www.nandos.co.zw/eat/restaurant/nandos-sam-levy-\""
            },
            {
                "__id": 13,
                "address_city": "\"Harare\"",
                "address_country": "\"ZW\"",
                "address_full": "\"142 Samora Machel, CBD, Harare, Harare, hre\"",
                "address_state": "\"Harare\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": -17.821267,
                "longitude": 31.076901,
                "name": "\"Nando's Samora Machel (Dine-in, Delivery & Takeaway)\"",
                "phone": "\"\"",
                "website": "\"https://www.nandos.co.zw/eat/restaurant/nandos-samora-machel\""
            },
            {
                "__id": 1,
                "address_city": "\"Victoria Falls\"",
                "address_country": "\"ZW\"",
                "address_full": "\"Pioneer Road, Sawanga Mall, Victoria Falls, Victoria Falls, Matebeleland North\"",
                "address_state": "\"Matebeleland North\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": -17.929995001381513,
                "longitude": 25.83465608246695,
                "name": "\"Nandos Victoria Falls (Dine-in, Delivery & Takeaway)\"",
                "phone": "\"\"",
                "website": "\"https://www.nandos.co.zw/eat/restaurant/Nandos-Victoria-Falls\""
            },
            {
                "__id": 14,
                "address_city": "\"Harare\"",
                "address_country": "\"ZW\"",
                "address_full": "\"Avondale Shopping Centre, 321-14 King George Road, Avondale, CBD, Harare, Harare, hre\"",
                "address_state": "\"Harare\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": -17.796287,
                "longitude": 31.033419,
                "name": "\"Nando's Avondale (Dine-in, Delivery & Takeaway)\"",
                "phone": "\"\"",
                "website": "\"https://www.nandos.co.zw/eat/restaurant/nandos-avondale\""
            },
            {
                "__id": 11,
                "address_city": "\"Harare\"",
                "address_country": "\"ZW\"",
                "address_full": "\"16885 Speke Avenue, CBD, Harare, Harare, hre\"",
                "address_state": "\"Harare\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": -17.832353,
                "longitude": 31.043513,
                "name": "\"Nando's Speke (Dine-in, Delivery & Takeaway)\"",
                "phone": "\"\"",
                "website": "\"https://www.nandos.co.zw/eat/restaurant/nandos-speke\""
            },
            {
                "__id": 7,
                "address_city": "\"Harare\"",
                "address_country": "\"ZW\"",
                "address_full": "\"5 Avenue Shopping Centre, Avenues, Harare, Harare\"",
                "address_state": "\"Harare\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": -17.8190751,
                "longitude": 31.0521326,
                "name": "\"Nando's Fife Avenue (Dine-in, Delivery & Takeaway)\"",
                "phone": "\"\"",
                "website": "\"https://www.nandos.co.zw/eat/restaurant/nandos-fife-avenue\""
            },
            {
                "__id": 10,
                "address_city": "\"Harare\"",
                "address_country": "\"ZW\"",
                "address_full": "\"Marimba Shopping Centre, Belvedere, CBD, Harare, Harare, hre\"",
                "address_state": "\"Harare\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": -17.831599,
                "longitude": 31.011222,
                "name": "\"Nando's Marimba (Dine-in, Delivery & Takeaway)\"",
                "phone": "\"+27777006847\"",
                "website": "\"https://www.nandos.co.zw/eat/restaurant/nandos-marimba\""
            },
            {
                "__id": 2,
                "address_city": "\"Gweru\"",
                "address_country": "\"ZW\"",
                "address_full": "\"7th St, Stand no. 61, Gweru, Midlands\"",
                "address_state": "\"Midlands\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": -17.83055,
                "longitude": 31.030978,
                "name": "\"Nando's Gweru (Dine-in, Delivery & Takeaway)\"",
                "phone": "\"\"",
                "website": "\"https://www.nandos.co.zw/eat/restaurant/nandos-gweru\""
            },
            {
                "__id": 5,
                "address_city": "\"Mutare\"",
                "address_country": "\"ZW\"",
                "address_full": "\"32 Second Street, CBD, Mutare, Manicaland, mtr\"",
                "address_state": "\"Manicaland\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": -18.973763,
                "longitude": 32.670014,
                "name": "\"Nando's Mutare (Dine-in, Delivery & Takeaway)\"",
                "phone": "\"\"",
                "website": "\"https://www.nandos.co.zw/eat/restaurant/nandos-mutare\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "heron_foods": {
        "__id": 39,
        "id": "heron_foods",
        "name": "Heron Foods",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'latitude', 'longitude', 'name', 'opening_hours', 'shop', 'street_address', 'website']",
        "rowLength": 318,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763468,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of heron_foods locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 10,
                "address_city": "\"Whittlesey\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"PE7 1AJ\"",
                "latitude": 52.5576477,
                "longitude": -0.1274036,
                "name": "\"Whittlesey\"",
                "opening_hours": "\"Mo-Sa 08:00-20:00; Su 10:00-17:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"23 High Causeway\"",
                "website": "\"https://heronfoods.com/store/whittlesey/\""
            },
            {
                "__id": 140,
                "address_city": "\"Glossop\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"SK13 8AN\"",
                "latitude": 53.4431787,
                "longitude": -1.9495075,
                "name": "\"Glossop\"",
                "opening_hours": "\"Mo-Sa 08:00-18:00; Su 09:00-17:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"9/11 High Street, Glossop, SK13 8AN\"",
                "website": "\"https://heronfoods.com/store/glossop/\""
            },
            {
                "__id": 128,
                "address_city": "\"Bridgend\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"CF31 1LL\"",
                "latitude": 51.5079,
                "longitude": -3.5781172,
                "name": "\"Bridgend\"",
                "opening_hours": "\"Mo-Sa 08:00-18:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"21 Market Street\"",
                "website": "\"https://heronfoods.com/store/bridgend/\""
            },
            {
                "__id": 195,
                "address_city": "\"Bolton\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"BL4 9DR\"",
                "latitude": 53.54775540000001,
                "longitude": -2.3957178,
                "name": "\"Farnworth\"",
                "opening_hours": "\"Mo-Sa 08:00-19:00; Su 10:00-16:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"52B Brackley Street, Farnworth\"",
                "website": "\"https://heronfoods.com/store/farnworth/\""
            },
            {
                "__id": 187,
                "address_city": "\"Wyke\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"BD12 9JF\"",
                "latitude": 53.7386879,
                "longitude": -1.7683819,
                "name": "\"Wyke\"",
                "opening_hours": "\"Mo-Sa 08:00-20:00; Su 10:00-16:00\"",
                "shop": "\"convenience\"",
                "street_address": "\"Titan Centre, Towngate\"",
                "website": "\"https://heronfoods.com/store/wyke-bm-express/\""
            },
            {
                "__id": 38,
                "address_city": "\"Bristol\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"BS3 4EY\"",
                "latitude": 51.441679,
                "longitude": -2.5984387,
                "name": "\"Bedminster\"",
                "opening_hours": "\"Mo-Sa 08:00-18:00; Su 10:00-16:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"102-106 East Street Bedminster\"",
                "website": "\"https://heronfoods.com/store/bedminster/\""
            },
            {
                "__id": 156,
                "address_city": "\"Hull\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"HU8 0XT\"",
                "latitude": 53.7714884,
                "longitude": -0.2891758,
                "name": "\"Ings Centre\"",
                "opening_hours": "\"Mo-Sa 08:00-19:00; Su 09:00-17:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Unit 5, Ings Centre, Savoy Road\"",
                "website": "\"https://heronfoods.com/store/ings-centre/\""
            },
            {
                "__id": 60,
                "address_city": "\"Bulwell\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"NG6 8QD\"",
                "latitude": 53.0005007,
                "longitude": -1.1972287,
                "name": "\"Bulwell\"",
                "opening_hours": "\"Mo-Sa 08:00-18:00; Su 09:00-17:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"77/79 Main Street\"",
                "website": "\"https://heronfoods.com/store/bulwell/\""
            },
            {
                "__id": 276,
                "address_city": "\"Hetton Le Hole\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"DH5 9PE\"",
                "latitude": 54.8411824,
                "longitude": -1.4951181,
                "name": "\"Hetton Le Hole\"",
                "opening_hours": "\"Mo-Sa 08:00-20:00; Su 10:00-16:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"15 Front Street\"",
                "website": "\"https://heronfoods.com/store/hetton-le-hole/\""
            },
            {
                "__id": 6,
                "address_city": "\"Bury\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"IP28 7ES\"",
                "latitude": 52.34372,
                "longitude": 0.511821,
                "name": "\"Mildenhall\"",
                "opening_hours": "\"Mo-Sa 08:00-20:00; Su 10:00-16:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"King St Mildenhall\"",
                "website": "\"https://heronfoods.com/store/mildenhall/\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "tapi_gb": {
        "__id": 40,
        "id": "tapi_gb",
        "name": "Tapi Great Britain",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'image', 'latitude', 'longitude', 'name', 'phone', 'shop', 'street_address', 'website']",
        "rowLength": 192,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763469,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of tapi_gb locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 25,
                "address_city": "\"\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"PO22 9NF\"",
                "image": "\"https://www.tapi.co.uk/images_stores/tapi-carpets-rustington-web.jpg\"",
                "latitude": 50.798798,
                "longitude": -0.666703,
                "name": "\"Tapi Carpets & Floors Bognor Regis\"",
                "phone": "\"01243 349 220\"",
                "shop": "\"carpet\"",
                "street_address": "\"Unit C1 Arun Retail Park, Shripney Road\"",
                "website": "\"https://www.tapi.co.uk/stores/bognor-regis\""
            },
            {
                "__id": 141,
                "address_city": "\"\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"PH1 5XA\"",
                "image": "\"https://www.tapi.co.uk/images_stores/tapi-carpets-perth-web.jpg\"",
                "latitude": 56.398977,
                "longitude": -3.441418,
                "name": "\"Tapi Carpets & Floors Perth\"",
                "phone": "\"01738 230 690\"",
                "shop": "\"carpet\"",
                "street_address": "\"Unit 8B St Catherine's Retail Park, St Catherine's Road\"",
                "website": "\"https://www.tapi.co.uk/stores/perth\""
            },
            {
                "__id": 20,
                "address_city": "\"\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"WR3 8HP\"",
                "image": "\"https://www.tapi.co.uk/images_stores/tapi-carpets-rustington-web.jpg\"",
                "latitude": 52.21077,
                "longitude": -2.201285,
                "name": "\"Tapi Carpets & Floors Worcester\"",
                "phone": "\"01905 950980\"",
                "shop": "\"carpet\"",
                "street_address": "\"Unit B5 Elgar Retail Park, Blackpole Road\"",
                "website": "\"https://www.tapi.co.uk/stores/worcester\""
            },
            {
                "__id": 176,
                "address_city": "\"\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"AL8 6BP\"",
                "image": "\"https://www.tapi.co.uk/images_stores/tapi-carpets-welwyn-web.jpg\"",
                "latitude": 51.801028,
                "longitude": -0.205704,
                "name": "\"Tapi Carpets & Floors Welwyn Garden City\"",
                "phone": "\"01707 245 900\"",
                "shop": "\"carpet\"",
                "street_address": "\"52 Howardsgate\"",
                "website": "\"https://www.tapi.co.uk/stores/welwyn-garden-city\""
            },
            {
                "__id": 131,
                "address_city": "\"\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"SL6 1JX\"",
                "image": "\"https://www.tapi.co.uk/images_stores/tapi-carpets-maidenhead-web.jpg\"",
                "latitude": 51.522601,
                "longitude": -0.721653,
                "name": "\"Tapi Carpets & Floors Maidenhead\"",
                "phone": "\"01628 327 050\"",
                "shop": "\"carpet\"",
                "street_address": "\"71-73 High Street\"",
                "website": "\"https://www.tapi.co.uk/stores/maidenhead\""
            },
            {
                "__id": 44,
                "address_city": "\"\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"LE2 7TF\"",
                "image": "\"https://www.tapi.co.uk/images_stores/b33189a37d09b9a29597c8a9dc2437f5.jpg\"",
                "latitude": 52.616473,
                "longitude": -1.127678,
                "name": "\"Tapi Carpets & Floors within Leicester Homebase\"",
                "phone": "\"01164 976600\"",
                "shop": "\"carpet\"",
                "street_address": "\"37 Putney Road\"",
                "website": "\"https://www.tapi.co.uk/stores/leicester-homebase\""
            },
            {
                "__id": 59,
                "address_city": "\"\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"SE6 2QU\"",
                "image": "\"https://www.tapi.co.uk/\"",
                "latitude": 53.480759,
                "longitude": -2.242631,
                "name": "\"Tapi Carpets & Floors Manchester\"",
                "phone": "\"03330 150 501\"",
                "shop": "\"carpet\"",
                "street_address": "\"\"",
                "website": "\"https://www.tapi.co.uk/stores/manchester\""
            },
            {
                "__id": 185,
                "address_city": "\"\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"G71 5PR\"",
                "image": "\"https://www.tapi.co.uk/images_stores/tapi-carpets-uddingston-web.jpg\"",
                "latitude": 55.836747,
                "longitude": -4.08935,
                "name": "\"Tapi Carpets & Floors Uddingston\"",
                "phone": "\"01698 535 450\"",
                "shop": "\"carpet\"",
                "street_address": "\"Unit 3 Birkenshaw Trading Estate, Rannoch Road\"",
                "website": "\"https://www.tapi.co.uk/stores/uddingston\""
            },
            {
                "__id": 31,
                "address_city": "\"East Kilbride\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"G74 4UN\"",
                "image": "\"https://www.tapi.co.uk/images_stores/tapi-carpets-ayr-web.jpg\"",
                "latitude": 55.78345971,
                "longitude": -4.1706352,
                "name": "\"Tapi Carpets & Floors East Kilbride\"",
                "phone": "\"01355 203100\"",
                "shop": "\"carpet\"",
                "street_address": "\"2 Kingsgate Retail Park, Glasgow Road\"",
                "website": "\"https://www.tapi.co.uk/stores/east-kilbride\""
            },
            {
                "__id": 147,
                "address_city": "\"\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"NR31 0DH\"",
                "image": "\"https://www.tapi.co.uk/images_stores/tapi-carpets-great-yarmouth-web.jpg\"",
                "latitude": 52.599172,
                "longitude": 1.717703,
                "name": "\"Tapi Carpets & Floors Great Yarmouth\"",
                "phone": "\"01493 808 980\"",
                "shop": "\"carpet\"",
                "street_address": "\"Unit 2 Pasteur Retail Park , Thamesfield Way\"",
                "website": "\"https://www.tapi.co.uk/stores/great-yarmouth\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "dennys": {
        "__id": 41,
        "id": "dennys",
        "name": "Denny's",
        "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'website']",
        "rowLength": 1439,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763471,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of dennys locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 1215,
                "address_city": "\"Banning\"",
                "address_full": "\"6305 Joshua Palmer Way\"",
                "address_postcode": "\"92220\"",
                "address_state": "\"CA\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american\"",
                "latitude": 33.927733624070854,
                "longitude": -116.9459331035614,
                "name": "\"Denny's\"",
                "opening_hours": "\"Mo-Th 06:00-22:00; Fr-Sa 06:00-23:00; Su 06:00-22:00\"",
                "phone": "\"(951) 845-2813\"",
                "website": "\"https://locations.dennys.com/CA/BANNING/247351\""
            },
            {
                "__id": 939,
                "address_city": "\"Santa Barbara\"",
                "address_full": "\"3614 State St\"",
                "address_postcode": "\"93105\"",
                "address_state": "\"CA\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american\"",
                "latitude": 34.4407049,
                "longitude": -119.7409175,
                "name": "\"Denny's\"",
                "opening_hours": "\"24/7\"",
                "phone": "\"(805) 898-4447\"",
                "website": "\"https://locations.dennys.com/CA/SANTA-BARBARA/247747\""
            },
            {
                "__id": 709,
                "address_city": "\"Oklahoma City\"",
                "address_full": "\"1617 SW 74th Street\"",
                "address_postcode": "\"73159\"",
                "address_state": "\"OK\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american\"",
                "latitude": 35.392067473699264,
                "longitude": -97.54668340086937,
                "name": "\"Denny's\"",
                "opening_hours": "\"24/7\"",
                "phone": "\"(405) 685-5414\"",
                "website": "\"https://locations.dennys.com/OK/OKLAHOMA-CITY/247996\""
            },
            {
                "__id": 117,
                "address_city": "\"Topeka\"",
                "address_full": "\"1500 Wannamaker Rd\"",
                "address_postcode": "\"66604\"",
                "address_state": "\"KS\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american\"",
                "latitude": 39.04007275150677,
                "longitude": -95.76111667064652,
                "name": "\"Denny's\"",
                "opening_hours": "\"Mo-Tu 06:00-17:00; We-Th 06:00-24:00; Fr-Sa 00:00-24:00; Su 06:00-24:00\"",
                "phone": "\"(785) 271-5599\"",
                "website": "\"https://locations.dennys.com/KS/TOPEKA/247268\""
            },
            {
                "__id": 41,
                "address_city": "\"Oak Park\"",
                "address_full": "\"711 N Harlem Avenue\"",
                "address_postcode": "\"60302\"",
                "address_state": "\"IL\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american\"",
                "latitude": 41.893430682078034,
                "longitude": -87.80495785387302,
                "name": "\"Denny's\"",
                "opening_hours": "\"Mo-Su 06:00-24:00\"",
                "phone": "\"(708) 386-6964\"",
                "website": "\"https://locations.dennys.com/IL/OAK-PARK/247982\""
            },
            {
                "__id": 607,
                "address_city": "\"Albuquerque\"",
                "address_full": "\"9911 Avalon Rd NW\"",
                "address_postcode": "\"87121\"",
                "address_state": "\"NM\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american\"",
                "latitude": 35.0793352,
                "longitude": -106.74544,
                "name": "\"Denny's\"",
                "opening_hours": "\"24/7\"",
                "phone": "\"(505) 836-1513\"",
                "website": "\"https://locations.dennys.com/NM/ALBUQUERQUE/248561\""
            },
            {
                "__id": 306,
                "address_city": "\"Mission\"",
                "address_full": "\"116 South Shary Rd\"",
                "address_postcode": "\"78572\"",
                "address_state": "\"TX\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american\"",
                "latitude": 26.200602170386524,
                "longitude": -98.28812509775162,
                "name": "\"Denny's\"",
                "opening_hours": "\"24/7\"",
                "phone": "\"(956) 584-2525\"",
                "website": "\"https://locations.dennys.com/TX/MISSION/202375\""
            },
            {
                "__id": 81,
                "address_city": "\"Scottsburg\"",
                "address_full": "\"175 South Honeyrun Parkway\"",
                "address_postcode": "\"47170\"",
                "address_state": "\"IN\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american\"",
                "latitude": 38.684494,
                "longitude": -85.7861175,
                "name": "\"Denny's\"",
                "opening_hours": "\"Mo-Th 06:00-23:00; Fr 06:00-24:00; Sa 00:00-24:00; Su 00:00-23:00\"",
                "phone": "\"(812) 752-1439\"",
                "website": "\"https://locations.dennys.com/IN/SCOTTSBURG/248856\""
            },
            {
                "__id": 1145,
                "address_city": "\"Hayward\"",
                "address_full": "\"25010 Hesperian Blvd\"",
                "address_postcode": "\"94545\"",
                "address_state": "\"CA\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american\"",
                "latitude": 37.64475253642875,
                "longitude": -122.10465670858098,
                "name": "\"Denny's\"",
                "opening_hours": "\"24/7\"",
                "phone": "\"(510) 925-2771\"",
                "website": "\"https://locations.dennys.com/CA/HAYWARD/249621\""
            },
            {
                "__id": 947,
                "address_city": "\"San Jacinto\"",
                "address_full": "\"1290 North State St\"",
                "address_postcode": "\"92583\"",
                "address_state": "\"CA\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american\"",
                "latitude": 33.8045020609494,
                "longitude": -116.97262296157533,
                "name": "\"Denny's\"",
                "opening_hours": "\"Mo-Tu 06:00-22:00; We-Sa 00:00-24:00; Su 00:00-22:00\"",
                "phone": "\"(951) 487-9237\"",
                "website": "\"https://locations.dennys.com/CA/SAN-JACINTO/248163\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "nationwide_gb": {
        "__id": 42,
        "id": "nationwide_gb",
        "name": "Nationwide Great Britain",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'image', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'street_address', 'website']",
        "rowLength": 549,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763472,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of nationwide_gb locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 547,
                "address_city": "\"Aberystwyth\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"SY23 1DE\"",
                "image": "\"https://dynl.mktgcdn.com/p/jPpU9bYhzEYWnQ2poYw1EIYj9ha4ySR9guujLOLODIc/400x400.jpg\"",
                "latitude": 52.4150411,
                "longitude": -4.0841313,
                "name": "\"Nationwide Aberystwyth\"",
                "opening_hours": "\"Mo-Tu 09:00-16:30; We 10:00-16:30; Th-Fr 09:00-16:30; Sa 09:00-12:00\"",
                "phone": "\"0345 266 0510\"",
                "street_address": "\"7 Great Darkgate Street\"",
                "website": "\"https://www.nationwide.co.uk/branches/aberystwyth/7-great-darkgate-street\""
            },
            {
                "__id": 342,
                "address_city": "\"Inverness\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"IV2 3NA\"",
                "image": "\"https://dynl.mktgcdn.com/p/jPpU9bYhzEYWnQ2poYw1EIYj9ha4ySR9guujLOLODIc/400x400.jpg\"",
                "latitude": 57.47817838354015,
                "longitude": -4.2217706144401745,
                "name": "\"Nationwide Inverness\"",
                "opening_hours": "\"Mo-Tu 09:00-16:30; We 10:00-16:30; Th-Fr 09:00-16:30; Sa 09:00-12:00\"",
                "phone": "\"0345 266 0916\"",
                "street_address": "\"16 Eastgate\"",
                "website": "\"https://www.nationwide.co.uk/branches/inverness/16-eastgate\""
            },
            {
                "__id": 147,
                "address_city": "\"Tiverton\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"EX16 6AA\"",
                "image": "\"https://dynl.mktgcdn.com/p/jPpU9bYhzEYWnQ2poYw1EIYj9ha4ySR9guujLOLODIc/400x400.jpg\"",
                "latitude": 50.903487827473214,
                "longitude": -3.4861230296866097,
                "name": "\"Nationwide Tiverton\"",
                "opening_hours": "\"Mo-Tu 09:00-16:30; We 10:00-16:30; Th-Fr 09:00-16:30\"",
                "phone": "\"0345 266 0429\"",
                "street_address": "\"11 Bampton Street\"",
                "website": "\"https://www.nationwide.co.uk/branches/tiverton/11-bampton-street\""
            },
            {
                "__id": 432,
                "address_city": "\"Durham\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"DH1 3PN\"",
                "image": "\"https://dynl.mktgcdn.com/p/jPpU9bYhzEYWnQ2poYw1EIYj9ha4ySR9guujLOLODIc/400x400.jpg\"",
                "latitude": 54.776826363825904,
                "longitude": -1.5752475410863553,
                "name": "\"Nationwide Durham\"",
                "opening_hours": "\"Mo-Tu 09:00-16:30; We 10:00-16:30; Th-Fr 09:00-16:30; Sa 09:00-12:00\"",
                "phone": "\"0345 266 0814\"",
                "street_address": "\"9 Market Place\"",
                "website": "\"https://www.nationwide.co.uk/branches/durham/9-market-place\""
            },
            {
                "__id": 303,
                "address_city": "\"Maidstone\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"ME14 1DA\"",
                "image": "\"https://dynl.mktgcdn.com/p/jPpU9bYhzEYWnQ2poYw1EIYj9ha4ySR9guujLOLODIc/400x400.jpg\"",
                "latitude": 51.273681606306866,
                "longitude": 0.5247417837108515,
                "name": "\"Nationwide Maidstone\"",
                "opening_hours": "\"Mo-Tu 09:00-16:30; We 10:00-16:30; Th-Fr 09:00-16:30; Sa 09:30-13:00\"",
                "phone": "\"0345 266 0277\"",
                "street_address": "\"28 King Street\"",
                "website": "\"https://www.nationwide.co.uk/branches/maidstone/28-king-street\""
            },
            {
                "__id": 113,
                "address_city": "\"Weymouth\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"DT4 8PX\"",
                "image": "\"https://dynl.mktgcdn.com/p/jPpU9bYhzEYWnQ2poYw1EIYj9ha4ySR9guujLOLODIc/400x400.jpg\"",
                "latitude": 50.6105399,
                "longitude": -2.4537896,
                "name": "\"Nationwide Weymouth\"",
                "opening_hours": "\"Mo-Tu 09:00-16:30; We 10:00-16:30; Th-Fr 09:00-16:30; Sa 09:00-12:00\"",
                "phone": "\"0345 266 0301\"",
                "street_address": "\"9 St Mary Street\"",
                "website": "\"https://www.nationwide.co.uk/branches/weymouth/9-st-mary-street\""
            },
            {
                "__id": 533,
                "address_city": "\"Bedford\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"MK40 1LN\"",
                "image": "\"https://dynl.mktgcdn.com/p/jPpU9bYhzEYWnQ2poYw1EIYj9ha4ySR9guujLOLODIc/400x400.jpg\"",
                "latitude": 52.13779564851772,
                "longitude": -0.4701217264209845,
                "name": "\"Nationwide Bedford\"",
                "opening_hours": "\"Mo-Tu 09:00-16:30; We 10:00-16:30; Th-Fr 09:00-16:30; Sa 09:30-13:00\"",
                "phone": "\"0345 266 0604\"",
                "street_address": "\"17-19 AllHallows\"",
                "website": "\"https://www.nationwide.co.uk/branches/bedford/17-19-allhallows\""
            },
            {
                "__id": 438,
                "address_city": "\"Dover\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"CT16 1AU\"",
                "image": "\"https://dynl.mktgcdn.com/p/jPpU9bYhzEYWnQ2poYw1EIYj9ha4ySR9guujLOLODIc/400x400.jpg\"",
                "latitude": 51.12711661072407,
                "longitude": 1.3101462274516962,
                "name": "\"Nationwide Dover\"",
                "opening_hours": "\"Mo-Tu 09:00-16:30; We 10:00-16:30; Th-Fr 09:00-16:30; Sa 09:00-12:00\"",
                "phone": "\"0345 266 0285\"",
                "street_address": "\"67 Biggin Street\"",
                "website": "\"https://www.nationwide.co.uk/branches/dover/67-biggin-street\""
            },
            {
                "__id": 376,
                "address_city": "\"Harlow\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"CM20 1JA\"",
                "image": "\"https://dynl.mktgcdn.com/p/jPpU9bYhzEYWnQ2poYw1EIYj9ha4ySR9guujLOLODIc/400x400.jpg\"",
                "latitude": 51.76928773349305,
                "longitude": 0.09399909654382554,
                "name": "\"Nationwide Harlow\"",
                "opening_hours": "\"Mo-Tu 09:00-16:30; We 10:00-16:30; Th-Fr 09:00-16:30; Sa 09:00-12:00\"",
                "phone": "\"0345 266 0694\"",
                "street_address": "\"36A Broad Walk Harvey Centre\"",
                "website": "\"https://www.nationwide.co.uk/branches/harlow/36a-broad-walk\""
            },
            {
                "__id": 462,
                "address_city": "\"Colchester\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"CO1 1JG\"",
                "image": "\"https://dynl.mktgcdn.com/p/jPpU9bYhzEYWnQ2poYw1EIYj9ha4ySR9guujLOLODIc/400x400.jpg\"",
                "latitude": 51.88921381919005,
                "longitude": 0.8978048711742304,
                "name": "\"Nationwide Colchester\"",
                "opening_hours": "\"Mo-Tu 09:00-16:30; We 10:00-16:30; Th-Fr 09:00-16:30; Sa 09:30-13:00\"",
                "phone": "\"0345 266 0601\"",
                "street_address": "\"10 Culver Street West\"",
                "website": "\"https://www.nationwide.co.uk/branches/colchester/10-culver-street-west\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "superdry": {
        "__id": 43,
        "id": "superdry",
        "name": "Superdry",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'image', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'website']",
        "rowLength": 594,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763472,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of superdry locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 508,
                "address_city": "\"Freiburg\"",
                "address_country": "\"DE\"",
                "address_postcode": "\"79098\"",
                "address_state": "\"\"",
                "image": "\"https://dynl.mktgcdn.com/p/8WTN67iLle6ZmSReLRilH3VmQPqFMnvS9vRQYRcs7fg/150x150.png\"",
                "latitude": 47.99523785869357,
                "longitude": 7.849200981319928,
                "name": "\"Superdry  Freiburg\"",
                "opening_hours": "\"Mo-Sa 10:00-20:00\"",
                "phone": "\"0761 31963615\"",
                "shop": "\"clothes\"",
                "street_address": "\"Bertholdstrasse 5\"",
                "website": "\"https://stores.superdry.com/de/freiburg/bertholdstrasse-5\""
            },
            {
                "__id": 106,
                "address_city": "\"Amstelveen\"",
                "address_country": "\"NL\"",
                "address_postcode": "\"1181 ZL\"",
                "address_state": "\"\"",
                "image": "\"https://dynl.mktgcdn.com/p/8WTN67iLle6ZmSReLRilH3VmQPqFMnvS9vRQYRcs7fg/150x150.png\"",
                "latitude": 52.3034539,
                "longitude": 4.8597008,
                "name": "\"Superdry  Amstelveen\"",
                "opening_hours": "\"Mo 12:00-18:00; Tu-We 10:00-18:00; Th 10:00-21:00; Fr-Sa 10:00-18:00; Su 12:00-18:00\"",
                "phone": "\"020 640 1222\"",
                "shop": "\"clothes\"",
                "street_address": "\"Rembrandthof 27\"",
                "website": "\"https://stores.superdry.com/nl/amstelveen/rembrandthof-27\""
            },
            {
                "__id": 321,
                "address_city": "\"Roppenheim\"",
                "address_country": "\"FR\"",
                "address_postcode": "\"67480\"",
                "address_state": "\"\"",
                "image": "\"https://dynl.mktgcdn.com/p/8WTN67iLle6ZmSReLRilH3VmQPqFMnvS9vRQYRcs7fg/196x196.png\"",
                "latitude": 48.8367543,
                "longitude": 8.0879666,
                "name": "\"Superdry  Roppenheim\"",
                "opening_hours": "\"Mo-Sa 10:00-19:00\"",
                "phone": "\"09 67 16 15 68\"",
                "shop": "\"clothes\"",
                "street_address": "\"The Style Outlet Center - Cellule C 25\"",
                "website": "\"https://stores.superdry.com/fr/roppenheim/the-style-outlet-center-cellule-c-25\""
            },
            {
                "__id": 472,
                "address_city": "\"Copenhagen\"",
                "address_country": "\"DK\"",
                "address_postcode": "\"1150\"",
                "address_state": "\"\"",
                "image": "\"https://dynl.mktgcdn.com/p/8WTN67iLle6ZmSReLRilH3VmQPqFMnvS9vRQYRcs7fg/150x150.png\"",
                "latitude": 55.680199846039244,
                "longitude": 12.579280370673311,
                "name": "\"Superdry  Copenhagen\"",
                "opening_hours": "\"Mo-Th 10:00-18:00; Fr 10:00-19:00; Sa 10:00-18:00; Su 11:00-16:00\"",
                "phone": "\"33 15 13 53\"",
                "shop": "\"clothes\"",
                "street_address": "\"K\u00f8bmagergade 26\"",
                "website": "\"https://stores.superdry.com/dk/copenhagen/k\u00f8bmagergade-26\""
            },
            {
                "__id": 518,
                "address_city": "\"Braunschweig\"",
                "address_country": "\"DE\"",
                "address_postcode": "\"38100\"",
                "address_state": "\"\"",
                "image": "\"https://dynl.mktgcdn.com/p/8WTN67iLle6ZmSReLRilH3VmQPqFMnvS9vRQYRcs7fg/150x150.png\"",
                "latitude": 52.26510433841171,
                "longitude": 10.528139219233728,
                "name": "\"Superdry  Braunschweig\"",
                "opening_hours": "\"Mo-Sa 10:00-20:00\"",
                "phone": "\"0531 26150820\"",
                "shop": "\"clothes\"",
                "street_address": "\"Unit 00.SH.060 Platz am Ritterbrunnen 1\"",
                "website": "\"https://stores.superdry.com/de/braunschweig/unit-00.sh.060\""
            },
            {
                "__id": 434,
                "address_city": "\"Albi\"",
                "address_country": "\"FR\"",
                "address_postcode": "\"81000\"",
                "address_state": "\"\"",
                "image": "\"https://dynl.mktgcdn.com/p/8WTN67iLle6ZmSReLRilH3VmQPqFMnvS9vRQYRcs7fg/196x196.png\"",
                "latitude": 43.92877,
                "longitude": 2.144174,
                "name": "\"Superdry  Albi Women\"",
                "opening_hours": "\"\"",
                "phone": "\"09 67 29 94 71\"",
                "shop": "\"clothes\"",
                "street_address": "\"Rue Saint Julien 12\"",
                "website": "\"https://stores.superdry.com/fr/albi/rue-saint-julien-12\""
            },
            {
                "__id": 34,
                "address_city": "\"Cambridge\"",
                "address_country": "\"US\"",
                "address_postcode": "\"02141\"",
                "address_state": "\"MA\"",
                "image": "\"https://dynl.mktgcdn.com/p/8WTN67iLle6ZmSReLRilH3VmQPqFMnvS9vRQYRcs7fg/150x150.png\"",
                "latitude": 42.36771327576608,
                "longitude": -71.07568354150442,
                "name": "\"Superdry  Cambridgeside Galleria\"",
                "opening_hours": "\"Mo-Th 11:00-19:00; Fr-Sa 11:00-20:00; Su 12:00-18:00\"",
                "phone": "\"(617) 245-2975\"",
                "shop": "\"clothes\"",
                "street_address": "\"100 Cambridgeside Pl. Unit E126A/E128\"",
                "website": "\"https://stores.superdry.com/us/ma/cambridge/100-cambridgeside-pl.\""
            },
            {
                "__id": 384,
                "address_city": "\"Dunkerque\"",
                "address_country": "\"FR\"",
                "address_postcode": "\"59140\"",
                "address_state": "\"\"",
                "image": "\"https://dynl.mktgcdn.com/p/8WTN67iLle6ZmSReLRilH3VmQPqFMnvS9vRQYRcs7fg/196x196.png\"",
                "latitude": 51.03538299901046,
                "longitude": 2.375828317773623,
                "name": "\"Superdry  Dunkerque\"",
                "opening_hours": "\"\"",
                "phone": "\"03 21 85 56 17\"",
                "shop": "\"clothes\"",
                "street_address": "\"7 Rue De L'Amiral Ronach\"",
                "website": "\"https://stores.superdry.com/fr/dunkerque/7-rue-de-l'amiral-ronach\""
            },
            {
                "__id": 409,
                "address_city": "\"Bourg En Bresse\"",
                "address_country": "\"FR\"",
                "address_postcode": "\"01000\"",
                "address_state": "\"\"",
                "image": "\"https://dynl.mktgcdn.com/p/8WTN67iLle6ZmSReLRilH3VmQPqFMnvS9vRQYRcs7fg/196x196.png\"",
                "latitude": 46.205374,
                "longitude": 5.22593,
                "name": "\"Superdry  Bourg En Bresse\"",
                "opening_hours": "\"\"",
                "phone": "\"04 74 22 68 61\"",
                "shop": "\"clothes\"",
                "street_address": "\"11 Rue Notre Dama\"",
                "website": "\"https://stores.superdry.com/fr/bourg-en-bresse/11-rue-notre-dama\""
            },
            {
                "__id": 277,
                "address_city": "\"Bournemouth\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"BH1 2AD\"",
                "address_state": "\"\"",
                "image": "\"https://dynl.mktgcdn.com/p/8WTN67iLle6ZmSReLRilH3VmQPqFMnvS9vRQYRcs7fg/150x150.png\"",
                "latitude": 50.72071067723162,
                "longitude": -1.8763105791217005,
                "name": "\"Superdry  Bournemouth\"",
                "opening_hours": "\"Mo-Sa 09:30-18:00; Su 11:00-17:00\"",
                "phone": "\"01202 310032\"",
                "shop": "\"clothes\"",
                "street_address": "\"Part Ground Floor\"",
                "website": "\"https://stores.superdry.com/gb/bournemouth/part-ground-floor\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "matalan_gb": {
        "__id": 44,
        "id": "matalan_gb",
        "name": "Matalan Great Britain",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'image', 'latitude', 'longitude', 'name', 'phone', 'shop', 'street_address', 'twitter', 'website']",
        "rowLength": 235,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763473,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of matalan_gb locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 141,
                "address_city": "\"Kings Lynn\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"PE30 1RX\"",
                "image": "\"https://matalan-assets.imgix.net/assets/matalan-logo-responsive-0f1c895c6ec9b0b427bf3614e1d03352e41e2e501608cabb0d042b5d22282259.svg\"",
                "latitude": 52.753958,
                "longitude": 0.404718,
                "name": "\"Matalan\"",
                "phone": "\"0333 0044444\"",
                "shop": "\"clothes\"",
                "street_address": "\"Blackfriars Road\"",
                "twitter": "\"@Matalan\"",
                "website": "\"https://www.matalan.co.uk/stores/kings-lynn\""
            },
            {
                "__id": 220,
                "address_city": "\"Stratford-Upon-Avon\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"CV37 0HZ\"",
                "image": "\"https://matalan-assets.imgix.net/assets/matalan-logo-responsive-0f1c895c6ec9b0b427bf3614e1d03352e41e2e501608cabb0d042b5d22282259.svg\"",
                "latitude": 52.200588,
                "longitude": -1.711724,
                "name": "\"Matalan\"",
                "phone": "\"0333 0044444\"",
                "shop": "\"clothes\"",
                "street_address": "\"Unit E Maybird Centre\"",
                "twitter": "\"@Matalan\"",
                "website": "\"https://www.matalan.co.uk/stores/stratford\""
            },
            {
                "__id": 174,
                "address_city": "\"Northampton\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"NN5 5BE\"",
                "image": "\"https://matalan-assets.imgix.net/assets/matalan-logo-responsive-0f1c895c6ec9b0b427bf3614e1d03352e41e2e501608cabb0d042b5d22282259.svg\"",
                "latitude": 52.239105,
                "longitude": -0.929039,
                "name": "\"Matalan\"",
                "phone": "\"0333 0044444\"",
                "shop": "\"clothes\"",
                "street_address": "\"Unit 1\"",
                "twitter": "\"@Matalan\"",
                "website": "\"https://www.matalan.co.uk/stores/northampton\""
            },
            {
                "__id": 181,
                "address_city": "\"Manchester\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"M11 2NN\"",
                "image": "\"https://matalan-assets.imgix.net/assets/matalan-logo-responsive-0f1c895c6ec9b0b427bf3614e1d03352e41e2e501608cabb0d042b5d22282259.svg\"",
                "latitude": 53.473821,
                "longitude": -2.175378,
                "name": "\"Matalan\"",
                "phone": "\"0333 0044444\"",
                "shop": "\"clothes\"",
                "street_address": "\"Ashton Old Road\"",
                "twitter": "\"@Matalan\"",
                "website": "\"https://www.matalan.co.uk/stores/openshaw\""
            },
            {
                "__id": 111,
                "address_city": "\"Exeter\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"EX2 8LH\"",
                "image": "\"https://matalan-assets.imgix.net/assets/matalan-logo-responsive-0f1c895c6ec9b0b427bf3614e1d03352e41e2e501608cabb0d042b5d22282259.svg\"",
                "latitude": 50.709603,
                "longitude": -3.535955,
                "name": "\"Matalan\"",
                "phone": "\"0333 0044444\"",
                "shop": "\"clothes\"",
                "street_address": "\"Unit 7\"",
                "twitter": "\"@Matalan\"",
                "website": "\"https://www.matalan.co.uk/stores/exeter\""
            },
            {
                "__id": 62,
                "address_city": "\"Bromborough\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"CH62 7ES\"",
                "image": "\"https://matalan-assets.imgix.net/assets/matalan-logo-responsive-0f1c895c6ec9b0b427bf3614e1d03352e41e2e501608cabb0d042b5d22282259.svg\"",
                "latitude": 53.333986,
                "longitude": -2.977453,
                "name": "\"Matalan\"",
                "phone": "\"0333 0044444\"",
                "shop": "\"clothes\"",
                "street_address": "\"Bromborough Village Road\"",
                "twitter": "\"@Matalan\"",
                "website": "\"https://www.matalan.co.uk/stores/bromborough\""
            },
            {
                "__id": 58,
                "address_city": "\"Somerset\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"TA6 4BH\"",
                "image": "\"https://matalan-assets.imgix.net/assets/matalan-logo-responsive-0f1c895c6ec9b0b427bf3614e1d03352e41e2e501608cabb0d042b5d22282259.svg\"",
                "latitude": 51.138518,
                "longitude": -2.992941,
                "name": "\"Matalan\"",
                "phone": "\"0333 0044444\"",
                "shop": "\"clothes\"",
                "street_address": "\"Bristol Road\"",
                "twitter": "\"@Matalan\"",
                "website": "\"https://www.matalan.co.uk/stores/bridgwater\""
            },
            {
                "__id": 30,
                "address_city": "\"Exeter\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"EX2 8BY\"",
                "image": "\"https://matalan-assets.imgix.net/assets/matalan-logo-responsive-0f1c895c6ec9b0b427bf3614e1d03352e41e2e501608cabb0d042b5d22282259.svg\"",
                "latitude": 50.716049,
                "longitude": -3.531508,
                "name": "\"Matalan\"",
                "phone": "\"0333 0044444\"",
                "shop": "\"clothes\"",
                "street_address": "\"Haven Banks\"",
                "twitter": "\"@Matalan\"",
                "website": "\"https://www.matalan.co.uk/stores/exeter-clearance\""
            },
            {
                "__id": 219,
                "address_city": "\"Stoke-on-Trent\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"ST5 0AY\"",
                "image": "\"https://matalan-assets.imgix.net/assets/matalan-logo-responsive-0f1c895c6ec9b0b427bf3614e1d03352e41e2e501608cabb0d042b5d22282259.svg\"",
                "latitude": 53.02952,
                "longitude": -2.208382,
                "name": "\"Matalan\"",
                "phone": "\"0333 0044444\"",
                "shop": "\"clothes\"",
                "street_address": "\"Wolstanton Retail Park\"",
                "twitter": "\"@Matalan\"",
                "website": "\"https://www.matalan.co.uk/stores/stoke\""
            },
            {
                "__id": 35,
                "address_city": "\"Baguley\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"M23 9BL\"",
                "image": "\"https://matalan-assets.imgix.net/assets/matalan-logo-responsive-0f1c895c6ec9b0b427bf3614e1d03352e41e2e501608cabb0d042b5d22282259.svg\"",
                "latitude": 53.402434,
                "longitude": -2.298638,
                "name": "\"Matalan\"",
                "phone": "\"0333 0044444\"",
                "shop": "\"clothes\"",
                "street_address": "\"Brookway Retail park\"",
                "twitter": "\"@Matalan\"",
                "website": "\"https://www.matalan.co.uk/stores/altrincham\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "ra_sushi": {
        "__id": 45,
        "id": "ra_sushi",
        "name": "RA Sushi",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'phone', 'website']",
        "rowLength": 83,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763474,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of ra_sushi locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 83,
                "address_city": "\"Kansas City\"",
                "address_country": "\"US\"",
                "address_postcode": "\"64106\"",
                "address_state": "\"MO\"",
                "latitude": 39.0972997,
                "longitude": -94.5799392,
                "name": "\"T-MOBILE CENTER, KANSAS CITY, MO\"",
                "phone": "\"\"",
                "website": "\"https://rasushi.com/locations/t-mobile-center-kansas-mo/\""
            },
            {
                "__id": 78,
                "address_city": "\"Cleveland\"",
                "address_country": "\"US\"",
                "address_postcode": "\"\"",
                "address_state": "\"OH\"",
                "latitude": 41.49932,
                "longitude": -81.6943605,
                "name": "\"CLEVELAND, OH\"",
                "phone": "\"\"",
                "website": "\"https://rasushi.com/locations/cleveland-oh/\""
            },
            {
                "__id": 80,
                "address_city": "\"Wheeling\"",
                "address_country": "\"US\"",
                "address_postcode": "\"\"",
                "address_state": "\"IL\"",
                "latitude": 42.139359,
                "longitude": -87.926323,
                "name": "\"WHEELING, IL\"",
                "phone": "\"\"",
                "website": "\"https://rasushi.com/locations/wheeling/\""
            },
            {
                "__id": 69,
                "address_city": "\"Dulles\"",
                "address_country": "\"US\"",
                "address_postcode": "\"\"",
                "address_state": "\"VA\"",
                "latitude": 38.9624899,
                "longitude": -77.4380485,
                "name": "\"DULLES, VA\"",
                "phone": "\"\"",
                "website": "\"https://rasushi.com/locations/dulles/\""
            },
            {
                "__id": 45,
                "address_city": "\"LAS COLINAS\"",
                "address_country": "\"US\"",
                "address_postcode": "\"\"",
                "address_state": "\"TX\"",
                "latitude": 32.8958605,
                "longitude": -96.9588229,
                "name": "\"LAS COLINAS, TX\"",
                "phone": "\"\"",
                "website": "\"https://rasushi.com/locations/las-colinas/\""
            },
            {
                "__id": 55,
                "address_city": "\"Puente Hills\"",
                "address_country": "\"US\"",
                "address_postcode": "\"\"",
                "address_state": "\"CA\"",
                "latitude": 33.9997344,
                "longitude": -117.9175627,
                "name": "\"PUENTE HILLS, CA\"",
                "phone": "\"\"",
                "website": "\"https://rasushi.com/locations/puente-hills/\""
            },
            {
                "__id": 66,
                "address_city": "\"Golden Valley\"",
                "address_country": "\"US\"",
                "address_postcode": "\"\"",
                "address_state": "\"MN\"",
                "latitude": 44.9917149,
                "longitude": -93.3600034,
                "name": "\"GOLDEN VALLEY, MN\"",
                "phone": "\"\"",
                "website": "\"https://rasushi.com/locations/golden-valley-mn/\""
            },
            {
                "__id": 33,
                "address_city": "\"\"",
                "address_country": "\"US\"",
                "address_postcode": "\"77027\"",
                "address_state": "\"Houston\"",
                "latitude": 29.7424268,
                "longitude": -95.4436968,
                "name": "\"HOUSTON- HIGHLAND VILLAGE TX\"",
                "phone": "\"713.621.5800\"",
                "website": "\"https://rasushi.com/locations/houston-highland-village-tx/\""
            },
            {
                "__id": 20,
                "address_city": "\"Baltimore\"",
                "address_country": "\"US\"",
                "address_postcode": "\"\"",
                "address_state": "\"MD\"",
                "latitude": 39.2824818,
                "longitude": -76.5974426,
                "name": "\"BALTIMORE, MD\"",
                "phone": "\"410.522.3200\"",
                "website": "\"https://rasushi.com/locations/baltimore/\""
            },
            {
                "__id": 51,
                "address_city": "\"Troy\"",
                "address_country": "\"US\"",
                "address_postcode": "\"\"",
                "address_state": "\"MN\"",
                "latitude": 42.6064095,
                "longitude": -83.1497751,
                "name": "\"TROY, MI\"",
                "phone": "\"\"",
                "website": "\"https://rasushi.com/locations/troy/\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "nandos_pk": {
        "__id": 46,
        "id": "nandos_pk",
        "name": "Nando's Pakistan",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'phone', 'website']",
        "rowLength": 12,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763474,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of nandos_pk locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 2,
                "address_city": "\"Rawalpindi\"",
                "address_country": "\"PK\"",
                "address_full": "\"Plot No. 10 Theme Park Avenue, Phase 7, Bahria Town, Rawalpindi, Punjab, 46220\"",
                "address_state": "\"Punjab\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 33.528749,
                "longitude": 73.098142,
                "name": "\"Nando's Bahria Rawalpindi\"",
                "phone": "\"+2751111626367\"",
                "website": "\"https://www.nandos.pk/eat/restaurant/nandos-bahria-rawalpindi\""
            },
            {
                "__id": 6,
                "address_city": "\"Lahore\"",
                "address_country": "\"PK\"",
                "address_full": "\"Plot No. 13, Sector Z Commercial 3, D.H.A, Lahore Cantt, Lahore, Punjab\"",
                "address_state": "\"Punjab\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 31.475028,
                "longitude": 74.378568,
                "name": "\"Nando's D.H.A. Block Z\"",
                "phone": "\"+2742111626367\"",
                "website": "\"https://www.nandos.pk/eat/restaurant/nandos-dha-Lahore\""
            },
            {
                "__id": 9,
                "address_city": "\"Karachi\"",
                "address_country": "\"PK\"",
                "address_full": "\"Q.M. Building BC-15, Block 7, Showroom 2 KDA Scheme 5 Kehkashan Clifton, Boat Basin, Karachi, Sindh, 75600\"",
                "address_state": "\"Sindh\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 24.82732,
                "longitude": 67.02448,
                "name": "\"Nando's Boat Basin\"",
                "phone": "\"+2721111626367\"",
                "website": "\"https://www.nandos.pk/eat/restaurant/nandos-boat-basin\""
            },
            {
                "__id": 1,
                "address_city": "\"Lahore\"",
                "address_country": "\"PK\"",
                "address_full": "\"157 MB, J block D.H.A Phase 6, Shabbir Sharif Road, Lahore, Punjab, 54792\"",
                "address_state": "\"Punjab\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 31.483664,
                "longitude": 74.456811,
                "name": "\"Nando's DHA Phase 6\"",
                "phone": "\"+2742111626367\"",
                "website": "\"https://www.nandos.pk/eat/restaurant/nandos-dha6-Lahore\""
            },
            {
                "__id": 5,
                "address_city": "\"Islamabad\"",
                "address_country": "\"PK\"",
                "address_full": "\"10-B, Super Market, F6, Islamabad, Punjab\"",
                "address_state": "\"Punjab\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 33.729984,
                "longitude": 73.077886,
                "name": "\"Nando's Islamabad\"",
                "phone": "\"+2751111626367\"",
                "website": "\"https://www.nandos.pk/eat/restaurant/nandos-islamabad\""
            },
            {
                "__id": 8,
                "address_city": "\"Karachi\"",
                "address_country": "\"PK\"",
                "address_full": "\"F&B-2, Ground floor (near Gate-2), Dolmen Mall Clifton, Dolmen City, HC-3, Block-1, Clifton, Karachi, Sindh, 75600\"",
                "address_state": "\"Sindh\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 24.802444,
                "longitude": 67.029386,
                "name": "\"Nando's Dolmen Mall Clifton\"",
                "phone": "\"+2721111626367\"",
                "website": "\"https://www.nandos.pk/eat/restaurant/nandos-dolmen-mall-clifton\""
            },
            {
                "__id": 7,
                "address_city": "\"Lahore\"",
                "address_country": "\"PK\"",
                "address_full": "\"Plot No. 39, Block A/2, Mian Mehmood Ali Kasuri Road, Gulberg 3, Lahore, Punjab\"",
                "address_state": "\"Punjab\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 31.507454,
                "longitude": 74.354793,
                "name": "\"Nando's Gulberg\"",
                "phone": "\"+2742111626367\"",
                "website": "\"https://www.nandos.pk/eat/restaurant/nandos-gulberg\""
            },
            {
                "__id": 3,
                "address_city": "\"Lahore\"",
                "address_country": "\"PK\"",
                "address_full": "\"3rd Floor, Emporium Mall, Abdul Haque Rd, Trade Centre Commercial Area Phase 2, Johar Town, Lahore, Punjab, 54000\"",
                "address_state": "\"Punjab\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 31.466544,
                "longitude": 74.265353,
                "name": "\"Nando's Emporium\"",
                "phone": "\"+2742111626367\"",
                "website": "\"https://www.nandos.pk/eat/restaurant/nandos-emporium\""
            },
            {
                "__id": 11,
                "address_city": "\"Karachi\"",
                "address_country": "\"PK\"",
                "address_full": "\"Plot 8, Block A, Sub Block D, SMCHS, Karachi, Sindh, 72500\"",
                "address_state": "\"Sindh\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 24.862611,
                "longitude": 67.055197,
                "name": "\"Nando's SMCHS\"",
                "phone": "\"+2721111626367\"",
                "website": "\"https://www.nandos.pk/eat/restaurant/nandos-smchs\""
            },
            {
                "__id": 12,
                "address_city": "\"Karachi\"",
                "address_country": "\"PK\"",
                "address_full": "\"Plot No. 31-D, Phase 6, D.H.A, Khayaban-e-Seher, Karachi, Sindh, 75500\"",
                "address_state": "\"Sindh\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 24.794903,
                "longitude": 67.049944,
                "name": "\"Nando's Sehar\"",
                "phone": "\"+2721111626367\"",
                "website": "\"https://www.nandos.pk/eat/restaurant/nandos-sehar\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "childcare_network": {
        "__id": 47,
        "id": "childcare_network",
        "name": "Childcare Network",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'website']",
        "rowLength": 233,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763475,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of childcare_network locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 134,
                "address_city": "\"Morehead City\"",
                "address_country": "\"US\"",
                "address_full": "\"204 N 18th Street\"",
                "address_postcode": "\"28557\"",
                "address_state": "\"North Carolina\"",
                "latitude": 34.72391877033037,
                "longitude": -76.72703271746019,
                "name": "\"Morehead City North 18th Street\"",
                "opening_hours": "\"6:00 AM\"",
                "phone": "\"(252) 247-2006\"",
                "website": "\"https://schools.childcarenetwork.com/nc/morehead-city/204-n-18th-street\""
            },
            {
                "__id": 93,
                "address_city": "\"Dacula\"",
                "address_country": "\"US\"",
                "address_full": "\"405 Dacula Road\"",
                "address_postcode": "\"30019\"",
                "address_state": "\"Georgia\"",
                "latitude": 33.998027047658226,
                "longitude": -83.90343890258566,
                "name": "\"Dacula Dacula Road\"",
                "opening_hours": "\"6:00 AM\"",
                "phone": "\"(770) 995-7660\"",
                "website": "\"https://schools.childcarenetwork.com/ga/dacula/405-dacula-road\""
            },
            {
                "__id": 6,
                "address_city": "\"Sunnyvale\"",
                "address_country": "\"US\"",
                "address_full": "\"240 S Collins Road\"",
                "address_postcode": "\"75182\"",
                "address_state": "\"Texas\"",
                "latitude": 32.777477,
                "longitude": -96.567481,
                "name": "\"Sunnyvale S. Collins Road\"",
                "opening_hours": "\"6:30 AM\"",
                "phone": "\"(972) 203-1999\"",
                "website": "\"https://schools.childcarenetwork.com/tx/sunnyvale/240-s-collins-road\""
            },
            {
                "__id": 177,
                "address_city": "\"Elizabethtown\"",
                "address_country": "\"US\"",
                "address_full": "\"500 Mercer Mill Road\"",
                "address_postcode": "\"28337\"",
                "address_state": "\"North Carolina\"",
                "latitude": 34.6183776,
                "longitude": -78.6040878,
                "name": "\"Elizabethtown Mercer Mill Road\"",
                "opening_hours": "\"6:30 AM\"",
                "phone": "\"(910) 872-0000\"",
                "website": "\"https://schools.childcarenetwork.com/nc/elizabethtown/500-mercer-mill-road\""
            },
            {
                "__id": 64,
                "address_city": "\"Warner Robins\"",
                "address_country": "\"US\"",
                "address_full": "\"209 Corder Road\"",
                "address_postcode": "\"31088\"",
                "address_state": "\"Georgia\"",
                "latitude": 32.61547459495486,
                "longitude": -83.647244781003,
                "name": "\"Warner Robins Corder Road\"",
                "opening_hours": "\"6:00 AM\"",
                "phone": "\"(478) 922-9918\"",
                "website": "\"https://schools.childcarenetwork.com/ga/warner-robins/209-corder-road\""
            },
            {
                "__id": 73,
                "address_city": "\"Savannah\"",
                "address_country": "\"US\"",
                "address_full": "\"12441 White Bluff Road\"",
                "address_postcode": "\"31419\"",
                "address_state": "\"Georgia\"",
                "latitude": 31.97283856649411,
                "longitude": -81.13348163252351,
                "name": "\"Savannah White Bluff Road\"",
                "opening_hours": "\"6:00 AM\"",
                "phone": "\"(912) 925-5238\"",
                "website": "\"https://schools.childcarenetwork.com/ga/savannah/12441-white-bluff-road\""
            },
            {
                "__id": 57,
                "address_city": "\"Riverdale\"",
                "address_country": "\"US\"",
                "address_full": "\"6048 West Fayetteville Road\"",
                "address_postcode": "\"30349\"",
                "address_state": "\"Georgia\"",
                "latitude": 33.59003632170551,
                "longitude": -84.44679285706042,
                "name": "\"College Park W. Fayetteville Road\"",
                "opening_hours": "\"6:30 AM\"",
                "phone": "\"(770) 996-1634\"",
                "website": "\"https://schools.childcarenetwork.com/ga/riverdale/6048-west-fayetteville-road\""
            },
            {
                "__id": 227,
                "address_city": "\"Cedar Hill\"",
                "address_country": "\"US\"",
                "address_full": "\"240 East Beltline Road\"",
                "address_postcode": "\"75104\"",
                "address_state": "\"Texas\"",
                "latitude": 32.589386790843065,
                "longitude": -96.93850063252353,
                "name": "\"Cedar Hill Beltline\"",
                "opening_hours": "\"6:30 AM\"",
                "phone": "\"(972) 293-2882\"",
                "website": "\"https://schools.childcarenetwork.com/tx/cedar-hill/240-east-beltline-road\""
            },
            {
                "__id": 195,
                "address_city": "\"Asheville\"",
                "address_country": "\"US\"",
                "address_full": "\"443 Weaverville Hwy\"",
                "address_postcode": "\"28804\"",
                "address_state": "\"North Carolina\"",
                "latitude": 35.667931220015866,
                "longitude": -82.58325654049281,
                "name": "\"Asheville Weaverville Highway\"",
                "opening_hours": "\"6:30 AM\"",
                "phone": "\"(828) 645-2697\"",
                "website": "\"https://schools.childcarenetwork.com/nc/asheville/443-weaverville-hwy\""
            },
            {
                "__id": 114,
                "address_city": "\"Dallas\"",
                "address_country": "\"US\"",
                "address_full": "\"124 Ridge Road\"",
                "address_postcode": "\"30157\"",
                "address_state": "\"Georgia\"",
                "latitude": 33.8077319,
                "longitude": -84.8874895,
                "name": "\"Dallas Ridge Road\"",
                "opening_hours": "\"6:00 AM\"",
                "phone": "\"(770) 459-1128\"",
                "website": "\"https://schools.childcarenetwork.com/ga/dallas/124-ridge-road\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "specsavers_gb": {
        "__id": 48,
        "id": "specsavers_gb",
        "name": "Specsavers Great Britain",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'image', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'website']",
        "rowLength": 838,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763476,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of specsavers_gb locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 260,
                "address_city": "\"Nuneaton\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"CV11 4EE\"",
                "address_state": "\"Warwickshire\"",
                "image": "\"https://a.mktgcdn.com/p/azhfTDLZ29m3lxUsEaZz8JbvWdm35eAkBjMSSbJRA4A/500x500.jpg\"",
                "latitude": 52.522495,
                "longitude": -1.468333,
                "name": "\"Nuneaton\"",
                "opening_hours": "\"Mo-Fr 08:45-17:45; Sa 08:30-17:00; Su 09:00-17:00\"",
                "phone": "\"024 7634 2078\"",
                "shop": "\"optician\"",
                "street_address": "\"14 Market Place\"",
                "website": "\"https://www.specsavers.co.uk/stores/nuneaton\""
            },
            {
                "__id": 361,
                "address_city": "\"Peckham\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"SE15 5EW\"",
                "address_state": "\"LND\"",
                "image": "\"https://a.mktgcdn.com/p/azhfTDLZ29m3lxUsEaZz8JbvWdm35eAkBjMSSbJRA4A/500x500.jpg\"",
                "latitude": 51.47305,
                "longitude": -0.06961405,
                "name": "\"London - Peckham\"",
                "opening_hours": "\"Mo-Fr 09:00-18:00; Sa 09:30-18:00; Su 10:00-17:00\"",
                "phone": "\"020 7277 7222\"",
                "shop": "\"optician\"",
                "street_address": "\"Unit 3 The Aylesham Centre, 3 Rye Lane\"",
                "website": "\"https://www.specsavers.co.uk/stores/peckham\""
            },
            {
                "__id": 514,
                "address_city": "\"Colne\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"BB8 9HY\"",
                "address_state": "\"North West (South)\"",
                "image": "\"https://a.mktgcdn.com/p/azhfTDLZ29m3lxUsEaZz8JbvWdm35eAkBjMSSbJRA4A/500x500.jpg\"",
                "latitude": 53.859913,
                "longitude": -2.166319,
                "name": "\"Colne Sainsbury's\"",
                "opening_hours": "\"Mo-Sa 09:00-17:30; Su 11:00-17:00\"",
                "phone": "\"01282 868816\"",
                "shop": "\"optician\"",
                "street_address": "\"Sainsbury's Windy Bank\"",
                "website": "\"https://www.specsavers.co.uk/stores/colnesainsburys\""
            },
            {
                "__id": 236,
                "address_city": "\"Street\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"BA16 0EZ\"",
                "address_state": "\"Somerset\"",
                "image": "\"https://a.mktgcdn.com/p/azhfTDLZ29m3lxUsEaZz8JbvWdm35eAkBjMSSbJRA4A/500x500.jpg\"",
                "latitude": 51.12623,
                "longitude": -2.7405202,
                "name": "\"Street\"",
                "opening_hours": "\"Mo-Th 08:30-20:00; Fr-Sa 08:30-17:30\"",
                "phone": "\"01458 449400\"",
                "shop": "\"optician\"",
                "street_address": "\"93 High Street\"",
                "website": "\"https://www.specsavers.co.uk/stores/street\""
            },
            {
                "__id": 44,
                "address_city": "\"Largs\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"KA30 8AL\"",
                "address_state": "\"North Ayrshire\"",
                "image": "\"https://a.mktgcdn.com/p/azhfTDLZ29m3lxUsEaZz8JbvWdm35eAkBjMSSbJRA4A/500x500.jpg\"",
                "latitude": 55.793858,
                "longitude": -4.868747,
                "name": "\"Largs\"",
                "opening_hours": "\"Mo-Sa 09:00-18:00; Su 09:00-16:00\"",
                "phone": "\"01475 687880\"",
                "shop": "\"optician\"",
                "street_address": "\"52-56 Main Street\"",
                "website": "\"https://www.specsavers.co.uk/stores/largs\""
            },
            {
                "__id": 105,
                "address_city": "\"Smethwick\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"B66 4BJ\"",
                "address_state": "\"SAW\"",
                "image": "\"https://a.mktgcdn.com/p/azhfTDLZ29m3lxUsEaZz8JbvWdm35eAkBjMSSbJRA4A/500x500.jpg\"",
                "latitude": 52.474117,
                "longitude": -1.9687614,
                "name": "\"Bearwood\"",
                "opening_hours": "\"Mo 09:00-20:00; Tu 09:00-17:30; We 09:00-20:00; Th-Sa 09:00-17:30; Su 10:00-16:30\"",
                "phone": "\"0121 420 0340\"",
                "shop": "\"optician\"",
                "street_address": "\"599 Bearwood Road\"",
                "website": "\"https://www.specsavers.co.uk/stores/bearwood\""
            },
            {
                "__id": 367,
                "address_city": "\"Bicester\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"OX26 6JF\"",
                "address_state": "\"Oxfordshire\"",
                "image": "\"https://a.mktgcdn.com/p/azhfTDLZ29m3lxUsEaZz8JbvWdm35eAkBjMSSbJRA4A/500x500.jpg\"",
                "latitude": 51.89808,
                "longitude": -1.1515158,
                "name": "\"Bicester\"",
                "opening_hours": "\"Mo-Fr 08:25-18:00; Sa 08:25-17:00; Su 09:00-16:00\"",
                "phone": "\"01869 321422\"",
                "shop": "\"optician\"",
                "street_address": "\"25 Sheep Street\"",
                "website": "\"https://www.specsavers.co.uk/stores/bicester\""
            },
            {
                "__id": 222,
                "address_city": "\"Stowmarket\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"IP14 1AH\"",
                "address_state": "\"Suffolk\"",
                "image": "\"https://a.mktgcdn.com/p/azhfTDLZ29m3lxUsEaZz8JbvWdm35eAkBjMSSbJRA4A/500x500.jpg\"",
                "latitude": 52.187366,
                "longitude": 0.9963389,
                "name": "\"Stowmarket\"",
                "opening_hours": "\"Mo-Fr 09:00-17:30; Sa 09:00-17:00; Su 09:00-16:00\"",
                "phone": "\"01449 618280\"",
                "shop": "\"optician\"",
                "street_address": "\"11 Ipswich Street\"",
                "website": "\"https://www.specsavers.co.uk/stores/stowmarket\""
            },
            {
                "__id": 241,
                "address_city": "\"Wells\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"BA5 2SN\"",
                "address_state": "\"Somerset\"",
                "image": "\"https://a.mktgcdn.com/p/azhfTDLZ29m3lxUsEaZz8JbvWdm35eAkBjMSSbJRA4A/500x500.jpg\"",
                "latitude": 51.208332,
                "longitude": -2.648287,
                "name": "\"Wells\"",
                "opening_hours": "\"Mo-Fr 09:00-17:30; Sa-Su 09:00-17:00\"",
                "phone": "\"01749 673694\"",
                "shop": "\"optician\"",
                "street_address": "\"60a High Street\"",
                "website": "\"https://www.specsavers.co.uk/stores/wells\""
            },
            {
                "__id": 638,
                "address_city": "\"Cardiff\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"CF10 2GR\"",
                "address_state": "\"Cardiff\"",
                "image": "\"https://a.mktgcdn.com/p/azhfTDLZ29m3lxUsEaZz8JbvWdm35eAkBjMSSbJRA4A/500x500.jpg\"",
                "latitude": 51.48231,
                "longitude": -3.1739118,
                "name": "\"Cardiff - Queen Street Opticians and Audiologists\"",
                "opening_hours": "\"Mo-Fr 09:00-18:30; Sa 09:00-17:30; Su 11:00-17:00\"",
                "phone": "\"029 2039 0297\"",
                "shop": "\"optician\"",
                "street_address": "\"90 Queen Street\"",
                "website": "\"https://www.specsavers.co.uk/stores/cardiff\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "benchmark_hospitality": {
        "__id": 49,
        "id": "benchmark_hospitality",
        "name": "Benchmark Hospitality",
        "columnNames": "['address_full', 'latitude', 'longitude', 'name', 'phone', 'website']",
        "rowLength": 58,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763477,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of benchmark_hospitality locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 45,
                "address_full": "\"88 Grandview Avenue, Boothbay Harbor, Maine, 04538\"",
                "latitude": 43.8300429,
                "longitude": -69.6248,
                "name": "\"Spruce Point Inn\"",
                "phone": "\"(207) 633-4152\"",
                "website": "\"https://www.benchmarkresortsandhotels.com/spruce_point_inn_maine/Overview/\""
            },
            {
                "__id": 50,
                "address_full": "\"3320 Mesa Road, Colorado Springs, CO  80904\"",
                "latitude": 38.8794444,
                "longitude": -104.8684324,
                "name": "\"Garden of the Gods Resort and Club\"",
                "phone": "\"719.632.5541\"",
                "website": "\"https://www.benchmarkresortsandhotels.com//garden_of_the_gods_co_springs/\""
            },
            {
                "__id": 47,
                "address_full": "\"\"",
                "latitude": 21.7042435,
                "longitude": -157.9987016,
                "name": "\"Turtle Bay Resort\"",
                "phone": "\"(866) 475-2569\"",
                "website": "\"https://www.benchmarkresortsandhotels.com//turtle_bay_resort_oahu/\""
            },
            {
                "__id": 39,
                "address_full": "\"750 SW Alder Street, Portland, Oregon 97205\"",
                "latitude": 45.520059,
                "longitude": -122.6822671,
                "name": "\"Dossier\"",
                "phone": "\"(503) 294-9000\"",
                "website": "\"https://www.benchmarkresortsandhotels.com/dossier-hotel-portland/Overview/\""
            },
            {
                "__id": 28,
                "address_full": "\"Margaritaville Lake Resort, 600 Margaritaville Parkway, Montgomery, TX 77356\"",
                "latitude": 30.4626729,
                "longitude": -95.6771772,
                "name": "\"Margaritaville Lake Resort, Lake Conroe-Houston\"",
                "phone": "\"936-448-4400\"",
                "website": "\"https://www.benchmarkresortsandhotels.com//margaritaville_lake_conroe_texas\""
            },
            {
                "__id": 23,
                "address_full": "\"44050 Woodridge Parkway Leesburg, VA 20176\"",
                "latitude": 39.0834518,
                "longitude": -77.4784423,
                "name": "\"Lansdowne\"",
                "phone": "\"703-729-8400\"",
                "website": "\"https://www.benchmarkresortsandhotels.com//lansdowne_resort_spa_virginia/\""
            },
            {
                "__id": 2,
                "address_full": "\"One Lake Parkway South Lake Tahoe, California 96150\"",
                "latitude": 38.9559706,
                "longitude": -119.940696,
                "name": "\"Forest Suites Resort\"",
                "phone": "\"800.822.5950\"",
                "website": "\"https://www.benchmarkresortsandhotels.com//forest_suites_resort_tahoe_ca/\""
            },
            {
                "__id": 44,
                "address_full": "\"70 Rowes Wharf, Boston MA 02110\"",
                "latitude": 42.3562,
                "longitude": -71.0504,
                "name": "\"Boston Harbor Hotel\"",
                "phone": "\"617-439-7000 \u00a0 F: 1-800-752-7077\"",
                "website": "\"https://www.benchmarkresortsandhotels.com/boston_harbor_hotel/Overview/\""
            },
            {
                "__id": 53,
                "address_full": "\"1201 East 60th Street, Chicago, IL 60637\"",
                "latitude": 41.7859006,
                "longitude": -87.5983584,
                "name": "\"David Rubenstein Forum\"",
                "phone": "\"773-702-2110\"",
                "website": "\"https://www.benchmarkresortsandhotels.com/david_rubenstein_forum_chicago/Overview/\""
            },
            {
                "__id": 20,
                "address_full": "\"14580 NE 145th Street, Woodinville, WA 98072\"",
                "latitude": 47.7337759,
                "longitude": -122.1470106,
                "name": "\"Willows Lodge\"",
                "phone": "\"(425) 424-3900\"",
                "website": "\"https://www.benchmarkresortsandhotels.com//willows_lodge_washington/\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "bloomingdales": {
        "__id": 50,
        "id": "bloomingdales",
        "name": "Bloomingdale's",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'website']",
        "rowLength": 86,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763477,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of bloomingdales locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 34,
                "address_city": "\"Boca Raton\"",
                "address_country": "\"US\"",
                "address_full": "\"5840 Glades Road\"",
                "address_postcode": "\"33431\"",
                "address_state": "\"FL\"",
                "latitude": 26.36309039695408,
                "longitude": -80.1343910255724,
                "name": "\"Bloomingdale's Forty Carrots Boca Raton\"",
                "opening_hours": "\"Mo-Su 11:00-18:00\"",
                "phone": "\"(561) 394-2237\"",
                "shop": "\"department_store\"",
                "website": "\"https://locations.bloomingdales.com/forty-carrots-boca-raton-fl\""
            },
            {
                "__id": 18,
                "address_city": "\"New York\"",
                "address_country": "\"US\"",
                "address_full": "\"504 Broadway\"",
                "address_postcode": "\"10012\"",
                "address_state": "\"NY\"",
                "latitude": 40.72202170478139,
                "longitude": -73.99890405841558,
                "name": "\"Bloomingdale's Forty Carrots - CLOSED Soho\"",
                "opening_hours": "\"\"",
                "phone": "\"(212) 729-5155\"",
                "shop": "\"department_store\"",
                "website": "\"https://locations.bloomingdales.com/forty-carrots-soho-ny\""
            },
            {
                "__id": 30,
                "address_city": "\"Chestnut Hill\"",
                "address_country": "\"US\"",
                "address_full": "\"225 Boylston Street\"",
                "address_postcode": "\"02467\"",
                "address_state": "\"MA\"",
                "latitude": 42.32134967855127,
                "longitude": -71.17699107471061,
                "name": "\"Bloomingdale's Forty Carrots Chestnut Hill\"",
                "opening_hours": "\"Mo-Su 11:00-18:00\"",
                "phone": "\"(617) 630-6747\"",
                "shop": "\"department_store\"",
                "website": "\"https://locations.bloomingdales.com/forty-carrots-chestnut-hill-ma\""
            },
            {
                "__id": 40,
                "address_city": "\"Sherman Oaks\"",
                "address_country": "\"US\"",
                "address_full": "\"14060 Riverside Drive\"",
                "address_postcode": "\"91423\"",
                "address_state": "\"CA\"",
                "latitude": 34.15704470428185,
                "longitude": -118.43920343004207,
                "name": "\"Bloomingdale's 59th & Lex Sherman Oaks\"",
                "opening_hours": "\"Mo-Su 11:00-18:00\"",
                "phone": "\"(818) 325-2451\"",
                "shop": "\"department_store\"",
                "website": "\"https://locations.bloomingdales.com/59th-lex-sherman-oaks-ca\""
            },
            {
                "__id": 63,
                "address_city": "\"Miami\"",
                "address_country": "\"US\"",
                "address_full": "\"8778 SW 136th Street\"",
                "address_postcode": "\"33176\"",
                "address_state": "\"FL\"",
                "latitude": 25.643098,
                "longitude": -80.3361513,
                "name": "\"Bloomingdale's - Closed The Falls, Miami\"",
                "opening_hours": "\"\"",
                "phone": "\"(305) 252-6300\"",
                "shop": "\"department_store\"",
                "website": "\"https://locations.bloomingdales.com/the-falls--miami\""
            },
            {
                "__id": 76,
                "address_city": "\"Newport Beach\"",
                "address_country": "\"US\"",
                "address_full": "\"701 Newport Center Drive\"",
                "address_postcode": "\"92660\"",
                "address_state": "\"CA\"",
                "latitude": 33.6173986,
                "longitude": -117.8757824,
                "name": "\"Bloomingdale's Newport Fashion Island\"",
                "opening_hours": "\"Mo-Sa 11:00-20:00; Su 11:00-18:00\"",
                "phone": "\"(949) 729-6600\"",
                "shop": "\"department_store\"",
                "website": "\"https://locations.bloomingdales.com/newport-fashion-island\""
            },
            {
                "__id": 33,
                "address_city": "\"Boca Raton\"",
                "address_country": "\"US\"",
                "address_full": "\"5840 Glades Road\"",
                "address_postcode": "\"33431\"",
                "address_state": "\"FL\"",
                "latitude": 26.36386659645363,
                "longitude": -80.13364142878225,
                "name": "\"Bloomingdale's Petrossian Boca Raton\"",
                "opening_hours": "\"Mo-Su 11:00-18:00\"",
                "phone": "\"(561) 394-2237\"",
                "shop": "\"department_store\"",
                "website": "\"https://locations.bloomingdales.com/petrossian-boca-raton-fl\""
            },
            {
                "__id": 7,
                "address_city": "\"Garden City\"",
                "address_country": "\"US\"",
                "address_full": "\"1050 Old Country Road\"",
                "address_postcode": "\"11530\"",
                "address_state": "\"NY\"",
                "latitude": 40.7427114,
                "longitude": -73.6052414,
                "name": "\"Bloomingdale's Outlet The Gallery at Westbury Plaza Outlet\"",
                "opening_hours": "\"Mo-Sa 11:00-20:00; Su 11:00-19:00\"",
                "phone": "\"(516) 743-4301\"",
                "shop": "\"department_store\"",
                "website": "\"https://locations.bloomingdales.com/the-gallery-at-westbury-plaza-outlet\""
            },
            {
                "__id": 31,
                "address_city": "\"Chicago\"",
                "address_country": "\"US\"",
                "address_full": "\"900 North Michigan Avenue\"",
                "address_postcode": "\"60611\"",
                "address_state": "\"IL\"",
                "latitude": 41.899588381488414,
                "longitude": -87.62500930059431,
                "name": "\"Bloomingdale's Forty Carrots Chicago\"",
                "opening_hours": "\"Mo-Su 11:00-18:00\"",
                "phone": "\"(312) 440-4861\"",
                "shop": "\"department_store\"",
                "website": "\"https://locations.bloomingdales.com/forty-carrots-chicago-il\""
            },
            {
                "__id": 70,
                "address_city": "\"Estero\"",
                "address_country": "\"US\"",
                "address_full": "\"10801 Corkscrew Road\"",
                "address_postcode": "\"33928\"",
                "address_state": "\"FL\"",
                "latitude": 26.4342624,
                "longitude": -81.7758982,
                "name": "\"Bloomingdale's Outlet - Closed Miromar Outlets\"",
                "opening_hours": "\"\"",
                "phone": "\"(239) 271-3455\"",
                "shop": "\"department_store\"",
                "website": "\"https://locations.bloomingdales.com/miromar-outlets\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "pricewaterhousecoopers": {
        "__id": 51,
        "id": "pricewaterhousecoopers",
        "name": "Price Waterhouse Coopers",
        "columnNames": "['address_country', 'address_full', 'address_postcode', 'latitude', 'longitude', 'name', 'office', 'official_name', 'phone', 'website']",
        "rowLength": 686,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763478,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of pricewaterhousecoopers locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 324,
                "address_country": "\"IT\"",
                "address_full": "\"Via Poscolle 43, Udine 33100, Italy\"",
                "address_postcode": "\"\"",
                "latitude": 46.0613675,
                "longitude": 13.2310336,
                "name": "\"Udine\"",
                "office": "\"consulting\"",
                "official_name": "\"PricewaterhouseCoopers\"",
                "phone": "\"+390 (432) 25789\"",
                "website": "\"https://www.pwc.com/us/en/about-us/pwc-office-locations.html#/office/it-udin\""
            },
            {
                "__id": 486,
                "address_country": "\"KR\"",
                "address_full": "\"713 Renaissance Building, 234 Hannuri-daero, Sejong/, 30128, South Korea\"",
                "address_postcode": "\"\"",
                "latitude": 36.48568,
                "longitude": 127.259094,
                "name": "\"Daejeon (PwC Samil)\"",
                "office": "\"consulting\"",
                "official_name": "\"PricewaterhouseCoopers\"",
                "phone": "\"+82 (42) 716 3031\"",
                "website": "\"https://www.pwc.com/us/en/about-us/pwc-office-locations.html#/office/kr-daej1\""
            },
            {
                "__id": 567,
                "address_country": "\"TN\"",
                "address_full": "\"Imm. PwC Rue du Lac d'Annecy, Les Berges du Lac, Tunis 1053, Tunisia\"",
                "address_postcode": "\"\"",
                "latitude": 36.8368816,
                "longitude": 10.232007,
                "name": "\"Tunis\"",
                "office": "\"consulting\"",
                "official_name": "\"PricewaterhouseCoopers\"",
                "phone": "\"+216 71 160 000\"",
                "website": "\"https://www.pwc.com/us/en/about-us/pwc-office-locations.html#/office/tn-tuni1\""
            },
            {
                "__id": 217,
                "address_country": "\"DE\"",
                "address_full": "\"Kapelle-Ufer 4, 10117 Berlin, Germany\"",
                "address_postcode": "\"\"",
                "latitude": 52.523476,
                "longitude": 13.373342,
                "name": "\"Berlin\"",
                "office": "\"consulting\"",
                "official_name": "\"PricewaterhouseCoopers\"",
                "phone": "\"+49 (30) 26 36 0\"",
                "website": "\"https://www.pwc.com/us/en/about-us/pwc-office-locations.html#/office/de-berl31\""
            },
            {
                "__id": 341,
                "address_country": "\"KZ\"",
                "address_full": "\"Renaissance Hotel/, 3rd floor, 15 B Satpayev St., Atyrau E02M0MB8, Republic of Kazakhstan\"",
                "address_postcode": "\"\"",
                "latitude": 47.102889,
                "longitude": 51.906321,
                "name": "\"Atyrau\"",
                "office": "\"consulting\"",
                "official_name": "\"PricewaterhouseCoopers\"",
                "phone": "\"+7 (7122) 763 000\"",
                "website": "\"https://www.pwc.com/us/en/about-us/pwc-office-locations.html#/office/kz-atyr\""
            },
            {
                "__id": 276,
                "address_country": "\"IN\"",
                "address_full": "\"Novus Tower/, 5th Floor/, Plot No. 18/, Sector 18/, Phase - 4/, Gurugram/, India\"",
                "address_postcode": "\"\"",
                "latitude": 28.491284,
                "longitude": 77.0775629999999,
                "name": "\"Delhi Gurugram Novus Tower\"",
                "office": "\"consulting\"",
                "official_name": "\"PricewaterhouseCoopers\"",
                "phone": "\"+91 (124) 446 7300 3rd Flr.+91 (124) 627 7300 5th Flr.\"",
                "website": "\"https://www.pwc.com/us/en/about-us/pwc-office-locations.html#/office/in-gurg6\""
            },
            {
                "__id": 85,
                "address_country": "\"CV\"",
                "address_full": "\"Edif\u00edcio BAI Center/, Piso 2 Direito, Avenida Cidade de Lisboa/, PO Box 303, Cidade da Praia, Cape Verde\"",
                "address_postcode": "\"\"",
                "latitude": 14.9191684,
                "longitude": -23.5117241,
                "name": "\"Cidade da Praia\"",
                "office": "\"consulting\"",
                "official_name": "\"PricewaterhouseCoopers\"",
                "phone": "\"+238 261 59 34\"",
                "website": "\"https://www.pwc.com/us/en/about-us/pwc-office-locations.html#/office/cv-cida1\""
            },
            {
                "__id": 288,
                "address_country": "\"IN\"",
                "address_full": "\"Office No. 9C/, Sector 21/, Central Business District/, Naya Raipur/, Chhattisgarh/, India\"",
                "address_postcode": "\"\"",
                "latitude": 21.169882,
                "longitude": 81.772551,
                "name": "\"Raipur\"",
                "office": "\"consulting\"",
                "official_name": "\"PricewaterhouseCoopers\"",
                "phone": "\"+91 (771) 431 7700\"",
                "website": "\"https://www.pwc.com/us/en/about-us/pwc-office-locations.html#/office/in-raip\""
            },
            {
                "__id": 32,
                "address_country": "\"BE\"",
                "address_full": "\"Culliganlaan 5, B-1831 Diegem, Belgium\"",
                "address_postcode": "\"\"",
                "latitude": 50.887958,
                "longitude": 4.453523,
                "name": "\"Brussels\"",
                "office": "\"consulting\"",
                "official_name": "\"PricewaterhouseCoopers\"",
                "phone": "\"+32 2 710 42 11\"",
                "website": "\"https://www.pwc.com/us/en/about-us/pwc-office-locations.html#/office/be-brus\""
            },
            {
                "__id": 241,
                "address_country": "\"GR\"",
                "address_full": "\"260 Kifissias Avenue & Kodrou, Halandri GR-152 32, Greece\"",
                "address_postcode": "\"\"",
                "latitude": 38.0183478,
                "longitude": 23.7877879,
                "name": "\"Athens\"",
                "office": "\"consulting\"",
                "official_name": "\"PricewaterhouseCoopers\"",
                "phone": "\"+30 (210) 6874 400\"",
                "website": "\"https://www.pwc.com/us/en/about-us/pwc-office-locations.html#/office/gr-athe1\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "arnold_clark": {
        "__id": 52,
        "id": "arnold_clark",
        "name": "Arnold Clark",
        "columnNames": "['address_city', 'address_postcode', 'address_state', 'image', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'street_address', 'website']",
        "rowLength": 191,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763479,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of arnold_clark locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 186,
                "address_city": "\"Aberdeen\"",
                "address_postcode": "\"AB12 3AR\"",
                "address_state": "\"\"",
                "image": "\"https://ac-branches.s3.amazonaws.com/branches/aberdeen-van-store_49007d7ac7ce01e6088110cb43ce69f9d6f0d211.png\"",
                "latitude": 57.129695,
                "longitude": -2.088781,
                "name": "\"Aberdeen Vanstore\"",
                "opening_hours": "\"Mo-Fr 08:30-18:00; Sa 09:00-13:00; Su 00:00-24:00\"",
                "phone": "\"+44-1224-010600\"",
                "street_address": "\"Craigshaw Road, West Tullos Industrial Estate,\"",
                "website": "\"https://www.arnoldclark.com/find-a-dealer/aberdeen-vanstore/ref/arndwx\""
            },
            {
                "__id": 43,
                "address_city": "\"Stafford\"",
                "address_postcode": "\"ST16 1GX\"",
                "address_state": "\"\"",
                "image": "\"https://ac-branches.s3.amazonaws.com/branches/stafford-motorstore_3155c69d4bdd7da682b663da753505c620f65f82.jpg\"",
                "latitude": 52.83222487049539,
                "longitude": -2.135767936706543,
                "name": "\"Stafford Motorstore\"",
                "opening_hours": "\"Mo-Th 09:00-19:00; Fr 09:30-19:00; Sa 09:00-18:00; Su 11:00-17:00\"",
                "phone": "\"+44-1785-904820\"",
                "street_address": "\"Spitfire Close, Prime Point Business Park\"",
                "website": "\"https://www.arnoldclark.com/find-a-dealer/stafford-motorstore/ref/arnbi\""
            },
            {
                "__id": 74,
                "address_city": "\"Livingston\"",
                "address_postcode": "\"EH54 8PT\"",
                "address_state": "\"Central\"",
                "image": "\"https://ac-branches.s3.amazonaws.com/branches/livingston-vauxhall_2b118c57ab08ea3257e3f2da0e86b7dd39693bc6.jpg\"",
                "latitude": 55.90261468767652,
                "longitude": -3.5443782806396484,
                "name": "\"Livingston Vauxhall\"",
                "opening_hours": "\"Mo-Fr 09:00-19:00; Sa 09:00-18:00; Su 11:00-18:00\"",
                "phone": "\"+44-1506-539500\"",
                "street_address": "\"Carmondean Centre, Deans\"",
                "website": "\"https://www.arnoldclark.com/find-a-dealer/livingston-vauxhall/ref/arnfe\""
            },
            {
                "__id": 78,
                "address_city": "\"Linwood\"",
                "address_postcode": "\"PA1 2BH\"",
                "address_state": "\"Glasgow\"",
                "image": "\"https://ac-branches.s3.amazonaws.com/branches/linwood-vauxhall-jeep-alfa-romeo_75bc5c4daa384c311b86dc221d6b5151a355f778.jpg\"",
                "latitude": 55.845438,
                "longitude": -4.475325,
                "name": "\"Linwood Vauxhall / Jeep / Alfa Romeo\"",
                "opening_hours": "\"Mo-Fr 09:00-19:00; Sa 09:00-18:00; Su 11:00-18:00\"",
                "phone": "\"+44-141-739-5864\"",
                "street_address": "\"Phoenix Retail Park,\"",
                "website": "\"https://www.arnoldclark.com/find-a-dealer/linwood-vauxhall-jeep-alfa-romeo/ref/arnay\""
            },
            {
                "__id": 70,
                "address_city": "\"Dundee\"",
                "address_postcode": "\"DD2 3PT\"",
                "address_state": "\"\"",
                "image": "\"https://ac-branches.s3.amazonaws.com/branches/mercedes-benz-of-dundee_4bd65ca7efefdb87c3ecad1d4db59bc6792d330a.jpg\"",
                "latitude": 56.48129377568141,
                "longitude": -3.0009493231773376,
                "name": "\"Mercedes-Benz of Dundee\"",
                "opening_hours": "\"Mo-Th 08:30-19:00; Fr 08:30-18:00; Sa 09:00-17:00; Su 11:00-17:00\"",
                "phone": "\"+44-1382-918251\"",
                "street_address": "\"Kings Cross Road,\"",
                "website": "\"https://www.arnoldclark.com/find-a-dealer/mercedes-benz-of-dundee/ref/arnycm\""
            },
            {
                "__id": 105,
                "address_city": "\"Glasgow\"",
                "address_postcode": "\"G11 6NA\"",
                "address_state": "\"Glasgow City\"",
                "image": "\"https://ac-branches.s3.amazonaws.com/branches/innovation-centre_13a72803cdcdd37b46dfbd049504f1ecfaa3d16b.jpg\"",
                "latitude": 55.871866,
                "longitude": -4.3269812,
                "name": "\"Innovation Centre\"",
                "opening_hours": "\"Mo-Sa 09:00-18:00; Su 11:00-18:00\"",
                "phone": "\"+44-141-739-8705\"",
                "street_address": "\"701 Dumbarton Road,\"",
                "website": "\"https://www.arnoldclark.com/find-a-dealer/innovation-centre/ref/arnhy\""
            },
            {
                "__id": 189,
                "address_city": "\"Aberdeen\"",
                "address_postcode": "\"AB12 3AR\"",
                "address_state": "\"Aberdeen\"",
                "image": "\"https://ac-branches.s3.amazonaws.com/branches/aberdeen-mazda-ford_111185f2b9885d5fc8861edff91578b2605db70a.jpg\"",
                "latitude": 57.126932,
                "longitude": -2.091647,
                "name": "\"Aberdeen Mazda\"",
                "opening_hours": "\"Mo-Fr 09:00-19:00; Sa 09:00-18:00; Su 11:00-18:00\"",
                "phone": "\"+44-1224-010620\"",
                "street_address": "\"Craigshaw Road, West Tullos Industrial Estate\"",
                "website": "\"https://www.arnoldclark.com/find-a-dealer/aberdeen-mazda/ref/arndw\""
            },
            {
                "__id": 23,
                "address_city": "\"Wishaw\"",
                "address_postcode": "\"ML2 9PS\"",
                "address_state": "\"\"",
                "image": "\"https://ac-branches.s3.amazonaws.com/branches/wishaw-audi-service-centre_b74ee776919b8365823cb4af23c0e555968ffb66.jpg\"",
                "latitude": 55.7642131648377,
                "longitude": -3.864912986755371,
                "name": "\"Wishaw Audi Service Centre\"",
                "opening_hours": "\"\"",
                "phone": "\"+44-1698-358221\"",
                "street_address": "\"Bogside, Newmains\"",
                "website": "\"https://www.arnoldclark.com/find-a-dealer/wishaw-audi-service-centre/ref/audi01\""
            },
            {
                "__id": 9,
                "address_city": "\"Birtley\"",
                "address_postcode": "\"DH3 2SN\"",
                "address_state": "\"\"",
                "image": "\"https://ac-branches.s3.amazonaws.com/branches/birtley-vanstore_fb156de108e995c66e518aa147a8eb78d83db2fd.jpg\"",
                "latitude": 54.901407,
                "longitude": -1.562308,
                "name": "\"Birtley Vanstore\"",
                "opening_hours": "\"Mo-Th 09:00-19:00; Fr 09:00-19:30; Sa 09:00-18:30; Su 11:00-18:00\"",
                "phone": "\"+44-191-823-7810\"",
                "street_address": "\"Portobello Way, Chester le Street\"",
                "website": "\"https://www.arnoldclark.com/find-a-dealer/birtley-vanstore/ref/arnfxc\""
            },
            {
                "__id": 58,
                "address_city": "\"Paisley\"",
                "address_postcode": "\"PA3 4AR\"",
                "address_state": "\"\"",
                "image": "\"https://ac-branches.s3.amazonaws.com/branches/paisley-fiat-abarth_20ffeac02619b6c7af427d9c03783f75fa23e9fa.jpg\"",
                "latitude": 55.851179864250895,
                "longitude": -4.419894218444824,
                "name": "\"Paisley Fiat / Abarth\"",
                "opening_hours": "\"Mo-Fr 09:00-19:00; Sa 09:00-18:00; Su 11:00-18:00\"",
                "phone": "\"+44-141-729-7780\"",
                "street_address": "\"Renfrew Road,\"",
                "website": "\"https://www.arnoldclark.com/find-a-dealer/paisley-fiat-abarth/ref/arneg\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "rue21": {
        "__id": 53,
        "id": "rue21",
        "name": "Rue21",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'shop', 'website']",
        "rowLength": 635,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763480,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of rue21 locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 393,
                "address_city": "\"Wichita\"",
                "address_country": "\"US\"",
                "address_full": "\"7700 E Kellogg Dr\"",
                "address_postcode": "\"67207\"",
                "address_state": "\"KS\"",
                "latitude": 37.6824069,
                "longitude": -97.2464496,
                "name": "\"Towne East Square\"",
                "opening_hours": "\"Mo-Th 11:00-20:00; Fr-Sa 10:00-21:00; Su 12:00-18:00\"",
                "shop": "\"clothes\"",
                "website": "\"https://stores.rue21.com/ks/wichita/7700-e-kellogg-dr.html\""
            },
            {
                "__id": 431,
                "address_city": "\"Los Banos\"",
                "address_country": "\"US\"",
                "address_full": "\"1417 W Pacheco Blvd\"",
                "address_postcode": "\"93635\"",
                "address_state": "\"CA\"",
                "latitude": 37.05627306130235,
                "longitude": -120.87584674358368,
                "name": "\"Stonecreek Plaza\"",
                "opening_hours": "\"Mo-Sa 11:00-19:00; Su 12:00-18:00\"",
                "shop": "\"clothes\"",
                "website": "\"https://stores.rue21.com/ca/los-banos/1417-w-pacheco-blvd.html\""
            },
            {
                "__id": 471,
                "address_city": "\"Lakeland\"",
                "address_country": "\"US\"",
                "address_full": "\"3800 US Hwy 98 N\"",
                "address_postcode": "\"33809\"",
                "address_state": "\"FL\"",
                "latitude": 28.0908205,
                "longitude": -81.978068,
                "name": "\"Lakeland Square\"",
                "opening_hours": "\"Mo-Sa 11:00-20:00; Su 12:00-18:00\"",
                "shop": "\"clothes\"",
                "website": "\"https://stores.rue21.com/fl/lakeland/3800-us-hwy-98-n.html\""
            },
            {
                "__id": 111,
                "address_city": "\"Goldsboro\"",
                "address_country": "\"US\"",
                "address_full": "\"621 A N Berkeley Blvd\"",
                "address_postcode": "\"27534\"",
                "address_state": "\"NC\"",
                "latitude": 35.377134,
                "longitude": -77.9421738,
                "name": "\"Berkeley Mall\"",
                "opening_hours": "\"Mo-Sa 10:00-20:00; Su 01:00-18:00\"",
                "shop": "\"clothes\"",
                "website": "\"https://stores.rue21.com/nc/goldsboro/621-a-n-berkeley-blvd.html\""
            },
            {
                "__id": 188,
                "address_city": "\"Chambersburg\"",
                "address_country": "\"US\"",
                "address_full": "\"901 Norland Ave\"",
                "address_postcode": "\"17201\"",
                "address_state": "\"PA\"",
                "latitude": 39.9422107,
                "longitude": -77.6323898,
                "name": "\"Chambersburg Crossing\"",
                "opening_hours": "\"Mo-Sa 11:00-19:00; Su 12:00-18:00\"",
                "shop": "\"clothes\"",
                "website": "\"https://stores.rue21.com/pa/chambersburg/901-norland-ave.html\""
            },
            {
                "__id": 193,
                "address_city": "\"Rock Hill\"",
                "address_country": "\"US\"",
                "address_full": "\"2301 Dave Lyle Blvd\"",
                "address_postcode": "\"29730\"",
                "address_state": "\"SC\"",
                "latitude": 34.943689956995286,
                "longitude": -80.96504867076874,
                "name": "\"Rock Hill Galleria\"",
                "opening_hours": "\"Mo-Sa 11:00-19:00; Su 12:00-18:00\"",
                "shop": "\"clothes\"",
                "website": "\"https://stores.rue21.com/sc/rock-hill/2301-dave-lyle-blvd.html\""
            },
            {
                "__id": 13,
                "address_city": "\"Lincoln\"",
                "address_country": "\"US\"",
                "address_full": "\"6100 East 'O' Street\"",
                "address_postcode": "\"68505\"",
                "address_state": "\"NE\"",
                "latitude": 40.8148783,
                "longitude": -96.6370845,
                "name": "\"Westfield Gateway Mall\"",
                "opening_hours": "\"Mo-Sa 11:00-20:00; Su 12:00-18:00\"",
                "shop": "\"clothes\"",
                "website": "\"https://stores.rue21.com/ne/lincoln/6100-east--o--street.html\""
            },
            {
                "__id": 214,
                "address_city": "\"Winchester\"",
                "address_country": "\"US\"",
                "address_full": "\"1850 Apple Blossom Drive\"",
                "address_postcode": "\"22601\"",
                "address_state": "\"VA\"",
                "latitude": 39.1607059,
                "longitude": -78.1627243,
                "name": "\"Apple Blossom Mall\"",
                "opening_hours": "\"Mo-Th 10:00-20:00; Fr-Sa 10:00-21:00; Su 11:00-18:00\"",
                "shop": "\"clothes\"",
                "website": "\"https://stores.rue21.com/va/winchester/1850-apple-blossom-drive.html\""
            },
            {
                "__id": 77,
                "address_city": "\"Middletown\"",
                "address_country": "\"US\"",
                "address_full": "\"1 Galleria Dr\"",
                "address_postcode": "\"10941\"",
                "address_state": "\"NY\"",
                "latitude": 41.452936809599706,
                "longitude": -74.36835871346948,
                "name": "\"Crystal Run Galleria\"",
                "opening_hours": "\"Mo-We 11:00-19:00; Th-Sa 11:00-21:00; Su 11:00-18:00\"",
                "shop": "\"clothes\"",
                "website": "\"https://stores.rue21.com/ny/middletown/1-galleria-dr.html\""
            },
            {
                "__id": 366,
                "address_city": "\"Richmond\"",
                "address_country": "\"US\"",
                "address_full": "\"11500 Midlothian Turnpike\"",
                "address_postcode": "\"23235\"",
                "address_state": "\"VA\"",
                "latitude": 37.50941700000002,
                "longitude": -77.60868072883613,
                "name": "\"Chesterfield Towne Center\"",
                "opening_hours": "\"Mo-Th 11:00-19:00; Fr-Sa 11:00-20:00; Su 12:00-18:00\"",
                "shop": "\"clothes\"",
                "website": "\"https://stores.rue21.com/va/richmond/11500-midlothian-turnpike.html\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "red_robin": {
        "__id": 54,
        "id": "red_robin",
        "name": "Red Robin",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'official_name', 'opening_hours', 'phone', 'website']",
        "rowLength": 514,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763481,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of red_robin locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 365,
                "address_city": "\"Battle Ground\"",
                "address_country": "\"US\"",
                "address_full": "\"1112 SW Scotton Way\"",
                "address_postcode": "\"98604\"",
                "address_state": "\"WA\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"burger\"",
                "latitude": 45.7705866,
                "longitude": -122.5490661,
                "name": "\"Battleground\"",
                "official_name": "\"Red Robin Gourmet Burgers and Brews\"",
                "opening_hours": "\"Mo-Th 11:00-22:00; Fr-Sa 11:00-23:00; Su 11:00-22:00\"",
                "phone": "\"+13606675949\"",
                "website": "\"https://locations.redrobin.com/wa/battle-ground/728/\""
            },
            {
                "__id": 355,
                "address_city": "\"Everett\"",
                "address_country": "\"US\"",
                "address_full": "\"1305 SE Everett Mall Way\"",
                "address_postcode": "\"98208\"",
                "address_state": "\"WA\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"burger\"",
                "latitude": 47.9121053,
                "longitude": -122.2154045,
                "name": "\"Everett\"",
                "official_name": "\"Red Robin Gourmet Burgers and Brews\"",
                "opening_hours": "\"Mo-Th 11:00-22:00; Fr-Sa 11:00-23:00; Su 11:00-22:00\"",
                "phone": "\"+14253557330\"",
                "website": "\"https://locations.redrobin.com/wa/everett/44/\""
            },
            {
                "__id": 390,
                "address_city": "\"Omaha\"",
                "address_country": "\"US\"",
                "address_full": "\"2627 S 180th St\"",
                "address_postcode": "\"68130\"",
                "address_state": "\"NE\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"burger\"",
                "latitude": 41.2346571,
                "longitude": -96.1954222,
                "name": "\"Western Springs\"",
                "official_name": "\"Red Robin Gourmet Burgers and Brews\"",
                "opening_hours": "\"Mo-Th 11:00-22:00; Fr-Sa 11:00-23:00; Su 11:00-22:00\"",
                "phone": "\"+14023300600\"",
                "website": "\"https://locations.redrobin.com/ne/omaha/91/\""
            },
            {
                "__id": 230,
                "address_city": "\"Clackamas\"",
                "address_country": "\"US\"",
                "address_full": "\"12560 S.E. 93Rd Street\"",
                "address_postcode": "\"97015\"",
                "address_state": "\"OR\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"burger\"",
                "latitude": 45.4327596,
                "longitude": -122.5682608,
                "name": "\"Clackamas\"",
                "official_name": "\"Red Robin Gourmet Burgers and Brews\"",
                "opening_hours": "\"Mo-Th 11:00-22:00; Fr-Sa 11:00-23:00; Su 11:00-22:00\"",
                "phone": "\"+15036591048\"",
                "website": "\"https://locations.redrobin.com/or/clackamas/43/\""
            },
            {
                "__id": 375,
                "address_city": "\"Missoula\"",
                "address_country": "\"US\"",
                "address_full": "\"2901 Brooks St\"",
                "address_postcode": "\"59801\"",
                "address_state": "\"MT\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"burger\"",
                "latitude": 46.8450655,
                "longitude": -114.0285964,
                "name": "\"Missoula\"",
                "official_name": "\"Red Robin Gourmet Burgers and Brews\"",
                "opening_hours": "\"Mo-Sa 11:00-20:45; Su 11:00-19:45\"",
                "phone": "\"+14068303170\"",
                "website": "\"https://locations.redrobin.com/mt/missoula/691/\""
            },
            {
                "__id": 14,
                "address_city": "\"Westminster\"",
                "address_country": "\"US\"",
                "address_full": "\"799 W 146th Ave\"",
                "address_postcode": "\"80020\"",
                "address_state": "\"CO\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"burger\"",
                "latitude": 39.9615103,
                "longitude": -104.9959791,
                "name": "\"Orchard Town Center\"",
                "official_name": "\"Red Robin Gourmet Burgers and Brews\"",
                "opening_hours": "\"Mo-Th 11:00-22:00; Fr-Sa 11:00-23:00; Su 11:00-22:00\"",
                "phone": "\"+13034500301\"",
                "website": "\"https://locations.redrobin.com/co/westminster/587/\""
            },
            {
                "__id": 401,
                "address_city": "\"Mays Landing\"",
                "address_country": "\"US\"",
                "address_full": "\"4259 Black Horse Pike\"",
                "address_postcode": "\"08330\"",
                "address_state": "\"NJ\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"burger\"",
                "latitude": 39.4463655,
                "longitude": -74.6296584,
                "name": "\"Mays Landing\"",
                "official_name": "\"Red Robin Gourmet Burgers and Brews\"",
                "opening_hours": "\"Mo-Th 11:00-22:00; Fr-Sa 11:00-23:00; Su 11:00-22:00\"",
                "phone": "\"+16094850050\"",
                "website": "\"https://locations.redrobin.com/nj/mays-landing/654/\""
            },
            {
                "__id": 485,
                "address_city": "\"Holyoke\"",
                "address_country": "\"US\"",
                "address_full": "\"27 Holyoke Street\"",
                "address_postcode": "\"01040\"",
                "address_state": "\"MA\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"burger\"",
                "latitude": 42.1737537,
                "longitude": -72.64184449999999,
                "name": "\"Holyoke\"",
                "official_name": "\"Red Robin Gourmet Burgers and Brews\"",
                "opening_hours": "\"Mo-Su 11:00-21:00\"",
                "phone": "\"+14133154350\"",
                "website": "\"https://locations.redrobin.com/ma/holyoke/178/\""
            },
            {
                "__id": 118,
                "address_city": "\"Middletown\"",
                "address_country": "\"US\"",
                "address_full": "\"140 Commerce Drive\"",
                "address_postcode": "\"19709\"",
                "address_state": "\"DE\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"burger\"",
                "latitude": 39.4455175,
                "longitude": -75.742373,
                "name": "\"Middletown\"",
                "official_name": "\"Red Robin Gourmet Burgers and Brews\"",
                "opening_hours": "\"Mo-Th 11:00-21:00; Fr-Sa 11:00-22:00; Su 11:00-21:00\"",
                "phone": "\"+13023149109\"",
                "website": "\"https://locations.redrobin.com/de/middletown/751/\""
            },
            {
                "__id": 408,
                "address_city": "\"Clifton\"",
                "address_country": "\"US\"",
                "address_full": "\"265 NJ 3 E\"",
                "address_postcode": "\"07014\"",
                "address_state": "\"NJ\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"burger\"",
                "latitude": 40.8270201,
                "longitude": -74.1343644,
                "name": "\"Clifton\"",
                "official_name": "\"Red Robin Gourmet Burgers and Brews\"",
                "opening_hours": "\"Mo-Th 11:00-22:00; Fr-Sa 11:00-23:00; Su 11:00-22:00\"",
                "phone": "\"+19734709222\"",
                "website": "\"https://locations.redrobin.com/nj/clifton/370/\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "marks_and_spencer": {
        "__id": 55,
        "id": "marks_and_spencer",
        "name": "Marks & Spencer",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'website']",
        "rowLength": 1091,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763481,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of marks_and_spencer locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 597,
                "address_city": "\"Horsham\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"RH12 1YX\"",
                "latitude": 51.06373,
                "longitude": -0.32912,
                "name": "\"HORSHAM\"",
                "opening_hours": "\"Mo-Sa 08:30-19:00; Su 10:30-16:30\"",
                "phone": "\"01403 259355\"",
                "shop": "\"department_store\"",
                "street_address": "\"2-3 Swan Walk\"",
                "website": "\"https://www.marksandspencer.com/stores/horsham-3298\""
            },
            {
                "__id": 512,
                "address_city": "\"Chippenham\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"SN14 0FJ\"",
                "latitude": 51.447393,
                "longitude": -2.147246,
                "name": "\"CHIPPENHAM FOODHALL\"",
                "opening_hours": "\"Mo-Fr 08:00-20:00; Sa 07:00-20:00; Su 10:00-16:00\"",
                "phone": "\"01249 474658\"",
                "shop": "\"department_store\"",
                "street_address": "\"Bath Road\"",
                "website": "\"https://www.marksandspencer.com/stores/chippenham-foodhall-2104\""
            },
            {
                "__id": 897,
                "address_city": "\"50 Longfleet Road, Poole\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"BH15 2JB\"",
                "latitude": 50.722018,
                "longitude": -1.972767,
                "name": "\"POOLE HOSPITAL WHS TRAVEL\"",
                "opening_hours": "\"Mo-Fr 07:00-20:00; Sa 09:00-17:00; Su 10:00-16:00\"",
                "phone": "\"\"",
                "shop": "\"department_store\"",
                "street_address": "\"WHSmith, Poole Hospital\"",
                "website": "\"https://www.marksandspencer.com/stores/poole-hospital-whs-travel-1001\""
            },
            {
                "__id": 321,
                "address_city": "\"INVERURIE\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"AB51 3RN\"",
                "latitude": 57.267938,
                "longitude": -2.369981,
                "name": "\"STEVENSONS BP\"",
                "opening_hours": "\"Mo-Su 06:00-22:00\"",
                "phone": "\"\"",
                "shop": "\"department_store\"",
                "street_address": "\"ELPHINSTONE ROAD\"",
                "website": "\"https://www.marksandspencer.com/stores/stevensons-bp-928\""
            },
            {
                "__id": 850,
                "address_city": "\"BRIDGWATER\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"TA6 4AB\"",
                "latitude": 51.132828,
                "longitude": -2.999403,
                "name": "\"BRIDGWATER SF\"",
                "opening_hours": "\"Mo-Sa 08:00-20:00; Su 10:00-16:00\"",
                "phone": "\"01278 557971\"",
                "shop": "\"department_store\"",
                "street_address": "\"UNIT 2C BRIDGWATER RETAIL PARK\"",
                "website": "\"https://www.marksandspencer.com/stores/bridgwater-sf-784\""
            },
            {
                "__id": 827,
                "address_city": "\"West Thurrock Way Grays\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"RM20 2ZQ\"",
                "latitude": 51.4875,
                "longitude": 0.28253,
                "name": "\"THURROCK\"",
                "opening_hours": "\"Mo-Fr 08:30-21:00; Sa 08:00-21:00; Su 11:00-17:00\"",
                "phone": "\"01708 860099\"",
                "shop": "\"department_store\"",
                "street_address": "\"Lakeside Thurrock Centre\"",
                "website": "\"https://www.marksandspencer.com/stores/thurrock-576\""
            },
            {
                "__id": 1042,
                "address_city": "\"Maritime Way Chatham\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"ME4 3ED\"",
                "latitude": 51.401199,
                "longitude": 0.534864,
                "name": "\"CHATHAM OUTLET\"",
                "opening_hours": "\"Mo-Sa 09:00-18:00; Su 11:00-17:00\"",
                "phone": "\"01634 891 258\"",
                "shop": "\"department_store\"",
                "street_address": "\"Unit 1 Dockside Factory Outlet\"",
                "website": "\"https://www.marksandspencer.com/stores/chatham-outlet-8387\""
            },
            {
                "__id": 687,
                "address_city": "\"London\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"EC4M 9AF\"",
                "latitude": 51.513588,
                "longitude": -0.095297,
                "name": "\"NEW CHANGE SIMPLY FOOD\"",
                "opening_hours": "\"Mo-Fr 07:00-21:00; Sa 09:00-19:00; Su 12:00-18:00\"",
                "phone": "\"0207 248 2737\"",
                "shop": "\"department_store\"",
                "street_address": "\"3A One New Change\"",
                "website": "\"https://www.marksandspencer.com/stores/new-change-simply-food-247\""
            },
            {
                "__id": 7,
                "address_city": "\"NORTHAMPTON\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"NN2 8DW\"",
                "latitude": 52.26594,
                "longitude": -0.89988,
                "name": "\"HARBOROUGH BP\"",
                "opening_hours": "\"Mo-Su 05:00-22:00\"",
                "phone": "\"\"",
                "shop": "\"department_store\"",
                "street_address": "\"HARBOROUGH RD\"",
                "website": "\"https://www.marksandspencer.com/stores/harborough-bp-1219\""
            },
            {
                "__id": 27,
                "address_city": "\"STIRLING\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"FK7 8EU\"",
                "latitude": 56.07576,
                "longitude": -3.922502,
                "name": "\"STIRLING M9 MOTO SIMPLY FOOD\"",
                "opening_hours": "\"Mo-Su 07:00-20:00\"",
                "phone": "\"\"",
                "shop": "\"department_store\"",
                "street_address": "\"M9 JUNCTION 9 NEAR STIRLING\"",
                "website": "\"https://www.marksandspencer.com/stores/stirling-m9-moto-simply-food-1948\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "banco_del_bienestar_mx": {
        "__id": 56,
        "id": "banco_del_bienestar_mx",
        "name": "Banco del Bienestar Mexico",
        "columnNames": "['address_city', 'address_country', 'address_full', 'amenity', 'latitude', 'longitude', 'name', 'phone']",
        "rowLength": 535,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763482,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of banco_del_bienestar_mx locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 23,
                "address_city": "\"CUAUHTEMOC\"",
                "address_country": "\"MX\"",
                "address_full": "\"Eje. Lazaro Cardenas esq. Tacuba s/n\"",
                "amenity": "\"bank\"",
                "latitude": 19.435497,
                "longitude": -99.14068,
                "name": "\"SPM. PALACIO POSTAL\"",
                "phone": "\"55128266;55128318\""
            },
            {
                "__id": 137,
                "address_city": "\"SAN JUAN DEL RIO\"",
                "address_country": "\"MX\"",
                "address_full": "\"Boulevard Hidalgo No. 26 y 28, Local 1, entre Ju\u00e1rez y Allende\"",
                "amenity": "\"bank\"",
                "latitude": 20.38492,
                "longitude": -99.997547,
                "name": "\"SAN JUAN DEL RIO\"",
                "phone": "\"2747416;2726573;2748153\""
            },
            {
                "__id": 478,
                "address_city": "\"OLINAL\u00c1\"",
                "address_country": "\"MX\"",
                "address_full": "\"Ignacio Zaragoza S/N\"",
                "amenity": "\"bank\"",
                "latitude": 0.0,
                "longitude": 0.0,
                "name": "\"\"",
                "phone": "\"\""
            },
            {
                "__id": 486,
                "address_city": "\"HUICHAPAN\"",
                "address_country": "\"MX\"",
                "address_full": "\"Av. Pedro Mar\u00eda Anaya # 3\"",
                "amenity": "\"bank\"",
                "latitude": 0.0,
                "longitude": 0.0,
                "name": "\"\"",
                "phone": "\"\""
            },
            {
                "__id": 170,
                "address_city": "\"GUADALAJARA\"",
                "address_country": "\"MX\"",
                "address_full": "\"Calle Pedro Antonio Buzeta No. 579 Col. Santa Teresita C.P 44600 Guadalajara, Jalisco\"",
                "amenity": "\"bank\"",
                "latitude": 20.683085,
                "longitude": -103.366163,
                "name": "\"SANTA TERESITA\"",
                "phone": "\"8250383;8250349\""
            },
            {
                "__id": 250,
                "address_city": "\"MANZANILLA DE LA PAZ, LA\"",
                "address_country": "\"MX\"",
                "address_full": "\"Zaragoza 402\"",
                "amenity": "\"bank\"",
                "latitude": 20.00427425158659,
                "longitude": -103.155807021822,
                "name": "\"LA MANZANILLA\"",
                "phone": "\"4350066;4350440\""
            },
            {
                "__id": 344,
                "address_city": "\"IXTLAHUACA\"",
                "address_country": "\"MX\"",
                "address_full": "\"Nicol\u00e1s Bravo Ote # 309 entre Ignacio Allende y Juan Alvarez\"",
                "amenity": "\"bank\"",
                "latitude": 19.567587,
                "longitude": -99.76714,
                "name": "\"IXTLAHUACA\"",
                "phone": "\"2836225;2833465\""
            },
            {
                "__id": 263,
                "address_city": "\"HOCTUN\"",
                "address_country": "\"MX\"",
                "address_full": "\"Calle 25 No. 102 entre 20 y 22\"",
                "amenity": "\"bank\"",
                "latitude": 20.864681,
                "longitude": -89.20112,
                "name": "\"HOCTUN\"",
                "phone": "\"9510039;9510337\""
            },
            {
                "__id": 511,
                "address_city": "\"GUADALUPE Y CALVO\"",
                "address_country": "\"MX\"",
                "address_full": "\"Plaza Donato Guerra S/N\"",
                "amenity": "\"bank\"",
                "latitude": 0.0,
                "longitude": 0.0,
                "name": "\"\"",
                "phone": "\"\""
            },
            {
                "__id": 176,
                "address_city": "\"OAXACA DE JUAREZ\"",
                "address_country": "\"MX\"",
                "address_full": "\"Calle Valerio Trujano # 704-A, entre Victoria y Perif\u00e9rico\"",
                "amenity": "\"bank\"",
                "latitude": 17.060432,
                "longitude": -96.7333,
                "name": "\"OAXACA PERIFERICO\"",
                "phone": "\"5144601;5011222\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "applebees": {
        "__id": 57,
        "id": "applebees",
        "name": "Applebee's",
        "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'official_name', 'opening_hours', 'phone', 'website']",
        "rowLength": 1568,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763483,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of applebees locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 379,
                "address_city": "\"Schenectady\"",
                "address_full": "\"2400 Cambridge Road \"",
                "address_postcode": "\"12304\"",
                "address_state": "\"NY\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american\"",
                "latitude": 42.783243,
                "longitude": -73.915297,
                "name": "\"Applebee's\"",
                "official_name": "\"Applebee's Neighborhood Grill & Bar\"",
                "opening_hours": "\"Mo-Su 11:00-00:00,11:00-00:00\"",
                "phone": "\"(518) 631-2658\"",
                "website": "\"https://restaurants.applebees.com/en-us/ny/schenectady/2400-cambridge-road-94041\""
            },
            {
                "__id": 973,
                "address_city": "\"Rice Lake\"",
                "address_full": "\"2901 Decker Dr. \"",
                "address_postcode": "\"54868\"",
                "address_state": "\"WI\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american\"",
                "latitude": 45.472493,
                "longitude": -91.753978,
                "name": "\"Applebee's\"",
                "official_name": "\"Applebee's Neighborhood Grill & Bar\"",
                "opening_hours": "\"Mo-Su 11:00-23:00,11:00-23:00\"",
                "phone": "\"(715) 861-1501\"",
                "website": "\"https://restaurants.applebees.com/en-us/wi/rice-lake/2901-decker-dr.-82062\""
            },
            {
                "__id": 267,
                "address_city": "\"Papillion\"",
                "address_full": "\"1202 N. Washington \"",
                "address_postcode": "\"68046\"",
                "address_state": "\"NE\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american\"",
                "latitude": 41.173151,
                "longitude": -96.043811,
                "name": "\"Applebee's\"",
                "official_name": "\"Applebee's Neighborhood Grill & Bar\"",
                "opening_hours": "\"Mo-Su 11:00-23:00,11:00-23:00\"",
                "phone": "\"(402) 339-4359\"",
                "website": "\"https://restaurants.applebees.com/en-us/ne/papillion/1202-n.-washington-92087\""
            },
            {
                "__id": 813,
                "address_city": "\"El Paso\"",
                "address_full": "\"5800 North Mesa \"",
                "address_postcode": "\"79912\"",
                "address_state": "\"TX\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american\"",
                "latitude": 31.825207,
                "longitude": -106.522832,
                "name": "\"Applebee's\"",
                "official_name": "\"Applebee's Neighborhood Grill & Bar\"",
                "opening_hours": "\"Mo-Su 11:00-00:00,11:00-00:00\"",
                "phone": "\"(915) 833-8899\"",
                "website": "\"https://restaurants.applebees.com/en-us/tx/el-paso/5800-north-mesa-88085\""
            },
            {
                "__id": 1140,
                "address_city": "\"LaGrange\"",
                "address_full": "\"1205 Market Street \"",
                "address_postcode": "\"40031\"",
                "address_state": "\"KY\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american\"",
                "latitude": 38.394971,
                "longitude": -85.367751,
                "name": "\"Applebee's\"",
                "official_name": "\"Applebee's Neighborhood Grill & Bar\"",
                "opening_hours": "\"Mo-Su 11:00-23:00,11:00-23:00\"",
                "phone": "\"(502) 265-4117\"",
                "website": "\"https://restaurants.applebees.com/en-us/ky/lagrange/1205-market-street-75057\""
            },
            {
                "__id": 1199,
                "address_city": "\"Port Saint Lucie\"",
                "address_full": "\"10501 South U.S. Highway 1 \"",
                "address_postcode": "\"34952\"",
                "address_state": "\"FL\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american\"",
                "latitude": 27.272519,
                "longitude": -80.290011,
                "name": "\"Applebee's\"",
                "official_name": "\"Applebee's Neighborhood Grill & Bar\"",
                "opening_hours": "\"Mo-Su 11:00-00:00,11:00-00:00\"",
                "phone": "\"(772) 337-0408\"",
                "website": "\"https://restaurants.applebees.com/en-us/fl/port-saint-lucie/10501-south-u.s.-highway-1-85060\""
            },
            {
                "__id": 965,
                "address_city": "\"Charles Town\"",
                "address_full": "\"129 Jefferson Crossing Way \"",
                "address_postcode": "\"25414\"",
                "address_state": "\"WV\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american\"",
                "latitude": 39.300298,
                "longitude": -77.844236,
                "name": "\"Applebee's\"",
                "official_name": "\"Applebee's Neighborhood Grill & Bar\"",
                "opening_hours": "\"Mo-Su 11:00-00:00,11:00-00:00\"",
                "phone": "\"(304) 728-8866\"",
                "website": "\"https://restaurants.applebees.com/en-us/wv/charles-town/129-jefferson-crossing-way-79091\""
            },
            {
                "__id": 163,
                "address_city": "\"Oak Park Heights\"",
                "address_full": "\"13625 60th Street North \"",
                "address_postcode": "\"55082\"",
                "address_state": "\"MN\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american\"",
                "latitude": 45.035373,
                "longitude": -92.830215,
                "name": "\"Applebee's\"",
                "official_name": "\"Applebee's Neighborhood Grill & Bar\"",
                "opening_hours": "\"Mo-Su 11:00-00:00,11:00-00:00\"",
                "phone": "\"(651) 439-8116\"",
                "website": "\"https://restaurants.applebees.com/en-us/mn/oak-park-heights/13625-60th-street-north-82019\""
            },
            {
                "__id": 1102,
                "address_city": "\"Liberal\"",
                "address_full": "\"2611 N. Kansas Avenue \"",
                "address_postcode": "\"67901\"",
                "address_state": "\"KS\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american\"",
                "latitude": 37.069528,
                "longitude": -100.921406,
                "name": "\"Applebee's\"",
                "official_name": "\"Applebee's Neighborhood Grill & Bar\"",
                "opening_hours": "\"Mo-Su 11:00-23:00,11:00-23:00\"",
                "phone": "\"(620) 624-2422\"",
                "website": "\"https://restaurants.applebees.com/en-us/ks/liberal/2611-n.-kansas-avenue-99037\""
            },
            {
                "__id": 1370,
                "address_city": "\"Bloomington\"",
                "address_full": "\"502 N Veterans Parkway \"",
                "address_postcode": "\"61701\"",
                "address_state": "\"IL\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american\"",
                "latitude": 40.484476,
                "longitude": -88.953447,
                "name": "\"Applebee's\"",
                "official_name": "\"Applebee's Neighborhood Grill & Bar\"",
                "opening_hours": "\"Mo-Su 11:00-23:00,11:00-23:00\"",
                "phone": "\"(309) 663-2134\"",
                "website": "\"https://restaurants.applebees.com/en-us/il/bloomington/502-n-veterans-parkway-89031\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "wellstar": {
        "__id": 58,
        "id": "wellstar",
        "name": "WellStar",
        "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone']",
        "rowLength": 249,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763484,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of wellstar locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 42,
                "address_city": "\"LaGrange\"",
                "address_full": "\"1527 Vernon Road\"",
                "address_postcode": "\"30240\"",
                "address_state": "\"GA\"",
                "latitude": 33.0297002,
                "longitude": -85.0609106,
                "name": "\" LaGrange Pediatrics\"",
                "opening_hours": "\"\"",
                "phone": "\"(706) 883-6363\""
            },
            {
                "__id": 169,
                "address_city": "\"Atlanta\"",
                "address_full": "\"315 Boulevard Drive NE Suite 400B\"",
                "address_postcode": "\"30312\"",
                "address_state": "\"GA\"",
                "latitude": 33.7535731,
                "longitude": -84.3502848,
                "name": "\" Southeast Kidney Associates\"",
                "opening_hours": "\"\"",
                "phone": "\"(404) 761-0819\""
            },
            {
                "__id": 105,
                "address_city": "\"Powder Springs\"",
                "address_full": "\"3460 Powder Springs Road\"",
                "address_postcode": "\"30127\"",
                "address_state": "\"GA\"",
                "latitude": 33.8610504,
                "longitude": -84.654837,
                "name": "\"Wellstar Hospitalist Group Medicine\"",
                "opening_hours": "\"\"",
                "phone": "\"(770) 439-9199\""
            },
            {
                "__id": 181,
                "address_city": "\"LaGrange\"",
                "address_full": "\"100 Glenn Bass Road\"",
                "address_postcode": "\"30240\"",
                "address_state": "\"GA\"",
                "latitude": 32.9970475,
                "longitude": -85.04963579999999,
                "name": "\"Wellstar West Georgia Worx\"",
                "opening_hours": "\"Mo-Fr 08:00-17:00\"",
                "phone": "\"(706) 845-3075\""
            },
            {
                "__id": 63,
                "address_city": "\"Newnan\"",
                "address_full": "\"1625 Highway 34 E Suite A\"",
                "address_postcode": "\"30265\"",
                "address_state": "\"GA\"",
                "latitude": 33.3992482,
                "longitude": -84.7254749,
                "name": "\" Skin Cancer Specialists, PC\"",
                "opening_hours": "\"\"",
                "phone": "\"(770) 502-0202\""
            },
            {
                "__id": 66,
                "address_city": "\"Villa Rica\"",
                "address_full": "\"690 Dallas Highway Suite 101\"",
                "address_postcode": "\"30180\"",
                "address_state": "\"GA\"",
                "latitude": 33.7445164,
                "longitude": -84.91691829999999,
                "name": "\" Center for Allergy & Asthma WG\"",
                "opening_hours": "\"\"",
                "phone": "\"(770) 459-0620\""
            },
            {
                "__id": 147,
                "address_city": "\"Smyrna\"",
                "address_full": "\"4581 South Cobb Drive SE Suite 100\"",
                "address_postcode": "\"30080\"",
                "address_state": "\"GA\"",
                "latitude": 33.8439108,
                "longitude": -84.4985391,
                "name": "\"Wellstar Pediatric Cardiology\"",
                "opening_hours": "\"Mo-Fr 08:00-16:00\"",
                "phone": "\"(470) 956-9166\""
            },
            {
                "__id": 227,
                "address_city": "\"Griffin\"",
                "address_full": "\"14557 Highway 19 Suite A\"",
                "address_postcode": "\"30224\"",
                "address_state": "\"GA\"",
                "latitude": 33.1821794,
                "longitude": -84.3009031,
                "name": "\" Coker Pediatrics, LLC\"",
                "opening_hours": "\"\"",
                "phone": "\"(678) 688-1580\""
            },
            {
                "__id": 161,
                "address_city": "\"Holly Springs\"",
                "address_full": "\"1120 Wellstar Way Suite 302\"",
                "address_postcode": "\"30114\"",
                "address_state": "\"GA\"",
                "latitude": 34.1433103,
                "longitude": -84.5199671,
                "name": "\"Wellstar Summit Surgical\"",
                "opening_hours": "\"\"",
                "phone": "\"(770) 423-0395\""
            },
            {
                "__id": 189,
                "address_city": "\"Griffin\"",
                "address_full": "\"601 South 8th Street\"",
                "address_postcode": "\"30224\"",
                "address_state": "\"GA\"",
                "latitude": 33.2393201,
                "longitude": -84.2674674,
                "name": "\" OBHG Georgia, PC\"",
                "opening_hours": "\"\"",
                "phone": "\"(864) 908-3530\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "royallepage": {
        "__id": 59,
        "id": "royallepage",
        "name": "Royal LePage",
        "columnNames": "['address_country', 'address_full', 'latitude', 'longitude', 'name', 'office', 'phone', 'website']",
        "rowLength": 605,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763485,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of royallepage locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 568,
                "address_country": "\"CA\"",
                "address_full": "\"2-47 King STREET WEST, Forest, ON, N0N 1J0\"",
                "latitude": 43.0966256,
                "longitude": -82.0020948,
                "name": "\"Royal LePage Key Realty, Brokerage\"",
                "office": "\"estate_agent\"",
                "phone": "\"519.336.7560\"",
                "website": "\"https://www.royallepage.ca/en/office/ontario/forest/royal-lepage-key-realty/4128/\""
            },
            {
                "__id": 95,
                "address_country": "\"CA\"",
                "address_full": "\"9604 96 STREET, PEACE RIVER, AB, T8S 1J2\"",
                "latitude": 56.2369649,
                "longitude": -117.2979339,
                "name": "\"Royal LePage Valley Realty\"",
                "office": "\"estate_agent\"",
                "phone": "\"780.624.1427\"",
                "website": "\"https://www.royallepage.ca/en/office/alberta/peace-river/royal-lepage-valley-realty/7023/\""
            },
            {
                "__id": 243,
                "address_country": "\"CA\"",
                "address_full": "\"4701 50TH STREET BOX 1974, Stettler, AB, T0C 2L0\"",
                "latitude": 52.3215074,
                "longitude": -112.7044999,
                "name": "\"Royal LePage Central\"",
                "office": "\"estate_agent\"",
                "phone": "\"403.742.3344\"",
                "website": "\"https://www.royallepage.ca/en/office/alberta/stettler/royal-lepage-central/264/\""
            },
            {
                "__id": 150,
                "address_country": "\"CA\"",
                "address_full": "\"E-152 NORTH FOORD STREET, STELLARTON, NS, B0K 1S0\"",
                "latitude": 45.5678,
                "longitude": -62.65977,
                "name": "\"Royal LePage Atlantic\"",
                "office": "\"estate_agent\"",
                "phone": "\"\"",
                "website": "\"https://www.royallepage.ca/en/office/nova-scotia/stellarton/royal-lepage-atlantic/10552/\""
            },
            {
                "__id": 118,
                "address_country": "\"CA\"",
                "address_full": "\"122 MAIN STREET, Erin, ON, N0B 1T0\"",
                "latitude": 43.76991,
                "longitude": -80.0636641,
                "name": "\"Royal LePage Meadowtowne Realty, Brokerage\"",
                "office": "\"estate_agent\"",
                "phone": "\"519.833.9714\"",
                "website": "\"https://www.royallepage.ca/en/office/ontario/erin/royal-lepage-meadowtowne-realty/5681/\""
            },
            {
                "__id": 178,
                "address_country": "\"CA\"",
                "address_full": "\"201-8 SAMPSON MEWS, TORONTO, ON, M3C 0H5\"",
                "latitude": 43.7350564,
                "longitude": -79.3441293,
                "name": "\"Royal LePage Signature Walker Realty, Brokerage\"",
                "office": "\"estate_agent\"",
                "phone": "\"416.443.0300\"",
                "website": "\"https://www.royallepage.ca/en/office/ontario/toronto/royal-lepage-signature-walker-realty/7409/\""
            },
            {
                "__id": 397,
                "address_country": "\"CA\"",
                "address_full": "\"19431 Opeongo Line, Barry's Bay, ON, K0J 1B0\"",
                "latitude": 45.4878118,
                "longitude": -77.67245,
                "name": "\"Royal LePage Team Realty, Brokerage\"",
                "office": "\"estate_agent\"",
                "phone": "\"613.633.3226\"",
                "website": "\"https://www.royallepage.ca/en/office/ontario/barrys-bay/royal-lepage-team-realty/10497/\""
            },
            {
                "__id": 404,
                "address_country": "\"CA\"",
                "address_full": "\"11-1 SPARKS AVENUE, Toronto, ON, M2H 2W1\"",
                "latitude": 43.8067651,
                "longitude": -79.3446635,
                "name": "\"Royal LePage Terrequity Realty, Brokerage\"",
                "office": "\"estate_agent\"",
                "phone": "\"416.496.9220\"",
                "website": "\"https://www.royallepage.ca/en/office/ontario/toronto/royal-lepage-terrequity-realty/10424/\""
            },
            {
                "__id": 349,
                "address_country": "\"CA\"",
                "address_full": "\"5107 50 Street Box 368, Rocky Mountain House, AB, T4T 1A3\"",
                "latitude": 52.3763256,
                "longitude": -114.91885,
                "name": "\"Royal LePage Tamarack Trail Realty\"",
                "office": "\"estate_agent\"",
                "phone": "\"403.845.5588\"",
                "website": "\"https://www.royallepage.ca/en/office/alberta/rocky-mountain-house/royal-lepage-tamarack-trail-realty/911/\""
            },
            {
                "__id": 512,
                "address_country": "\"CA\"",
                "address_full": "\"2347 LAKESHORE RD W., OAKVILLE, ON, L6L 1H4\"",
                "latitude": 43.3973159,
                "longitude": -79.7089177,
                "name": "\"Royal LePage Realty Plus Oakville, Brokerage\"",
                "office": "\"estate_agent\"",
                "phone": "\"905.825.7777\"",
                "website": "\"https://www.royallepage.ca/en/office/ontario/oakville/royal-lepage-realty-plus-oakville/5728/\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "vets4pets_gb": {
        "__id": 60,
        "id": "vets4pets_gb",
        "name": "Vets4Pets Great Britain",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'amenity', 'contact_email', 'image', 'name', 'opening_hours', 'phone', 'street_address', 'website', 'latitude', 'longitude']",
        "rowLength": 399,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763486,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of vets4pets_gb locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 190,
                "address_city": "\"Luton\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"LU1 3JH\"",
                "address_state": "\"Bedfordshire\"",
                "amenity": "\"veterinary\"",
                "contact_email": "\"lutongipsylane@vets4pets.com\"",
                "image": "\"https://www.vets4pets.com/SysSiteAssets/website/placeholders/listing680.jpg?w=585&scale=down\"",
                "name": "51.87313470211557",
                "opening_hours": "-0.3985845102355054",
                "phone": "\"Luton Gipsy Lane Vets4Pets\"",
                "street_address": "\"\"",
                "website": "\"01582 721 121\"",
                "latitude": 0.0,
                "longitude": 0.0
            },
            {
                "__id": 318,
                "address_city": "\"Aberdeen\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"AB23 8JW\"",
                "address_state": "\"\"",
                "amenity": "\"veterinary\"",
                "contact_email": "\"aberdeenbridgeofdon@vets4pets.com\"",
                "image": "\"https://www.vets4pets.com/SysSiteAssets/website/placeholders/listing680.jpg?w=585&scale=down\"",
                "name": "\"Aberdeen Bridge of Don Vets4Pets\"",
                "opening_hours": "\"\"",
                "phone": "\"01224 706258\"",
                "street_address": "\"Inside Pets at Home ,Bridge of Don Retail Park ,Denmore Road\"",
                "website": "\"https://www.vets4pets.com/practices/vets-in-aberdeenshire/vets4pets-aberdeen-bridge-of-don/\"",
                "latitude": 0.0,
                "longitude": 0.0
            },
            {
                "__id": 310,
                "address_city": "\"Borehamwood\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"WD6 4PR\"",
                "address_state": "\"Hertfordshire\"",
                "amenity": "\"veterinary\"",
                "contact_email": "\"borehamwood@vets4pets.com\"",
                "image": "\"https://www.vets4pets.com/SysSiteAssets/website/placeholders/listing680.jpg?w=585&scale=down\"",
                "name": "\"Borehamwood Vets4Pets\"",
                "opening_hours": "\"\"",
                "phone": "\"020 8236 3930\"",
                "street_address": "\"Inside Pets at Home ,Borehamwood Shopping Centre ,25 Theobald Street\"",
                "website": "\"https://www.vets4pets.com/practices/vets4pets-borehamwood/\"",
                "latitude": 0.0,
                "longitude": 0.0
            },
            {
                "__id": 71,
                "address_city": "\"Dundee\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"DD4 8JT\"",
                "address_state": "\"\"",
                "amenity": "\"veterinary\"",
                "contact_email": "\"dundee@vets4pets.com\"",
                "image": "\"https://www.vets4pets.com/SysSiteAssets/website/placeholders/listing680.jpg?w=585&scale=down\"",
                "name": "\"Dundee Vets4Pets\"",
                "opening_hours": "\"\"",
                "phone": "\"01382 519200\"",
                "street_address": "\"Inside Pets at Home ,Kingsway East Retail Park\"",
                "website": "\"https://www.vets4pets.com/practices/vets-near-east-lothian/vets4pets-dundee/\"",
                "latitude": 0.0,
                "longitude": 0.0
            },
            {
                "__id": 19,
                "address_city": "\"Prescot\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"L34 5NQ\"",
                "address_state": "\"Liverpool\"",
                "amenity": "\"veterinary\"",
                "contact_email": "\"prescot@vets4pets.co.uk\"",
                "image": "\"https://www.vets4pets.com/SysSiteAssets/website/placeholders/listing680.jpg?w=585&scale=down\"",
                "name": "53.42630944569716",
                "opening_hours": "-2.8019790650203293",
                "phone": "\"Prescot Vets4Pets\"",
                "street_address": "\"\"",
                "website": "\"0151 430 6474\"",
                "latitude": 0.0,
                "longitude": 0.0
            },
            {
                "__id": 325,
                "address_city": "\"Barry\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"CF63 4BA\"",
                "address_state": "\"South Glamorgan\"",
                "amenity": "\"veterinary\"",
                "contact_email": "\"barry@vets4pets.com\"",
                "image": "\"https://www.vets4pets.com/SysSiteAssets/website/placeholders/listing680.jpg?w=585&scale=down\"",
                "name": "51.4019223",
                "opening_hours": "-3.2706377",
                "phone": "\"Barry Vets4Pets\"",
                "street_address": "\"\"",
                "website": "\"01446 729900\"",
                "latitude": 0.0,
                "longitude": 0.0
            },
            {
                "__id": 228,
                "address_city": "\"Stoke on Trent\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"ST3 5EP\"",
                "address_state": "\"Staffordshire\"",
                "amenity": "\"veterinary\"",
                "contact_email": "\"longton@vets4pets.com\"",
                "image": "\"https://www.vets4pets.com/SysSiteAssets/website/placeholders/listing680.jpg?w=585&scale=down\"",
                "name": "52.99299020882015",
                "opening_hours": "-2.1308536839233496",
                "phone": "\"Longton Vets4Pets\"",
                "street_address": "\"\"",
                "website": "\"01782 332400\"",
                "latitude": 0.0,
                "longitude": 0.0
            },
            {
                "__id": 300,
                "address_city": "\"Daventry\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"NN11 4GL\"",
                "address_state": "\"Northamptonshire\"",
                "amenity": "\"veterinary\"",
                "contact_email": "\"daventry@vets4pets.com\"",
                "image": "\"https://www.vets4pets.com/SysSiteAssets/website/placeholders/listing680.jpg?w=585&scale=down\"",
                "name": "52.26072922103615",
                "opening_hours": "-1.157162651286998",
                "phone": "\"Daventry Vets4Pets\"",
                "street_address": "\"\"",
                "website": "\"01327 368 450\"",
                "latitude": 0.0,
                "longitude": 0.0
            },
            {
                "__id": 31,
                "address_city": "\"Rugby\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"CV22 7DY\"",
                "address_state": "\"Warwickshire\"",
                "amenity": "\"veterinary\"",
                "contact_email": "\"rugby@vets4pets.com\"",
                "image": "\"https://www.vets4pets.com/SysSiteAssets/website/placeholders/listing680.jpg?w=585&scale=down\"",
                "name": "52.36517604714636",
                "opening_hours": "-1.2834433526089337",
                "phone": "\"Rugby Vets4Pets\"",
                "street_address": "\"\"",
                "website": "\"01788 816117\"",
                "latitude": 0.0,
                "longitude": 0.0
            },
            {
                "__id": 305,
                "address_city": "\"\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"SN14 6RZ\"",
                "address_state": "\"Wiltshire\"",
                "amenity": "\"veterinary\"",
                "contact_email": "\"chippenham@companioncare.co.uk\"",
                "image": "\"https://www.vets4pets.com/SysSiteAssets/website/placeholders/listing680.jpg?w=585&scale=down\"",
                "name": "\"Chippenham Vets4Pets\"",
                "opening_hours": "\"\"",
                "phone": "\"01249 736941\"",
                "street_address": "\"Inside Pets at Home, Bumpers Way ,Chippenham\"",
                "website": "\"https://www.vets4pets.com/practices/chippenham-vets4pets/\"",
                "latitude": 0.0,
                "longitude": 0.0
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "usavingsbank": {
        "__id": 61,
        "id": "usavingsbank",
        "name": "U.S. Savings Bank",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'street_address', 'website']",
        "rowLength": 37,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763486,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of usavingsbank locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 6,
                "address_city": "\"Westerville\"",
                "address_country": "\"US\"",
                "address_postcode": "\"43081\"",
                "address_state": "\"OH\"",
                "latitude": 40.12492,
                "longitude": -82.9308322,
                "name": "\"Westerville\"",
                "opening_hours": "\"Mo-Th 09:00-17:00; Fr 09:00-18:00; Sa 09:00-13:00\"",
                "phone": "\"(614) 882-2286\"",
                "street_address": "\"44 S State St\"",
                "website": "\"https://usavingsbank.com/find-a-branch-atm/locations/westerville/\""
            },
            {
                "__id": 33,
                "address_city": "\"Columbus\"",
                "address_country": "\"US\"",
                "address_postcode": "\"47201\"",
                "address_state": "\"IN\"",
                "latitude": 39.2259867,
                "longitude": -85.9037378,
                "name": "\"Columbus, IN\"",
                "opening_hours": "\"Mo-Th 09:00-17:00; Fr 09:00-18:00; Sa 09:00-13:00\"",
                "phone": "\"(812) 378-9906\"",
                "street_address": "\"2655 Central Ave\"",
                "website": "\"https://usavingsbank.com/find-a-branch-atm/locations/columbus-in/\""
            },
            {
                "__id": 3,
                "address_city": "\"Columbus\"",
                "address_country": "\"US\"",
                "address_postcode": "\"43229\"",
                "address_state": "\"OH\"",
                "latitude": 40.061403,
                "longitude": -82.9832099,
                "name": "\"Columbus\"",
                "opening_hours": "\"Mo-Th 09:00-17:00; Fr 09:00-18:00; Sa 09:00-13:00\"",
                "phone": "\"(614) 781-8896\"",
                "street_address": "\"1330 Morse Rd\"",
                "website": "\"https://usavingsbank.com/find-a-branch-atm/locations/columbus/\""
            },
            {
                "__id": 28,
                "address_city": "\"Cincinnati\"",
                "address_country": "\"US\"",
                "address_postcode": "\"45208\"",
                "address_state": "\"OH\"",
                "latitude": 39.1451306,
                "longitude": -84.4430096,
                "name": "\"Hyde Park\"",
                "opening_hours": "\"Mo-Th 09:00-17:00; Fr 09:00-18:00; Sa 09:00-13:00\"",
                "phone": "\"(513) 842-9000\"",
                "street_address": "\"2691 Madison Rd\"",
                "website": "\"https://usavingsbank.com/find-a-branch-atm/locations/hyde-park/\""
            },
            {
                "__id": 11,
                "address_city": "\"Fairborn\"",
                "address_country": "\"US\"",
                "address_postcode": "\"45324\"",
                "address_state": "\"OH\"",
                "latitude": 39.777884,
                "longitude": -84.058012,
                "name": "\"Fairborn\"",
                "opening_hours": "\"Mo-Th 09:00-17:00; Fr 09:00-18:00; Sa 09:00-13:00\"",
                "phone": "\"(937) 431-3663\"",
                "street_address": "\"2794 Colonel Glenn Hwy\"",
                "website": "\"https://usavingsbank.com/find-a-branch-atm/locations/fairborn/\""
            },
            {
                "__id": 35,
                "address_city": "\"Greenwood\"",
                "address_country": "\"US\"",
                "address_postcode": "\"46143\"",
                "address_state": "\"IN\"",
                "latitude": 39.6133271,
                "longitude": -86.0772809,
                "name": "\"Greenwood\"",
                "opening_hours": "\"Mo-Th 09:00-17:00; Fr 09:00-18:00; Sa 09:00-13:00\"",
                "phone": "\"(317) 865-1166\"",
                "street_address": "\"91 Byrd Way\"",
                "website": "\"https://usavingsbank.com/find-a-branch-atm/locations/greenwood/\""
            },
            {
                "__id": 8,
                "address_city": "\"Upper Arlington\"",
                "address_country": "\"US\"",
                "address_postcode": "\"43221\"",
                "address_state": "\"OH\"",
                "latitude": 40.0239665,
                "longitude": -83.0566509,
                "name": "\"Upper Arlington\"",
                "opening_hours": "\"Mo-Th 09:00-17:00; Fr 09:00-18:00; Sa 09:00-13:00\"",
                "phone": "\"(614) 545-5626\"",
                "street_address": "\"3250 Northwest Blvd\"",
                "website": "\"https://usavingsbank.com/find-a-branch-atm/locations/upper-arlington/\""
            },
            {
                "__id": 7,
                "address_city": "\"Grove City\"",
                "address_country": "\"US\"",
                "address_postcode": "\"43123\"",
                "address_state": "\"OH\"",
                "latitude": 39.8795575,
                "longitude": -83.0692608,
                "name": "\"Grove City\"",
                "opening_hours": "\"Mo-Th 09:00-17:00; Fr 09:00-18:00; Sa 09:00-13:00\"",
                "phone": "\"(614) 317-9700\"",
                "street_address": "\"4090 Hoover Rd\"",
                "website": "\"https://usavingsbank.com/find-a-branch-atm/locations/grove-city/\""
            },
            {
                "__id": 2,
                "address_city": "\"Reynoldsburg\"",
                "address_country": "\"US\"",
                "address_postcode": "\"43068\"",
                "address_state": "\"OH\"",
                "latitude": 39.9525193,
                "longitude": -82.828508,
                "name": "\"Reynoldsburg\"",
                "opening_hours": "\"Mo-Th 09:00-17:00; Fr 09:00-18:00; Sa 09:00-13:00\"",
                "phone": "\"(614) 861-9003\"",
                "street_address": "\"1320 Brice Rd\"",
                "website": "\"https://usavingsbank.com/find-a-branch-atm/locations/reynoldsburg/\""
            },
            {
                "__id": 4,
                "address_city": "\"Lancaster\"",
                "address_country": "\"US\"",
                "address_postcode": "\"43130\"",
                "address_state": "\"OH\"",
                "latitude": 39.7142536,
                "longitude": -82.5674628,
                "name": "\"Lancaster\"",
                "opening_hours": "\"Mo-Th 09:00-17:00; Fr 09:00-18:00; Sa 09:00-13:00\"",
                "phone": "\"(740) 653-3730\"",
                "street_address": "\"1654 E Main St\"",
                "website": "\"https://usavingsbank.com/find-a-branch-atm/locations/lancaster/\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "signet_jewelers": {
        "__id": 62,
        "id": "signet_jewelers",
        "name": "Signet Jewelers",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'latitude', 'longitude', 'name']",
        "rowLength": 43,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763488,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of signet_jewelers locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 27,
                "address_city": "\"Enfield\"",
                "address_country": "\"GB\"",
                "address_full": "\"29 Palace Gardens\"",
                "address_postcode": "\"EN2 6SN\"",
                "latitude": 51.6512,
                "longitude": -0.0835,
                "name": "\"Enfield\""
            },
            {
                "__id": 23,
                "address_city": "\"Maidenhead\"",
                "address_country": "\"GB\"",
                "address_full": "\"39 Nicholsons Walk\"",
                "address_postcode": "\"SL6 1LB\"",
                "latitude": 51.5221,
                "longitude": -0.7219,
                "name": "\"Maidenhead\""
            },
            {
                "__id": 19,
                "address_city": "\"Romford\"",
                "address_country": "\"GB\"",
                "address_full": "\"11 The Liberty\"",
                "address_postcode": "\"RM1 3RL\"",
                "latitude": 51.5783,
                "longitude": 0.1837,
                "name": "\"Romford Liberty\""
            },
            {
                "__id": 39,
                "address_city": "\"Slough\"",
                "address_country": "\"GB\"",
                "address_full": "\"Unit 39 Queensmere\"",
                "address_postcode": "\"SL1 1DD\"",
                "latitude": 51.5091,
                "longitude": -0.5917,
                "name": "\"Slough Queensmere\""
            },
            {
                "__id": 20,
                "address_city": "\"Sutton\"",
                "address_country": "\"GB\"",
                "address_full": "\"128 High Street\"",
                "address_postcode": "\"SM1 1LU\"",
                "latitude": 51.3635,
                "longitude": -0.1931,
                "name": "\"Sutton\""
            },
            {
                "__id": 37,
                "address_city": "\"Kingston\"",
                "address_country": "\"GB\"",
                "address_full": "\"Unit 19 Bentall Centre\"",
                "address_postcode": "\"KT1 1TR\"",
                "latitude": 51.4118,
                "longitude": -0.3047,
                "name": "\"Ej Kingston Bentalls\""
            },
            {
                "__id": 24,
                "address_city": "\"Bexleyheath\"",
                "address_country": "\"GB\"",
                "address_full": "\"Unit 61\"",
                "address_postcode": "\"DA6 7JN\"",
                "latitude": 51.4562,
                "longitude": 0.1462,
                "name": "\"Bexley Heath\""
            },
            {
                "__id": 42,
                "address_city": "\"Bromley\"",
                "address_country": "\"GB\"",
                "address_full": "\"SU21\"",
                "address_postcode": "\"BR1 1DD\"",
                "latitude": 51.4038,
                "longitude": 0.0166,
                "name": "\"Bromley Glades\""
            },
            {
                "__id": 16,
                "address_city": "\"Kingston\"",
                "address_country": "\"GB\"",
                "address_full": "\"51-51A Clarence Street\"",
                "address_postcode": "\"KT1 1RF\"",
                "latitude": 51.4111,
                "longitude": -0.3044,
                "name": "\"Kingston Clarence St\""
            },
            {
                "__id": 5,
                "address_city": "\"Wood Green\"",
                "address_country": "\"GB\"",
                "address_full": "\"3 Cheapside\"",
                "address_postcode": "\"N22 6HH\"",
                "latitude": 51.5932,
                "longitude": -0.1066,
                "name": "\"Wood Green\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "busy_beaver": {
        "__id": 63,
        "id": "busy_beaver",
        "name": "Busy Beaver",
        "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'website']",
        "rowLength": 25,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763488,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of busy_beaver locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 19,
                "address_city": "\"New Castle\"",
                "address_full": "\"2640 Ellwood Rd\"",
                "address_postcode": "\"16101\"",
                "address_state": "\"PA\"",
                "latitude": 40.965202,
                "longitude": -80.310965,
                "name": "\"Busy Beaver of New Castle\"",
                "opening_hours": "\"Mo-Sa 07:30-21:00; Su 08:00-18:00\"",
                "phone": "\"(724) 923-4469\"",
                "website": "\"https://busybeaver.com/stores/newcastle/\""
            },
            {
                "__id": 23,
                "address_city": "\"Minerva\"",
                "address_full": "\"16400 Bayard Rd\"",
                "address_postcode": "\"44657\"",
                "address_state": "\"OH\"",
                "latitude": 40.748689,
                "longitude": -81.089863,
                "name": "\"True Value of Minerva\"",
                "opening_hours": "\"Mo-Sa 08:00-20:00; Su 09:00-17:00\"",
                "phone": "\"(330) 868-7709\"",
                "website": "\"https://busybeaver.com/stores/minerva/\""
            },
            {
                "__id": 18,
                "address_city": "\"Calcutta\"",
                "address_full": "\"15891 State Route 170\"",
                "address_postcode": "\"43920\"",
                "address_state": "\"OH\"",
                "latitude": 40.671875,
                "longitude": -80.5846,
                "name": "\"Busy Beaver of Calcutta\"",
                "opening_hours": "\"Mo-Sa 07:30-21:00; Su 08:00-18:00\"",
                "phone": "\"(330) 476-2299\"",
                "website": "\"https://busybeaver.com/stores/calcutta/\""
            },
            {
                "__id": 22,
                "address_city": "\"Greenville\"",
                "address_full": "\"100 Hadley Rd\"",
                "address_postcode": "\"16125\"",
                "address_state": "\"PA\"",
                "latitude": 41.415616,
                "longitude": -80.352814,
                "name": "\"Busy Beaver of Greenville\"",
                "opening_hours": "\"Mo-Sa 07:30-21:00; Su 08:00-18:00\"",
                "phone": "\"(724) 638-9539\"",
                "website": "\"https://busybeaver.com/stores/greenville/\""
            },
            {
                "__id": 8,
                "address_city": "\"Pittsburgh\"",
                "address_full": "\"4743 Plummer St\"",
                "address_postcode": "\"15201\"",
                "address_state": "\"PA\"",
                "latitude": 40.475418,
                "longitude": -79.958818,
                "name": "\"Busy Beaver of Lawrenceville\"",
                "opening_hours": "\"Mo-Sa 07:30-21:00; Su 08:00-18:00\"",
                "phone": "\"(412) 904-1444\"",
                "website": "\"https://busybeaver.com/stores/lawrenceville/\""
            },
            {
                "__id": 14,
                "address_city": "\"Weirton\"",
                "address_full": "\"290 Three Springs Dr\"",
                "address_postcode": "\"26062\"",
                "address_state": "\"WV\"",
                "latitude": 40.398401,
                "longitude": -80.552988,
                "name": "\"Busy Beaver of Weirton\"",
                "opening_hours": "\"Mo-Sa 07:30-21:00; Su 08:00-18:00\"",
                "phone": "\"(304) 723-7222\"",
                "website": "\"https://busybeaver.com/stores/weirton/\""
            },
            {
                "__id": 13,
                "address_city": "\"Moon Township\"",
                "address_full": "\"1011 Beaver Grade Rd\"",
                "address_postcode": "\"15108\"",
                "address_state": "\"PA\"",
                "latitude": 40.503852,
                "longitude": -80.204956,
                "name": "\"True Value of Moon Township\"",
                "opening_hours": "\"Mo-Sa 08:00-20:00; Su 09:00-17:00\"",
                "phone": "\"(412) 832-1324\"",
                "website": "\"https://busybeaver.com/stores/moon-township/\""
            },
            {
                "__id": 21,
                "address_city": "\"Salem\"",
                "address_full": "\"2340 E State St\"",
                "address_postcode": "\"44460\"",
                "address_state": "\"OH\"",
                "latitude": 40.902509,
                "longitude": -80.829244,
                "name": "\"True Value of Salem\"",
                "opening_hours": "\"Mo-Sa 08:00-20:00; Su 09:00-17:00\"",
                "phone": "\"330-337-5350\"",
                "website": "\"https://busybeaver.com/stores/salem/\""
            },
            {
                "__id": 15,
                "address_city": "\"Ellwood City\"",
                "address_full": "\"265 State Route 288\"",
                "address_postcode": "\"16117\"",
                "address_state": "\"PA\"",
                "latitude": 40.84638,
                "longitude": -80.260727,
                "name": "\"Busy Beaver of Ellwood City\"",
                "opening_hours": "\"Mo-Sa 07:30-21:00; Su 08:00-18:00\"",
                "phone": "\"(724) 758-0662\"",
                "website": "\"https://busybeaver.com/stores/ellwood-city/\""
            },
            {
                "__id": 11,
                "address_city": "\"Washington\"",
                "address_full": "\"1281 W Chestnut St\"",
                "address_postcode": "\"15301\"",
                "address_state": "\"PA\"",
                "latitude": 40.166259,
                "longitude": -80.272181,
                "name": "\"Busy Beaver of Washington\"",
                "opening_hours": "\"Mo-Sa 07:30-21:00; Su 08:00-18:00\"",
                "phone": "\"(724) 228-2660\"",
                "website": "\"https://busybeaver.com/stores/washington/\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "torchys_tacos": {
        "__id": 64,
        "id": "torchys_tacos",
        "name": "Torchy's Tacos",
        "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'latitude', 'longitude', 'opening_hours', 'phone', 'website']",
        "rowLength": 110,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763489,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of torchys_tacos locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 15,
                "address_city": "\"Austin\"",
                "address_full": "\"1468 E. Whitestone Blvd., Ste. 100\"",
                "address_postcode": "\"78613\"",
                "address_state": "\"TX\"",
                "latitude": 30.526583,
                "longitude": -97.809088,
                "opening_hours": "\"Mo-Th 10:00-22:00; Fr 10:00-23:00; Sa 09:00-23:00; Su 09:00-21:00\"",
                "phone": "\"(512) 337-0997\"",
                "website": "\"https://torchystacos.com/location/cedar-park/\""
            },
            {
                "__id": 87,
                "address_city": "\"Lake Charles\"",
                "address_full": "\"3407 Nelson Road\"",
                "address_postcode": "\"70605\"",
                "address_state": "\"LA\"",
                "latitude": 30.195985,
                "longitude": -93.249416,
                "opening_hours": "\"Mo 10:00-21:00; Tu-Th 10:00-22:00; Fr 10:00-23:00; Sa 09:00-23:00; Su 09:00-21:00\"",
                "phone": "\"337-376-0020\"",
                "website": "\"https://torchystacos.com/location/lake-charles/\""
            },
            {
                "__id": 92,
                "address_city": "\"Olathe\"",
                "address_full": "\"11919 S Strang Line Rd.\"",
                "address_postcode": "\"66062\"",
                "address_state": "\"KS\"",
                "latitude": 38.91093,
                "longitude": -94.76605,
                "opening_hours": "\"Mo-Th 10:00-21:00; Fr-Sa 10:00-22:00; Su 10:00-21:00\"",
                "phone": "\"(913)361-0131\"",
                "website": "\"https://torchystacos.com/location/olathe/\""
            },
            {
                "__id": 68,
                "address_city": "\"Austin\"",
                "address_full": "\"110 San Antonio Street Unit 120\"",
                "address_postcode": "\"78701\"",
                "address_state": "\"TX\"",
                "latitude": 30.2653123,
                "longitude": -97.7495447,
                "opening_hours": "\"Mo-Th 08:00-22:00; Fr 08:00-23:00; Sa 09:00-23:00; Su 09:00-22:00\"",
                "phone": "\"(512) 792-5909\"",
                "website": "\"https://torchystacos.com/location/northshore/\""
            },
            {
                "__id": 100,
                "address_city": "\"Denver\"",
                "address_full": "\"1085 N Broadway\"",
                "address_postcode": "\"80203\"",
                "address_state": "\"CO\"",
                "latitude": 39.733496,
                "longitude": -104.987818,
                "opening_hours": "\"Mo-Su 08:00-22:00\"",
                "phone": "\"(303) 436-1704\"",
                "website": "\"https://torchystacos.com/location/broadway-at-11th-ave/\""
            },
            {
                "__id": 9,
                "address_city": "\"Austin\"",
                "address_full": "\"11521 RR 620 N. E-1000\"",
                "address_postcode": "\"78726\"",
                "address_state": "\"TX\"",
                "latitude": 30.453007,
                "longitude": -97.827605,
                "opening_hours": "\"Mo-Th 09:00-21:00; Fr-Sa 09:00-22:00; Su 09:00-21:00\"",
                "phone": "\"(512) 381-8226\"",
                "website": "\"https://torchystacos.com/location/anderson-mill/\""
            },
            {
                "__id": 10,
                "address_city": "\"Austin\"",
                "address_full": "\"4301 W. William Cannon Dr. G-400\"",
                "address_postcode": "\"78749\"",
                "address_state": "\"TX\"",
                "latitude": 30.222333,
                "longitude": -97.840426,
                "opening_hours": "\"Mo-Th 08:00-22:00; Fr-Sa 08:00-23:00; Su 08:00-22:00\"",
                "phone": "\"(512) 514-0767\"",
                "website": "\"https://torchystacos.com/location/arbor-trails/\""
            },
            {
                "__id": 57,
                "address_city": "\"Houston\"",
                "address_full": "\"1035 Gessner Rd., Suite A\"",
                "address_postcode": "\"77055\"",
                "address_state": "\"TX\"",
                "latitude": 29.786095,
                "longitude": -95.544891,
                "opening_hours": "\"Mo-Th 10:00-22:00; Fr 10:00-23:00; Sa 09:00-23:00; Su 09:00-22:00\"",
                "phone": "\"832-304-2700\"",
                "website": "\"https://torchystacos.com/location/memorial-city/\""
            },
            {
                "__id": 6,
                "address_city": "\"Austin\"",
                "address_full": "\"1801 E. 51st Bldg. D\"",
                "address_postcode": "\"78723\"",
                "address_state": "\"TX\"",
                "latitude": 30.301655,
                "longitude": -97.698969,
                "opening_hours": "\"Mo-Fr 10:00-22:00; Sa-Su 08:00-22:00\"",
                "phone": "\"(512) 322-2411\"",
                "website": "\"https://torchystacos.com/location/mueller/\""
            },
            {
                "__id": 42,
                "address_city": "\"Lubbock\"",
                "address_full": "\"3204 W Loop 289 Suite 100\"",
                "address_postcode": "\"79414\"",
                "address_state": "\"TX\"",
                "latitude": 33.56521,
                "longitude": -101.945007,
                "opening_hours": "\"Mo-Th 10:00-22:00; Fr-Sa 10:00-23:00; Su 10:00-22:00\"",
                "phone": "\"(806) 412-5220\"",
                "website": "\"https://torchystacos.com/location/lubbock-west-end/\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "ppgpaints": {
        "__id": 65,
        "id": "ppgpaints",
        "name": "PPG Paints",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'phone', 'shop', 'street_address', 'website']",
        "rowLength": 4929,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763490,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of ppgpaints locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 1831,
                "address_city": "\"Parry Sound, \"",
                "address_country": "\"CA\"",
                "address_postcode": "\"P2A 2G5\"",
                "address_state": "\"ON\"",
                "latitude": 45.359332,
                "longitude": -80.043007,
                "name": "\"SOUND INTERIORS\"",
                "phone": "\"705-771-9414\"",
                "shop": "\"paint\"",
                "street_address": "\"500C-70 JOSEPH ST.   \"",
                "website": "\"https://www.ppgpaints.com/store-locator/ca/ontario/parry-sound-paint-stores/sound-interiors-31393\""
            },
            {
                "__id": 1013,
                "address_city": "\"Pleasanton, \"",
                "address_country": "\"US\"",
                "address_postcode": "\"94588\"",
                "address_state": "\"CA\"",
                "latitude": 37.700115,
                "longitude": -121.910646,
                "name": "\"HOME DEPOT 0629\"",
                "phone": "\"925-847-9200\"",
                "shop": "\"paint\"",
                "street_address": "\"6000 JOHNSON DR   \"",
                "website": "\"https://www.ppgpaints.com/store-locator/us/california/pleasanton-paint-stores/home-depot-0629-7796\""
            },
            {
                "__id": 4501,
                "address_city": "\"Moultrie, \"",
                "address_country": "\"US\"",
                "address_postcode": "\"31768\"",
                "address_state": "\"GA\"",
                "latitude": 31.177329,
                "longitude": -83.761894,
                "name": "\"STONES MOULTRIE\"",
                "phone": "\"229-985-1032\"",
                "shop": "\"paint\"",
                "street_address": "\"139 HIGHWAY 319 SOUTH   \"",
                "website": "\"https://www.ppgpaints.com/store-locator/us/georgia/moultrie-paint-stores/stones-moultrie-26132\""
            },
            {
                "__id": 2746,
                "address_city": "\"Houston, \"",
                "address_country": "\"US\"",
                "address_postcode": "\"77006\"",
                "address_state": "\"TX\"",
                "latitude": 29.752144,
                "longitude": -95.386062,
                "name": "\"SOUTHWESTERN PAINT & WALLPAPER\"",
                "phone": "\"713-522-1685\"",
                "shop": "\"paint\"",
                "street_address": "\"1902 TAFT STREET   \"",
                "website": "\"https://www.ppgpaints.com/store-locator/us/texas/houston-paint-stores/southwestern-paint-and-wallpaper-27444\""
            },
            {
                "__id": 3168,
                "address_city": "\"Omak, \"",
                "address_country": "\"US\"",
                "address_postcode": "\"98841\"",
                "address_state": "\"WA\"",
                "latitude": 48.415327,
                "longitude": -119.503793,
                "name": "\"HOME DEPOT 4748\"",
                "phone": "\"509-826-5459\"",
                "shop": "\"paint\"",
                "street_address": "\"920 ENGH RD   \"",
                "website": "\"https://www.ppgpaints.com/store-locator/us/washington/omak-paint-stores/home-depot-4748-9830\""
            },
            {
                "__id": 4758,
                "address_city": "\"Kapolei, \"",
                "address_country": "\"US\"",
                "address_postcode": "\"96707\"",
                "address_state": "\"HI\"",
                "latitude": 21.329357,
                "longitude": -158.088726,
                "name": "\"HOME DEPOT 1706\"",
                "phone": "\"808-674-6175\"",
                "shop": "\"paint\"",
                "street_address": "\"4600 KALOLEI PKWY   \"",
                "website": "\"https://www.ppgpaints.com/store-locator/us/hawaii/kapolei-paint-stores/home-depot-1706-9015\""
            },
            {
                "__id": 4678,
                "address_city": "\"Glennville, \"",
                "address_country": "\"US\"",
                "address_postcode": "\"30427\"",
                "address_state": "\"GA\"",
                "latitude": 31.933908,
                "longitude": -81.930283,
                "name": "\"SUPPLY COMPANY THE\"",
                "phone": "\"912-654-3082\"",
                "shop": "\"paint\"",
                "street_address": "\"214 S VETERANS BLVD   \"",
                "website": "\"https://www.ppgpaints.com/store-locator/us/georgia/glennville-paint-stores/supply-company-the-27209\""
            },
            {
                "__id": 4763,
                "address_city": "\"Albany, \"",
                "address_country": "\"US\"",
                "address_postcode": "\"31707\"",
                "address_state": "\"GA\"",
                "latitude": 31.616542,
                "longitude": -84.206654,
                "name": "\"HOME DEPOT 0155\"",
                "phone": "\"912-446-2501\"",
                "shop": "\"paint\"",
                "street_address": "\"1219 WESTOVER BLVD   \"",
                "website": "\"https://www.ppgpaints.com/store-locator/us/georgia/albany-paint-stores/home-depot-0155-13732\""
            },
            {
                "__id": 1232,
                "address_city": "\"Anaheim, \"",
                "address_country": "\"US\"",
                "address_postcode": "\"92805\"",
                "address_state": "\"CA\"",
                "latitude": 33.818838,
                "longitude": -117.890778,
                "name": "\"PPG Paints 8067\"",
                "phone": "\"714-535-8810\"",
                "shop": "\"paint\"",
                "street_address": "\"1889 EAST BALL ROAD   \"",
                "website": "\"https://www.ppgpaints.com/store-locator/us/california/anaheim-paint-stores/ppg-paints-8067-21394\""
            },
            {
                "__id": 2772,
                "address_city": "\"Houston, \"",
                "address_country": "\"US\"",
                "address_postcode": "\"77089\"",
                "address_state": "\"TX\"",
                "latitude": 29.607901,
                "longitude": -95.211557,
                "name": "\"HOME DEPOT 6501\"",
                "phone": "\"281-464-2080\"",
                "shop": "\"paint\"",
                "street_address": "\"11820 DICKINSON RD   \"",
                "website": "\"https://www.ppgpaints.com/store-locator/us/texas/houston-paint-stores/home-depot-6501-7607\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "chef_and_brewer": {
        "__id": 66,
        "id": "chef_and_brewer",
        "name": "Chef & Brewer",
        "columnNames": "['address_country', 'address_full', 'address_postcode', 'amenity', 'contact_email', 'image', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'website']",
        "rowLength": 151,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763491,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of chef_and_brewer locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 105,
                "address_country": "\"GB\"",
                "address_full": "\"1 Church Road, Little Berkhamsted, Hertford, SG13 8LY\"",
                "address_postcode": "\"SG13 8LY\"",
                "amenity": "\"pub\"",
                "contact_email": "\"6177@greeneking.co.uk\"",
                "image": "\"https://www.chefandbrewer.com/q/i/6177/\"",
                "latitude": 51.753828,
                "longitude": -0.129733,
                "name": "\"Five Horseshoes\"",
                "opening_hours": "\"Mo-Fr 11:00-23:00; Sa 09:30-23:00; Su 09:30-22:30\"",
                "phone": "\"01707 875055\"",
                "website": "\"https://www.chefandbrewer.com/pubs/hertfordshire/five-horseshoes/\""
            },
            {
                "__id": 57,
                "address_country": "\"GB\"",
                "address_full": "\", Norton Fitzwarren, Taunton, TA2 6NR\"",
                "address_postcode": "\"TA2 6NR\"",
                "amenity": "\"pub\"",
                "contact_email": "\"6302@greeneking.co.uk\"",
                "image": "\"https://www.chefandbrewer.com/q/i/6302/\"",
                "latitude": 51.031096,
                "longitude": -3.135569,
                "name": "\"Cross Keys\"",
                "opening_hours": "\"Mo-Fr 11:00-23:00; Sa 09:30-23:00; Su 09:30-22:30\"",
                "phone": "\"01823 333062\"",
                "website": "\"https://www.chefandbrewer.com/pubs/somerset/cross-keys/\""
            },
            {
                "__id": 22,
                "address_country": "\"GB\"",
                "address_full": "\"Ermin Street, Blunsdon, Swindon, SN26 8DJ\"",
                "address_postcode": "\"SN26 8DJ\"",
                "amenity": "\"pub\"",
                "contact_email": "\"6165@greeneking.co.uk\"",
                "image": "\"https://www.chefandbrewer.com/q/i/6165/\"",
                "latitude": 51.609066,
                "longitude": -1.787389,
                "name": "\"Cold Harbour\"",
                "opening_hours": "\"Mo-Fr 07:00-23:00; Sa 08:00-23:00; Su 08:00-22:30\"",
                "phone": "\"01793 724840\"",
                "website": "\"https://www.chefandbrewer.com/pubs/wiltshire/cold-harbour/\""
            },
            {
                "__id": 50,
                "address_country": "\"GB\"",
                "address_full": "\"Rugeley Road, Burntwood, WS7 9HA\"",
                "address_postcode": "\"WS7 9HA\"",
                "amenity": "\"pub\"",
                "contact_email": "\"6213@greeneking.co.uk\"",
                "image": "\"https://www.chefandbrewer.com/q/i/6213/\"",
                "latitude": 52.686262,
                "longitude": -1.906136,
                "name": "\"Nags Head\"",
                "opening_hours": "\"Mo-Fr 11:00-23:00; Sa 09:30-23:00; Su 09:30-22:30\"",
                "phone": "\"01543 682510\"",
                "website": "\"https://www.chefandbrewer.com/pubs/staffordshire/nags-head/\""
            },
            {
                "__id": 72,
                "address_country": "\"GB\"",
                "address_full": "\"High Street, Weedon, Northampton, NN7 4PX\"",
                "address_postcode": "\"NN7 4PX\"",
                "amenity": "\"pub\"",
                "contact_email": "\"6167@greeneking.co.uk\"",
                "image": "\"https://www.chefandbrewer.com/q/i/6167/\"",
                "latitude": 52.233166,
                "longitude": -1.075236,
                "name": "\"Crossroads\"",
                "opening_hours": "\"Mo-Fr 11:00-23:00; Sa 09:30-23:00; Su 09:30-22:30\"",
                "phone": "\"01327 340354\"",
                "website": "\"https://www.chefandbrewer.com/pubs/northamptonshire/crossroads/\""
            },
            {
                "__id": 134,
                "address_country": "\"GB\"",
                "address_full": "\"Thorpe Meadows, Peterborough, PE3 6GA\"",
                "address_postcode": "\"PE3 6GA\"",
                "amenity": "\"pub\"",
                "contact_email": "\"0122@greeneking.co.uk\"",
                "image": "\"https://www.chefandbrewer.com/q/i/0122/\"",
                "latitude": 52.571087,
                "longitude": -0.266863,
                "name": "\"Boathouse\"",
                "opening_hours": "\"Mo-Su 11:00-23:00\"",
                "phone": "\"01733 898 469\"",
                "website": "\"https://www.chefandbrewer.com/pubs/cambridgeshire/boathouse/\""
            },
            {
                "__id": 4,
                "address_country": "\"GB\"",
                "address_full": "\"Fox Lane, Wimborne, BH21 2EQ\"",
                "address_postcode": "\"BH21 2EQ\"",
                "amenity": "\"pub\"",
                "contact_email": "\"7773@greeneking.co.uk\"",
                "image": "\"https://www.chefandbrewer.com/q/i/7773/\"",
                "latitude": 50.799566,
                "longitude": -1.937061,
                "name": "\"Fox & Hounds\"",
                "opening_hours": "\"Mo-Sa 11:00-23:00; Su 11:00-22:30\"",
                "phone": "\"01202 872 881\"",
                "website": "\"https://www.chefandbrewer.com/pubs/dorset/fox-hounds/\""
            },
            {
                "__id": 95,
                "address_country": "\"GB\"",
                "address_full": "\"Station Road, Wrea Green, Preston, PR4 2PH\"",
                "address_postcode": "\"PR4 2PH\"",
                "amenity": "\"pub\"",
                "contact_email": "\"6188@greeneking.co.uk\"",
                "image": "\"https://www.chefandbrewer.com/q/i/6188/\"",
                "latitude": 53.777115,
                "longitude": -2.916159,
                "name": "\"Grapes Hotel\"",
                "opening_hours": "\"Mo-Fr 11:00-23:00; Sa 09:30-23:00; Su 09:30-22:30\"",
                "phone": "\"01772 682927\"",
                "website": "\"https://www.chefandbrewer.com/pubs/lancashire/grapes-hotel/\""
            },
            {
                "__id": 67,
                "address_country": "\"GB\"",
                "address_full": "\"Mapperley Plains, Mapperley, Nottingham, NG3 5RT\"",
                "address_postcode": "\"NG3 5RT\"",
                "amenity": "\"pub\"",
                "contact_email": "\"6250@greeneking.co.uk\"",
                "image": "\"https://www.chefandbrewer.com/q/i/6250/\"",
                "latitude": 53.010172,
                "longitude": -1.099853,
                "name": "\"Travellers Rest\"",
                "opening_hours": "\"Mo-Fr 11:00-23:00; Sa 09:30-23:00; Su 09:30-22:30\"",
                "phone": "\"01159 264412\"",
                "website": "\"https://www.chefandbrewer.com/pubs/nottinghamshire/travellers-rest/\""
            },
            {
                "__id": 125,
                "address_country": "\"GB\"",
                "address_full": "\"Sewardstone Road, Sewardstone, Waltham Abbey, EN9 3QF\"",
                "address_postcode": "\"EN9 3QF\"",
                "amenity": "\"pub\"",
                "contact_email": "\"6099@greeneking.co.uk\"",
                "image": "\"https://www.chefandbrewer.com/q/i/6099/\"",
                "latitude": 51.6681,
                "longitude": 0.002212,
                "name": "\"Bakers Arms\"",
                "opening_hours": "\"Mo-Fr 11:00-23:00; Sa 09:30-23:00; Su 09:30-22:30\"",
                "phone": "\"01992 656000\"",
                "website": "\"https://www.chefandbrewer.com/pubs/essex/bakers-arms/\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "ace_hardware": {
        "__id": 67,
        "id": "ace_hardware",
        "name": "Ace Hardware",
        "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'website']",
        "rowLength": 3999,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763492,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of ace_hardware locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 3010,
                "address_city": "\"Delray Beach\"",
                "address_full": "\"4591 W Atlantic Ave\"",
                "address_postcode": "\"334453834\"",
                "address_state": "\"FL\"",
                "latitude": 26.456,
                "longitude": -80.1045,
                "name": "\"Hessler Paint & Ace Hardware\"",
                "opening_hours": "\"Mo-Fr 07:00-18:00; Sa 08:00-17:00; Su 09:00-16:00\"",
                "phone": "\"(561) 495-8900\"",
                "shop": "\"doityourself\"",
                "website": "\"https://www.acehardware.com/store-details/16958\""
            },
            {
                "__id": 2127,
                "address_city": "\"Auburn\"",
                "address_full": "\"965 Minot Ave\"",
                "address_postcode": "\"042103741\"",
                "address_state": "\"ME\"",
                "latitude": 44.0811,
                "longitude": -70.2696,
                "name": "\"Petro's Ace Hardware\"",
                "opening_hours": "\"Mo-Sa 07:00-17:00\"",
                "phone": "\"(207) 783-0433\"",
                "shop": "\"doityourself\"",
                "website": "\"https://www.acehardware.com/store-details/11835\""
            },
            {
                "__id": 3393,
                "address_city": "\"Fruita\"",
                "address_full": "\"1650 Highway 6 And 50\"",
                "address_postcode": "\"815212042\"",
                "address_state": "\"CO\"",
                "latitude": 39.163347,
                "longitude": -108.74636,
                "name": "\"Co-op Country\"",
                "opening_hours": "\"Mo-Sa 06:00-19:00; Su 08:00-19:00\"",
                "phone": "\"(970) 858-3667\"",
                "shop": "\"doityourself\"",
                "website": "\"https://www.acehardware.com/store-details/04987\""
            },
            {
                "__id": 2612,
                "address_city": "\"Peru\"",
                "address_full": "\"1713 4th St\"",
                "address_postcode": "\"613543322\"",
                "address_state": "\"IL\"",
                "latitude": 41.327643,
                "longitude": -89.128025,
                "name": "\"Debo Ace Hardware\"",
                "opening_hours": "\"Mo-Fr 07:00-17:30; Sa 07:00-17:00\"",
                "phone": "\"(815) 223-0461\"",
                "shop": "\"doityourself\"",
                "website": "\"https://www.acehardware.com/store-details/12081\""
            },
            {
                "__id": 1189,
                "address_city": "\"Loveland\"",
                "address_full": "\"675 Loveland Madeira Rd\"",
                "address_postcode": "\"451402715\"",
                "address_state": "\"OH\"",
                "latitude": 39.263334,
                "longitude": -84.26847,
                "name": "\"Ace Hardware Loveland\"",
                "opening_hours": "\"Mo-Fr 08:00-20:00; Sa 08:00-19:00; Su 10:00-18:00\"",
                "phone": "\"(513) 583-1415\"",
                "shop": "\"doityourself\"",
                "website": "\"https://www.acehardware.com/store-details/15732\""
            },
            {
                "__id": 2335,
                "address_city": "\"Lexington\"",
                "address_full": "\"3330 Partner Pl Ste 152\"",
                "address_postcode": "\"405033575\"",
                "address_state": "\"KY\"",
                "latitude": 38.0053,
                "longitude": -84.5321,
                "name": "\"Lyons Ace Hardware\"",
                "opening_hours": "\"Mo-Sa 08:30-18:30; Su 12:00-17:00\"",
                "phone": "\"(859) 436-1700\"",
                "shop": "\"doityourself\"",
                "website": "\"https://www.acehardware.com/store-details/17972\""
            },
            {
                "__id": 1155,
                "address_city": "\"Kenton\"",
                "address_full": "\"910 E Columbus St\"",
                "address_postcode": "\"433261683\"",
                "address_state": "\"OH\"",
                "latitude": 40.646,
                "longitude": -83.6026,
                "name": "\"Ace Hardware\"",
                "opening_hours": "\"Mo-Sa 08:00-20:00; Su 10:00-18:00\"",
                "phone": "\"(419) 673-0517\"",
                "shop": "\"doityourself\"",
                "website": "\"https://www.acehardware.com/store-details/17666\""
            },
            {
                "__id": 3925,
                "address_city": "\"Guntersville\"",
                "address_full": "\"1536 Blount Ave\"",
                "address_postcode": "\"359761838\"",
                "address_state": "\"AL\"",
                "latitude": 34.3448,
                "longitude": -86.3246,
                "name": "\"Lake Guntersville Ace Hardware, LLC\"",
                "opening_hours": "\"Mo-Fr 07:00-17:30; Sa 07:00-17:00\"",
                "phone": "\"(256) 582-3353\"",
                "shop": "\"doityourself\"",
                "website": "\"https://www.acehardware.com/store-details/17514\""
            },
            {
                "__id": 523,
                "address_city": "\"Seagoville\"",
                "address_full": "\"550 N Highway 175 Suite 108\"",
                "address_postcode": "\"751591801\"",
                "address_state": "\"TX\"",
                "latitude": 32.6377,
                "longitude": -96.5509,
                "name": "\"Westlake Ace Hardware\"",
                "opening_hours": "\"Mo-Sa 08:00-20:00; Su 09:00-18:00\"",
                "phone": "\"(972) 287-1515\"",
                "shop": "\"doityourself\"",
                "website": "\"https://www.acehardware.com/store-details/16527\""
            },
            {
                "__id": 1536,
                "address_city": "\"Lincoln\"",
                "address_full": "\"924 N 70th St\"",
                "address_postcode": "\"685052104\"",
                "address_state": "\"NE\"",
                "latitude": 40.8256,
                "longitude": -96.6204,
                "name": "\"Westlake Ace Hardware\"",
                "opening_hours": "\"Mo-Sa 07:30-20:00; Su 09:00-18:00\"",
                "phone": "\"(402) 486-1515\"",
                "shop": "\"doityourself\"",
                "website": "\"https://www.acehardware.com/store-details/17852\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "aldi_nord_fr": {
        "__id": 68,
        "id": "aldi_nord_fr",
        "name": "Aldi Nord France",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address']",
        "rowLength": 1330,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763493,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of aldi_nord_fr locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 1030,
                "address_city": "\"Vic-le-Comte\"",
                "address_country": "\"FR\"",
                "address_postcode": "\"63270\"",
                "address_state": "\"Auvergne-Rh\u00f4ne-Alpes\"",
                "latitude": 45.6659833,
                "longitude": 3.2078791,
                "name": "\"ALDI\"",
                "opening_hours": "\"Mo-Sa 08:30-19:30; Su 08:30-12:30\"",
                "phone": "\"+33 1 81 09 78 25\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Boulevard du G\u00e9n\u00e9ral de Gaulle\""
            },
            {
                "__id": 986,
                "address_city": "\"Cond\u00e9-en-Normandie\"",
                "address_country": "\"FR\"",
                "address_postcode": "\"14110\"",
                "address_state": "\"Normandie\"",
                "latitude": 48.844274,
                "longitude": -0.5529605,
                "name": "\"ALDI\"",
                "opening_hours": "\"Mo-Sa 08:30-20:00; Su 08:30-12:30\"",
                "phone": "\"+33 1 81 09 78 25\"",
                "shop": "\"supermarket\"",
                "street_address": "\"67 Rue Saint-Martin\""
            },
            {
                "__id": 1142,
                "address_city": "\"Mougins\"",
                "address_country": "\"FR\"",
                "address_postcode": "\"06250\"",
                "address_state": "\"Provence-Alpes-C\u00f4te d'Azur\"",
                "latitude": 43.6086164,
                "longitude": 6.97302415,
                "name": "\"ALDI\"",
                "opening_hours": "\"Mo-Sa 08:30-19:30; Su 08:30-12:30\"",
                "phone": "\"+33 1 81 09 78 25\"",
                "shop": "\"supermarket\"",
                "street_address": "\"470 Chemin de la Plaine\""
            },
            {
                "__id": 1164,
                "address_city": "\"Rocbaron\"",
                "address_country": "\"FR\"",
                "address_postcode": "\"83136\"",
                "address_state": "\"Provence-Alpes-C\u00f4te d'Azur\"",
                "latitude": 43.315171,
                "longitude": 6.0748386,
                "name": "\"ALDI\"",
                "opening_hours": "\"Mo-Sa 08:30-20:00; Su 08:30-12:30\"",
                "phone": "\"+33 1 81 09 78 25\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Fray Redon\""
            },
            {
                "__id": 190,
                "address_city": "\"Colmar\"",
                "address_country": "\"FR\"",
                "address_postcode": "\"68000\"",
                "address_state": "\"Grand Est\"",
                "latitude": 48.09635272,
                "longitude": 7.3689499,
                "name": "\"ALDI\"",
                "opening_hours": "\"Mo-Sa 08:30-20:00\"",
                "phone": "\"+33 1 81 09 78 25\"",
                "shop": "\"supermarket\"",
                "street_address": "\"1 Rue Andr\u00e9 Kiener\""
            },
            {
                "__id": 679,
                "address_city": "\"Kembs\"",
                "address_country": "\"FR\"",
                "address_postcode": "\"68680\"",
                "address_state": "\"Grand Est\"",
                "latitude": 47.67730084,
                "longitude": 7.49945726,
                "name": "\"ALDI\"",
                "opening_hours": "\"Mo-Sa 08:30-19:30\"",
                "phone": "\"+33 1 81 09 78 25\"",
                "shop": "\"supermarket\"",
                "street_address": "\"14 Rue de l'Artisanat\""
            },
            {
                "__id": 1077,
                "address_city": "\"Le Havre\"",
                "address_country": "\"FR\"",
                "address_postcode": "\"76600\"",
                "address_state": "\"Normandie\"",
                "latitude": 49.4930274,
                "longitude": 0.1374443,
                "name": "\"ALDI\"",
                "opening_hours": "\"Mo-Sa 08:30-20:00; Su 08:30-12:30\"",
                "phone": "\"+33 1 81 09 78 25\"",
                "shop": "\"supermarket\"",
                "street_address": "\"40 Rue de Fleurus\""
            },
            {
                "__id": 21,
                "address_city": "\"Puttelange-aux-Lacs\"",
                "address_country": "\"FR\"",
                "address_postcode": "\"57510\"",
                "address_state": "\"Grand Est\"",
                "latitude": 49.0555928,
                "longitude": 6.9271849,
                "name": "\"ALDI\"",
                "opening_hours": "\"Mo-Sa 08:30-19:30\"",
                "phone": "\"+33 1 81 09 78 25\"",
                "shop": "\"supermarket\"",
                "street_address": "\"20 Rue Robert Schuman\""
            },
            {
                "__id": 842,
                "address_city": "\"Quimper\"",
                "address_country": "\"FR\"",
                "address_postcode": "\"29000\"",
                "address_state": "\"Bretagne\"",
                "latitude": 48.01759018,
                "longitude": -4.08415222,
                "name": "\"ALDI\"",
                "opening_hours": "\"Mo-Sa 08:30-20:00; Su 08:30-12:30\"",
                "phone": "\"+33 1 81 09 78 25\"",
                "shop": "\"supermarket\"",
                "street_address": "\"173 Route de Brest\""
            },
            {
                "__id": 661,
                "address_city": "\"Bouillargues\"",
                "address_country": "\"FR\"",
                "address_postcode": "\"30230\"",
                "address_state": "\"Occitanie\"",
                "latitude": 43.7911289,
                "longitude": 4.4322525,
                "name": "\"ALDI\"",
                "opening_hours": "\"Mo-Sa 08:30-19:30; Su 08:30-12:30\"",
                "phone": "\"+33 1 81 09 78 25\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Rue des Quatre Vents\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "netto_salling": {
        "__id": 69,
        "id": "netto_salling",
        "name": "Netto Salling",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'image', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'twitter', 'website']",
        "rowLength": 1524,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763494,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of netto_salling locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 136,
                "address_city": "\"Fars&#248;\"",
                "address_country": "\"DK\"",
                "address_postcode": "\"9640\"",
                "image": "\"https://netto-dk-prod.imgix.net/media/1071/09_08_16_netto_image_01_114.jpg?rect=0%2C107%2C1618%2C866&w=1120&h=600&s=852f34c5e073d1c2a940d662eee5c77c\"",
                "latitude": 56.77889,
                "longitude": 9.34178,
                "name": "\"Netto Fars&#248;\"",
                "opening_hours": "\"Mo-Su 08:00-22:00\"",
                "phone": "\"\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Holmevej 2\"",
                "twitter": "\"@nettodk\"",
                "website": "\"https://www.netto.dk/butikker/farsoe/holmevej-2/cee272d53c6448078dd47951c74f51f2/\""
            },
            {
                "__id": 281,
                "address_city": "\"Ullerslev\"",
                "address_country": "\"DK\"",
                "address_postcode": "\"5540\"",
                "image": "\"https://netto-dk-prod.imgix.net/media/1071/09_08_16_netto_image_01_114.jpg?rect=0%2C107%2C1618%2C866&w=1120&h=600&s=852f34c5e073d1c2a940d662eee5c77c\"",
                "latitude": 55.36301,
                "longitude": 10.67279,
                "name": "\"Netto Nyborgvej, Ullerslev\"",
                "opening_hours": "\"Mo-Su 07:00-22:00\"",
                "phone": "\"\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Nyborgvej 15-17\"",
                "twitter": "\"@nettodk\"",
                "website": "\"https://www.netto.dk/butikker/ullerslev/nyborgvej-15-17/7d3678666e6a4d1bb44c7c55507ecb97/\""
            },
            {
                "__id": 843,
                "address_city": "\"Odense Sv\"",
                "address_country": "\"DK\"",
                "address_postcode": "\"5250\"",
                "image": "\"https://netto-dk-prod.imgix.net/media/1071/09_08_16_netto_image_01_114.jpg?rect=0%2C107%2C1618%2C866&w=1120&h=600&s=852f34c5e073d1c2a940d662eee5c77c\"",
                "latitude": 55.35713,
                "longitude": 10.34086,
                "name": "\"Netto Odense Sv\"",
                "opening_hours": "\"Mo-Su 07:00-22:00\"",
                "phone": "\"\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Magnoliavej 1\"",
                "twitter": "\"@nettodk\"",
                "website": "\"https://www.netto.dk/butikker/odense-sv/magnoliavej-1/655ea375e01d45e4834473d3c670cad9/\""
            },
            {
                "__id": 179,
                "address_city": "\"Aalborg &#216;st\"",
                "address_country": "\"DK\"",
                "address_postcode": "\"9220\"",
                "image": "\"https://netto-dk-prod.imgix.net/media/1071/09_08_16_netto_image_01_114.jpg?rect=0%2C107%2C1618%2C866&w=1120&h=600&s=852f34c5e073d1c2a940d662eee5c77c\"",
                "latitude": 57.02572,
                "longitude": 10.00136,
                "name": "\"Netto Tornh&#248;jvej, Aalborg\"",
                "opening_hours": "\"Mo-Su 07:00-22:00\"",
                "phone": "\"\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Tornh&#248;jvej 4\"",
                "twitter": "\"@nettodk\"",
                "website": "\"https://www.netto.dk/butikker/aalborg-oest/tornhoejvej-4/150f799220164075a2f32a2f7c8ab7b2/\""
            },
            {
                "__id": 318,
                "address_city": "\"Havdrup\"",
                "address_country": "\"DK\"",
                "address_postcode": "\"4622\"",
                "image": "\"https://netto-dk-prod.imgix.net/media/1071/09_08_16_netto_image_01_114.jpg?rect=0%2C107%2C1618%2C866&w=1120&h=600&s=852f34c5e073d1c2a940d662eee5c77c\"",
                "latitude": 55.5438,
                "longitude": 12.11417,
                "name": "\"Netto Havdrup\"",
                "opening_hours": "\"Mo-Su 08:00-22:00\"",
                "phone": "\"\"",
                "shop": "\"supermarket\"",
                "street_address": "\"M&#248;llemarken 14\"",
                "twitter": "\"@nettodk\"",
                "website": "\"https://www.netto.dk/butikker/havdrup/moellemarken-14/905a219d0e224818916d06fd5fc38999/\""
            },
            {
                "__id": 139,
                "address_city": "\"&#216;lgod\"",
                "address_country": "\"DK\"",
                "address_postcode": "\"6870\"",
                "image": "\"https://netto-dk-prod.imgix.net/media/1071/09_08_16_netto_image_01_114.jpg?rect=0%2C107%2C1618%2C866&w=1120&h=600&s=852f34c5e073d1c2a940d662eee5c77c\"",
                "latitude": 55.80789,
                "longitude": 8.6247,
                "name": "\"Netto &#216;lgod\"",
                "opening_hours": "\"Mo-Su 07:00-22:00\"",
                "phone": "\"\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Industrivej 2\"",
                "twitter": "\"@nettodk\"",
                "website": "\"https://www.netto.dk/butikker/oelgod/industrivej-2/b4cc891e411845d0b21753f0bb46d89e/\""
            },
            {
                "__id": 339,
                "address_city": "\"K&#248;benhavn &#216;\"",
                "address_country": "\"DK\"",
                "address_postcode": "\"2100\"",
                "image": "\"https://netto-dk-prod.imgix.net/media/1071/09_08_16_netto_image_01_114.jpg?rect=0%2C107%2C1618%2C866&w=1120&h=600&s=852f34c5e073d1c2a940d662eee5c77c\"",
                "latitude": 55.71184,
                "longitude": 12.57199,
                "name": "\"Netto Nyg&#229;rdsvej\"",
                "opening_hours": "\"Mo-Su 08:00-22:00\"",
                "phone": "\"\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Nyg&#229;rdsvej 32\"",
                "twitter": "\"@nettodk\"",
                "website": "\"https://www.netto.dk/butikker/koebenhavn-oe/nygaardsvej-32/aa777e4b5ac841b18da3c2f6096e16e6/\""
            },
            {
                "__id": 1464,
                "address_city": "\"Salzwedel\"",
                "address_country": "\"DE\"",
                "address_postcode": "\"29410\"",
                "image": "\"https://netto-de-prod.imgix.net/media/1114/netto_stadtkarte.jpg?rect=0%2C0%2C1866%2C1000&w=1120&h=600&s=4a3ed4787255c714c04d5e05dd927eeb\"",
                "latitude": 52.8583,
                "longitude": 11.15622,
                "name": "\"Netto Salzwedel\"",
                "opening_hours": "\"Mo-Sa 07:00-20:00\"",
                "phone": "\"\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Bahnhofstra&#223;e 2\"",
                "twitter": "\"\"",
                "website": "\"https://www.netto.de/geschaefte/salzwedel/bahnhofstrasse-2/ccb57713d7ab417f878603cbd1a3947b/\""
            },
            {
                "__id": 1061,
                "address_city": "\"Greve\"",
                "address_country": "\"DK\"",
                "address_postcode": "\"2670\"",
                "image": "\"https://netto-dk-prod.imgix.net/media/1071/09_08_16_netto_image_01_114.jpg?rect=0%2C107%2C1618%2C866&w=1120&h=600&s=852f34c5e073d1c2a940d662eee5c77c\"",
                "latitude": 55.59439,
                "longitude": 12.31666,
                "name": "\"Netto Eriksmindevej Greve\"",
                "opening_hours": "\"Mo-Su 08:00-22:00\"",
                "phone": "\"\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Eriksmindevej 26\"",
                "twitter": "\"@nettodk\"",
                "website": "\"https://www.netto.dk/butikker/greve/eriksmindevej-26/3014420d914a4f3fb4d7d3a41112aa9c/\""
            },
            {
                "__id": 426,
                "address_city": "\"Otwock\"",
                "address_country": "\"PL\"",
                "address_postcode": "\"05-400\"",
                "image": "\"https://netto-pl-prod.imgix.net/media/1001/dsc07822.jpg?rect=240%2C0%2C1120%2C600&w=1120&h=600&s=8da5979abde055402cf0d2339eaaf000\"",
                "latitude": 52.09678,
                "longitude": 21.28754,
                "name": "\"Netto Otwock\"",
                "opening_hours": "\"Mo-Sa 07:00-22:00\"",
                "phone": "\"+48 531 994 994\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Ul. Plk. Ryszarda Kukli\u0144skiego 1\"",
                "twitter": "\"\"",
                "website": "\"https://www.netto.pl/sklepy/otwock/ul-plk-ryszarda-kuklinskiego-1/496b74f6531048469d75a2f1510a1f53/\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "cititrends": {
        "__id": 70,
        "id": "cititrends",
        "name": "Citi Trends",
        "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'website']",
        "rowLength": 615,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763495,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of cititrends locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 63,
                "address_city": "\"Beaufort\"",
                "address_full": "\"11 Robert Smalls Parkway\"",
                "address_postcode": "\"29906\"",
                "address_state": "\"South Carolina\"",
                "latitude": 32.4394094,
                "longitude": -80.7030953,
                "name": "\"Citi Trends\"",
                "opening_hours": "\"Mo 11:00-20:00; Tu 11:00-20:00; We 11:00-20:00; Th 11:00-20:00; Fr 10:00-21:00; Sa 10:00-21:00; Su 12:00-18:00\"",
                "phone": "\"(843) 522-8254\"",
                "shop": "\"clothes\"",
                "website": "\"https://locations.cititrends.com/sc/beaufort/11-robert-smalls-parkway.html\""
            },
            {
                "__id": 192,
                "address_city": "\"Chickasaw\"",
                "address_full": "\"416 N. Craft Hwy\"",
                "address_postcode": "\"36611\"",
                "address_state": "\"Alabama\"",
                "latitude": 30.771575873552486,
                "longitude": -88.07397386068124,
                "name": "\"Citi Trends\"",
                "opening_hours": "\"Mo 11:00-20:00; Tu 11:00-20:00; We 11:00-20:00; Th 11:00-20:00; Fr 10:00-20:00; Sa 10:00-20:00; Su 12:00-18:00\"",
                "phone": "\"(251) 452-0015\"",
                "shop": "\"clothes\"",
                "website": "\"https://locations.cititrends.com/al/chickasaw/416-n--craft-hwy.html\""
            },
            {
                "__id": 320,
                "address_city": "\"Hinesville\"",
                "address_full": "\"229 General Screvn Wy M#1\"",
                "address_postcode": "\"31313\"",
                "address_state": "\"Georgia\"",
                "latitude": 31.8426935,
                "longitude": -81.6021207,
                "name": "\"Citi Trends\"",
                "opening_hours": "\"Mo 10:00-21:00; Tu 10:00-21:00; We 10:00-21:00; Th 10:00-21:00; Fr 10:00-21:00; Sa 10:00-21:00; Su 12:00-18:00\"",
                "phone": "\"(912) 368-2590\"",
                "shop": "\"clothes\"",
                "website": "\"https://locations.cititrends.com/ga/hinesville/229-general-screvn-wy-m-1.html\""
            },
            {
                "__id": 567,
                "address_city": "\"Columbus\"",
                "address_full": "\"3515 Cleveland Ave\"",
                "address_postcode": "\"43224\"",
                "address_state": "\"Ohio\"",
                "latitude": 40.039258,
                "longitude": -82.9641822,
                "name": "\"Citi Trends\"",
                "opening_hours": "\"Mo 10:00-21:00; Tu 10:00-21:00; We 10:00-21:00; Th 10:00-21:00; Fr 10:00-21:00; Sa 10:00-21:00; Su 12:00-18:00\"",
                "phone": "\"(614) 262-4655\"",
                "shop": "\"clothes\"",
                "website": "\"https://locations.cititrends.com/oh/columbus/3515-cleveland-ave.html\""
            },
            {
                "__id": 164,
                "address_city": "\"Troy\"",
                "address_full": "\"101 Southland Village\"",
                "address_postcode": "\"36079\"",
                "address_state": "\"Alabama\"",
                "latitude": 31.785709058015723,
                "longitude": -85.96658502587697,
                "name": "\"Citi Trends\"",
                "opening_hours": "\"Mo 11:00-20:00; Tu 11:00-20:00; We 11:00-20:00; Th 11:00-20:00; Fr 10:00-21:00; Sa 10:00-21:00; Su 12:00-18:00\"",
                "phone": "\"(334) 670-2016\"",
                "shop": "\"clothes\"",
                "website": "\"https://locations.cititrends.com/al/troy/101-southland-village.html\""
            },
            {
                "__id": 362,
                "address_city": "\"Augusta\"",
                "address_full": "\"1329-B Augusta West Pkwy\"",
                "address_postcode": "\"30909\"",
                "address_state": "\"Georgia\"",
                "latitude": 33.46887844219079,
                "longitude": -82.08759597440184,
                "name": "\"Citi Trends\"",
                "opening_hours": "\"Mo 11:00-20:00; Tu 11:00-20:00; We 11:00-20:00; Th 11:00-20:00; Fr 10:00-21:00; Sa 10:00-21:00; Su 12:00-18:00\"",
                "phone": "\"(706) 447-4480\"",
                "shop": "\"clothes\"",
                "website": "\"https://locations.cititrends.com/ga/augusta/1329-b-augusta-west-pkwy.html\""
            },
            {
                "__id": 154,
                "address_city": "\"Newport News\"",
                "address_full": "\"439-A Oriana Rd\"",
                "address_postcode": "\"23608\"",
                "address_state": "\"Virginia\"",
                "latitude": 37.1328265,
                "longitude": -76.5356553,
                "name": "\"Citi Trends\"",
                "opening_hours": "\"Mo 11:00-20:00; Tu 11:00-20:00; We 11:00-20:00; Th 11:00-20:00; Fr 10:00-21:00; Sa 10:00-21:00; Su 12:00-18:00\"",
                "phone": "\"(757) 898-1768\"",
                "shop": "\"clothes\"",
                "website": "\"https://locations.cititrends.com/va/newport-news/439-a-oriana-rd.html\""
            },
            {
                "__id": 238,
                "address_city": "\"Palm Bay\"",
                "address_full": "\"4700 Babcock St Ne\"",
                "address_postcode": "\"32905\"",
                "address_state": "\"Florida\"",
                "latitude": 28.0343084,
                "longitude": -80.6230424,
                "name": "\"Citi Trends\"",
                "opening_hours": "\"Mo 11:00-20:00; Tu 11:00-20:00; We 11:00-20:00; Th 11:00-20:00; Fr 10:00-21:00; Sa 10:00-21:00; Su 12:00-18:00\"",
                "phone": "\"(321) 725-4141\"",
                "shop": "\"clothes\"",
                "website": "\"https://locations.cititrends.com/fl/palm-bay/4700-babcock-st-ne.html\""
            },
            {
                "__id": 310,
                "address_city": "\"Marietta\"",
                "address_full": "\"653 S. Marietta Pkwy SE\"",
                "address_postcode": "\"30060\"",
                "address_state": "\"Georgia\"",
                "latitude": 33.9444352,
                "longitude": -84.5327202,
                "name": "\"Citi Trends\"",
                "opening_hours": "\"Mo 11:00-20:00; Tu 11:00-20:00; We 11:00-20:00; Th 11:00-20:00; Fr 10:00-21:00; Sa 10:00-21:00; Su 12:00-18:00\"",
                "phone": "\"(678) 331-1125\"",
                "shop": "\"clothes\"",
                "website": "\"https://locations.cititrends.com/ga/marietta/653-s--marietta-pkwy-se.html\""
            },
            {
                "__id": 269,
                "address_city": "\"Cutler Bay\"",
                "address_full": "\"20505 S. Dixie Hwy\"",
                "address_postcode": "\"33189\"",
                "address_state": "\"Florida\"",
                "latitude": 25.577795612964028,
                "longitude": -80.36798882209018,
                "name": "\"Citi Trends\"",
                "opening_hours": "\"Mo 11:00-20:00; Tu 11:00-20:00; We 11:00-20:00; Th 11:00-20:00; Fr 10:00-20:00; Sa 10:00-20:00; Su 12:00-18:00\"",
                "phone": "\"(305) 252-6144\"",
                "shop": "\"clothes\"",
                "website": "\"https://locations.cititrends.com/fl/cutler-bay/20505-s--dixie-hwy.html\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "nandos_bh": {
        "__id": 71,
        "id": "nandos_bh",
        "name": "Nando's Bahrain",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'website']",
        "rowLength": 5,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763499,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of nandos_bh locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 4,
                "address_city": "\"Manama\"",
                "address_country": "\"BH\"",
                "address_full": "\"Manama, seef mall, Seef area, Seef Area, Manama, Manama, 428\"",
                "address_state": "\"Manama\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 26.233162,
                "longitude": 50.537973,
                "name": "\"Nando's Seef mall (Dine In, Delivery & Take Out)\"",
                "website": "\"https://www.nandos.com.bh/eat/restaurant/nandos-seef-mall\""
            },
            {
                "__id": 3,
                "address_city": "\"Muharrak\"",
                "address_country": "\"BH\"",
                "address_full": "\"muharrak government, lagoon, Amwaj islands, lagoon, amwaj islands, Muharrak, Amwaj, 251\"",
                "address_state": "\"Amwaj\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 26.288934,
                "longitude": 50.664843,
                "name": "\"Nando's Amwaj (Dine In, Delivery & Take Out)\"",
                "website": "\"https://www.nandos.com.bh/eat/restaurant/nandos-amwaj\""
            },
            {
                "__id": 1,
                "address_city": "\"Saar Area\"",
                "address_country": "\"BH\"",
                "address_full": "\"The Atrium Mall, Block 527, Saar Area, Saar\"",
                "address_state": "\"Saar\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 26.175685,
                "longitude": 50.48378,
                "name": "\"Nando's Atrium Mall (Dine In, Delivery & Take Out)\"",
                "website": "\"https://www.nandos.com.bh/eat/restaurant/nandos-atrium-mall\""
            },
            {
                "__id": 5,
                "address_city": "\"Manama\"",
                "address_country": "\"BH\"",
                "address_full": "\"Nando's, Shabab Avenue, Manama, Shabab avenue, Juffair, Manama, Manama, 340\"",
                "address_state": "\"Manama\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 26.211213,
                "longitude": 50.608403,
                "name": "\"Nando's Juffair (Dine In, Delivery & Take Out)\"",
                "website": "\"https://www.nandos.com.bh/eat/restaurant/nandos-juffair\""
            },
            {
                "__id": 2,
                "address_city": "\"Central Governorate\"",
                "address_country": "\"BH\"",
                "address_full": "\"Shiekh Salman High Way, Zayed Town, Zayed Town, Central Governorate, Isa Town\"",
                "address_state": "\"Isa Town\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 26.167451,
                "longitude": 50.542286,
                "name": "\"Nando's Zayed Town (Closed)\"",
                "website": "\"https://www.nandos.com.bh/eat/restaurant/nandos-zayed-town\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "mcdonalds_pt": {
        "__id": 72,
        "id": "mcdonalds_pt",
        "name": "McDonald's Portugal",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'image', 'latitude', 'longitude', 'name', 'opening_hours', 'street_address', 'website']",
        "rowLength": 191,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763504,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of mcdonalds_pt locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 4,
                "address_city": "\"Beja\"",
                "address_country": "\"PT\"",
                "address_postcode": "\"7800-000\"",
                "address_state": "\"BEJA\"",
                "image": "\"https://www.mcdonalds.pt/images/logo.png\"",
                "latitude": 38.003839,
                "longitude": -7.866342,
                "name": "\"McDonald&#39;s Beja\"",
                "opening_hours": "\"Mo 02:00-05:30; Tu 08:00-08:00\"",
                "street_address": "\"Av.Fialho de Almeida e a EN 122\"",
                "website": "\"https://www.mcdonalds.pt/restaurantes/beja\""
            },
            {
                "__id": 97,
                "address_city": "\"Merc&#234;s\"",
                "address_country": "\"PT\"",
                "address_postcode": "\"2725-083\"",
                "address_state": "\"MEM MARTINS\"",
                "image": "\"https://www.mcdonalds.pt/images/logo.png\"",
                "latitude": 38.800628,
                "longitude": -9.326003,
                "name": "\"McDonald&#39;s Merc&#234;s\"",
                "opening_hours": "\"Mo 02:00-05:30; Tu 08:00-08:00\"",
                "street_address": "\"Estrada das Merc&#234;s, Limites do lugar de Algueir&#227;o\"",
                "website": "\"https://www.mcdonalds.pt/restaurantes/merces\""
            },
            {
                "__id": 93,
                "address_city": "\"Odivelas\"",
                "address_country": "\"PT\"",
                "address_postcode": "\"2675-000\"",
                "address_state": "\"ODIVELAS\"",
                "image": "\"https://www.mcdonalds.pt/images/logo.png\"",
                "latitude": 38.794578,
                "longitude": -9.181378,
                "name": "\"McDonald&#39;s Odivelas\"",
                "opening_hours": "\"Mo 02:00-05:30; Tu 08:00-08:00\"",
                "street_address": "\"Piscinas Municipais de Odivelas,Av.Prof.Dr.Augusto Abreu Lopes\"",
                "website": "\"https://www.mcdonalds.pt/restaurantes/odivelas\""
            },
            {
                "__id": 71,
                "address_city": "\"Rossio\"",
                "address_country": "\"PT\"",
                "address_postcode": "\"1100-202\"",
                "address_state": "\"LISBOA\"",
                "image": "\"https://www.mcdonalds.pt/images/logo.png\"",
                "latitude": 38.714325,
                "longitude": -9.139022,
                "name": "\"McDonald&#39;s Rossio\"",
                "opening_hours": "\"Mo 02:00-05:30; Tu 08:00-08:00\"",
                "street_address": "\"Pra&#231;a D.Pedro IV, 81-83\"",
                "website": "\"https://www.mcdonalds.pt/restaurantes/rossio\""
            },
            {
                "__id": 49,
                "address_city": "\"Viana do Castelo Drive\"",
                "address_country": "\"PT\"",
                "address_postcode": "\"4900-470\"",
                "address_state": "\"VIANA DO CASTELO\"",
                "image": "\"https://www.mcdonalds.pt/images/logo.png\"",
                "latitude": 41.701508,
                "longitude": -8.819006,
                "name": "\"McDonald&#39;s Viana do Castelo Drive\"",
                "opening_hours": "\"Mo 02:00-05:30; Tu 08:00-08:00\"",
                "street_address": "\"Estrada da Papanata, n&#186; 535\"",
                "website": "\"https://www.mcdonalds.pt/restaurantes/viana-do-castelo-drive\""
            },
            {
                "__id": 32,
                "address_city": "\"Paredes\"",
                "address_country": "\"PT\"",
                "address_postcode": "\"4580-104\"",
                "address_state": "\"\"",
                "image": "\"https://www.mcdonalds.pt/images/logo.png\"",
                "latitude": 41.202583,
                "longitude": -8.326918,
                "name": "\"McDonald&#39;s Paredes\"",
                "opening_hours": "\"Mo 02:00-05:30; Tu 08:00-08:00\"",
                "street_address": "\"Av Dr. Francisco S&#225; Carneiro\"",
                "website": "\"https://www.mcdonalds.pt/restaurantes/paredes\""
            },
            {
                "__id": 1,
                "address_city": "\"Birre\"",
                "address_country": "\"PT\"",
                "address_postcode": "\"2750-217\"",
                "address_state": "\"CASCAIS\"",
                "image": "\"https://www.mcdonalds.pt/images/logo.png\"",
                "latitude": 38.715251,
                "longitude": -9.446847,
                "name": "\"McDonald&#39;s Birre\"",
                "opening_hours": "\"Mo 02:00-05:30; Tu 08:00-08:00\"",
                "street_address": "\"Rua de Birre, 581\"",
                "website": "\"https://www.mcdonalds.pt/restaurantes/birre\""
            },
            {
                "__id": 125,
                "address_city": "\"Gondomar (Parque Nascente)\"",
                "address_country": "\"PT\"",
                "address_postcode": "\"4435\"",
                "address_state": "\"GONDOMAR\"",
                "image": "\"https://www.mcdonalds.pt/images/logo.png\"",
                "latitude": 41.175225,
                "longitude": -8.565569,
                "name": "\"McDonald&#39;s Gondomar (Parque Nascente)\"",
                "opening_hours": "\"Mo 02:00-05:30; Tu 08:00-08:00\"",
                "street_address": "\"Galeria Comercial Parque Nascente,Lj.5.21,Estrada Ext.Circunvala&#231;&#227;o\"",
                "website": "\"https://www.mcdonalds.pt/restaurantes/gondomar-parque-nascente\""
            },
            {
                "__id": 107,
                "address_city": "\"Maia Centro\"",
                "address_country": "\"PT\"",
                "address_postcode": "\"4470-142\"",
                "address_state": "\"MAIA\"",
                "image": "\"https://www.mcdonalds.pt/images/logo.png\"",
                "latitude": 41.226514,
                "longitude": -8.618231,
                "name": "\"McDonald&#39;s Maia Centro\"",
                "opening_hours": "\"Mo 02:00-05:30; Tu 08:00-08:00\"",
                "street_address": "\"Gaveto da Via Perif&#233;rica c/ a Av. Santos de Leite, Lugar de Catassol\"",
                "website": "\"https://www.mcdonalds.pt/restaurantes/maia-centro\""
            },
            {
                "__id": 25,
                "address_city": "\"Nazar&#233;\"",
                "address_country": "\"PT\"",
                "address_postcode": "\"2450-138\"",
                "address_state": "\"Nazar&#233;\"",
                "image": "\"https://www.mcdonalds.pt/images/logo.png\"",
                "latitude": 39.613638,
                "longitude": -9.061262,
                "name": "\"McDonald&#39;s Nazar&#233;\"",
                "opening_hours": "\"Mo 02:00-05:30; Tu 08:00-08:00\"",
                "street_address": "\"Av Nogent Sur Marne Estrada Nacional 242 Km 32+500\"",
                "website": "\"https://www.mcdonalds.pt/restaurantes/nazare\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "nandos": {
        "__id": 73,
        "id": "nandos",
        "name": "Nando's",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'street_address', 'website']",
        "rowLength": 711,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763512,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of nandos locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 325,
                "address_city": "\"London\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"EC4M 9AF\"",
                "address_state": "\"London\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 51.513612,
                "longitude": -0.095528,
                "name": "\"One New Change\"",
                "opening_hours": "\"\"",
                "phone": "\"\"",
                "street_address": "\"Cheapside, Unit SU48, Level 1 South, One New Change, EC4M 9AF,\"",
                "website": "\"https://www.nandos.co.uk/restaurants/one-new-change\""
            },
            {
                "__id": 51,
                "address_city": "\"\"",
                "address_country": "\"NZ\"",
                "address_postcode": "\"1072\"",
                "address_state": "\"AUCK\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": -36.8901,
                "longitude": 174.8339,
                "name": "\"Lunn Avenue\"",
                "opening_hours": "\"\"",
                "phone": "\"(09) 574 6976\"",
                "street_address": "\"71 Lunn Ave\"",
                "website": "\"https://www.nandos.co.nz/restaurants/auck/lunn-avenue\""
            },
            {
                "__id": 62,
                "address_city": "\"\"",
                "address_country": "\"AU\"",
                "address_postcode": "\"3076\"",
                "address_state": "\"VIC\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": -37.6555599,
                "longitude": 145.0185896,
                "name": "\"EPPING PLAZA\"",
                "opening_hours": "\"\"",
                "phone": "\"1300 626 367\"",
                "street_address": "\"Shop R2,571-583 High St, Epping Plaza\"",
                "website": "\"https://www.nandos.com.au/restaurants/vic/epping-plaza\""
            },
            {
                "__id": 689,
                "address_city": "\"Mississauga\"",
                "address_country": "\"CA\"",
                "address_postcode": "\"L4W0E6\"",
                "address_state": "\"ON\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 43.6377365,
                "longitude": -79.6233435,
                "name": "\"Dixie\"",
                "opening_hours": "\"\"",
                "phone": "\"19052387448\"",
                "street_address": "\"5101 Dixie Rd\"",
                "website": "\"https://www.nandos.ca/find/dixie/\""
            },
            {
                "__id": 485,
                "address_city": "\"Essex\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"CM20 1AN\"",
                "address_state": "\"Essex\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 51.767659,
                "longitude": 0.094683,
                "name": "\"Harlow\"",
                "opening_hours": "\"Mo-Su 11:30-22:00\"",
                "phone": "\"\"",
                "street_address": "\"Third Ave, Unit A3.3, The Water Gardens, Harlow, CM20 1AN,\"",
                "website": "\"https://www.nandos.co.uk/restaurants/harlow\""
            },
            {
                "__id": 109,
                "address_city": "\"\"",
                "address_country": "\"AU\"",
                "address_postcode": "\"3023\"",
                "address_state": "\"VIC\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": -37.7640736,
                "longitude": 144.7756704,
                "name": "\"BRIMBANK SHOPPING CENTRE\"",
                "opening_hours": "\"\"",
                "phone": "\"1300 626 367\"",
                "street_address": "\"Shop F 009, Brimbank Shopping centre\"",
                "website": "\"https://www.nandos.com.au/restaurants/vic/brimbank-shopping-centre\""
            },
            {
                "__id": 245,
                "address_city": "\"Manchester\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"M1 1LU\"",
                "address_state": "\"Manchester\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 53.481507,
                "longitude": -2.236438,
                "name": "\"Manchester - Piccadilly\"",
                "opening_hours": "\"Mo-Su 11:30-22:00\"",
                "phone": "\"\"",
                "street_address": "\"27 Piccadilly, M1 1LU,\"",
                "website": "\"https://www.nandos.co.uk/restaurants/manchester-piccadilly\""
            },
            {
                "__id": 164,
                "address_city": "\"\"",
                "address_country": "\"AU\"",
                "address_postcode": "\"6062\"",
                "address_state": "\"WA\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": -31.8967285,
                "longitude": 115.895351,
                "name": "\"MORLEY\"",
                "opening_hours": "\"\"",
                "phone": "\"1300 626 367\"",
                "street_address": "\"Shop 1, 60 Russell Street\"",
                "website": "\"https://www.nandos.com.au/restaurants/wa/morley\""
            },
            {
                "__id": 627,
                "address_city": "\"Nottinghamshire\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"NG18 1BU\"",
                "address_state": "\"Nottinghamshire\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 53.135533,
                "longitude": -1.200726,
                "name": "\"Mansfield\"",
                "opening_hours": "\"Mo-Su 11:30-22:00\"",
                "phone": "\"\"",
                "street_address": "\"Nottingham Rd, Unit 1, Mansfield Leisure Park, Mansfield, NG18 1BU,\"",
                "website": "\"https://www.nandos.co.uk/restaurants/mansfield\""
            },
            {
                "__id": 450,
                "address_city": "\"London\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"SE6 4HW\"",
                "address_state": "\"London\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 51.447787,
                "longitude": -0.018286,
                "name": "\"Catford\"",
                "opening_hours": "\"Mo-Su 11:30-22:00\"",
                "phone": "\"\"",
                "street_address": "\"74-76 Rushey Green, Catford, SE6 4HW,\"",
                "website": "\"https://www.nandos.co.uk/restaurants/catford\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "nandos_my": {
        "__id": 74,
        "id": "nandos_my",
        "name": "Nando's Malaysia",
        "columnNames": "['address_country', 'address_full', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'website']",
        "rowLength": 74,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763516,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of nandos_my locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 74,
                "address_country": "\"MY\"",
                "address_full": "\"LG-049, The Mall, Mid Valley Southkey, Persiaran Southkey 1, Southkey, Johor Bharu, Johor 80180 \"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 1.501537,
                "longitude": 103.777159,
                "name": "\"Mid Valley Southkey\"",
                "website": "\"https://nandos.com.my/restaurants/mid-valley-southkey/\""
            },
            {
                "__id": 54,
                "address_country": "\"MY\"",
                "address_full": "\"Lot No. LG-92, Concourse Queensbay Mall,  Persiaran Bayan Indah, Bayan Lepas, Penang 11900\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 5.333198,
                "longitude": 100.306493,
                "name": "\"Queensbay Mall\"",
                "website": "\"https://nandos.com.my/restaurants/nandos-queensbay-mall/\""
            },
            {
                "__id": 2,
                "address_country": "\"MY\"",
                "address_full": "\"GF-01A & GF-01B, Ground Floor, East Coast Mall, Jalan Putra Square 6, Putra Square, Kuantan, Pahang 25200\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 3.818763,
                "longitude": 103.326836,
                "name": "\"East Coast Mall\"",
                "website": "\"https://nandos.com.my/restaurants/nandos-east-coast-mall/\""
            },
            {
                "__id": 20,
                "address_country": "\"MY\"",
                "address_full": "\"L1-66 Level 1, Mines Shopping Fair, Jln Dulang, Mines Resorts City, Seri Kembangan Selangor 43300\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 3.028535,
                "longitude": 101.718416,
                "name": "\"The Mines\"",
                "website": "\"https://nandos.com.my/restaurants/nandos-the-mines/\""
            },
            {
                "__id": 61,
                "address_country": "\"MY\"",
                "address_full": "\"1.0.28 \u2013 1.0.31, Ground Floor, Pearl Point Shopping Mall, Batu 5, Jalan Klang Lama, Kuala Lumpur 58000\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 3.085001,
                "longitude": 101.673353,
                "name": "\"Pearl Point\"",
                "website": "\"https://nandos.com.my/restaurants/nandos-pearl-point/\""
            },
            {
                "__id": 23,
                "address_country": "\"MY\"",
                "address_full": "\"Lot LG 10B-C, Lower Ground Floor, Subang Parade, No.5, Jalan SS16/1, Subang Jaya,  Selangor 47500\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 3.082243,
                "longitude": 101.585915,
                "name": "\"Subang Parade\"",
                "website": "\"https://nandos.com.my/restaurants/nandos-subang-parade/\""
            },
            {
                "__id": 15,
                "address_country": "\"MY\"",
                "address_full": "\"Lot G19, Ground Floor, Bangsar Village,  Bangsar Baru, Bangsar Baru Kuala Lumpur 59100 \"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 3.129204,
                "longitude": 101.671127,
                "name": "\"Bangsar Village\"",
                "website": "\"https://nandos.com.my/restaurants/nandos-bangsar-village/\""
            },
            {
                "__id": 57,
                "address_country": "\"MY\"",
                "address_full": "\"Unit G-13, Ground Floor, 1 Mon\u2019t Kiara, No.1, Jalan Kiara, Mon\u2019t Kiara, Kuala Lumpur 50480\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 3.1654,
                "longitude": 101.65277,
                "name": "\"1 Mont Kiara\"",
                "website": "\"https://nandos.com.my/restaurants/nandos-1-mont-kiara/\""
            },
            {
                "__id": 46,
                "address_country": "\"MY\"",
                "address_full": "\"S-11 Second Floor, AEON Mall Kuching Central, Min Chu Garden, Kuching, Sarawak 93200\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 1.526921,
                "longitude": 110.337195,
                "name": "\"AEON Kuching\"",
                "website": "\"https://nandos.com.my/restaurants/nandos-aeon-kuching/\""
            },
            {
                "__id": 44,
                "address_country": "\"MY\"",
                "address_full": "\"G-051, G-051s & G-065B Ground Floor Vivacity Megamall Jalan Wan Alwi, Kuching 93350\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 1.527032,
                "longitude": 110.36861,
                "name": "\"Vivacity\"",
                "website": "\"https://nandos.com.my/restaurants/nandos-vivacity/\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "mcdonalds_fr": {
        "__id": 75,
        "id": "mcdonalds_fr",
        "name": "McDonald's France",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'takeaway']",
        "rowLength": 1512,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669759977,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of mcdonalds_fr locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 1402,
                "address_city": "\"SAINT-PIERRE-D'IRUBE\"",
                "address_country": "\"FR\"",
                "address_full": "\"McDonald's Centre Commercial Ik\u00e9a Bayonne Ametzondo, 43160 Avenue du Portou\"",
                "address_postcode": "\"64990\"",
                "address_state": "\"Sud Ouest\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 43.4841252,
                "longitude": -1.445818499999973,
                "name": "\"McDonalds Bayonne Ik\u00e9a\"",
                "opening_hours": "\"Mo-Sa 09:00-22:00\"",
                "phone": "\"0559635434\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 1108,
                "address_city": "\"MONTAUBAN\"",
                "address_country": "\"FR\"",
                "address_full": "\"McDonald's C.Cial Albasud ZAC de\"",
                "address_postcode": "\"82000\"",
                "address_state": "\"Sud Ouest\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 43.98839569091797,
                "longitude": 1.3348339796066284,
                "name": "\"McDonalds MONTAUBAN\"",
                "opening_hours": "\"Mo-Su 10:30-23:00\"",
                "phone": "\"0563021722\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 475,
                "address_city": "\"SAINT-ANDRE LES VERGERS\"",
                "address_country": "\"FR\"",
                "address_full": "\"Avenue d'Echenilly\"",
                "address_postcode": "\"10120\"",
                "address_state": "\"Nord Est\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 48.27892424,
                "longitude": 4.042751155,
                "name": "\"McDonalds TROYES SAINT ANDRE\"",
                "opening_hours": "\"Mo-Th 10:00-23:30; Fr-Sa 10:00-24:00; Su 10:00-23:30\"",
                "phone": "\"0325710102\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 463,
                "address_city": "\"VILLETELLE\"",
                "address_country": "\"FR\"",
                "address_full": "\"Aire d'Ambrussum Sud - Autoroute, A9\"",
                "address_postcode": "\"34400\"",
                "address_state": "\"Sud Est\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 43.71233,
                "longitude": 4.132669,
                "name": "\"McDonalds A9 MONTPELLIER AMBRUSSUM\"",
                "opening_hours": "\"Mo-Su 10:00-22:00\"",
                "phone": "\"0499546589\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 1044,
                "address_city": "\"TOULOUSE\"",
                "address_country": "\"FR\"",
                "address_full": "\"McDonald's Boulevard Gabriel Koenigs\"",
                "address_postcode": "\"31300\"",
                "address_state": "\"Sud Ouest\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 43.59509,
                "longitude": 1.41937,
                "name": "\"McDonalds Toulouse Koenigs\"",
                "opening_hours": "\"Mo-Su 09:00-01:00\"",
                "phone": "\"0562483636\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 992,
                "address_city": "\"ORLEANS\"",
                "address_country": "\"FR\"",
                "address_full": "\"McDonald's 3-5, Boulevard de Verdun\"",
                "address_postcode": "\"45000\"",
                "address_state": "\"Ouest\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 47.9063681,
                "longitude": 1.90466709999998,
                "name": "\"McDonalds ORLEANS CENTRE VILLE\"",
                "opening_hours": "\"\"",
                "phone": "\"0238810598\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 25,
                "address_city": "\"DORLISHEIM\"",
                "address_country": "\"FR\"",
                "address_full": "\"Zone Commerciale 'Les Mercuriales'\"",
                "address_postcode": "\"67120\"",
                "address_state": "\"Nord Est\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 48.524872,
                "longitude": 7.497917,
                "name": "\"McDonalds DORLISHEIM\"",
                "opening_hours": "\"Mo-Su 10:30-24:00\"",
                "phone": "\"0388384600\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 690,
                "address_city": "\"PONT-SAINTE-MAXENCE\"",
                "address_country": "\"FR\"",
                "address_full": "\"1243 avenue d'Auvelais\"",
                "address_postcode": "\"60700\"",
                "address_state": "\"Nord Est\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 49.318835,
                "longitude": 2.605229,
                "name": "\"McDonalds PONT SAINTE MAXENCE\"",
                "opening_hours": "\"Mo-Su 08:00-24:00\"",
                "phone": "\"0344310486\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 835,
                "address_city": "\"VILLEJUIF\"",
                "address_country": "\"FR\"",
                "address_full": "\"123 Boulevard Maxime Gorki\"",
                "address_postcode": "\"94800\"",
                "address_state": "\"Ile de France (Banlieue)\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 48.7919,
                "longitude": 2.3698,
                "name": "\"McDonalds VILLEJUIF MAXIME GORKI\"",
                "opening_hours": "\"Mo-Su 10:00-24:00\"",
                "phone": "\"0142110230\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 912,
                "address_city": "\"PARIS\"",
                "address_country": "\"FR\"",
                "address_full": "\"2 Rue d'Orsel\"",
                "address_postcode": "\"75018\"",
                "address_state": "\"Ile de France (Paris)\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 48.88396794,
                "longitude": 2.346459561,
                "name": "\"McDonalds PARIS ORSEL CLIGNANCOURT\"",
                "opening_hours": "\"Mo-Th 09:00-23:00; Fr-Sa 09:00-24:00; Su 09:00-23:00\"",
                "phone": "\"0142546527\"",
                "takeaway": "\"yes\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "mcdonalds_jp": {
        "__id": 76,
        "id": "mcdonalds_jp",
        "name": "McDonald's Japan",
        "columnNames": "['address_country', 'address_full', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'takeaway']",
        "rowLength": 2951,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763592,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of mcdonalds_jp locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 1984,
                "address_country": "\"JP\"",
                "address_full": "\"\u4eac\u90fd\u5e9c\u4eac\u90fd\u5e02\u4f0f\u898b\u533a\u918d\u9190\u9ad8\u7551\u753a\uff11\uff0d\uff13\uff17\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 34.951014204643876,
                "longitude": 135.81111557297234,
                "name": "\"\u918d\u9190\u30a2\u30eb\u30d7\u30e9\u30b6\u5e97\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 2344,
                "address_country": "\"JP\"",
                "address_full": "\"\u5175\u5eab\u770c\u5c3c\u5d0e\u5e02\u585a\u53e3\u672c\u753a\uff14\uff0d\uff18\uff0d\uff11\u3064\u304b\u3057\u3093\uff33\uff23\u5317\u9928\uff11\uff26\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 34.760848425973535,
                "longitude": 135.4198795252472,
                "name": "\"\u3064\u304b\u3057\u3093\u5e97\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 1550,
                "address_country": "\"JP\"",
                "address_full": "\"\u5c90\u961c\u770c\u571f\u5c90\u5e02\u571f\u5c90\u6d25\u753a\u571f\u5c90\u53e3\uff11\uff13\uff17\uff12\uff0d\uff11\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 35.34857209894062,
                "longitude": 137.15331430821834,
                "name": "\"\u30a4\u30aa\u30f3\u30e2\u30fc\u30eb\u571f\u5c90\u5e97\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 1680,
                "address_country": "\"JP\"",
                "address_full": "\"\u611b\u77e5\u770c\u540d\u53e4\u5c4b\u5e02\u5b88\u5c71\u533a\u85ae\u7530\u753a\uff19\uff10\uff16\uff0d\uff12\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 35.200239185322225,
                "longitude": 136.99300034899352,
                "name": "\"\u5927\u68ee\u30a4\u30f3\u30bf\u30fc\u5357\u5e97\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 2842,
                "address_country": "\"JP\"",
                "address_full": "\"\u718a\u672c\u770c\u83ca\u6c60\u90e1\u83ca\u967d\u753a\u5927\u5b57\u6d25\u4e45\u793c\uff12\uff14\uff13\uff13\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 32.8603392484407,
                "longitude": 130.8009790569503,
                "name": "\"\u83ca\u967d\u30d0\u30a4\u30d1\u30b9\u5e97\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 500,
                "address_country": "\"JP\"",
                "address_full": "\"\u57fc\u7389\u770c\u6240\u6ca2\u5e02\u5317\u539f\u753a\uff11\uff13\uff17\uff13\uff0d\uff12\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 35.80706371986257,
                "longitude": 139.48031302234543,
                "name": "\"\u6240\u6ca2\u5317\u539f\u753a\u5e97\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 101,
                "address_country": "\"JP\"",
                "address_full": "\"\u9752\u68ee\u770c\u3080\u3064\u5e02\u4e2d\u592e\uff12\uff0d\uff12\uff19\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 41.2878855565401,
                "longitude": 141.1849456520892,
                "name": "\"\u3080\u3064\u4e2d\u592e\u5e97\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 2660,
                "address_country": "\"JP\"",
                "address_full": "\"\u9ad8\u77e5\u770c\u9ad8\u77e5\u5e02\u5e2f\u5c4b\u753a\uff11\uff0d\uff11\uff13\uff0d\uff12\uff13\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 33.56040987967345,
                "longitude": 133.53876471351018,
                "name": "\"\u5e2f\u5c4b\u753a\u5e97\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 1530,
                "address_country": "\"JP\"",
                "address_full": "\"\u5c90\u961c\u770c\u5927\u57a3\u5e02\u5916\u91ce\uff12\uff0d\uff11\uff10\uff10\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 35.337200163117735,
                "longitude": 136.61285439627036,
                "name": "\"\u30a4\u30aa\u30f3\u30e2\u30fc\u30eb\u5927\u57a3\u5e97\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 993,
                "address_country": "\"JP\"",
                "address_full": "\"\u6771\u4eac\u90fd\u6e2f\u533a\u829d\u6d66\uff11\uff0d\uff12\uff0d\uff12\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 35.64962272710978,
                "longitude": 139.75653193653784,
                "name": "\"\u829d\u6d66\u30b7\u30fc\u30d0\u30f3\u30b9\u5e97\"",
                "takeaway": "\"yes\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "mcdonalds_qstore": {
        "__id": 77,
        "id": "mcdonalds_qstore",
        "name": "McDonald's Q-Store",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'street_address', 'takeaway']",
        "rowLength": 1346,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763603,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of mcdonalds_qstore locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 684,
                "address_city": "\"Helensvale\"",
                "address_country": "\"AU\"",
                "address_postcode": "\"4212\"",
                "address_state": "\"QLD\"",
                "amenity": "\"\"",
                "cuisine": "\"\"",
                "latitude": -27.905674113194454,
                "longitude": 153.31814378499985,
                "name": "\"HELENSVALE QLD\"",
                "opening_hours": "\"24/7\"",
                "phone": "\"07 5573 6288\"",
                "street_address": "\"14-20 Siganto Drive\"",
                "takeaway": "\"\""
            },
            {
                "__id": 545,
                "address_city": "\"Geelong North\"",
                "address_country": "\"AU\"",
                "address_postcode": "\"3220\"",
                "address_state": "\"VIC\"",
                "amenity": "\"\"",
                "cuisine": "\"\"",
                "latitude": -38.102473260076906,
                "longitude": 144.3536674976349,
                "name": "\"GEELONG NORTH VIC\"",
                "opening_hours": "\"24/7\"",
                "phone": "\"03 5272 2350\"",
                "street_address": "\"400 Melbourne Road\"",
                "takeaway": "\"\""
            },
            {
                "__id": 86,
                "address_city": "\"\"",
                "address_country": "\"EG\"",
                "address_postcode": "\"\"",
                "address_state": "\"\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 30.825824,
                "longitude": 29.0020943,
                "name": "\"Porto Marina - rth Coast\"",
                "opening_hours": "\"\"",
                "phone": "\"\"",
                "street_address": "\"-\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 270,
                "address_city": "\"OTOROHANGA\"",
                "address_country": "\"NZ\"",
                "address_postcode": "\"3900\"",
                "address_state": "\"NEW ZEALAND\"",
                "amenity": "\"\"",
                "cuisine": "\"\"",
                "latitude": -38.189215,
                "longitude": 175.21016,
                "name": "\"OTOROHANGA\"",
                "opening_hours": "\"Su-Sa :-:\"",
                "phone": "\"07 8738525\"",
                "street_address": "\"117 MANIAPOTO STREET\"",
                "takeaway": "\"\""
            },
            {
                "__id": 1032,
                "address_city": "\"Browns Plains\"",
                "address_country": "\"AU\"",
                "address_postcode": "\"4118\"",
                "address_state": "\"QLD\"",
                "amenity": "\"\"",
                "cuisine": "\"\"",
                "latitude": -27.662550000336342,
                "longitude": 153.04065666235738,
                "name": "\"GRAND PLAZA QLD\"",
                "opening_hours": "\"Su 10:00-16:00; Mo-We 07:00-18:00; Th 07:00-21:00; Fr-Sa 07:00-18:00\"",
                "phone": "\"07 3809 0300\"",
                "street_address": "\"Grand Plaza Shopping Centre, Browns Plains Rd\"",
                "takeaway": "\"\""
            },
            {
                "__id": 191,
                "address_city": "\"WHANGAREI\"",
                "address_country": "\"NZ\"",
                "address_postcode": "\"\"",
                "address_state": "\"NEW ZEALAND\"",
                "amenity": "\"\"",
                "cuisine": "\"\"",
                "latitude": -35.683719,
                "longitude": 174.300722,
                "name": "\"KAMO\"",
                "opening_hours": "\"24/7\"",
                "phone": "\"09-4354133\"",
                "street_address": "\"511 KAMO ROAD\"",
                "takeaway": "\"\""
            },
            {
                "__id": 549,
                "address_city": "\"Keysborough\"",
                "address_country": "\"AU\"",
                "address_postcode": "\"3175\"",
                "address_state": "\"VIC\"",
                "amenity": "\"\"",
                "cuisine": "\"\"",
                "latitude": -37.99488175413914,
                "longitude": 145.1746916770935,
                "name": "\"KEYSBOROUGH VIC\"",
                "opening_hours": "\"24/7\"",
                "phone": "\"03 9798 8448\"",
                "street_address": "\"Cnr Cheltenham & Perry Roads\"",
                "takeaway": "\"\""
            },
            {
                "__id": 563,
                "address_city": "\"Capalaba\"",
                "address_country": "\"AU\"",
                "address_postcode": "\"4157\"",
                "address_state": "\"QLD\"",
                "amenity": "\"\"",
                "cuisine": "\"\"",
                "latitude": -27.521873521248214,
                "longitude": 153.19846361875534,
                "name": "\"CAPALABA QLD\"",
                "opening_hours": "\"24/7\"",
                "phone": "\"07 3245 6266\"",
                "street_address": "\"203 Old Cleveland Road\"",
                "takeaway": "\"\""
            },
            {
                "__id": 1244,
                "address_city": "\"Ripley\"",
                "address_country": "\"AU\"",
                "address_postcode": "\"4306\"",
                "address_state": "\"QLD\"",
                "amenity": "\"\"",
                "cuisine": "\"\"",
                "latitude": -27.6559488,
                "longitude": 152.7803067,
                "name": "\"Ripley QLD\"",
                "opening_hours": "\"24/7\"",
                "phone": "\"07 3288 6709\"",
                "street_address": "\"332 Ripley Road\"",
                "takeaway": "\"\""
            },
            {
                "__id": 225,
                "address_city": "\"SHIRLEY\"",
                "address_country": "\"NZ\"",
                "address_postcode": "\"\"",
                "address_state": "\"NEW ZEALAND\"",
                "amenity": "\"\"",
                "cuisine": "\"\"",
                "latitude": -43.507887,
                "longitude": 172.663025,
                "name": "\"THE PALMS\"",
                "opening_hours": "\"Su-Sa 08:30-18:30\"",
                "phone": "\"03-3850383\"",
                "street_address": "\"CNR MARSHLANDS & NEW BRIGHTON RD\"",
                "takeaway": "\"\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "nandos_qa": {
        "__id": 78,
        "id": "nandos_qa",
        "name": "Nando's Qatar",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'website']",
        "rowLength": 8,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763654,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of nandos_qa locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 7,
                "address_city": "\"\"",
                "address_country": "\"QA\"",
                "address_full": "\"Ahmad Bin Ali St, Doha\"",
                "address_state": "\"Doha\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 25.3103442655953,
                "longitude": 51.4962207606048,
                "name": "\"Bin Omran \"",
                "website": "\"https://www.nandos.qa/eat/restaurant/bin-omran\""
            },
            {
                "__id": 2,
                "address_city": "\"\"",
                "address_country": "\"QA\"",
                "address_full": "\"Midmac Flyeover , Salwa Road , Doha\"",
                "address_state": "\"Doha\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 25.265871,
                "longitude": 51.4993018999999,
                "name": "\"Salwa Road \"",
                "website": "\"https://www.nandos.qa/eat/restaurant/salwa-road\""
            },
            {
                "__id": 1,
                "address_city": "\"Qatar\"",
                "address_country": "\"QA\"",
                "address_full": "\"Al Wukair St Gate 1, Ground floor, Ezdam mall, Qatar, Doha\"",
                "address_state": "\"Doha\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 25.1706643,
                "longitude": 51.5882143,
                "name": "\"Ezdan Mall Wakra\"",
                "website": "\"https://www.nandos.qa/eat/restaurant/Ezdan-Mall-Wakra\""
            },
            {
                "__id": 4,
                "address_city": "\"\"",
                "address_country": "\"QA\"",
                "address_full": "\"Al Emadi Building, C-Ring Road, Doha\"",
                "address_state": "\"Doha\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 25.2625336681826,
                "longitude": 51.5257980809509,
                "name": "\"Financial Square \"",
                "website": "\"https://www.nandos.qa/eat/restaurant/financial-square\""
            },
            {
                "__id": 6,
                "address_city": "\"Um Slal Mohammad\"",
                "address_country": "\"QA\"",
                "address_full": "\"South Casual Dining Area, North Road , Um Slal Mohammad, Doha\"",
                "address_state": "\"Doha\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 25.3853906,
                "longitude": 51.4413171999999,
                "name": "\"Doha Festival City \"",
                "website": "\"https://www.nandos.qa/eat/restaurant/doha-festival-city\""
            },
            {
                "__id": 5,
                "address_city": "\"Doha\"",
                "address_country": "\"QA\"",
                "address_full": "\"Andalucia Way, Medina Centrale , Doha, Doha\"",
                "address_state": "\"Doha\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 25.3853906,
                "longitude": 51.4413171999999,
                "name": "\"The Pearl Qatar \"",
                "website": "\"https://www.nandos.qa/eat/restaurant/the-pearl-qatar\""
            },
            {
                "__id": 8,
                "address_city": "\"\"",
                "address_country": "\"QA\"",
                "address_full": "\"3rd Floor, West Bay, Doha\"",
                "address_state": "\"Doha\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 25.3251978115674,
                "longitude": 51.5298087777709,
                "name": "\"City Center Doha Mall\"",
                "website": "\"https://www.nandos.qa/eat/restaurant/city-centre-doha-mall\""
            },
            {
                "__id": 3,
                "address_city": "\"\"",
                "address_country": "\"QA\"",
                "address_full": "\"Al Waab Rd , Doha\"",
                "address_state": "\"Doha\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 25.2559523,
                "longitude": 51.4390035,
                "name": "\"Hyatt Plaza Mall \"",
                "website": "\"https://www.nandos.qa/eat/restaurant/hyatt-plaza-mall\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "mcdonalds_my": {
        "__id": 79,
        "id": "mcdonalds_my",
        "name": "McDonald's Malaysia",
        "columnNames": "['address_country', 'address_full', 'contact_email', 'latitude', 'longitude', 'name', 'phone', 'website']",
        "rowLength": 329,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763655,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of mcdonalds_my locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 308,
                "address_country": "\"MY\"",
                "address_full": "\"Lot 16843 (Lots 4581, 9210 and 9212), Block 11, MTLD Jalan Canna, 93150, Kuching, Sarawak, Malaysia\"",
                "contact_email": "\"s322@my.mcd.com\"",
                "latitude": 1.513061,
                "longitude": 110.387951,
                "name": "\"McDonald's Tabuan Jaya DT\"",
                "phone": "\"082-360395\"",
                "website": "\"https://www.mcdonalds.com.my\""
            },
            {
                "__id": 197,
                "address_country": "\"MY\"",
                "address_full": "\"Plot No. 2, Pusat Perniagaan Oren, Diatas Lot 10067 (Sebahagian Lot Lama: 35), Jalan Bagan Ajam Seksyen 1, 13000 Bandar Butterworth, Seberang Perai Utara Pulau Pinang.\"",
                "contact_email": "\"s388@my.mcd.com\"",
                "latitude": 5.440361,
                "longitude": 100.381611,
                "name": "\"McDonald's Bagan Ajam DT\"",
                "phone": "\"04-3137858\"",
                "website": "\"https://www.mcdonalds.com.my\""
            },
            {
                "__id": 315,
                "address_country": "\"MY\"",
                "address_full": "\"Lot No. L1L04 - L1L06, Labuan Airport, Arrival Hall 1, Terminal Building, 80569, WP Labuan, Malaysia\"",
                "contact_email": "\"s229@my.mcd.com\"",
                "latitude": 5.29399,
                "longitude": 115.249723,
                "name": "\"McDonald's Labuan Airport\"",
                "phone": "\"087-428890\"",
                "website": "\"https://www.mcdonalds.com.my\""
            },
            {
                "__id": 182,
                "address_country": "\"MY\"",
                "address_full": "\"Part of Lot 16793, Mukim 12, Barat Daya, 11900, Pulau Pinang, Malaysia\"",
                "contact_email": "\"s352@my.mcd.com\"",
                "latitude": 5.302556,
                "longitude": 100.261412,
                "name": "\"McDonald's Setia Pearl DT\"",
                "phone": "\"04-2962806\"",
                "website": "\"https://www.mcdonalds.com.my\""
            },
            {
                "__id": 127,
                "address_country": "\"MY\"",
                "address_full": "\"Lot PT. 35810, Persiaran Putra Indah, Putra Heights, Mukim Damansara, Daerah Petaling, 47650, Selangor, Malaysia\"",
                "contact_email": "\"s358@my.mcd.com\"",
                "latitude": 2.99728,
                "longitude": 101.570971,
                "name": "\"McDonald's Shell Putra Height DT\"",
                "phone": "\"03-51035347\"",
                "website": "\"https://www.mcdonalds.com.my\""
            },
            {
                "__id": 41,
                "address_country": "\"MY\"",
                "address_full": "\"Lot B, Jalan Cecawi 6/18A, Kota Damansara, 47810, Petaling Jaya, Selangor, Malaysia\"",
                "contact_email": "\"s178@my.mcd.com\"",
                "latitude": 3.160465,
                "longitude": 101.574277,
                "name": "\"McDonald's Kota Damansara DT\"",
                "phone": "\"03-61561180\"",
                "website": "\"https://www.mcdonalds.com.my\""
            },
            {
                "__id": 119,
                "address_country": "\"MY\"",
                "address_full": "\"Lot No G-08, Ground Floor, Pearl Point Shopping Complex, 5th Mile, Old Klang Road, 58000 Kuala Lumpur                                                                 \"",
                "contact_email": "\"S385@my.mcd.com\"",
                "latitude": 3.084915,
                "longitude": 101.673568,
                "name": "\"McDonald's Pearl Point DT\"",
                "phone": "\"03-79710196\"",
                "website": "\"https://www.mcdonalds.com.my\""
            },
            {
                "__id": 310,
                "address_country": "\"MY\"",
                "address_full": "\"Lots 119 & 120, Block 32, Kemena Land District, Jalan Tun Hussein Onn, 97000, Bintulu, Sarawak, Malaysia\"",
                "contact_email": "\"s306@my.mcd.com\"",
                "latitude": 3.19756,
                "longitude": 113.079632,
                "name": "\"McDonald's Bintulu DT \"",
                "phone": "\"086-312124\"",
                "website": "\"https://www.mcdonalds.com.my\""
            },
            {
                "__id": 223,
                "address_country": "\"MY\"",
                "address_full": "\"Lot PT  13668, Mukim Batu Berendam, Melaka Tengah, 75350, Hang Tuah Jaya, Melaka, Malaysia\"",
                "contact_email": "\"s274@my.mcd.com\"",
                "latitude": 2.251997,
                "longitude": 102.2459,
                "name": "\"McDonald's Batu Berendam DT\"",
                "phone": "\"06-3173461\"",
                "website": "\"https://www.mcdonalds.com.my\""
            },
            {
                "__id": 259,
                "address_country": "\"MY\"",
                "address_full": "\"Jalan Kampung Bakau, 01000, Kangar, Perlis, Malaysia\"",
                "contact_email": "\"s336@my.mcd.com\"",
                "latitude": 6.432622,
                "longitude": 100.196158,
                "name": "\"McDonald's Kangar DT\"",
                "phone": "\"04-9779336\"",
                "website": "\"https://www.mcdonalds.com.my\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "nandos_sg": {
        "__id": 80,
        "id": "nandos_sg",
        "name": "Nando's Singapore",
        "columnNames": "['address_country', 'address_full', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'website']",
        "rowLength": 6,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763655,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of nandos_sg locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 1,
                "address_country": "\"SG\"",
                "address_full": "\"#01-85/87 Bugis Junction 200 Victoria Street Singapore 188021\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 1.29889,
                "longitude": 103.854761,
                "name": "\"Bugis Junction\"",
                "website": "\"https://www.nandos.com.sg/restaurants/bugis-junction/\""
            },
            {
                "__id": 2,
                "address_country": "\"SG\"",
                "address_full": "\"#01-46 Tampines Mall 4 Tampines Central 5 Singapore 529510\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 1.352492,
                "longitude": 103.944344,
                "name": "\"Tampines Mall\"",
                "website": "\"https://www.nandos.com.sg/restaurants/tampines-mall/\""
            },
            {
                "__id": 3,
                "address_country": "\"SG\"",
                "address_full": "\"#04 -10/11 Plaza Singapura 68 Orchard Road Singapore 238839\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 1.30106,
                "longitude": 103.845131,
                "name": "\"Plaza Singapura\"",
                "website": "\"https://www.nandos.com.sg/restaurants/plaza-singapura/\""
            },
            {
                "__id": 4,
                "address_country": "\"SG\"",
                "address_full": "\"#B1-09 The Star Vista 1 Vista Exchange Green Singapore 138617\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 1.307063,
                "longitude": 103.788191,
                "name": "\"The Star Vista\"",
                "website": "\"https://www.nandos.com.sg/restaurants/the-star-vista/\""
            },
            {
                "__id": 5,
                "address_country": "\"SG\"",
                "address_full": "\"#01-115/117 Tanglin Mall 163 Tanglin Road Singapore 247933\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 1.305029,
                "longitude": 103.823545,
                "name": "\"Tanglin Mall\"",
                "website": "\"https://www.nandos.com.sg/restaurants/tanglin-mall/\""
            },
            {
                "__id": 6,
                "address_country": "\"SG\"",
                "address_full": "\"JP1 #02-32 Jurong Point, 1 Jurong West Central 2 Singapore 648886\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 1.3400554084893312,
                "longitude": 103.70637040489959,
                "name": "\"Jurong Point\"",
                "website": "\"https://www.nandos.com.sg/restaurants/jurong-point/\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "nandos_ae": {
        "__id": 81,
        "id": "nandos_ae",
        "name": "Nandos United Arab Emirates",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'phone', 'website']",
        "rowLength": 19,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763656,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of nandos_ae locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 19,
                "address_city": "\"\"",
                "address_country": "\"AE\"",
                "address_full": "\"AL Khan Corniche St, Block D, Al Qasba, Al Khan, Sharjah\"",
                "address_state": "\"Sharjah\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 25.321744,
                "longitude": 55.37601,
                "name": "\"Nando's Al Qasba \"",
                "phone": "\"+276 556 6033\"",
                "website": "\"https://www.nandos.ae/eat/restaurant/nandos-al-qasba\""
            },
            {
                "__id": 4,
                "address_city": "\"\"",
                "address_country": "\"AE\"",
                "address_full": "\"Hamdan Bin Mohammed St, Level 3, World Trade Center, Al Danah, Abu Dhabi\"",
                "address_state": "\"Abu Dhabi\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 24.48766,
                "longitude": 54.357305,
                "name": "\"Nando's The Mall - World Trade Center\"",
                "phone": "\"+272 631 0002\"",
                "website": "\"https://www.nandos.ae/eat/restaurant/world-trade-centre\""
            },
            {
                "__id": 7,
                "address_city": "\"Al Barsha 1\"",
                "address_country": "\"AE\"",
                "address_full": "\"Sheikh Zayed Rd, Level 1, Mall of the Emirates, Al Barsha 1, Dubai\"",
                "address_state": "\"Dubai\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 25.198981,
                "longitude": 55.278698,
                "name": "\"Nando's Mall of the Emirates \"",
                "phone": "\"+274 334 3479\"",
                "website": "\"https://www.nandos.ae/eat/restaurant/nandos-mall-of-the-emirates\""
            },
            {
                "__id": 5,
                "address_city": "\"\"",
                "address_country": "\"AE\"",
                "address_full": "\"Othman Bin Affan St, Level 1, Al Ain Mall, Al Murabaa, Al Ain\"",
                "address_state": "\"Al Ain\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 24.222823,
                "longitude": 55.782113,
                "name": "\"Nando's Al Ain Mall\"",
                "phone": "\"+273 737 7187\"",
                "website": "\"https://www.nandos.ae/eat/restaurant/al-ain\""
            },
            {
                "__id": 12,
                "address_city": "\"\"",
                "address_country": "\"AE\"",
                "address_full": "\"Crescent Dr, Ground Floor, Dubai Festival City Mall, Dubai Festival City, Dubai\"",
                "address_state": "\"Dubai\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 25.22106,
                "longitude": 55.351481,
                "name": "\"Nando's Dubai Festival City Mall \"",
                "phone": "\"+274 286 5868\"",
                "website": "\"https://www.nandos.ae/eat/restaurant/dubai-festival-city\""
            },
            {
                "__id": 18,
                "address_city": "\"Al Barsha South 4\"",
                "address_country": "\"AE\"",
                "address_full": "\"Street 21, JVC District 11, Ground Floor, Circle Mall, , Hadaeq Mohammed Bin Rashid, Al Barsha South 4, Dubai\"",
                "address_state": "\"Dubai\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 25.0659,
                "longitude": 55.2164,
                "name": "\"Nando's Circle Mall\"",
                "phone": "\"\"",
                "website": "\"https://www.nandos.ae/eat/restaurant/nandos-circle-mall\""
            },
            {
                "__id": 13,
                "address_city": "\"\"",
                "address_country": "\"AE\"",
                "address_full": "\"Sheikh Mohammed Bin Zayed Rd, Level 1, City Centre Mirdif, Mirdif, Dubai\"",
                "address_state": "\"Dubai\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 25.214287,
                "longitude": 55.407502,
                "name": "\"Nando's City Centre Mirdiff \"",
                "phone": "\"+274 284 4088\"",
                "website": "\"https://www.nandos.ae/eat/restaurant/mirdif-city-centre\""
            },
            {
                "__id": 1,
                "address_city": "\"\"",
                "address_country": "\"AE\"",
                "address_full": "\"Sheikh Mohammad Bin Salem Rd, Level 1, Al Hamra Mall, Al Jazirah Al Hamra, Ras Al Khaimah\"",
                "address_state": "\"Ras Al Khaimah\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 25.683021,
                "longitude": 55.781642,
                "name": "\"Nando's Al Hamra Mall \"",
                "phone": "\"+277 243 8848\"",
                "website": "\"https://www.nandos.ae/eat/restaurant/nandos-al-hamra-mall\""
            },
            {
                "__id": 9,
                "address_city": "\"\"",
                "address_country": "\"AE\"",
                "address_full": "\"First Al Khail St, Ground Floor, Jumeirah Islands Pavilion, Jumeirah Islands, Dubai\"",
                "address_state": "\"Dubai\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 25.06447,
                "longitude": 55.147546,
                "name": "\"Nando's Jumeirah Islands Pavilion\"",
                "phone": "\"+274 368 5522\"",
                "website": "\"https://www.nandos.ae/eat/restaurant/jumeirah-lake-towers\""
            },
            {
                "__id": 6,
                "address_city": "\"\"",
                "address_country": "\"AE\"",
                "address_full": "\"Financial Centre Rd, Lower Ground floor, Waterfront Promenade, The Dubai Mall, Downtown, Dubai\"",
                "address_state": "\"Dubai\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 25.198981,
                "longitude": 55.278698,
                "name": "\"Nando's The Dubai Mall \"",
                "phone": "\"\"",
                "website": "\"https://www.nandos.ae/eat/restaurant/dubai-mall\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "mcdonalds_ma": {
        "__id": 82,
        "id": "mcdonalds_ma",
        "name": "McDonald's Macedonia",
        "columnNames": "['address_city', 'address_country', 'address_full', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'phone', 'takeaway']",
        "rowLength": 58,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763657,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of mcdonalds_ma locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 15,
                "address_city": "\"\"",
                "address_country": "\"MA\"",
                "address_full": "\"Espagne Gare ferroviaire MADINA TANGER\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 35.7716,
                "longitude": -5.7862,
                "name": "\"McDonald's Tanger LGV\"",
                "phone": "\"\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 33,
                "address_city": "\"Mohamm\u00e9dia\"",
                "address_country": "\"MA\"",
                "address_full": "\"Centre Commercial Marjane Route de Rabat\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 33.7087,
                "longitude": -7.35056,
                "name": "\"McDonald's Mohamm\u00e9dia\"",
                "phone": "\"0523 32 91 82\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 35,
                "address_city": "\"Nador\"",
                "address_country": "\"MA\"",
                "address_full": "\"Corniche Sidi Ali\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 35.1863,
                "longitude": -2.92635,
                "name": "\"McDonald's Nador\"",
                "phone": "\"05 36 32 02 27\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 20,
                "address_city": "\"Casablanca\"",
                "address_country": "\"MA\"",
                "address_full": "\"Centre commercial Tachfine, boulevard Ibn Tachfine\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 33.5871,
                "longitude": -7.59247,
                "name": "\"McDonald's Ibn Tachfine\"",
                "phone": "\"\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 55,
                "address_city": "\"\"",
                "address_country": "\"MA\"",
                "address_full": "\"Aba Chouaib Doukkali, Marjane Derb Sultan Casablanca\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 33.5629,
                "longitude": -7.59426,
                "name": "\"McDonald's Derb Sultan\"",
                "phone": "\"05 22 83 68 57\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 14,
                "address_city": "\"\"",
                "address_country": "\"MA\"",
                "address_full": "\"s Drive Gare LGV Rabat Agdal, Avenue Mohamed Triki Gare LGV Agdal, Rabat\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 34.0015,
                "longitude": -6.8575,
                "name": "\"McDonald's Gare Rabat Agdal\"",
                "phone": "\"\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 19,
                "address_city": "\"\"",
                "address_country": "\"MA\"",
                "address_full": "\"Avenue Prince Moulay Abdellah, Tanger, Maroc\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 35.7794,
                "longitude": -5.80967,
                "name": "\"McDonald's Tanger Porte\"",
                "phone": "\"\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 44,
                "address_city": "\"Mekn\u00e8s\"",
                "address_country": "\"MA\"",
                "address_full": "\"av des Far\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 33.8958,
                "longitude": -5.5552,
                "name": "\"McDonald's Mekn\u00e8s\"",
                "phone": "\"05 35 40 42 83\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 49,
                "address_city": "\"Rabat\"",
                "address_country": "\"MA\"",
                "address_full": "\"Marjane\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 33.9535,
                "longitude": -6.85469,
                "name": "\"McDonald's Hay Riad\"",
                "phone": "\"05 37 71 03 17\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 28,
                "address_city": "\"Avenue Mohammed VI , 25000 Khouribga\"",
                "address_country": "\"MA\"",
                "address_full": "\"Centre commercial Marjane Khouribga\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 32.8871,
                "longitude": -6.90755,
                "name": "\"McDonald's Khouribga\"",
                "phone": "\"06 61 94 71 36\"",
                "takeaway": "\"yes\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "mcdonalds_il": {
        "__id": 83,
        "id": "mcdonalds_il",
        "name": "McDonald's",
        "columnNames": "['address_country', 'address_full', 'name', 'phone']",
        "rowLength": 224,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763658,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of mcdonalds_il locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 54,
                "address_country": "\"IL\"",
                "address_full": "\"\u05db\u05ea\u05d5\u05d1\u05ea: \u05e9\u05d3\u05e8\u05d5\u05ea \u05e0\u05d9\u05dd 2, \u05e8\u05d0\u05e9\u05d5\u05df \u05dc\u05e6\u05d9\u05d5\u05df\"",
                "name": "\"$pop_title\"",
                "phone": "\"054-3360232\""
            },
            {
                "__id": 133,
                "address_country": "\"IL\"",
                "address_full": "\"\u05db\u05ea\u05d5\u05d1\u05ea: \u05d0\u05d2\u05d5\u05d3\u05ea \u05e1\u05e4\u05d5\u05e8\u05d8 \u05d1\u05d9\u05ea\u05e8 1, \u05d9\u05e8\u05d5\u05e9\u05dc\u05d9\u05dd\"",
                "name": "\"\u05d0\u05d9\u05ea\u05d5\u05e8 \u05de\u05e1\u05e2\u05d3\u05d4\"",
                "phone": "\"054-3360512\""
            },
            {
                "__id": 27,
                "address_country": "\"IL\"",
                "address_full": "\"\u05db\u05ea\u05d5\u05d1\u05ea: \u05de\u05e8\u05db\u05d6 \u05e7\u05e0\u05d9\u05d5\u05ea \u05d7\u05d5\u05e6\u05d5\u05ea \u05e9\u05e4\u05d9\u05d9\u05dd, \u05e7\u05d9\u05d1\u05d5\u05e5 \u05e9\u05e4\u05d9\u05d9\u05dd\"",
                "name": "\"\u05d0\u05d9\u05ea\u05d5\u05e8 \u05de\u05e1\u05e2\u05d3\u05d4\"",
                "phone": "\"052-9440055\""
            },
            {
                "__id": 4,
                "address_country": "\"IL\"",
                "address_full": "\"\u05db\u05ea\u05d5\u05d1\u05ea: \u05d1\u05d9\u05ea \u05d7\u05d5\u05dc\u05d9\u05dd \u05d1\u05d9\u05dc\u05d9\u05e0\u05e1\u05d5\u05df-\u05d6'\u05d1\u05d5\u05d8\u05d9\u05e0\u05e1\u05e7\u05d9 39, \u05e4\u05ea\u05d7 \u05ea\u05e7\u05d5\u05d5\u05d4\"",
                "name": "\"$pop_title\"",
                "phone": "\"052-9440136\""
            },
            {
                "__id": 182,
                "address_country": "\"IL\"",
                "address_full": "\"\u05db\u05ea\u05d5\u05d1\u05ea: \u05de\u05d7'\u05dc 1, \u05d0\u05e9\u05e7\u05dc\u05d5\u05df\"",
                "name": "\"$pop_title\"",
                "phone": "\"052-9440207\""
            },
            {
                "__id": 70,
                "address_country": "\"IL\"",
                "address_full": "\"\u05db\u05ea\u05d5\u05d1\u05ea: \u05e7\u05e0\u05d9\u05d5\u05df \u05d4\u05e1\u05d9\u05d8\u05d9, \u05e9\u05d3\u05e8\u05d5\u05ea \u05de\u05e0\u05d7\u05dd \u05d1\u05d2\u05d9\u05df, \u05d0\u05e9\u05d3\u05d5\u05d3\"",
                "name": "\"$pop_title\"",
                "phone": "\"052-9440040\""
            },
            {
                "__id": 195,
                "address_country": "\"IL\"",
                "address_full": "\"\u05db\u05ea\u05d5\u05d1\u05ea: \u05e8\u05d7' \u05d4\u05d1\u05e0\u05d0\u05d9 \u05e4\u05d9\u05e0\u05ea \u05d9\u05e8\u05d5\u05e9\u05dc\u05d9\u05dd, \u05d7\u05d5\u05dc\u05d5\u05df\"",
                "name": "\"\u05e4\u05d5\u05e4\u05d0\u05e3\"",
                "phone": "\"054-3360225\""
            },
            {
                "__id": 163,
                "address_country": "\"IL\"",
                "address_full": "\"\u05db\u05ea\u05d5\u05d1\u05ea: \u05e9\u05d3\u05e8\u05d5\u05ea \u05d9\u05e8\u05d5\u05e9\u05dc\u05d9\u05dd 15, \u05ea\u05dc \u05d0\u05d1\u05d9\u05d1-\u05d9\u05e4\u05d5\"",
                "name": "\"$pop_title\"",
                "phone": "\"052-9440074\""
            },
            {
                "__id": 162,
                "address_country": "\"IL\"",
                "address_full": "\"\u05db\u05ea\u05d5\u05d1\u05ea: \u05d4\u05d9\u05e8\u05e7\u05d5\u05df 81, \u05ea\u05dc \u05d0\u05d1\u05d9\u05d1-\u05d9\u05e4\u05d5\"",
                "name": "\"$pop_title\"",
                "phone": "\"052-9440026\""
            },
            {
                "__id": 193,
                "address_country": "\"IL\"",
                "address_full": "\"\u05db\u05ea\u05d5\u05d1\u05ea: \u05d0\u05d1\u05d9\u05d6\u05d5\u05d4\u05e8 8, \u05d9\u05e8\u05d5\u05e9\u05dc\u05d9\u05dd\"",
                "name": "\"$pop_title\"",
                "phone": "\"052-9440131\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "nandos_om": {
        "__id": 84,
        "id": "nandos_om",
        "name": "Nando's Oman",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'website']",
        "rowLength": 4,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763659,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of nandos_om locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 4,
                "address_city": "\"Seeb\"",
                "address_country": "\"OM\"",
                "address_full": "\"Muscat City Centre, East Wing, Mawaleh, Seeb, Seeb, Muscat, 112\"",
                "address_state": "\"Muscat\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 23.614328,
                "longitude": 58.545284,
                "name": "\"Nando's Muscat City Centre \"",
                "website": "\"https://www.nandosoman.com/eat/restaurant/Nandos-Muscat-City-Centre\""
            },
            {
                "__id": 1,
                "address_city": "\"Salalah\"",
                "address_country": "\"OM\"",
                "address_full": "\"Ground Floor, Salalah Grand Mall, North Dahariz, Salalah, Dhofar Governorate\"",
                "address_state": "\"Dhofar Governorate\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 29.96474,
                "longitude": 31.26981,
                "name": "\"Nando's Salalah Grand Mall \"",
                "website": "\"https://www.nandosoman.com/eat/restaurant/nandos-salalah-grand-mall-\""
            },
            {
                "__id": 3,
                "address_city": "\"Seeb\"",
                "address_country": "\"OM\"",
                "address_full": "\"The Walk, Street No. 6, Muscat 138, Oman, Seeb, Seeb, Muscat, 138\"",
                "address_state": "\"Muscat\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 23.614328,
                "longitude": 58.545284,
                "name": "\"Nando's Al Mouj \"",
                "website": "\"https://www.nandosoman.com/eat/restaurant/nandos-al-mouj\""
            },
            {
                "__id": 2,
                "address_city": "\"Muscat\"",
                "address_country": "\"OM\"",
                "address_full": "\"Al Qurum Complex, Shop No. 9697, Building No. 6144, Block No. 206, Way 667, Al Qurum, Al Qurum, Muscat, Muscat, 112\"",
                "address_state": "\"Muscat\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 23.614328,
                "longitude": 58.4975,
                "name": "\"Nando's Al Qurum Complex \"",
                "website": "\"https://www.nandosoman.com/eat/restaurant/nandos-al-qurum-complex\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "mcdonalds_be": {
        "__id": 85,
        "id": "mcdonalds_be",
        "name": "McDonald's Belgium",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'latitude', 'longitude', 'name', 'phone', 'street_address', 'website']",
        "rowLength": 106,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763659,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of mcdonalds_be locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 2,
                "address_city": "\"Aartselaar\"",
                "address_country": "\"BE\"",
                "address_postcode": "\"2630\"",
                "latitude": 51.142697,
                "longitude": 4.378428,
                "name": "\"AARTSELAAR (Drive)\"",
                "phone": "\"03 877 23 00\"",
                "street_address": "\"Antwerpsesteenweg\"",
                "website": "\"https://www.mcdonalds.be/en/restaurants/aartselaar-drive\""
            },
            {
                "__id": 44,
                "address_city": "\"Halle\"",
                "address_country": "\"BE\"",
                "address_postcode": "\"1500\"",
                "latitude": 50.736696,
                "longitude": 4.230877,
                "name": "\"HALLE (Drive)\"",
                "phone": "\"02 356 99 08\"",
                "street_address": "\"De Maeghtlaan\"",
                "website": "\"https://www.mcdonalds.be/en/restaurants/halle-drive\""
            },
            {
                "__id": 88,
                "address_city": "\"Overijse\"",
                "address_country": "\"BE\"",
                "address_postcode": "\"3090\"",
                "latitude": 50.786259,
                "longitude": 4.500376,
                "name": "\"OVERIJSE (Drive)\"",
                "phone": "\"02 688 00 06\"",
                "street_address": "\"Brusselsesteenweg\"",
                "website": "\"https://www.mcdonalds.be/en/restaurants/overijse-drive\""
            },
            {
                "__id": 32,
                "address_city": "\"Dour\"",
                "address_country": "\"BE\"",
                "address_postcode": "\"7370\"",
                "latitude": 50.397861,
                "longitude": 3.765911,
                "name": "\"DOUR (drive)\"",
                "phone": "\"065 62 15 51 \"",
                "street_address": "\"Rue de la Grande Veine\"",
                "website": "\"https://www.mcdonalds.be/en/restaurants/dour-drive\""
            },
            {
                "__id": 31,
                "address_city": "\"Bruxelles\"",
                "address_country": "\"BE\"",
                "address_postcode": "\"1030\"",
                "latitude": 50.879616,
                "longitude": 4.374294,
                "name": "\"DOCKS (Mall)\"",
                "phone": "\"02 215 85 00 \"",
                "street_address": "\"Avenue Lambermont\"",
                "website": "\"https://www.mcdonalds.be/en/restaurants/docks-mall\""
            },
            {
                "__id": 75,
                "address_city": "\"Mol\"",
                "address_country": "\"BE\"",
                "address_postcode": "\"2400\"",
                "latitude": 51.178825,
                "longitude": 5.124255,
                "name": "\"MOL (Drive)\"",
                "phone": "\"014 32 07 85\"",
                "street_address": "\"Kruisven\"",
                "website": "\"https://www.mcdonalds.be/en/restaurants/mol-drive\""
            },
            {
                "__id": 72,
                "address_city": "\"Merksem Bredabaan\"",
                "address_country": "\"BE\"",
                "address_postcode": "\"2170\"",
                "latitude": 51.265956,
                "longitude": 4.462415,
                "name": "\"MERKSEM BREDABAAN (Drive)\"",
                "phone": "\"03 644 03 34\"",
                "street_address": "\"Bredabaan\"",
                "website": "\"https://www.mcdonalds.be/en/restaurants/merksem-bredabaan-drive\""
            },
            {
                "__id": 95,
                "address_city": "\"Sint-Pieters-Leeuw\"",
                "address_country": "\"BE\"",
                "address_postcode": "\"1600\"",
                "latitude": 50.783603,
                "longitude": 4.275834,
                "name": "\"SINT-PIETERS-LEEUW Makro (Drive)\"",
                "phone": "\"+32 2 486 85 53\"",
                "street_address": "\"Bergensesteenweg\"",
                "website": "\"https://www.mcdonalds.be/en/restaurants/sint-pieters-leeuw-makro-drive\""
            },
            {
                "__id": 30,
                "address_city": "\"Diest\"",
                "address_country": "\"BE\"",
                "address_postcode": "\"3290\"",
                "latitude": 50.97652,
                "longitude": 5.043604,
                "name": "\"DIEST (Drive)\"",
                "phone": "\"013 32 60 92\"",
                "street_address": "\"Leuvensesteenweg\"",
                "website": "\"https://www.mcdonalds.be/en/restaurants/diest-drive\""
            },
            {
                "__id": 33,
                "address_city": "\"Eke\"",
                "address_country": "\"BE\"",
                "address_postcode": "\"9810\"",
                "latitude": 50.979614,
                "longitude": 3.663668,
                "name": "\"EKE (Drive)\"",
                "phone": "\"09 227 93 81\"",
                "street_address": "\"Savaanstraat\"",
                "website": "\"https://www.mcdonalds.be/en/restaurants/eke-drive\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "nandos_in": {
        "__id": 86,
        "id": "nandos_in",
        "name": "Nandos India",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'website']",
        "rowLength": 12,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763663,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of nandos_in locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 10,
                "address_city": "\"New Delhi \"",
                "address_country": "\"IN\"",
                "address_full": "\"Astha Kunj Road, Upper Ground floor, Epicuria Mall, Nehru Place, New Delhi , Delhi, 110019\"",
                "address_state": "\"Delhi\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 28.551333,
                "longitude": 77.251885,
                "name": "\"Nando's Epicuria (Delivery & Take Out only)\"",
                "website": "\"https://www.nandosindia.com/eat/restaurant/nandos-epicuria\""
            },
            {
                "__id": 3,
                "address_city": "\"Bengaluru\"",
                "address_country": "\"IN\"",
                "address_full": "\"Whitefield Main Road, Unit UG-15, Upper ground floor, Phoenix Market city, Krishnarajapura, Bengaluru, Karnataka, 560048\"",
                "address_state": "\"Karnataka\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 12.997432,
                "longitude": 77.69654,
                "name": "\"Nando's Phoenix Market City (Delivery & Take Out only)\"",
                "website": "\"https://www.nandosindia.com/eat/restaurant/nandos-phoenix-market-city\""
            },
            {
                "__id": 8,
                "address_city": "\"Bengaluru\"",
                "address_country": "\"IN\"",
                "address_full": "\"Dr Rajkumar Road, Orion Mall, Rajajinagar, Bengaluru, Karnataka, 560055\"",
                "address_state": "\"Karnataka\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 13.01175,
                "longitude": 77.555211,
                "name": "\"Nando's Orion (Delivery & Take Out only)\"",
                "website": "\"https://www.nandosindia.com/eat/restaurant/orion-mall-brigade-gateway\""
            },
            {
                "__id": 7,
                "address_city": "\"New Delhi\"",
                "address_country": "\"IN\"",
                "address_full": "\"Nelson Mandela Marg, Second Floor, DLF Promenade mall, Vasant Kunj, New Delhi, Delhi, 110070\"",
                "address_state": "\"Delhi\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 28.542694,
                "longitude": 77.156739,
                "name": "\"Nando's Promenade, VK  (Delivery & Take Out only)\"",
                "website": "\"https://www.nandosindia.com/eat/restaurant/nandos-promenade\""
            },
            {
                "__id": 9,
                "address_city": "\"Gurugram\"",
                "address_country": "\"IN\"",
                "address_full": "\"Delhi - Jaipur Expy, Shop 12-14, Ground Floor, Cyber City, DLF Phase 2, Gurugram, Haryana , 122022\"",
                "address_state": "\"Haryana \"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 28.495665,
                "longitude": 77.088505,
                "name": "\"Nando's Cyberhub  (Delivery & Take Out only)\"",
                "website": "\"https://www.nandosindia.com/eat/restaurant/nandos-cyberhub\""
            },
            {
                "__id": 5,
                "address_city": "\"Noida\"",
                "address_country": "\"IN\"",
                "address_full": "\"Maharaja Agrasen Marg, DLF Mall of India, Sector 18, Noida, Uttar Pradesh, 201301\"",
                "address_state": "\"Uttar Pradesh\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 28.567378,
                "longitude": 77.321315,
                "name": "\"Nando's Mall of India \"",
                "website": "\"https://www.nandosindia.com/eat/restaurant/nandos-mall-of-india\""
            },
            {
                "__id": 2,
                "address_city": "\"Gurugram\"",
                "address_country": "\"IN\"",
                "address_full": "\"Gurgaon - Delhi Expy, 3rd Floor, Ambience Mall, DLF Phase 3, Gurugram, Haryana, 122022\"",
                "address_state": "\"Haryana\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 28.505103,
                "longitude": 77.096378,
                "name": "\"Nando's Ambience \"",
                "website": "\"https://www.nandosindia.com/eat/restaurant/nandos-ambience\""
            },
            {
                "__id": 6,
                "address_city": "\"\"",
                "address_country": "\"IN\"",
                "address_full": "\"Ginger Hotel Road, Shop 178-178A, Third Floor, Elante Mall, Industrial Area Phase I, Chandigarh, 160002\"",
                "address_state": "\"Chandigarh\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 30.705652,
                "longitude": 76.800742,
                "name": "\"Nando's Elante  (Delivery & Take Out only)\"",
                "website": "\"https://www.nandosindia.com/eat/restaurant/nandos-elante\""
            },
            {
                "__id": 12,
                "address_city": "\"New Delhi \"",
                "address_country": "\"IN\"",
                "address_full": "\"Radial Road Number 1, Block F, Malhotra Building, Connaught Place, New Delhi , Delhi, 110001\"",
                "address_state": "\"Delhi\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 28.6309,
                "longitude": 77.219841,
                "name": "\"Nando's Connaught Place (Delivery & Take Out only)\"",
                "website": "\"https://www.nandosindia.com/eat/restaurant/nandos-connaught-place\""
            },
            {
                "__id": 4,
                "address_city": "\"Bengaluru\"",
                "address_country": "\"IN\"",
                "address_full": "\"12th Main Rd, Indiranagar, Bengaluru, Karnataka, 560008\"",
                "address_state": "\"Karnataka\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": 12.969896,
                "longitude": 77.638042,
                "name": "\"Nando's 12th Main (Delivery & Take Out only)\"",
                "website": "\"https://www.nandosindia.com/eat/restaurant/Nando-12th-main\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "nandos_zm": {
        "__id": 87,
        "id": "nandos_zm",
        "name": "Nando's Zambia",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'phone', 'website']",
        "rowLength": 7,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763664,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of nandos_zm locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 2,
                "address_city": "\"Lusaka\"",
                "address_country": "\"ZM\"",
                "address_full": "\"Great East Road, Shop 27, East park mall, Lusaka, Lusaka Province, 10101\"",
                "address_state": "\"Lusaka Province\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": -15.4068728,
                "longitude": 28.3040369,
                "name": "\"Nando's East Park Mall\"",
                "phone": "\"\"",
                "website": "\"https://www.nandos.co.zm/eat/restaurant/nandos-east-park\""
            },
            {
                "__id": 3,
                "address_city": "\"Lusaka\"",
                "address_country": "\"ZM\"",
                "address_full": "\"Shop No. L07, Manda Hill Mall, Cnr. Great East Road & Manchinchi Road, Lusaka, Lusaka Province, 10101\"",
                "address_state": "\"Lusaka Province\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": -15.398338,
                "longitude": 28.306375,
                "name": "\"Nando's Manda Hill\"",
                "phone": "\"+27962566733\"",
                "website": "\"https://www.nandos.co.zm/eat/restaurant/nandos-manda-hill\""
            },
            {
                "__id": 7,
                "address_city": "\"Ndola\"",
                "address_country": "\"ZM\"",
                "address_full": "\"Shop No. 38, Jacaranda Mall, Cnr. Kabwe & Mushili Road, Ndola, Copperbelt Province\"",
                "address_state": "\"Copperbelt Province\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": -13.004921,
                "longitude": 28.650233,
                "name": "\"Nando's Jacaranda Mall\"",
                "phone": "\"+27962566764\"",
                "website": "\"https://www.nandos.co.zm/eat/restaurant/nandos-jacaranda-mall\""
            },
            {
                "__id": 5,
                "address_city": "\"Lusaka\"",
                "address_country": "\"ZM\"",
                "address_full": "\"Shop No. 7, Centro Mall, Cnr. Kabulonga & Bishops Road, Lusaka, Lusaka Province, 10101\"",
                "address_state": "\"Lusaka Province\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": -15.420827,
                "longitude": 28.342384,
                "name": "\"Nando's Kabulonga\"",
                "phone": "\"+27962566735\"",
                "website": "\"https://www.nandos.co.zm/eat/restaurant/nandos-kabulonga\""
            },
            {
                "__id": 6,
                "address_city": "\"Kitwe\"",
                "address_country": "\"ZM\"",
                "address_full": "\"Shop No. 3, Mukuba Mall, Chiwala Avenue, Kitwe, Copperbelt Province\"",
                "address_state": "\"Copperbelt Province\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": -12.806758,
                "longitude": 28.219517,
                "name": "\"Nando's Mukuba Mall\"",
                "phone": "\"+27962566769\"",
                "website": "\"https://www.nandos.co.zm/eat/restaurant/nandos-mukuba-mall\""
            },
            {
                "__id": 4,
                "address_city": "\"Lusaka\"",
                "address_country": "\"ZM\"",
                "address_full": "\"Engen Fuel station at Cosmopolition Mall, Kafue road, Makeni, Kafue Road, Lusaka, Lusaka Province, 10101\"",
                "address_state": "\"Lusaka Province\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": -15.4551332,
                "longitude": 28.2675007,
                "name": "\"Nando's Makeni\"",
                "phone": "\"+27962566734\"",
                "website": "\"https://www.nandos.co.zm/eat/restaurant/nandos-makeni\""
            },
            {
                "__id": 1,
                "address_city": "\"\"",
                "address_country": "\"ZM\"",
                "address_full": "\"Main street, Ibex Hill, Lusaka Province, 10101\"",
                "address_state": "\"Lusaka Province\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"chicken;portuguese\"",
                "latitude": -15.413540250431417,
                "longitude": 28.370031815341957,
                "name": "\"Nando's Ibex Hill\"",
                "phone": "\"\"",
                "website": "\"https://www.nandos.co.zm/eat/restaurant/nandos-ibex-hill\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "premier_gb": {
        "__id": 88,
        "id": "premier_gb",
        "name": "Premier Great Britain",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'phone', 'shop', 'street_address', 'website']",
        "rowLength": 4008,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763666,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of premier_gb locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 992,
                "address_city": "\"Stockport\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"SK2 6LR\"",
                "address_state": "\"Cheshire\"",
                "latitude": 53.3866598,
                "longitude": -2.1318,
                "name": "\"Premier Heavily Convenience Store\"",
                "phone": "\"01614833212\"",
                "shop": "\"convenience\"",
                "street_address": "\"133a-133b Buxton Road,\"",
                "website": "\"https://www.premier-stores.co.uk/index.php/our-stores/heavily-convenience-store\""
            },
            {
                "__id": 2381,
                "address_city": "\"Oldham\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"OL2 5DY\"",
                "address_state": "\"\"",
                "latitude": 53.5614893,
                "longitude": -2.13345,
                "name": "\"Premier One Stop Convenience Royton\"",
                "phone": "\"07923324002\"",
                "shop": "\"convenience\"",
                "street_address": "\"15 Royleu, Royton\"",
                "website": "\"https://www.premier-stores.co.uk/index.php/our-stores/one-stop-convenience-royton\""
            },
            {
                "__id": 2966,
                "address_city": "\"London\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"SE18 5NQ\"",
                "address_state": "\"\"",
                "latitude": 51.4920447,
                "longitude": 0.0511666,
                "name": "\"Premier TJ's\"",
                "phone": "\"\"",
                "shop": "\"convenience\"",
                "street_address": "\"134 Woolwich Church Street, Woolwich\"",
                "website": "\"https://www.premier-stores.co.uk/index.php/our-stores/tjs\""
            },
            {
                "__id": 737,
                "address_city": "\"Glasgow\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"G32 8UQ\"",
                "address_state": "\"\"",
                "latitude": 55.8436647,
                "longitude": -4.1730285,
                "name": "\"Premier Liquor Barn 2\"",
                "phone": "\"\"",
                "shop": "\"convenience\"",
                "street_address": "\"1043 Tollcross Road,\"",
                "website": "\"https://www.premier-stores.co.uk/index.php/our-stores/liquor-barn-2\""
            },
            {
                "__id": 911,
                "address_city": "\"Blackburn\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"BB1 2AL\"",
                "address_state": "\"Lancashire\"",
                "latitude": 53.7475684,
                "longitude": -2.4461221,
                "name": "\"Premier Intack Mini Market\"",
                "phone": "\"0125454710\"",
                "shop": "\"convenience\"",
                "street_address": "\"331 Accrington Road,\"",
                "website": "\"https://www.premier-stores.co.uk/index.php/our-stores/intack-mini-market\""
            },
            {
                "__id": 1805,
                "address_city": "\"Kilmarnock\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"KA3 2BH\"",
                "address_state": "\"\"",
                "latitude": 55.6261407,
                "longitude": -4.5051104,
                "name": "\"Premier Attwal Bros\"",
                "phone": "\"01563 534503\"",
                "shop": "\"convenience\"",
                "street_address": "\"7/9 Tourhill Road,\"",
                "website": "\"https://www.premier-stores.co.uk/index.php/our-stores/attwal-bros\""
            },
            {
                "__id": 2564,
                "address_city": "\"London\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"SE16 2HB\"",
                "address_state": "\"\"",
                "latitude": 51.4923179,
                "longitude": -0.0595208,
                "name": "\"Premier SP Stores\"",
                "phone": "\"02072372551\"",
                "shop": "\"convenience\"",
                "street_address": "\"300 Southwark Park Road,\"",
                "website": "\"https://www.premier-stores.co.uk/index.php/our-stores/sp-stores\""
            },
            {
                "__id": 3002,
                "address_city": "\"Colchester\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"CO4 5YE\"",
                "address_state": "\"Essex\"",
                "latitude": 51.924487,
                "longitude": 0.91744930000004,
                "name": "\"Premier Cuckoopoint Superstore\"",
                "phone": "\"01206 853066\"",
                "shop": "\"convenience\"",
                "street_address": "\"De Grey Road, Mile End\"",
                "website": "\"https://www.premier-stores.co.uk/index.php/our-stores/cuckoopoint-superstore\""
            },
            {
                "__id": 518,
                "address_city": "\"Glebe Estate\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"CV21 1DP\"",
                "address_state": "\"Newbold Rugby\"",
                "latitude": 52.3865363,
                "longitude": -1.2719869,
                "name": "\"Premier Nagra Supermarket\"",
                "phone": "\"01788 551226\"",
                "shop": "\"convenience\"",
                "street_address": "\"25/29 Norman Road,\"",
                "website": "\"https://www.premier-stores.co.uk/index.php/our-stores/nagra-supermarket\""
            },
            {
                "__id": 1502,
                "address_city": "\"Bournemouth\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"BH8 8UE\"",
                "address_state": "\"Dorset\"",
                "latitude": 50.7348089,
                "longitude": -1.8687857,
                "name": "\"Premier Charminster News Food & Wine\"",
                "phone": "\"01202 291172\"",
                "shop": "\"convenience\"",
                "street_address": "\"21 Charminster Road,\"",
                "website": "\"https://www.premier-stores.co.uk/index.php/our-stores/charminster-news-food-wine\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "mcdonalds_th": {
        "__id": 89,
        "id": "mcdonalds_th",
        "name": "McDonald's Thailand",
        "columnNames": "['address_country', 'address_full', 'latitude', 'longitude', 'name', 'website']",
        "rowLength": 209,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763668,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of mcdonalds_th locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 12,
                "address_country": "\"TH\"",
                "address_full": "\"35/22 Moo 1, Bangkaew, Nakornchaisri, Nakornpathom 73120\"",
                "latitude": 13.80192,
                "longitude": 100.165329,
                "name": "\"PTT NAKORNCHAISRI (DRIVE-THRU)\"",
                "website": "\"https://www.mcdonalds.co.th/storeLocations/5bd8908dacc28a46600999d8\""
            },
            {
                "__id": 18,
                "address_country": "\"TH\"",
                "address_full": "\"No. 9 among the six distribution centers Lotus. supercenter Srinakarin Srinakarin Bangmuangmai Muang Samut Prakan MON 10270\"",
                "latitude": 13.621109,
                "longitude": 100.62084,
                "name": "\"TESCO SRINAKARIN\"",
                "website": "\"https://www.mcdonalds.co.th/storeLocations/5bd51503acc28a4209293d6d\""
            },
            {
                "__id": 103,
                "address_country": "\"TH\"",
                "address_full": "\"No. 45/63 motorway Bangkok - Chonburi (new line) , Moo 7 , Tambon Khao Din Bang Pakong, Chachoengsao 24130 .\"",
                "latitude": 13.539328,
                "longitude": 101.009594,
                "name": "\"PTT MOTORWAY OUTBOUND\"",
                "website": "\"https://www.mcdonalds.co.th/storeLocations/5bd516dbacc28a4209293d71\""
            },
            {
                "__id": 33,
                "address_country": "\"TH\"",
                "address_full": "\"No. 59/1 Moo 5 Tambon Khok Muang Ratchaburi , Ratchaburi 70000 .\"",
                "latitude": 13.575802,
                "longitude": 99.830298,
                "name": "\"RAJBURI PETCHKASEM (DRIVE-THRU)\"",
                "website": "\"https://www.mcdonalds.co.th/storeLocations/5bd8822bacc28a3b59098aba\""
            },
            {
                "__id": 208,
                "address_country": "\"TH\"",
                "address_full": "\"No. 241/4 Market Place Building, Krungthep Kreetha, Room No. KTK04LS105, Floor 1, Krungthep Kreetha Road, Huamark Subdistrict, Bangkapi District, Bangkok 10240\"",
                "latitude": 13.7513339,
                "longitude": 100.6498712,
                "name": "\"MARKET PLACE KRUNGTHEP KREETHA\"",
                "website": "\"https://www.mcdonalds.co.th/storeLocations/615539465840792b2d7bba82\""
            },
            {
                "__id": 150,
                "address_country": "\"TH\"",
                "address_full": "\"No. 474 Tesco Lotus supermarket branches Thalang Road, Cherng Talay, Thalang, Phuket 83110 .\"",
                "latitude": 7.986159,
                "longitude": 98.301737,
                "name": "\"TESCO CHOENGTALAY PHUKET\"",
                "website": "\"https://www.mcdonalds.co.th/storeLocations/5bd65e0bacc28a61ab194efe\""
            },
            {
                "__id": 158,
                "address_country": "\"TH\"",
                "address_full": "\"754 Big C Shopping Center Building Super Center, Nakhon Pathom Branch, Room No. GCR106-107, 1st Floor, Petchakasem Rd. Amphoe Mueang Nakhon Pathom Nakorn Pathom 73000\"",
                "latitude": 13.812654,
                "longitude": 100.072407,
                "name": "\"BIG C NAKORNPATHOM\"",
                "website": "\"https://www.mcdonalds.co.th/storeLocations/5bd89730acc28a3b59098aeb\""
            },
            {
                "__id": 68,
                "address_country": "\"TH\"",
                "address_full": "\"No. 30/21 Moo 4 Navaminthra City Mall Avenue , Room 101 , 1st Floor well Jorakaebua Bangkok 10230 .\"",
                "latitude": 13.833535,
                "longitude": 100.611022,
                "name": "\"NAWAMIN CITY AVENUE (DRIVE-THRU)\"",
                "website": "\"https://www.mcdonalds.co.th/storeLocations/5bd652f6acc28a3cff32a3ea\""
            },
            {
                "__id": 144,
                "address_country": "\"TH\"",
                "address_full": "\"No. 1697, Central Plaza Ladprao , Unit No. G 40-46 , G Floor , Phaholyothin Road, Chatuchak , Bangkok 10900 .\"",
                "latitude": 13.815706,
                "longitude": 100.560543,
                "name": "\"CENTRAL LADPRAO\"",
                "website": "\"https://www.mcdonalds.co.th/storeLocations/5bd513a6acc28a76f33fe1c9\""
            },
            {
                "__id": 92,
                "address_country": "\"TH\"",
                "address_full": "\"No. 275 Moo 1 Petchkasem Rd -Nua, Wattana , Bangkok 10160 .\"",
                "latitude": 13.713718,
                "longitude": 100.407254,
                "name": "\"THE MALL BANGKAE 1\"",
                "website": "\"https://www.mcdonalds.co.th/storeLocations/5bd508e2acc28a7b2f038d7c\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "mcdonalds_at": {
        "__id": 90,
        "id": "mcdonalds_at",
        "name": "McDonald's Austria",
        "columnNames": "['address_country', 'latitude', 'longitude', 'website']",
        "rowLength": 199,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763668,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of mcdonalds_at locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 65,
                "address_country": "\"AT\"",
                "latitude": 47.9722283,
                "longitude": 16.8387696,
                "website": "\"https://www.mcdonalds.at/restaurant/wiener-strasse-116-a-7100-neusiedel-see\""
            },
            {
                "__id": 5,
                "address_country": "\"AT\"",
                "latitude": 48.2187834,
                "longitude": 16.392127,
                "website": "\"https://www.mcdonalds.at/restaurant/praterstern-bahnhof-nord-a-1020-wien\""
            },
            {
                "__id": 165,
                "address_country": "\"AT\"",
                "latitude": 46.7869934,
                "longitude": 13.5230218,
                "website": "\"https://www.mcdonalds.at/restaurant/villacher-str-113-a-9800-spittal-drau\""
            },
            {
                "__id": 170,
                "address_country": "\"AT\"",
                "latitude": 47.1515795,
                "longitude": 9.8146109,
                "website": "\"https://www.mcdonalds.at/restaurant/hauptstrasse-3-a-6706-bludenz-buers\""
            },
            {
                "__id": 78,
                "address_country": "\"AT\"",
                "latitude": 48.2433515,
                "longitude": 14.2353479,
                "website": "\"https://www.mcdonalds.at/restaurant/pluskaufstrasse-7-plus-city-a-4061-pasching\""
            },
            {
                "__id": 199,
                "address_country": "\"AT\"",
                "latitude": 47.0455195,
                "longitude": 15.4305198,
                "website": "\"https://www.mcdonalds.at/restaurant/lauzilgasse-31-a-8020-graz\""
            },
            {
                "__id": 195,
                "address_country": "\"AT\"",
                "latitude": 48.239429,
                "longitude": 16.4311797,
                "website": "\"https://www.mcdonalds.at/restaurant/wagramer-strasse-54-a-1220-wien\""
            },
            {
                "__id": 99,
                "address_country": "\"AT\"",
                "latitude": 48.2162788,
                "longitude": 14.4596653,
                "website": "\"https://www.mcdonalds.at/restaurant/dr-karl-renner-strasse-58-a-4470-enns\""
            },
            {
                "__id": 157,
                "address_country": "\"AT\"",
                "latitude": 48.2067483,
                "longitude": 16.3844952,
                "website": "\"https://www.mcdonalds.at/restaurant/landstrasser-hauptstrasse-1b-ez-wien-mitte-a-1030-wien\""
            },
            {
                "__id": 79,
                "address_country": "\"AT\"",
                "latitude": 48.3596983,
                "longitude": 14.4802901,
                "website": "\"https://www.mcdonalds.at/restaurant/radingdorf-11-a-4210-unterweitersdorf\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "mcdonalds_baltics": {
        "__id": 91,
        "id": "mcdonalds_baltics",
        "name": "McDonald's Baltics",
        "columnNames": "['address_country', 'latitude', 'longitude', 'name', 'website']",
        "rowLength": 40,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763672,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of mcdonalds_baltics locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 11,
                "address_country": "\"EE\"",
                "latitude": 58.3766753,
                "longitude": 26.7324454,
                "name": "\"Turu, Tartu - McDonald's\"",
                "website": "\"https://mcdonalds.ee/asukohad/turu-tartu/\""
            },
            {
                "__id": 38,
                "address_country": "\"LV\"",
                "latitude": 56.959039691099,
                "longitude": 24.036176204681,
                "name": "\"Imanta, R\u012bga - McDonald's\"",
                "website": "\"https://mcdonalds.lv/location/imanta-riga/\""
            },
            {
                "__id": 25,
                "address_country": "\"LT\"",
                "latitude": 54.6525706,
                "longitude": 25.2769025,
                "name": "\"Nordika, Vilnius - McDonald's\"",
                "website": "\"https://mcd.lt/location/nordika-vilnius/\""
            },
            {
                "__id": 17,
                "address_country": "\"EE\"",
                "latitude": 59.4219391,
                "longitude": 24.793867,
                "name": "\"\u00dclemiste, Tallinn - McDonald's\"",
                "website": "\"https://mcdonalds.ee/asukohad/ulemiste-tallinn/\""
            },
            {
                "__id": 21,
                "address_country": "\"LV\"",
                "latitude": 56.92442221162,
                "longitude": 24.174347519875,
                "name": "\"Akropole, R\u012bga - McDonald's\"",
                "website": "\"https://mcdonalds.lv/location/akropole-riga/\""
            },
            {
                "__id": 22,
                "address_country": "\"LT\"",
                "latitude": 54.6994247,
                "longitude": 25.2598126,
                "name": "\"Panorama, Vilnius - McDonald's\"",
                "website": "\"https://mcd.lt/location/panorama-vilnius/\""
            },
            {
                "__id": 9,
                "address_country": "\"EE\"",
                "latitude": 58.3602246,
                "longitude": 26.6759867,
                "name": "\"L\u00f5unakeskus, Tartu - McDonald's\"",
                "website": "\"https://mcdonalds.ee/asukohad/lounakeskus-tartu/\""
            },
            {
                "__id": 3,
                "address_country": "\"EE\"",
                "latitude": 58.4073213,
                "longitude": 24.4926217,
                "name": "\"P\u00e4rnu, P\u00e4rnu - McDonald's\"",
                "website": "\"https://mcdonalds.ee/asukohad/parnu/\""
            },
            {
                "__id": 37,
                "address_country": "\"LV\"",
                "latitude": 56.928819212902,
                "longitude": 24.023237228394,
                "name": "\"J\u016bra, R\u012bga - McDonald's\"",
                "website": "\"https://mcdonalds.lv/location/jura-riga/\""
            },
            {
                "__id": 33,
                "address_country": "\"LV\"",
                "latitude": 56.924018200159,
                "longitude": 24.162583351135,
                "name": "\"Daugava, R\u012bga - McDonald's\"",
                "website": "\"https://mcdonalds.lv/location/daugava-riga/\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "mcdonalds_bg": {
        "__id": 92,
        "id": "mcdonalds_bg",
        "name": "McDonald's Bulgaria",
        "columnNames": "['address_country', 'address_full', 'latitude', 'longitude', 'name', 'phone']",
        "rowLength": 46,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763672,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of mcdonalds_bg locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 1,
                "address_country": "\"BG\"",
                "address_full": "\"\u041c\u043e\u043b \u0413\u0430\u043b\u0435\u0440\u0438\u044f \u0411\u0443\u0440\u0433\u0430\u0441, \u0435\u0442.2\"",
                "latitude": 42.513176130438,
                "longitude": 27.453645335382,
                "name": "\"McDonalds\"",
                "phone": "\"0879/ 477\u00a0407\""
            },
            {
                "__id": 4,
                "address_country": "\"BG\"",
                "address_full": "\"\u041c\u0430\u043a\u0434\u043e\u043d\u0430\u043b\u0434\u0441 \u041c\u0435\u0436\u0434\u0443\u043d\u0430\u0440\u043e\u0434\u0435\u043d \u043f\u044a\u0442 \u041587, 206\u043a\u043c \u043f\u043e\u0441\u043e\u043a\u0430 \u0411\u0443\u0440\u0433\u0430\u0441 \u0434\u043e \u043a.\u0441.\u0420\u0430\u0432\u0434\u0430\"",
                "latitude": 42.659239090637,
                "longitude": 27.687271209662,
                "name": "\"McDonalds\"",
                "phone": "\"0879/ 903 667\""
            },
            {
                "__id": 38,
                "address_country": "\"BG\"",
                "address_full": "\"\u041c\u043e\u043b \u0411\u0443\u0440\u0433\u0430\u0441, \u0431\u0443\u043b. \u201e\u0422\u0440\u0430\u043d\u0441\u043f\u043e\u0440\u0442\u043d\u0430\u201c \u2013 \"",
                "latitude": 42.5308635,
                "longitude": 27.4573043,
                "name": "\"McDonalds\"",
                "phone": "\"0888/ 770 909\""
            },
            {
                "__id": 31,
                "address_country": "\"BG\"",
                "address_full": "\"\u043a.\u043a. \u0421\u043b\u044a\u043d\u0447\u0435\u0432 \u0431\u0440\u044f\u0433, \u0445\u043e\u0442\u0435\u043b \u0427\u0430\u0439\u043a\u0430\"",
                "latitude": 42.703881151429,
                "longitude": 27.718542382031,
                "name": "\"McDonalds\"",
                "phone": "\"0878/ 831 699\""
            },
            {
                "__id": 43,
                "address_country": "\"BG\"",
                "address_full": "\"\u041f\u0430\u0440\u0430\u0434\u0430\u0439\u0441 \u041c\u043e\u043b, \u0431\u0443\u043b. \u201e\u0427\u0435\u0440\u043d\u0438 \u0432\u0440\u044a\u0445\u201c 100 \u2013\"",
                "latitude": 42.6586161,
                "longitude": 23.3158643,
                "name": "\"McDonalds\"",
                "phone": "\"0886/ 818 741\""
            },
            {
                "__id": 6,
                "address_country": "\"BG\"",
                "address_full": "\"279-\u043a\u043c \u043d\u0430 \u043c\u0435\u0436\u0434\u0443\u043d\u0430\u0440\u043e\u0434\u0435\u043d \u043f\u044a\u0442 \u041579, \u0434\u043e \u0441. \u0421\u0442\u0443\u0434\u0435\u043d\u0430 \u2013\"",
                "latitude": 42.53929522115,
                "longitude": 23.129546642303,
                "name": "\"McDonalds\"",
                "phone": "\"0885/ 552 661\""
            },
            {
                "__id": 23,
                "address_country": "\"BG\"",
                "address_full": "\"\u0431\u0443\u043b. \u201e\u0421\u0442\u0435\u0444\u0430\u043d \u0421\u0442\u0430\u043c\u0431\u043e\u043b\u043e\u0432\u201c / \u0431\u0443\u043b. \u201e\u041d\u0438\u043a\u043e\u043b\u0430 \u041f\u0435\u0442\u043a\u043e\u0432\u201c \u2013 \"",
                "latitude": 42.51718849672,
                "longitude": 27.461915016174,
                "name": "\"McDonalds\"",
                "phone": "\"0884/ 000 308\""
            },
            {
                "__id": 36,
                "address_country": "\"BG\"",
                "address_full": "\"\u041c\u0430\u0433\u0438\u0441\u0442\u0440\u0430\u043b\u0430 \u0422\u0440\u0430\u043a\u0438\u044f, 89 \u043a\u043c \u2013 \"",
                "latitude": 42.2643917,
                "longitude": 24.3069824,
                "name": "\"McDonalds\"",
                "phone": "\"0884/ 000 314\""
            },
            {
                "__id": 10,
                "address_country": "\"BG\"",
                "address_full": "\"\u041c\u0430\u043a\u0434\u043e\u043d\u0430\u043b\u0434\u0441, \u042f\u043c\u0431\u043e\u043b 1\"",
                "latitude": 42.5322711,
                "longitude": 26.3999963,
                "name": "\"McDonalds\"",
                "phone": "\"\""
            },
            {
                "__id": 46,
                "address_country": "\"BG\"",
                "address_full": "\"\u0436.\u043a. \u041b\u044e\u043b\u0438\u043d, \u0431\u0443\u043b. \u201e\u0415\u0432\u0440\u043e\u043f\u0430\u201c 1 \u2013 \"",
                "latitude": 42.716939360143,
                "longitude": 23.278806209564,
                "name": "\"McDonalds\"",
                "phone": "\"0884/ 000 313\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "awrestaurants": {
        "__id": 93,
        "id": "awrestaurants",
        "name": "A&W Restaurants",
        "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'takeaway', 'website']",
        "rowLength": 494,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763496,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of awrestaurants locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 325,
                "address_city": "\"New Kensington\"",
                "address_full": "\"100 Tarentum Bridge Rd\"",
                "address_postcode": "\"15068\"",
                "address_state": "\"PA\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 40.590627,
                "longitude": -79.750757,
                "name": "\"A&W Restaurant\"",
                "opening_hours": "\"Mo-Th 11:00-20:00; Fr-Sa 11:00-21:00; Su 11:00-20:00\"",
                "phone": "\"+17243351500\"",
                "takeaway": "\"yes\"",
                "website": "\"https://awrestaurants.com/locations/pennsylvania/new-kensington/100-tarentum-bridge-rd/\""
            },
            {
                "__id": 455,
                "address_city": "\"Ortonville\"",
                "address_full": "\"470 South St\"",
                "address_postcode": "\"48462\"",
                "address_state": "\"MI\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 42.845149,
                "longitude": -83.448043,
                "name": "\"A&W Restaurant\"",
                "opening_hours": "\"\"",
                "phone": "\"+12486272670\"",
                "takeaway": "\"yes\"",
                "website": "\"https://awrestaurants.com/locations/michigan/ortonville/470-south-street/\""
            },
            {
                "__id": 434,
                "address_city": "\"Ravenna\"",
                "address_full": "\"769 E Main St\"",
                "address_postcode": "\"44266\"",
                "address_state": "\"OH\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 41.157388,
                "longitude": -81.229346,
                "name": "\"A&W Restaurant\"",
                "opening_hours": "\"Mo-Su 11:00-21:00\"",
                "phone": "\"+13302965028\"",
                "takeaway": "\"yes\"",
                "website": "\"https://awrestaurants.com/locations/ohio/ravenna/769-e-main-street/\""
            },
            {
                "__id": 418,
                "address_city": "\"Indianapolis\"",
                "address_full": "\"49 West Maryland St\"",
                "address_postcode": "\"46204\"",
                "address_state": "\"IN\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 39.765808,
                "longitude": -86.159691,
                "name": "\"A&W Restaurant\"",
                "opening_hours": "\"Mo-Su 10:30-21:00\"",
                "phone": "\"+13176861077\"",
                "takeaway": "\"yes\"",
                "website": "\"https://awrestaurants.com/locations/indiana/indianapolis/49-west-maryland-street/\""
            },
            {
                "__id": 173,
                "address_city": "\"Tacoma\"",
                "address_full": "\"14522 Pacific Ave\"",
                "address_postcode": "\"98444\"",
                "address_state": "\"WA\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 47.124461,
                "longitude": -122.435175,
                "name": "\"A&W Restaurant\"",
                "opening_hours": "\"Mo-Su 10:30-23:00\"",
                "phone": "\"+12535363719\"",
                "takeaway": "\"yes\"",
                "website": "\"https://awrestaurants.com/locations/washington/tacoma/14522-pacific-avenue/\""
            },
            {
                "__id": 267,
                "address_city": "\"Springfield\"",
                "address_full": "\"1030 Clock Tower Dr.\"",
                "address_postcode": "\"62704\"",
                "address_state": "\"IL\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 39.789839,
                "longitude": -89.703546,
                "name": "\"A&W Restaurant\"",
                "opening_hours": "\"Mo-Th 10:30-21:00; Fr-Sa 10:30-22:00; Su 10:30-21:00\"",
                "phone": "\"+12177871562\"",
                "takeaway": "\"yes\"",
                "website": "\"https://awrestaurants.com/locations/illinois/springfield/1030-clock-tower-dr/\""
            },
            {
                "__id": 114,
                "address_city": "\"Stockton\"",
                "address_full": "\"134 E Martin Luther King Jr Blvd\"",
                "address_postcode": "\"95206\"",
                "address_state": "\"CA\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 37.938762,
                "longitude": -121.285009,
                "name": "\"A&W Restaurant\"",
                "opening_hours": "\"Mo-Su 10:30-21:00\"",
                "phone": "\"+12094660771\"",
                "takeaway": "\"yes\"",
                "website": "\"https://awrestaurants.com/locations/california/stockton/134-e-martin-luther-king-jr-blvd/\""
            },
            {
                "__id": 296,
                "address_city": "\"Houston\"",
                "address_full": "\"10270 North Freeway\"",
                "address_postcode": "\"77037\"",
                "address_state": "\"TX\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 29.915041,
                "longitude": -95.411642,
                "name": "\"A&W Restaurant\"",
                "opening_hours": "\"Mo-Tu 10:30-22:00; We 10:00-22:00; Th 10:30-22:00; Fr-Sa 10:30-23:00; Su 10:30-22:00\"",
                "phone": "\"+12816686015\"",
                "takeaway": "\"yes\"",
                "website": "\"https://awrestaurants.com/locations/texas/houston/10270-north-freeway/\""
            },
            {
                "__id": 39,
                "address_city": "\"Fort Gratiot\"",
                "address_full": "\"3805 Pine Grove\"",
                "address_postcode": "\"48059\"",
                "address_state": "\"MI\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 43.01951,
                "longitude": -82.454674,
                "name": "\"A&W Restaurant\"",
                "opening_hours": "\"Mo-Th 10:00-20:00; Fr-Sa 10:00-21:00; Su 10:00-20:00\"",
                "phone": "\"+18109856560\"",
                "takeaway": "\"yes\"",
                "website": "\"https://awrestaurants.com/locations/michigan/fort-gratiot/3805-pine-grove/\""
            },
            {
                "__id": 218,
                "address_city": "\"Snowville\"",
                "address_full": "\"75 S Stone St\"",
                "address_postcode": "\"84336\"",
                "address_state": "\"UT\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 41.964317,
                "longitude": -112.709971,
                "name": "\"A&W Restaurant\"",
                "opening_hours": "\"Mo-Th 10:00-22:00; Fr-Sa 10:00-23:00\"",
                "phone": "\"+14358728228\"",
                "takeaway": "\"yes\"",
                "website": "\"https://awrestaurants.com/locations/utah/snowville/75-s-stone-rd/\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "hardrock": {
        "__id": 94,
        "id": "hardrock",
        "name": "Hard Rock",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'address_street', 'latitude', 'longitude', 'name', 'phone', 'website']",
        "rowLength": 216,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763496,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of hardrock locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 181,
                "address_city": "\"Dalian\"",
                "address_country": "\"CN\"",
                "address_postcode": "\"\"",
                "address_state": "\"Liaoning\"",
                "address_street": "\"No. 65 West Haibin Road, Jinzhou District\"",
                "latitude": 39.08667,
                "longitude": 122.005369,
                "name": "\"Hard Rock Hotel Dalian\"",
                "phone": "\"\"",
                "website": "\"https://www.hardrockhotels.com/dalian\""
            },
            {
                "__id": 198,
                "address_city": "\"Osaka\"",
                "address_country": "\"JP\"",
                "address_postcode": "\"554-002\"",
                "address_state": "\"\"",
                "address_street": "\"Universal CityWalk Osaka 3F & 4F, 6-2-61 Shimaya, Konokana-ku\"",
                "latitude": 34.66806,
                "longitude": 135.43617,
                "name": "\"Hard Rock Cafe Osaka Universal\"",
                "phone": "\"+81-6-4804-3870\"",
                "website": "\"https://www.hardrockcafe.com/location/osaka-universal/\""
            },
            {
                "__id": 13,
                "address_city": "\"Chicago\"",
                "address_country": "\"US\"",
                "address_postcode": "\"60654\"",
                "address_state": "\"Illinois\"",
                "address_street": "\"63 W. Ontario\"",
                "latitude": 41.89312,
                "longitude": -87.63059,
                "name": "\"Hard Rock Cafe Chicago\"",
                "phone": "\"+1-312-943-2252\"",
                "website": "\"https://www.hardrockcafe.com/location/chicago/\""
            },
            {
                "__id": 178,
                "address_city": "\"Bangalore\"",
                "address_country": "\"IN\"",
                "address_postcode": "\"\"",
                "address_state": "\"\"",
                "address_street": "\"40, ITPL Main Rd Unit no.G-25 & 26, Ground Floor\"",
                "latitude": 12.9943405,
                "longitude": 77.711616,
                "name": "\"Hard Rock Cafe -\tBengaluru Whitefield\"",
                "phone": "\"+91 80 6122 4722\"",
                "website": "\"https://www.hardrockcafe.com/location/whitefield/\""
            },
            {
                "__id": 114,
                "address_city": "\"Dubrovnik\"",
                "address_country": "\"HR\"",
                "address_postcode": "\"\"",
                "address_state": "\"\"",
                "address_street": "\"Poljana Paska Milicevica 4\"",
                "latitude": 42.6418592,
                "longitude": 18.1049591,
                "name": "\"Hard Rock Cafe Dubrovnik\"",
                "phone": "\"+385 20 413 100\"",
                "website": "\"https://www.hardrockcafe.com/location/Dubrovnik/\""
            },
            {
                "__id": 87,
                "address_city": "\"Buenos Aires\"",
                "address_country": "\"AR\"",
                "address_postcode": "\"\"",
                "address_state": "\"\"",
                "address_street": "\"Puerto Madero, Buenos Aires, Argentina\"",
                "latitude": -34.60062,
                "longitude": -58.36723,
                "name": "\"Hard Rock Cafe Puerto Madero\"",
                "phone": "\"\"",
                "website": "\"https://www.hardrockcafe.com/location/puerto-madero/\""
            },
            {
                "__id": 214,
                "address_city": "\"Tokyo\"",
                "address_country": "\"JP\"",
                "address_postcode": "\"106-0032\"",
                "address_state": "\"\"",
                "address_street": "\"5-4-20 Roppongi, Minato-ku\"",
                "latitude": 35.66178,
                "longitude": 139.73413,
                "name": "\"Hard Rock Cafe Tokyo Roppongi\"",
                "phone": "\"+81-3-3408-7018<\"",
                "website": "\"https://www.hardrockcafe.com/location/tokyo-roppongi/\""
            },
            {
                "__id": 86,
                "address_city": "\"Ponce\"",
                "address_country": "\"PR\"",
                "address_postcode": "\"00717\"",
                "address_state": "\"\"",
                "address_street": "\"Carr PR #2 Km.228.9 Ave. Santiago de los Caballeros\"",
                "latitude": 17.99704,
                "longitude": -66.60401,
                "name": "\"Hard Rock Cafe Ponce\"",
                "phone": "\"\"",
                "website": "\"https://www.hardrockcafe.com/location/ponce/\""
            },
            {
                "__id": 66,
                "address_city": "\"Atlanta\"",
                "address_country": "\"US\"",
                "address_postcode": "\"30313\"",
                "address_state": "\"Georgia\"",
                "address_street": "\"89 Centennial Olympic Park Dr NW\"",
                "latitude": 33.7537087,
                "longitude": -84.4013372,
                "name": "\"REVERB Hotel Downtown Atlanta\"",
                "phone": "\"+1 (470) 552-8410\"",
                "website": "\"https://reverb.hardrockhotels.com/atlanta.aspx\""
            },
            {
                "__id": 148,
                "address_city": "\"Paris\"",
                "address_country": "\"FR\"",
                "address_postcode": "\"75009\"",
                "address_state": "\"\"",
                "address_street": "\"14 Boulevard Montmartre\"",
                "latitude": 48.87187,
                "longitude": 2.34158,
                "name": "\"Hard Rock Cafe Paris\"",
                "phone": "\"+331-5324-6000\"",
                "website": "\"https://www.hardrockcafe.com/location/paris/\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "ridleys": {
        "__id": 95,
        "id": "ridleys",
        "name": "Ridley's",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'phone', 'street_address']",
        "rowLength": 27,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763497,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of ridleys locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 17,
                "address_city": "\"Winnemucca\"",
                "address_country": "\"US\"",
                "address_postcode": "\"89445\"",
                "address_state": "\"NV\"",
                "latitude": 40.9642853,
                "longitude": -117.7438498,
                "name": "\"Winnemucca Market & Pharmacy\"",
                "phone": "\"775-623-2577\"",
                "street_address": "\"1125 W. Winnemucca Blvd\""
            },
            {
                "__id": 5,
                "address_city": "\"Gooding\"",
                "address_country": "\"US\"",
                "address_postcode": "\"83330\"",
                "address_state": "\"ID\"",
                "latitude": 42.9309241,
                "longitude": -114.7128665,
                "name": "\"Gooding Grocery Store & Pharmacy\"",
                "phone": "\"(208) 934-4032\"",
                "street_address": "\"1427 Main St.\""
            },
            {
                "__id": 9,
                "address_city": "\"McCall\"",
                "address_country": "\"US\"",
                "address_postcode": "\"83638\"",
                "address_state": "\"ID\"",
                "latitude": 44.911006,
                "longitude": -116.0987364,
                "name": "\"McCall Grocery & Variety Store\"",
                "phone": "\"(208) 634-8719\"",
                "street_address": "\"411 Deinhard Lane\""
            },
            {
                "__id": 7,
                "address_city": "\"Kimberly\"",
                "address_country": "\"US\"",
                "address_postcode": "\"83341\"",
                "address_state": "\"ID\"",
                "latitude": 42.5326253,
                "longitude": -114.3647704,
                "name": "\"Kimberly Grocery Store & Pharmacy\"",
                "phone": "\"(208) 423-5490\"",
                "street_address": "\"210 Main St. South\""
            },
            {
                "__id": 14,
                "address_city": "\"Star\"",
                "address_country": "\"US\"",
                "address_postcode": "\"83669\"",
                "address_state": "\"ID\"",
                "latitude": 43.6900618,
                "longitude": -116.4753317,
                "name": "\"Star Grocery Store, Pharmacy & Ace Hardware\"",
                "phone": "\"208-207-1010\"",
                "street_address": "\"145 South Plummer Way\""
            },
            {
                "__id": 12,
                "address_city": "\"Pocatello\"",
                "address_country": "\"US\"",
                "address_postcode": "\"83204\"",
                "address_state": "\"ID\"",
                "latitude": 42.8684927,
                "longitude": -112.4581459,
                "name": "\"Pocatello Grocery Store & Pharmacy\"",
                "phone": "\"(208) 232-3156\"",
                "street_address": "\"911 N. Main St.\""
            },
            {
                "__id": 11,
                "address_city": "\"Pocatello\"",
                "address_country": "\"US\"",
                "address_postcode": "\"83204\"",
                "address_state": "\"ID\"",
                "latitude": 42.894045,
                "longitude": -112.441015,
                "name": "\"Pocatello Creek Grocery Store & Pharmacy\"",
                "phone": "\"208-233-4158\"",
                "street_address": "\"1000 Pocatello Creek Rd\""
            },
            {
                "__id": 2,
                "address_city": "\"Wellington\"",
                "address_country": "\"US\"",
                "address_postcode": "\"80549\"",
                "address_state": "\"CO\"",
                "latitude": 40.6961771,
                "longitude": -105.0040942,
                "name": "\"Wellington Market & Pharmacy\"",
                "phone": "\"970-568-3197\"",
                "street_address": "\"7670 5th St.\""
            },
            {
                "__id": 18,
                "address_city": "\"Eagle Mountain\"",
                "address_country": "\"US\"",
                "address_postcode": "\"84005\"",
                "address_state": "\"UT\"",
                "latitude": 40.322565,
                "longitude": -112.013512,
                "name": "\"Eagle Mountain Grocery Store & Pharmacy\"",
                "phone": "\"801-789-4995\"",
                "street_address": "\"4045 East Pony Express Pkwy\""
            },
            {
                "__id": 20,
                "address_city": "\"Midway\"",
                "address_country": "\"US\"",
                "address_postcode": "\"84049\"",
                "address_state": "\"UT\"",
                "latitude": 40.512827,
                "longitude": -111.473231,
                "name": "\"Midway Grocery Store and Fuel Center\"",
                "phone": "\"435-657-0170\"",
                "street_address": "\"42 West Main Street\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "honey_baked": {
        "__id": 96,
        "id": "honey_baked",
        "name": "Honey Baked",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'alt_name', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'official_name', 'phone', 'takeaway']",
        "rowLength": 495,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763498,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of honey_baked locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 424,
                "address_city": "\"Parker\"",
                "address_country": "\"US\"",
                "address_full": "\"18870 Plaza Drive\"",
                "address_postcode": "\"80134\"",
                "address_state": "\"CO\"",
                "alt_name": "\"HoneyBaked Ham\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"american\"",
                "latitude": 39.526141,
                "longitude": -104.770683,
                "name": "\"HoneyBaked of Parker\"",
                "official_name": "\"The Honey Baked Ham Company\"",
                "phone": "\"3038407968\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 313,
                "address_city": "\"Bourbonnais\"",
                "address_country": "\"US\"",
                "address_full": "\"538 Main St.\"",
                "address_postcode": "\"60914\"",
                "address_state": "\"IL\"",
                "alt_name": "\"HoneyBaked Ham\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"american\"",
                "latitude": 41.158322,
                "longitude": -87.886005,
                "name": "\"HoneyBaked of Bourbonnais\"",
                "official_name": "\"The Honey Baked Ham Company\"",
                "phone": "\"8159359700\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 463,
                "address_city": "\"Orange\"",
                "address_country": "\"US\"",
                "address_full": "\"1419 N. Tustin\"",
                "address_postcode": "\"92867\"",
                "address_state": "\"CA\"",
                "alt_name": "\"HoneyBaked Ham\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"american\"",
                "latitude": 33.810581,
                "longitude": -117.835772,
                "name": "\"Orange\"",
                "official_name": "\"The Honey Baked Ham Company\"",
                "phone": "\"7149979960\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 287,
                "address_city": "\"Bloomington\"",
                "address_country": "\"US\"",
                "address_full": "\"3293 West 3rd Street\"",
                "address_postcode": "\"47404\"",
                "address_state": "\"IN\"",
                "alt_name": "\"HoneyBaked Ham\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"american\"",
                "latitude": 39.164507,
                "longitude": -86.575896,
                "name": "\"HoneyBaked of Bloomington\"",
                "official_name": "\"The Honey Baked Ham Company\"",
                "phone": "\"8123364426\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 468,
                "address_city": "\"La Habra\"",
                "address_country": "\"US\"",
                "address_full": "\"2428 West Whittier Blvd.\"",
                "address_postcode": "\"90631\"",
                "address_state": "\"CA\"",
                "alt_name": "\"HoneyBaked Ham\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"american\"",
                "latitude": 33.939843,
                "longitude": -117.973341,
                "name": "\"La Habra\"",
                "official_name": "\"The Honey Baked Ham Company\"",
                "phone": "\"5626942114\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 357,
                "address_city": "\"Houma\"",
                "address_country": "\"US\"",
                "address_full": "\"1539 MLK Bvd\"",
                "address_postcode": "\"70360\"",
                "address_state": "\"LA\"",
                "alt_name": "\"HoneyBaked Ham\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"american\"",
                "latitude": 29.607374,
                "longitude": -90.750338,
                "name": "\"HoneyBaked of Houma\"",
                "official_name": "\"The Honey Baked Ham Company\"",
                "phone": "\"9858513692\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 174,
                "address_city": "\"Pinellas Park\"",
                "address_country": "\"US\"",
                "address_full": "\"7221 US Hwy 19\"",
                "address_postcode": "\"33781\"",
                "address_state": "\"FL\"",
                "alt_name": "\"HoneyBaked Ham\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"american\"",
                "latitude": 27.837871,
                "longitude": -82.687297,
                "name": "\"HoneyBaked of Pinellas Park\"",
                "official_name": "\"The Honey Baked Ham Company\"",
                "phone": "\"7275212621\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 404,
                "address_city": "\"Cedar Hill\"",
                "address_country": "\"US\"",
                "address_full": "\"305 West FM 1382\"",
                "address_postcode": "\"75104\"",
                "address_state": "\"TX\"",
                "alt_name": "\"HoneyBaked Ham\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"american\"",
                "latitude": 32.602324,
                "longitude": -96.942498,
                "name": "\"HoneyBaked Ham Holiday Express\"",
                "official_name": "\"The Honey Baked Ham Company\"",
                "phone": "\"8174651800\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 113,
                "address_city": "\"Daytona Beach\"",
                "address_country": "\"US\"",
                "address_full": "\"345 Bill France Blvd\"",
                "address_postcode": "\"32114\"",
                "address_state": "\"FL\"",
                "alt_name": "\"HoneyBaked Ham\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"american\"",
                "latitude": 29.199058,
                "longitude": -81.069987,
                "name": "\"HoneyBaked of Daytona\"",
                "official_name": "\"The Honey Baked Ham Company\"",
                "phone": "\"3862380026\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 129,
                "address_city": "\"Eustis\"",
                "address_country": "\"US\"",
                "address_full": "\"421 W Plaza Drive\"",
                "address_postcode": "\"32726\"",
                "address_state": "\"FL\"",
                "alt_name": "\"HoneyBaked Ham\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"american\"",
                "latitude": 28.824454,
                "longitude": -81.690665,
                "name": "\"HoneyBaked of Eustis\"",
                "official_name": "\"The Honey Baked Ham Company\"",
                "phone": "\"3524831776\"",
                "takeaway": "\"yes\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "qualitydiary": {
        "__id": 97,
        "id": "qualitydiary",
        "name": "Quality Diary",
        "columnNames": "['address_city', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'website']",
        "rowLength": 27,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763499,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of qualitydiary locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 19,
                "address_city": "\"Lansing\"",
                "address_state": "\"MI\"",
                "latitude": 42.7266559,
                "longitude": -84.6423966,
                "name": "\"Quality Dairy Store - Creyts Road\"",
                "opening_hours": "\"Su 06:00-22:00; Mo-Sa 06:00-12:00\"",
                "phone": "\"517-371-8924\"",
                "website": "\"http://qualitydairy.com/v15/stores/creyts/\""
            },
            {
                "__id": 4,
                "address_city": "\"Lansing\"",
                "address_state": "\"MI\"",
                "latitude": 42.74119,
                "longitude": -84.559671,
                "name": "\"Quality Dairy Store - Pine Street & West Saginaw\"",
                "opening_hours": "\"Su 07:00-12:00; Mo-Fr 06:00-12:00; Sa 07:00-12:00\"",
                "phone": "\"517-371-8905\"",
                "website": "\"http://qualitydairy.com/v15/stores/pine/\""
            },
            {
                "__id": 23,
                "address_city": "\"Lansing\"",
                "address_state": "\"MI\"",
                "latitude": 42.683365,
                "longitude": -84.513436,
                "name": "\"Quality Dairy Store - Jolly & Dunckel\"",
                "opening_hours": "\"24/7\"",
                "phone": "\" 517-371-8928\"",
                "website": "\"http://qualitydairy.com/v15/stores/dunckel/\""
            },
            {
                "__id": 25,
                "address_city": "\"Okemos\"",
                "address_state": "\"MI\"",
                "latitude": 42.717569,
                "longitude": -84.410574,
                "name": "\"Quality Dairy Store - Okemos\"",
                "opening_hours": "\"Su 07:00-23:00; Mo-Fr 06:00-23:00; Sa 07:00-23:00\"",
                "phone": "\"517-371-8931\"",
                "website": "\"http://qualitydairy.com/v15/stores/okemos/\""
            },
            {
                "__id": 3,
                "address_city": "\"Lansing\"",
                "address_state": "\"MI\"",
                "latitude": 42.712285,
                "longitude": -84.572059,
                "name": "\"Quality Dairy Store - West Mount Hope Ave\"",
                "opening_hours": "\"06:00-12:00\"",
                "phone": "\"517-371-8904\"",
                "website": "\"http://qualitydairy.com/v15/stores/mt-hope/\""
            },
            {
                "__id": 11,
                "address_city": "\"Lansing\"",
                "address_state": "\"MI\"",
                "latitude": 42.682834,
                "longitude": -84.603668,
                "name": "\"Quality Dairy Store - South Waverly & Jolly Road\"",
                "opening_hours": "\"06:00-02:00\"",
                "phone": "\"517-371-8916\"",
                "website": "\"http://qualitydairy.com/v15/stores/s-waverly/\""
            },
            {
                "__id": 18,
                "address_city": "\"Eaton Rapids\"",
                "address_state": "\"MI\"",
                "latitude": 42.512416,
                "longitude": -84.655749,
                "name": "\"Quality Dairy Store - Eaton Rapids\"",
                "opening_hours": "\"24/7\"",
                "phone": "\"517-371-8923\"",
                "website": "\"http://qualitydairy.com/v15/stores/eaton-rapids/\""
            },
            {
                "__id": 14,
                "address_city": "\"Dimondale\"",
                "address_state": "\"MI\"",
                "latitude": 42.671722,
                "longitude": -84.659431,
                "name": "\"Quality Dairy Store - Lansing Road / M-78\"",
                "opening_hours": "\"Su 06:00-12:00; Mo-Fr 04:00-12:00; Sa 06:00-12:00\"",
                "phone": "\"517-371-8919\"",
                "website": "\"http://qualitydairy.com/v15/stores/m-78/\""
            },
            {
                "__id": 1,
                "address_city": "\"Lansing\"",
                "address_state": "\"MI\"",
                "latitude": 42.72952863060616,
                "longitude": -84.5383868081303,
                "name": "\"Quality Dairy Store - South Pennsylvania & Kalamazoo\"",
                "opening_hours": "\"06:00-02:00\"",
                "phone": "\"517-371-8902\"",
                "website": "\"http://qualitydairy.com/v15/stores/s-pennsylvania/\""
            },
            {
                "__id": 13,
                "address_city": "\"Holt\"",
                "address_state": "\"MI\"",
                "latitude": 42.64694,
                "longitude": -84.523239,
                "name": "\"Quality Dairy Store - Holt\"",
                "opening_hours": "\"05:30-02:00\"",
                "phone": "\"517-371-8918\"",
                "website": "\"http://qualitydairy.com/v15/stores/holt/\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "bertuccis": {
        "__id": 98,
        "id": "bertuccis",
        "name": "Bertucci's",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'address_street', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'website']",
        "rowLength": 47,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763500,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of bertuccis locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 39,
                "address_city": "\"Peabody\"",
                "address_country": "\"US\"",
                "address_postcode": "\"01960\"",
                "address_state": "\"Massachusetts\"",
                "address_street": "\"15 Newbury Street\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"pizza;italian\"",
                "latitude": 42.52146154204321,
                "longitude": -70.99697752872089,
                "name": "\"Bertucci\u2019s Brick Oven Pizza & Pasta\"",
                "opening_hours": "\"Mo-We 11:00-21:30; Th 11:00-22:00; Fr-Sa 11:00-23:00; Su 11:00-21:30\"",
                "phone": "\"(978) 535-0969\"",
                "website": "\"https://locations.bertuccis.com/us/ma/peabody/15-newbury-street\""
            },
            {
                "__id": 30,
                "address_city": "\"Manchester\"",
                "address_country": "\"US\"",
                "address_postcode": "\"03103\"",
                "address_state": "\"New Hampshire\"",
                "address_street": "\"1500 South Willow St.\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"pizza;italian\"",
                "latitude": 42.9552545,
                "longitude": -71.4330667,
                "name": "\"Bertucci\u2019s Brick Oven Pizza & Pasta\"",
                "opening_hours": "\"Mo-Th 11:00-19:00; Fr-Sa 11:00-21:00; Su 12:00-18:00\"",
                "phone": "\"(603) 668-6204\"",
                "website": "\"https://locations.bertuccis.com/us/nh/manchester/1500-south-willow-st\""
            },
            {
                "__id": 11,
                "address_city": "\"Chestnut Hill\"",
                "address_country": "\"US\"",
                "address_postcode": "\"02467\"",
                "address_state": "\"Massachusetts\"",
                "address_street": "\"683 VFW Pkwy\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"pizza;italian\"",
                "latitude": 42.29642009558911,
                "longitude": -71.15748628042638,
                "name": "\"Bertucci\u2019s Brick Oven Pizza & Pasta\"",
                "opening_hours": "\"Mo-We 11:00-21:30; Th 11:00-22:00; Fr-Sa 11:00-23:00; Su 11:00-21:30\"",
                "phone": "\"(617) 327-0898\"",
                "website": "\"https://locations.bertuccis.com/us/ma/chestnut-hill/683-vfw-pkwy\""
            },
            {
                "__id": 40,
                "address_city": "\"Norwood\"",
                "address_country": "\"US\"",
                "address_postcode": "\"02062\"",
                "address_state": "\"Massachusetts\"",
                "address_street": "\"1405 Providence Highway\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"pizza;italian\"",
                "latitude": 42.16519272776503,
                "longitude": -71.19955480098724,
                "name": "\"Bertucci\u2019s Brick Oven Pizza & Pasta\"",
                "opening_hours": "\"Mo-We 11:00-21:30; Th 11:00-22:00; Fr-Sa 11:00-23:00; Su 11:00-21:30\"",
                "phone": "\"(781) 762-4155\"",
                "website": "\"https://locations.bertuccis.com/us/ma/norwood/1405-providence-highway\""
            },
            {
                "__id": 6,
                "address_city": "\"Mansfield\"",
                "address_country": "\"US\"",
                "address_postcode": "\"02048\"",
                "address_state": "\"Massachusetts\"",
                "address_street": "\"280 School Street\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"pizza;italian\"",
                "latitude": 42.012363,
                "longitude": -71.229755,
                "name": "\"Bertucci\u2019s Brick Oven Pizza & Pasta\"",
                "opening_hours": "\"Mo-We 11:00-21:30; Th 11:00-22:00; Fr-Sa 11:00-23:00; Su 11:00-21:30\"",
                "phone": "\"(508) 261-2371\"",
                "website": "\"https://locations.bertuccis.com/us/ma/mansfield/280-school-street\""
            },
            {
                "__id": 47,
                "address_city": "\"Avon\"",
                "address_country": "\"US\"",
                "address_postcode": "\"06001\"",
                "address_state": "\"Connecticut\"",
                "address_street": "\"380 West Main Street\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"pizza;italian\"",
                "latitude": 41.81677300200213,
                "longitude": -72.8630706183854,
                "name": "\"Bertucci\u2019s Brick Oven Pizza & Pasta\"",
                "opening_hours": "\"Mo-We 11:00-21:30; Th 11:00-22:00; Fr-Sa 11:00-23:00; Su 11:00-21:30\"",
                "phone": "\"(860) 676-1177\"",
                "website": "\"https://locations.bertuccis.com/us/ct/avon/380-west-main-street\""
            },
            {
                "__id": 19,
                "address_city": "\"Wayne\"",
                "address_country": "\"US\"",
                "address_postcode": "\"19087\"",
                "address_state": "\"Pennsylvania\"",
                "address_street": "\"523 West Lancaster Avenue\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"pizza;italian\"",
                "latitude": 40.04555459977201,
                "longitude": -75.40338099002838,
                "name": "\"Bertucci\u2019s Brick Oven Pizza & Pasta\"",
                "opening_hours": "\"Mo-We 11:00-21:30; Th 11:00-22:00; Fr-Sa 11:00-23:00; Su 11:00-21:30\"",
                "phone": "\"(610) 293-1700\"",
                "website": "\"https://locations.bertuccis.com/us/pa/wayne/523-west-lancaster-avenue\""
            },
            {
                "__id": 20,
                "address_city": "\"Springfield\"",
                "address_country": "\"US\"",
                "address_postcode": "\"19064\"",
                "address_state": "\"Pennsylvania\"",
                "address_street": "\"965 Baltimore Pike\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"pizza;italian\"",
                "latitude": 39.914278833909634,
                "longitude": -75.33981800079346,
                "name": "\"Bertucci\u2019s Brick Oven Pizza & Pasta\"",
                "opening_hours": "\"Mo-We 11:00-21:30; Th 11:00-22:00; Fr-Sa 11:00-23:00; Su 11:00-21:30\"",
                "phone": "\"(610) 543-8079\"",
                "website": "\"https://locations.bertuccis.com/us/pa/springfield/965-baltimore-pike\""
            },
            {
                "__id": 16,
                "address_city": "\"Braintree\"",
                "address_country": "\"US\"",
                "address_postcode": "\"02184\"",
                "address_state": "\"Massachusetts\"",
                "address_street": "\"412 Franklin Street\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"pizza;italian\"",
                "latitude": 42.2110893,
                "longitude": -71.01945,
                "name": "\"Bertucci\u2019s Brick Oven Pizza & Pasta\"",
                "opening_hours": "\"Mo-We 11:00-21:30; Th 11:00-22:00; Fr-Sa 11:00-23:00; Su 11:00-21:30\"",
                "phone": "\"(781) 849-3066\"",
                "website": "\"https://locations.bertuccis.com/us/ma/braintree/412-franklin-street\""
            },
            {
                "__id": 9,
                "address_city": "\"Hingham\"",
                "address_country": "\"US\"",
                "address_postcode": "\"02043\"",
                "address_state": "\"Massachusetts\"",
                "address_street": "\"90 Derby Street\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"pizza;italian\"",
                "latitude": 42.17977366475019,
                "longitude": -70.90692847967148,
                "name": "\"Bertucci\u2019s Brick Oven Pizza & Pasta\"",
                "opening_hours": "\"Mo-We 11:00-21:30; Th 11:00-22:00; Fr-Sa 11:00-23:00; Su 11:00-21:30\"",
                "phone": "\"(781) 740-4405\"",
                "website": "\"https://locations.bertuccis.com/us/ma/hingham/90-derby-street\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "cinemark": {
        "__id": 99,
        "id": "cinemark",
        "name": "Cinemark",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'amenity', 'contact_email', 'image', 'latitude', 'longitude', 'name', 'phone', 'street_address', 'website']",
        "rowLength": 327,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763501,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of cinemark locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 247,
                "address_city": "\"Webster\"",
                "address_country": "\"US\"",
                "address_postcode": "\"77598\"",
                "address_state": "\"TX\"",
                "amenity": "\"cinema\"",
                "contact_email": "\"295@cinemark.com\"",
                "image": "\"https://www.cinemark.com/images/cinemarkLogo.png\"",
                "latitude": 29.525179,
                "longitude": -95.12698,
                "name": "\"Cinemark 18 and XD\"",
                "phone": "\"281-332-0951\"",
                "street_address": "\"20915 Gulf Freeway\"",
                "website": "\"https://www.cinemark.com/theatres/tx-webster/cinemark-18-and-xd\""
            },
            {
                "__id": 153,
                "address_city": "\"Huntington Beach\"",
                "address_country": "\"US\"",
                "address_postcode": "\"92647\"",
                "address_state": "\"CA\"",
                "amenity": "\"cinema\"",
                "contact_email": "\"482@cinemark.com\"",
                "image": "\"https://www.cinemark.com/images/cinemarkLogo.png\"",
                "latitude": 33.731549,
                "longitude": -117.994441,
                "name": "\"Century Huntington Beach and XD\"",
                "phone": "\"714-373-4573\"",
                "street_address": "\"7777 Edinger Ave\"",
                "website": "\"https://www.cinemark.com/theatres/ca-huntington-beach/century-huntington-beach-and-xd\""
            },
            {
                "__id": 137,
                "address_city": "\"Sioux Falls\"",
                "address_country": "\"US\"",
                "address_postcode": "\"57110\"",
                "address_state": "\"SD\"",
                "amenity": "\"cinema\"",
                "contact_email": "\"1011@cinemark.com\"",
                "image": "\"https://www.cinemark.com/images/cinemarkLogo.png\"",
                "latitude": 43.5378,
                "longitude": -96.654639,
                "name": "\"Century East At Dawley Farm\"",
                "phone": "\"605-334-2468\"",
                "street_address": "\"1101 S Highline Place\"",
                "website": "\"https://www.cinemark.com/theatres/sd-sioux-falls/century-east-at-dawley-farm\""
            },
            {
                "__id": 15,
                "address_city": "\"Big Spring\"",
                "address_country": "\"US\"",
                "address_postcode": "\"79720\"",
                "address_state": "\"TX\"",
                "amenity": "\"cinema\"",
                "contact_email": "\"085@cinemark.com\"",
                "image": "\"https://www.cinemark.com/images/cinemarkLogo.png\"",
                "latitude": 32.250753,
                "longitude": -101.4358,
                "name": "\"Cinemark Cinema 4\"",
                "phone": "\"432-263-2252\"",
                "street_address": "\"1801 East FM 700\"",
                "website": "\"https://www.cinemark.com/theatres/tx-big-spring/cinemark-cinema-4\""
            },
            {
                "__id": 107,
                "address_city": "\"Draper\"",
                "address_country": "\"US\"",
                "address_postcode": "\"84020\"",
                "address_state": "\"UT\"",
                "amenity": "\"cinema\"",
                "contact_email": "\"1056@cinemark.com\"",
                "image": "\"https://www.cinemark.com/images/cinemarkLogo.png\"",
                "latitude": 40.532089,
                "longitude": -111.889383,
                "name": "\"Cinemark Draper and XD\"",
                "phone": "\"801-619-6494\"",
                "street_address": "\"12129 South State St\"",
                "website": "\"https://www.cinemark.com/theatres/ut-draper/cinemark-draper-and-xd\""
            },
            {
                "__id": 42,
                "address_city": "\"Raleigh\"",
                "address_country": "\"US\"",
                "address_postcode": "\"27613\"",
                "address_state": "\"NC\"",
                "amenity": "\"cinema\"",
                "contact_email": "\"1145@cinemark.com\"",
                "image": "\"https://www.cinemark.com/images/cinemarkLogo.png\"",
                "latitude": 35.8721214,
                "longitude": -78.7184804,
                "name": "\"Cinemark Raleigh Grande\"",
                "phone": "\"919-788-9000\"",
                "street_address": "\"4840 Grove Barton Rd\"",
                "website": "\"https://www.cinemark.com/theatres/nc-raleigh/cinemark-raleigh-grande\""
            },
            {
                "__id": 31,
                "address_city": "\"Hayward\"",
                "address_country": "\"US\"",
                "address_postcode": "\"94545\"",
                "address_state": "\"CA\"",
                "amenity": "\"cinema\"",
                "contact_email": "\"1138@cinemark.com\"",
                "image": "\"https://www.cinemark.com/images/cinemarkLogo.png\"",
                "latitude": 37.650561,
                "longitude": -122.1037548,
                "name": "\"Century Southland Mall\"",
                "phone": "\"510-784-3040\"",
                "street_address": "\"256 Southland Mall Dr\"",
                "website": "\"https://www.cinemark.com/theatres/ca-hayward/century-southland-mall\""
            },
            {
                "__id": 236,
                "address_city": "\"Ontario\"",
                "address_country": "\"US\"",
                "address_postcode": "\"44903\"",
                "address_state": "\"OH\"",
                "amenity": "\"cinema\"",
                "contact_email": "\"342@cinemark.com\"",
                "image": "\"https://www.cinemark.com/images/cinemarkLogo.png\"",
                "latitude": 40.76801,
                "longitude": -82.524119,
                "name": "\"Cinemark 14 Mansfield Town Center\"",
                "phone": "\"419-589-7300\"",
                "street_address": "\"2355 Walker Lake Rd\"",
                "website": "\"https://www.cinemark.com/theatres/oh-ontario/cinemark-14-mansfield-town-center\""
            },
            {
                "__id": 284,
                "address_city": "\"Ogden\"",
                "address_country": "\"US\"",
                "address_postcode": "\"84405\"",
                "address_state": "\"UT\"",
                "amenity": "\"cinema\"",
                "contact_email": "\"240@cinemark.com\"",
                "image": "\"https://www.cinemark.com/images/cinemarkLogo.png\"",
                "latitude": 41.196128,
                "longitude": -111.979519,
                "name": "\"Cinemark Tinseltown 14\"",
                "phone": "\"801-334-8655\"",
                "street_address": "\"3651 Wall Ave\"",
                "website": "\"https://www.cinemark.com/theatres/ut-ogden/cinemark-tinseltown-14\""
            },
            {
                "__id": 104,
                "address_city": "\"Farmington\"",
                "address_country": "\"US\"",
                "address_postcode": "\"84025\"",
                "address_state": "\"UT\"",
                "amenity": "\"cinema\"",
                "contact_email": "\"1059@cinemark.com\"",
                "image": "\"https://www.cinemark.com/images/cinemarkLogo.png\"",
                "latitude": 40.982117,
                "longitude": -111.90632,
                "name": "\"Cinemark Farmington at Station Park and XD\"",
                "phone": "\"801-447-8561\"",
                "street_address": "\"900 W Clark Ln\"",
                "website": "\"https://www.cinemark.com/theatres/ut-farmington/cinemark-farmington-at-station-park-and-xd\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "penny_de": {
        "__id": 100,
        "id": "penny_de",
        "name": "Penny Germany",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_housenumber', 'address_postcode', 'latitude', 'longitude', 'name', 'opening_hours', 'shop']",
        "rowLength": 2014,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763502,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of penny_de locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 914,
                "address_city": "\"Leisnig\"",
                "address_country": "\"DE\"",
                "address_full": "\"Rosa-Luxemburg-Str.\"",
                "address_housenumber": "\"12\"",
                "address_postcode": "\"04703\"",
                "latitude": 51.15679,
                "longitude": 12.92824,
                "name": "\"Penny Am Johannistal\"",
                "opening_hours": "\"Mo-Sa 07:00-20:00\"",
                "shop": "\"supermarket\""
            },
            {
                "__id": 1558,
                "address_city": "\"Wesel\"",
                "address_country": "\"DE\"",
                "address_full": "\"Werftstrasse\"",
                "address_housenumber": "\"1-3\"",
                "address_postcode": "\"46483\"",
                "latitude": 51.65315,
                "longitude": 6.60677,
                "name": "\"Penny An der Werft\"",
                "opening_hours": "\"Mo-Sa 07:00-22:00\"",
                "shop": "\"supermarket\""
            },
            {
                "__id": 612,
                "address_city": "\"Baden-Baden/Oos\"",
                "address_country": "\"DE\"",
                "address_full": "\"Wilhelm-Drapp-Str.\"",
                "address_housenumber": "\"15\"",
                "address_postcode": "\"76532\"",
                "latitude": 48.79562,
                "longitude": 8.19687,
                "name": "\"Penny An der Oos\"",
                "opening_hours": "\"Mo-Sa 07:30-21:00\"",
                "shop": "\"supermarket\""
            },
            {
                "__id": 887,
                "address_city": "\"Kleinwelka\"",
                "address_country": "\"DE\"",
                "address_full": "\"Hoyerswerdaer Strasse\"",
                "address_housenumber": "\"136\"",
                "address_postcode": "\"02625\"",
                "latitude": 51.21409,
                "longitude": 14.39287,
                "name": "\"Penny Am Saurierpark\"",
                "opening_hours": "\"Mo-Sa 08:00-20:00\"",
                "shop": "\"supermarket\""
            },
            {
                "__id": 1485,
                "address_city": "\"Marl - Sinsen\"",
                "address_country": "\"DE\"",
                "address_full": "\"Bahnhofstrasse\"",
                "address_housenumber": "\"121-123A\"",
                "address_postcode": "\"45770\"",
                "latitude": 51.66737,
                "longitude": 7.1671,
                "name": "\"Penny An der Burg\"",
                "opening_hours": "\"Mo-Sa 08:00-21:30\"",
                "shop": "\"supermarket\""
            },
            {
                "__id": 462,
                "address_city": "\"Pforzheim\"",
                "address_country": "\"DE\"",
                "address_full": "\"Buechenbronner Str.\"",
                "address_housenumber": "\"68\"",
                "address_postcode": "\"75172\"",
                "latitude": 48.886,
                "longitude": 8.67042,
                "name": "\"Penny Am M\u00fchlkanal\"",
                "opening_hours": "\"Mo-Sa 07:30-22:00\"",
                "shop": "\"supermarket\""
            },
            {
                "__id": 1831,
                "address_city": "\"Oldenburg\"",
                "address_country": "\"DE\"",
                "address_full": "\"Donnerschweerstr.\"",
                "address_housenumber": "\"194-196\"",
                "address_postcode": "\"26123\"",
                "latitude": 53.15019,
                "longitude": 8.23506,
                "name": "\"Penny Donnerschwee\"",
                "opening_hours": "\"Mo-Sa 07:00-21:00\"",
                "shop": "\"supermarket\""
            },
            {
                "__id": 1156,
                "address_city": "\"Stahnsdorf\"",
                "address_country": "\"DE\"",
                "address_full": "\"Potsdamer Allee\"",
                "address_housenumber": "\"102-106\"",
                "address_postcode": "\"14532\"",
                "latitude": 52.38708,
                "longitude": 13.19831,
                "name": "\"Penny Stahnsdorf\"",
                "opening_hours": "\"Mo-Sa 07:00-21:00\"",
                "shop": "\"supermarket\""
            },
            {
                "__id": 1442,
                "address_city": "\"Erkrath - Unterfeldhaus\"",
                "address_country": "\"DE\"",
                "address_full": "\"Am Gatherfeld\"",
                "address_housenumber": "\"3\"",
                "address_postcode": "\"40699\"",
                "latitude": 51.20119,
                "longitude": 6.91122,
                "name": "\"Penny Neuenhausplatz\"",
                "opening_hours": "\"Mo-Sa 07:00-22:00\"",
                "shop": "\"supermarket\""
            },
            {
                "__id": 489,
                "address_city": "\"Stutensee/Friedrichstal\"",
                "address_country": "\"DE\"",
                "address_full": "\"Heinrich-Hertz-Str.\"",
                "address_housenumber": "\"6\"",
                "address_postcode": "\"76297\"",
                "latitude": 49.11255,
                "longitude": 8.47271,
                "name": "\"Penny Friedrichstal\"",
                "opening_hours": "\"Mo-Sa 07:30-21:00\"",
                "shop": "\"supermarket\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "crackerbarrel": {
        "__id": 101,
        "id": "crackerbarrel",
        "name": "Cracker Barrel",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'street_address', 'website']",
        "rowLength": 664,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763503,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of crackerbarrel locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 49,
                "address_city": "\"Lexington\"",
                "address_country": "\"US\"",
                "address_postcode": "\"40505-4840\"",
                "address_state": "\"KY\"",
                "latitude": 38.0448023,
                "longitude": -84.4221083,
                "name": "\"Lexington (Winchester Rd.)\"",
                "opening_hours": "\"Mo-Th 07:00-09:00; Fr-Sa 07:00-10:00; Su 07:00-09:00\"",
                "phone": "\"859-293-2555\"",
                "street_address": "\"2220 Elkhorn Rd.\"",
                "website": "\"https://www.crackerbarrel.com/Locations/States/ky/lexington/77\""
            },
            {
                "__id": 62,
                "address_city": "\"Newnan\"",
                "address_country": "\"US\"",
                "address_postcode": "\"30265-1045\"",
                "address_state": "\"GA\"",
                "latitude": 33.3951469,
                "longitude": -84.7597096,
                "name": "\"Newnan\"",
                "opening_hours": "\"Mo-Th 07:00-09:00; Fr-Sa 07:00-10:00; Su 07:00-09:00\"",
                "phone": "\"770-252-1055\"",
                "street_address": "\"527 Bullsboro Dr.\"",
                "website": "\"https://www.crackerbarrel.com/Locations/States/ga/newnan/212\""
            },
            {
                "__id": 44,
                "address_city": "\"Lenoir City\"",
                "address_country": "\"US\"",
                "address_postcode": "\"37772-5677\"",
                "address_state": "\"TN\"",
                "latitude": 35.8248225,
                "longitude": -84.2721158,
                "name": "\"Lenoir City\"",
                "opening_hours": "\"Mo-Th 07:00-09:00; Fr-Sa 07:00-10:00; Su 07:00-09:00\"",
                "phone": "\"865-988-3201\"",
                "street_address": "\"325 Ft Loudoun Medical Cent Dr\"",
                "website": "\"https://www.crackerbarrel.com/Locations/States/tn/lenoir-city/573\""
            },
            {
                "__id": 215,
                "address_city": "\"Sanford\"",
                "address_country": "\"US\"",
                "address_postcode": "\"32771-6915\"",
                "address_state": "\"FL\"",
                "latitude": 28.8141885,
                "longitude": -81.3355151,
                "name": "\"Sanford\"",
                "opening_hours": "\"Mo-Th 07:00-10:00; Fr-Sa 07:00-11:00; Su 07:00-10:00\"",
                "phone": "\"407-324-1020\"",
                "street_address": "\"200 Hickman Dr.\"",
                "website": "\"https://www.crackerbarrel.com/Locations/States/fl/sanford/190\""
            },
            {
                "__id": 618,
                "address_city": "\"Salisbury\"",
                "address_country": "\"US\"",
                "address_postcode": "\"28144-5173\"",
                "address_state": "\"NC\"",
                "latitude": 35.6572212,
                "longitude": -80.4626802,
                "name": "\"Salisbury\"",
                "opening_hours": "\"Mo-Th 07:00-09:00; Fr-Sa 07:00-10:00; Su 07:00-09:00\"",
                "phone": "\"704-636-1873\"",
                "street_address": "\"1070 Free Land Dr.\"",
                "website": "\"https://www.crackerbarrel.com/Locations/States/nc/salisbury/625\""
            },
            {
                "__id": 427,
                "address_city": "\"Daytona Beach\"",
                "address_country": "\"US\"",
                "address_postcode": "\"32114-1101\"",
                "address_state": "\"FL\"",
                "latitude": 29.182874,
                "longitude": -81.0776216,
                "name": "\"Daytona Beach\"",
                "opening_hours": "\"Mo-Th 07:00-10:00; Fr-Sa 07:00-11:00; Su 07:00-10:00\"",
                "phone": "\"386-248-0034\"",
                "street_address": "\"2325 W. International Speedway\"",
                "website": "\"https://www.crackerbarrel.com/Locations/States/fl/daytona-beach/158\""
            },
            {
                "__id": 127,
                "address_city": "\"Zanesville\"",
                "address_country": "\"US\"",
                "address_postcode": "\"43701-4090\"",
                "address_state": "\"OH\"",
                "latitude": 39.9436449,
                "longitude": -81.9999219,
                "name": "\"Zanesville\"",
                "opening_hours": "\"Mo-Th 07:00-09:00; Fr-Sa 07:00-10:00; Su 07:00-09:00\"",
                "phone": "\"740-450-8985\"",
                "street_address": "\"225 Scenic Crest Dr.\"",
                "website": "\"https://www.crackerbarrel.com/Locations/States/oh/zanesville/370\""
            },
            {
                "__id": 489,
                "address_city": "\"Ashland\"",
                "address_country": "\"US\"",
                "address_postcode": "\"23005-2233\"",
                "address_state": "\"VA\"",
                "latitude": 37.7578975,
                "longitude": -77.460052,
                "name": "\"Ashland\"",
                "opening_hours": "\"Mo-Th 06:00-10:00; Fr-Sa 06:00-11:00; Su 06:00-10:00\"",
                "phone": "\"804-798-1542\"",
                "street_address": "\"106 S Carter Rd.\"",
                "website": "\"https://www.crackerbarrel.com/Locations/States/va/ashland/248\""
            },
            {
                "__id": 520,
                "address_city": "\"Lansing\"",
                "address_country": "\"US\"",
                "address_postcode": "\"48917-9765\"",
                "address_state": "\"MI\"",
                "latitude": 42.7401941,
                "longitude": -84.6721849,
                "name": "\"Lansing\"",
                "opening_hours": "\"Mo-Th 07:00-09:00; Fr-Sa 07:00-10:00; Su 07:00-09:00\"",
                "phone": "\"517-627-7755\"",
                "street_address": "\"608 Commercial Dr.\"",
                "website": "\"https://www.crackerbarrel.com/Locations/States/mi/lansing/195\""
            },
            {
                "__id": 250,
                "address_city": "\"Jacksonville\"",
                "address_country": "\"US\"",
                "address_postcode": "\"32221-4108\"",
                "address_state": "\"FL\"",
                "latitude": 30.3085882,
                "longitude": -81.844763,
                "name": "\"Jacksonville (Chaffee Point)\"",
                "opening_hours": "\"Mo-Th 07:00-10:00; Fr-Sa 07:00-11:00; Su 07:00-10:00\"",
                "phone": "\"904-783-0254\"",
                "street_address": "\"502 Chaffee Point Rd.\"",
                "website": "\"https://www.crackerbarrel.com/Locations/States/fl/jacksonville/577\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "holland_and_barrett": {
        "__id": 102,
        "id": "holland_and_barrett",
        "name": "Holland & Barrett",
        "columnNames": "['address_city', 'address_postcode', 'address_state', 'image', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'website']",
        "rowLength": 1200,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763503,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of holland_and_barrett locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 328,
                "address_city": "\"Sussex\"",
                "address_postcode": "\"TN31 7JE\"",
                "address_state": "\"Rye\"",
                "image": "\"https://images.hollandandbarrettimages.co.uk/info/stores/1x1/hblogo.jpg\"",
                "latitude": 0.0,
                "longitude": 0.0,
                "name": "\"0330 058 2640\"",
                "opening_hours": "\"health_food\"",
                "phone": "\"91 High Street\"",
                "shop": "\"/stores/rye-4019/\"",
                "street_address": "\"\"",
                "website": "\"\""
            },
            {
                "__id": 1133,
                "address_city": "\"Yorkshire\"",
                "address_postcode": "\"YO19 4TA\"",
                "address_state": "\"York\"",
                "image": "\"https://images.hollandandbarrettimages.co.uk/info/stores/1x1/hblogo.jpg\"",
                "latitude": 53.922153,
                "longitude": -1.077467,
                "name": "\"Holland & Barrett | York Fulford (4073)\"",
                "opening_hours": "\"Mo-Fr 10:00-20:00; Sa 09:00-20:00; Su 10:00-18:00\"",
                "phone": "\"0330 058 2640\"",
                "shop": "\"health_food\"",
                "street_address": "\"Unit 72, South Mall\"",
                "website": "\"/stores/york-outlet-4073/\""
            },
            {
                "__id": 307,
                "address_city": "\"Zuid-Holland\"",
                "address_postcode": "\"2353 TR\"",
                "address_state": "\"Leiderdorp\"",
                "image": "\"https://images.hollandandbarrettimages.co.uk/info/stores/1x1/hblogo.jpg\"",
                "latitude": 52.16243,
                "longitude": 4.53289,
                "name": "\"Holland & Barrett | Leiderdorp Winkelhof (7807)\"",
                "opening_hours": "\"Mo 11:00-18:00; Tu-Th 09:00-18:00; Fr 09:00-20:00; Sa 09:00-17:00; Su 12:00-17:00\"",
                "phone": "\"071 5421 696\"",
                "shop": "\"health_food\"",
                "street_address": "\"Winkelhof 12/21, 2553 Tr\"",
                "website": "\"/winkels/leiderdorp-7807/\""
            },
            {
                "__id": 1093,
                "address_city": "\"\"",
                "address_postcode": "\"SK9 1DU\"",
                "address_state": "\"Wilmslow\"",
                "image": "\"https://images.hollandandbarrettimages.co.uk/info/stores/1x1/hblogo.jpg\"",
                "latitude": 53.32704,
                "longitude": -2.23098,
                "name": "\"Holland & Barrett | Wilmslow (3794)\"",
                "opening_hours": "\"Mo-Sa 09:00-17:30; Su 10:00-16:00\"",
                "phone": "\"0330 058 2640\"",
                "shop": "\"health_food\"",
                "street_address": "\"23 Grove Street, Wilmslow\"",
                "website": "\"/stores/wilmslow-3794/\""
            },
            {
                "__id": 69,
                "address_city": "\"Utrecht\"",
                "address_postcode": "\"3511 DN\"",
                "address_state": "\"Utrecht Hc\"",
                "image": "\"https://images.hollandandbarrettimages.co.uk/info/stores/1x1/hblogo.jpg\"",
                "latitude": 52.08954,
                "longitude": 5.11277,
                "name": "\"Holland & Barrett | Utrecht Hoog Catharijne (7611)\"",
                "opening_hours": "\"Mo 11:00-18:00; Tu-Sa 09:30-18:00; Su 12:00-18:00\"",
                "phone": "\"030 232 85 71\"",
                "shop": "\"health_food\"",
                "street_address": "\"Godebaldkwartier 49-51, 3511 Dn\"",
                "website": "\"/winkels/utrecht-hc-7611/\""
            },
            {
                "__id": 920,
                "address_city": "\"Ayrshire\"",
                "address_postcode": "\"KA12 8EH\"",
                "address_state": "\"Irvine\"",
                "image": "\"https://images.hollandandbarrettimages.co.uk/info/stores/1x1/hblogo.jpg\"",
                "latitude": 55.612905,
                "longitude": -4.671425,
                "name": "\"Holland & Barrett | Irvine (3467)\"",
                "opening_hours": "\"Mo-Sa 09:00-17:30; Su 11:00-17:00\"",
                "phone": "\"0330 058 2640\"",
                "shop": "\"health_food\"",
                "street_address": "\"11 Rivergate\"",
                "website": "\"/stores/irvine-3467/\""
            },
            {
                "__id": 779,
                "address_city": "\"Lincolnshire\"",
                "address_postcode": "\"PE21 6NF\"",
                "address_state": "\"Boston\"",
                "image": "\"https://images.hollandandbarrettimages.co.uk/info/stores/1x1/hblogo.jpg\"",
                "latitude": 52.977934,
                "longitude": -0.024412,
                "name": "\"Holland & Barrett | Boston (3419)\"",
                "opening_hours": "\"Mo-Sa 09:00-17:30; Su 10:00-16:00\"",
                "phone": "\"0330 058 2640\"",
                "shop": "\"health_food\"",
                "street_address": "\"47 Market Place\"",
                "website": "\"/stores/boston-3419/\""
            },
            {
                "__id": 1060,
                "address_city": "\"London\"",
                "address_postcode": "\"SW1E 5JL\"",
                "address_state": "\"London\"",
                "image": "\"https://images.hollandandbarrettimages.co.uk/info/stores/1x1/hblogo.jpg\"",
                "latitude": 51.496987,
                "longitude": -0.140029,
                "name": "\"Holland & Barrett | Victoria Street 2 (3743)\"",
                "opening_hours": "\"Mo-Fr 08:00-19:00; Sa-Su 10:00-18:00\"",
                "phone": "\"0330 058 2640\"",
                "shop": "\"health_food\"",
                "street_address": "\"86 Victoria Street, London\"",
                "website": "\"/stores/victoria-street-2-3743/\""
            },
            {
                "__id": 448,
                "address_city": "\"Cleveland\"",
                "address_postcode": "\"TS1 2LA\"",
                "address_state": "\"Middlesborough\"",
                "image": "\"https://images.hollandandbarrettimages.co.uk/info/stores/1x1/hblogo.jpg\"",
                "latitude": 54.575912,
                "longitude": -1.23687,
                "name": "\"Holland & Barrett | Middlesborough (3383)\"",
                "opening_hours": "\"Mo-Sa 09:00-17:30; Su 10:30-16:30\"",
                "phone": "\"0330 058 2640\"",
                "shop": "\"health_food\"",
                "street_address": "\"76-78 Cleveland Centre, Linthorpe Road\"",
                "website": "\"/stores/middlesbrough-3383/\""
            },
            {
                "__id": 1015,
                "address_city": "\"Warwickshire\"",
                "address_postcode": "\"CV6 6AS\"",
                "address_state": "\"Coventry Tesco\"",
                "image": "\"https://images.hollandandbarrettimages.co.uk/info/stores/1x1/hblogo.jpg\"",
                "latitude": 52.443771,
                "longitude": -1.491036,
                "name": "\"Holland & Barrett | Coventry Arena Tesco (4101)\"",
                "opening_hours": "\"Mo-Sa 09:00-20:00; Su 11:00-17:00\"",
                "phone": "\"0330 058 2640\"",
                "shop": "\"health_food\"",
                "street_address": "\"Holland & Barrett Store C/o Tesco, Phoenix Way Longford Road\"",
                "website": "\"/stores/coventy-tesco-4101/\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "thecheesecakefactory": {
        "__id": 103,
        "id": "thecheesecakefactory",
        "name": "The Cheesecake Factory",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'alt_name', 'amenity', 'cuisine', 'image', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'street_address', 'website']",
        "rowLength": 209,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763505,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of thecheesecakefactory locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 34,
                "address_city": "\"Murray\"",
                "address_country": "\"US\"",
                "address_postcode": "\"84107\"",
                "address_state": "\"UT\"",
                "alt_name": "\"Cheesecake Factory\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american\"",
                "image": "\"http://www.thecheesecakefactory.com/media/locationImages/locations_Default.jpg\"",
                "latitude": 40.6351163,
                "longitude": -111.8891896,
                "name": "\"The Cheesecake Factory\"",
                "opening_hours": "\"Mo-Th 11:00-22:00; Fr-Sa 11:00-23:00; Su 10:00-22:00\"",
                "phone": "\"801-266-9100\"",
                "street_address": "\"6223 S. State Street\"",
                "website": "\"https://locations.thecheesecakefactory.com/ut/murray-135.html\""
            },
            {
                "__id": 168,
                "address_city": "\"Boca Raton\"",
                "address_country": "\"US\"",
                "address_postcode": "\"33431\"",
                "address_state": "\"FL\"",
                "alt_name": "\"Cheesecake Factory\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american\"",
                "image": "\"http://www.thecheesecakefactory.com/media/locationImages/locations_Boca.jpg\"",
                "latitude": 26.3673414,
                "longitude": -80.1298607,
                "name": "\"The Cheesecake Factory\"",
                "opening_hours": "\"Mo-Th 11:00-22:00; Fr-Sa 11:00-23:00; Su 10:00-22:00\"",
                "phone": "\"561-393-0344\"",
                "street_address": "\"5530 Glades Road\"",
                "website": "\"https://locations.thecheesecakefactory.com/fl/boca-raton-11.html\""
            },
            {
                "__id": 126,
                "address_city": "\"Burlington\"",
                "address_country": "\"US\"",
                "address_postcode": "\"01803\"",
                "address_state": "\"MA\"",
                "alt_name": "\"Cheesecake Factory\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american\"",
                "image": "\"http://www.thecheesecakefactory.com/media/locationImages/locations_Burlington.jpg\"",
                "latitude": 42.482391,
                "longitude": -71.2136759,
                "name": "\"The Cheesecake Factory\"",
                "opening_hours": "\"Mo-Th 11:00-22:00; Fr-Sa 11:00-23:00; Su 10:00-22:00\"",
                "phone": "\"781-273-0060\"",
                "street_address": "\"75 Middlesex Turnpike\"",
                "website": "\"https://locations.thecheesecakefactory.com/ma/burlington-94.html\""
            },
            {
                "__id": 146,
                "address_city": "\"Atlanta\"",
                "address_country": "\"US\"",
                "address_postcode": "\"30346\"",
                "address_state": "\"GA\"",
                "alt_name": "\"Cheesecake Factory\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american\"",
                "image": "\"http://www.thecheesecakefactory.com/media/locationImages/locations_Perimeter.jpg\"",
                "latitude": 33.9245591,
                "longitude": -84.3409764,
                "name": "\"The Cheesecake Factory\"",
                "opening_hours": "\"Mo-Th 11:00-22:00; Fr-Sa 11:00-23:00; Su 10:00-22:00\"",
                "phone": "\"678-320-0201\"",
                "street_address": "\"4400 Ashford-Dunwoody Road\"",
                "website": "\"https://locations.thecheesecakefactory.com/ga/atlanta-35.html\""
            },
            {
                "__id": 58,
                "address_city": "\"San Juan\"",
                "address_country": "\"PR\"",
                "address_postcode": "\"00918\"",
                "address_state": "\"\"",
                "alt_name": "\"Cheesecake Factory\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american\"",
                "image": "\"http://www.thecheesecakefactory.com/media/locationImages/locations_Default.jpg\"",
                "latitude": 18.4220743,
                "longitude": -66.0742342,
                "name": "\"The Cheesecake Factory\"",
                "opening_hours": "\"Mo-Th 11:00-22:00; Fr-Sa 11:00-23:00; Su 10:00-22:00\"",
                "phone": "\"787-756-6200\"",
                "street_address": "\"525 F.D. Roosevelt Avenue\"",
                "website": "\"https://locations.thecheesecakefactory.com/san-juan/san-juan-165.html\""
            },
            {
                "__id": 17,
                "address_city": "\"Chattanooga\"",
                "address_country": "\"US\"",
                "address_postcode": "\"37421\"",
                "address_state": "\"TN\"",
                "alt_name": "\"Cheesecake Factory\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american\"",
                "image": "\"https://www.thecheesecakefactory.com/media/locationImages/locations_Default.jpg\"",
                "latitude": 35.0328102,
                "longitude": -85.1611415,
                "name": "\"The Cheesecake Factory\"",
                "opening_hours": "\"Mo-Th 11:30-22:00; Fr-Sa 11:30-23:00; Su 10:00-22:00\"",
                "phone": "\"423-499-4447\"",
                "street_address": "\"2084 Hamilton Place Boulevard\"",
                "website": "\"https://locations.thecheesecakefactory.com/tn/chattanooga-208.html\""
            },
            {
                "__id": 95,
                "address_city": "\"Short Hills\"",
                "address_country": "\"US\"",
                "address_postcode": "\"07078\"",
                "address_state": "\"NJ\"",
                "alt_name": "\"Cheesecake Factory\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american\"",
                "image": "\"http://www.thecheesecakefactory.com/media/locationImages/locations_Default.jpg\"",
                "latitude": 40.7408743,
                "longitude": -74.3642457,
                "name": "\"The Cheesecake Factory\"",
                "opening_hours": "\"Mo-Th 11:00-22:00; Fr-Sa 11:00-23:00; Su 10:00-22:00\"",
                "phone": "\"973-921-0930\"",
                "street_address": "\"1200 Morris Turnpike\"",
                "website": "\"https://locations.thecheesecakefactory.com/nj/short-hills-154.html\""
            },
            {
                "__id": 113,
                "address_city": "\"Edina\"",
                "address_country": "\"US\"",
                "address_postcode": "\"55435\"",
                "address_state": "\"MN\"",
                "alt_name": "\"Cheesecake Factory\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american\"",
                "image": "\"http://www.thecheesecakefactory.com/media/locationImages/locations_Default.jpg\"",
                "latitude": 44.8798194,
                "longitude": -93.3250752,
                "name": "\"The Cheesecake Factory\"",
                "opening_hours": "\"Mo-Th 11:00-22:00; Fr-Sa 11:00-23:00; Su 10:00-22:00\"",
                "phone": "\"952-653-3333\"",
                "street_address": "\"2715 Southdale Center\"",
                "website": "\"https://locations.thecheesecakefactory.com/mn/edina-53.html\""
            },
            {
                "__id": 154,
                "address_city": "\"Sarasota\"",
                "address_country": "\"US\"",
                "address_postcode": "\"34243\"",
                "address_state": "\"FL\"",
                "alt_name": "\"Cheesecake Factory\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american\"",
                "image": "\"http://www.thecheesecakefactory.com/media/locationImages/locations_Default.jpg\"",
                "latitude": 27.3845419,
                "longitude": -82.4531109,
                "name": "\"The Cheesecake Factory\"",
                "opening_hours": "\"Mo-Th 11:00-22:00; Fr-Sa 11:00-23:00; Su 10:00-22:00\"",
                "phone": "\"941-256-3760\"",
                "street_address": "\"130 University Town Center Drive\"",
                "website": "\"https://locations.thecheesecakefactory.com/fl/sarasota-175.html\""
            },
            {
                "__id": 137,
                "address_city": "\"Orland Park\"",
                "address_country": "\"US\"",
                "address_postcode": "\"60462\"",
                "address_state": "\"IL\"",
                "alt_name": "\"Cheesecake Factory\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american\"",
                "image": "\"http://www.thecheesecakefactory.com/media/locationImages/locations_Default.jpg\"",
                "latitude": 41.620151,
                "longitude": -87.8494888,
                "name": "\"The Cheesecake Factory\"",
                "opening_hours": "\"Mo-Th 11:00-22:00; Fr-Sa 11:00-23:00; Su 10:00-22:00\"",
                "phone": "\"708-873-3747\"",
                "street_address": "\"304 Orland Square Drive\"",
                "website": "\"https://locations.thecheesecakefactory.com/il/orland-park-163.html\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "foodlion": {
        "__id": 104,
        "id": "foodlion",
        "name": "Food Lion",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'website']",
        "rowLength": 175,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763506,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of foodlion locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 10,
                "address_city": "\"Glen Burnie\"",
                "address_country": "\"US\"",
                "address_full": "\"121 Crain Hwy N\"",
                "address_postcode": "\"21061\"",
                "address_state": "\"MD\"",
                "latitude": 39.16510009765625,
                "longitude": -76.62339782714844,
                "name": "\"Food Lion Grocery Store of Glen Burnie\"",
                "opening_hours": "\"07:00-23:00\"",
                "phone": "\"410-863-4957\"",
                "shop": "\"supermarket\"",
                "website": "\"https://www.foodlion.com/content/food-lion/stores/md/glen_burnie/1543/\""
            },
            {
                "__id": 56,
                "address_city": "\"Manassas\"",
                "address_country": "\"US\"",
                "address_full": "\"13490 Dumfries Road\"",
                "address_postcode": "\"20112\"",
                "address_state": "\"VA\"",
                "latitude": 38.6598014831543,
                "longitude": -77.44139862060547,
                "name": "\"Food Lion Grocery Store of Manassas\"",
                "opening_hours": "\"07:00-23:00\"",
                "phone": "\"703-791-5360\"",
                "shop": "\"supermarket\"",
                "website": "\"https://www.foodlion.com/content/food-lion/stores/va/manassas/1208/\""
            },
            {
                "__id": 148,
                "address_city": "\"Royston\"",
                "address_country": "\"US\"",
                "address_full": "\"1041 Franklin Springs St.\"",
                "address_postcode": "\"30662\"",
                "address_state": "\"GA\"",
                "latitude": 34.283512115478516,
                "longitude": -83.12809753417969,
                "name": "\"Food Lion Grocery Store of Royston\"",
                "opening_hours": "\"07:00-23:00\"",
                "phone": "\"706-245-6345\"",
                "shop": "\"supermarket\"",
                "website": "\"https://www.foodlion.com/content/food-lion/stores/ga/royston/2841/\""
            },
            {
                "__id": 78,
                "address_city": "\"Marshall\"",
                "address_country": "\"US\"",
                "address_full": "\"4195 Winchester Rd\"",
                "address_postcode": "\"20115\"",
                "address_state": "\"VA\"",
                "latitude": 38.863800048828125,
                "longitude": -77.8478012084961,
                "name": "\"Food Lion Grocery Store of Marshall\"",
                "opening_hours": "\"07:00-23:00\"",
                "phone": "\"540-364-3439\"",
                "shop": "\"supermarket\"",
                "website": "\"https://www.foodlion.com/content/food-lion/stores/va/marshall/1588/\""
            },
            {
                "__id": 91,
                "address_city": "\"Warsaw\"",
                "address_country": "\"US\"",
                "address_full": "\"4665 Richmond Road\"",
                "address_postcode": "\"22572\"",
                "address_state": "\"VA\"",
                "latitude": 37.96329879760742,
                "longitude": -76.7771987915039,
                "name": "\"Food Lion Grocery Store of Warsaw\"",
                "opening_hours": "\"07:00-22:00\"",
                "phone": "\"804-333-6368\"",
                "shop": "\"supermarket\"",
                "website": "\"https://www.foodlion.com/content/food-lion/stores/va/warsaw/1196/\""
            },
            {
                "__id": 166,
                "address_city": "\"Greenville\"",
                "address_country": "\"US\"",
                "address_full": "\"101 Verdae Blvd., Ste. 1200\"",
                "address_postcode": "\"29607\"",
                "address_state": "\"SC\"",
                "latitude": 34.8206672668457,
                "longitude": -82.33995056152344,
                "name": "\"Food Lion Grocery Store of Greenville\"",
                "opening_hours": "\"07:00-23:00\"",
                "phone": "\"864-627-4490\"",
                "shop": "\"supermarket\"",
                "website": "\"https://www.foodlion.com/content/food-lion/stores/sc/greenville/2829/\""
            },
            {
                "__id": 169,
                "address_city": "\"Salisbury\"",
                "address_country": "\"US\"",
                "address_full": "\"525 Jake Alexander Blvd W\"",
                "address_postcode": "\"28147\"",
                "address_state": "\"NC\"",
                "latitude": 35.66041946411133,
                "longitude": -80.50052642822266,
                "name": "\"Food Lion Grocery Store of Salisbury\"",
                "opening_hours": "\"06:00-23:00\"",
                "phone": "\"704-636-7615\"",
                "shop": "\"supermarket\"",
                "website": "\"https://www.foodlion.com/content/food-lion/stores/nc/salisbury/10/\""
            },
            {
                "__id": 127,
                "address_city": "\"Livingston\"",
                "address_country": "\"US\"",
                "address_full": "\"400 Market Sq Plaza\"",
                "address_postcode": "\"38570\"",
                "address_state": "\"TN\"",
                "latitude": 36.39670181274414,
                "longitude": -85.32150268554688,
                "name": "\"Food Lion Grocery Store of Livingston\"",
                "opening_hours": "\"07:00-23:00\"",
                "phone": "\"931-823-8990\"",
                "shop": "\"supermarket\"",
                "website": "\"https://www.foodlion.com/content/food-lion/stores/tn/livingston/1381/\""
            },
            {
                "__id": 122,
                "address_city": "\"Clarksville\"",
                "address_country": "\"US\"",
                "address_full": "\"1764 Hwy 48\"",
                "address_postcode": "\"37040\"",
                "address_state": "\"TN\"",
                "latitude": 36.47819900512695,
                "longitude": -87.37699890136719,
                "name": "\"Food Lion Grocery Store of Clarksville\"",
                "opening_hours": "\"07:00-23:00\"",
                "phone": "\"931-920-5953\"",
                "shop": "\"supermarket\"",
                "website": "\"https://www.foodlion.com/content/food-lion/stores/tn/clarksville/1473/\""
            },
            {
                "__id": 54,
                "address_city": "\"Taneytown\"",
                "address_country": "\"US\"",
                "address_full": "\"511 East Baltimore St\"",
                "address_postcode": "\"21787\"",
                "address_state": "\"MD\"",
                "latitude": 39.65129852294922,
                "longitude": -77.15799713134766,
                "name": "\"Food Lion Grocery Store of Taneytown\"",
                "opening_hours": "\"07:00-23:00\"",
                "phone": "\"410-756-1443\"",
                "shop": "\"supermarket\"",
                "website": "\"https://www.foodlion.com/content/food-lion/stores/md/taneytown/950/\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "brandywine_living": {
        "__id": 105,
        "id": "brandywine_living",
        "name": "Brandywine Living",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'name', 'phone', 'website']",
        "rowLength": 32,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763506,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of brandywine_living locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 3,
                "address_city": "\" East Norriton\"",
                "address_country": "\"US\"",
                "address_full": "\"2101 New Hope Street\"",
                "address_postcode": "\"19401\"",
                "address_state": "\" Pennsylvania \"",
                "name": "\" Senior Suites \"",
                "phone": "\"(610) 272-6200\"",
                "website": "\"https://www.brandycare.com/our-communities/senior-suites/\""
            },
            {
                "__id": 26,
                "address_city": "\" Howell\"",
                "address_country": "\"US\"",
                "address_full": "\"100 Meridian Place\"",
                "address_postcode": "\"07731\"",
                "address_state": "\" New Jersey \"",
                "name": "\" Howell \"",
                "phone": "\"(732) 719-0100\"",
                "website": "\"https://www.brandycare.com/our-communities/howell/\""
            },
            {
                "__id": 2,
                "address_city": "\" Phoenixville\"",
                "address_country": "\"US\"",
                "address_full": "\"1133 Black Rock Road\"",
                "address_postcode": "\"19460\"",
                "address_state": "\" Pennsylvania \"",
                "name": "\" Upper Providence \"",
                "phone": "\"(610) 933-3250\"",
                "website": "\"https://www.brandycare.com/our-communities/upper-providence/\""
            },
            {
                "__id": 12,
                "address_city": "\" Voorhees\"",
                "address_country": "\"US\"",
                "address_full": "\"311 Route 73\"",
                "address_postcode": "\"08043\"",
                "address_state": "\" New Jersey \"",
                "name": "\" Voorhees \"",
                "phone": "\"(856) 424-0004\"",
                "website": "\"https://www.brandycare.com/our-communities/voorhees/\""
            },
            {
                "__id": 23,
                "address_city": "\" Bridgewater\"",
                "address_country": "\"US\"",
                "address_full": "\"2005 Route 22 West\"",
                "address_postcode": "\"08807\"",
                "address_state": "\" New Jersey \"",
                "name": "\" Middlebrook Crossing \"",
                "phone": "\"(732) 868-8181\"",
                "website": "\"https://www.brandycare.com/our-communities/middlebrook-crossing/\""
            },
            {
                "__id": 28,
                "address_city": "\" Englishtown\"",
                "address_country": "\"US\"",
                "address_full": "\"49 LaSatta Avenue\"",
                "address_postcode": "\"07726\"",
                "address_state": "\" New Jersey \"",
                "name": "\" Governor\u2019s Crossing \"",
                "phone": "\"(732) 786-1000\"",
                "website": "\"https://www.brandycare.com/our-communities/governors-crossing/\""
            },
            {
                "__id": 25,
                "address_city": "\" Livingston\"",
                "address_country": "\"US\"",
                "address_full": "\"369 East Mount Pleasant Avenue \"",
                "address_postcode": "\"07039\"",
                "address_state": "\" New Jersey \"",
                "name": "\" Livingston \"",
                "phone": "\"(973) 251-0600\"",
                "website": "\"https://www.brandycare.com/our-communities/livingston/\""
            },
            {
                "__id": 5,
                "address_city": "\" Haverford\"",
                "address_country": "\"US\"",
                "address_full": "\"731 Old Buck Lane\"",
                "address_postcode": "\"19041\"",
                "address_state": "\" Pennsylvania \"",
                "name": "\" Haverford Estates \"",
                "phone": "\"(610) 527-1800 \"",
                "website": "\"https://www.brandycare.com/our-communities/haverford-estates/\""
            },
            {
                "__id": 14,
                "address_city": "\" Shrewsbury\"",
                "address_country": "\"US\"",
                "address_full": "\"5 Meridian Way \"",
                "address_postcode": "\"07702\"",
                "address_state": "\" New Jersey \"",
                "name": "\" The Sycamore \"",
                "phone": "\"(732) 212-2600\"",
                "website": "\"https://www.brandycare.com/our-communities/the-sycamore/\""
            },
            {
                "__id": 21,
                "address_city": "\" Watchung\"",
                "address_country": "\"US\"",
                "address_full": "\"680 Mountain Blvd.\"",
                "address_postcode": "\"07069\"",
                "address_state": "\" New Jersey \"",
                "name": "\" Mountain Ridge \"",
                "phone": "\"(908) 754-8180\"",
                "website": "\"https://www.brandycare.com/our-communities/mountain-ridge/\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "edwardjones": {
        "__id": 106,
        "id": "edwardjones",
        "name": "Edward Jones",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'alt_name', 'image', 'latitude', 'longitude', 'name', 'office', 'phone', 'street_address', 'website']",
        "rowLength": 17896,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763508,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of edwardjones locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 9072,
                "address_city": "\"Waco\"",
                "address_country": "\"US\"",
                "address_postcode": "\"76710\"",
                "alt_name": "\"Edward Jones Investments\"",
                "image": "\"https://edwardjoneslive.pc.cdn.bitgravity.com/assets/fa-pictures/600x900/1346784.jpg\"",
                "latitude": 31.532705,
                "longitude": -97.188244,
                "name": "\"Matt Rollins\"",
                "office": "\"financial_advisor\"",
                "phone": "\"2543996597\"",
                "street_address": "\"1105 Wooded Acres DriveSuite 230\"",
                "website": "\"https://www.edwardjones.com/us-en/financial-advisor/matt-rollins\""
            },
            {
                "__id": 1329,
                "address_city": "\"Norwood Young Americ\"",
                "address_country": "\"US\"",
                "address_postcode": "\"55368\"",
                "alt_name": "\"Edward Jones Investments\"",
                "image": "\"https://edwardjoneslive.pc.cdn.bitgravity.com/assets/fa-pictures/600x900/1761647.jpg\"",
                "latitude": 44.771489,
                "longitude": -93.919502,
                "name": "\"Jason A Kuerschner\"",
                "office": "\"financial_advisor\"",
                "phone": "\"9524672380\"",
                "street_address": "\"515 Faxon Rd\"",
                "website": "\"https://www.edwardjones.com/us-en/financial-advisor/jason-kuerschner\""
            },
            {
                "__id": 2672,
                "address_city": "\"Lees Summit\"",
                "address_country": "\"US\"",
                "address_postcode": "\"64063\"",
                "alt_name": "\"Edward Jones Investments\"",
                "image": "\"https://edwardjoneslive.pc.cdn.bitgravity.com/assets/fa-pictures/600x900/14403.jpg\"",
                "latitude": 38.913819,
                "longitude": -94.377075,
                "name": "\"Mark A Sandy\"",
                "office": "\"financial_advisor\"",
                "phone": "\"8162725650\"",
                "street_address": "\"200 S.E. Douglas Ste 105\"",
                "website": "\"https://www.edwardjones.com/us-en/financial-advisor/mark-sandy\""
            },
            {
                "__id": 1319,
                "address_city": "\"Layton\"",
                "address_country": "\"US\"",
                "address_postcode": "\"84041\"",
                "alt_name": "\"Edward Jones Investments\"",
                "image": "\"https://edwardjoneslive.pc.cdn.bitgravity.com/assets/fa-pictures/600x900/1575847.jpg\"",
                "latitude": 41.086368,
                "longitude": -111.998658,
                "name": "\"Jared W Davis\"",
                "office": "\"financial_advisor\"",
                "phone": "\"8015254585\"",
                "street_address": "\"1771 North MainSuite 3\"",
                "website": "\"https://www.edwardjones.com/us-en/financial-advisor/jared-davis\""
            },
            {
                "__id": 2326,
                "address_city": "\"Winona\"",
                "address_country": "\"US\"",
                "address_postcode": "\"55987\"",
                "alt_name": "\"Edward Jones Investments\"",
                "image": "\"https://edwardjoneslive.pc.cdn.bitgravity.com/assets/fa-pictures/600x900/1039423.jpg\"",
                "latitude": 44.05221,
                "longitude": -91.63323,
                "name": "\"John M Hardy\"",
                "office": "\"financial_advisor\"",
                "phone": "\"5074545068\"",
                "street_address": "\"102 Walnut Street #101\"",
                "website": "\"https://www.edwardjones.com/us-en/financial-advisor/john-hardy\""
            },
            {
                "__id": 12469,
                "address_city": "\"Solon\"",
                "address_country": "\"US\"",
                "address_postcode": "\"44139\"",
                "alt_name": "\"Edward Jones Investments\"",
                "image": "\"https://edwardjoneslive.pc.cdn.bitgravity.com/assets/fa-pictures/600x900/1429645.jpg\"",
                "latitude": 41.389618,
                "longitude": -81.437787,
                "name": "\"Craig George\"",
                "office": "\"financial_advisor\"",
                "phone": "\"4402487734\"",
                "street_address": "\"6240 Som Center RoadSuite 110\"",
                "website": "\"https://www.edwardjones.com/us-en/financial-advisor/craig-george\""
            },
            {
                "__id": 11672,
                "address_city": "\"Dublin\"",
                "address_country": "\"US\"",
                "address_postcode": "\"43017\"",
                "alt_name": "\"Edward Jones Investments\"",
                "image": "\"https://edwardjoneslive.pc.cdn.bitgravity.com/assets/fa-pictures/600x900/1176892.jpg\"",
                "latitude": 40.094938,
                "longitude": -83.135252,
                "name": "\"Kyle Hatfield\"",
                "office": "\"financial_advisor\"",
                "phone": "\"6147649119\"",
                "street_address": "\"655 Metro Place SouthSuite 620\"",
                "website": "\"https://www.edwardjones.com/us-en/financial-advisor/kyle-hatfield\""
            },
            {
                "__id": 14253,
                "address_city": "\"Pensacola\"",
                "address_country": "\"US\"",
                "address_postcode": "\"32504\"",
                "alt_name": "\"Edward Jones Investments\"",
                "image": "\"https://edwardjoneslive.pc.cdn.bitgravity.com/assets/fa-pictures/600x900/80295.jpg\"",
                "latitude": 30.495367,
                "longitude": -87.19805,
                "name": "\"Joel C Dunham\"",
                "office": "\"financial_advisor\"",
                "phone": "\"8504763682\"",
                "street_address": "\"2180 Creighton Road\"",
                "website": "\"https://www.edwardjones.com/us-en/financial-advisor/joel-dunham\""
            },
            {
                "__id": 11332,
                "address_city": "\"Camarillo\"",
                "address_country": "\"US\"",
                "address_postcode": "\"93010\"",
                "alt_name": "\"Edward Jones Investments\"",
                "image": "\"https://edwardjoneslive.pc.cdn.bitgravity.com/assets/fa-pictures/600x900/1814200.jpg\"",
                "latitude": 34.2387,
                "longitude": -118.98869,
                "name": "\"Roman Kisz\"",
                "office": "\"financial_advisor\"",
                "phone": "\"8057642010\"",
                "street_address": "\"634 Camino Tierra SantaSuite 104\"",
                "website": "\"https://www.edwardjones.com/us-en/financial-advisor/roman-kisz\""
            },
            {
                "__id": 4637,
                "address_city": "\"Chesterfield\"",
                "address_country": "\"US\"",
                "address_postcode": "\"63017\"",
                "alt_name": "\"Edward Jones Investments\"",
                "image": "\"https://edwardjoneslive.pc.cdn.bitgravity.com/assets/fa-pictures/600x900/340489.jpg\"",
                "latitude": 38.652135,
                "longitude": -90.570321,
                "name": "\"Andre K Nelson\"",
                "office": "\"financial_advisor\"",
                "phone": "\"6367352204\"",
                "street_address": "\"16150 Main Circle Dr Suite 110\"",
                "website": "\"https://www.edwardjones.com/us-en/financial-advisor/andre-nelson\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "caliber_home_loans": {
        "__id": 107,
        "id": "caliber_home_loans",
        "name": "Caliber Home Loans",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'website']",
        "rowLength": 235,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763510,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of caliber_home_loans locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 52,
                "address_city": "\"Tampa\"",
                "address_country": "\"US\"",
                "address_full": "\"15310 Amberly Dr.  Ste. 250  Office 60\"",
                "address_postcode": "\"33647\"",
                "address_state": "\"FL\"",
                "latitude": 28.091158,
                "longitude": -82.4036589,
                "name": "\"19548 - Tampa - 15310 Amberly Dr.\"",
                "website": "\"https://www.caliberhomeloans.com/Home/BranchList?stateCode=FL&LCSpeciality=all&SpanishSpeaking=no\""
            },
            {
                "__id": 225,
                "address_city": "\"Silverdale\"",
                "address_country": "\"US\"",
                "address_full": "\"9226 Bayshore Drive NW Suites 200 & 224\"",
                "address_postcode": "\"98383\"",
                "address_state": "\"WA\"",
                "latitude": 47.6464033,
                "longitude": -122.6934937,
                "name": "\"Silverdale\"",
                "website": "\"https://www.caliberhomeloans.com/Home/BranchList?stateCode=WA&LCSpeciality=all&SpanishSpeaking=no\""
            },
            {
                "__id": 219,
                "address_city": "\"Tacoma\"",
                "address_country": "\"US\"",
                "address_full": "\"6050 Tacoma Mall Boulevard  Suite 200\"",
                "address_postcode": "\"98409\"",
                "address_state": "\"WA\"",
                "latitude": 47.201176,
                "longitude": -122.4635688,
                "name": "\"18337 - Tacoma - 6050 Tacoma Mall Boulevard\"",
                "website": "\"https://www.caliberhomeloans.com/Home/BranchList?stateCode=WA&LCSpeciality=all&SpanishSpeaking=no\""
            },
            {
                "__id": 43,
                "address_city": "\"Miramar\"",
                "address_country": "\"US\"",
                "address_full": "\"2750 SW 145th Ave Suite 309\"",
                "address_postcode": "\"33027\"",
                "address_state": "\"FL\"",
                "latitude": 25.9838224,
                "longitude": -80.3422854,
                "name": "\"19260 - Miramar - 2750 SW 145th Ave\"",
                "website": "\"https://www.caliberhomeloans.com/Home/BranchList?stateCode=FL&LCSpeciality=all&SpanishSpeaking=no\""
            },
            {
                "__id": 8,
                "address_city": "\"Santa Ana\"",
                "address_country": "\"US\"",
                "address_full": "\"1403 N. Tustin Ave.  Ste. 250\"",
                "address_postcode": "\"92705\"",
                "address_state": "\"CA\"",
                "latitude": 33.7574537,
                "longitude": -117.8321155,
                "name": "\"19103 - Santa Ana BHL - 1403 N. Tustin Ave.\"",
                "website": "\"https://www.caliberhomeloans.com/Home/BranchList?stateCode=CA&LCSpeciality=all&SpanishSpeaking=no\""
            },
            {
                "__id": 98,
                "address_city": "\"Overland Park\"",
                "address_country": "\"US\"",
                "address_full": "\"10975 Grandview St. Suite 175\"",
                "address_postcode": "\"66210\"",
                "address_state": "\"KS\"",
                "latitude": 38.928769,
                "longitude": -94.6902843,
                "name": "\"19243 - Overland Park - 10975 Grandview St.\"",
                "website": "\"https://www.caliberhomeloans.com/Home/BranchList?stateCode=KS&LCSpeciality=all&SpanishSpeaking=no\""
            },
            {
                "__id": 46,
                "address_city": "\"Miami\"",
                "address_country": "\"US\"",
                "address_full": "\"7190 SW 87th Avenue Suite 405 406\"",
                "address_postcode": "\"33173\"",
                "address_state": "\"FL\"",
                "latitude": 25.7029509,
                "longitude": -80.3346272,
                "name": "\"19496 - Miami - 7190 SW 87th Avenue\"",
                "website": "\"https://www.caliberhomeloans.com/Home/BranchList?stateCode=FL&LCSpeciality=all&SpanishSpeaking=no\""
            },
            {
                "__id": 199,
                "address_city": "\"Vancouver\"",
                "address_country": "\"US\"",
                "address_full": "\"201 NE Park Plaza Dr. Suite 250\"",
                "address_postcode": "\"98684\"",
                "address_state": "\"WA\"",
                "latitude": 45.6227063,
                "longitude": -122.5391056,
                "name": "\"19489 - Vancouver - 201 NE Park Plaza Dr.\"",
                "website": "\"https://www.caliberhomeloans.com/Home/BranchList?stateCode=WA&LCSpeciality=all&SpanishSpeaking=no\""
            },
            {
                "__id": 218,
                "address_city": "\"Bothell\"",
                "address_country": "\"US\"",
                "address_full": "\"18704 Bothell Way NE  Suite 200\"",
                "address_postcode": "\"98011\"",
                "address_state": "\"WA\"",
                "latitude": 47.7636011,
                "longitude": -122.2073252,
                "name": "\"19000 - Canyon Park - 18704 Bothell Way NE\"",
                "website": "\"https://www.caliberhomeloans.com/Home/BranchList?stateCode=WA&LCSpeciality=all&SpanishSpeaking=no\""
            },
            {
                "__id": 226,
                "address_city": "\"Marysville\"",
                "address_country": "\"US\"",
                "address_full": "\"2704 171st Place NE  Ste. 103\"",
                "address_postcode": "\"98271\"",
                "address_state": "\"WA\"",
                "latitude": 48.1506959,
                "longitude": -122.1933914,
                "name": "\"19583 - Marysville - 2704 171st Place NE\"",
                "website": "\"https://www.caliberhomeloans.com/Home/BranchList?stateCode=WA&LCSpeciality=all&SpanishSpeaking=no\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "carrefour_fr": {
        "__id": 108,
        "id": "carrefour_fr",
        "name": "Carrefour France",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'store_type', 'street_address', 'website']",
        "rowLength": 3591,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763511,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of carrefour_fr locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 682,
                "address_city": "\"Cremieu\"",
                "address_country": "\"FR\"",
                "address_postcode": "\"38460\"",
                "latitude": 45.72283565,
                "longitude": 5.23929165,
                "name": "\"Carrefour Market Cremieu\"",
                "opening_hours": "\"Mo-Sa 08:30-20:00; Su 08:30-12:30\"",
                "phone": "\"0033474908899\"",
                "shop": "\"\"",
                "store_type": "\"CARREFOUR MARKET\"",
                "street_address": "\"Route De Lyon\"",
                "website": "\"https://www.carrefour.fr/magasin/market-cremieu\""
            },
            {
                "__id": 2573,
                "address_city": "\"Criel Sur Mer\"",
                "address_country": "\"FR\"",
                "address_postcode": "\"76910\"",
                "latitude": 50.01156362,
                "longitude": 1.31509493,
                "name": "\"Carrefour Contact Criel Sur Mer\"",
                "opening_hours": "\"Mo-Sa 08:00-20:00; Su 09:00-13:00\"",
                "phone": "\"0033235041640\"",
                "shop": "\"\"",
                "store_type": "\"CARREFOUR CONTACT\"",
                "street_address": "\"Route De Touffreville\"",
                "website": "\"https://www.carrefour.fr/magasin/contact-criel-sur-mer\""
            },
            {
                "__id": 2018,
                "address_city": "\"Paris\"",
                "address_country": "\"FR\"",
                "address_postcode": "\"75009\"",
                "latitude": 48.87602543,
                "longitude": 2.34060727,
                "name": "\"Carrefour City Paris Chateaudun\"",
                "opening_hours": "\"Mo-Sa 07:00-23:00; Su 09:00-13:00\"",
                "phone": "\"0033140359849\"",
                "shop": "\"\"",
                "store_type": "\"CARREFOUR CITY\"",
                "street_address": "\"12 rue Chateaudun\"",
                "website": "\"https://www.carrefour.fr/magasin/city-paris-chateaudun\""
            },
            {
                "__id": 3101,
                "address_city": "\"Hourtin\"",
                "address_country": "\"FR\"",
                "address_postcode": "\"33990\"",
                "latitude": 45.18667133,
                "longitude": -1.05378467,
                "name": "\"Carrefour Market Hourtin\"",
                "opening_hours": "\"Mo-Sa 08:30-19:30; Su 09:00-12:30\"",
                "phone": "\"0033556732305\"",
                "shop": "\"\"",
                "store_type": "\"MARKET\"",
                "street_address": "\"1 bis rue Cantelaude\"",
                "website": "\"https://www.carrefour.fr/magasin/market-hourtin\""
            },
            {
                "__id": 2815,
                "address_city": "\"Le Havre\"",
                "address_country": "\"FR\"",
                "address_postcode": "\"76600\"",
                "latitude": 49.49866722,
                "longitude": 0.11910886,
                "name": "\"Carrefour City Le Havre Joffre\"",
                "opening_hours": "\"Mo-Sa 07:00-21:00; Su 09:00-13:00\"",
                "phone": "\"0033235229105\"",
                "shop": "\"\"",
                "store_type": "\"CARREFOUR CITY\"",
                "street_address": "\"18 rue Du Marechal Joffre\"",
                "website": "\"https://www.carrefour.fr/magasin/city-le-havre-joffre\""
            },
            {
                "__id": 3354,
                "address_city": "\"Rennes\"",
                "address_country": "\"FR\"",
                "address_postcode": "\"35000\"",
                "latitude": 48.10545985,
                "longitude": -1.67793533,
                "name": "\"Carrefour Market Rennes 3 Soleils\"",
                "opening_hours": "\"Mo-Sa 09:00-20:00\"",
                "phone": "\"0033299314639\"",
                "shop": "\"\"",
                "store_type": "\"MARKET\"",
                "street_address": "\"18 rue D'Isly\"",
                "website": "\"https://www.carrefour.fr/magasin/market-rennes-3-soleils\""
            },
            {
                "__id": 1827,
                "address_city": "\"Wissous\"",
                "address_country": "\"FR\"",
                "address_postcode": "\"91320\"",
                "latitude": 48.73733431,
                "longitude": 2.32785164,
                "name": "\"Carrefour Contact Wissous\"",
                "opening_hours": "\"Mo-Sa 08:00-20:00; Su 09:00-13:00\"",
                "phone": "\"0033164470538\"",
                "shop": "\"\"",
                "store_type": "\"CARREFOUR CONTACT\"",
                "street_address": "\"1 chemin De La Vall\u00e9e\"",
                "website": "\"https://www.carrefour.fr/magasin/contact-wissous\""
            },
            {
                "__id": 776,
                "address_city": "\"Lyon\"",
                "address_country": "\"FR\"",
                "address_postcode": "\"69008\"",
                "latitude": 45.734888,
                "longitude": 4.87488099,
                "name": "\"Carrefour City Lyon Mermoz\"",
                "opening_hours": "\"Mo-Sa 07:00-22:00; Su 09:00-13:00\"",
                "phone": "\"0033478000577\"",
                "shop": "\"\"",
                "store_type": "\"CARREFOUR CITY\"",
                "street_address": "\"51 avenue Jean Mermoz\"",
                "website": "\"https://www.carrefour.fr/magasin/city-lyon-mermoz\""
            },
            {
                "__id": 748,
                "address_city": "\"Chalon Sur Saone\"",
                "address_country": "\"FR\"",
                "address_postcode": "\"71100\"",
                "latitude": 46.78596093,
                "longitude": 4.85716791,
                "name": "\"Carrefour City Chalon Belfort\"",
                "opening_hours": "\"Mo-Sa 07:00-22:00; Su 09:00-19:00\"",
                "phone": "\"0033385972783\"",
                "shop": "\"\"",
                "store_type": "\"CARREFOUR CITY\"",
                "street_address": "\"19 rue De Belfort\"",
                "website": "\"https://www.carrefour.fr/magasin/city-chalon-belfort\""
            },
            {
                "__id": 648,
                "address_city": "\"Quetigny\"",
                "address_country": "\"FR\"",
                "address_postcode": "\"21800\"",
                "latitude": 47.310039,
                "longitude": 5.106526,
                "name": "\"Carrefour None\"",
                "opening_hours": "\"24/7\"",
                "phone": "\"0033380560748\"",
                "shop": "\"\"",
                "store_type": "\"\"",
                "street_address": "\"avenue De Bourgogne\"",
                "website": "\"https://www.carrefour.fr/magasin/quetigny\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "trader_joes": {
        "__id": 109,
        "id": "trader_joes",
        "name": "Trader Joe's",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'image', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'website']",
        "rowLength": 547,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763513,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of trader_joes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 172,
                "address_city": "\"New York\"",
                "address_country": "\"US\"",
                "address_postcode": "\"10003\"",
                "address_state": "\"NY\"",
                "image": "\"https://contentstatic.com/9859362e-927a-444e-a634-c92f77f75d7b.jpeg\"",
                "latitude": 40.733371,
                "longitude": -73.9878966,
                "name": "\"Traderjoes\"",
                "opening_hours": "\"Mo-Su 08:00-21:00\"",
                "phone": "\"212-529-4612\"",
                "shop": "\"supermarket\"",
                "street_address": "\"142 E 14th St\"",
                "website": "\"https://locations.traderjoes.com/ny/new-york/540\""
            },
            {
                "__id": 507,
                "address_city": "\"Brentwood\"",
                "address_country": "\"US\"",
                "address_postcode": "\"94513\"",
                "address_state": "\"CA\"",
                "image": "\"https://contentstatic.com/658bd462-4301-4601-9f5d-9a7c1c3e0a26.jpeg\"",
                "latitude": 37.9611463,
                "longitude": -121.7501075,
                "name": "\"Traderjoes\"",
                "opening_hours": "\"Mo-Su 08:00-21:00\"",
                "phone": "\"925-516-3044\"",
                "shop": "\"supermarket\"",
                "street_address": "\"5451 Lone Tree Way\"",
                "website": "\"https://locations.traderjoes.com/ca/brentwood/201\""
            },
            {
                "__id": 11,
                "address_city": "\"Wichita\"",
                "address_country": "\"US\"",
                "address_postcode": "\"67206\"",
                "address_state": "\"KS\"",
                "image": "\"https://contentstatic.com/5b3201f2-4a97-4fad-9037-78b847a99899.jpeg\"",
                "latitude": 37.7163687,
                "longitude": -97.2434102,
                "name": "\"Traderjoes\"",
                "opening_hours": "\"Mo-Su 09:00-21:00\"",
                "phone": "\"316-634-1846\"",
                "shop": "\"supermarket\"",
                "street_address": "\"1800 N. Rock Road\"",
                "website": "\"https://locations.traderjoes.com/ks/wichita/729\""
            },
            {
                "__id": 383,
                "address_city": "\"San Diego\"",
                "address_country": "\"US\"",
                "address_postcode": "\"92130\"",
                "address_state": "\"CA\"",
                "image": "\"https://contentstatic.com/1c18ee9f-7918-4f66-bf81-cea272debf94.jpeg\"",
                "latitude": 32.9611431,
                "longitude": -117.1914046,
                "name": "\"Traderjoes\"",
                "opening_hours": "\"Mo-Su 08:00-21:00\"",
                "phone": "\"858-755-7130\"",
                "shop": "\"supermarket\"",
                "street_address": "\"13480 Highlands Place\"",
                "website": "\"https://locations.traderjoes.com/ca/san-diego/223\""
            },
            {
                "__id": 121,
                "address_city": "\"Williamsburg\"",
                "address_country": "\"US\"",
                "address_postcode": "\"23188\"",
                "address_state": "\"VA\"",
                "image": "\"https://contentstatic.com/00850b91-b7a2-449d-bfeb-19aa8305b85f.jpeg\"",
                "latitude": 37.2771346,
                "longitude": -76.7477849,
                "name": "\"Traderjoes\"",
                "opening_hours": "\"Mo-Su 08:00-21:00\"",
                "phone": "\"757-259-2135\"",
                "shop": "\"supermarket\"",
                "street_address": "\"5000 Settlers Market Blvd\"",
                "website": "\"https://locations.traderjoes.com/va/williamsburg/657\""
            },
            {
                "__id": 48,
                "address_city": "\"Lexington\"",
                "address_country": "\"US\"",
                "address_postcode": "\"40503\"",
                "address_state": "\"KY\"",
                "image": "\"https://contentstatic.com/f5e85537-7666-4560-a69e-5e362bdfa413.jpeg\"",
                "latitude": 38.0049273,
                "longitude": -84.5183628,
                "name": "\"Traderjoes\"",
                "opening_hours": "\"Mo-Su 08:00-21:00\"",
                "phone": "\"859-313-5030\"",
                "shop": "\"supermarket\"",
                "street_address": "\"2326 Nicholasville Rd\"",
                "website": "\"https://locations.traderjoes.com/ky/lexington/627\""
            },
            {
                "__id": 49,
                "address_city": "\"Crestview Hills\"",
                "address_country": "\"US\"",
                "address_postcode": "\"41017\"",
                "address_state": "\"KY\"",
                "image": "\"https://contentstatic.com/6fd2899d-42d7-4873-ad5a-5f8e4412c996.jpeg\"",
                "latitude": 39.0288001,
                "longitude": -84.5771089,
                "name": "\"Traderjoes\"",
                "opening_hours": "\"Mo-Su 08:00-21:00\"",
                "phone": "\"859-331-0034\"",
                "shop": "\"supermarket\"",
                "street_address": "\"2780 Dixie Highway\"",
                "website": "\"https://locations.traderjoes.com/ky/crestview-hills/788\""
            },
            {
                "__id": 180,
                "address_city": "\"Las Vegas\"",
                "address_country": "\"US\"",
                "address_postcode": "\"89135\"",
                "address_state": "\"NV\"",
                "image": "\"https://contentstatic.com/83339a88-b9a8-4d5f-a0f6-9b6b16dab7e9.jpeg\"",
                "latitude": 36.1461897,
                "longitude": -115.3354281,
                "name": "\"Traderjoes\"",
                "opening_hours": "\"Mo-Su 08:00-21:00\"",
                "phone": "\"702-242-0597\"",
                "shop": "\"supermarket\"",
                "street_address": "\"2315 Summa Dr Ste 100\"",
                "website": "\"https://locations.traderjoes.com/nv/las-vegas/284\""
            },
            {
                "__id": 355,
                "address_city": "\"Santa Rosa\"",
                "address_country": "\"US\"",
                "address_postcode": "\"95407\"",
                "address_state": "\"CA\"",
                "image": "\"https://contentstatic.com/4840eb59-3f2b-40eb-883f-840a4d17fd3a.jpeg\"",
                "latitude": 38.4175177,
                "longitude": -122.7120491,
                "name": "\"Traderjoes\"",
                "opening_hours": "\"Mo-Su 08:00-21:00\"",
                "phone": "\"707-535-0788\"",
                "shop": "\"supermarket\"",
                "street_address": "\"2100 Santa Rosa Ave\"",
                "website": "\"https://locations.traderjoes.com/ca/santa-rosa/178\""
            },
            {
                "__id": 89,
                "address_city": "\"Kentwood\"",
                "address_country": "\"US\"",
                "address_postcode": "\"49512\"",
                "address_state": "\"MI\"",
                "image": "\"https://contentstatic.com/c9589f53-bef1-4634-b0b7-9e2779775cc7.jpeg\"",
                "latitude": 42.9119665,
                "longitude": -85.5766868,
                "name": "\"Traderjoes\"",
                "opening_hours": "\"Mo-Su 08:00-21:00\"",
                "phone": "\"616-977-1819\"",
                "shop": "\"supermarket\"",
                "street_address": "\"3684 28th St SE\"",
                "website": "\"https://locations.traderjoes.com/mi/kentwood/707\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "bhf_gb": {
        "__id": 110,
        "id": "bhf_gb",
        "name": "British Heart Foundation Great Britain",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'website']",
        "rowLength": 504,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763513,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of bhf_gb locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 74,
                "address_city": "\"Childwall Valley Road\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"L25 2RF\"",
                "address_state": "\"Merseyside\"",
                "latitude": 53.3905748,
                "longitude": -2.8599181,
                "name": "\"Belle Vale BHF Charity Shop\"",
                "opening_hours": "\"Mo-Sa 09:00-17:30; Su 10:00-16:00\"",
                "phone": "\"+44 (0)151 488 0295\"",
                "shop": "\"charity\"",
                "street_address": "\"Unit A Belle Vale Shopping Centre\"",
                "website": "\"https://www.bhf.org.uk/what-we-do/find-bhf-near-you/belle-vale-bhf-shop\""
            },
            {
                "__id": 430,
                "address_city": "\"Camden\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"NW1 7JL\"",
                "address_state": "\"London\"",
                "latitude": 51.5926307,
                "longitude": -0.0694362,
                "name": "\"Camden BHF Charity Shop\"",
                "opening_hours": "\"Mo-Sa 10:00-18:00; Su 11:00-17:00\"",
                "phone": "\"+44 (0)20 7387 2952\"",
                "shop": "\"charity\"",
                "street_address": "\"65 High Street\"",
                "website": "\"https://www.bhf.org.uk/what-we-do/find-bhf-near-you/tottenham-bhf-shop\""
            },
            {
                "__id": 66,
                "address_city": "\"Norwich\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"NR2 1HL\"",
                "address_state": "\"Norwich\"",
                "latitude": 52.6299135,
                "longitude": 1.2960972,
                "name": "\"Norwich BHF Shop\"",
                "opening_hours": "\"\"",
                "phone": "\"\"",
                "shop": "\"charity\"",
                "street_address": "\"57 London Street\"",
                "website": "\"https://www.bhf.org.uk/what-we-do/find-bhf-near-you/norwich-bhf-shop\""
            },
            {
                "__id": 283,
                "address_city": "\"Leeds\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"LS2 8NG\"",
                "address_state": "\"Leeds\"",
                "latitude": 53.8016298,
                "longitude": -1.5440482,
                "name": "\"Leeds Merrion BHF Shop\"",
                "opening_hours": "\"\"",
                "phone": "\"01132 441 110\"",
                "shop": "\"charity\"",
                "street_address": "\"Unit 80 Merrion Centre\"",
                "website": "\"https://www.bhf.org.uk/what-we-do/find-bhf-near-you/leeds-merrion-bhf-shop\""
            },
            {
                "__id": 404,
                "address_city": "\"Cumbernauld\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"G67 1NG\"",
                "address_state": "\"Strathclyde\"",
                "latitude": 55.9481991,
                "longitude": -3.9888572,
                "name": "\"Cumbernauld BHF Charity Shop\"",
                "opening_hours": "\"Mo-Sa 09:00-17:30\"",
                "phone": "\"+44 (0)1236 453051\"",
                "shop": "\"charity\"",
                "street_address": "\"2 Teviot Way\"",
                "website": "\"https://www.bhf.org.uk/what-we-do/find-bhf-near-you/cumbernauld-bhf-shop\""
            },
            {
                "__id": 120,
                "address_city": "\"Uxbridge\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"UB8 1JN\"",
                "address_state": "\"Middlesex\"",
                "latitude": 51.5454018,
                "longitude": -0.4783415,
                "name": "\"Uxbridge BHF Charity Shop\"",
                "opening_hours": "\"Mo-Sa 09:30-17:00\"",
                "phone": "\"+44 (0)1895 271417\"",
                "shop": "\"charity\"",
                "street_address": "\"6 High Street\"",
                "website": "\"https://www.bhf.org.uk/what-we-do/find-bhf-near-you/uxbridge-bhf-shop\""
            },
            {
                "__id": 228,
                "address_city": "\"North Shields\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"NE29 0SZ\"",
                "address_state": "\"Tyne and Wear\"",
                "latitude": 55.0091275,
                "longitude": -1.4472712,
                "name": "\"North Shields BHF Charity Shop\"",
                "opening_hours": "\"Mo-Sa 09:30-17:00; Su 11:00-16:00\"",
                "phone": "\"+44 (0)1912 596089\"",
                "shop": "\"charity\"",
                "street_address": "\"48 Bedford Street, (Unit 38 Beacon Centre)\"",
                "website": "\"https://www.bhf.org.uk/what-we-do/find-bhf-near-you/north-shields-bhf-shop\""
            },
            {
                "__id": 393,
                "address_city": "\"Didcot\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"OX11 8RN\"",
                "address_state": "\"Oxfordshire\"",
                "latitude": 51.6066417,
                "longitude": -1.2432353,
                "name": "\"Didcot BHF Charity Shop\"",
                "opening_hours": "\"Mo-Sa 09:00-16:30; Su 10:00-16:00\"",
                "phone": "\"+44 (0)1235817142\"",
                "shop": "\"charity\"",
                "street_address": "\"166 The Broadway\"",
                "website": "\"https://www.bhf.org.uk/what-we-do/find-bhf-near-you/didcot-bhf-shop\""
            },
            {
                "__id": 160,
                "address_city": "\"Stamford\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"PE9 2AL\"",
                "address_state": "\"Lincolnshire\"",
                "latitude": 52.6523573,
                "longitude": -0.4790163,
                "name": "\"Stamford BHF Charity Shop\"",
                "opening_hours": "\"Mo-Sa 09:30-17:00; Su 10:00-16:00\"",
                "phone": "\"+44 (0)1780756538\"",
                "shop": "\"charity\"",
                "street_address": "\"13 High Street\"",
                "website": "\"https://www.bhf.org.uk/what-we-do/find-bhf-near-you/stamford-bhf-shop\""
            },
            {
                "__id": 161,
                "address_city": "\"Staines\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"TW18 4DP\"",
                "address_state": "\"Staines\"",
                "latitude": 51.4345905,
                "longitude": -0.5099518,
                "name": "\"Staines BHF Shop\"",
                "opening_hours": "\"\"",
                "phone": "\"01784 464903\"",
                "shop": "\"charity\"",
                "street_address": "\"\"",
                "website": "\"https://www.bhf.org.uk/what-we-do/find-bhf-near-you/staines-bhf-shop\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "hilton": {
        "__id": 111,
        "id": "hilton",
        "name": "Hilton",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'alt_name', 'contact_email', 'image', 'latitude', 'longitude', 'name', 'official_name', 'phone', 'street_address', 'tourism', 'website']",
        "rowLength": 6967,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763515,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of hilton locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 2364,
                "address_city": "\"San Antonio\"",
                "address_country": "\"US\"",
                "address_full": "\"8202 City Base Landing, San Antonio, Texas, 78235, USA\"",
                "address_postcode": "\"78235\"",
                "address_state": "\"TX\"",
                "alt_name": "\"\"",
                "contact_email": "\"\"",
                "image": "\"https://www.hilton.com/im/en/SATBCHX/7784725/satbchx-01exterior.jpg\"",
                "latitude": 29.3464275726,
                "longitude": -98.4292371252,
                "name": "\"Hampton Inn & Suites San Antonio Brooks City Base\"",
                "official_name": "\"Hampton Inn & Suites by Hilton\"",
                "phone": "\"+1 210-333-4800\"",
                "street_address": "\"8202 City Base Landing\"",
                "tourism": "\"hotel\"",
                "website": "\"https://www.hilton.com/en/hotels/satbchx-hampton-suites-san-antonio-brooks-city-base/\""
            },
            {
                "__id": 659,
                "address_city": "\"Guangzhou\"",
                "address_country": "\"CN\"",
                "address_full": "\"4 Yunxi Road, Zengcheng District, Guangdong Province, Guangzhou, 511300, China\"",
                "address_postcode": "\"511300\"",
                "address_state": "\"\"",
                "alt_name": "\"\"",
                "contact_email": "\"\"",
                "image": "\"https://www.hilton.com/im/en/CANGODI/17228079/cango-exterior-aerial-result.jpg\"",
                "latitude": 23.2767291,
                "longitude": 113.8103427,
                "name": "\"DoubleTree by Hilton Guangzhou Zengcheng\"",
                "official_name": "\"DoubleTree by Hilton\"",
                "phone": "\"+86 20 8281 2888\"",
                "street_address": "\"4 Yunxi Road, Zengcheng District, Guangdong Province\"",
                "tourism": "\"hotel\"",
                "website": "\"https://www.hilton.com/en/hotels/cangodi-doubletree-guangzhou-zengcheng/\""
            },
            {
                "__id": 2955,
                "address_city": "\"Mianyang\"",
                "address_country": "\"CN\"",
                "address_full": "\"No. 2 Fucheng Road, Fuchegn Distric Mianyang City, Sichuan Province, Mianyang, 621000, China\"",
                "address_postcode": "\"621000\"",
                "address_state": "\"\"",
                "alt_name": "\"Hampton Inn\"",
                "contact_email": "\"\"",
                "image": "\"https://www.hilton.com/im/en/MIGANHX/17141071/main-2-.jpg\"",
                "latitude": 31.467143,
                "longitude": 104.750484,
                "name": "\"Hampton by Hilton Mianyang Fucheng\"",
                "official_name": "\"Hampton by Hilton\"",
                "phone": "\"+86 816 680 8888\"",
                "street_address": "\"No. 2 Fucheng Road, Fuchegn Distric Mianyang City, Sichuan Province\"",
                "tourism": "\"hotel\"",
                "website": "\"https://www.hilton.com/en/hotels/miganhx-hampton-mianyang-fucheng/\""
            },
            {
                "__id": 1652,
                "address_city": "\"West Chester\"",
                "address_country": "\"US\"",
                "address_full": "\"9306 Schulze Drive, West Chester, Ohio, 45069, USA\"",
                "address_postcode": "\"45069\"",
                "address_state": "\"OH\"",
                "alt_name": "\"\"",
                "contact_email": "\"\"",
                "image": "\"https://www.hilton.com/im/en/CVGMSGI/3671073/cvgmsgi-exterior.jpg\"",
                "latitude": 39.321,
                "longitude": -84.429329,
                "name": "\"Hilton Garden Inn Cincinnati/West Chester\"",
                "official_name": "\"\"",
                "phone": "\"+1 513-860-3170\"",
                "street_address": "\"9306 Schulze Drive\"",
                "tourism": "\"hotel\"",
                "website": "\"https://www.hilton.com/en/hotels/cvgmsgi-hilton-garden-inn-cincinnati-west-chester/\""
            },
            {
                "__id": 82,
                "address_city": "\"Bangkok\"",
                "address_country": "\"TH\"",
                "address_full": "\"All Seasons Place 87/3 Wireless Rd. Phatumwan, Bangkok, 10330, Thailand\"",
                "address_postcode": "\"10330\"",
                "address_state": "\"\"",
                "alt_name": "\"\"",
                "contact_email": "\"bkkci.info@conradhotels.com\"",
                "image": "\"https://www.hilton.com/im/en/MultiHotel/17265513/bkkcici-pool-02.jpg\"",
                "latitude": 13.7386,
                "longitude": 100.5482,
                "name": "\"Conrad Bangkok\"",
                "official_name": "\"\"",
                "phone": "\"+66 2 690 9999\"",
                "street_address": "\"All Seasons Place 87/3 Wireless Rd. Phatumwan\"",
                "tourism": "\"hotel\"",
                "website": "\"https://www.hilton.com/en/hotels/bkkcici-conrad-bangkok/\""
            },
            {
                "__id": 5210,
                "address_city": "\"Nashville\"",
                "address_country": "\"US\"",
                "address_full": "\"7815 Coley Davis Road, Nashville, Tennessee, 37221, USA\"",
                "address_postcode": "\"37221\"",
                "address_state": "\"TN\"",
                "alt_name": "\"\"",
                "contact_email": "\"\"",
                "image": "\"https://www.hilton.com/im/en/BNAWEHX/2474544/bnawe-daytime-exterior-1.jpg\"",
                "latitude": 36.078957,
                "longitude": -86.954956,
                "name": "\"Hampton Inn Bellevue / Nashville-I-40 West\"",
                "official_name": "\"Hampton Inn & Suites by Hilton\"",
                "phone": "\"+1 615-662-3133\"",
                "street_address": "\"7815 Coley Davis Road\"",
                "tourism": "\"hotel\"",
                "website": "\"https://www.hilton.com/en/hotels/bnawehx-hampton-bellevue-nashville-i-40-west/\""
            },
            {
                "__id": 5471,
                "address_city": "\"Anderson\"",
                "address_country": "\"US\"",
                "address_full": "\"2312 Hampton Drive, Anderson, Indiana, 46013, USA\"",
                "address_postcode": "\"46013\"",
                "address_state": "\"IN\"",
                "alt_name": "\"\"",
                "contact_email": "\"\"",
                "image": "\"https://www.hilton.com/im/en/AIDINHX/2429474/aidinhx-exterior.jpg\"",
                "latitude": 40.048543,
                "longitude": -85.649154,
                "name": "\"Hampton Inn Anderson\"",
                "official_name": "\"Hampton Inn & Suites by Hilton\"",
                "phone": "\"+1 765-622-0700\"",
                "street_address": "\"2312 Hampton Drive\"",
                "tourism": "\"hotel\"",
                "website": "\"https://www.hilton.com/en/hotels/aidinhx-hampton-anderson/\""
            },
            {
                "__id": 3108,
                "address_city": "\"Seattle\"",
                "address_country": "\"US\"",
                "address_full": "\"255 South King Street, Seattle, Washington, 98104, USA\"",
                "address_postcode": "\"98104\"",
                "address_state": "\"WA\"",
                "alt_name": "\"\"",
                "contact_email": "\"SEAPS_MOD@hilton.com\"",
                "image": "\"https://www.hilton.com/im/en/SEAPSES/6764742/seaps-exterior-seahawks-eye-view.jpg\"",
                "latitude": 47.5979693485,
                "longitude": -122.331035459,
                "name": "\"Embassy Suites by Hilton Seattle Downtown Pioneer Square\"",
                "official_name": "\"Embassy Suites by Hilton\"",
                "phone": "\"+1 206-859-4400\"",
                "street_address": "\"255 South King Street\"",
                "tourism": "\"hotel\"",
                "website": "\"https://www.hilton.com/en/hotels/seapses-embassy-suites-seattle-downtown-pioneer-square/\""
            },
            {
                "__id": 3243,
                "address_city": "\"Shijiazhuang\"",
                "address_country": "\"CN\"",
                "address_full": "\"5 Dong Da Jie, Chang'an District, Shijiazhuang, 050011, China\"",
                "address_postcode": "\"050011\"",
                "address_state": "\"\"",
                "alt_name": "\"\"",
                "contact_email": "\"\"",
                "image": "\"https://www.hilton.com/im/en/SJWZSHI/3276844/wj029-approved.jpg\"",
                "latitude": 38.045839,
                "longitude": 114.531525,
                "name": "\"Hilton Shijiazhuang\"",
                "official_name": "\"\"",
                "phone": "\"+86 311 6796 6666\"",
                "street_address": "\"5 Dong Da Jie, Chang'an District\"",
                "tourism": "\"hotel\"",
                "website": "\"https://www.hilton.com/en/hotels/sjwzshi-hilton-shijiazhuang/\""
            },
            {
                "__id": 5752,
                "address_city": "\"Winter Garden\"",
                "address_country": "\"US\"",
                "address_full": "\"341 Flagler Ave, Winter Garden, Florida, 34787, USA\"",
                "address_postcode": "\"34787\"",
                "address_state": "\"FL\"",
                "alt_name": "\"\"",
                "contact_email": "\"\"",
                "image": "\"https://www.hilton.com/im/en/MCOFLHT/15170071/dsc-3172-3-4.jpg\"",
                "latitude": 28.382103,
                "longitude": -81.616525,
                "name": "\"Home2 Suites by Hilton Orlando at FLAMINGO CROSSINGS Town Center\"",
                "official_name": "\"Home2 Suites by Hilton\"",
                "phone": "\"+1 407-993-3999\"",
                "street_address": "\"341 Flagler Ave\"",
                "tourism": "\"hotel\"",
                "website": "\"https://www.hilton.com/en/hotels/mcoflht-home2-suites-orlando/\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "dutchbros": {
        "__id": 112,
        "id": "dutchbros",
        "name": "Dutch Bros",
        "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
        "rowLength": 651,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763516,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of dutchbros locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 61,
                "address_city": "\"Yuma \"",
                "address_full": "\"1512 S. 4th Ave\"",
                "address_postcode": "\"85364\"",
                "address_state": "\"AZ\"",
                "amenity": "\"cafe\"",
                "cuisine": "\"coffee_shop\"",
                "latitude": 32.700221,
                "longitude": -114.625123,
                "name": "\"S 4th Ave\"",
                "opening_hours": "\"Mo-Th 05:00-22:00; Fr-Sa 05:00-23:00; Su 05:00-22:00\"",
                "takeaway": "\"only\"",
                "website": "\"https://locations.dutchbros.com/dutch-bros-coffee-a2b97909f435\""
            },
            {
                "__id": 373,
                "address_city": "\"Newberg\"",
                "address_full": "\"701 Deborah Rd.\"",
                "address_postcode": "\"97132\"",
                "address_state": "\"OR\"",
                "amenity": "\"cafe\"",
                "cuisine": "\"coffee_shop\"",
                "latitude": 45.304003,
                "longitude": -122.950067,
                "name": "\"Newberg, OR (Newberg East)\"",
                "opening_hours": "\"Mo-Th 05:00-22:00; Fr-Sa 05:00-23:00; Su 05:00-22:00\"",
                "takeaway": "\"only\"",
                "website": "\"https://locations.dutchbros.com/dutch-bros-coffee-d740b44fb8fc\""
            },
            {
                "__id": 430,
                "address_city": "\"McMinnville\"",
                "address_full": "\"1365 N.E. Highway 99 W.\"",
                "address_postcode": "\"97128\"",
                "address_state": "\"OR\"",
                "amenity": "\"cafe\"",
                "cuisine": "\"coffee_shop\"",
                "latitude": 45.22657,
                "longitude": -123.18565,
                "name": "\"Mcminnville, OR (Shop 2)\"",
                "opening_hours": "\"Mo-Th 05:00-22:00; Fr-Sa 05:00-23:00; Su 05:00-22:00\"",
                "takeaway": "\"only\"",
                "website": "\"https://locations.dutchbros.com/dutch-bros-coffee-faa36fd5ce9f\""
            },
            {
                "__id": 202,
                "address_city": "\"Arvada\"",
                "address_full": "\"5385 W 80th Ave\"",
                "address_postcode": "\"80003\"",
                "address_state": "\"CO\"",
                "amenity": "\"cafe\"",
                "cuisine": "\"coffee_shop\"",
                "latitude": 39.84219,
                "longitude": -105.05565,
                "name": "\"80th\"",
                "opening_hours": "\"Mo-Su 05:00-23:00\"",
                "takeaway": "\"only\"",
                "website": "\"https://locations.dutchbros.com/dutch-bros-coffee-4a4a3828f807\""
            },
            {
                "__id": 282,
                "address_city": "\"Las Vegas\"",
                "address_full": "\"2840 E Tropicana Avenue\"",
                "address_postcode": "\"89121\"",
                "address_state": "\"NV\"",
                "amenity": "\"cafe\"",
                "cuisine": "\"coffee_shop\"",
                "latitude": 36.100355,
                "longitude": -115.111957,
                "name": "\"Las Vegas, NV (Tropicana)\"",
                "opening_hours": "\"Mo-Su 05:00-23:00\"",
                "takeaway": "\"only\"",
                "website": "\"https://locations.dutchbros.com/dutch-bros-coffee-1e5b3060352e\""
            },
            {
                "__id": 558,
                "address_city": "\"Addison\"",
                "address_full": "\"14310 Marsh Ln\"",
                "address_postcode": "\"75001\"",
                "address_state": "\"TX\"",
                "amenity": "\"cafe\"",
                "cuisine": "\"coffee_shop\"",
                "latitude": 32.942935,
                "longitude": -96.85379,
                "name": "\"Marsh\"",
                "opening_hours": "\"Mo-Th 05:00-22:00; Fr-Sa 05:00-23:00; Su 05:00-22:00\"",
                "takeaway": "\"only\"",
                "website": "\"https://locations.dutchbros.com/dutch-bros-coffee-49bb40d14e3c\""
            },
            {
                "__id": 365,
                "address_city": "\"Salem\"",
                "address_full": "\"1102 Lancaster Dr. N.E.\"",
                "address_postcode": "\"97301\"",
                "address_state": "\"OR\"",
                "amenity": "\"cafe\"",
                "cuisine": "\"coffee_shop\"",
                "latitude": 44.943579,
                "longitude": -122.983006,
                "name": "\"Salem, OR (Lanny)\"",
                "opening_hours": "\"Mo-Th 05:00-22:00; Fr-Sa 05:00-23:00; Su 05:00-22:00\"",
                "takeaway": "\"only\"",
                "website": "\"https://locations.dutchbros.com/dutch-bros-coffee-4cf65bdec3eb\""
            },
            {
                "__id": 271,
                "address_city": "\"Belton\"",
                "address_full": "\"1733 E North Ave\"",
                "address_postcode": "\"64012\"",
                "address_state": "\"MO\"",
                "amenity": "\"cafe\"",
                "cuisine": "\"coffee_shop\"",
                "latitude": 38.81304,
                "longitude": -94.50772,
                "name": "\"E North\"",
                "opening_hours": "\"Mo-Th 05:00-22:00; Fr-Sa 05:00-23:00; Su 05:00-22:00\"",
                "takeaway": "\"only\"",
                "website": "\"https://locations.dutchbros.com/dutch-bros-coffee-0afdc125ac9f\""
            },
            {
                "__id": 486,
                "address_city": "\"Princeton\"",
                "address_full": "\"603 W Princeton Dr\"",
                "address_postcode": "\"75407\"",
                "address_state": "\"TX\"",
                "amenity": "\"cafe\"",
                "cuisine": "\"coffee_shop\"",
                "latitude": 33.17455,
                "longitude": -96.50995,
                "name": "\"Princeton Dr\"",
                "opening_hours": "\"Mo-Th 05:00-22:00; Fr-Sa 05:00-23:00; Su 05:00-22:00\"",
                "takeaway": "\"only\"",
                "website": "\"https://locations.dutchbros.com/dutch-bros-coffee-d6c3ce4ded73\""
            },
            {
                "__id": 200,
                "address_city": "\"Commerce City\"",
                "address_full": "\"15431 E 104th Ave (Chambers)\"",
                "address_postcode": "\"80022\"",
                "address_state": "\"CO\"",
                "amenity": "\"cafe\"",
                "cuisine": "\"coffee_shop\"",
                "latitude": 39.88555,
                "longitude": -104.80723,
                "name": "\"E. 104th\"",
                "opening_hours": "\"Mo-Su 05:00-23:00\"",
                "takeaway": "\"only\"",
                "website": "\"https://locations.dutchbros.com/dutch-bros-coffee-ed0784ad57d7\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "golden_corral": {
        "__id": 113,
        "id": "golden_corral",
        "name": "Golden Corral",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'image', 'latitude', 'longitude', 'name', 'phone', 'street_address', 'website']",
        "rowLength": 396,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763517,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of golden_corral locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 87,
                "address_city": "\"El paso\"",
                "address_country": "\"US\"",
                "address_postcode": "\"79936\"",
                "address_state": "\"TX\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american;buffet\"",
                "image": "\"https://www.goldencorral.com/wp-content/uploads/2018/02/2018-02-About_Us_Header.jpg\"",
                "latitude": 31.7501442,
                "longitude": -106.3183274,
                "name": "\"Golden Corral 537, El paso, TX\"",
                "phone": "\"915-592-8411\"",
                "street_address": "\"N Lee Trevino Dr\"",
                "website": "\"https://www.goldencorral.com/locations/location-detail/537/golden-corral-n-lee-trevino-dr/\""
            },
            {
                "__id": 139,
                "address_city": "\"Cincinnati\"",
                "address_country": "\"US\"",
                "address_postcode": "\"45245\"",
                "address_state": "\"OH\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american;buffet\"",
                "image": "\"https://www.goldencorral.com/wp-content/uploads/2018/02/2018-02-About_Us_Header.jpg\"",
                "latitude": 39.0928994,
                "longitude": -84.2625774,
                "name": "\"Golden Corral 874, Cincinnati, OH\"",
                "phone": "\"513-752-0137\"",
                "street_address": "\"Glen Este-withamsville Rd\"",
                "website": "\"https://www.goldencorral.com/locations/location-detail/874/golden-corral-glen-este-withamsville-rd/\""
            },
            {
                "__id": 208,
                "address_city": "\"Westland\"",
                "address_country": "\"US\"",
                "address_postcode": "\"48185\"",
                "address_state": "\"MI\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american;buffet\"",
                "image": "\"https://www.goldencorral.com/wp-content/uploads/2018/02/2018-02-About_Us_Header.jpg\"",
                "latitude": 42.3352645,
                "longitude": -83.4046843,
                "name": "\"Golden Corral 2467, Westland, MI\"",
                "phone": "\"734-641-9163\"",
                "street_address": "\"Warren Rd.\"",
                "website": "\"https://www.goldencorral.com/locations/location-detail/2467/golden-corral-warren-rd/\""
            },
            {
                "__id": 101,
                "address_city": "\"Sevierville\"",
                "address_country": "\"US\"",
                "address_postcode": "\"37876\"",
                "address_state": "\"TN\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american;buffet\"",
                "image": "\"https://www.goldencorral.com/wp-content/uploads/2018/02/2018-02-About_Us_Header.jpg\"",
                "latitude": 35.8759202,
                "longitude": -83.5704231,
                "name": "\"Golden Corral 804, Sevierville, TN\"",
                "phone": "\"865-453-8859\"",
                "street_address": "\"Winfield Dunn Parkway\"",
                "website": "\"https://www.goldencorral.com/locations/location-detail/804/golden-corral-winfield-dunn-parkway/\""
            },
            {
                "__id": 322,
                "address_city": "\"Celebration\"",
                "address_country": "\"US\"",
                "address_postcode": "\"34747\"",
                "address_state": "\"FL\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american;buffet\"",
                "image": "\"https://www.goldencorral.com/wp-content/uploads/2018/02/2018-02-About_Us_Header.jpg\"",
                "latitude": 28.333776,
                "longitude": -81.5274998,
                "name": "\"Golden Corral 2571, Celebration, FL\"",
                "phone": "\"321-402-0290\"",
                "street_address": "\"W. Irlo Bronson Memorial\"",
                "website": "\"https://www.goldencorral.com/locations/location-detail/2571/golden-corral-w-irlo-bronson-memorial/\""
            },
            {
                "__id": 239,
                "address_city": "\"Topeka\"",
                "address_country": "\"US\"",
                "address_postcode": "\"66604\"",
                "address_state": "\"KS\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american;buffet\"",
                "image": "\"https://www.goldencorral.com/wp-content/uploads/2018/02/2018-02-About_Us_Header.jpg\"",
                "latitude": 39.0374428,
                "longitude": -95.7625297,
                "name": "\"Golden Corral 2401, Topeka, KS\"",
                "phone": "\"785-273-5354\"",
                "street_address": "\"Sw Wanamaker Road\"",
                "website": "\"https://www.goldencorral.com/locations/location-detail/2401/golden-corral-sw-wanamaker-road/\""
            },
            {
                "__id": 75,
                "address_city": "\"Houston\"",
                "address_country": "\"US\"",
                "address_postcode": "\"77034\"",
                "address_state": "\"TX\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american;buffet\"",
                "image": "\"https://www.goldencorral.com/wp-content/uploads/2018/02/2018-02-About_Us_Header.jpg\"",
                "latitude": 29.6164284,
                "longitude": -95.2179816,
                "name": "\"Golden Corral 993, Houston, TX\"",
                "phone": "\"713-910-0387\"",
                "street_address": "\"Gulf Freeway\"",
                "website": "\"https://www.goldencorral.com/locations/location-detail/993/golden-corral-gulf-freeway/\""
            },
            {
                "__id": 170,
                "address_city": "\"Asheboro\"",
                "address_country": "\"US\"",
                "address_postcode": "\"27203\"",
                "address_state": "\"NC\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american;buffet\"",
                "image": "\"https://www.goldencorral.com/wp-content/uploads/2018/02/2018-02-About_Us_Header.jpg\"",
                "latitude": 35.6930346,
                "longitude": -79.7961399,
                "name": "\"Golden Corral 2454, Asheboro, NC\"",
                "phone": "\"336-625-6734\"",
                "street_address": "\"East Dixie Drive\"",
                "website": "\"https://www.goldencorral.com/locations/location-detail/2454/golden-corral-east-dixie-drive/\""
            },
            {
                "__id": 148,
                "address_city": "\"Sanford\"",
                "address_country": "\"US\"",
                "address_postcode": "\"27332\"",
                "address_state": "\"NC\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american;buffet\"",
                "image": "\"https://www.goldencorral.com/wp-content/uploads/2018/02/2018-02-About_Us_Header.jpg\"",
                "latitude": 35.4575465,
                "longitude": -79.143344,
                "name": "\"Golden Corral 622, Sanford, NC\"",
                "phone": "\"919-775-3295\"",
                "street_address": "\"South Horner Blvd.\"",
                "website": "\"https://www.goldencorral.com/locations/location-detail/622/golden-corral-south-horner-blvd/\""
            },
            {
                "__id": 296,
                "address_city": "\"Pembroke pines\"",
                "address_country": "\"US\"",
                "address_postcode": "\"33024\"",
                "address_state": "\"FL\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american;buffet\"",
                "image": "\"https://www.goldencorral.com/wp-content/uploads/2018/02/2018-02-About_Us_Header.jpg\"",
                "latitude": 26.0094623,
                "longitude": -80.2660636,
                "name": "\"Golden Corral 873, Pembroke pines, FL\"",
                "phone": "\"954-447-1877\"",
                "street_address": "\"Pines Boulevard\"",
                "website": "\"https://www.goldencorral.com/locations/location-detail/873/golden-corral-pines-boulevard/\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "labreweries": {
        "__id": 114,
        "id": "labreweries",
        "name": "LaBreweries",
        "columnNames": "['opening_hours', 'website']",
        "rowLength": 77,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763518,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of labreweries locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 22,
                "opening_hours": "\"Monday 3-12AM Wednesday 3-12AM Thursday 3-12AM Friday 12pm-1AM Saturday 12PM\u20131AM Sunday 12\u201312AM\"",
                "website": "\"https://labeerhop.com/breweries/transplantsbrewing/\""
            },
            {
                "__id": 16,
                "opening_hours": "\"Thursday 3\u201310PM Friday 3\u201310PM Saturday 12\u201310PM Sunday 12\u201310PM\"",
                "website": "\"https://labeerhop.com/breweries/the-dudes-brewing-co/\""
            },
            {
                "__id": 59,
                "opening_hours": "\"Sunday - Thursday 11am - 11pm Friday - Saturday 11am - 12am\"",
                "website": "\"https://labeerhop.com/breweries/firestone-walker-venice/\""
            },
            {
                "__id": 7,
                "opening_hours": "\"Monday 5PM\u20132AM Tuesday 5PM\u20132AM Wednesday 5PM\u20132AM Thursday 5PM\u20132AM Friday 12PM\u20132AM Saturday 12PM\u20132AM Sunday 12PM\u20132AM\"",
                "website": "\"https://labeerhop.com/breweries/highland-park-brewery/\""
            },
            {
                "__id": 14,
                "opening_hours": "\"Wednesday 4\u20139PM Thursday 4\u20139PM Friday 4\u20139PM Saturday 11AM\u20138PM Sunday 12\u20135PM\"",
                "website": "\"https://labeerhop.com/breweries/enegren-brewing/\""
            },
            {
                "__id": 47,
                "opening_hours": "\"Wednesday: 4-10pm Thursday: 4-10pm Friday: 4-11pm Saturday: 12-11pm Sunday: 12-7pm\"",
                "website": "\"https://labeerhop.com/breweries/beachwood-blendery/\""
            },
            {
                "__id": 72,
                "opening_hours": "\"Monday 3\u201310PM Tuesday Closed Wednesday 3\u201310PM Thursday 3\u201310PM Friday 3\u201311:30PM Saturday 12\u201311:30PM Sunday 12\u20138PM\"",
                "website": "\"https://labeerhop.com/breweries/old-stump-brewing-co/\""
            },
            {
                "__id": 17,
                "opening_hours": "\"Monday 4PM\u201312AM Tuesday 4PM\u201312AM Wednesday 4PM\u201312AM Thursday 4PM\u201312AM Friday 4PM\u20132AM Saturday 12PM\u20132AM Sunday 12PM\u201312AM\"",
                "website": "\"https://labeerhop.com/breweries/homebound/\""
            },
            {
                "__id": 6,
                "opening_hours": "\"Monday 5\u20139PM Tuesday 5\u20139PM Wednesday 5\u20139PM Thursday 5\u201310PM Friday 4\u201311PM Saturday 12\u201311PM Sunday 12\u20137PM\"",
                "website": "\"https://labeerhop.com/breweries/el-segundo-brewing/\""
            },
            {
                "__id": 26,
                "opening_hours": "\"Monday Closed Tuesday Closed Wednesday 5pm - 10pm Thursday 5pm - 10pm Friday 12pm - 10pm Saturday 12pm - 10pm Sunday 12pm - 8pm\"",
                "website": "\"https://labeerhop.com/breweries/frogtown-brewery/\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "batteriesplus": {
        "__id": 115,
        "id": "batteriesplus",
        "name": "Batteries Plus",
        "columnNames": "['address_city', 'address_postcode', 'address_state', 'image', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'website']",
        "rowLength": 724,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763519,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of batteriesplus locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 291,
                "address_city": "\"Carson City\"",
                "address_postcode": "\"89702\"",
                "address_state": "\"NV\"",
                "image": "\"https://www.batteriesplus.com/content/images/logos/Logo-BPB.png\"",
                "latitude": 39.154514,
                "longitude": -119.767537,
                "name": "\"Visit the Battery and Lighting Experts in Carson City\"",
                "opening_hours": "\"Mo-Sa 08:00-19:00; Su 10:00-18:00\"",
                "phone": "\"(775) 884-0566\"",
                "shop": "\"electronics\"",
                "street_address": "\"1400 S Carson St\"",
                "website": "\"https://www.batteriesplus.com/store-locator/nv/carsoncity/batteries-plus-350\""
            },
            {
                "__id": 199,
                "address_city": "\"Mount Pleasant\"",
                "address_postcode": "\"29464\"",
                "address_state": "\"SC\"",
                "image": "\"https://www.batteriesplus.com/content/images/logos/Logo-BPB.png\"",
                "latitude": 32.807098,
                "longitude": -79.881932,
                "name": "\"Stop By Our Mount Pleasant Store Today\"",
                "opening_hours": "\"Mo-Sa 08:00-20:00; Su 09:00-19:00\"",
                "phone": "\"(843) 881-4710\"",
                "shop": "\"electronics\"",
                "street_address": "\"621 Johnnie Dodds Blvd Plaza at East Cooper\"",
                "website": "\"https://www.batteriesplus.com/store-locator/sc/mountpleasant/batteries-plus-233\""
            },
            {
                "__id": 61,
                "address_city": "\"Federal Way\"",
                "address_postcode": "\"98003\"",
                "address_state": "\"WA\"",
                "image": "\"https://www.batteriesplus.com/content/images/logos/Logo-BPB.png\"",
                "latitude": 47.316461,
                "longitude": -122.312559,
                "name": "\"Shop our Federal Way Location Today\"",
                "opening_hours": "\"Mo-Fr 08:00-19:00; Sa 09:00-19:00; Su 10:00-18:00\"",
                "phone": "\"(253) 666-9555\"",
                "shop": "\"electronics\"",
                "street_address": "\"31830 Pacific Hwy South\"",
                "website": "\"https://www.batteriesplus.com/store-locator/wa/federalway/batteries-plus-899\""
            },
            {
                "__id": 385,
                "address_city": "\"Sauk Rapids\"",
                "address_postcode": "\"56379\"",
                "address_state": "\"MN\"",
                "image": "\"https://www.batteriesplus.com/content/images/logos/Logo-BPB.png\"",
                "latitude": 45.589479,
                "longitude": -94.166771,
                "name": "\"Shop our Sauk Rapids location\"",
                "opening_hours": "\"Mo-Fr 08:00-19:00; Sa 09:00-19:00; Su 10:00-17:00\"",
                "phone": "\"(320) 230-2332\"",
                "shop": "\"electronics\"",
                "street_address": "\"27 N. Benton Drive Suite 27\"",
                "website": "\"https://www.batteriesplus.com/store-locator/mn/saukrapids/batteries-plus-794\""
            },
            {
                "__id": 226,
                "address_city": "\"Pittsburgh\"",
                "address_postcode": "\"15234\"",
                "address_state": "\"PA\"",
                "image": "\"https://www.batteriesplus.com/content/images/logos/Logo-BPB.png\"",
                "latitude": 40.363511,
                "longitude": -80.027091,
                "name": "\"Stop Our Pittsburgh Store Today\"",
                "opening_hours": "\"Mo-Fr 08:00-18:00; Sa 09:00-18:00; Su 10:00-17:00\"",
                "phone": "\"(412) 207-8362\"",
                "shop": "\"electronics\"",
                "street_address": "\"3912 Library Rd\"",
                "website": "\"https://www.batteriesplus.com/store-locator/pa/pittsburgh/batteries-plus-191\""
            },
            {
                "__id": 166,
                "address_city": "\"Sevierville\"",
                "address_postcode": "\"37862\"",
                "address_state": "\"TN\"",
                "image": "\"https://www.batteriesplus.com/content/images/logos/Logo-BPB.png\"",
                "latitude": 35.866647,
                "longitude": -83.569308,
                "name": "\"Visit Us In Sevierville Today\"",
                "opening_hours": "\"Mo-Fr 08:00-19:00; Sa 09:00-19:00; Su 10:00-18:00\"",
                "phone": "\"(865) 365-4213\"",
                "shop": "\"electronics\"",
                "street_address": "\"207 Forks of the River Parkway\"",
                "website": "\"https://www.batteriesplus.com/store-locator/tn/sevierville/batteries-plus-542\""
            },
            {
                "__id": 566,
                "address_city": "\"Venice\"",
                "address_postcode": "\"34285\"",
                "address_state": "\"FL\"",
                "image": "\"https://www.batteriesplus.com/content/images/logos/Logo-BPB.png\"",
                "latitude": 27.106272,
                "longitude": -82.438229,
                "name": "\"Visit Venice's battery and lighting experts\"",
                "opening_hours": "\"Mo-Sa 08:00-19:00; Su 10:00-17:00\"",
                "phone": "\"(941) 488-2120\"",
                "shop": "\"electronics\"",
                "street_address": "\"511 US Highway 41 Bypass N\"",
                "website": "\"https://www.batteriesplus.com/store-locator/fl/venice/batteries-plus-037\""
            },
            {
                "__id": 691,
                "address_city": "\"Clovis\"",
                "address_postcode": "\"93612\"",
                "address_state": "\"CA\"",
                "image": "\"https://www.batteriesplus.com/content/images/logos/Logo-BPB.png\"",
                "latitude": 36.808758,
                "longitude": -119.711084,
                "name": "\"Stop by our Clovis store today\"",
                "opening_hours": "\"Mo-Fr 08:00-19:00; Sa 08:00-18:00; Su 10:00-17:00\"",
                "phone": "\"(559) 900-2789\"",
                "shop": "\"electronics\"",
                "street_address": "\"95 W. Shaw Avenue Suite 105\"",
                "website": "\"https://www.batteriesplus.com/store-locator/ca/clovis/batteries-plus-904\""
            },
            {
                "__id": 421,
                "address_city": "\"Farmington Hills\"",
                "address_postcode": "\"48334\"",
                "address_state": "\"MI\"",
                "image": "\"https://www.batteriesplus.com/content/images/logos/Logo-BPB.png\"",
                "latitude": 42.522991,
                "longitude": -83.359331,
                "name": "\"Stop by our Farmington Hills store Today\"",
                "opening_hours": "\"Mo-Fr 08:00-19:00; Sa 09:00-19:00; Su 10:00-18:00\"",
                "phone": "\"(248) 737-9140\"",
                "shop": "\"electronics\"",
                "street_address": "\"30760 Orchard Lake Rd\"",
                "website": "\"https://www.batteriesplus.com/store-locator/mi/farmingtonhills/batteries-plus-480\""
            },
            {
                "__id": 428,
                "address_city": "\"Upper Marlboro\"",
                "address_postcode": "\"20774\"",
                "address_state": "\"MD\"",
                "image": "\"https://www.batteriesplus.com/content/images/logos/Logo-BPB.png\"",
                "latitude": 38.899568,
                "longitude": -76.790048,
                "name": "\"Visit us in Upper Marlboro today\"",
                "opening_hours": "\"Mo-Sa 09:00-19:00; Su 10:00-17:00\"",
                "phone": "\"(301) 218-1630\"",
                "shop": "\"electronics\"",
                "street_address": "\"42 Watkins Park Dr\"",
                "website": "\"https://www.batteriesplus.com/store-locator/md/uppermarlboro/batteries-plus-431\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "boost_mobile": {
        "__id": 116,
        "id": "boost_mobile",
        "name": "Boost Mobile",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'shop', 'street_address']",
        "rowLength": 4025,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763521,
        "runTime": 2,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of boost_mobile locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 3292,
                "address_city": "\"Brownsville\"",
                "address_country": "\"US\"",
                "address_postcode": "\"78521\"",
                "address_state": "\"TX\"",
                "latitude": 25.932804,
                "longitude": -97.475976,
                "name": "\"Boost Mobile Premier Store\"",
                "shop": "\"mobile_phone\"",
                "street_address": "\"2835 E Price Road J\""
            },
            {
                "__id": 818,
                "address_city": "\"Lanham\"",
                "address_country": "\"US\"",
                "address_postcode": "\"20706\"",
                "address_state": "\"MD\"",
                "latitude": 38.9537387,
                "longitude": -76.8738528,
                "name": "\"Boost Mobile Premier Store\"",
                "shop": "\"mobile_phone\"",
                "street_address": "\"7947 Annapolis Road\""
            },
            {
                "__id": 2853,
                "address_city": "\"Lewisville\"",
                "address_country": "\"US\"",
                "address_postcode": "\"75057\"",
                "address_state": "\"TX\"",
                "latitude": 33.0341993,
                "longitude": -96.9950295,
                "name": "\"Boost Mobile Premier Store\"",
                "shop": "\"mobile_phone\"",
                "street_address": "\"283 Huffines Plz 200B\""
            },
            {
                "__id": 3879,
                "address_city": "\"Redwood City\"",
                "address_country": "\"US\"",
                "address_postcode": "\"94063\"",
                "address_state": "\"CA\"",
                "latitude": 37.4778773,
                "longitude": -122.21284,
                "name": "\"Boost Mobile Store\"",
                "shop": "\"mobile_phone\"",
                "street_address": "\"2593 Middlefield Rd\""
            },
            {
                "__id": 1185,
                "address_city": "\"Marietta\"",
                "address_country": "\"US\"",
                "address_postcode": "\"30064\"",
                "address_state": "\"GA\"",
                "latitude": 33.866204,
                "longitude": -84.6296809,
                "name": "\"Boost Mobile Store\"",
                "shop": "\"mobile_phone\"",
                "street_address": "\"2667 Powder Springs Road 106\""
            },
            {
                "__id": 3462,
                "address_city": "\"West Valley City\"",
                "address_country": "\"US\"",
                "address_postcode": "\"84120\"",
                "address_state": "\"UT\"",
                "latitude": 40.682533,
                "longitude": -111.987834,
                "name": "\"Boost Mobile Premier Store\"",
                "shop": "\"mobile_phone\"",
                "street_address": "\"4036 West 4100 South\""
            },
            {
                "__id": 2605,
                "address_city": "\"Saint Louis\"",
                "address_country": "\"US\"",
                "address_postcode": "\"63115\"",
                "address_state": "\"MO\"",
                "latitude": 38.6694284,
                "longitude": -90.2360197,
                "name": "\"Boost Mobile Premier Store\"",
                "shop": "\"mobile_phone\"",
                "street_address": "\"4336 Natural Bridge Ave\""
            },
            {
                "__id": 1397,
                "address_city": "\"Davie\"",
                "address_country": "\"US\"",
                "address_postcode": "\"33317\"",
                "address_state": "\"FL\"",
                "latitude": 26.094925,
                "longitude": -80.201369,
                "name": "\"Boost Mobile Premier Store\"",
                "shop": "\"mobile_phone\"",
                "street_address": "\"2003 S State Road 7\""
            },
            {
                "__id": 483,
                "address_city": "\"Newburgh\"",
                "address_country": "\"US\"",
                "address_postcode": "\"12550\"",
                "address_state": "\"NY\"",
                "latitude": 41.5176827,
                "longitude": -74.0713709,
                "name": "\"Boost Mobile Premier Store\"",
                "shop": "\"mobile_phone\"",
                "street_address": "\"1401 Route 300 1108\""
            },
            {
                "__id": 603,
                "address_city": "\"Buffalo\"",
                "address_country": "\"US\"",
                "address_postcode": "\"14211\"",
                "address_state": "\"NY\"",
                "latitude": 42.912005,
                "longitude": -78.839344,
                "name": "\"Boost Mobile Premier Store\"",
                "shop": "\"mobile_phone\"",
                "street_address": "\"1408 Fillmore Ave\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "anntaylor": {
        "__id": 117,
        "id": "anntaylor",
        "name": "Ann Taylor",
        "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'clothes', 'latitude', 'longitude', 'name', 'phone', 'shop', 'website']",
        "rowLength": 94,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763522,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of anntaylor locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 20,
                "address_city": "\"Chester,\"",
                "address_full": "\"208 Route 206 South\"",
                "address_postcode": "\"07930\"",
                "address_state": "\"New Jersey\"",
                "clothes": "\"women\"",
                "latitude": 40.77859569189334,
                "longitude": -74.69888806343079,
                "name": "\"Ann Taylor\"",
                "phone": "\"(908) 879-8552\"",
                "shop": "\"clothes\"",
                "website": "\"https://stores.anntaylor.com/nj/chester/208-route-206-south.html\""
            },
            {
                "__id": 92,
                "address_city": "\"Brea,\"",
                "address_full": "\"2117 Brea Mall\"",
                "address_postcode": "\"92821\"",
                "address_state": "\"California\"",
                "clothes": "\"women\"",
                "latitude": 33.916038,
                "longitude": -117.887217,
                "name": "\"Ann Taylor\"",
                "phone": "\"(714) 529-9002\"",
                "shop": "\"clothes\"",
                "website": "\"https://stores.anntaylor.com/ca/brea/2117-brea-mall.html\""
            },
            {
                "__id": 73,
                "address_city": "\"Fairfax,\"",
                "address_full": "\"11750 Fair Oaks\"",
                "address_postcode": "\"22033\"",
                "address_state": "\"Virginia\"",
                "clothes": "\"women\"",
                "latitude": 38.8623883,
                "longitude": -77.3592103,
                "name": "\"Ann Taylor\"",
                "phone": "\"(703) 383-9040\"",
                "shop": "\"clothes\"",
                "website": "\"https://stores.anntaylor.com/va/fairfax/11750-fair-oaks.html\""
            },
            {
                "__id": 46,
                "address_city": "\"Troy,\"",
                "address_full": "\"2801 West Big Beaver Road\"",
                "address_postcode": "\"48084\"",
                "address_state": "\"Michigan\"",
                "clothes": "\"women\"",
                "latitude": 42.5598479,
                "longitude": -83.1842022,
                "name": "\"Ann Taylor\"",
                "phone": "\"(248) 643-4457\"",
                "shop": "\"clothes\"",
                "website": "\"https://stores.anntaylor.com/mi/troy/2801-west-big-beaver-road.html\""
            },
            {
                "__id": 51,
                "address_city": "\"Deer Park,\"",
                "address_full": "\"20530 North Rand Road\"",
                "address_postcode": "\"60010\"",
                "address_state": "\"Illinois\"",
                "clothes": "\"women\"",
                "latitude": 42.1610486,
                "longitude": -88.0562777,
                "name": "\"Ann Taylor\"",
                "phone": "\"(847) 550-8709\"",
                "shop": "\"clothes\"",
                "website": "\"https://stores.anntaylor.com/il/deer-park/20530-north-rand-road.html\""
            },
            {
                "__id": 88,
                "address_city": "\"Sacramento,\"",
                "address_full": "\"1689 Arden Way\"",
                "address_postcode": "\"95815\"",
                "address_state": "\"California\"",
                "clothes": "\"women\"",
                "latitude": 38.6017999,
                "longitude": -121.4272218,
                "name": "\"Ann Taylor\"",
                "phone": "\"(916) 927-3162\"",
                "shop": "\"clothes\"",
                "website": "\"https://stores.anntaylor.com/ca/sacramento/1689-arden-way.html\""
            },
            {
                "__id": 19,
                "address_city": "\"Marlton,\"",
                "address_full": "\"500 Route 73 South\"",
                "address_postcode": "\"08053\"",
                "address_state": "\"New Jersey\"",
                "clothes": "\"women\"",
                "latitude": 39.8799465,
                "longitude": -74.919713,
                "name": "\"Ann Taylor\"",
                "phone": "\"(856) 797-3401\"",
                "shop": "\"clothes\"",
                "website": "\"https://stores.anntaylor.com/nj/marlton/500-route-73-south.html\""
            },
            {
                "__id": 28,
                "address_city": "\"Alpharetta,\"",
                "address_full": "\"1180 North Point Circle\"",
                "address_postcode": "\"30022\"",
                "address_state": "\"Georgia\"",
                "clothes": "\"women\"",
                "latitude": 34.0485292,
                "longitude": -84.2942424,
                "name": "\"Ann Taylor\"",
                "phone": "\"(770) 664-4915\"",
                "shop": "\"clothes\"",
                "website": "\"https://stores.anntaylor.com/ga/alpharetta/1180-north-point-circle.html\""
            },
            {
                "__id": 18,
                "address_city": "\"Wayne,\"",
                "address_full": "\"1472 Willowbrook Mall\"",
                "address_postcode": "\"07470\"",
                "address_state": "\"New Jersey\"",
                "clothes": "\"women\"",
                "latitude": 40.8886059,
                "longitude": -74.2590303,
                "name": "\"Ann Taylor\"",
                "phone": "\"(973) 812-9355\"",
                "shop": "\"clothes\"",
                "website": "\"https://stores.anntaylor.com/nj/wayne/1472-willowbrook-mall.html\""
            },
            {
                "__id": 69,
                "address_city": "\"Mount Lebanon,\"",
                "address_full": "\"1500 Washington Road\"",
                "address_postcode": "\"15228\"",
                "address_state": "\"Pennsylvania\"",
                "clothes": "\"women\"",
                "latitude": 40.3569955,
                "longitude": -80.051357,
                "name": "\"Ann Taylor\"",
                "phone": "\"(412) 561-8753\"",
                "shop": "\"clothes\"",
                "website": "\"https://stores.anntaylor.com/pa/mount-lebanon/1500-washington-road.html\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "jll": {
        "__id": 118,
        "id": "jll",
        "name": "Jones Lang LaSalle",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'office', 'phone', 'website']",
        "rowLength": 316,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763523,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of jll locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 47,
                "address_city": "\"Montr\u00e9al\"",
                "address_country": "\"CA\"",
                "address_full": "\"1 Place Ville Marie, Suite 3838\"",
                "address_postcode": "\"H3B 2C6\"",
                "address_state": "\"Canada\"",
                "latitude": 45.501769,
                "longitude": -73.5692036,
                "name": "\"Montreal | Centre Ville\"",
                "office": "\"estate_agent\"",
                "phone": "\"+1 514 849 8849\"",
                "website": "\"https://www.jll.ca/en/locations/montreal\""
            },
            {
                "__id": 245,
                "address_city": "\"Bogata\"",
                "address_country": "\"\"",
                "address_full": "\"Calle 82 N 9 - 65\"",
                "address_postcode": "\"110221\"",
                "address_state": "\"Colombia\"",
                "latitude": 4.665731,
                "longitude": -74.0511845,
                "name": "\"Colombia\"",
                "office": "\"estate_agent\"",
                "phone": "\"57 1 744 1410\"",
                "website": "\"\""
            },
            {
                "__id": 5,
                "address_city": "\"Los Angeles\"",
                "address_country": "\"\"",
                "address_full": "\"11620 Wilshire Boulevard\"",
                "address_postcode": "\"90025\"",
                "address_state": "\"California\"",
                "latitude": 34.0448,
                "longitude": -118.46666,
                "name": "\"Los Angeles | Brentwood\"",
                "office": "\"estate_agent\"",
                "phone": "\"+1 310 473 4424\"",
                "website": "\"https://www.us.jll.com/en/locations/west/los-angeles\""
            },
            {
                "__id": 169,
                "address_city": "\"Munich\"",
                "address_country": "\"DE\"",
                "address_full": "\"Jones Lang LaSalle SE\"",
                "address_postcode": "\"80539 Munich\"",
                "address_state": "\"Germany\"",
                "latitude": 48.1456065,
                "longitude": 11.5771956,
                "name": "\"Munich\"",
                "office": "\"estate_agent\"",
                "phone": "\"+49 (0) 89 290088 0\"",
                "website": "\"https://www.jll.de/en/cities/munich\""
            },
            {
                "__id": 243,
                "address_city": "\"Korea\"",
                "address_country": "\"\"",
                "address_full": "\"32F One IFC\"",
                "address_postcode": "\"Seoul 07326\"",
                "address_state": "\"Asia Pacific\"",
                "latitude": 37.525855,
                "longitude": 126.926266,
                "name": "\"JLL Korea\"",
                "office": "\"estate_agent\"",
                "phone": "\"+82 2 3704 8888\"",
                "website": "\"\""
            },
            {
                "__id": 187,
                "address_city": "\"London\"",
                "address_country": "\"\"",
                "address_full": "\"11 Westferry Circus\"",
                "address_postcode": "\"E14 4HE\"",
                "address_state": "\"United Kingdom\"",
                "latitude": 51.5066279,
                "longitude": -0.0283973,
                "name": "\"London Canary Wharf | Residential\"",
                "office": "\"estate_agent\"",
                "phone": "\"+44 (0)20 7715 9700\"",
                "website": "\"\""
            },
            {
                "__id": 119,
                "address_city": "\"Tampa\"",
                "address_country": "\"\"",
                "address_full": "\"101 East Kennedy Boulevard\"",
                "address_postcode": "\"33602\"",
                "address_state": "\"Florida\"",
                "latitude": 27.946785,
                "longitude": -82.4590988,
                "name": "\"Tampa | East Kennedy Boulevard\"",
                "office": "\"estate_agent\"",
                "phone": "\"+1 813 387 9900\"",
                "website": "\"https://www.us.jll.com/en/locations/southeast/florida\""
            },
            {
                "__id": 91,
                "address_city": "\"Melbourne\"",
                "address_country": "\"AU\"",
                "address_full": "\"Wurundjeri Country\"",
                "address_postcode": "\"VIC 3000\"",
                "address_state": "\"Australia\"",
                "latitude": -37.814903,
                "longitude": 144.970669,
                "name": "\"Melbourne\"",
                "office": "\"estate_agent\"",
                "phone": "\"+61 3 9672 6666\"",
                "website": "\"https://www.jll.com.au/en/locations/melbourne-real-estate-market\""
            },
            {
                "__id": 54,
                "address_city": "\"Tempe\"",
                "address_country": "\"\"",
                "address_full": "\"51 W. 3rd Street\"",
                "address_postcode": "\"85281\"",
                "address_state": "\"Arizona\"",
                "latitude": 33.42689,
                "longitude": -111.94093,
                "name": "\"Tempe\"",
                "office": "\"estate_agent\"",
                "phone": "\"+1 480 626 6304\"",
                "website": "\"https://www.us.jll.com/en/locations/west/phoenix\""
            },
            {
                "__id": 216,
                "address_city": "\"Hyderabad\"",
                "address_country": "\"\"",
                "address_full": "\"Level 9, Octave, Salarpuria Sattva Knowledge City,\"",
                "address_postcode": "\"500081\"",
                "address_state": "\"India\"",
                "latitude": 0.0,
                "longitude": 0.0,
                "name": "\"+91 40 71495678\"",
                "office": "\"\"",
                "phone": "\"\"",
                "website": "\"\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "pizza_express_gb": {
        "__id": 119,
        "id": "pizza_express_gb",
        "name": "Pizza Express Great Britain",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'contact_email', 'cuisine', 'image', 'latitude', 'longitude', 'name', 'phone', 'street_address', 'website']",
        "rowLength": 342,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763524,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of pizza_express_gb locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 134,
                "address_city": "\"London\"",
                "address_country": "\"GB\"",
                "address_full": "\"Benbow House, 24 New Globe Walk, London, SE1 9DS\"",
                "address_postcode": "\"SE1 9DS\"",
                "address_state": "\"\"",
                "amenity": "\"restaurant\"",
                "contact_email": "\"Bankside.Manager@pizzaexpress.com\"",
                "cuisine": "\"pizza\"",
                "image": "\"https://www.pizzaexpress.com/-/media/2020_Images/NROR/Bankside_829x680.ashx\"",
                "latitude": 51.50805,
                "longitude": -0.09591,
                "name": "\"London Bankside\"",
                "phone": "\"020 7401 3977\"",
                "street_address": "\"Benbow House, 24 New Globe Walk\"",
                "website": "\"https://www.pizzaexpress.com/london-bankside\""
            },
            {
                "__id": 110,
                "address_city": "\"London\"",
                "address_country": "\"GB\"",
                "address_full": "\"64 Tranquil Vale, Blackheath, London, SE3 0BN\"",
                "address_postcode": "\"SE3 0BN\"",
                "address_state": "\"\"",
                "amenity": "\"restaurant\"",
                "contact_email": "\"Blackheath.Manager@pizzaexpress.com\"",
                "cuisine": "\"pizza\"",
                "image": "\"https://www.pizzaexpress.com/-/media/2020_Images/NROR/Blackheath_829x680.ashx\"",
                "latitude": 51.46704,
                "longitude": 0.00735,
                "name": "\"Blackheath Tranquil Vale\"",
                "phone": "\"020 8318 2595\"",
                "street_address": "\"64 Tranquil Vale, Blackheath\"",
                "website": "\"https://www.pizzaexpress.com/blackheath-tranquil-vale\""
            },
            {
                "__id": 284,
                "address_city": "\"Sheffield\"",
                "address_country": "\"GB\"",
                "address_full": "\"54b The Oasis, The Meadowhall Centre, Sheffield, Yorkshire, S9 1EP\"",
                "address_postcode": "\"S9 1EP\"",
                "address_state": "\"Yorkshire\"",
                "amenity": "\"restaurant\"",
                "contact_email": "\"SheffieldMeadowhall.Manager@pizzaexpress.com\"",
                "cuisine": "\"pizza\"",
                "image": "\"https://www.pizzaexpress.com/-/media/Images/App-Images/sheffieldmeadowhallapp.ashx\"",
                "latitude": 53.41306,
                "longitude": -1.41246,
                "name": "\"Sheffield Meadowhall Shopping Centre\"",
                "phone": "\"0114 256 9623\"",
                "street_address": "\"54b The Oasis, The Meadowhall Centre\"",
                "website": "\"https://www.pizzaexpress.com/sheffield-meadowhall-shopping-centre\""
            },
            {
                "__id": 308,
                "address_city": "\"Yorkshire\"",
                "address_country": "\"GB\"",
                "address_full": "\"Unit 9-10 Arndale Centre, Headingley, Leeds, Yorkshire, LS6 2UJ\"",
                "address_postcode": "\"LS6 2UJ\"",
                "address_state": "\" Leeds \"",
                "amenity": "\"restaurant\"",
                "contact_email": "\"Leeds5.Manager@pizzaexpress.com\"",
                "cuisine": "\"pizza\"",
                "image": "\"https://www.pizzaexpress.com/-/media/Images/App-Images/leedsheadingleyapp.ashx\"",
                "latitude": 53.8222,
                "longitude": -1.578295,
                "name": "\"Headingley Otley Road\"",
                "phone": "\"0113 278 3828\"",
                "street_address": "\"Unit 9-10 Arndale Centre\"",
                "website": "\"https://www.pizzaexpress.com/headingly-otley-road\""
            },
            {
                "__id": 59,
                "address_city": "\"Coulsdon\"",
                "address_country": "\"GB\"",
                "address_full": "\"Ground Floor, Block A, Trinity Square, 127 Brighton Road, Coulsdon CR5 2NJ\"",
                "address_postcode": "\"CR5 2NJ\"",
                "address_state": "\"\"",
                "amenity": "\"restaurant\"",
                "contact_email": "\"Coulsdon.Manager@pizzaexpress.com\"",
                "cuisine": "\"pizza\"",
                "image": "\"https://www.pizzaexpress.com/-/media/Images/App-Images/coulsdonapp.ashx\"",
                "latitude": 51.321804,
                "longitude": -0.136896,
                "name": "\"Coulsdon Trinity Square\"",
                "phone": "\"020 8660 1199\"",
                "street_address": "\"Ground Floor Block A, Trinity Square, 127 Brighton Road\"",
                "website": "\"https://www.pizzaexpress.com/coulsdon-trinity-square\""
            },
            {
                "__id": 269,
                "address_city": "\"Lincolnshire\"",
                "address_country": "\"GB\"",
                "address_full": "\"269 High Street, Lincoln, Lincolnshire, LN2 1JG\"",
                "address_postcode": "\"LN2 1JG\"",
                "address_state": "\"Lincolnshire\"",
                "amenity": "\"restaurant\"",
                "contact_email": "\"Lincoln.Manager@pizzaexpress.com\"",
                "cuisine": "\"pizza\"",
                "image": "\"https://www.pizzaexpress.com/-/media/2022-Images/March/PizzaExpress_Lincoln_Interior_016.ashx\"",
                "latitude": 53.23093,
                "longitude": -0.53957,
                "name": "\"Lincoln High Street\"",
                "phone": "\"01522 544 701\"",
                "street_address": "\"269 High Street\"",
                "website": "\"https://www.pizzaexpress.com/lincoln-high-street\""
            },
            {
                "__id": 324,
                "address_city": "\"Carlisle\"",
                "address_country": "\"GB\"",
                "address_full": "\"21 Lowther Street, Carlisle, Cumbria, CA3 8ES\"",
                "address_postcode": "\"CA3 8ES\"",
                "address_state": "\"Cumbria\"",
                "amenity": "\"restaurant\"",
                "contact_email": "\"Carlisle.Manager@pizzaexpress.com\"",
                "cuisine": "\"pizza\"",
                "image": "\"https://www.pizzaexpress.com/-/media/Images/App-Images/carlisleapp.ashx\"",
                "latitude": 54.89251,
                "longitude": -2.93349,
                "name": "\"Carlisle Lowther Street\"",
                "phone": "\"01228 599 638\"",
                "street_address": "\"21 Lowther Street\"",
                "website": "\"https://www.pizzaexpress.com/carlisle-lowther-street\""
            },
            {
                "__id": 81,
                "address_city": "\"Bromley\"",
                "address_country": "\"GB\"",
                "address_full": "\"15 Widmore Road, Bromley, Kent, BR1 1RL\"",
                "address_postcode": "\"BR1 1RL\"",
                "address_state": "\"Kent\"",
                "amenity": "\"restaurant\"",
                "contact_email": "\"Bromley.Manager@pizzaexpress.com\"",
                "cuisine": "\"pizza\"",
                "image": "\"https://www.pizzaexpress.com/-/media/Images/App-Images/829x680lifestyle4app.ashx\"",
                "latitude": 51.40619,
                "longitude": 0.01611,
                "name": "\"Bromley Widmore Road\"",
                "phone": "\"020 8464 2708\"",
                "street_address": "\"15 Widmore Road\"",
                "website": "\"https://www.pizzaexpress.com/bromley-widmore-road\""
            },
            {
                "__id": 102,
                "address_city": "\"London\"",
                "address_country": "\"GB\"",
                "address_full": "\"136-140 Herne Hill, London, SE24 9QH\"",
                "address_postcode": "\"SE24 9QH\"",
                "address_state": "\"\"",
                "amenity": "\"restaurant\"",
                "contact_email": "\"HerneHill.Manager@pizzaexpress.com\"",
                "cuisine": "\"pizza\"",
                "image": "\"https://www.pizzaexpress.com/-/media/Images/App-Images/hernehillapp.ashx\"",
                "latitude": 51.45296,
                "longitude": -0.1011837,
                "name": "\"Brockwell Park Herne Hill\"",
                "phone": "\"020 7738 3373\"",
                "street_address": "\"136-140 Herne Hill\"",
                "website": "\"https://www.pizzaexpress.com/brockwell-park-herne-hill\""
            },
            {
                "__id": 294,
                "address_city": "\"Manchester\"",
                "address_country": "\"GB\"",
                "address_full": "\"84-86 King Street, Manchester, M2 4WQ\"",
                "address_postcode": "\"M2 4WQ\"",
                "address_state": "\"\"",
                "amenity": "\"restaurant\"",
                "contact_email": "\"Manchester7.Manager@pizzaexpress.com\"",
                "cuisine": "\"pizza\"",
                "image": "\"https://www.pizzaexpress.com/-/media/Images/App-Images/manchesterkingstreetapp.ashx\"",
                "latitude": 53.4808,
                "longitude": -2.243458,
                "name": "\"Manchester King Street\"",
                "phone": "\"0161 832 5435\"",
                "street_address": "\"84-86 King Street\"",
                "website": "\"https://www.pizzaexpress.com/manchester-king-street\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "londis_gb": {
        "__id": 120,
        "id": "londis_gb",
        "name": "Londis Great Britain",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'phone', 'street_address', 'website']",
        "rowLength": 2040,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763525,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of londis_gb locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 355,
                "address_city": "\"Brighton\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"BN1 8YB\"",
                "address_state": "\"East Sussex\"",
                "latitude": 50.863387,
                "longitude": -0.151678,
                "name": "\"Londis Patcham Service Station\"",
                "phone": "\"\"",
                "street_address": "\"Patcham Bypass,\"",
                "website": "\"https://www.londis.co.uk/our-stores/patcham-service-station\""
            },
            {
                "__id": 50,
                "address_city": "\"Blackwater Truro\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"TR4 8HS\"",
                "address_state": "\"Cornwall\"",
                "latitude": 50.279384,
                "longitude": -5.165736,
                "name": "\"Londis Chiverton Cross\"",
                "phone": "\"01872 560 596\"",
                "street_address": "\"Chiverton Cross Rbt.,\"",
                "website": "\"https://www.londis.co.uk/our-stores/chiverton-cross\""
            },
            {
                "__id": 1699,
                "address_city": "\"Stoke on trent\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"ST7 8PS\"",
                "address_state": "\"Staffordshire\"",
                "latitude": 53.0552988,
                "longitude": -2.2854851,
                "name": "\"Londis Londis, Stoke on trent\"",
                "phone": "\"01782 729113\"",
                "street_address": "\"21 Ravens Lane,\"",
                "website": "\"https://www.londis.co.uk/our-stores/londis-stoke-trent\""
            },
            {
                "__id": 1312,
                "address_city": "\"Manchester\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"M27 9GW\"",
                "address_state": "\"\"",
                "latitude": 53.5177447,
                "longitude": -2.3570125,
                "name": "\"Londis Bav\u00e2\u20ac\u2122s Convenience Store\"",
                "phone": "\"0161 794 7585\"",
                "street_address": "\"574 Manchester Road, Wardley\"",
                "website": "\"https://www.londis.co.uk/our-stores/bavaeutms-convenience-store\""
            },
            {
                "__id": 108,
                "address_city": "\"Whitwood Castleford\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"WF10 5NW\"",
                "address_state": "\"West Yorkshire\"",
                "latitude": 53.716492,
                "longitude": -1.38235,
                "name": "\"Londis Castleford\"",
                "phone": "\"01977 520736\"",
                "street_address": "\"Willowbridge Lane,\"",
                "website": "\"https://www.londis.co.uk/our-stores/castleford\""
            },
            {
                "__id": 841,
                "address_city": "\"Morton in marsh\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"GL56 9TE\"",
                "address_state": "\"Gloucestershire\"",
                "latitude": 51.9957558,
                "longitude": -1.7761958,
                "name": "\"Londis Troopers Lodge Filling Station\"",
                "phone": "\"01386 701546\"",
                "street_address": "\"Bourton On The Hill,\"",
                "website": "\"https://www.londis.co.uk/our-stores/troopers-lodge-filling-station\""
            },
            {
                "__id": 1517,
                "address_city": "\"Farcotton\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"NN4 8AG\"",
                "address_state": "\"Northamptonshire\"",
                "latitude": 52.2272481,
                "longitude": -0.8962544,
                "name": "\"Londis Jet Farcotton Service Station\"",
                "phone": "\"01604 705742\"",
                "street_address": "\"19 London Road,\"",
                "website": "\"https://www.londis.co.uk/our-stores/jet-farcotton-service-station\""
            },
            {
                "__id": 1773,
                "address_city": "\"Rotherham\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"S64 8AP\"",
                "address_state": "\"South Yorkshire\"",
                "latitude": 53.4878888,
                "longitude": -1.3030043,
                "name": "\"Londis Jet Swinton Service Station\"",
                "phone": "\"01709 570689\"",
                "street_address": "\"Bridge Street,\"",
                "website": "\"https://www.londis.co.uk/our-stores/jet-swinton-service-station\""
            },
            {
                "__id": 1477,
                "address_city": "\"Solihull\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"B93 0JD\"",
                "address_state": "\"West Midlands\"",
                "latitude": 52.3873496,
                "longitude": -1.7313673,
                "name": "\"Londis BP Knowle Service Station\"",
                "phone": "\"01564 770 910\"",
                "street_address": "\"32 Kenilworth Road,\"",
                "website": "\"https://www.londis.co.uk/our-stores/bp-knowle-service-station\""
            },
            {
                "__id": 1680,
                "address_city": "\"Oxford\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"OX14 4RR\"",
                "address_state": "\"Oxfordshire\"",
                "latitude": 51.6215935,
                "longitude": -1.2935227,
                "name": "\"Londis Londis\"",
                "phone": "\"01235 821369\"",
                "street_address": "\"Unit 5A & 5B Milton Park,\"",
                "website": "\"https://www.londis.co.uk/our-stores/londis-5\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "keybank": {
        "__id": 121,
        "id": "keybank",
        "name": "KeyBank",
        "columnNames": "['address_city', 'address_postcode', 'address_state', 'image', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'street_address', 'twitter', 'website']",
        "rowLength": 1005,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763526,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of keybank locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 293,
                "address_city": "\"Toledo\"",
                "address_postcode": "\"43604\"",
                "address_state": "\"OH\"",
                "image": "\"https://www.key.com/kco/images/key_social_logo.png\"",
                "latitude": 41.656777,
                "longitude": -83.545554,
                "name": "\"Edison Plaza\"",
                "opening_hours": "\"Mo-Fr 09:00-17:00\"",
                "phone": "\"419-259-8160\"",
                "street_address": "\"300 Madison Ave\"",
                "twitter": "\"@keybank\"",
                "website": "\"https://www.key.com/locations/oh/toledo/BRCH01010332/300-madison-ave\""
            },
            {
                "__id": 717,
                "address_city": "\"Kenmore\"",
                "address_postcode": "\"14217\"",
                "address_state": "\"NY\"",
                "image": "\"https://www.key.com/kco/images/key_social_logo.png\"",
                "latitude": 42.96206,
                "longitude": -78.870271,
                "name": "\"Kenmore\"",
                "opening_hours": "\"Mo-Th 09:00-16:00; Fr 09:00-17:00\"",
                "phone": "\"716-515-6140\"",
                "street_address": "\"2858 Delaware Ave\"",
                "twitter": "\"@keybank\"",
                "website": "\"https://www.key.com/locations/ny/kenmore/BRCH32900741/2858-delaware-ave\""
            },
            {
                "__id": 356,
                "address_city": "\"Pittsburgh\"",
                "address_postcode": "\"15222\"",
                "address_state": "\"PA\"",
                "image": "\"https://www.key.com/kco/images/key_social_logo.png\"",
                "latitude": 40.439088,
                "longitude": -80.006343,
                "name": "\"Key Private Bank - Pittsburgh\"",
                "opening_hours": "\"Mo-Fr 08:00-16:30\"",
                "phone": "\"412-807-2723\"",
                "street_address": "\"11 Stanwix St 15th Floor\"",
                "twitter": "\"@keybank\"",
                "website": "\"https://www.key.com/locations/pa/pittsburgh/MCD32900989/11-stanwix-st-15th-floor\""
            },
            {
                "__id": 87,
                "address_city": "\"Ann Arbor\"",
                "address_postcode": "\"48108\"",
                "address_state": "\"MI\"",
                "image": "\"https://www.key.com/kco/images/key_social_logo.png\"",
                "latitude": 42.233962,
                "longitude": -83.739193,
                "name": "\"South State Street\"",
                "opening_hours": "\"Mo-Th 09:00-16:30; Fr 09:00-17:00\"",
                "phone": "\"734-996-8595\"",
                "street_address": "\"3848 S State St\"",
                "twitter": "\"@keybank\"",
                "website": "\"https://www.key.com/locations/mi/ann-arbor/BRCH01010223/3848-s-state-st\""
            },
            {
                "__id": 597,
                "address_city": "\"Norristown\"",
                "address_postcode": "\"19403\"",
                "address_state": "\"PA\"",
                "image": "\"https://www.key.com/kco/images/key_social_logo.png\"",
                "latitude": 40.130225,
                "longitude": -75.403874,
                "name": "\"Audubon Branch\"",
                "opening_hours": "\"Mo-Th 09:00-16:00; Fr 09:00-17:00; Sa 09:00-13:00\"",
                "phone": "\"610-666-7494\"",
                "street_address": "\"2624 Egypt Rd\"",
                "twitter": "\"@keybank\"",
                "website": "\"https://www.key.com/locations/pa/norristown/BRCH32900385/2624-egypt-rd\""
            },
            {
                "__id": 728,
                "address_city": "\"Snoqualmie\"",
                "address_postcode": "\"98065\"",
                "address_state": "\"WA\"",
                "image": "\"https://www.key.com/kco/images/key_social_logo.png\"",
                "latitude": 47.529743,
                "longitude": -121.872919,
                "name": "\"Snoqualmie Ridge\"",
                "opening_hours": "\"Mo-Th 09:00-17:00; Fr 09:00-18:00; Sa 10:00-13:30\"",
                "phone": "\"425-396-8000\"",
                "street_address": "\"7917 Center Blvd Se\"",
                "twitter": "\"@keybank\"",
                "website": "\"https://www.key.com/locations/wa/snoqualmie/BRCH47310274/7917-center-blvd-se\""
            },
            {
                "__id": 753,
                "address_city": "\"Canby\"",
                "address_postcode": "\"97013\"",
                "address_state": "\"OR\"",
                "image": "\"https://www.key.com/kco/images/key_social_logo.png\"",
                "latitude": 45.263233,
                "longitude": -122.694921,
                "name": "\"Canby\"",
                "opening_hours": "\"Mo-Th 09:00-17:00; Fr 09:00-18:00\"",
                "phone": "\"503-980-6503\"",
                "street_address": "\"200 NW Second Ave\"",
                "twitter": "\"@keybank\"",
                "website": "\"https://www.key.com/locations/or/canby/BRCH37200071/200-nw-second-ave\""
            },
            {
                "__id": 107,
                "address_city": "\"Highlands Ranch\"",
                "address_postcode": "\"80126\"",
                "address_state": "\"CO\"",
                "image": "\"https://www.key.com/kco/images/key_social_logo.png\"",
                "latitude": 39.542129,
                "longitude": -104.947025,
                "name": "\"Fairview\"",
                "opening_hours": "\"Mo 09:00-18:00; Tu-Fr 09:00-17:00\"",
                "phone": "\"720-344-2000\"",
                "street_address": "\"3626 Highlands Ranch Pkwy Ste 210\"",
                "twitter": "\"@keybank\"",
                "website": "\"https://www.key.com/locations/co/highlands-ranch/BRCH06180158/3626-highlands-ranch-pkwy-ste-210\""
            },
            {
                "__id": 247,
                "address_city": "\"Portland\"",
                "address_postcode": "\"97214\"",
                "address_state": "\"OR\"",
                "image": "\"https://www.key.com/kco/images/key_social_logo.png\"",
                "latitude": 45.51217,
                "longitude": -122.619619,
                "name": "\"Hawthorne\"",
                "opening_hours": "\"Mo-Th 09:00-17:00; Fr 09:00-18:00\"",
                "phone": "\"503-235-5848\"",
                "street_address": "\"4131 SE Hawthorne Blvd\"",
                "twitter": "\"@keybank\"",
                "website": "\"https://www.key.com/locations/or/portland/BRCH37200035/4131-se-hawthorne-blvd\""
            },
            {
                "__id": 566,
                "address_city": "\"Bellbrook\"",
                "address_postcode": "\"45305\"",
                "address_state": "\"OH\"",
                "image": "\"https://www.key.com/kco/images/key_social_logo.png\"",
                "latitude": 39.636253,
                "longitude": -84.071332,
                "name": "\"Bellbrook\"",
                "opening_hours": "\"Mo-Th 09:00-17:00; Fr 09:00-18:00\"",
                "phone": "\"937-848-6111\"",
                "street_address": "\"18 W Franklin St\"",
                "twitter": "\"@keybank\"",
                "website": "\"https://www.key.com/locations/oh/bellbrook/BRCH01010379/18-w-franklin-st\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "subway": {
        "__id": 122,
        "id": "subway",
        "name": "Subway",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'image', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'street_address', 'takeaway', 'website']",
        "rowLength": 30186,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763528,
        "runTime": 2,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of subway locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 16646,
                "address_city": "\"Chapleau\"",
                "address_country": "\"CA\"",
                "address_postcode": "\"P0M 1K0\"",
                "address_state": "\"ON\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"sandwich\"",
                "image": "\"https://restaurants.subway.com/permanent-b0b701/assets/images/hero_fallback.50bb3276.jpg\"",
                "latitude": 47.8424223,
                "longitude": -83.4007805,
                "name": "\"Subway  29 Lorne Street\"",
                "opening_hours": "\"Mo-Fr 06:00-22:00; Sa 07:00-22:00; Su 08:00-22:00\"",
                "phone": "\"(705) 864-2212\"",
                "street_address": "\"29 Lorne Street\"",
                "takeaway": "\"yes\"",
                "website": "\"https://restaurants.subway.com/fr_ca/canada/on/chapleau/29-lorne-street\""
            },
            {
                "__id": 743,
                "address_city": "\"Wauwatosa Milwaukee\"",
                "address_country": "\"US\"",
                "address_postcode": "\"53213\"",
                "address_state": "\"WI\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"sandwich\"",
                "image": "\"https://dynl.mktgcdn.com/p/XNBDyl6-e6asFIEuR2wAgoEdqiSjAclS_eYHcFEl4A4/1440x710.jpg\"",
                "latitude": 43.06045050046556,
                "longitude": -87.9972199807338,
                "name": "\"Subway  6733 W North Ave\"",
                "opening_hours": "\"Mo 07:30-21:00; Tu-Fr 07:00-21:00; Sa-Su 09:00-21:00\"",
                "phone": "\"(414) 257-1070\"",
                "street_address": "\"6733 W North Ave\"",
                "takeaway": "\"yes\"",
                "website": "\"https://restaurants.subway.com/united-states/wi/wauwatosa/6733-w-north-ave\""
            },
            {
                "__id": 13646,
                "address_city": "\"Allentown Lehigh\"",
                "address_country": "\"US\"",
                "address_postcode": "\"18102\"",
                "address_state": "\"PA\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"sandwich\"",
                "image": "\"https://dynl.mktgcdn.com/p/XNBDyl6-e6asFIEuR2wAgoEdqiSjAclS_eYHcFEl4A4/1440x710.jpg\"",
                "latitude": 40.608491473126676,
                "longitude": -75.48926779447743,
                "name": "\"Subway  1313 Tilghman St\"",
                "opening_hours": "\"Mo-Sa 07:00-22:00; Su 07:00-20:00\"",
                "phone": "\"(610) 434-5649\"",
                "street_address": "\"1313 Tilghman St\"",
                "takeaway": "\"yes\"",
                "website": "\"https://restaurants.subway.com/united-states/pa/allentown/1313-tilghman-st\""
            },
            {
                "__id": 25603,
                "address_city": "\"Billings Yellowstone\"",
                "address_country": "\"US\"",
                "address_postcode": "\"59102\"",
                "address_state": "\"MT\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"sandwich\"",
                "image": "\"https://dynl.mktgcdn.com/p/XNBDyl6-e6asFIEuR2wAgoEdqiSjAclS_eYHcFEl4A4/1440x710.jpg\"",
                "latitude": 45.784302746076186,
                "longitude": -108.53820717822435,
                "name": "\"Subway  875 Grand Ave\"",
                "opening_hours": "\"Mo-Fr 07:30-21:00; Sa-Su 08:00-20:00\"",
                "phone": "\"(406) 248-4910\"",
                "street_address": "\"875 Grand Ave\"",
                "takeaway": "\"yes\"",
                "website": "\"https://restaurants.subway.com/united-states/mt/billings/875-grand-ave\""
            },
            {
                "__id": 13113,
                "address_city": "\"West Covina Los Angeles\"",
                "address_country": "\"US\"",
                "address_postcode": "\"91792\"",
                "address_state": "\"CA\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"sandwich\"",
                "image": "\"https://dynl.mktgcdn.com/p/XNBDyl6-e6asFIEuR2wAgoEdqiSjAclS_eYHcFEl4A4/1440x710.jpg\"",
                "latitude": 34.00905647537647,
                "longitude": -117.88684386135958,
                "name": "\"Subway  19030 La Puente Road\"",
                "opening_hours": "\"Mo-Fr 08:00-21:30; Sa-Su 09:00-21:30\"",
                "phone": "\"(626) 839-7504\"",
                "street_address": "\"19030 La Puente Road\"",
                "takeaway": "\"yes\"",
                "website": "\"https://restaurants.subway.com/united-states/ca/west-covina/19030-la-puente-road\""
            },
            {
                "__id": 25761,
                "address_city": "\"Huddersfield West Yorkshire\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"HD1 4LG\"",
                "address_state": "\"\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"sandwich\"",
                "image": "\"https://restaurants.subway.com/permanent-b0b701/assets/images/hero_fallback.50bb3276.jpg\"",
                "latitude": 53.6513103,
                "longitude": -1.8057547,
                "name": "\"Subway  49/49A Westbourne Road\"",
                "opening_hours": "\"Mo-Th 08:00-19:30; Fr-Sa 08:00-20:00; Su 10:00-19:00\"",
                "phone": "\"01484 546041\"",
                "street_address": "\"49 Westbourne Road Marsh\"",
                "takeaway": "\"yes\"",
                "website": "\"https://restaurants.subway.com/united-kingdom/en/huddersfield/49-westbourne-road\""
            },
            {
                "__id": 10505,
                "address_city": "\"Lexington Lexington\"",
                "address_country": "\"US\"",
                "address_postcode": "\"29073\"",
                "address_state": "\"SC\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"sandwich\"",
                "image": "\"https://dynl.mktgcdn.com/p/XNBDyl6-e6asFIEuR2wAgoEdqiSjAclS_eYHcFEl4A4/1440x710.jpg\"",
                "latitude": 33.9111,
                "longitude": -81.229581,
                "name": "\"Subway  Wal-Mart #4521\"",
                "opening_hours": "\"Mo-Su 08:00-20:00\"",
                "phone": "\"(803) 996-6194\"",
                "street_address": "\"1780 S Lake Dr\"",
                "takeaway": "\"yes\"",
                "website": "\"https://restaurants.subway.com/united-states/sc/lexington/1780-s-lake-dr\""
            },
            {
                "__id": 27650,
                "address_city": "\"Caledonia Houston\"",
                "address_country": "\"US\"",
                "address_postcode": "\"55921\"",
                "address_state": "\"MN\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"sandwich\"",
                "image": "\"https://dynl.mktgcdn.com/p/XNBDyl6-e6asFIEuR2wAgoEdqiSjAclS_eYHcFEl4A4/1440x710.jpg\"",
                "latitude": 43.63812101747104,
                "longitude": -91.5024475749832,
                "name": "\"Subway  507 Vista Dr\"",
                "opening_hours": "\"Mo-Su 09:00-21:00\"",
                "phone": "\"(507) 725-5652\"",
                "street_address": "\"507 Vista Dr\"",
                "takeaway": "\"yes\"",
                "website": "\"https://restaurants.subway.com/united-states/mn/caledonia/507-vista-dr\""
            },
            {
                "__id": 11910,
                "address_city": "\"Pittsburgh Allegheny\"",
                "address_country": "\"US\"",
                "address_postcode": "\"15237\"",
                "address_state": "\"PA\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"sandwich\"",
                "image": "\"https://dynl.mktgcdn.com/p/XNBDyl6-e6asFIEuR2wAgoEdqiSjAclS_eYHcFEl4A4/1440x710.jpg\"",
                "latitude": 40.552458,
                "longitude": -80.0156114,
                "name": "\"Subway  Mcintyre Square Shopping Ctr\"",
                "opening_hours": "\"Mo-Su 09:00-19:00\"",
                "phone": "\"(412) 364-4854\"",
                "street_address": "\"3070 Mcintyre Square Dr\"",
                "takeaway": "\"yes\"",
                "website": "\"https://restaurants.subway.com/united-states/pa/pittsburgh/3070-mcintyre-square-dr\""
            },
            {
                "__id": 17440,
                "address_city": "\"Antigonish\"",
                "address_country": "\"CA\"",
                "address_postcode": "\"B2G 1R7\"",
                "address_state": "\"NS\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"sandwich\"",
                "image": "\"https://restaurants.subway.com/permanent-b0b701/assets/images/hero_fallback.50bb3276.jpg\"",
                "latitude": 45.6154868,
                "longitude": -62.0035042,
                "name": "\"Subway  46 James St\"",
                "opening_hours": "\"Mo 06:00-24:00; Tu 01:00-24:00; We-Sa 06:00-24:00; Su 07:00-24:00\"",
                "phone": "\"(902) 863-8373\"",
                "street_address": "\"46 James St\"",
                "takeaway": "\"yes\"",
                "website": "\"https://restaurants.subway.com/fr_ca/canada/ns/antigonish/46-james-st\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "scrubs_and_beyond": {
        "__id": 123,
        "id": "scrubs_and_beyond",
        "name": "Scrubs & Beyond",
        "columnNames": "['address_city', 'address_postcode', 'address_state', 'image', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'street_address', 'website']",
        "rowLength": 117,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763529,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of scrubs_and_beyond locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 59,
                "address_city": "\"Peoria\"",
                "address_postcode": "\"85305\"",
                "address_state": "\"AZ\"",
                "image": "\"https://s7d2.scene7.com/is/image/scrubsandbeyond/sb-tri-logo-large\"",
                "latitude": 33.5522328,
                "longitude": -112.2631972,
                "name": "\"Nursing Uniforms & Medical Scrubs at 9494 W Northern Ave Suite 112 | Scrubs & Beyond Near Me\"",
                "opening_hours": "\"Mo-Fr 10:00-20:00; Sa 10:00-18:00; Su 11:00-17:00\"",
                "phone": "\"(623) 872-2989\"",
                "street_address": "\"9494 W Northern Ave Suite 112 Suite 112\"",
                "website": "\"https://locations.scrubsandbeyond.com/az/peoria/scrubs-peoria-az-33.html\""
            },
            {
                "__id": 91,
                "address_city": "\"Overland Park\"",
                "address_postcode": "\"66209\"",
                "address_state": "\"KS\"",
                "image": "\"https://s7d2.scene7.com/is/image/scrubsandbeyond/sb-tri-logo-large\"",
                "latitude": 38.9125402,
                "longitude": -94.665622,
                "name": "\"Nursing Uniforms & Medical Scrubs at 6951 W. 119th Street Suite B | Scrubs & Beyond Near Me\"",
                "opening_hours": "\"Mo-Fr 10:00-17:00; Sa 10:00-18:00; Su 12:00-16:00\"",
                "phone": "\"(913) 901-9242\"",
                "street_address": "\"6951 W. 119th Street Suite B Suite B\"",
                "website": "\"https://locations.scrubsandbeyond.com/ks/overlandpark/scrubs-overland-park-ks-483.html\""
            },
            {
                "__id": 6,
                "address_city": "\"Aurora\"",
                "address_postcode": "\"80012\"",
                "address_state": "\"CO\"",
                "image": "\"https://s7d2.scene7.com/is/image/scrubsandbeyond/sb-tri-logo-large\"",
                "latitude": 39.6963173,
                "longitude": -104.8268112,
                "name": "\"Nursing Uniforms & Medical Scrubs at 1113 South Abilene St Suite 107 | Scrubs & Beyond Near Me\"",
                "opening_hours": "\"Mo-Fr 09:30-19:30; Sa 10:00-18:00; Su 11:00-17:00\"",
                "phone": "\"(720) 748-7783\"",
                "street_address": "\"1113 South Abilene St Suite 107 Suite 107\"",
                "website": "\"https://locations.scrubsandbeyond.com/co/aurora/scrubs-aurora-co-35.html\""
            },
            {
                "__id": 39,
                "address_city": "\"Gilbert\"",
                "address_postcode": "\"85297\"",
                "address_state": "\"AZ\"",
                "image": "\"https://s7d2.scene7.com/is/image/scrubsandbeyond/sb-tri-logo-large\"",
                "latitude": 33.281865,
                "longitude": -111.787406,
                "name": "\"Nursing Uniforms & Medical Scrubs at 3755 S Gilbert Road | Scrubs & Beyond Near Me\"",
                "opening_hours": "\"Mo-Fr 10:00-21:00; Sa 10:00-20:00; Su 12:00-18:00\"",
                "phone": "\"(480) 855-3065\"",
                "street_address": "\"3755 S Gilbert Road Suite 101\"",
                "website": "\"https://locations.scrubsandbeyond.com/az/gilbert/scrubs-gilbert-az-28.html\""
            },
            {
                "__id": 111,
                "address_city": "\"Riverside\"",
                "address_postcode": "\"92503\"",
                "address_state": "\"CA\"",
                "image": "\"https://s7d2.scene7.com/is/image/scrubsandbeyond/sb-tri-logo-large\"",
                "latitude": 33.909435,
                "longitude": -117.456559,
                "name": "\"Nursing Uniforms & Medical Scrubs at 1289 Tyler St | Scrubs & Beyond Near Me\"",
                "opening_hours": "\"Mo-Sa 11:00-20:00; Su 11:00-18:00\"",
                "phone": "\"(951) 353-1085\"",
                "street_address": "\"1289 Tyler St\"",
                "website": "\"https://locations.scrubsandbeyond.com/ca/riverside/scrubs-riverside-ca-266.html\""
            },
            {
                "__id": 35,
                "address_city": "\"Charlotte\"",
                "address_postcode": "\"28216\"",
                "address_state": "\"NC\"",
                "image": "\"https://s7d2.scene7.com/is/image/scrubsandbeyond/sb-tri-logo-large\"",
                "latitude": 35.3490261,
                "longitude": -80.8576277,
                "name": "\"Nursing Uniforms & Medical Scrubs at 9821 Northlake Center Parkway, Ste. F | Scrubs & Beyond Near Me\"",
                "opening_hours": "\"Mo-Sa 10:00-19:00; Su 12:00-18:00\"",
                "phone": "\"(704) 979-0121\"",
                "street_address": "\"9821 Northlake Center Parkway, Ste. F Ste. F\"",
                "website": "\"https://locations.scrubsandbeyond.com/nc/charlotte/scrubs-charlotte-nc-3.html\""
            },
            {
                "__id": 99,
                "address_city": "\"Coon Rapids\"",
                "address_postcode": "\"55448\"",
                "address_state": "\"MN\"",
                "image": "\"https://s7d2.scene7.com/is/image/scrubsandbeyond/sb-tri-logo-large\"",
                "latitude": 45.1998706,
                "longitude": -93.3542623,
                "name": "\"Nursing Uniforms & Medical Scrubs at 12770 Riverdale Blvd | Scrubs & Beyond Near Me\"",
                "opening_hours": "\"Mo-Fr 10:00-20:00; Sa 10:00-18:00; Su 11:00-17:00\"",
                "phone": "\"(763) 433-8366\"",
                "street_address": "\"12770 Riverdale Blvd\"",
                "website": "\"https://locations.scrubsandbeyond.com/mn/coonrapids/scrubs-coon-rapids-mn-15.html\""
            },
            {
                "__id": 112,
                "address_city": "\"Pittsburgh\"",
                "address_postcode": "\"15237\"",
                "address_state": "\"PA\"",
                "image": "\"https://s7d2.scene7.com/is/image/scrubsandbeyond/sb-tri-logo-large\"",
                "latitude": 40.553706,
                "longitude": -80.015767,
                "name": "\"Nursing Uniforms & Medical Scrubs at 3260 McIntyre Square Dr. Unit 60 | Scrubs & Beyond Near Me\"",
                "opening_hours": "\"Mo-Fr 10:00-19:00; Sa 10:00-18:00; Su 12:00-17:00\"",
                "phone": "\"(412) 367-1344\"",
                "street_address": "\"3260 McIntyre Square Dr. Unit 60 Unit 60\"",
                "website": "\"https://locations.scrubsandbeyond.com/pa/pittsburgh/scrubs-pittsburgh-pa-417.html\""
            },
            {
                "__id": 13,
                "address_city": "\"Cincinnati\"",
                "address_postcode": "\"45251\"",
                "address_state": "\"OH\"",
                "image": "\"https://s7d2.scene7.com/is/image/scrubsandbeyond/sb-tri-logo-large\"",
                "latitude": 39.242817,
                "longitude": -84.596416,
                "name": "\"Nursing Uniforms & Medical Scrubs at Northgate Mall 9457 Colerain Ave. | Scrubs & Beyond Near Me\"",
                "opening_hours": "\"Mo-Fr 10:00-20:00; Sa 10:00-19:00; Su 12:00-18:00\"",
                "phone": "\"(513) 385-5911\"",
                "street_address": "\"Northgate Mall 9457 Colerain Ave.\"",
                "website": "\"https://locations.scrubsandbeyond.com/oh/cincinnati/scrubs-cincinnati-oh-138.html\""
            },
            {
                "__id": 43,
                "address_city": "\"Myrtle Beach\"",
                "address_postcode": "\"29577\"",
                "address_state": "\"SC\"",
                "image": "\"https://s7d2.scene7.com/is/image/scrubsandbeyond/sb-tri-logo-large\"",
                "latitude": 33.7120925,
                "longitude": -78.8941004,
                "name": "\"Nursing Uniforms & Medical Scrubs at 1138 Seaboard Street | Scrubs & Beyond Near Me\"",
                "opening_hours": "\"Mo-Sa 10:00-20:00; Su 12:00-18:00\"",
                "phone": "\"(843) 448-7343\"",
                "street_address": "\"1138 Seaboard Street\"",
                "website": "\"https://locations.scrubsandbeyond.com/sc/myrtlebeach/scrubs-myrtle-beach-sc-442.html\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "aldi_nord_pt": {
        "__id": 124,
        "id": "aldi_nord_pt",
        "name": "Aldi Nord Portugal",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'name', 'shop', 'street_address', 'website']",
        "rowLength": 115,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763530,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of aldi_nord_pt locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 12,
                "address_city": "\"Vila Nova de Gaia\"",
                "address_country": "\"PT\"",
                "address_postcode": "\"4405-905\"",
                "name": "\"ALDI Vila Nova de Gaia - Rua Jos\u00e9 Gomes Soares, Vilar do Para\u00edso\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Rua Jos\u00e9 Gomes Soares, Vilar do Para\u00edso 249\"",
                "website": "\"https://www.aldi.pt/tools/lojas-e-horarios-de-funcionamento/porto/vila-nova-de-gaia/rua-jose-gomes-soares-vilar-do-paraiso-249.html\""
            },
            {
                "__id": 45,
                "address_city": "\"Olh\u00e3o\"",
                "address_country": "\"PT\"",
                "address_postcode": "\"8700-224\"",
                "name": "\"ALDI Olh\u00e3o - Av. D. Jo\u00e3o VI\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Av. D. Jo\u00e3o VI\"",
                "website": "\"https://www.aldi.pt/tools/lojas-e-horarios-de-funcionamento/faro/olhao/av-d-joao-vi.html\""
            },
            {
                "__id": 96,
                "address_city": "\"Entroncamento\"",
                "address_country": "\"PT\"",
                "address_postcode": "\"2330-027\"",
                "name": "\"ALDI Entroncamento - Rua Bartolomeu de Gusm\u00e3o\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Rua Bartolomeu de Gusm\u00e3o 3\"",
                "website": "\"https://www.aldi.pt/tools/lojas-e-horarios-de-funcionamento/santarem/entroncamento/rua-bartolomeu-de-gusmao-3.html\""
            },
            {
                "__id": 68,
                "address_city": "\"Caldas da Rainha\"",
                "address_country": "\"PT\"",
                "address_postcode": "\"2500-934\"",
                "name": "\"ALDI Caldas da Rainha - Avenida Timor Lorosae\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Avenida Timor Lorosae\"",
                "website": "\"https://www.aldi.pt/tools/lojas-e-horarios-de-funcionamento/leiria/caldas-da-rainha/avenida-timor-lorosae.html\""
            },
            {
                "__id": 99,
                "address_city": "\"F\u00e1tima\"",
                "address_country": "\"PT\"",
                "address_postcode": "\"2495-456\"",
                "name": "\"ALDI F\u00e1tima - Avenida Beato Nuno\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Avenida Beato Nuno 108\"",
                "website": "\"https://www.aldi.pt/tools/lojas-e-horarios-de-funcionamento/santarem/fatima/avenida-beato-nuno-108.html\""
            },
            {
                "__id": 73,
                "address_city": "\"Leiria\"",
                "address_country": "\"PT\"",
                "address_postcode": "\"2410-119\"",
                "name": "\"ALDI Leiria - Rua D. Jos\u00e9 Alves Correia da Silva\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Rua D. Jos\u00e9 Alves Correia da Silva\"",
                "website": "\"https://www.aldi.pt/tools/lojas-e-horarios-de-funcionamento/leiria/leiria/rua-d-jose-alves-correia-da-silva.html\""
            },
            {
                "__id": 6,
                "address_city": "\"Elvas\"",
                "address_country": "\"PT\"",
                "address_postcode": "\"7350-091\"",
                "name": "\"ALDI Elvas - Avenida Ant\u00f3nio Sardinha\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Avenida Ant\u00f3nio Sardinha 20 A\"",
                "website": "\"https://www.aldi.pt/tools/lojas-e-horarios-de-funcionamento/portalegre/elvas/avenida-antonio-sardinha-20-a.html\""
            },
            {
                "__id": 5,
                "address_city": "\"Valongo\"",
                "address_country": "\"PT\"",
                "address_postcode": "\"4440-652\"",
                "name": "\"ALDI Valongo - Rua Fonseca Dias\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Rua Fonseca Dias 80 e 84\"",
                "website": "\"https://www.aldi.pt/tools/lojas-e-horarios-de-funcionamento/porto/valongo/rua-fonseca-dias-80-e-84.html\""
            },
            {
                "__id": 61,
                "address_city": "\"Bobadela\"",
                "address_country": "\"PT\"",
                "address_postcode": "\"2695-066\"",
                "name": "\"ALDI Bobadela - Estrada Nacional 10, Km 140+200 E\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Estrada Nacional 10, Km 140+200 E\"",
                "website": "\"https://www.aldi.pt/tools/lojas-e-horarios-de-funcionamento/lisboa/bobadela/estrada-nacional-10-km-140-200-e.html\""
            },
            {
                "__id": 25,
                "address_city": "\"Santa Maria da Feira\"",
                "address_country": "\"PT\"",
                "address_postcode": "\"4520-283\"",
                "name": "\"ALDI Santa Maria da Feira - Rua Dr. Crispim Borges de Castro\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Rua Dr. Crispim Borges de Castro 38\"",
                "website": "\"https://www.aldi.pt/tools/lojas-e-horarios-de-funcionamento/aveiro/santa-maria-da-feira/rua-dr-crispim-borges-de-castro-38.html\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "lidl_gb": {
        "__id": 125,
        "id": "lidl_gb",
        "name": "Lidl Great Britain",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'latitude', 'longitude', 'name', 'opening_hours', 'shop', 'street_address', 'toilets', 'toilets_access']",
        "rowLength": 937,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763531,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of lidl_gb locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 53,
                "address_city": "\"Horley\"",
                "address_country": "\"GB\"",
                "address_full": "\"100 Victoria Road, Surrey, Horley, RH6 7AB, United Kingdom\"",
                "address_postcode": "\"RH6 7AB\"",
                "latitude": 51.17263,
                "longitude": -0.16439,
                "name": "\"Horley\"",
                "opening_hours": "\"Mo-Sa 08:00-21:00; Su 10:00-16:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"100 Victoria Road\"",
                "toilets": "\"\"",
                "toilets_access": "\"\""
            },
            {
                "__id": 808,
                "address_city": "\"Helston\"",
                "address_country": "\"GB\"",
                "address_full": "\"Penzance Road, Cornwall, Helston, TR13 0SF, United Kingdom\"",
                "address_postcode": "\"TR13 0SF\"",
                "latitude": 50.10019,
                "longitude": -5.28,
                "name": "\"Helston\"",
                "opening_hours": "\"Mo-Sa 07:00-22:00; Su 10:00-16:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Penzance Road\"",
                "toilets": "\"\"",
                "toilets_access": "\"\""
            },
            {
                "__id": 889,
                "address_city": "\"Aldershot\"",
                "address_country": "\"GB\"",
                "address_full": "\"8 Wellington Centre, Aldershot, GU11 1DB, United Kingdom\"",
                "address_postcode": "\"GU11 1DB\"",
                "latitude": 51.24868,
                "longitude": -0.76566,
                "name": "\"Aldershot\"",
                "opening_hours": "\"Mo-Sa 08:00-20:00; Su 10:00-16:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"8 Wellington Centre\"",
                "toilets": "\"\"",
                "toilets_access": "\"\""
            },
            {
                "__id": 911,
                "address_city": "\"Wokingham\"",
                "address_country": "\"GB\"",
                "address_full": "\"Molly Millars Lane, Wokingham, RG41 2RU, United Kingdom\"",
                "address_postcode": "\"RG41 2RU\"",
                "latitude": 51.40593,
                "longitude": -0.84633,
                "name": "\"Wokingham\"",
                "opening_hours": "\"Mo-Sa 08:00-22:00; Su 10:00-16:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Molly Millars Lane\"",
                "toilets": "\"\"",
                "toilets_access": "\"\""
            },
            {
                "__id": 666,
                "address_city": "\"Portsmouth\"",
                "address_country": "\"GB\"",
                "address_full": "\"London Road, Portsmouth, PO2 9AB, United Kingdom\"",
                "address_postcode": "\"PO2 9AB\"",
                "latitude": 50.81527,
                "longitude": -1.07971,
                "name": "\"North End\"",
                "opening_hours": "\"Mo-Sa 08:00-22:00; Su 10:00-16:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"London Road\"",
                "toilets": "\"yes\"",
                "toilets_access": "\"customers\""
            },
            {
                "__id": 679,
                "address_city": "\"Wilmslow\"",
                "address_country": "\"GB\"",
                "address_full": "\"Dean Row Road, Wilmslow, SK9 2TA, United Kingdom\"",
                "address_postcode": "\"SK9 2TA\"",
                "latitude": 53.33668,
                "longitude": -2.2164,
                "name": "\"Wilmslow\"",
                "opening_hours": "\"Mo-Sa 08:00-22:00; Su 10:00-16:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Dean Row Road\"",
                "toilets": "\"yes\"",
                "toilets_access": "\"customers\""
            },
            {
                "__id": 497,
                "address_city": "\"Knottingley\"",
                "address_country": "\"GB\"",
                "address_full": "\"Hill Top, Knottingley, WF11 8EE, United Kingdom\"",
                "address_postcode": "\"WF11 8EE\"",
                "latitude": 53.70956,
                "longitude": -1.24809,
                "name": "\"Knottingley\"",
                "opening_hours": "\"Mo-Sa 08:00-22:00; Su 10:00-16:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Hill Top\"",
                "toilets": "\"yes\"",
                "toilets_access": "\"customers\""
            },
            {
                "__id": 839,
                "address_city": "\"Darwen\"",
                "address_country": "\"GB\"",
                "address_full": "\"Duckworth Street, Lancashire, Darwen, BB3 1QA, United Kingdom\"",
                "address_postcode": "\"BB3 1QA\"",
                "latitude": 53.69891,
                "longitude": -2.47204,
                "name": "\"Darwen\"",
                "opening_hours": "\"Mo-Sa 08:00-22:00; Su 10:00-16:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Duckworth Street\"",
                "toilets": "\"\"",
                "toilets_access": "\"\""
            },
            {
                "__id": 827,
                "address_city": "\"Plymouth\"",
                "address_country": "\"GB\"",
                "address_full": "\"Towerfield Drive, Devon, Plymouth, PL6 7FG, United Kingdom\"",
                "address_postcode": "\"PL6 7FG\"",
                "latitude": 50.43579,
                "longitude": -4.10701,
                "name": "\"Woolwell\"",
                "opening_hours": "\"Mo-Sa 07:00-22:00; Su 10:00-16:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Towerfield Drive\"",
                "toilets": "\"\"",
                "toilets_access": "\"\""
            },
            {
                "__id": 429,
                "address_city": "\"Glenrothes\"",
                "address_country": "\"GB\"",
                "address_full": "\"Leslie Road, Glenrothes, KY7 5PS, United Kingdom\"",
                "address_postcode": "\"KY7 5PS\"",
                "latitude": 56.1985,
                "longitude": -3.17427,
                "name": "\"Glenrothes\"",
                "opening_hours": "\"Mo-Su 08:00-22:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Leslie Road\"",
                "toilets": "\"\"",
                "toilets_access": "\"\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "mydentist_gb": {
        "__id": 126,
        "id": "mydentist_gb",
        "name": "My Dentist Great Britain",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'amenity', 'contact_email', 'healthcare', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'street_address', 'website']",
        "rowLength": 570,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763532,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of mydentist_gb locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 461,
                "address_city": "\"['Stoke-on-Trent', '537 King Street']\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"ST3 1HD\"",
                "amenity": "\"dentist\"",
                "contact_email": "\"\"",
                "healthcare": "\"dentist\"",
                "latitude": 52.990521,
                "longitude": -2.136864,
                "name": "\"{my}dentist, King Street, Longton\"",
                "opening_hours": "\"Mo-Th 09:00-17:30; Fr 08:00-16:00\"",
                "phone": "\"01782 334940\"",
                "street_address": "\"St Johns Chambers\"",
                "website": "\"https://www.mydentist.co.uk/dentists/practices/england/west-midlands/stoke-on-trent/st-johns-chambers-0\""
            },
            {
                "__id": 109,
                "address_city": "\"Abergele\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"LL227AW\"",
                "amenity": "\"dentist\"",
                "contact_email": "\"\"",
                "healthcare": "\"dentist\"",
                "latitude": 53.283777,
                "longitude": -3.5829129999999623,
                "name": "\"{my}dentist, Chapel Street, Abergele\"",
                "opening_hours": "\"Mo-Fr 08:30-17:30\"",
                "phone": "\"01745 826885\"",
                "street_address": "\"1 Chapel Street\"",
                "website": "\"https://www.mydentist.co.uk/dentists/practices/wales/north-wales/abergele/1-chapel-street\""
            },
            {
                "__id": 216,
                "address_city": "\"['Poulton-le-Fylde', 'Broadpool Lane']\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"FY6 9AG\"",
                "amenity": "\"dentist\"",
                "contact_email": "\"\"",
                "healthcare": "\"dentist\"",
                "latitude": 53.874483,
                "longitude": -2.956668000000036,
                "name": "\"{my}dentist, Broadpool Lane, Poulton-le-Fylde\"",
                "opening_hours": "\"Mo-Fr 09:00-17:00\"",
                "phone": "\"01253 701633\"",
                "street_address": "\"1 West Lodge\"",
                "website": "\"https://www.mydentist.co.uk/dentists/practices/england/north-west-england/poulton-le-fylde/1-west-lodge\""
            },
            {
                "__id": 98,
                "address_city": "\"['Doncaster', 'York Road']\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"DN5 9BL\"",
                "amenity": "\"dentist\"",
                "contact_email": "\"\"",
                "healthcare": "\"dentist\"",
                "latitude": 53.535487,
                "longitude": -1.159793000000036,
                "name": "\"{my}dentist, Halifax Crescent, Doncaster\"",
                "opening_hours": "\"Mo-Tu 08:00-17:45; We-Fr 08:00-17:00\"",
                "phone": "\"01302 784145\"",
                "street_address": "\"25. Halifax crescent\"",
                "website": "\"https://www.mydentist.co.uk/dentists/practices/england/yorkshire-and-the-humber/doncaster/25-halifax-crescent\""
            },
            {
                "__id": 555,
                "address_city": "\"Ely\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"CB7 4LN\"",
                "amenity": "\"dentist\"",
                "contact_email": "\"\"",
                "healthcare": "\"dentist\"",
                "latitude": 52.399703,
                "longitude": 0.26377800000000207,
                "name": "\"{my}dentist, Chequer Lane, Ely\"",
                "opening_hours": "\"Mo-Th 08:30-17:00; Fr 08:00-16:00\"",
                "phone": "\"01353 668281\"",
                "street_address": "\"6 Chequer Lane\"",
                "website": "\"https://www.mydentist.co.uk/dentists/practices/england/east-of-england/ely/6-chequer-lane\""
            },
            {
                "__id": 188,
                "address_city": "\"['Warrington', 'Fearnhead']\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"WA2 0QG\"",
                "amenity": "\"dentist\"",
                "contact_email": "\"\"",
                "healthcare": "\"dentist\"",
                "latitude": 53.410716,
                "longitude": -2.5570410000000265,
                "name": "\"{my}dentist, Station Road, Fearnhead\"",
                "opening_hours": "\"Mo-Th 09:00-17:30; Fr 09:00-17:00\"",
                "phone": "\"01925 828847\"",
                "street_address": "\"86 Station Road\"",
                "website": "\"https://www.mydentist.co.uk/dentists/practices/england/north-west-england/warrington/86-station-road\""
            },
            {
                "__id": 544,
                "address_city": "\"Ipswich\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"IP3 0NF\"",
                "amenity": "\"dentist\"",
                "contact_email": "\"\"",
                "healthcare": "\"dentist\"",
                "latitude": 52.047392,
                "longitude": 1.1774010000000317,
                "name": "\"The Orwell Dental Surgery\"",
                "opening_hours": "\"Mo-Th 08:00-18:00; Fr 08:00-17:30\"",
                "phone": "\"01473 727158\"",
                "street_address": "\"199 Nacton Road\"",
                "website": "\"https://www.mydentist.co.uk/dentists/practices/england/east-of-england/ipswich/199-nacton-road\""
            },
            {
                "__id": 179,
                "address_city": "\"['Gateshead', 'Low Fell']\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"NE9 5AH\"",
                "amenity": "\"dentist\"",
                "contact_email": "\"\"",
                "healthcare": "\"dentist\"",
                "latitude": 54.942635,
                "longitude": -1.599183000000039,
                "name": "\"{my}dentist, Durham Road, Low Fell\"",
                "opening_hours": "\"Mo 08:00-18:00; Tu 08:00-19:00; We-Th 08:00-18:00; Fr 08:00-17:00\"",
                "phone": "\"01914 910660\"",
                "street_address": "\"323 Durham Road\"",
                "website": "\"https://www.mydentist.co.uk/dentists/practices/england/north-east-england/gateshead/323-durham-road\""
            },
            {
                "__id": 173,
                "address_city": "\"Peterlee\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"SR8 1AL\"",
                "amenity": "\"dentist\"",
                "contact_email": "\"\"",
                "healthcare": "\"dentist\"",
                "latitude": 54.759211,
                "longitude": -1.3334049999999706,
                "name": "\"{my}dentist, Yoden Way, Peterlee\"",
                "opening_hours": "\"Mo-Fr 08:30-17:30\"",
                "phone": "\"01915 862918\"",
                "street_address": "\"30 Yoden Way\"",
                "website": "\"https://www.mydentist.co.uk/dentists/practices/england/north-east-england/peterlee/30-yoden-way\""
            },
            {
                "__id": 134,
                "address_city": "\"\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"\"",
                "amenity": "\"dentist\"",
                "contact_email": "\"\"",
                "healthcare": "\"dentist\"",
                "latitude": 53.590923,
                "longitude": -0.644816,
                "name": "\"mydentist\"",
                "opening_hours": "\"Mo 08:30-17:30; Tu 08:30-19:00; We-Fr 08:30-17:30; Sa 09:00-13:00\"",
                "phone": "\"01724 514241\"",
                "street_address": "\"\"",
                "website": "\"https://www.mydentist.co.uk/dentists/practices/england/lincolnshire/scunthorpe/3-jubilee-way\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "finishline": {
        "__id": 127,
        "id": "finishline",
        "name": "Finish Line",
        "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'name', 'opening_hours', 'phone', 'shop', 'website', 'latitude', 'longitude']",
        "rowLength": 806,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763533,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of finishline locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 648,
                "address_city": "\"Jensen Beach\"",
                "address_full": "\"3006 North West Federal Highway\"",
                "address_postcode": "\"34957\"",
                "address_state": "\"FL\"",
                "name": "27.242323",
                "opening_hours": "-80.274579",
                "phone": "\"Treasure Coast Square\"",
                "shop": "\"Mo-Sa 11:00-19:00; Su 12:00-18:00\"",
                "website": "\"(772) 232-9699\"",
                "latitude": 0.0,
                "longitude": 0.0
            },
            {
                "__id": 603,
                "address_city": "\"Bakersfield\"",
                "address_full": "\"2701 Ming Avenue\"",
                "address_postcode": "\"93304\"",
                "address_state": "\"CA\"",
                "name": "35.3377229",
                "opening_hours": "-119.0324518",
                "phone": "\"Valley Plaza\"",
                "shop": "\"Mo-Th 11:00-19:00; Fr-Sa 11:00-20:00; Su 11:00-19:00\"",
                "website": "\"(661) 397-0035\"",
                "latitude": 0.0,
                "longitude": 0.0
            },
            {
                "__id": 310,
                "address_city": "\"Charleston\"",
                "address_full": "\"2150 Northwoods Blvd C8\"",
                "address_postcode": "\"29406\"",
                "address_state": "\"SC\"",
                "name": "\"Northwoods Mall\"",
                "opening_hours": "\"\"",
                "phone": "\"(843) 818-1220\"",
                "shop": "\"sports\"",
                "website": "\"https://stores.finishline.com/sc/charleston.html\"",
                "latitude": 0.0,
                "longitude": 0.0
            },
            {
                "__id": 482,
                "address_city": "\"Stockton\"",
                "address_full": "\"5242 Pacific Ave\"",
                "address_postcode": "\"95207\"",
                "address_state": "\"CA\"",
                "name": "\"Stockton Sherwood\"",
                "opening_hours": "\"\"",
                "phone": "\"(209) 477-3333\"",
                "shop": "\"sports\"",
                "website": "\"https://stores.finishline.com/ca/stockton.html\"",
                "latitude": 0.0,
                "longitude": 0.0
            },
            {
                "__id": 614,
                "address_city": "\"Tallahassee\"",
                "address_full": "\"1500 Apalachee Pkwy 2025\"",
                "address_postcode": "\"32301\"",
                "address_state": "\"FL\"",
                "name": "30.4368976",
                "opening_hours": "-84.2542133",
                "phone": "\"Governor's Square\"",
                "shop": "\"Mo-Sa 11:00-19:00; Su 12:00-18:00\"",
                "website": "\"(850) 656-9601\"",
                "latitude": 0.0,
                "longitude": 0.0
            },
            {
                "__id": 418,
                "address_city": "\"Colonial Heights\"",
                "address_full": "\"410 South Park Circle\"",
                "address_postcode": "\"23834\"",
                "address_state": "\"VA\"",
                "name": "\"SouthPark Mall\"",
                "opening_hours": "\"\"",
                "phone": "\"(804) 526-3304\"",
                "shop": "\"sports\"",
                "website": "\"https://stores.finishline.com/va/colonial-heights.html\"",
                "latitude": 0.0,
                "longitude": 0.0
            },
            {
                "__id": 452,
                "address_city": "\"Wichita\"",
                "address_full": "\"7700 E Kellogg G4A\"",
                "address_postcode": "\"67207\"",
                "address_state": "\"KS\"",
                "name": "37.682734",
                "opening_hours": "-97.247284",
                "phone": "\"Towne East Square\"",
                "shop": "\"Mo-Th 11:00-19:00; Fr-Sa 10:00-20:00; Su 12:00-18:00\"",
                "website": "\"(316) 652-0648\"",
                "latitude": 0.0,
                "longitude": 0.0
            },
            {
                "__id": 623,
                "address_city": "\"Plantation\"",
                "address_full": "\"8000 W Broward Blvd.\"",
                "address_postcode": "\"33388\"",
                "address_state": "\"FL\"",
                "name": "\"Broward Mall\"",
                "opening_hours": "\"\"",
                "phone": "\"(954) 475-3400\"",
                "shop": "\"sports\"",
                "website": "\"https://stores.finishline.com/fl/plantation.html\"",
                "latitude": 0.0,
                "longitude": 0.0
            },
            {
                "__id": 477,
                "address_city": "\"Torrance\"",
                "address_full": "\"3525 W Carson St\"",
                "address_postcode": "\"90503\"",
                "address_state": "\"CA\"",
                "name": "\"Del Amo Fashion Center\"",
                "opening_hours": "\"\"",
                "phone": "\"(424) 327-9233\"",
                "shop": "\"sports\"",
                "website": "\"https://stores.finishline.com/ca/torrance.html\"",
                "latitude": 0.0,
                "longitude": 0.0
            },
            {
                "__id": 211,
                "address_city": "\"Sandusky\"",
                "address_full": "\"4314 Milan Rd 170\"",
                "address_postcode": "\"44870\"",
                "address_state": "\"OH\"",
                "name": "41.418528",
                "opening_hours": "-82.669938",
                "phone": "\"Sandusky Mall\"",
                "shop": "\"Mo-Sa 11:00-19:00; Su 12:00-18:00\"",
                "website": "\"(419) 627-8852\"",
                "latitude": 0.0,
                "longitude": 0.0
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "anthonys": {
        "__id": 128,
        "id": "anthonys",
        "name": "Anthony's",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_housenumber', 'address_postcode', 'address_state', 'address_street', 'latitude', 'longitude', 'name', 'phone', 'street_address', 'website']",
        "rowLength": 60,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763534,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of anthonys locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 59,
                "address_city": "\"Pike Creek\"",
                "address_country": "\"US\"",
                "address_full": "\"4805 Limestone Road, Pike Creek, DE 19808\"",
                "address_housenumber": "\"4805\"",
                "address_postcode": "\"19808\"",
                "address_state": "\"DE\"",
                "address_street": "\"Limestone Road\"",
                "latitude": 39.7428995,
                "longitude": -75.6945801,
                "name": "\"Pike Creek\"",
                "phone": "\"(302) 635-7013\"",
                "street_address": "\"4805 Limestone Road\"",
                "website": "\"https://acfp.com/locations/pike-creek\""
            },
            {
                "__id": 31,
                "address_city": "\"Weston\"",
                "address_country": "\"US\"",
                "address_full": "\"4527 Weston Road, Weston, FL 33331\"",
                "address_housenumber": "\"4527\"",
                "address_postcode": "\"33331\"",
                "address_state": "\"FL\"",
                "address_street": "\"Weston Road\"",
                "latitude": 26.0635268,
                "longitude": -80.3660567,
                "name": "\"Weston\"",
                "phone": "\"(954) 358-2625\"",
                "street_address": "\"4527 Weston Road\"",
                "website": "\"https://acfp.com/locations/weston\""
            },
            {
                "__id": 51,
                "address_city": "\"Coral Gables\"",
                "address_country": "\"US\"",
                "address_full": "\"2626 Ponce De Leon Blvd, Coral Gables, FL 33134\"",
                "address_housenumber": "\"2626\"",
                "address_postcode": "\"33134\"",
                "address_state": "\"FL\"",
                "address_street": "\"Ponce De Leon Blvd\"",
                "latitude": 25.7472697,
                "longitude": -80.261028,
                "name": "\"Coral Gables\"",
                "phone": "\"(786) 456-9200\"",
                "street_address": "\"2626 Ponce De Leon Blvd\"",
                "website": "\"https://acfp.com/locations/coral-gables\""
            },
            {
                "__id": 37,
                "address_city": "\"Plantation\"",
                "address_country": "\"US\"",
                "address_full": "\"512 North Pine Island Road, Plantation, FL 33324\"",
                "address_housenumber": "\"512\"",
                "address_postcode": "\"33324\"",
                "address_state": "\"FL\"",
                "address_street": "\"North Pine Island Road\"",
                "latitude": 26.1269188,
                "longitude": -80.2624379,
                "name": "\"Plantation\"",
                "phone": "\"(954) 474-3311\"",
                "street_address": "\"512 North Pine Island Road\"",
                "website": "\"https://acfp.com/locations/plantation\""
            },
            {
                "__id": 40,
                "address_city": "\"Palm Beach Gardens\"",
                "address_country": "\"US\"",
                "address_full": "\"2680 PGA Boulevard, Palm Beach Gardens, FL 33410\"",
                "address_housenumber": "\"2680\"",
                "address_postcode": "\"33410\"",
                "address_state": "\"FL\"",
                "address_street": "\"PGA Boulevard\"",
                "latitude": 26.8443936,
                "longitude": -80.0757095,
                "name": "\"Palm Beach Gardens\"",
                "phone": "\"(561) 804-7777\"",
                "street_address": "\"2680 PGA Boulevard\"",
                "website": "\"https://acfp.com/locations/palm-beach-gardens\""
            },
            {
                "__id": 52,
                "address_city": "\"Clearwater\"",
                "address_country": "\"US\"",
                "address_full": "\"2532 N McMullen Booth Road, Clearwater, FL 33761\"",
                "address_housenumber": "\"2532\"",
                "address_postcode": "\"33761\"",
                "address_state": "\"FL\"",
                "address_street": "\"N McMullen Booth Road\"",
                "latitude": 28.01376,
                "longitude": -82.70907,
                "name": "\"Clearwater\"",
                "phone": "\"(727) 797-0929\"",
                "street_address": "\"2532 N McMullen Booth Road\"",
                "website": "\"https://acfp.com/locations/clearwater\""
            },
            {
                "__id": 15,
                "address_city": "\"Commack\"",
                "address_country": "\"US\"",
                "address_full": "\"6401 Jericho Turnpike, Commack, NY 11725\"",
                "address_housenumber": "\"6401\"",
                "address_postcode": "\"11725\"",
                "address_state": "\"NY\"",
                "address_street": "\"Jericho Turnpike\"",
                "latitude": 40.8438214,
                "longitude": -73.2968761,
                "name": "\"Commack\"",
                "phone": "\"(631) 858-2625\"",
                "street_address": "\"6401 Jericho Turnpike\"",
                "website": "\"https://acfp.com/locations/commack\""
            },
            {
                "__id": 32,
                "address_city": "\"West Palm Beach\"",
                "address_country": "\"US\"",
                "address_full": "\"1900 Okeechobee Boulevard, West Palm Beach, FL 33409\"",
                "address_housenumber": "\"1900\"",
                "address_postcode": "\"33409\"",
                "address_state": "\"FL\"",
                "address_street": "\"Okeechobee Boulevard\"",
                "latitude": 26.7047878,
                "longitude": -80.0827013,
                "name": "\"West Palm Beach\"",
                "phone": "\"(561) 473-2567\"",
                "street_address": "\"1900 Okeechobee Boulevard\"",
                "website": "\"https://acfp.com/locations/west-palm-beach\""
            },
            {
                "__id": 19,
                "address_city": "\"Ramsey\"",
                "address_country": "\"US\"",
                "address_full": "\"984 State Route 17, Ramsey, NJ 07446\"",
                "address_housenumber": "\"984\"",
                "address_postcode": "\"07446\"",
                "address_state": "\"NJ\"",
                "address_street": "\"State Route 17\"",
                "latitude": 41.0661277,
                "longitude": -74.1332958,
                "name": "\"Ramsey\"",
                "phone": "\"(201) 818-2625\"",
                "street_address": "\"984 State Route 17\"",
                "website": "\"https://acfp.com/locations/ramsey\""
            },
            {
                "__id": 28,
                "address_city": "\"Natick\"",
                "address_country": "\"US\"",
                "address_full": "\"219 North Main Street Suite A-104, Natick, MA 01760\"",
                "address_housenumber": "\"219\"",
                "address_postcode": "\"01760\"",
                "address_state": "\"MA\"",
                "address_street": "\"North Main Street Suite A-104\"",
                "latitude": 42.3021545,
                "longitude": -71.3618021,
                "name": "\"Natick\"",
                "phone": "\"(774) 290-5106\"",
                "street_address": "\"219 North Main Street Suite A-104\"",
                "website": "\"https://acfp.com/locations/natick\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "ingles": {
        "__id": 129,
        "id": "ingles",
        "name": "Ingles",
        "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'website']",
        "rowLength": 189,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763535,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of ingles locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 83,
                "address_city": "\"Shelby\"",
                "address_full": "\"706 E Dixon Blvd\"",
                "address_postcode": "\"28152\"",
                "address_state": "\"NC\"",
                "latitude": 35.27337760309626,
                "longitude": -81.52861387897492,
                "name": "\"Shelby\"",
                "opening_hours": "\"Mo-Su 07:00-10:00\"",
                "phone": "\"704-471-9494\"",
                "website": "\"https://www.ingles-markets.com/storelocate/storeinfo.php?storenum=112\""
            },
            {
                "__id": 156,
                "address_city": "\"Lilburn\"",
                "address_full": "\"650 Rockbridge Rd.\"",
                "address_postcode": "\"30047\"",
                "address_state": "\"GA\"",
                "latitude": 33.856377,
                "longitude": -84.140336,
                "name": "\"Lilburn\"",
                "opening_hours": "\"Mo-Su 07:00-10:00\"",
                "phone": "\"770-381-7318\"",
                "website": "\"https://www.ingles-markets.com/storelocate/storeinfo.php?storenum=402\""
            },
            {
                "__id": 140,
                "address_city": "\"Villa Rica\"",
                "address_full": "\"98 Hwy. 61 Connector\"",
                "address_postcode": "\"30180\"",
                "address_state": "\"GA\"",
                "latitude": 33.7264,
                "longitude": -84.93956,
                "name": "\"Villa Rica\"",
                "opening_hours": "\"Mo-Su 07:00-10:00\"",
                "phone": "\"770-459-0223\"",
                "website": "\"https://www.ingles-markets.com/storelocate/storeinfo.php?storenum=423\""
            },
            {
                "__id": 56,
                "address_city": "\"Greenville\"",
                "address_full": "\"6605 White Horse Rd\"",
                "address_postcode": "\"29602\"",
                "address_state": "\"SC\"",
                "latitude": 34.867923,
                "longitude": -82.465328,
                "name": "\"Greenville\"",
                "opening_hours": "\"Mo-Su 07:00-10:00\"",
                "phone": "\"864-246-5507\"",
                "website": "\"https://www.ingles-markets.com/storelocate/storeinfo.php?storenum=74\""
            },
            {
                "__id": 91,
                "address_city": "\"Mars Hill\"",
                "address_full": "\"225 Carl Eller Rd\"",
                "address_postcode": "\"28754\"",
                "address_state": "\"NC\"",
                "latitude": 35.823592,
                "longitude": -82.544651,
                "name": "\"Mars Hill\"",
                "opening_hours": "\"Mo-Su 06:00-11:00\"",
                "phone": "\"828-689-5980\"",
                "website": "\"https://www.ingles-markets.com/storelocate/storeinfo.php?storenum=80\""
            },
            {
                "__id": 23,
                "address_city": "\"Lenoir City\"",
                "address_full": "\"404 Highway 321 N\"",
                "address_postcode": "\"37771\"",
                "address_state": "\"TN\"",
                "latitude": 35.80236,
                "longitude": -84.26106,
                "name": "\"Lenoir City\"",
                "opening_hours": "\"Mo-Su 06:00-11:00\"",
                "phone": "\"865-988-5902\"",
                "website": "\"https://www.ingles-markets.com/storelocate/storeinfo.php?storenum=71\""
            },
            {
                "__id": 33,
                "address_city": "\"Farragut\"",
                "address_full": "\"11847 Kingston Pike\"",
                "address_postcode": "\"11847\"",
                "address_state": "\"TN\"",
                "latitude": 35.87456,
                "longitude": -84.175572,
                "name": "\"Farragut\"",
                "opening_hours": "\"Mo-Su 06:00-11:00\"",
                "phone": "\"865-966-4360\"",
                "website": "\"https://www.ingles-markets.com/storelocate/storeinfo.php?storenum=91\""
            },
            {
                "__id": 129,
                "address_city": "\"Asheville\"",
                "address_full": "\"499 Weaverville Rd.\"",
                "address_postcode": "\"28804\"",
                "address_state": "\"NC\"",
                "latitude": 35.673002,
                "longitude": -82.580333,
                "name": "\"Asheville\"",
                "opening_hours": "\"Mo-Su 07:00-11:00\"",
                "phone": "\"828-645-4521\"",
                "website": "\"https://www.ingles-markets.com/storelocate/storeinfo.php?storenum=22\""
            },
            {
                "__id": 74,
                "address_city": "\"Walnut Cove\"",
                "address_full": "\"802 S. Main Street\"",
                "address_postcode": "\"27052\"",
                "address_state": "\"NC\"",
                "latitude": 36.296503,
                "longitude": -80.140697,
                "name": "\"Walnut Cove\"",
                "opening_hours": "\"Mo-Su 07:00-10:00\"",
                "phone": "\"336-591-5818\"",
                "website": "\"https://www.ingles-markets.com/storelocate/storeinfo.php?storenum=109\""
            },
            {
                "__id": 159,
                "address_city": "\"Hull\"",
                "address_full": "\"9161 Hwy. 29\"",
                "address_postcode": "\"30646\"",
                "address_state": "\"GA\"",
                "latitude": 34.032528,
                "longitude": -83.315165,
                "name": "\"Hull\"",
                "opening_hours": "\"Mo-Su 06:00-12:00\"",
                "phone": "\"706-613-1334\"",
                "website": "\"https://www.ingles-markets.com/storelocate/storeinfo.php?storenum=450\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "tropical_smoothie_cafe": {
        "__id": 130,
        "id": "tropical_smoothie_cafe",
        "name": "Tropical Smoothie Cafe",
        "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'branch', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'takeaway', 'website']",
        "rowLength": 1218,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763544,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of tropical_smoothie_cafe locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 347,
                "address_city": "\"Camp Hill\"",
                "address_full": "\"3601 Market Street\"",
                "address_postcode": "\"17011\"",
                "address_state": "\"PA\"",
                "amenity": "\"fast_food\"",
                "branch": "\"Camp Hill\"",
                "cuisine": "\"juice\"",
                "latitude": 40.238877726954705,
                "longitude": -76.94386240911138,
                "name": "\"Tropical Smoothie Cafe\"",
                "opening_hours": "\"Mo-Fr 07:00-21:00; Sa 08:00-21:00; Su 10:00-20:00\"",
                "phone": "\"(717) 412-0157\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.tropicalsmoothiecafe.com/pa/camp-hill/3601-market-street\""
            },
            {
                "__id": 352,
                "address_city": "\"Tulsa\"",
                "address_full": "\"3722 South Peoria Avenue Suite 200\"",
                "address_postcode": "\"74105\"",
                "address_state": "\"OK\"",
                "amenity": "\"fast_food\"",
                "branch": "\"Tulsa\"",
                "cuisine": "\"juice\"",
                "latitude": 36.10914683437974,
                "longitude": -95.97598727671061,
                "name": "\"Tropical Smoothie Cafe\"",
                "opening_hours": "\"Mo-Fr 07:00-21:00; Sa 08:00-21:00; Su 09:00-21:00\"",
                "phone": "\"(918) 743-6333\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.tropicalsmoothiecafe.com/ok/tulsa/3722-south-peoria-avenue\""
            },
            {
                "__id": 160,
                "address_city": "\"Weatherford\"",
                "address_full": "\"1302 S Main St\"",
                "address_postcode": "\"76086\"",
                "address_state": "\"TX\"",
                "amenity": "\"fast_food\"",
                "branch": "\"Weatherford\"",
                "cuisine": "\"juice\"",
                "latitude": 32.74360810192712,
                "longitude": -97.7982210669292,
                "name": "\"Tropical Smoothie Cafe\"",
                "opening_hours": "\"Mo-Fr 07:00-21:00; Sa 08:00-21:00; Su 10:00-20:00\"",
                "phone": "\"(817) 550-6248\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.tropicalsmoothiecafe.com/tx/weatherford/1302-s-main-st\""
            },
            {
                "__id": 137,
                "address_city": "\"Centreville\"",
                "address_full": "\"14220 Centreville Square Unit B\"",
                "address_postcode": "\"20121\"",
                "address_state": "\"VA\"",
                "amenity": "\"fast_food\"",
                "branch": "\"Centreville\"",
                "cuisine": "\"juice\"",
                "latitude": 38.8380838661555,
                "longitude": -77.43817417790984,
                "name": "\"Tropical Smoothie Cafe\"",
                "opening_hours": "\"Mo-Fr 07:00-21:00; Sa 08:00-21:00; Su 10:00-20:00\"",
                "phone": "\"(703) 815-1455\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.tropicalsmoothiecafe.com/va/centreville/14220-centreville-square\""
            },
            {
                "__id": 2,
                "address_city": "\"Montgomery\"",
                "address_full": "\"355 S Kelly St\"",
                "address_postcode": "\"36112\"",
                "address_state": "\"AL\"",
                "amenity": "\"fast_food\"",
                "branch": "\"Montgomery\"",
                "cuisine": "\"juice\"",
                "latitude": 32.37444670287709,
                "longitude": -86.35383231964477,
                "name": "\"Tropical Smoothie Cafe\"",
                "opening_hours": "\"\"",
                "phone": "\"(770) 821-1900\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.tropicalsmoothiecafe.com/al/montgomery/355-s-kelly-st\""
            },
            {
                "__id": 458,
                "address_city": "\"East Northport\"",
                "address_full": "\"532 Larkfield Road\"",
                "address_postcode": "\"11731\"",
                "address_state": "\"NY\"",
                "amenity": "\"fast_food\"",
                "branch": "\"East Northport\"",
                "cuisine": "\"juice\"",
                "latitude": 40.861348042811905,
                "longitude": -73.32080986258296,
                "name": "\"Tropical Smoothie Cafe\"",
                "opening_hours": "\"Mo-Fr 07:00-21:00; Sa 08:00-21:00; Su 09:00-20:00\"",
                "phone": "\"(631) 486-4455\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.tropicalsmoothiecafe.com/ny/east-northport/532-larkfield-road\""
            },
            {
                "__id": 1026,
                "address_city": "\"Naples\"",
                "address_full": "\"8855 Immokalee Road #1\"",
                "address_postcode": "\"34120\"",
                "address_state": "\"FL\"",
                "amenity": "\"fast_food\"",
                "branch": "\"Naples\"",
                "cuisine": "\"juice\"",
                "latitude": 26.27452185847032,
                "longitude": -81.68734565040131,
                "name": "\"Tropical Smoothie Cafe\"",
                "opening_hours": "\"Mo-Fr 06:00-21:00; Sa 08:00-21:00; Su 09:00-20:00\"",
                "phone": "\"(239) 331-7445\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.tropicalsmoothiecafe.com/fl/naples/8855-immokalee-road\""
            },
            {
                "__id": 475,
                "address_city": "\"Las Vegas\"",
                "address_full": "\"7375 South Durango Drive #107\"",
                "address_postcode": "\"89113\"",
                "address_state": "\"NV\"",
                "amenity": "\"fast_food\"",
                "branch": "\"Las Vegas\"",
                "cuisine": "\"juice\"",
                "latitude": 36.0546103,
                "longitude": -115.2798589,
                "name": "\"Tropical Smoothie Cafe\"",
                "opening_hours": "\"24/7\"",
                "phone": "\"(702) 262-5515\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.tropicalsmoothiecafe.com/nv/las-vegas/7375-south-durango-drive\""
            },
            {
                "__id": 800,
                "address_city": "\"Owensboro\"",
                "address_full": "\"2804 Frederica St\"",
                "address_postcode": "\"42301\"",
                "address_state": "\"KY\"",
                "amenity": "\"fast_food\"",
                "branch": "\"Owensboro\"",
                "cuisine": "\"juice\"",
                "latitude": 37.747941,
                "longitude": -87.113909,
                "name": "\"Tropical Smoothie Cafe\"",
                "opening_hours": "\"Mo-Fr 07:00-21:00; Sa 08:00-21:00; Su 09:00-20:00\"",
                "phone": "\"(270) 713-0295\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.tropicalsmoothiecafe.com/ky/owensboro/2804-frederica-st\""
            },
            {
                "__id": 250,
                "address_city": "\"Brownsville\"",
                "address_full": "\"3230 Pablo Kisel Boulevard\"",
                "address_postcode": "\"78526\"",
                "address_state": "\"TX\"",
                "amenity": "\"fast_food\"",
                "branch": "\"Brownsville\"",
                "cuisine": "\"juice\"",
                "latitude": 25.96379913145115,
                "longitude": -97.5039005176518,
                "name": "\"Tropical Smoothie Cafe\"",
                "opening_hours": "\"Mo-Th 07:00-22:00; Fr 07:00-23:00; Sa 08:00-23:00; Su 09:00-22:00\"",
                "phone": "\"(956) 621-1519\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.tropicalsmoothiecafe.com/tx/brownsville/3230-pablo-kisel-boulevard\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "dillards": {
        "__id": 131,
        "id": "dillards",
        "name": "Dillard's",
        "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'website']",
        "rowLength": 277,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763544,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of dillards locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 9,
                "address_city": "\"Pueblo\"",
                "address_postcode": "\"81008\"",
                "address_state": "\"CO\"",
                "latitude": 38.30425,
                "longitude": -104.61528,
                "name": "\"Dillard's Pueblo Mall in Pueblo, Colorado\"",
                "opening_hours": "\"Mo-Sa 11:00-20:00; Su 12:00-18:00\"",
                "phone": "\"(719)545-9665\"",
                "shop": "\"department_store\"",
                "street_address": "\"3601 Dillon Dr\"",
                "website": "\"https://www.dillards.com/stores/colorado/pueblo-mall-pueblo/0992\""
            },
            {
                "__id": 226,
                "address_city": "\"Daytona Beach\"",
                "address_postcode": "\"32114\"",
                "address_state": "\"FL\"",
                "latitude": 29.1986,
                "longitude": -81.06278,
                "name": "\"Dillard's Volusia Mall in Daytona Beach, Florida\"",
                "opening_hours": "\"Mo-Sa 11:00-20:00; Su 12:00-18:00\"",
                "phone": "\"(386)255-8161\"",
                "shop": "\"department_store\"",
                "street_address": "\"1700 W International Speedway Blvd\"",
                "website": "\"https://www.dillards.com/stores/florida/volusia-mall-daytona-beach/0235\""
            },
            {
                "__id": 179,
                "address_city": "\"Louisville\"",
                "address_postcode": "\"40219\"",
                "address_state": "\"KY\"",
                "latitude": 38.14036,
                "longitude": -85.67171,
                "name": "\"Dillard's Jefferson Mall in Louisville, Kentucky\"",
                "opening_hours": "\"Mo-Sa 11:00-20:00; Su 12:00-18:00\"",
                "phone": "\"(502)968-6080\"",
                "shop": "\"department_store\"",
                "street_address": "\"4801 Outer Loop\"",
                "website": "\"https://www.dillards.com/stores/kentucky/jefferson-mall-louisville/0396\""
            },
            {
                "__id": 248,
                "address_city": "\"Fort Smith\"",
                "address_postcode": "\"72903\"",
                "address_state": "\"AR\"",
                "latitude": 35.36821,
                "longitude": -94.37411,
                "name": "\"Dillard's Central Mall in Fort Smith, Arkansas\"",
                "opening_hours": "\"Mo-Sa 11:00-20:00; Su 12:00-18:00\"",
                "phone": "\"(479)484-5700\"",
                "shop": "\"department_store\"",
                "street_address": "\"5111 Rogers Ave\"",
                "website": "\"https://www.dillards.com/stores/arkansas/central-mall-fort-smith/0413\""
            },
            {
                "__id": 120,
                "address_city": "\"Winston-Salem\"",
                "address_postcode": "\"27103\"",
                "address_state": "\"NC\"",
                "latitude": 36.06843,
                "longitude": -80.30216,
                "name": "\"Dillard's Hanes Mall in Winston-Salem, North Carolina\"",
                "opening_hours": "\"Mo-Sa 11:00-20:00; Su 12:00-18:00\"",
                "phone": "\"(336)659-1515\"",
                "shop": "\"department_store\"",
                "street_address": "\"3320 Silas Creek Pkwy #3500\"",
                "website": "\"https://www.dillards.com/stores/north-carolina/hanes-mall-winston-salem/0145\""
            },
            {
                "__id": 263,
                "address_city": "\"Mesa\"",
                "address_postcode": "\"85206\"",
                "address_state": "\"AZ\"",
                "latitude": 33.38995,
                "longitude": -111.6876,
                "name": "\"Dillard's Superstition Springs Center in Mesa, Arizona\"",
                "opening_hours": "\"Mo-Sa 11:00-20:00; Su 12:00-18:00\"",
                "phone": "\"(480)832-1247\"",
                "shop": "\"department_store\"",
                "street_address": "\"6545 E Southern Ave\"",
                "website": "\"https://www.dillards.com/stores/arizona/superstition-springs-center-mesa/0909\""
            },
            {
                "__id": 22,
                "address_city": "\"Newport News\"",
                "address_postcode": "\"23602\"",
                "address_state": "\"VA\"",
                "latitude": 37.11228,
                "longitude": -76.49725,
                "name": "\"Dillard's Patrick Henry Mall in Newport News, Virginia\"",
                "opening_hours": "\"Mo-Sa 11:00-20:00; Su 12:00-18:00\"",
                "phone": "\"(757)930-0291\"",
                "shop": "\"department_store\"",
                "street_address": "\"12300 Jefferson Ave Suite 300\"",
                "website": "\"https://www.dillards.com/stores/virginia/patrick-henry-mall-newport-news/0174\""
            },
            {
                "__id": 211,
                "address_city": "\"Tampa\"",
                "address_postcode": "\"33625\"",
                "address_state": "\"FL\"",
                "latitude": 28.06755,
                "longitude": -82.57936,
                "name": "\"Dillard's Citrus Park Town Center in Tampa, Florida\"",
                "opening_hours": "\"Mo-Sa 11:00-20:00; Su 12:00-18:00\"",
                "phone": "\"(813)926-4545\"",
                "shop": "\"department_store\"",
                "street_address": "\"8161 Citrus Park Town Center Mall\"",
                "website": "\"https://www.dillards.com/stores/florida/citrus-park-town-center-tampa/0203\""
            },
            {
                "__id": 59,
                "address_city": "\"Houston\"",
                "address_postcode": "\"77070\"",
                "address_state": "\"TX\"",
                "latitude": 29.96183,
                "longitude": -95.53821,
                "name": "\"Dillard's Willowbrook Mall in Houston, Texas\"",
                "opening_hours": "\"Mo-Sa 11:00-20:00; Su 12:00-18:00\"",
                "phone": "\"(281)890-9200\"",
                "shop": "\"department_store\"",
                "street_address": "\"7925 Farm to Market 1960 Rd W\"",
                "website": "\"https://www.dillards.com/stores/texas/willowbrook-mall-houston/0771\""
            },
            {
                "__id": 66,
                "address_city": "\"Garland\"",
                "address_postcode": "\"75040\"",
                "address_state": "\"TX\"",
                "latitude": 32.95553,
                "longitude": -96.61525,
                "name": "\"Dillard's Firewheel Town Center in Garland, Texas\"",
                "opening_hours": "\"Mo-Sa 11:00-20:00; Su 12:00-18:00\"",
                "phone": "\"(972)496-4070\"",
                "shop": "\"department_store\"",
                "street_address": "\"301 Horseshoe Dr\"",
                "website": "\"https://www.dillards.com/stores/texas/firewheel-town-center-garland/0708\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "barlouie": {
        "__id": 132,
        "id": "barlouie",
        "name": "Bar Louie",
        "columnNames": "['address_city', 'address_postcode', 'address_state', 'image', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'street_address', 'website']",
        "rowLength": 67,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763545,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of barlouie locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 17,
                "address_city": "\"Dearborn\"",
                "address_postcode": "\"48124\"",
                "address_state": "\"MI\"",
                "image": "\"https://images.prismic.io/bar-louie/57ddeac5852591d8cdadef9b5f78e61ef1ef53c1_2016-06-17-14.59.41.jpg?auto=compress,format\"",
                "latitude": 42.304854,
                "longitude": -83.249148,
                "name": "\"Dearborn\"",
                "opening_hours": "\"\"",
                "phone": "\"313-394-1000\"",
                "street_address": "\"22269 Michigan Ave\"",
                "website": "\"https://www.barlouie.com/locations/us/mi/dearborn/dearborn\""
            },
            {
                "__id": 49,
                "address_city": "\"Winter Park\"",
                "address_postcode": "\"32789\"",
                "address_state": "\"FL\"",
                "image": "\"https://images.prismic.io/bar-louie/7facc22c-b5bb-4cb1-ad5f-0a15e5bd33b2_New&#x2B;Search&#x2B;Location&#x2B;Header&#x2B;Image&#x2B;for&#x2B;Website.jpg?auto=compress,format&amp;rect=0,0,1520,360&amp;w=1520&amp;h=360\"",
                "latitude": 28.601748,
                "longitude": -81.362663,
                "name": "\"Winter Park\"",
                "opening_hours": "\"Mo-Fr 11:00-02:00; Sa 10:00-02:00; Su 10:00-24:00\"",
                "phone": "\"407-794-9500\"",
                "street_address": "\"460 Orlando Ave., #122\"",
                "website": "\"https://www.barlouie.com/locations/us/fl/winter-park/winter-park\""
            },
            {
                "__id": 23,
                "address_city": "\"Sugar Land\"",
                "address_postcode": "\"77479\"",
                "address_state": "\"TX\"",
                "image": "\"https://images.prismic.io/bar-louie/4d82b216c07bd1642df9ede9bce8a69833534a2d_2016-06-17-14.59.41.jpg?auto=compress,format\"",
                "latitude": 29.596117,
                "longitude": -95.621767,
                "name": "\"Sugar Land\"",
                "opening_hours": "\"Mo-Su 11:00-02:00\"",
                "phone": "\"281-313-9002\"",
                "street_address": "\"16089 City Walk\"",
                "website": "\"https://www.barlouie.com/locations/us/tx/sugar-land/sugar-land\""
            },
            {
                "__id": 32,
                "address_city": "\"Round Rock\"",
                "address_postcode": "\"78655\"",
                "address_state": "\"TX\"",
                "image": "\"https://images.prismic.io/bar-louie/7facc22c-b5bb-4cb1-ad5f-0a15e5bd33b2_New&#x2B;Search&#x2B;Location&#x2B;Header&#x2B;Image&#x2B;for&#x2B;Website.jpg?auto=compress,format&amp;rect=0,0,1520,360&amp;w=1520&amp;h=360\"",
                "latitude": 30.5699126883357,
                "longitude": -97.6883124962974,
                "name": "\"Round Rock\"",
                "opening_hours": "\"\"",
                "phone": "\"(512) 957-8660\"",
                "street_address": "\"270 Bass Pro Dr\"",
                "website": "\"https://www.barlouie.com/locations/us/tx/round-rock/round-rock\""
            },
            {
                "__id": 4,
                "address_city": "\"Center Valley\"",
                "address_postcode": "\"18034\"",
                "address_state": "\"PA\"",
                "image": "\"https://images.prismic.io/bar-louie/cee0ca12aba2ebe58b4fbd23cd418baa65e2d91b_center-valley.jpg?auto=compress,format\"",
                "latitude": 40.559035,
                "longitude": -75.410872,
                "name": "\"Center Valley\"",
                "opening_hours": "\"Mo-Fr 11:00-02:00; Sa-Su 10:00-02:00\"",
                "phone": "\"610-798-6700\"",
                "street_address": "\"2960 Center Valley Parkway Suite 700\"",
                "website": "\"https://www.barlouie.com/locations/us/pa/center-valley/center-valley\""
            },
            {
                "__id": 24,
                "address_city": "\"Spring\"",
                "address_postcode": "\"77380\"",
                "address_state": "\"TX\"",
                "image": "\"https://images.prismic.io/bar-louie/085417d2b6035a331b38e9337c8bede9a2f12571_woodlands.jpg?auto=compress,format\"",
                "latitude": 30.160547,
                "longitude": -95.45831,
                "name": "\"The Woodlands\"",
                "opening_hours": "\"Mo-Th 11:00-24:00; Fr-Sa 11:00-02:00; Su 11:00-24:00\"",
                "phone": "\"281-909-3300\"",
                "street_address": "\"24 Waterway Ave\"",
                "website": "\"https://www.barlouie.com/locations/us/tx/spring/the-woodlands\""
            },
            {
                "__id": 20,
                "address_city": "\"Hampton\"",
                "address_postcode": "\"23666\"",
                "address_state": "\"VA\"",
                "image": "\"https://images.prismic.io/bar-louie/d8f61385fc81db751f768ab73221a786d448e911_2016-06-17-14.59.41.jpg?auto=compress,format\"",
                "latitude": 37.043454,
                "longitude": -76.393397,
                "name": "\"Hampton\"",
                "opening_hours": "\"Mo-Su 11:00-02:00\"",
                "phone": "\"757-814-2500\"",
                "street_address": "\"3530 Von Schilling Dr\"",
                "website": "\"https://www.barlouie.com/locations/us/va/hampton/hampton\""
            },
            {
                "__id": 22,
                "address_city": "\"Ashburn\"",
                "address_postcode": "\"20147\"",
                "address_state": "\"VA\"",
                "image": "\"https://images.prismic.io/bar-louie/e2a1a343b620f6927ac46d9852d9ddcec2e52eeb_ashburn..jpg?auto=compress,format\"",
                "latitude": 39.051669,
                "longitude": -77.454149,
                "name": "\"One Loudoun\"",
                "opening_hours": "\"Mo-Fr 11:00-02:00; Sa-Su 10:00-02:00\"",
                "phone": "\"571-549-3300\"",
                "street_address": "\"20586 East Hampton Plaza\"",
                "website": "\"https://www.barlouie.com/locations/us/va/ashburn/one-loudoun\""
            },
            {
                "__id": 28,
                "address_city": "\"Dallas\"",
                "address_postcode": "\"75231\"",
                "address_state": "\"TX\"",
                "image": "\"https://images.prismic.io/bar-louie/3fd4318d9b90290e2446cf6bcad805abd1122be5_blpl0091.jpg?auto=compress,format\"",
                "latitude": 32.869639,
                "longitude": -96.768006,
                "name": "\"The Shops at Park Lane\"",
                "opening_hours": "\"Mo-Fr 11:00-02:00; Sa-Su 10:00-02:00\"",
                "phone": "\"469-638-8050\"",
                "street_address": "\"8166 Park Ln Suite C310\"",
                "website": "\"https://www.barlouie.com/locations/us/tx/dallas/the-shops-at-park-lane\""
            },
            {
                "__id": 13,
                "address_city": "\"Royal Oak\"",
                "address_postcode": "\"48067\"",
                "address_state": "\"MI\"",
                "image": "\"https://images.prismic.io/bar-louie/d02dd84d8b954f5b3a57206283c0a4dcf15b2abe_bar-1.jpg?auto=compress,format\"",
                "latitude": 42.486054,
                "longitude": -83.144332,
                "name": "\"Royal Oak\"",
                "opening_hours": "\"Mo-Th 11:00-23:00; Fr-Sa 11:00-01:00; Su 10:00-23:00\"",
                "phone": "\"248-951-2195\"",
                "street_address": "\"510 South Main St\"",
                "website": "\"https://www.barlouie.com/locations/us/mi/royal-oak/royal-oak\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "amf_bowling": {
        "__id": 133,
        "id": "amf_bowling",
        "name": "AMF Bowling",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'phone', 'website']",
        "rowLength": 328,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763546,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of amf_bowling locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 274,
                "address_city": "\"Arcadia\"",
                "address_country": "\"US\"",
                "address_full": "\"400 South Baldwin Ave\"",
                "address_postcode": "\"91007\"",
                "address_state": "\"CA\"",
                "latitude": 34.13502,
                "longitude": -118.05132,
                "name": "\"Bowlero Arcadia\"",
                "phone": "\"626-264-9750\"",
                "website": "\"https://www.bowlero.com/location/bowlero-arcadia\""
            },
            {
                "__id": 149,
                "address_city": "\"Atlanta\"",
                "address_country": "\"US\"",
                "address_full": "\"2175 Savoy Dr.\"",
                "address_postcode": "\"30341\"",
                "address_state": "\"GA\"",
                "latitude": 33.919,
                "longitude": -84.2992,
                "name": "\"Bowlero Chamblee\"",
                "phone": "\"770-451-8605\"",
                "website": "\"https://www.bowlero.com/location/bowlero-chamblee\""
            },
            {
                "__id": 203,
                "address_city": "\"Scottsdale\"",
                "address_country": "\"US\"",
                "address_full": "\"9027 E. Via Linda\"",
                "address_postcode": "\"85258\"",
                "address_state": "\"AZ\"",
                "latitude": 33.5685,
                "longitude": -111.886,
                "name": "\"Bowlero Via Linda\"",
                "phone": "\"480-860-1363\"",
                "website": "\"https://www.bowlero.com/location/bowlero-via-linda\""
            },
            {
                "__id": 67,
                "address_city": "\"Altamonte Springs\"",
                "address_country": "\"US\"",
                "address_full": "\"280 Douglas Ave.\"",
                "address_postcode": "\"32714\"",
                "address_state": "\"FL\"",
                "latitude": 28.6653,
                "longitude": -81.3918,
                "name": "\"Bowlero Altamonte\"",
                "phone": "\"407-862-2500\"",
                "website": "\"https://www.bowlero.com/location/bowlero-altamonte\""
            },
            {
                "__id": 104,
                "address_city": "\"Tempe\"",
                "address_country": "\"US\"",
                "address_full": "\"4407 S. Rural Rd.\"",
                "address_postcode": "\"85282\"",
                "address_state": "\"AZ\"",
                "latitude": 33.3847,
                "longitude": -111.925,
                "name": "\"AMF Tempe Village Lanes\"",
                "phone": "\"480-831-5322\"",
                "website": "\"https://www.amf.com/location/amf-tempe-village-lanes\""
            },
            {
                "__id": 146,
                "address_city": "\"Houston\"",
                "address_country": "\"US\"",
                "address_full": "\"14441 Northwest Freeway\"",
                "address_postcode": "\"77040\"",
                "address_state": "\"TX\"",
                "latitude": 29.8633,
                "longitude": -95.5353,
                "name": "\"AMF Windfern Lanes\"",
                "phone": "\"713-466-8012\"",
                "website": "\"https://www.amf.com/location/amf-windfern-lanes\""
            },
            {
                "__id": 275,
                "address_city": "\"Fairview\"",
                "address_country": "\"US\"",
                "address_full": "\"351 Town Place \"",
                "address_postcode": "\"75069\"",
                "address_state": "\"TX\"",
                "latitude": 33.139318,
                "longitude": -96.602569,
                "name": "\"Bowlero Fairview\"",
                "phone": "\"972-457-9500\"",
                "website": "\"https://www.bowlero.com/location/bowlero-fairview\""
            },
            {
                "__id": 179,
                "address_city": "\"Vernon Hills\"",
                "address_country": "\"US\"",
                "address_full": "\"316 Center Drive\"",
                "address_postcode": "\"60061\"",
                "address_state": "\"IL\"",
                "latitude": 42.2451,
                "longitude": -87.9548,
                "name": "\"Bowlero Vernon Hills\"",
                "phone": "\"847-367-1600\"",
                "website": "\"https://www.bowlero.com/location/bowlero-vernon-hills\""
            },
            {
                "__id": 89,
                "address_city": "\"Aurora\"",
                "address_country": "\"US\"",
                "address_full": "\"16700 E. Mississippi Ave.\"",
                "address_postcode": "\"80017\"",
                "address_state": "\"CO\"",
                "latitude": 39.6955,
                "longitude": -104.793,
                "name": "\"AMF Aurora Lanes\"",
                "phone": "\"303-695-0680\"",
                "website": "\"https://www.amf.com/location/amf-aurora-lanes\""
            },
            {
                "__id": 168,
                "address_city": "\"Denton\"",
                "address_country": "\"US\"",
                "address_full": "\"2200 San Jacinto Blvd.\"",
                "address_postcode": "\"76205\"",
                "address_state": "\"TX\"",
                "latitude": 33.1936,
                "longitude": -97.1077,
                "name": "\"Bowlero Denton\"",
                "phone": "\"940-383-3515\"",
                "website": "\"https://www.bowlero.com/location/bowlero-denton\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "lincolnshire_cooperative": {
        "__id": 134,
        "id": "lincolnshire_cooperative",
        "name": "Lincolnshire Co-operative",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'image', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'website']",
        "rowLength": 184,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763547,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of lincolnshire_cooperative locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 115,
                "address_city": "\"Boston\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"LN6 9WF\"",
                "image": "\"https://s3-eu-west-1.amazonaws.com/lincolnshire-coop/images/store/Witham-St-Hughes-Food-Store.jpg\"",
                "latitude": 53.149733,
                "longitude": -0.665169,
                "name": "\"Witham St Hughs Food Store\"",
                "opening_hours": "\"Mo-Su 07:00-22:00\"",
                "phone": "\"01522 868 493\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Muntjac Way, Witham St Hughs, Lincoln\"",
                "website": "\"https://www.lincolnshire.coop/branches/food-stores/witham-st-hughs-food-store\""
            },
            {
                "__id": 13,
                "address_city": "\"Boston\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"NG24 4BZ\"",
                "image": "\"https://s3-eu-west-1.amazonaws.com/lincolnshire-coop/images/store/Bowbridge-Road-Food-Store-Newark-36.jpg\"",
                "latitude": 53.0688,
                "longitude": -0.805587,
                "name": "\"Bowbridge Road Food Store\"",
                "opening_hours": "\"Mo-Su 07:00-22:00\"",
                "phone": "\"01636 704080\"",
                "shop": "\"supermarket\"",
                "street_address": "\"108 Bowbridge Road, Newark, Nottinghamshire\"",
                "website": "\"https://www.lincolnshire.coop/branches/food-stores/bowbridge-road-food-store\""
            },
            {
                "__id": 111,
                "address_city": "\"Boston\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"LN8 5ND\"",
                "image": "\"https://s3-eu-west-1.amazonaws.com/lincolnshire-coop/images/store/Wragby-Food-Store-19.jpg\"",
                "latitude": 53.287139,
                "longitude": -0.30425,
                "name": "\"Wragby Food Store\"",
                "opening_hours": "\"Mo-Su 07:00-22:00\"",
                "phone": "\"01673 858 221\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Lincoln Road, A158, Wragby\"",
                "website": "\"https://www.lincolnshire.coop/branches/food-stores/wragby-food-store\""
            },
            {
                "__id": 14,
                "address_city": "\"Boston\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"LN6 0PY\"",
                "image": "\"https://s3-eu-west-1.amazonaws.com/lincolnshire-coop/images/store/Birchwood-Food-Store.JPG\"",
                "latitude": 53.214998,
                "longitude": -0.604203,
                "name": "\"Birchwood Centre Food Store\"",
                "opening_hours": "\"Mo-Sa 07:00-22:00; Su 10:00-16:00\"",
                "phone": "\"01522 686 668\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Birchwood Centre, Jasmin Road, Lincoln\"",
                "website": "\"https://www.lincolnshire.coop/branches/food-stores/birchwood-centre-food-store\""
            },
            {
                "__id": 51,
                "address_city": "\"Boston\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"PE21 0PH\"",
                "image": "\"https://s3-eu-west-1.amazonaws.com/lincolnshire-coop/images/store/Eastwood.jpg\"",
                "latitude": 52.976665,
                "longitude": -0.002356,
                "name": "\"Eastwood Road Food Store\"",
                "opening_hours": "\"Mo-Su 07:00-22:00\"",
                "phone": "\"01205 362 813\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Eastwood Road, Boston, Lincolnshire\"",
                "website": "\"https://www.lincolnshire.coop/branches/food-stores/eastwood-road-food-store\""
            },
            {
                "__id": 134,
                "address_city": "\"Boston\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"PE25 1SE\"",
                "image": "\"https://s3-eu-west-1.amazonaws.com/lincolnshire-coop/images/store/Roman-Bank-Food-Store.JPG\"",
                "latitude": 53.151981,
                "longitude": 0.341513,
                "name": "\"Roman Bank Food Store\"",
                "opening_hours": "\"Mo-Su 08:00-22:00\"",
                "phone": "\"01754 769 370\"",
                "shop": "\"supermarket\"",
                "street_address": "\"136-140 Roman Bank, Skegness, Lincolnshire\"",
                "website": "\"https://www.lincolnshire.coop/branches/food-stores/roman-bank-food-store\""
            },
            {
                "__id": 18,
                "address_city": "\"Boston\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"PE21 8PH\"",
                "image": "\"https://www.lincolnshire.coop/images/skin/logo.png\"",
                "latitude": 52.981041,
                "longitude": -0.038825,
                "name": "\"Argyle Street Food Store\"",
                "opening_hours": "\"Mo-Su 07:00-22:00\"",
                "phone": "\"01205 353 621\"",
                "shop": "\"supermarket\"",
                "street_address": "\"52-60 Argyle Street, Boston, Lincolnshire\"",
                "website": "\"https://www.lincolnshire.coop/branches/food-stores/argyle-street-food-store\""
            },
            {
                "__id": 80,
                "address_city": "\"Boston\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"YO12 6EE\"",
                "image": "\"https://s3-eu-west-1.amazonaws.com/lincolnshire-coop/images/store/Newby-Pharmacy.jpg\"",
                "latitude": 54.295974,
                "longitude": -0.441595,
                "name": "\"Newby Pharmacy\"",
                "opening_hours": "\"Mo-Fr 09:00-17:30; Sa 09:00-12:30; Su 00:00-24:00\"",
                "phone": "\"01723 362 071\"",
                "shop": "\"supermarket\"",
                "street_address": "\"448 Scalby Road, Newby, Scarborough\"",
                "website": "\"https://www.lincolnshire.coop/branches/pharmacies/newby-pharmacy\""
            },
            {
                "__id": 48,
                "address_city": "\"Boston\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"PE6 0ED\"",
                "image": "\"https://s3-eu-west-1.amazonaws.com/lincolnshire-coop/images/store/Crowland-Food-Store.jpg\"",
                "latitude": 52.675574,
                "longitude": -0.168902,
                "name": "\"Crowland Food Store\"",
                "opening_hours": "\"Mo-Su 07:00-22:00\"",
                "phone": "\"01733 210 020\"",
                "shop": "\"supermarket\"",
                "street_address": "\"8-12 West Street, Crowland, Peterborough\"",
                "website": "\"https://www.lincolnshire.coop/branches/food-stores/crowland-food-store\""
            },
            {
                "__id": 171,
                "address_city": "\"Boston\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"LN6 9NG\"",
                "image": "\"https://s3-eu-west-1.amazonaws.com/lincolnshire-coop/images/store/Newark-Road-Food-Store-and-Pharmacy.jpg\"",
                "latitude": 53.182478,
                "longitude": -0.609663,
                "name": "\"Newark Road Hykeham Pharmacy\"",
                "opening_hours": "\"Mo-Fr 09:00-18:00; Sa 09:00-12:00; Su 00:00-24:00\"",
                "phone": "\"01522 686242\"",
                "shop": "\"supermarket\"",
                "street_address": "\"451 Newark Road, North Hykeham, Lincoln, Lincolnshire\"",
                "website": "\"https://www.lincolnshire.coop/branches/pharmacies/newark-road-hykeham-pharmacy\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "natural_grocers": {
        "__id": 135,
        "id": "natural_grocers",
        "name": "Natural Grocers",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'shop', 'website']",
        "rowLength": 165,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763547,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of natural_grocers locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 147,
                "address_city": "\"Santa Fe\"",
                "address_country": "\"US\"",
                "address_full": "\"1090 S. St. Francis Dr.\"",
                "address_postcode": "\"87505\"",
                "address_state": "\"NM\"",
                "latitude": 35.675088,
                "longitude": -105.954258,
                "name": "\"Santa Fe\"",
                "opening_hours": "\"Mo-Sa 08:30-20:36; Su 09:00-19:35\"",
                "shop": "\"supermarket\"",
                "website": "\"https://www.naturalgrocers.com/store/santa-fe\""
            },
            {
                "__id": 85,
                "address_city": "\"Wheat Ridge\"",
                "address_country": "\"US\"",
                "address_full": "\"4900 Kipling Street\"",
                "address_postcode": "\"80033\"",
                "address_state": "\"CO\"",
                "latitude": 39.7860991,
                "longitude": -105.108811,
                "name": "\"Wheat Ridge\"",
                "opening_hours": "\"Mo-Sa 08:30-20:36; Su 09:00-19:35\"",
                "shop": "\"supermarket\"",
                "website": "\"https://www.naturalgrocers.com/store/wheat-ridge\""
            },
            {
                "__id": 43,
                "address_city": "\"Iowa City\"",
                "address_country": "\"US\"",
                "address_full": "\"1404 Gilbert Street\"",
                "address_postcode": "\"52240\"",
                "address_state": "\"IA\"",
                "latitude": 41.6447118,
                "longitude": -91.5318328,
                "name": "\"Iowa City\"",
                "opening_hours": "\"Mo-Sa 08:30-20:36; Su 09:00-19:35\"",
                "shop": "\"supermarket\"",
                "website": "\"https://www.naturalgrocers.com/store/iowa-city\""
            },
            {
                "__id": 42,
                "address_city": "\"Heber City\"",
                "address_country": "\"US\"",
                "address_full": "\"989 S. Main Street\"",
                "address_postcode": "\"84032\"",
                "address_state": "\"UT\"",
                "latitude": 40.494508,
                "longitude": -111.412794,
                "name": "\"Heber City\"",
                "opening_hours": "\"Mo-Sa 08:30-20:36; Su 09:00-19:35\"",
                "shop": "\"supermarket\"",
                "website": "\"https://www.naturalgrocers.com/store/heber-city\""
            },
            {
                "__id": 135,
                "address_city": "\"Abilene\"",
                "address_country": "\"US\"",
                "address_full": "\"3350 South Clack St\"",
                "address_postcode": "\"79606\"",
                "address_state": "\"TX\"",
                "latitude": 32.410136,
                "longitude": -99.772856,
                "name": "\"Abilene\"",
                "opening_hours": "\"Mo-Sa 09:00-20:36; Su 09:00-19:35\"",
                "shop": "\"supermarket\"",
                "website": "\"https://www.naturalgrocers.com/store/abilene\""
            },
            {
                "__id": 79,
                "address_city": "\"Rochester\"",
                "address_country": "\"US\"",
                "address_full": "\"1507 Greenview Drive SW\"",
                "address_postcode": "\"55902\"",
                "address_state": "\"MN\"",
                "latitude": 44.0031167,
                "longitude": -92.4873883,
                "name": "\"Rochester\"",
                "opening_hours": "\"Mo-Sa 09:00-20:36; Su 09:00-19:35\"",
                "shop": "\"supermarket\"",
                "website": "\"https://www.naturalgrocers.com/store/rochester\""
            },
            {
                "__id": 3,
                "address_city": "\"Castle Rock\"",
                "address_country": "\"US\"",
                "address_full": "\"4510 Trail Boss Drive\"",
                "address_postcode": "\"80104\"",
                "address_state": "\"CO\"",
                "latitude": 39.4073334,
                "longitude": -104.8590523,
                "name": "\"Castle Rock\"",
                "opening_hours": "\"Mo-Sa 08:30-20:36; Su 09:00-19:35\"",
                "shop": "\"supermarket\"",
                "website": "\"https://www.naturalgrocers.com/store/castle-rock\""
            },
            {
                "__id": 44,
                "address_city": "\"Davenport\"",
                "address_country": "\"US\"",
                "address_full": "\"3805 E. 53rd Street\"",
                "address_postcode": "\"52807\"",
                "address_state": "\"IA\"",
                "latitude": 41.5741593,
                "longitude": -90.5188784,
                "name": "\"Davenport\"",
                "opening_hours": "\"Mo-Sa 08:30-20:36; Su 09:00-19:35\"",
                "shop": "\"supermarket\"",
                "website": "\"https://www.naturalgrocers.com/store/davenport\""
            },
            {
                "__id": 86,
                "address_city": "\"Tyler\"",
                "address_country": "\"US\"",
                "address_full": "\"4820 S. Broadway Ave.\"",
                "address_postcode": "\"75703\"",
                "address_state": "\"TX\"",
                "latitude": 32.2966717,
                "longitude": -95.2993059,
                "name": "\"Tyler\"",
                "opening_hours": "\"Mo-Sa 08:30-20:36; Su 09:00-19:35\"",
                "shop": "\"supermarket\"",
                "website": "\"https://www.naturalgrocers.com/store/tyler\""
            },
            {
                "__id": 41,
                "address_city": "\"Joplin\"",
                "address_country": "\"US\"",
                "address_full": "\"510 South Range Line Rd\"",
                "address_postcode": "\"64801\"",
                "address_state": "\"MO\"",
                "latitude": 37.085833,
                "longitude": -94.478029,
                "name": "\"Joplin\"",
                "opening_hours": "\"Mo-Sa 08:30-20:36; Su 09:00-19:35\"",
                "shop": "\"supermarket\"",
                "website": "\"https://www.naturalgrocers.com/store/joplin\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "central_england_cooperative": {
        "__id": 136,
        "id": "central_england_cooperative",
        "name": "Central England Co-operative",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'image', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'street_address', 'website']",
        "rowLength": 457,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763548,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of central_england_cooperative locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 95,
                "address_city": "\"Birmingham Yardley\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"B26 1DT\"",
                "address_state": "\"West Midlands\"",
                "image": "\"https://dynl.mktgcdn.com/p/3Y_tKmqzvM7QakOLmjdWmqHK5L1QqNC1-beSA-YgBaM/1800x442.png\"",
                "latitude": 52.460751622619895,
                "longitude": -1.8045733441816765,
                "name": "\"Central Co-op Funeral - Yardley\"",
                "opening_hours": "\"Mo-Fr 09:00-17:00\"",
                "phone": "\"0121 706 8432\"",
                "street_address": "\"1735 Coventry Road\"",
                "website": "\"https://stores.centralengland.coop/west-midlands/birmingham/1735-coventry-road\""
            },
            {
                "__id": 77,
                "address_city": "\"Birmingham Weoley Castle\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"B29 5QL\"",
                "address_state": "\"West Midlands\"",
                "image": "\"https://dynl.mktgcdn.com/p/3Y_tKmqzvM7QakOLmjdWmqHK5L1QqNC1-beSA-YgBaM/1800x442.png\"",
                "latitude": 52.437507820461036,
                "longitude": -1.97143804291602,
                "name": "\"Central Co-op Funeral - Weoley Castle\"",
                "opening_hours": "\"Mo-We 09:00-17:00; Th-Fr 09:00-15:00\"",
                "phone": "\"0121 428 4668\"",
                "street_address": "\"156 Weoley Castle Road\"",
                "website": "\"https://stores.centralengland.coop/west-midlands/birmingham/156-weoley-castle-road\""
            },
            {
                "__id": 31,
                "address_city": "\"Wolverhampton Oxley\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"WV10 6AN\"",
                "address_state": "\"\"",
                "image": "\"https://dynl.mktgcdn.com/p/3Y_tKmqzvM7QakOLmjdWmqHK5L1QqNC1-beSA-YgBaM/1800x442.png\"",
                "latitude": 52.619164186415865,
                "longitude": -2.1296280895666153,
                "name": "\"Central Co-op Funeral - Oxley, Wolverhampton\"",
                "opening_hours": "\"\"",
                "phone": "\"01902 398845\"",
                "street_address": "\"492 Stafford Road\"",
                "website": "\"https://stores.centralengland.coop/wolverhampton/492-stafford-road\""
            },
            {
                "__id": 295,
                "address_city": "\"Leicester Blaby\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"LE8 4DW\"",
                "address_state": "\"Leicestershire\"",
                "image": "\"https://dynl.mktgcdn.com/p/3Y_tKmqzvM7QakOLmjdWmqHK5L1QqNC1-beSA-YgBaM/1800x442.png\"",
                "latitude": 52.574914117152524,
                "longitude": -1.1635672386429405,
                "name": "\"Central Co-op Funeral - Blaby\"",
                "opening_hours": "\"Mo-Fr 09:00-17:00\"",
                "phone": "\"0116 278 4237\"",
                "street_address": "\"9 Lutterworth Road\"",
                "website": "\"https://stores.centralengland.coop/leicestershire/leicester/9-lutterworth-road\""
            },
            {
                "__id": 452,
                "address_city": "\"\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"PE16 6NN\"",
                "address_state": "\"Cambridgeshire\"",
                "image": "\"https://dynl.mktgcdn.com/p/3Y_tKmqzvM7QakOLmjdWmqHK5L1QqNC1-beSA-YgBaM/1800x442.png\"",
                "latitude": 52.45735864516181,
                "longitude": 0.04746423313520154,
                "name": "\"Central Co-op Funeral - Chatteris\"",
                "opening_hours": "\"Mo-Fr 09:00-17:00\"",
                "phone": "\"01354 760072\"",
                "street_address": "\"72 High Street\"",
                "website": "\"https://stores.centralengland.coop/cambridgeshire/chatteris/72-high-street\""
            },
            {
                "__id": 160,
                "address_city": "\"\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"ST16 1LA\"",
                "address_state": "\"Staffordshire\"",
                "image": "\"https://dynl.mktgcdn.com/p/HH-gmopQundU4bHBtdZW84A0LCkLnORg79KjHZ4lwIM/3750x921.jpg\"",
                "latitude": 52.8214957914076,
                "longitude": -2.1268865889549033,
                "name": "\"Central Co-op Food -  Stone Road, Stafford\"",
                "opening_hours": "\"Mo-Su 07:00-22:00\"",
                "phone": "\"01785 223614\"",
                "street_address": "\"243 Stone Road\"",
                "website": "\"https://stores.centralengland.coop/staffordshire/stafford/243-stone-road\""
            },
            {
                "__id": 38,
                "address_city": "\"Huddersfield Shepley\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"HD8 8BL\"",
                "address_state": "\"West Yorkshire\"",
                "image": "\"https://dynl.mktgcdn.com/p/HH-gmopQundU4bHBtdZW84A0LCkLnORg79KjHZ4lwIM/3750x921.jpg\"",
                "latitude": 53.57498,
                "longitude": -1.705867,
                "name": "\"Central Co-op Food & Petrol - Lane Head Road, Shepley\"",
                "opening_hours": "\"24/7\"",
                "phone": "\"01484 603524\"",
                "street_address": "\"Lane Head Road\"",
                "website": "\"https://stores.centralengland.coop/west-yorkshire/huddersfield/lane-head-road\""
            },
            {
                "__id": 235,
                "address_city": "\"\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"PE9 2BJ\"",
                "address_state": "\"Lincolnshire\"",
                "image": "\"https://dynl.mktgcdn.com/p/3Y_tKmqzvM7QakOLmjdWmqHK5L1QqNC1-beSA-YgBaM/1800x442.png\"",
                "latitude": 52.652355,
                "longitude": -0.475979,
                "name": "\"R J Scholes funeral services\"",
                "opening_hours": "\"Mo-Fr 09:00-17:00\"",
                "phone": "\"01780 763092\"",
                "street_address": "\"St Georges Street\"",
                "website": "\"https://stores.centralengland.coop/lincolnshire/stamford/st-georges-street\""
            },
            {
                "__id": 88,
                "address_city": "\"Birmingham Sutton Coldfield\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"B73 5TD\"",
                "address_state": "\"West Midlands\"",
                "image": "\"https://dynl.mktgcdn.com/p/3Y_tKmqzvM7QakOLmjdWmqHK5L1QqNC1-beSA-YgBaM/1800x442.png\"",
                "latitude": 52.54962138243634,
                "longitude": -1.8417976123751032,
                "name": "\"Central Co-op Funeral - Sutton Coldfield\"",
                "opening_hours": "\"Mo-Fr 09:00-17:00\"",
                "phone": "\"0121 355 1166\"",
                "street_address": "\"30 Boldmere Road\"",
                "website": "\"https://stores.centralengland.coop/west-midlands/birmingham/30-boldmere-road\""
            },
            {
                "__id": 82,
                "address_city": "\"Birmingham Nechells\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"B7 4EN\"",
                "address_state": "\"West Midlands\"",
                "image": "\"https://dynl.mktgcdn.com/p/3Y_tKmqzvM7QakOLmjdWmqHK5L1QqNC1-beSA-YgBaM/1800x442.png\"",
                "latitude": 52.48643911989483,
                "longitude": -1.8808851161140865,
                "name": "\"Central Co-op Funeral - Nechells\"",
                "opening_hours": "\"Mo-Fr 09:00-17:00\"",
                "phone": "\"0121 359 1919\"",
                "street_address": "\"19 Great Brook Street\"",
                "website": "\"https://stores.centralengland.coop/west-midlands/birmingham/19-great-brook-street\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "ljsilvers": {
        "__id": 137,
        "id": "ljsilvers",
        "name": "LJ's Silvers",
        "columnNames": "['address_city', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'street_address', 'takeaway', 'website']",
        "rowLength": 604,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763549,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of ljsilvers locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 303,
                "address_city": "\"Las Vegas\"",
                "address_state": "\"NV\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"seafood\"",
                "latitude": 36.2706726,
                "longitude": -115.2619933,
                "name": "\"Long John Silver's | KFC\"",
                "opening_hours": "\"Mo-Su 10:30-22:00\"",
                "phone": "\"(702) 771-0476\"",
                "street_address": "\"5930 Centennial Center Blvd.\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.ljsilvers.com/nv/las-vegas/5930-centennial-center-blvd.\""
            },
            {
                "__id": 102,
                "address_city": "\"Lubbock\"",
                "address_state": "\"TX\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"seafood\"",
                "latitude": 33.5926796,
                "longitude": -101.92529,
                "name": "\"Long John Silver's\"",
                "opening_hours": "\"Mo-Su 10:30-22:00\"",
                "phone": "\"(806) 792-7960\"",
                "street_address": "\"5402 4th Street\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.ljsilvers.com/tx/lubbock/5402-4th-street\""
            },
            {
                "__id": 186,
                "address_city": "\"Anderson\"",
                "address_state": "\"SC\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"seafood\"",
                "latitude": 34.5431888366833,
                "longitude": -82.6682854111942,
                "name": "\"Long John Silver's | A&W\"",
                "opening_hours": "\"Mo-Th 10:30-21:00; Fr-Sa 10:30-22:00; Su 10:30-21:00\"",
                "phone": "\"(864) 225-0604\"",
                "street_address": "\"3314-A North Main St.\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.ljsilvers.com/sc/anderson/3314-a-north-main-st.\""
            },
            {
                "__id": 250,
                "address_city": "\"Piqua\"",
                "address_state": "\"OH\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"seafood\"",
                "latitude": 40.15049010158035,
                "longitude": -84.21490394357986,
                "name": "\"Long John Silver's | A&W\"",
                "opening_hours": "\"Mo 10:30-21:00; Tu-We 10:30-22:00; Th-Su 10:30-21:00\"",
                "phone": "\"(937) 773-0174\"",
                "street_address": "\"1219 East Ash St.\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.ljsilvers.com/oh/piqua/1219-east-ash-st.\""
            },
            {
                "__id": 26,
                "address_city": "\"Morgantown\"",
                "address_state": "\"WV\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"seafood\"",
                "latitude": 39.62847042840016,
                "longitude": -79.931447754786,
                "name": "\"Long John Silver's\"",
                "opening_hours": "\"Mo-Th 10:30-21:30; Fr-Sa 10:30-22:00; Su 10:30-21:30\"",
                "phone": "\"(304) 296-2142\"",
                "street_address": "\"1381 Earl L Core Road Sabraton Shopping Ctr.\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.ljsilvers.com/wv/morgantown/1381-earl-l-core-road\""
            },
            {
                "__id": 80,
                "address_city": "\"San Antonio\"",
                "address_state": "\"TX\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"seafood\"",
                "latitude": 29.41656027363593,
                "longitude": -98.6504002027134,
                "name": "\"Long John Silver's\"",
                "opening_hours": "\"Mo-Su 10:30-22:00\"",
                "phone": "\"(210) 501-0403\"",
                "street_address": "\"1737 SW Loop 410\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.ljsilvers.com/tx/san-antonio/1737-sw-loop-410\""
            },
            {
                "__id": 402,
                "address_city": "\"Paducah\"",
                "address_state": "\"KY\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"seafood\"",
                "latitude": 37.062714413142885,
                "longitude": -88.63911391033047,
                "name": "\"Long John Silver's | A&W\"",
                "opening_hours": "\"Mo-Th 10:30-21:00; Fr-Sa 10:30-22:00; Su 10:30-21:00\"",
                "phone": "\"(270) 442-1071\"",
                "street_address": "\"555 Lone Oak Road\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.ljsilvers.com/ky/paducah/555-lone-oak-road\""
            },
            {
                "__id": 66,
                "address_city": "\"Wichita Falls\"",
                "address_state": "\"TX\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"seafood\"",
                "latitude": 33.93654442656287,
                "longitude": -98.51952320674069,
                "name": "\"Long John Silver's\"",
                "opening_hours": "\"\"",
                "phone": "\"(940) 767-6256\"",
                "street_address": "\"2000 Maurine Street\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.ljsilvers.com/tx/wichita-falls/2000-maurine-street\""
            },
            {
                "__id": 531,
                "address_city": "\"Chicago\"",
                "address_state": "\"IL\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"seafood\"",
                "latitude": 41.9241754,
                "longitude": -87.7915744,
                "name": "\"Long John Silver's | Taco Bell\"",
                "opening_hours": "\"Mo-Su 10:30-22:00\"",
                "phone": "\"(773) 237-4661\"",
                "street_address": "\"6622 W. Fullerton Ave.\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.ljsilvers.com/il/chicago/6622-w.-fullerton-ave.\""
            },
            {
                "__id": 209,
                "address_city": "\"Du Bois\"",
                "address_state": "\"PA\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"seafood\"",
                "latitude": 41.12307084540447,
                "longitude": -78.73061915454134,
                "name": "\"Long John Silver's | A&W\"",
                "opening_hours": "\"Mo-Su 11:00-21:00\"",
                "phone": "\"(814) 375-0874\"",
                "street_address": "\"1255 Dubois Ave.\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.ljsilvers.com/pa/du-bois/1255-dubois-ave.\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "giantfoodstores": {
        "__id": 138,
        "id": "giantfoodstores",
        "name": "Giant Food Stores",
        "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'street_address', 'website']",
        "rowLength": 162,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763550,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of giantfoodstores locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 15,
                "address_city": "\"Bensalem\"",
                "address_postcode": "\"19020\"",
                "address_state": "\"PA\"",
                "latitude": 40.11114301053709,
                "longitude": -74.9513053894043,
                "name": "\"GIANT Bensalem\"",
                "opening_hours": "\"Mo-Fr 06:00-24:00,09:00-20:00; Sa 06:00-24:00,09:00-17:00; Su 06:00-24:00,10:00-17:00\"",
                "phone": "\"(215) 604-9924\"",
                "street_address": "\"2721 Street Rd\"",
                "website": "\"https://stores.giantfoodstores.com/pa/bensalem/2721-street-rd\""
            },
            {
                "__id": 55,
                "address_city": "\"Reading\"",
                "address_postcode": "\"19607\"",
                "address_state": "\"PA\"",
                "latitude": 40.3074094,
                "longitude": -75.9545106,
                "name": "\"GIANT Reading\"",
                "opening_hours": "\"Mo-Fr 06:00-23:00,09:00-20:00; Sa 06:00-23:00,09:00-17:00; Su 06:00-23:00,10:00-17:00\"",
                "phone": "\"(610) 775-5015\"",
                "street_address": "\"600 E Lancaster Ave\"",
                "website": "\"https://stores.giantfoodstores.com/pa/reading/600-e-lancaster-ave\""
            },
            {
                "__id": 154,
                "address_city": "\"Columbia\"",
                "address_postcode": "\"17512\"",
                "address_state": "\"PA\"",
                "latitude": 40.0378908,
                "longitude": -76.4534436,
                "name": "\"GIANT Columbia\"",
                "opening_hours": "\"Mo-Su 06:00-22:00\"",
                "phone": "\"(717) 285-4380\"",
                "street_address": "\"3985 Columbia Avenue\"",
                "website": "\"https://stores.giantfoodstores.com/pa/columbia/3985-columbia-avenue\""
            },
            {
                "__id": 10,
                "address_city": "\"Bloomsburg\"",
                "address_postcode": "\"17815\"",
                "address_state": "\"PA\"",
                "latitude": 41.00690154691386,
                "longitude": -76.43460291630618,
                "name": "\"GIANT Bloomsburg\"",
                "opening_hours": "\"Mo-Fr 06:00-23:00,09:00-21:00; Sa 06:00-23:00,09:00-19:00; Su 06:00-23:00,09:00-17:00\"",
                "phone": "\"(570) 389-9004\"",
                "street_address": "\"1000 Scott Town Plaza\"",
                "website": "\"https://stores.giantfoodstores.com/pa/bloomsburg/1000-scott-town-plaza\""
            },
            {
                "__id": 66,
                "address_city": "\"Phoenixville\"",
                "address_postcode": "\"19460\"",
                "address_state": "\"PA\"",
                "latitude": 40.13419619785424,
                "longitude": -75.53396970033646,
                "name": "\"GIANT Phoenixville\"",
                "opening_hours": "\"Mo-Fr 06:00-23:00,09:00-20:00; Sa 06:00-23:00,09:00-17:00; Su 06:00-23:00,10:00-17:00\"",
                "phone": "\"(610) 917-9086\"",
                "street_address": "\"700 Nutt Rd\"",
                "website": "\"https://stores.giantfoodstores.com/pa/phoenixville/700-nutt-rd\""
            },
            {
                "__id": 28,
                "address_city": "\"West Chester\"",
                "address_postcode": "\"19380\"",
                "address_state": "\"PA\"",
                "latitude": 39.9607095,
                "longitude": -75.621889,
                "name": "\"GIANT West Chester\"",
                "opening_hours": "\"Mo-Fr 06:00-23:00,09:00-20:00; Sa 06:00-23:00,09:00-17:00; Su 06:00-23:00,10:00-17:00\"",
                "phone": "\"(610) 430-7150\"",
                "street_address": "\"698 Downingtown Pike\"",
                "website": "\"https://stores.giantfoodstores.com/pa/west-chester/698-downingtown-pike\""
            },
            {
                "__id": 132,
                "address_city": "\"Halifax\"",
                "address_postcode": "\"17032\"",
                "address_state": "\"PA\"",
                "latitude": 40.455333744163,
                "longitude": -76.92496463656425,
                "name": "\"GIANT Halifax\"",
                "opening_hours": "\"Mo-Su 06:00-23:00\"",
                "phone": "\"(717) 896-8703\"",
                "street_address": "\"3777 Peters Mtn\"",
                "website": "\"https://stores.giantfoodstores.com/pa/halifax/3777-peters-mtn\""
            },
            {
                "__id": 118,
                "address_city": "\"Horsham\"",
                "address_postcode": "\"19044\"",
                "address_state": "\"PA\"",
                "latitude": 40.1820957,
                "longitude": -75.1332126,
                "name": "\"GIANT Horsham\"",
                "opening_hours": "\"Mo-Su 06:00-23:00\"",
                "phone": "\"(215) 682-7422\"",
                "street_address": "\"314 Horsham Rd\"",
                "website": "\"https://stores.giantfoodstores.com/pa/horsham/314-horsham-rd\""
            },
            {
                "__id": 138,
                "address_city": "\"Exton\"",
                "address_postcode": "\"19341\"",
                "address_state": "\"PA\"",
                "latitude": 40.0504655,
                "longitude": -75.6648089,
                "name": "\"GIANT Exton\"",
                "opening_hours": "\"Mo-Su 06:00-23:00\"",
                "phone": "\"(610) 363-0799\"",
                "street_address": "\"168 Eagleview Blvd\"",
                "website": "\"https://stores.giantfoodstores.com/pa/exton/168-eagleview-blvd\""
            },
            {
                "__id": 116,
                "address_city": "\"Huntingdon Valley\"",
                "address_postcode": "\"19006\"",
                "address_state": "\"PA\"",
                "latitude": 40.086481397982254,
                "longitude": -75.09179413318634,
                "name": "\"GIANT Huntingdon Valley\"",
                "opening_hours": "\"Mo-Fr 06:00-23:00,09:00-20:00; Sa 06:00-23:00,09:00-17:00; Su 06:00-23:00,10:00-17:00\"",
                "phone": "\"(215) 379-6900\"",
                "street_address": "\"737 Huntingdon Pike\"",
                "website": "\"https://stores.giantfoodstores.com/pa/huntingdon-valley/737-huntingdon-pike\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "halifax_gb": {
        "__id": 139,
        "id": "halifax_gb",
        "name": "Halifax Great Britain",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'image', 'latitude', 'longitude', 'name', 'phone', 'street_address', 'website']",
        "rowLength": 526,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763551,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of halifax_gb locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 324,
                "address_city": "\"Huntingdon\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"PE29 3LS\"",
                "image": "\"https://dynl.mktgcdn.com/p/F_R5OxCNf8cOxGGehDXdcoK_2SId9V39CruXsPpiN2U/150x150.jpg\"",
                "latitude": 52.3305242,
                "longitude": -0.1835188,
                "name": "\"Halifax Huntingdon\"",
                "phone": "\"0345 720 3040\"",
                "street_address": "\"114 High Street\"",
                "website": "\"https://branches.halifax.co.uk/huntingdon/114-high-street\""
            },
            {
                "__id": 424,
                "address_city": "\"Craigavon\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"BT66 6AR\"",
                "image": "\"https://dynl.mktgcdn.com/p/F_R5OxCNf8cOxGGehDXdcoK_2SId9V39CruXsPpiN2U/150x150.jpg\"",
                "latitude": 54.46218688198516,
                "longitude": -6.332740728836064,
                "name": "\"Halifax Lurgan\"",
                "phone": "\"0345 720 3040\"",
                "street_address": "\"23 Market Street Lurgan\"",
                "website": "\"https://branches.halifax.co.uk/craigavon/23-market-street\""
            },
            {
                "__id": 70,
                "address_city": "\"Torquay\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"TQ1 3YA\"",
                "image": "\"https://dynl.mktgcdn.com/p/F_R5OxCNf8cOxGGehDXdcoK_2SId9V39CruXsPpiN2U/150x150.jpg\"",
                "latitude": 50.467434131501584,
                "longitude": -3.5304311124449472,
                "name": "\"Halifax Torquay\"",
                "phone": "\"0345 720 3040\"",
                "street_address": "\"3/4 Union House Union Street\"",
                "website": "\"https://branches.halifax.co.uk/torquay/3/4-union-house\""
            },
            {
                "__id": 338,
                "address_city": "\"Herne Bay\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"CT6 5HS\"",
                "image": "\"https://dynl.mktgcdn.com/p/F_R5OxCNf8cOxGGehDXdcoK_2SId9V39CruXsPpiN2U/150x150.jpg\"",
                "latitude": 51.37209714620108,
                "longitude": 1.1313700346467792,
                "name": "\"Halifax Herne Bay\"",
                "phone": "\"0345 720 3040\"",
                "street_address": "\"149/151 Mortimer Street\"",
                "website": "\"https://branches.halifax.co.uk/herne-bay/149/151-mortimer-street\""
            },
            {
                "__id": 248,
                "address_city": "\"London\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"NW4 3FT\"",
                "image": "\"https://dynl.mktgcdn.com/p/F_R5OxCNf8cOxGGehDXdcoK_2SId9V39CruXsPpiN2U/150x150.jpg\"",
                "latitude": 51.5762328,
                "longitude": -0.2249639,
                "name": "\"Halifax Brent Cross\"",
                "phone": "\"0345 720 3040\"",
                "street_address": "\"Lower Ground Floor\"",
                "website": "\"https://branches.halifax.co.uk/london/lower-ground-floor\""
            },
            {
                "__id": 399,
                "address_city": "\"Dudley\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"DY1 1LD\"",
                "image": "\"https://dynl.mktgcdn.com/p/F_R5OxCNf8cOxGGehDXdcoK_2SId9V39CruXsPpiN2U/150x150.jpg\"",
                "latitude": 52.510784667376264,
                "longitude": -2.081335190766407,
                "name": "\"Halifax Dudley Castle Street\"",
                "phone": "\"0345 720 3040\"",
                "street_address": "\"15 Castle Street\"",
                "website": "\"https://branches.halifax.co.uk/dudley/15-castle-street\""
            },
            {
                "__id": 453,
                "address_city": "\"Camborne\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"TR14 8AD\"",
                "image": "\"https://dynl.mktgcdn.com/p/F_R5OxCNf8cOxGGehDXdcoK_2SId9V39CruXsPpiN2U/150x150.jpg\"",
                "latitude": 50.214296137853026,
                "longitude": -5.298072823911237,
                "name": "\"Halifax Camborne\"",
                "phone": "\"0345 720 3040\"",
                "street_address": "\"31 Trelowarren Street\"",
                "website": "\"https://branches.halifax.co.uk/camborne/31-trelowarren-street\""
            },
            {
                "__id": 445,
                "address_city": "\"Carlisle\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"CA3 8HE\"",
                "image": "\"https://dynl.mktgcdn.com/p/F_R5OxCNf8cOxGGehDXdcoK_2SId9V39CruXsPpiN2U/150x150.jpg\"",
                "latitude": 54.89370075326651,
                "longitude": -2.934005821755854,
                "name": "\"Halifax Carlisle\"",
                "phone": "\"0345 720 3040\"",
                "street_address": "\"16 Bank Street\"",
                "website": "\"https://branches.halifax.co.uk/carlisle/16-bank-street\""
            },
            {
                "__id": 415,
                "address_city": "\"Darlington\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"DL1 1TR\"",
                "image": "\"https://dynl.mktgcdn.com/p/F_R5OxCNf8cOxGGehDXdcoK_2SId9V39CruXsPpiN2U/150x150.jpg\"",
                "latitude": 54.52719071348935,
                "longitude": -1.5541050286215885,
                "name": "\"Halifax Darlington\"",
                "phone": "\"0345 720 3040\"",
                "street_address": "\"53/55 Northgate\"",
                "website": "\"https://branches.halifax.co.uk/darlington/53/55-northgate\""
            },
            {
                "__id": 314,
                "address_city": "\"Kettering\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"NN16 8TA\"",
                "image": "\"https://dynl.mktgcdn.com/p/F_R5OxCNf8cOxGGehDXdcoK_2SId9V39CruXsPpiN2U/150x150.jpg\"",
                "latitude": 52.400323519101256,
                "longitude": -0.7289061045326584,
                "name": "\"Halifax Kettering\"",
                "phone": "\"0345 720 3040\"",
                "street_address": "\"15 Lower Street\"",
                "website": "\"https://branches.halifax.co.uk/kettering/15-lower-street\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "whidbeycoffee": {
        "__id": 140,
        "id": "whidbeycoffee",
        "name": "Whidbey Coffee",
        "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'website']",
        "rowLength": 12,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763552,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of whidbeycoffee locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 4,
                "address_city": "\"Mukilteo\"",
                "address_full": "\"12620 Mukilteo Speedway\"",
                "address_postcode": "\"98275\"",
                "address_state": "\"Wa\"",
                "latitude": 47.883516,
                "longitude": -122.2832807,
                "name": "\"Mukilteo Speedway - Drive Thru\"",
                "opening_hours": "\"Mo-Fr 05:30-17:30; Sa 06:00-17:30; Su 07:00-17:30\"",
                "phone": "\"-347-5390\"",
                "website": "\"https://www.whidbeycoffee.com/pages/mukilteo-speedway-drive-thru\""
            },
            {
                "__id": 6,
                "address_city": "\"Kirkland\"",
                "address_full": "\"12040 NE 128th St\"",
                "address_postcode": "\"98034\"",
                "address_state": "\"WA\"",
                "latitude": 47.7156087,
                "longitude": -122.1806126,
                "name": "\"Evergreen Hospital Silver Coffee Stand\"",
                "opening_hours": "\"Mo-Fr 06:00-17:00\"",
                "phone": "\"-899-1774\"",
                "website": "\"https://www.whidbeycoffee.com/pages/evergreen-hospital-silver-location\""
            },
            {
                "__id": 5,
                "address_city": "\"Freeland\"",
                "address_full": "\"1609 E Main St.\"",
                "address_postcode": "\"98249\"",
                "address_state": "\"Wa\"",
                "latitude": 48.0098187,
                "longitude": -122.5319591,
                "name": "\"Freeland - Drive Thru\"",
                "opening_hours": "\"Mo-Fr 05:30-18:00; Sa 06:00-18:00\"",
                "phone": "\"360-331-3626\"",
                "website": "\"https://www.whidbeycoffee.com/pages/freeland-drive-thru\""
            },
            {
                "__id": 8,
                "address_city": "\"Kirkland\"",
                "address_full": "\"12040 NE 128th St\"",
                "address_postcode": "\"98034\"",
                "address_state": "\"Wa\"",
                "latitude": 47.7156087,
                "longitude": -122.1806126,
                "name": "\"Evergreen Hospital Courtyard Location - Coffee Station\"",
                "opening_hours": "\"Mo-Fr 06:00-17:00; Sa 07:00-17:00\"",
                "phone": "\"-899-1774\"",
                "website": "\"https://www.whidbeycoffee.com/pages/evergreen-hospital\""
            },
            {
                "__id": 10,
                "address_city": "\"Burlington\"",
                "address_full": "\"848 S Burlington Blvd\"",
                "address_postcode": "\"98233\"",
                "address_state": "\"Wa\"",
                "latitude": 48.4693243,
                "longitude": -122.337386,
                "name": "\"Burlington Blvd - Drive Thru\"",
                "opening_hours": "\"Mo-Fr 05:00-19:00; Sa 06:00-19:00; Su 06:00-18:00\"",
                "phone": "\"360-757-4879\"",
                "website": "\"https://www.whidbeycoffee.com/pages/burlington-drive-thru\""
            },
            {
                "__id": 2,
                "address_city": "\"Oak Harbor\"",
                "address_full": "\"31275 SR 20\"",
                "address_postcode": "\"98277\"",
                "address_state": "\"Wa\"",
                "latitude": 48.2894286,
                "longitude": -122.6542828,
                "name": "\"Oak Harbor - SR20 Coffee Shop\"",
                "opening_hours": "\"Mo-Sa 05:30-20:00; Su 06:30-19:00\"",
                "phone": "\"360-279-8165\"",
                "website": "\"https://www.whidbeycoffee.com/pages/oak-harbor-sr20\""
            },
            {
                "__id": 9,
                "address_city": "\"Clinton\"",
                "address_full": "\"4141 E SR 525\"",
                "address_postcode": "\"98236\"",
                "address_state": "\"Wa\"",
                "latitude": 47.9891091,
                "longitude": -122.3962361,
                "name": "\"Clinton - Drive Thru\"",
                "opening_hours": "\"Mo-Fr 05:30-18:00; Sa 06:00-18:00; Su 07:00-18:00\"",
                "phone": "\"360-222-2072\"",
                "website": "\"https://www.whidbeycoffee.com/pages/clinton-drive-thru\""
            },
            {
                "__id": 3,
                "address_city": "\"Oak Harbor\"",
                "address_full": "\"980 SE Pioneer Way\"",
                "address_postcode": "\"98277\"",
                "address_state": "\"WA\"",
                "latitude": 48.289429,
                "longitude": -122.6477167,
                "name": "\"Oak Harbor - Pioneer Way Coffee Shop\"",
                "opening_hours": "\"Mo-Fr 05:30-19:00; Sa 06:00-19:00; Su 06:30-18:00\"",
                "phone": "\"360-679-1162\"",
                "website": "\"https://www.whidbeycoffee.com/pages/oak-harbor-pioneer-way\""
            },
            {
                "__id": 12,
                "address_city": "\"Anacortes\"",
                "address_full": "\"1120 Commercial Ave\"",
                "address_postcode": "\"98221\"",
                "address_state": "\"Wa\"",
                "latitude": 48.5128715,
                "longitude": -122.6150238,
                "name": "\"Anacortes Drive Thru\"",
                "opening_hours": "\"Mo-Fr 05:30-18:00; Sa 06:00-18:00; Su 07:00-17:00\"",
                "phone": "\"360-230-1339\"",
                "website": "\"https://www.whidbeycoffee.com/pages/anacortes-drive-thru\""
            },
            {
                "__id": 1,
                "address_city": "\"Mt. Vernon\"",
                "address_full": "\"1415 E Kincaid St\"",
                "address_postcode": "\"98273\"",
                "address_state": "\"WA\"",
                "latitude": 48.4179604,
                "longitude": -122.3258503,
                "name": "\"Mt. Vernon - Skagit Valley Hospital Coffee Shop\"",
                "opening_hours": "\"\"",
                "phone": "\"360-416-8328\"",
                "website": "\"https://www.whidbeycoffee.com/pages/skagit-valley-hospital\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "marstons_plc": {
        "__id": 141,
        "id": "marstons_plc",
        "name": "Marston's PLC",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'contact_email', 'image', 'latitude', 'longitude', 'name', 'payment_marstons_privilege_card', 'phone', 'street_address', 'tourism', 'website']",
        "rowLength": 1422,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763553,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of marstons_plc locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 1053,
                "address_city": "\"Hull\"",
                "address_country": "\"GB\"",
                "address_full": "\"Queens Road, Hull, East Yorkshire, HU5 2PY\"",
                "address_postcode": "\"HU5 2PY\"",
                "address_state": "\"East Yorkshire\"",
                "amenity": "\"pub\"",
                "contact_email": "\"StJohns.Hull@phoenixpub.co.uk\"",
                "image": "\"\"",
                "latitude": 53.761536,
                "longitude": -0.351612,
                "name": "\"St Johns Hotel\"",
                "payment_marstons_privilege_card": "\"no\"",
                "phone": "\"01482 341013\"",
                "street_address": "\"Queens Road\"",
                "tourism": "\"\"",
                "website": "\"\""
            },
            {
                "__id": 683,
                "address_city": "\"Swanwick\"",
                "address_country": "\"GB\"",
                "address_full": "\"The Green, Swanwick, Alfreton, Derbyshire, DE55 1BL\"",
                "address_postcode": "\"DE55 1BL\"",
                "address_state": "\"Derbyshire\"",
                "amenity": "\"pub\"",
                "contact_email": "\"BootandSlipper.Swanick@phoenixpub.co.uk\"",
                "image": "\"\"",
                "latitude": 53.075264,
                "longitude": -1.399723,
                "name": "\"Boot & Slipper\"",
                "payment_marstons_privilege_card": "\"no\"",
                "phone": "\"01773 608607\"",
                "street_address": "\"The Green\"",
                "tourism": "\"\"",
                "website": "\"\""
            },
            {
                "__id": 264,
                "address_city": "\"Preston\"",
                "address_country": "\"GB\"",
                "address_full": "\"Mount Street, Preston, Lancashire, PR1 8BS\"",
                "address_postcode": "\"PR1 8BS\"",
                "address_state": "\"Lancashire\"",
                "amenity": "\"pub\"",
                "contact_email": "\"hartleys.preston@phoenixpub.co.uk\"",
                "image": "\"https://www.marstonspubs.co.uk/img/pubs/5/17055_med.jpg\"",
                "latitude": 53.756012,
                "longitude": -2.703776,
                "name": "\"Hartleys Wine Bar\"",
                "payment_marstons_privilege_card": "\"no\"",
                "phone": "\"01772 378925\"",
                "street_address": "\"Mount Street\"",
                "tourism": "\"\"",
                "website": "\"https://www.artleyswinebarpreston.co.uk/\""
            },
            {
                "__id": 79,
                "address_city": "\"Manchester\"",
                "address_country": "\"GB\"",
                "address_full": "\"Failsworth, Manchester, Greater Manchester, M35 0ES\"",
                "address_postcode": "\"M35 0ES\"",
                "address_state": "\"Greater Manchester\"",
                "amenity": "\"pub\"",
                "contact_email": "\"MareandFoal.Failsworth@phoenixpub.co.uk\"",
                "image": "\"https://www.marstonspubs.co.uk/img/pubs/9/90499_med.jpg\"",
                "latitude": 53.507046,
                "longitude": -2.160056,
                "name": "\"Mare & Foal\"",
                "payment_marstons_privilege_card": "\"no\"",
                "phone": "\"01616813112\"",
                "street_address": "\"Failsworth\"",
                "tourism": "\"\"",
                "website": "\"\""
            },
            {
                "__id": 845,
                "address_city": "\"Rowley Regis\"",
                "address_country": "\"GB\"",
                "address_full": "\"11 High Street, Rowley Regis, West Midlands, B65 0DT\"",
                "address_postcode": "\"B65 0DT\"",
                "address_state": "\"West Midlands\"",
                "amenity": "\"pub\"",
                "contact_email": "\"Vine.RowleyRegis@phoenixpub.co.uk\"",
                "image": "\"\"",
                "latitude": 52.475426,
                "longitude": -2.040207,
                "name": "\"Vine Inn\"",
                "payment_marstons_privilege_card": "\"no\"",
                "phone": "\"01215722586\"",
                "street_address": "\"11 High Street\"",
                "tourism": "\"\"",
                "website": "\"\""
            },
            {
                "__id": 697,
                "address_city": "\"Burton Upon Trent\"",
                "address_country": "\"GB\"",
                "address_full": "\"Barton Turns, Burton Upon Trent, Staffordshire, DE13 8EA\"",
                "address_postcode": "\"DE13 8EA\"",
                "address_state": "\"Staffordshire\"",
                "amenity": "\"pub\"",
                "contact_email": "\"sheila-woodcock@hotmail.co.uk\"",
                "image": "\"\"",
                "latitude": 52.760944,
                "longitude": -1.702768,
                "name": "\"Barton Turns Inn\"",
                "payment_marstons_privilege_card": "\"no\"",
                "phone": "\"01283 480682\"",
                "street_address": "\"Barton Turns\"",
                "tourism": "\"\"",
                "website": "\"\""
            },
            {
                "__id": 483,
                "address_city": "\"Glan Conway\"",
                "address_country": "\"GB\"",
                "address_full": "\"Llanrwyst Road, Glan Conway, Clwyd, LL28 5SS\"",
                "address_postcode": "\"LL28 5SS\"",
                "address_state": "\"Clwyd\"",
                "amenity": "\"pub\"",
                "contact_email": "\"CrossKeys.Conwy@phoenixpub.co.uk\"",
                "image": "\"https://www.marstonspubs.co.uk/img/pubs/4/15014_med.jpg\"",
                "latitude": 53.269024,
                "longitude": -3.79616,
                "name": "\"Cross Keys\"",
                "payment_marstons_privilege_card": "\"no\"",
                "phone": "\"01492 592544\"",
                "street_address": "\"Llanrwyst Road\"",
                "tourism": "\"\"",
                "website": "\"\""
            },
            {
                "__id": 103,
                "address_city": "\"Hull\"",
                "address_country": "\"GB\"",
                "address_full": "\"Trinity House Lane, Hull, East Yorkshire, HU1 2JA\"",
                "address_postcode": "\"HU1 2JA\"",
                "address_state": "\"East Yorkshire\"",
                "amenity": "\"pub\"",
                "contact_email": "\"KingstonHotel.Hull@phoenixpub.co.uk\"",
                "image": "\"\"",
                "latitude": 53.742344,
                "longitude": -0.334788,
                "name": "\"Kingston Hotel\"",
                "payment_marstons_privilege_card": "\"no\"",
                "phone": "\"01482 223635\"",
                "street_address": "\"Trinity House Lane\"",
                "tourism": "\"\"",
                "website": "\"\""
            },
            {
                "__id": 954,
                "address_city": "\"Surrey\"",
                "address_country": "\"GB\"",
                "address_full": "\"Guards Avenue, Surrey, Caterham, CR3 5XL\"",
                "address_postcode": "\"CR3 5XL\"",
                "address_state": "\"Caterham\"",
                "amenity": "\"pub\"",
                "contact_email": "\"BoundaryontheGreen.Caterham@marstons.co.uk\"",
                "image": "\"https://www.marstonspubs.co.uk/img/pubs/6/91946_med.jpg\"",
                "latitude": 51.291233,
                "longitude": -0.102006,
                "name": "\"The Boundary On The Green\"",
                "payment_marstons_privilege_card": "\"yes\"",
                "phone": "\"01883 818087\"",
                "street_address": "\"Guards Avenue\"",
                "tourism": "\"\"",
                "website": "\"https://www.boundaryonthegreenpub.co.uk/\""
            },
            {
                "__id": 670,
                "address_city": "\"Ruthin\"",
                "address_country": "\"GB\"",
                "address_full": "\"4 Clwyd Street, Ruthin, Flintshire, LL15 1HW\"",
                "address_postcode": "\"LL15 1HW\"",
                "address_state": "\"Flintshire\"",
                "amenity": "\"pub\"",
                "contact_email": "\"BoarsHead.Ruthin@phoenixpub.co.uk\"",
                "image": "\"\"",
                "latitude": 53.11427,
                "longitude": -3.312249,
                "name": "\"Boars Head\"",
                "payment_marstons_privilege_card": "\"no\"",
                "phone": "\"01824 703355\"",
                "street_address": "\"4 Clwyd Street\"",
                "tourism": "\"\"",
                "website": "\"\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "calvin_klein": {
        "__id": 142,
        "id": "calvin_klein",
        "name": "Calvin Klein",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'phone', 'shop']",
        "rowLength": 233,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763553,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of calvin_klein locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 230,
                "address_city": "\"Saint Petersburg\"",
                "address_country": "\"RU\"",
                "address_full": "\"Vasi Alekseeva, 6, 198188, Saint Petersburg, Russia\"",
                "address_postcode": "\"\"",
                "address_state": "\"Europe\"",
                "latitude": 59.87991,
                "longitude": 30.26557,
                "name": "\"Calvin Klein\"",
                "phone": "\"\"",
                "shop": "\"clothes\""
            },
            {
                "__id": 107,
                "address_city": "\"Palma de Mallorca\"",
                "address_country": "\"ES\"",
                "address_full": "\"C.C Mallorca Fashion Outlet, Autopista Palma-Inca, Km 7,1\"",
                "address_postcode": "\"07141\"",
                "address_state": "\"Europe\"",
                "latitude": 39.63218,
                "longitude": 2.73297,
                "name": "\"Calvin Klein\"",
                "phone": "\"\"",
                "shop": "\"clothes\""
            },
            {
                "__id": 78,
                "address_city": "\"Magdeburg\"",
                "address_country": "\"DE\"",
                "address_full": "\"Ernst-Reuter-Allee 11\"",
                "address_postcode": "\"39104\"",
                "address_state": "\"Europe\"",
                "latitude": 52.13005,
                "longitude": 11.63808,
                "name": "\"Calvin Klein\"",
                "phone": "\"\"",
                "shop": "\"clothes\""
            },
            {
                "__id": 197,
                "address_city": "\"Nizhny Novgorod\"",
                "address_country": "\"RU\"",
                "address_full": "\"Fantastika Mall, Rodionova street 187\"",
                "address_postcode": "\"603005\"",
                "address_state": "\"Europe\"",
                "latitude": 56.30704,
                "longitude": 44.0751,
                "name": "\"Calvin Klein\"",
                "phone": "\"\"",
                "shop": "\"clothes\""
            },
            {
                "__id": 74,
                "address_city": "\"Aachen\"",
                "address_country": "\"DE\"",
                "address_full": "\"Adalbertstrasse 70\"",
                "address_postcode": "\"52062\"",
                "address_state": "\"Europe\"",
                "latitude": 50.77522,
                "longitude": 6.09195,
                "name": "\"Calvin Klein\"",
                "phone": "\"\"",
                "shop": "\"clothes\""
            },
            {
                "__id": 203,
                "address_city": "\"Voronezh\"",
                "address_country": "\"RU\"",
                "address_full": "\"Galereya Chizhova, Koltsovskaya street 35A\"",
                "address_postcode": "\"394000\"",
                "address_state": "\"Europe\"",
                "latitude": 51.66569,
                "longitude": 39.19196,
                "name": "\"Calvin Klein\"",
                "phone": "\"\"",
                "shop": "\"clothes\""
            },
            {
                "__id": 33,
                "address_city": "\"London\"",
                "address_country": "\"GB\"",
                "address_full": "\"Wembley Park Boulevard  77, HA9 0FD, London, UK\"",
                "address_postcode": "\"HA9 0FD\"",
                "address_state": "\"Europe\"",
                "latitude": 51.5571,
                "longitude": -0.28403,
                "name": "\"Calvin Klein\"",
                "phone": "\"\"",
                "shop": "\"clothes\""
            },
            {
                "__id": 201,
                "address_city": "\"Chelyabinsk\"",
                "address_country": "\"RU\"",
                "address_full": "\"Gorki Mall, Ulitsa Artilleriyskaya, 136\"",
                "address_postcode": "\"454007\"",
                "address_state": "\"Europe\"",
                "latitude": 55.16442,
                "longitude": 61.43098,
                "name": "\"Calvin Klein\"",
                "phone": "\"\"",
                "shop": "\"clothes\""
            },
            {
                "__id": 21,
                "address_city": "\"Greenhithe\"",
                "address_country": "\"GB\"",
                "address_full": "\"Bluewater Shopping Center, Unit U036\"",
                "address_postcode": "\"DA9 9ST\"",
                "address_state": "\"Europe\"",
                "latitude": 51.43873,
                "longitude": 0.271,
                "name": "\"Calvin Klein\"",
                "phone": "\"\"",
                "shop": "\"clothes\""
            },
            {
                "__id": 103,
                "address_city": "\"Barakaldo\"",
                "address_country": "\"ES\"",
                "address_full": "\"Centro Comercial Megapark Barakaldo, Avda. de la Ribera\"",
                "address_postcode": "\"48903\"",
                "address_state": "\"Europe\"",
                "latitude": 43.29493,
                "longitude": -3.0037,
                "name": "\"Calvin Klein\"",
                "phone": "\"\"",
                "shop": "\"clothes\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "michaels": {
        "__id": 143,
        "id": "michaels",
        "name": "Michaels",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'image', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'website']",
        "rowLength": 1155,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763554,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of michaels locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 633,
                "address_city": "\"Battle Creek\"",
                "address_country": "\"US\"",
                "address_postcode": "\"49014-7556\"",
                "address_state": "\"MI\"",
                "image": "\"https://www.michaels.com/static/on/demandware.static/Sites-MichaelsUS-Site/-/default/dw05541f0f/images/store-front.png\"",
                "latitude": 42.262916,
                "longitude": -85.178181,
                "name": "\"Harper Village\"",
                "opening_hours": "\"Mo-Sa 09:00-21:00; Su 10:00-19:00\"",
                "phone": "\"+12699798771\"",
                "shop": "\"craft\"",
                "street_address": "\"12765 Harper Village Dr\"",
                "website": "\"https://locations.michaels.com/mi/battle-creek/1537\""
            },
            {
                "__id": 321,
                "address_city": "\"Keizer\"",
                "address_country": "\"US\"",
                "address_postcode": "\"97303-1695\"",
                "address_state": "\"OR\"",
                "image": "\"https://www.michaels.com/static/on/demandware.static/Sites-MichaelsUS-Site/-/default/dw05541f0f/images/store-front.png\"",
                "latitude": 45.012641,
                "longitude": -122.998019,
                "name": "\"Keizer Station Village Center\"",
                "opening_hours": "\"Mo-Sa 09:00-21:00; Su 10:00-19:00\"",
                "phone": "\"+15033930260\"",
                "shop": "\"craft\"",
                "street_address": "\"6445 Keizer Station Blvd NE\"",
                "website": "\"https://locations.michaels.com/or/keizer/7814\""
            },
            {
                "__id": 83,
                "address_city": "\"Roanoke\"",
                "address_country": "\"US\"",
                "address_postcode": "\"24012\"",
                "address_state": "\"VA\"",
                "image": "\"https://www.michaels.com/static/on/demandware.static/Sites-MichaelsUS-Site/-/default/dw05541f0f/images/store-front.png\"",
                "latitude": 37.306588,
                "longitude": -79.962201,
                "name": "\"Valley View Shopping Center\"",
                "opening_hours": "\"Mo-Sa 09:00-21:00; Su 10:00-19:00\"",
                "phone": "\"+15406138885\"",
                "shop": "\"craft\"",
                "street_address": "\"1920 Valley View Blvd NW, Ste 1\"",
                "website": "\"https://locations.michaels.com/va/roanoke/5123\""
            },
            {
                "__id": 668,
                "address_city": "\"Worcester\"",
                "address_country": "\"US\"",
                "address_postcode": "\"01605-1905\"",
                "address_state": "\"MA\"",
                "image": "\"https://www.michaels.com/static/on/demandware.static/Sites-MichaelsUS-Site/-/default/dw05541f0f/images/store-front.png\"",
                "latitude": 42.293172,
                "longitude": -71.771106,
                "name": "\"Lincoln Plaza\"",
                "opening_hours": "\"Mo-Sa 09:00-21:00; Su 10:00-19:00\"",
                "phone": "\"+15085711300\"",
                "shop": "\"craft\"",
                "street_address": "\"541 Lincoln St\"",
                "website": "\"https://locations.michaels.com/ma/worcester/8371\""
            },
            {
                "__id": 616,
                "address_city": "\"Midland\"",
                "address_country": "\"US\"",
                "address_postcode": "\"48642-8903\"",
                "address_state": "\"MI\"",
                "image": "\"https://www.michaels.com/static/on/demandware.static/Sites-MichaelsUS-Site/-/default/dw05541f0f/images/store-front.png\"",
                "latitude": 43.660076,
                "longitude": -84.237443,
                "name": "\"Midland Phase II\"",
                "opening_hours": "\"Mo-Sa 09:00-21:00; Su 10:00-19:00\"",
                "phone": "\"+19898394373\"",
                "shop": "\"craft\"",
                "street_address": "\"909 Joe Mann Blvd\"",
                "website": "\"https://locations.michaels.com/mi/midland/1575\""
            },
            {
                "__id": 438,
                "address_city": "\"Reno\"",
                "address_country": "\"US\"",
                "address_postcode": "\"89509-6549\"",
                "address_state": "\"NV\"",
                "image": "\"https://www.michaels.com/static/on/demandware.static/Sites-MichaelsUS-Site/-/default/dw05541f0f/images/store-front.png\"",
                "latitude": 39.479622,
                "longitude": -119.79367,
                "name": "\"Fire Creek Crossing\"",
                "opening_hours": "\"Mo-Sa 09:00-21:00; Su 10:00-19:00\"",
                "phone": "\"+17758298000\"",
                "shop": "\"craft\"",
                "street_address": "\"4871 Kietzke Ln\"",
                "website": "\"https://locations.michaels.com/nv/reno/5801\""
            },
            {
                "__id": 42,
                "address_city": "\"Veradale\"",
                "address_country": "\"US\"",
                "address_postcode": "\"99037-9544\"",
                "address_state": "\"WA\"",
                "image": "\"https://www.michaels.com/static/on/demandware.static/Sites-MichaelsUS-Site/-/default/dw05541f0f/images/store-front.png\"",
                "latitude": 47.665303,
                "longitude": -117.195274,
                "name": "\"Spokane Valley Plaza\"",
                "opening_hours": "\"Mo-Sa 09:00-21:00; Su 10:00-19:00\"",
                "phone": "\"+15098930543\"",
                "shop": "\"craft\"",
                "street_address": "\"15521 E Broadway Ave\"",
                "website": "\"https://locations.michaels.com/wa/veradale/9973\""
            },
            {
                "__id": 153,
                "address_city": "\"Plano\"",
                "address_country": "\"US\"",
                "address_postcode": "\"75075-8838\"",
                "address_state": "\"TX\"",
                "image": "\"https://www.michaels.com/static/on/demandware.static/Sites-MichaelsUS-Site/-/default/dw05541f0f/images/store-front.png\"",
                "latitude": 33.020574,
                "longitude": -96.715784,
                "name": "\"Creekwalk Village\"",
                "opening_hours": "\"Mo-Sa 09:00-21:00; Su 10:00-19:00\"",
                "phone": "\"+19724229990\"",
                "shop": "\"craft\"",
                "street_address": "\"801 W 15th St, Ste A\"",
                "website": "\"https://locations.michaels.com/tx/plano/9833\""
            },
            {
                "__id": 515,
                "address_city": "\"Raleigh\"",
                "address_country": "\"US\"",
                "address_postcode": "\"27617-7598\"",
                "address_state": "\"NC\"",
                "image": "\"https://www.michaels.com/static/on/demandware.static/Sites-MichaelsUS-Site/-/default/dw05541f0f/images/store-front.png\"",
                "latitude": 35.910683,
                "longitude": -78.783607,
                "name": "\"Brier Creek Shopping Center\"",
                "opening_hours": "\"Mo-Sa 09:00-21:00; Su 10:00-19:00\"",
                "phone": "\"+19198062736\"",
                "shop": "\"craft\"",
                "street_address": "\"8131 Brier Creek Pkwy\"",
                "website": "\"https://locations.michaels.com/nc/raleigh/2736\""
            },
            {
                "__id": 406,
                "address_city": "\"Nanuet\"",
                "address_country": "\"US\"",
                "address_postcode": "\"10954-2956\"",
                "address_state": "\"NY\"",
                "image": "\"https://www.michaels.com/static/on/demandware.static/Sites-MichaelsUS-Site/-/default/dw05541f0f/images/store-front.png\"",
                "latitude": 41.0915158,
                "longitude": -74.0025609,
                "name": "\"Rockland Center\"",
                "opening_hours": "\"Mo-Sa 09:00-21:00; Su 10:00-19:00\"",
                "phone": "\"+18456713100\"",
                "shop": "\"craft\"",
                "street_address": "\"165 Rockland Center\"",
                "website": "\"https://locations.michaels.com/ny/nanuet/8353\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "wingstop": {
        "__id": 144,
        "id": "wingstop",
        "name": "Wingstop",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'phone', 'takeaway']",
        "rowLength": 1706,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763555,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of wingstop locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 1231,
                "address_city": "\"Philadelphia\"",
                "address_country": "\"US\"",
                "address_full": "\"3300 Fairmount Ave. K30\"",
                "address_postcode": "\"19104\"",
                "address_state": "\"PA\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"wings\"",
                "latitude": 39.9661569,
                "longitude": -75.1910622,
                "name": "\"Wingstop Philadelphia Fairmount Ave ~ Fair Foods\"",
                "phone": "\"(215) 259-3227\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 1077,
                "address_city": "\"Nashville\"",
                "address_country": "\"US\"",
                "address_full": "\"7088-C Charlotte Pike\"",
                "address_postcode": "\"37209\"",
                "address_state": "\"TN\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"wings\"",
                "latitude": 36.1291466,
                "longitude": -86.9073493,
                "name": "\"Wingstop Nashville Charlotte Pike\"",
                "phone": "\"(615) 352-0088\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 983,
                "address_city": "\"Maywood\"",
                "address_country": "\"US\"",
                "address_full": "\"4442 Slauson Ave\"",
                "address_postcode": "\"90270\"",
                "address_state": "\"CA\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"wings\"",
                "latitude": 33.9863848,
                "longitude": -118.1869044,
                "name": "\"Wingstop Maywood Slauson Ave\"",
                "phone": "\"(323) 773-9464\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 76,
                "address_city": "\"Arlington\"",
                "address_country": "\"US\"",
                "address_full": "\"4407 Little Rd Ste 660\"",
                "address_postcode": "\"76016\"",
                "address_state": "\"TX\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"wings\"",
                "latitude": 32.6756899,
                "longitude": -97.1972373,
                "name": "\"Wingstop Arlington Little Rd\"",
                "phone": "\"(817) 561-0700\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 946,
                "address_city": "\"Loveland\"",
                "address_country": "\"US\"",
                "address_full": "\"3033 N. Garfield Ave\"",
                "address_postcode": "\"80538\"",
                "address_state": "\"CO\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"wings\"",
                "latitude": 40.4244007,
                "longitude": -105.0781315,
                "name": "\"Wingstop Loveland N. Garfield Ave\"",
                "phone": "\"(970) 635-9464\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 1043,
                "address_city": "\"Mobile\"",
                "address_country": "\"US\"",
                "address_full": "\"7450 Airport Blvd\"",
                "address_postcode": "\"36608\"",
                "address_state": "\"AL\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"wings\"",
                "latitude": 30.68649,
                "longitude": -88.218188,
                "name": "\"Wingstop Mobile Airport Blvd\"",
                "phone": "\"(251) 639-4700\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 577,
                "address_city": "\"Fort Lauderdale\"",
                "address_country": "\"US\"",
                "address_full": "\"1571 S. Federal Highway\"",
                "address_postcode": "\"33316\"",
                "address_state": "\"FL\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"wings\"",
                "latitude": 26.102463,
                "longitude": -80.1373298,
                "name": "\"Wingstop Ft Lauderdale S\"",
                "phone": "\"(954) 533-2161\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 1457,
                "address_city": "\"Sarasota\"",
                "address_country": "\"US\"",
                "address_full": "\"8462 Lockwood Ridge Rd\"",
                "address_postcode": "\"34243\"",
                "address_state": "\"FL\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"wings\"",
                "latitude": 27.3905391,
                "longitude": -82.5076035,
                "name": "\"Wingstop Sarasota Lockwood Ridge Rd\"",
                "phone": "\"(941) 867-9100\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 951,
                "address_city": "\"Lufkin\"",
                "address_country": "\"US\"",
                "address_full": "\"4505 S Medford Dr\"",
                "address_postcode": "\"75901\"",
                "address_state": "\"TX\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"wings\"",
                "latitude": 31.3091845,
                "longitude": -94.7233892,
                "name": "\"Wingstop Lufkin\"",
                "phone": "\"(936) 639-9464\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 272,
                "address_city": "\"Chicago\"",
                "address_country": "\"US\"",
                "address_full": "\"120 W. 87th Street\"",
                "address_postcode": "\"60620\"",
                "address_state": "\"IL\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"wings\"",
                "latitude": 41.7365295,
                "longitude": -87.6275487,
                "name": "\"Wingstop Chicago 87th & Dan Ryan Expressway\"",
                "phone": "\"(773) 729-1300\"",
                "takeaway": "\"yes\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "speedee_oil": {
        "__id": 145,
        "id": "speedee_oil",
        "name": "Speedee Oil",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'phone', 'website']",
        "rowLength": 158,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763556,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of speedee_oil locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 3,
                "address_city": "\"Amarillo\"",
                "address_country": "\"US\"",
                "address_full": "\"4500 S Bell St\"",
                "address_postcode": "\"79109\"",
                "address_state": "\"TX\"",
                "latitude": 35.161171,
                "longitude": -101.902756,
                "name": "\"Amarillo, TX - #5885\"",
                "phone": "\"(806) 359-5411\"",
                "website": "\"https://www.speedeeoil.com/amarillo-tx-5885/\""
            },
            {
                "__id": 55,
                "address_city": "\"Greenville\"",
                "address_country": "\"US\"",
                "address_full": "\"126 Greenville Blvd. SE\"",
                "address_postcode": "\"27858\"",
                "address_state": "\"NC\"",
                "latitude": 35.578465,
                "longitude": -77.3797,
                "name": "\"Greenville, NC - #9311\"",
                "phone": "\"(252) 751-6463\"",
                "website": "\"https://www.speedeeoil.com/greenville-nc-9311/\""
            },
            {
                "__id": 1,
                "address_city": "\"Abington\"",
                "address_country": "\"US\"",
                "address_full": "\"740 Brockton Ave.\"",
                "address_postcode": "\"02351\"",
                "address_state": "\"MA\"",
                "latitude": 42.094045,
                "longitude": -70.9678247,
                "name": "\"Abington, MA - #9014\"",
                "phone": "\"(781) 857-2446\"",
                "website": "\"https://www.speedeeoil.com/abington-ma-9014/\""
            },
            {
                "__id": 90,
                "address_city": "\"Modesto\"",
                "address_country": "\"US\"",
                "address_full": "\"1343 Coffee Rd\"",
                "address_postcode": "\"95355\"",
                "address_state": "\"CA\"",
                "latitude": 37.663322,
                "longitude": -120.976021,
                "name": "\"Modesto, CA - #7065\"",
                "phone": "\"(209) 521-1929\"",
                "website": "\"https://www.speedeeoil.com/modesto-ca-7065/\""
            },
            {
                "__id": 53,
                "address_city": "\"Gardner\"",
                "address_country": "\"US\"",
                "address_full": "\"15 Donlan St\"",
                "address_postcode": "\"01440\"",
                "address_state": "\"MA\"",
                "latitude": 42.5681109,
                "longitude": -71.9924702,
                "name": "\"Gardner, MA - #7046\"",
                "phone": "\"(978) 630-1511\"",
                "website": "\"https://www.speedeeoil.com/gardner-ma-7046/\""
            },
            {
                "__id": 101,
                "address_city": "\"Oklahoma City\"",
                "address_country": "\"US\"",
                "address_full": "\"8613 N. Council Rd.\"",
                "address_postcode": "\"73132\"",
                "address_state": "\"OK\"",
                "latitude": 35.5588336,
                "longitude": -97.6567813,
                "name": "\"Oklahoma City, OK - #8051\"",
                "phone": "\"(405) 384-5823\"",
                "website": "\"https://www.speedeeoil.com/oklahoma-city-ok-8051/\""
            },
            {
                "__id": 16,
                "address_city": "\"Canton\"",
                "address_country": "\"US\"",
                "address_full": "\"3610 Marietta Hwy\"",
                "address_postcode": "\"30114\"",
                "address_state": "\"GA\"",
                "latitude": 34.197582,
                "longitude": -84.509331,
                "name": "\"Canton, GA - #1083\"",
                "phone": "\"(678) 880-3134\"",
                "website": "\"https://www.speedeeoil.com/canton-ga-1083/\""
            },
            {
                "__id": 106,
                "address_city": "\"Providence\"",
                "address_country": "\"US\"",
                "address_full": "\"985 N Main St\"",
                "address_postcode": "\"02904\"",
                "address_state": "\"RI\"",
                "latitude": 41.847736,
                "longitude": -71.404007,
                "name": "\"Providence, RI - #9051\"",
                "phone": "\"(401) 453-4707\"",
                "website": "\"https://www.speedeeoil.com/providence-ri-9051/\""
            },
            {
                "__id": 7,
                "address_city": "\"Attleboro\"",
                "address_country": "\"US\"",
                "address_full": "\"30 N Main St\"",
                "address_postcode": "\"02703\"",
                "address_state": "\"MA\"",
                "latitude": 41.944984,
                "longitude": -71.285309,
                "name": "\"Attleboro, MA - #7070\"",
                "phone": "\"(508) 226-1465\"",
                "website": "\"https://www.speedeeoil.com/attleboro-ma-7070/\""
            },
            {
                "__id": 76,
                "address_city": "\"Lincoln\"",
                "address_country": "\"US\"",
                "address_full": "\"3730 Cornhusker Highway\"",
                "address_postcode": "\"68504\"",
                "address_state": "\"NE\"",
                "latitude": 40.8449009,
                "longitude": -96.6673252,
                "name": "\"Lincoln, NE - #8048\"",
                "phone": "\"(402) 467-1233\"",
                "website": "\"https://www.speedeeoil.com/lincoln-ne-8048/\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "fastrac": {
        "__id": 146,
        "id": "fastrac",
        "name": "FasTrac",
        "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone']",
        "rowLength": 55,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763557,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of fastrac locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 7,
                "address_city": "\"Camillus\"",
                "address_full": "\"507 Hinsdale Rd.\"",
                "address_postcode": "\"13031\"",
                "address_state": "\"NY\"",
                "latitude": 43.0531364,
                "longitude": -76.26619310000001,
                "name": "\"Camillus Fastrac\"",
                "opening_hours": "\"5AM \u2013 10PM\"",
                "phone": "\"680-204-2484\""
            },
            {
                "__id": 6,
                "address_city": "\"Camden\"",
                "address_full": "\"24 Main St.\"",
                "address_postcode": "\"13316\"",
                "address_state": "\"NY\"",
                "latitude": 43.333353,
                "longitude": -75.74836199999999,
                "name": "\"Camden Fastrac\"",
                "opening_hours": "\"5AM \u2013 MIDNIGHT\"",
                "phone": "\"315-245-0539\""
            },
            {
                "__id": 19,
                "address_city": "\"Geneva\"",
                "address_full": "\"371 Hamilton St.\"",
                "address_postcode": "\"14456\"",
                "address_state": "\"NY\"",
                "latitude": 42.8577967,
                "longitude": -76.99768089999998,
                "name": "\"Geneva Fastrac\"",
                "opening_hours": "\"5AM \u2013 10PM\"",
                "phone": "\"315-789-5098\""
            },
            {
                "__id": 28,
                "address_city": "\"Liverpool\"",
                "address_full": "\"7189 Oswego Rd.\"",
                "address_postcode": "\"13090\"",
                "address_state": "\"NY\"",
                "latitude": 43.11877250000001,
                "longitude": -76.21553690000002,
                "name": "\"Liverpool Fastrac\"",
                "opening_hours": "\"4AM \u2013 10PM\"",
                "phone": "\"315-457-0199\""
            },
            {
                "__id": 38,
                "address_city": "\"Oswego\"",
                "address_full": "\"214 West Bridge St.\"",
                "address_postcode": "\"13126\"",
                "address_state": "\"NY\"",
                "latitude": 43.4535791,
                "longitude": -76.5265023,
                "name": "\"Oswego Fastrac\"",
                "opening_hours": "\"6AM \u2013 10PM\"",
                "phone": "\"315-342-2627\""
            },
            {
                "__id": 52,
                "address_city": "\"Watertown\"",
                "address_full": "\"1709 State St.\"",
                "address_postcode": "\"13601\"",
                "address_state": "\"NY\"",
                "latitude": 43.96765,
                "longitude": -75.88092999999998,
                "name": "\"Watertown Fastrac\"",
                "opening_hours": "\"Open 24 Hours\"",
                "phone": "\"315-782-0406\""
            },
            {
                "__id": 12,
                "address_city": "\"Clyde\"",
                "address_full": "\"1 North Park St.\"",
                "address_postcode": "\"14433\"",
                "address_state": "\"NY\"",
                "latitude": 43.0845183,
                "longitude": -76.8695851,
                "name": "\"Clyde Fastrac\"",
                "opening_hours": "\"6AM \u2013 10PM\"",
                "phone": "\"315-923-9811\""
            },
            {
                "__id": 13,
                "address_city": "\"Elbridge\"",
                "address_full": "\"110-112 W. Main Street\"",
                "address_postcode": "\"13060\"",
                "address_state": "\"NY\"",
                "latitude": 43.0346595,
                "longitude": -76.449315,
                "name": "\"Elbridge Fastrac\"",
                "opening_hours": "\"6AM \u2013 10PM\"",
                "phone": "\"315-689-3510\""
            },
            {
                "__id": 53,
                "address_city": "\"Watertown\"",
                "address_full": "\"20852 State Route 3\"",
                "address_postcode": "\"13601\"",
                "address_state": "\"NY\"",
                "latitude": 43.974689,
                "longitude": -75.956523,
                "name": "\"Watertown West Fastrac\"",
                "opening_hours": "\"6AM \u2013 11PM\"",
                "phone": "\"(315) 786-1063\""
            },
            {
                "__id": 14,
                "address_city": "\"Elmira Heights\"",
                "address_full": "\"2204 College Ave.\"",
                "address_postcode": "\"14903\"",
                "address_state": "\"NY\"",
                "latitude": 42.1315363,
                "longitude": -76.8272299,
                "name": "\"Elmira Heights Fastrac\"",
                "opening_hours": "\"5AM \u2013 10PM\"",
                "phone": "\"607.732.1930\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "afcurgentcare": {
        "__id": 147,
        "id": "afcurgentcare",
        "name": "AFC Urgent Care",
        "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'website']",
        "rowLength": 386,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763557,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of afcurgentcare locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 245,
                "address_city": "\"Suffolk\"",
                "address_full": "\"1013 University Blvd., Suite 270\"",
                "address_postcode": "\"23435\"",
                "address_state": "\"VA\"",
                "latitude": 36.8735034,
                "longitude": -76.4225649,
                "name": "\"Suffolk\"",
                "opening_hours": "\"Mo-Fr 08:00-20:00; Sa-Su 08:00-17:00\"",
                "phone": "\"+17573354755\"",
                "website": "\"https://www.afcurgentcare.com/suffolk/\""
            },
            {
                "__id": 94,
                "address_city": "\"Frankfort\"",
                "address_full": "\"\"",
                "address_postcode": "\"\"",
                "address_state": "\"KY\"",
                "latitude": 38.2009055,
                "longitude": -84.87328350000001,
                "name": "\"Kentucky\"",
                "opening_hours": "\"\"",
                "phone": "\"\"",
                "website": "\"https://www.afcurgentcare.com/kentucky/\""
            },
            {
                "__id": 123,
                "address_city": "\"Athens\"",
                "address_full": "\"2037 S. Congress Parkway\"",
                "address_postcode": "\"37303\"",
                "address_state": "\"TN\"",
                "latitude": 35.444117,
                "longitude": -84.6311622,
                "name": "\"Athens, TN\"",
                "opening_hours": "\"Mo-Fr 08:00-20:00; Sa-Su 08:00-17:00\"",
                "phone": "\"+14233810152\"",
                "website": "\"https://www.afcurgentcare.com/athens-tn/\""
            },
            {
                "__id": 9,
                "address_city": "\"Aurora\"",
                "address_full": "\"7460 S Gartrell Rd\"",
                "address_postcode": "\"80016\"",
                "address_state": "\"CO\"",
                "latitude": 39.5814138,
                "longitude": -104.7242494,
                "name": "\"Aurora Saddle Rock\"",
                "opening_hours": "\"Mo-Su 08:00-20:00\"",
                "phone": "\"+13035293300\"",
                "website": "\"https://www.afcurgentcare.com/aurora-saddle-rock/\""
            },
            {
                "__id": 274,
                "address_city": "\"Philadelphia\"",
                "address_full": "\"\"",
                "address_postcode": "\"19019\"",
                "address_state": "\"PA\"",
                "latitude": 40.11971279999999,
                "longitude": -75.0097103,
                "name": "\"Philadelphia\"",
                "opening_hours": "\"Mo-Fr 08:00-20:00; Sa-Su 08:00-17:00\"",
                "phone": "\"\"",
                "website": "\"https://www.afcurgentcare.com/philadelphia/\""
            },
            {
                "__id": 327,
                "address_city": "\"Danbury\"",
                "address_full": "\"76c Newtown Road\"",
                "address_postcode": "\"06810\"",
                "address_state": "\"CT\"",
                "latitude": 41.4082124,
                "longitude": -73.416377,
                "name": "\"East Danbury\"",
                "opening_hours": "\"Mo-Fr 08:00-20:00; Sa-Su 08:00-17:00\"",
                "phone": "\"+12038268434\"",
                "website": "\"https://www.afcurgentcare.com/east-danbury/\""
            },
            {
                "__id": 244,
                "address_city": "\"Granada Hills\"",
                "address_full": "\"16912 San Fernando Mission Blvd.\"",
                "address_postcode": "\"91344\"",
                "address_state": "\"CA\"",
                "latitude": 34.2709742,
                "longitude": -118.5004351,
                "name": "\"Granada Hills\"",
                "opening_hours": "\"Mo-Fr 08:00-20:00; Sa-Su 08:00-17:00\"",
                "phone": "\"+18184886160\"",
                "website": "\"https://www.afcurgentcare.com/granada-hills/\""
            },
            {
                "__id": 171,
                "address_city": "\"Greenville\"",
                "address_full": "\"3213 N. Pleasantburg Dr. Ste E-2\"",
                "address_postcode": "\"29609\"",
                "address_state": "\"SC\"",
                "latitude": 34.8891407,
                "longitude": -82.4003955,
                "name": "\"Cherrydale\"",
                "opening_hours": "\"Mo-Fr 08:00-20:00; Sa-Su 08:00-17:00\"",
                "phone": "\"+18644672005\"",
                "website": "\"https://www.afcurgentcare.com/cherrydale/\""
            },
            {
                "__id": 114,
                "address_city": "\"Chattanooga\"",
                "address_full": "\"3520 Cummings Hwy\"",
                "address_postcode": "\"37419\"",
                "address_state": "\"TN\"",
                "latitude": 35.0182426,
                "longitude": -85.3748583,
                "name": "\"Lookout Valley\"",
                "opening_hours": "\"Mo-Su 08:00-18:00\"",
                "phone": "\"+14238225942\"",
                "website": "\"https://www.afcurgentcare.com/lookout-valley/\""
            },
            {
                "__id": 343,
                "address_city": "\"Portland\"",
                "address_full": "\"25 NW 23rd Place\"",
                "address_postcode": "\"97210\"",
                "address_state": "\"OR\"",
                "latitude": 45.524351,
                "longitude": -122.700221,
                "name": "\"NW Portland\"",
                "opening_hours": "\"Mo-Fr 08:00-20:00; Sa-Su 08:00-18:00\"",
                "phone": "\"+15033056262\"",
                "website": "\"https://www.afcurgentcare.com/nw-portland/\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "toby_carvery_gb": {
        "__id": 148,
        "id": "toby_carvery_gb",
        "name": "Toby Carvery Great Britain",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'image', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'street_address', 'twitter', 'website']",
        "rowLength": 152,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763558,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of toby_carvery_gb locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 121,
                "address_city": "\"Liverpool\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"L9 5BD\"",
                "address_state": "\"Merseyside\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"british\"",
                "image": "\"https://www.tobycarvery.co.uk/content/dam/toby-carvery/images/toby_logo.png\"",
                "latitude": 53.475563,
                "longitude": -2.954188,
                "name": "\"Toby Carvery Aintree Aintree\"",
                "opening_hours": "\"Mo-Su 08:00-22:00\"",
                "phone": "\"0151 524 2602\"",
                "street_address": "\"Grand National Avenue\"",
                "twitter": "\"@tobycarvery\"",
                "website": "\"https://www.tobycarvery.co.uk/restaurants/north-west/aintreeliverpool\""
            },
            {
                "__id": 67,
                "address_city": "\"Woodford Green\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"IG8 8LG\"",
                "address_state": "\"Greater London\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"british\"",
                "image": "\"https://www.tobycarvery.co.uk/content/dam/toby-carvery/images/toby_logo.png\"",
                "latitude": 51.591415,
                "longitude": 0.047352,
                "name": "\"Toby Carvery Woodford Essex\"",
                "opening_hours": "\"Mo-Fr 11:30-22:00; Sa-Su 09:00-22:00\"",
                "phone": "\"020 8551 3848\"",
                "street_address": "\"1 Southend Road\"",
                "twitter": "\"@tobycarvery\"",
                "website": "\"https://www.tobycarvery.co.uk/restaurants/london/woodfordgreenessex\""
            },
            {
                "__id": 149,
                "address_city": "\"Rotherham\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"S65 1EN\"",
                "address_state": "\"\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"british\"",
                "image": "\"https://www.tobycarvery.co.uk/content/dam/toby-carvery/images/toby_logo.png\"",
                "latitude": 53.442685,
                "longitude": -1.322858,
                "name": "\"Toby Carvery Rotherham Rotherham\"",
                "opening_hours": "\"Mo-Th 11:30-22:00; Fr 11:00-22:00; Sa-Su 08:00-22:00\"",
                "phone": "\"01709 369925\"",
                "street_address": "\"Sycamore Road, Eastwood Trading Estate\"",
                "twitter": "\"@tobycarvery\"",
                "website": "\"https://www.tobycarvery.co.uk/restaurants/yorkshire/rotherhamsouthyorkshire\""
            },
            {
                "__id": 36,
                "address_city": "\"Gloucester\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"GL2 8DE\"",
                "address_state": "\"Gloucestershire\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"british\"",
                "image": "\"https://www.tobycarvery.co.uk/content/dam/toby-carvery/images/toby_logo.png\"",
                "latitude": 51.874222,
                "longitude": -2.27372,
                "name": "\"Toby Carvery Highnam Gloucestershire\"",
                "opening_hours": "\"Mo-Su 08:00-22:00\"",
                "phone": "\"01452 521191\"",
                "street_address": "\"Over\"",
                "twitter": "\"@tobycarvery\"",
                "website": "\"https://www.tobycarvery.co.uk/restaurants/south-west/highnamgloucestershire\""
            },
            {
                "__id": 62,
                "address_city": "\"Enfield\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"EN1 3PN\"",
                "address_state": "\"Greater London\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"british\"",
                "image": "\"https://www.tobycarvery.co.uk/content/dam/toby-carvery/images/toby_logo.png\"",
                "latitude": 51.65972,
                "longitude": -0.059343,
                "name": "\"Toby Carvery Enfield Enfield\"",
                "opening_hours": "\"Mo-Su 08:00-22:00\"",
                "phone": "\"020 8367 4290\"",
                "street_address": "\"801 Great Cambridge Road\"",
                "twitter": "\"@tobycarvery\"",
                "website": "\"https://www.tobycarvery.co.uk/restaurants/london/thehalfwayhouseenfield\""
            },
            {
                "__id": 21,
                "address_city": "\"Barry\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"CF62 3BA\"",
                "address_state": "\"South Glamorgan\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"british\"",
                "image": "\"https://www.tobycarvery.co.uk/content/dam/toby-carvery/images/toby_logo.png\"",
                "latitude": 51.407845,
                "longitude": -3.304606,
                "name": "\"Toby Carvery Rhoose Barry\"",
                "opening_hours": "\"Mo-Su 08:00-22:00\"",
                "phone": "\"01446 700075\"",
                "street_address": "\"Port Road West\"",
                "twitter": "\"@tobycarvery\"",
                "website": "\"https://www.tobycarvery.co.uk/restaurants/wales/rhoosebarry\""
            },
            {
                "__id": 97,
                "address_city": "\"Derby\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"DE21 6LZ\"",
                "address_state": "\"Derbyshire\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"british\"",
                "image": "\"https://www.tobycarvery.co.uk/content/dam/toby-carvery/images/toby_logo.png\"",
                "latitude": 52.924182,
                "longitude": -1.437733,
                "name": "\"Toby Carvery Chaddesden Derby\"",
                "opening_hours": "\"Mo-Th 08:00-23:00; Fr-Sa 08:00-24:00; Su 08:00-23:00\"",
                "phone": "\"01332 662504\"",
                "street_address": "\"Nottingham Road\"",
                "twitter": "\"@tobycarvery\"",
                "website": "\"https://www.tobycarvery.co.uk/restaurants/midlands/chaddesdenderby\""
            },
            {
                "__id": 125,
                "address_city": "\"Widnes\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"WA8 0TA\"",
                "address_state": "\"Cheshire\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"british\"",
                "image": "\"https://www.tobycarvery.co.uk/content/dam/toby-carvery/images/toby_logo.png\"",
                "latitude": 53.364247,
                "longitude": -2.721727,
                "name": "\"Toby Carvery Widnes Widnes\"",
                "opening_hours": "\"Mo-Su 08:00-22:00\"",
                "phone": "\"0151 257 9790\"",
                "street_address": "\"Bowers Park Business Estate, Earle Road\"",
                "twitter": "\"@tobycarvery\"",
                "website": "\"https://www.tobycarvery.co.uk/restaurants/north-west/bowersparkwidnes\""
            },
            {
                "__id": 140,
                "address_city": "\"Huddersfield\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"HD2 2LB\"",
                "address_state": "\"West Yorkshire\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"british\"",
                "image": "\"https://www.tobycarvery.co.uk/content/dam/toby-carvery/images/toby_logo.png\"",
                "latitude": 53.670582,
                "longitude": -1.825294,
                "name": "\"Toby Carvery Huddersfield Huddersfield\"",
                "opening_hours": "\"Mo-Su 08:00-22:00\"",
                "phone": "\"01422 374360\"",
                "street_address": "\"Brighouse Road\"",
                "twitter": "\"@tobycarvery\"",
                "website": "\"https://www.tobycarvery.co.uk/restaurants/yorkshire/ainleytophuddersfield\""
            },
            {
                "__id": 35,
                "address_city": "\"Gloucester\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"GL3 4PH\"",
                "address_state": "\"Gloucestershire\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"british\"",
                "image": "\"https://www.tobycarvery.co.uk/content/dam/toby-carvery/images/toby_logo.png\"",
                "latitude": 51.84252,
                "longitude": -2.148462,
                "name": "\"Toby Carvery Brockworth Gloucester\"",
                "opening_hours": "\"Mo-Su 08:00-22:00\"",
                "phone": "\"01452 863441\"",
                "street_address": "\"Shurdington Road, Brockworth\"",
                "twitter": "\"@tobycarvery\"",
                "website": "\"https://www.tobycarvery.co.uk/restaurants/south-west/brockworthgloucester\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "whitehouseblackmarket": {
        "__id": 149,
        "id": "whitehouseblackmarket",
        "name": "White House Black Market",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop']",
        "rowLength": 476,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763559,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of whitehouseblackmarket locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 172,
                "address_city": "\"Buford\"",
                "address_country": "\"US\"",
                "address_full": "\"3333 Buford Dr, Ste 2027\"",
                "address_postcode": "\"30519\"",
                "address_state": "\"GA\"",
                "latitude": 34.0651493,
                "longitude": -83.9847659,
                "name": "\"Mall of Georgia\"",
                "opening_hours": "\"Mo-Th 11:00-20:00; Fr-Sa 10:00-21:00; Su 11:00-19:00\"",
                "phone": "\"(770) 271-3676\"",
                "shop": "\"clothes\""
            },
            {
                "__id": 411,
                "address_city": "\"Bend\"",
                "address_country": "\"US\"",
                "address_full": "\"325 SW Powerhouse Drive, Suite 110\"",
                "address_postcode": "\"97702\"",
                "address_state": "\"OR\"",
                "latitude": 44.0472701,
                "longitude": -121.3151947,
                "name": "\"Old Mill District\"",
                "opening_hours": "\"Mo-Sa 10:00-19:00; Su 11:00-18:00\"",
                "phone": "\"(541) 385-6119\"",
                "shop": "\"clothes\""
            },
            {
                "__id": 432,
                "address_city": "\"Happy Valley\"",
                "address_country": "\"US\"",
                "address_full": "\"11860 SE 82nd Ave, Ste 3005\"",
                "address_postcode": "\"97086\"",
                "address_state": "\"OR\"",
                "latitude": 45.4350433,
                "longitude": -122.5750535,
                "name": "\"Clackamas Town Center\"",
                "opening_hours": "\"Mo-Sa 10:00-21:00; Su 12:00-18:00\"",
                "phone": "\"(503) 659-5779\"",
                "shop": "\"clothes\""
            },
            {
                "__id": 412,
                "address_city": "\"Reno\"",
                "address_country": "\"US\"",
                "address_full": "\"13945 S Virginia St, Ste 608\"",
                "address_postcode": "\"89511\"",
                "address_state": "\"NV\"",
                "latitude": 39.401693,
                "longitude": -119.7519223,
                "name": "\"The Summit Reno\"",
                "opening_hours": "\"Mo-Sa 10:00-18:00; Su 11:00-18:00\"",
                "phone": "\"(775) 852-8584\"",
                "shop": "\"clothes\""
            },
            {
                "__id": 89,
                "address_city": "\"Burlington\"",
                "address_country": "\"CA\"",
                "address_full": "\"900 Maple Avenue, Ste B12A\"",
                "address_postcode": "\"L7S 2J8\"",
                "address_state": "\"ON\"",
                "latitude": 43.325868,
                "longitude": -79.820266,
                "name": "\"Mapleview Shopping Centre\"",
                "opening_hours": "\"Mo-Sa 10:00-18:00; Su 12:00-18:00\"",
                "phone": "\"(905) 681-7166\"",
                "shop": "\"clothes\""
            },
            {
                "__id": 44,
                "address_city": "\"Hingham\"",
                "address_country": "\"US\"",
                "address_full": "\"98 Derby Street, Ste 251\"",
                "address_postcode": "\"02043\"",
                "address_state": "\"MA\"",
                "latitude": 42.1775627,
                "longitude": -70.9095583,
                "name": "\"Derby Street Shoppes\"",
                "opening_hours": "\"Mo-Sa 10:00-20:00; Su 11:00-18:00\"",
                "phone": "\"(781) 740-9572\"",
                "shop": "\"clothes\""
            },
            {
                "__id": 426,
                "address_city": "\"Irvine\"",
                "address_country": "\"US\"",
                "address_full": "\"768 Spectrum Center Drive\"",
                "address_postcode": "\"92618\"",
                "address_state": "\"CA\"",
                "latitude": 33.6494709,
                "longitude": -117.7430412,
                "name": "\"Irvine Spectrum Center\"",
                "opening_hours": "\"Mo-Th 10:00-21:00; Fr-Sa 10:00-22:00; Su 10:00-21:00\"",
                "phone": "\"(949) 450-9284\"",
                "shop": "\"clothes\""
            },
            {
                "__id": 185,
                "address_city": "\"Oak Brook\"",
                "address_country": "\"US\"",
                "address_full": "\"74 Oakbrook Center\"",
                "address_postcode": "\"60523\"",
                "address_state": "\"IL\"",
                "latitude": 41.8507981,
                "longitude": -87.9505368,
                "name": "\"Oakbrook Center\"",
                "opening_hours": "\"Mo-Th 10:00-19:00; Fr-Sa 10:00-20:00; Su 11:00-18:00\"",
                "phone": "\"(630) 368-1870\"",
                "shop": "\"clothes\""
            },
            {
                "__id": 272,
                "address_city": "\"Mobile\"",
                "address_country": "\"US\"",
                "address_full": "\"9 Du Rhu Drive, Ste 358\"",
                "address_postcode": "\"36608\"",
                "address_state": "\"AL\"",
                "latitude": 30.6891546,
                "longitude": -88.1311033,
                "name": "\"Legacy Village at Spring Hill\"",
                "opening_hours": "\"Mo-Sa 10:00-18:00; Su 12:00-17:00\"",
                "phone": "\"(251) 725-5394\"",
                "shop": "\"clothes\""
            },
            {
                "__id": 17,
                "address_city": "\"Lancaster\"",
                "address_country": "\"US\"",
                "address_full": "\"106 Stanley K. Tanger Boulevard\"",
                "address_postcode": "\"17602\"",
                "address_state": "\"PA\"",
                "latitude": 40.0268386,
                "longitude": -76.2227421,
                "name": "\"Tanger Outlet Center Lancaster\"",
                "opening_hours": "\"Mo-Sa 09:00-21:00; Su 10:00-19:00\"",
                "phone": "\"(717) 553-7498\"",
                "shop": "\"clothes\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "nordstrom": {
        "__id": 150,
        "id": "nordstrom",
        "name": "Nordstrom",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'phone', 'shop', 'street_address', 'type', 'website']",
        "rowLength": 111,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763560,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of nordstrom locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 95,
                "address_city": "\"Portland\"",
                "address_country": "\"US\"",
                "address_postcode": "\"97205\"",
                "address_state": "\"OR\"",
                "latitude": 45.5191726,
                "longitude": -122.6803729,
                "name": "\"Nordstrom Downtown Portland\"",
                "phone": "\"503.224.6666\"",
                "shop": "\"department_store\"",
                "street_address": "\"701 SW Broadway\"",
                "type": "\"FullLineStore\"",
                "website": "\"https://www.nordstrom.com/store-details/nordstrom-downtown-portland\""
            },
            {
                "__id": 88,
                "address_city": "\"Lone Tree\"",
                "address_country": "\"US\"",
                "address_postcode": "\"80124\"",
                "address_state": "\"CO\"",
                "latitude": 39.5613293,
                "longitude": -104.8749196,
                "name": "\"Nordstrom Park Meadows\"",
                "phone": "\"303.799.3400\"",
                "shop": "\"department_store\"",
                "street_address": "\"8465 Park Meadows Center Drive\"",
                "type": "\"FullLineStore\"",
                "website": "\"https://www.nordstrom.com/store-details/nordstrom-park-meadows\""
            },
            {
                "__id": 33,
                "address_city": "\"Canoga Park\"",
                "address_country": "\"US\"",
                "address_postcode": "\"91303\"",
                "address_state": "\"CA\"",
                "latitude": 34.1887114,
                "longitude": -118.6026661,
                "name": "\"Nordstrom Topanga\"",
                "phone": "\"818.884.7900\"",
                "shop": "\"department_store\"",
                "street_address": "\"21725 Victory Boulevard\"",
                "type": "\"FullLineStore\"",
                "website": "\"https://www.nordstrom.com/store-details/nordstrom-topanga\""
            },
            {
                "__id": 41,
                "address_city": "\"New York\"",
                "address_country": "\"US\"",
                "address_postcode": "\"10011\"",
                "address_state": "\"NY\"",
                "latitude": 40.7369182,
                "longitude": -74.0006617,
                "name": "\"Nordstrom Local West Village\"",
                "phone": "\"332.204.8965\"",
                "shop": "\"department_store\"",
                "street_address": "\"13 7th Ave\"",
                "type": "\"NordstromLocal\"",
                "website": "\"https://www.nordstrom.com/store-details/nordstrom-local-west-village\""
            },
            {
                "__id": 109,
                "address_city": "\"Des Peres\"",
                "address_country": "\"US\"",
                "address_postcode": "\"63131\"",
                "address_state": "\"MO\"",
                "latitude": 38.6008751,
                "longitude": -90.44793899999999,
                "name": "\"Nordstrom West County\"",
                "phone": "\"314.255.2000\"",
                "shop": "\"department_store\"",
                "street_address": "\"47 West County Center\"",
                "type": "\"FullLineStore\"",
                "website": "\"https://www.nordstrom.com/store-details/nordstrom-west-county\""
            },
            {
                "__id": 69,
                "address_city": "\"Pittsburgh\"",
                "address_country": "\"US\"",
                "address_postcode": "\"15237\"",
                "address_state": "\"PA\"",
                "latitude": 40.5444283,
                "longitude": -80.0083315,
                "name": "\"Nordstrom Ross Park\"",
                "phone": "\"412.548.4300\"",
                "shop": "\"department_store\"",
                "street_address": "\"1000 Ross Park Mall Drive\"",
                "type": "\"FullLineStore\"",
                "website": "\"https://www.nordstrom.com/store-details/nordstrom-ross-park\""
            },
            {
                "__id": 98,
                "address_city": "\"Murray\"",
                "address_country": "\"US\"",
                "address_postcode": "\"84107\"",
                "address_state": "\"UT\"",
                "latitude": 40.6368125,
                "longitude": -111.8871581,
                "name": "\"Nordstrom Fashion Place\"",
                "phone": "\"801.261.4402\"",
                "shop": "\"department_store\"",
                "street_address": "\"6191 South State Street, Suite 200\"",
                "type": "\"FullLineStore\"",
                "website": "\"https://www.nordstrom.com/store-details/nordstrom-fashion-place\""
            },
            {
                "__id": 80,
                "address_city": "\"Atlanta\"",
                "address_country": "\"US\"",
                "address_postcode": "\"30346\"",
                "address_state": "\"GA\"",
                "latitude": 33.9236807,
                "longitude": -84.34241279999999,
                "name": "\"Nordstrom Perimeter Mall\"",
                "phone": "\"770.394.1141\"",
                "shop": "\"department_store\"",
                "street_address": "\"4390 Ashford Dunwoody Road NE\"",
                "type": "\"FullLineStore\"",
                "website": "\"https://www.nordstrom.com/store-details/nordstrom-perimeter-mall\""
            },
            {
                "__id": 57,
                "address_city": "\"Cherry Hill\"",
                "address_country": "\"US\"",
                "address_postcode": "\"08002\"",
                "address_state": "\"NJ\"",
                "latitude": 39.938872,
                "longitude": -75.025279,
                "name": "\"Nordstrom Cherry Hill\"",
                "phone": "\"856.773.5600\"",
                "shop": "\"department_store\"",
                "street_address": "\"2000 Route 38\"",
                "type": "\"FullLineStore\"",
                "website": "\"https://www.nordstrom.com/store-details/nordstrom-cherry-hill\""
            },
            {
                "__id": 13,
                "address_city": "\"San Diego\"",
                "address_country": "\"US\"",
                "address_postcode": "\"92122\"",
                "address_state": "\"CA\"",
                "latitude": 32.870919,
                "longitude": -117.2130392,
                "name": "\"Nordstrom La Jolla at UTC\"",
                "phone": "\"858.457.4575\"",
                "shop": "\"department_store\"",
                "street_address": "\"4401 La Jolla Village Drive\"",
                "type": "\"FullLineStore\"",
                "website": "\"https://www.nordstrom.com/store-details/nordstrom-la-jolla-at-utc\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "bananarepublic": {
        "__id": 151,
        "id": "bananarepublic",
        "name": "Banana Republic",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'clothes', 'image', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'website']",
        "rowLength": 433,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763561,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of bananarepublic locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 73,
                "address_city": "\"Nashville\"",
                "address_country": "\"US\"",
                "address_postcode": "\"37214-2434\"",
                "address_state": "\"TN\"",
                "clothes": "\"men;women\"",
                "image": "\"/stores/assets/images/logo.svg\"",
                "latitude": 36.2035066,
                "longitude": -86.6947991,
                "name": "\"Banana Republic\"",
                "opening_hours": "\"Mo-Sa 10:00-20:00; Su 11:00-18:00\"",
                "phone": "\"(615) 678-0768\"",
                "shop": "\"clothes\"",
                "street_address": "\"240 Opry Mills Dr Ste 648\"",
                "website": "\"https://bananarepublic.gap.com/stores/tn/nashville/banana-republic-6276.html\""
            },
            {
                "__id": 264,
                "address_city": "\"Calgary\"",
                "address_country": "\"CA\"",
                "address_postcode": "\"T2P 2Y9\"",
                "address_state": "\"AB\"",
                "clothes": "\"men;women\"",
                "image": "\"/stores/assets/images/logo.svg\"",
                "latitude": 51.0460555,
                "longitude": -114.06984,
                "name": "\"Banana Republic\"",
                "opening_hours": "\"Mo-Th 10:00-18:00; Fr 11:15-17:00; Sa 10:00-18:00; Su 12:00-17:00\"",
                "phone": "\"4032648886\"",
                "shop": "\"clothes\"",
                "street_address": "\"317 7th Ave SW Ste #228\"",
                "website": "\"https://bananarepublic.gapcanada.ca/stores/ab/calgary/the-core-6737\""
            },
            {
                "__id": 414,
                "address_city": "\"Fresno\"",
                "address_country": "\"US\"",
                "address_postcode": "\"93704\"",
                "address_state": "\"CA\"",
                "clothes": "\"men;women\"",
                "image": "\"/stores/assets/images/logo.svg\"",
                "latitude": 36.8109794,
                "longitude": -119.8066538,
                "name": "\"Banana Republic\"",
                "opening_hours": "\"Mo-Sa 10:00-19:00; Su 11:00-18:00\"",
                "phone": "\"(559) 226-3906\"",
                "shop": "\"clothes\"",
                "street_address": "\"742 West Shaw Avenue\"",
                "website": "\"https://bananarepublic.gap.com/stores/ca/fresno/banana-republic-7652.html\""
            },
            {
                "__id": 387,
                "address_city": "\"SANTA BARBARA\"",
                "address_country": "\"US\"",
                "address_postcode": "\"93101-3351\"",
                "address_state": "\"CA\"",
                "clothes": "\"men;women\"",
                "image": "\"/stores/assets/images/logo.svg\"",
                "latitude": 34.4192866,
                "longitude": -119.6993905,
                "name": "\"Banana Republic\"",
                "opening_hours": "\"Mo-Sa 10:00-19:00; Su 11:00-18:00\"",
                "phone": "\"(805) 564-7084\"",
                "shop": "\"clothes\"",
                "street_address": "\"735 State St\"",
                "website": "\"https://bananarepublic.gap.com/stores/ca/santabarbara/banana-republic-7685.html\""
            },
            {
                "__id": 363,
                "address_city": "\"WASHINGTON\"",
                "address_country": "\"US\"",
                "address_postcode": "\"20007\"",
                "address_state": "\"DC\"",
                "clothes": "\"men;women\"",
                "image": "\"/stores/assets/images/logo.svg\"",
                "latitude": 38.9049422,
                "longitude": -77.0630573,
                "name": "\"Banana Republic\"",
                "opening_hours": "\"Mo-Sa 11:00-19:00; Su 12:00-18:00\"",
                "phone": "\"(202) 333-2554\"",
                "shop": "\"clothes\"",
                "street_address": "\"3200 M STREET NW\"",
                "website": "\"https://bananarepublic.gap.com/stores/dc/washington/banana-republic-8084.html\""
            },
            {
                "__id": 352,
                "address_city": "\"Jacksonville\"",
                "address_country": "\"US\"",
                "address_postcode": "\"32246\"",
                "address_state": "\"FL\"",
                "clothes": "\"men;women\"",
                "image": "\"/stores/assets/images/logo.svg\"",
                "latitude": 30.257382,
                "longitude": -81.524994,
                "name": "\"Banana Republic\"",
                "opening_hours": "\"Mo-Sa 10:00-21:00; Su 11:00-18:00\"",
                "phone": "\"(904) 996-2494\"",
                "shop": "\"clothes\"",
                "street_address": "\"4711 River City Drive Suite 131\"",
                "website": "\"https://bananarepublic.gap.com/stores/fl/jacksonville/banana-republic-3167.html\""
            },
            {
                "__id": 162,
                "address_city": "\"Marlton\"",
                "address_country": "\"US\"",
                "address_postcode": "\"08053\"",
                "address_state": "\"NJ\"",
                "clothes": "\"men;women\"",
                "image": "\"/stores/assets/images/logo.svg\"",
                "latitude": 39.8788271,
                "longitude": -74.919718,
                "name": "\"Banana Republic\"",
                "opening_hours": "\"Mo-Sa 10:00-20:00; Su 11:00-18:00\"",
                "phone": "\"(856) 983-1119\"",
                "shop": "\"clothes\"",
                "street_address": "\"500 Route 73 South Ste A-1\"",
                "website": "\"https://bananarepublic.gap.com/stores/nj/marlton/banana-republic-7699.html\""
            },
            {
                "__id": 277,
                "address_city": "\"Hanover\"",
                "address_country": "\"US\"",
                "address_postcode": "\"21076-1380\"",
                "address_state": "\"MD\"",
                "clothes": "\"men;women\"",
                "image": "\"/stores/assets/images/logo.svg\"",
                "latitude": 39.1563099,
                "longitude": -76.7262996,
                "name": "\"Banana Republic\"",
                "opening_hours": "\"Mo-Th 11:00-19:00; Fr-Sa 10:00-20:00; Su 11:00-18:00\"",
                "phone": "\"(443) 755-0153\"",
                "shop": "\"clothes\"",
                "street_address": "\"7000 Arundel Mills Circle Space #448\"",
                "website": "\"https://bananarepublic.gap.com/stores/md/hanover/banana-republic-6278.html\""
            },
            {
                "__id": 150,
                "address_city": "\"Albuquerque\"",
                "address_country": "\"US\"",
                "address_postcode": "\"87110\"",
                "address_state": "\"NM\"",
                "clothes": "\"men;women\"",
                "image": "\"/stores/assets/images/logo.svg\"",
                "latitude": 35.1040709,
                "longitude": -106.5668549,
                "name": "\"Banana Republic\"",
                "opening_hours": "\"Mo-Sa 10:00-19:00; Su 12:00-18:00\"",
                "phone": "\"(505) 881-5074\"",
                "shop": "\"clothes\"",
                "street_address": "\"2240 Q Street NE Suite 10A\"",
                "website": "\"https://bananarepublic.gap.com/stores/nm/albuquerque/banana-republic-6932.html\""
            },
            {
                "__id": 181,
                "address_city": "\"Mebane\"",
                "address_country": "\"US\"",
                "address_postcode": "\"27302-7650\"",
                "address_state": "\"NC\"",
                "clothes": "\"men;women\"",
                "image": "\"/stores/assets/images/logo.svg\"",
                "latitude": 36.0747645,
                "longitude": -79.2663974,
                "name": "\"Banana Republic\"",
                "opening_hours": "\"Mo-Sa 10:00-21:00; Su 10:00-19:00\"",
                "phone": "\"(919) 304-1901\"",
                "shop": "\"clothes\"",
                "street_address": "\"4000 Arrowhead Blvd Ste 600\"",
                "website": "\"https://bananarepublic.gap.com/stores/nc/mebane/banana-republic-1924.html\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "loves": {
        "__id": 152,
        "id": "loves",
        "name": "Love's",
        "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'phone']",
        "rowLength": 670,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763562,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of loves locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 508,
                "address_city": "\"Beaverdam\"",
                "address_full": "\"416 Village Ave\"",
                "address_postcode": "\"45808\"",
                "address_state": "\"OH\"",
                "latitude": 40.836052,
                "longitude": -83.963903,
                "name": "\"Site 905\"",
                "phone": "\"(419) 643-8482\""
            },
            {
                "__id": 47,
                "address_city": "\"Miami\"",
                "address_full": "\"1015 E Steve Owens Blvd\"",
                "address_postcode": "\"74354\"",
                "address_state": "\"OK\"",
                "latitude": 36.871347,
                "longitude": -94.86786,
                "name": "\"Site 73\"",
                "phone": "\"(918) 542-8702\""
            },
            {
                "__id": 255,
                "address_city": "\"Carlisle\"",
                "address_full": "\"1165 Harrisburg Pike\"",
                "address_postcode": "\"17013\"",
                "address_state": "\"PA\"",
                "latitude": 40.230349,
                "longitude": -77.144086,
                "name": "\"Site 407\"",
                "phone": "\"(717) 240-0055\""
            },
            {
                "__id": 256,
                "address_city": "\"Corning\"",
                "address_full": "\"2120 South Ave.\"",
                "address_postcode": "\"96021\"",
                "address_state": "\"CA\"",
                "latitude": 39.907601,
                "longitude": -122.196352,
                "name": "\"Site 410\"",
                "phone": "\"(530) 824-8767\""
            },
            {
                "__id": 235,
                "address_city": "\"Hinton \"",
                "address_full": "\"4400 N. Broadway \"",
                "address_postcode": "\"73047\"",
                "address_state": "\"OK\"",
                "latitude": 35.526555,
                "longitude": -98.351492,
                "name": "\"Site 385\"",
                "phone": "\"(405) 542-3836\""
            },
            {
                "__id": 254,
                "address_city": "\"Brunswick\"",
                "address_full": "\"2766 US Highway 17 S\"",
                "address_postcode": "\"31523\"",
                "address_state": "\"GA\"",
                "latitude": 31.137752,
                "longitude": -81.571797,
                "name": "\"Site 405\"",
                "phone": "\"(912) 264-2777\""
            },
            {
                "__id": 356,
                "address_city": "\"Italy\"",
                "address_full": "\"1021 Dale Evans Dr\"",
                "address_postcode": "\"76651\"",
                "address_state": "\"TX\"",
                "latitude": 32.193688,
                "longitude": -96.898726,
                "name": "\"Site 628\"",
                "phone": "\"(972) 483-1686\""
            },
            {
                "__id": 154,
                "address_city": "\"Boise City\"",
                "address_full": "\"1100 East Main Street\"",
                "address_postcode": "\"73933\"",
                "address_state": "\"OK\"",
                "latitude": 36.729194,
                "longitude": -102.5003,
                "name": "\"Site 302\"",
                "phone": "\"(580) 544-4431\""
            },
            {
                "__id": 600,
                "address_city": "\"Oak Grove\"",
                "address_full": "\"11700 Ft Campbell Blvd\"",
                "address_postcode": "\"42262\"",
                "address_state": "\"KY\"",
                "latitude": 36.709575,
                "longitude": -87.454901,
                "name": "\"Site 782\"",
                "phone": "\"(270) 640-6053\""
            },
            {
                "__id": 205,
                "address_city": "\"Thomson\"",
                "address_full": "\"2129 Washington Rd. NW\"",
                "address_postcode": "\"30824\"",
                "address_state": "\"GA\"",
                "latitude": 33.514228,
                "longitude": -82.503612,
                "name": "\"Site 354\"",
                "phone": "\"(706) 843-9833\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "american_freight": {
        "__id": 153,
        "id": "american_freight",
        "name": "American Freight",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'image', 'latitude', 'longitude', 'name', 'phone', 'shop', 'street_address', 'website']",
        "rowLength": 366,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763563,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of american_freight locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 105,
                "address_city": "\"West Palm Beach\"",
                "address_country": "\"US\"",
                "address_postcode": "\"33409\"",
                "address_state": "\"FL\"",
                "image": "\"https://www.americanfreight.com/webrrv509c4da/img/logos/logo-american-freight.svg\"",
                "latitude": 26.707489013671875,
                "longitude": -80.08148193359375,
                "name": "\"American Freight Store (Sears Outlet) - Appliance, Furniture and Mattress\"",
                "phone": "\"(561) 642-9319\"",
                "shop": "\"department_store\"",
                "street_address": "\"2031 Okeechobee Blvd.\"",
                "website": "\"/br/store/fl/west-palm-beach/4526\""
            },
            {
                "__id": 245,
                "address_city": "\"Phoenix\"",
                "address_country": "\"US\"",
                "address_postcode": "\"85016\"",
                "address_state": "\"AZ\"",
                "image": "\"https://www.americanfreight.com/webrrv509c4da/img/logos/logo-american-freight.svg\"",
                "latitude": 33.480621337890625,
                "longitude": -112.01417541503906,
                "name": "\"American Freight Store - Furniture, Mattress and Appliance\"",
                "phone": "\"(602) 956-6900\"",
                "shop": "\"department_store\"",
                "street_address": "\"3144 E. Thomas Rd.\"",
                "website": "\"/br/store/az/phoenix/157\""
            },
            {
                "__id": 213,
                "address_city": "\"Hendersonville\"",
                "address_country": "\"US\"",
                "address_postcode": "\"28791\"",
                "address_state": "\"NC\"",
                "image": "\"https://www.americanfreight.com/webrrv509c4da/img/logos/logo-american-freight.svg\"",
                "latitude": 35.340065002441406,
                "longitude": -82.47611999511719,
                "name": "\"American Freight Store - Furniture and Mattress\"",
                "phone": "\"(828) 585-6611\"",
                "shop": "\"department_store\"",
                "street_address": "\"2111 Asheville Hwy\"",
                "website": "\"/br/store/nc/hendersonville/191\""
            },
            {
                "__id": 161,
                "address_city": "\"Owensboro\"",
                "address_country": "\"US\"",
                "address_postcode": "\"42303\"",
                "address_state": "\"KY\"",
                "image": "\"https://www.americanfreight.com/webrrv509c4da/img/logos/logo-american-freight.svg\"",
                "latitude": 37.763973236083984,
                "longitude": -87.06786346435547,
                "name": "\"American Freight Store (FFO) - Furniture, Mattress and Appliance\"",
                "phone": "\"(270) 297-4555\"",
                "shop": "\"department_store\"",
                "street_address": "\"2512 Calumet Trace\"",
                "website": "\"/br/store/ky/owensboro/244\""
            },
            {
                "__id": 197,
                "address_city": "\"Hammond\"",
                "address_country": "\"US\"",
                "address_postcode": "\"70401\"",
                "address_state": "\"LA\"",
                "image": "\"https://www.americanfreight.com/webrrv509c4da/img/logos/logo-american-freight.svg\"",
                "latitude": 30.510284423828125,
                "longitude": -90.43118286132812,
                "name": "\"American Freight Store - Furniture and Mattress\"",
                "phone": "\"(985) 317-3339\"",
                "shop": "\"department_store\"",
                "street_address": "\"2103 US Hwy 190 E\"",
                "website": "\"/br/store/la/hammond/208\""
            },
            {
                "__id": 258,
                "address_city": "\"El Paso\"",
                "address_country": "\"US\"",
                "address_postcode": "\"79936\"",
                "address_state": "\"TX\"",
                "image": "\"https://www.americanfreight.com/webrrv509c4da/img/logos/logo-american-freight.svg\"",
                "latitude": 31.743709564208984,
                "longitude": -106.31945037841797,
                "name": "\"American Freight Store - Furniture, Mattress and Appliance\"",
                "phone": "\"(915) 599-2222\"",
                "shop": "\"department_store\"",
                "street_address": "\"11330 James Watt Dr.\"",
                "website": "\"/br/store/tx/el-paso/142\""
            },
            {
                "__id": 297,
                "address_city": "\"Altoona\"",
                "address_country": "\"US\"",
                "address_postcode": "\"16602\"",
                "address_state": "\"PA\"",
                "image": "\"https://www.americanfreight.com/webrrv509c4da/img/logos/logo-american-freight.svg\"",
                "latitude": 40.47471618652344,
                "longitude": -78.40753173828125,
                "name": "\"American Freight Store - Furniture, Mattress and Appliance\"",
                "phone": "\"(814) 944-2444\"",
                "shop": "\"department_store\"",
                "street_address": "\"516 West Plank Road\"",
                "website": "\"/br/store/pa/altoona/99\""
            },
            {
                "__id": 54,
                "address_city": "\"Chesapeake\"",
                "address_country": "\"US\"",
                "address_postcode": "\"23320\"",
                "address_state": "\"VA\"",
                "image": "\"https://www.americanfreight.com/webrrv509c4da/img/logos/logo-american-freight.svg\"",
                "latitude": 36.78554916381836,
                "longitude": -76.25822448730469,
                "name": "\"American Freight Store (Sears Outlet) - Appliance, Furniture and Mattress\"",
                "phone": "\"(757) 901-1449\"",
                "shop": "\"department_store\"",
                "street_address": "\"2005 South Military Highway\"",
                "website": "\"/br/store/va/chesapeake/7593\""
            },
            {
                "__id": 183,
                "address_city": "\"Jefferson City\"",
                "address_country": "\"US\"",
                "address_postcode": "\"65109\"",
                "address_state": "\"MO\"",
                "image": "\"https://www.americanfreight.com/webrrv509c4da/img/logos/logo-american-freight.svg\"",
                "latitude": 38.58150863647461,
                "longitude": -92.20372772216797,
                "name": "\"American Freight Store (FFO) - Furniture, Mattress and Appliance\"",
                "phone": "\"(573) 636-5530\"",
                "shop": "\"department_store\"",
                "street_address": "\"2010 Missouri Blvd.\"",
                "website": "\"/br/store/mo/jefferson-city/222\""
            },
            {
                "__id": 311,
                "address_city": "\"Birmingham\"",
                "address_country": "\"US\"",
                "address_postcode": "\"35235\"",
                "address_state": "\"AL\"",
                "image": "\"https://www.americanfreight.com/webrrv509c4da/img/logos/logo-american-freight.svg\"",
                "latitude": 33.597328186035156,
                "longitude": -86.64647674560547,
                "name": "\"American Freight Store - Furniture, Mattress and Appliance\"",
                "phone": "\"(205) 661-2229\"",
                "shop": "\"department_store\"",
                "street_address": "\"3260 Morrow Rd\"",
                "website": "\"/br/store/al/birmingham/83\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "alicedelice": {
        "__id": 154,
        "id": "alicedelice",
        "name": "Alice Delice",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'facebook', 'latitude', 'longitude', 'name', 'phone', 'shop', 'street_address', 'website']",
        "rowLength": 25,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763564,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of alicedelice locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 22,
                "address_city": "\"ERAGNY\"",
                "address_country": "\"FR\"",
                "address_postcode": "\"95610\"",
                "facebook": "\"AliceDelice.eragny\"",
                "latitude": 49.0317734,
                "longitude": 2.1016002,
                "name": "\"ALICE DELICE ERAGNY\"",
                "phone": "\"0134021783\"",
                "shop": "\"houseware\"",
                "street_address": "\"1 RUE DU BAS NOYER, CENTRE COMMERCIAL ART DE VIVRE\"",
                "website": "\"https://www.alicedelice.com/alice-delice-eragny-b-85784\""
            },
            {
                "__id": 16,
                "address_city": "\"REIMS\"",
                "address_country": "\"FR\"",
                "address_postcode": "\"51100\"",
                "facebook": "\"\"",
                "latitude": 49.2547788,
                "longitude": 4.0282631,
                "name": "\"ALICE DELICE REIMS CV\"",
                "phone": "\"0326889550\"",
                "shop": "\"houseware\"",
                "street_address": "\"13/15 PASSAGE TALLEYRAND\"",
                "website": "\"https://www.alicedelice.com/alice-delice-reims-cv-b-1890999\""
            },
            {
                "__id": 15,
                "address_city": "\"VALENCIENNES\"",
                "address_country": "\"FR\"",
                "address_postcode": "\"59300\"",
                "facebook": "\"AliceDelice.valenciennes\"",
                "latitude": 50.3570311,
                "longitude": 3.5245718,
                "name": "\"ALICE DELICE VALENCIENNES\"",
                "phone": "\"0327336843\"",
                "shop": "\"houseware\"",
                "street_address": "\"12 RUE DE LA HALLE, CENTRE COMMERCIAL COEUR DE VILLE, PLACE D'ARMES\"",
                "website": "\"https://www.alicedelice.com/alice-delice-valenciennes-b-85770\""
            },
            {
                "__id": 11,
                "address_city": "\"NANTES\"",
                "address_country": "\"FR\"",
                "address_postcode": "\"44000\"",
                "facebook": "\"alicedelicenantes\"",
                "latitude": 47.2136756,
                "longitude": -1.5603485,
                "name": "\"ALICE DELICE NANTES\"",
                "phone": "\"0240740709\"",
                "shop": "\"houseware\"",
                "street_address": "\"20, PASSAGE POMMERAYE\"",
                "website": "\"https://www.alicedelice.com/alice-delice-nantes-b-85789\""
            },
            {
                "__id": 19,
                "address_city": "\"CHAUCONIN NEUFMONTIERS\"",
                "address_country": "\"FR\"",
                "address_postcode": "\"77124\"",
                "facebook": "\"AliceDelice.lessaisonsdemeaux\"",
                "latitude": 48.957678,
                "longitude": 2.8607554,
                "name": "\"ALICE DELICE MEAUX\"",
                "phone": "\"0160097400\"",
                "shop": "\"houseware\"",
                "street_address": "\"3 AVENUE ROLAND MORENO, C CIAL LES SAISONS DE MEAUX\"",
                "website": "\"https://www.alicedelice.com/alice-delice-meaux-b-85801\""
            },
            {
                "__id": 10,
                "address_city": "\"LYON\"",
                "address_country": "\"FR\"",
                "address_postcode": "\"69002\"",
                "facebook": "\"AliceDeliceLyon\"",
                "latitude": 45.7611827,
                "longitude": 4.8337529,
                "name": "\"ALICE DELICE LYON CV\"",
                "phone": "\"0478928284\"",
                "shop": "\"houseware\"",
                "street_address": "\"80 RUE DU PRESIDENT EDOUARD HERRIOT\"",
                "website": "\"https://www.alicedelice.com/alice-delice-lyon-cv-b-85782\""
            },
            {
                "__id": 17,
                "address_city": "\"AMIENS\"",
                "address_country": "\"FR\"",
                "address_postcode": "\"80000\"",
                "facebook": "\"AliceDelice.amiens\"",
                "latitude": 49.894067,
                "longitude": 2.295753,
                "name": "\"ALICE DELICE AMIENS\"",
                "phone": "\"0322926466\"",
                "shop": "\"houseware\"",
                "street_address": "\"6-8 RUE ERNEST CAUVIN\"",
                "website": "\"https://www.alicedelice.com/alice-delice-amiens-b-85771\""
            },
            {
                "__id": 12,
                "address_city": "\"NANCY\"",
                "address_country": "\"FR\"",
                "address_postcode": "\"54000\"",
                "facebook": "\"AliceDelice.nancy\"",
                "latitude": 48.68439,
                "longitude": 6.18496,
                "name": "\"ALICE DELICE NANCY\"",
                "phone": "\"0383303987\"",
                "shop": "\"houseware\"",
                "street_address": "\"PLACE CHARLES III, RUE DES PONTS, CENTRE COMMERCIAL SAINT SEBASTIEN\"",
                "website": "\"https://www.alicedelice.com/alice-delice-nancy-b-85783\""
            },
            {
                "__id": 6,
                "address_city": "\"ANNECY\"",
                "address_country": "\"FR\"",
                "address_postcode": "\"74000\"",
                "facebook": "\"AliceDelice.Annecy\"",
                "latitude": 45.9040959,
                "longitude": 6.1235798,
                "name": "\"ALICE DELICE ANNECY\"",
                "phone": "\"0450625552\"",
                "shop": "\"houseware\"",
                "street_address": "\"65 BOULEVARD CARNOT, CENTRE COMMERCIAL COURIER\"",
                "website": "\"https://www.alicedelice.com/alice-delice-annecy-b-85788\""
            },
            {
                "__id": 9,
                "address_city": "\"STRASBOURG\"",
                "address_country": "\"FR\"",
                "address_postcode": "\"67000\"",
                "facebook": "\"AliceDelice.laubette\"",
                "latitude": 48.5838031,
                "longitude": 7.7465687,
                "name": "\"ALICE DELICE AUBETTE\"",
                "phone": "\"0388165834\"",
                "shop": "\"houseware\"",
                "street_address": "\"31 PLACE KLEBER, CENTRE COMMERCIAL GALERIE DE L'AUBETTE\"",
                "website": "\"https://www.alicedelice.com/alice-delice-aubette-b-85774\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "advanceautoparts": {
        "__id": 155,
        "id": "advanceautoparts",
        "name": "Advance Auto Parts",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'website']",
        "rowLength": 4422,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763565,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of advanceautoparts locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 2248,
                "address_city": "\"Perry\"",
                "address_country": "\"US\"",
                "address_full": "\"2206 S Byron Butler Pkwy\"",
                "address_postcode": "\"32348\"",
                "address_state": "\"FL\"",
                "latitude": 30.0957347555477,
                "longitude": -83.579932898283,
                "name": "\"Advance Auto Parts #9493 Perry\"",
                "opening_hours": "\"Mo-Sa 07:30-21:00; Su 08:30-20:30\"",
                "phone": "\"(850) 838-2915\"",
                "shop": "\"car_parts\"",
                "website": "\"https://stores.advanceautoparts.com/fl/perry/2206-s-byron-butler-pkwy\""
            },
            {
                "__id": 1833,
                "address_city": "\"Atlanta\"",
                "address_country": "\"US\"",
                "address_full": "\"2129 Campbellton Rd SW\"",
                "address_postcode": "\"30311\"",
                "address_state": "\"GA\"",
                "latitude": 33.70648649591386,
                "longitude": -84.45855601692529,
                "name": "\"Advance Auto Parts #9623 Atlanta\"",
                "opening_hours": "\"Mo-Sa 07:30-21:00; Su 08:30-20:30\"",
                "phone": "\"(404) 753-3949\"",
                "shop": "\"car_parts\"",
                "website": "\"https://stores.advanceautoparts.com/ga/atlanta/2129-campbellton-rd-sw\""
            },
            {
                "__id": 3712,
                "address_city": "\"Reisterstown\"",
                "address_country": "\"US\"",
                "address_full": "\"11909 Reisterstown Rd\"",
                "address_postcode": "\"21136\"",
                "address_state": "\"MD\"",
                "latitude": 39.448298127115805,
                "longitude": -76.81433022022253,
                "name": "\"Advance Auto Parts #2363 Reisterstown\"",
                "opening_hours": "\"Mo-Sa 07:30-21:00; Su 09:00-20:00\"",
                "phone": "\"(410) 833-1000\"",
                "shop": "\"car_parts\"",
                "website": "\"https://stores.advanceautoparts.com/md/reisterstown/11909-reisterstown-rd\""
            },
            {
                "__id": 989,
                "address_city": "\"Aiken\"",
                "address_country": "\"US\"",
                "address_full": "\"950 York St NE\"",
                "address_postcode": "\"29801\"",
                "address_state": "\"SC\"",
                "latitude": 33.573972190885115,
                "longitude": -81.70448407530779,
                "name": "\"Advance Auto Parts #5260 Aiken\"",
                "opening_hours": "\"Mo-Sa 07:30-21:00; Su 09:00-21:00\"",
                "phone": "\"(803) 648-2999\"",
                "shop": "\"car_parts\"",
                "website": "\"https://stores.advanceautoparts.com/sc/aiken/950-york-st-ne\""
            },
            {
                "__id": 1394,
                "address_city": "\"Etters\"",
                "address_country": "\"US\"",
                "address_full": "\"135 Newberry Pkwy\"",
                "address_postcode": "\"17319\"",
                "address_state": "\"PA\"",
                "latitude": 40.14670604704821,
                "longitude": -76.81318491697311,
                "name": "\"Advance Auto Parts #7164 Etters\"",
                "opening_hours": "\"Mo-Sa 07:30-21:00; Su 09:00-20:00\"",
                "phone": "\"(717) 932-7861\"",
                "shop": "\"car_parts\"",
                "website": "\"https://stores.advanceautoparts.com/pa/etters/135-newberry-pkwy\""
            },
            {
                "__id": 2506,
                "address_city": "\"Miami\"",
                "address_country": "\"US\"",
                "address_full": "\"12398 Quail Roost Dr\"",
                "address_postcode": "\"33177\"",
                "address_state": "\"FL\"",
                "latitude": 25.581331675099303,
                "longitude": -80.39238899946213,
                "name": "\"Advance Auto Parts #9181 Miami\"",
                "opening_hours": "\"Mo-Sa 07:30-21:00; Su 08:00-20:00\"",
                "phone": "\"(786) 242-8110\"",
                "shop": "\"car_parts\"",
                "website": "\"https://stores.advanceautoparts.com/fl/miami/12398-quail-roost-dr\""
            },
            {
                "__id": 1291,
                "address_city": "\"Lancaster\"",
                "address_country": "\"US\"",
                "address_full": "\"1003 E King St\"",
                "address_postcode": "\"17602\"",
                "address_state": "\"PA\"",
                "latitude": 40.039872826926796,
                "longitude": -76.28249377012247,
                "name": "\"Advance Auto Parts #1259 Lancaster\"",
                "opening_hours": "\"Mo-Sa 07:30-20:00; Su 09:00-20:00\"",
                "phone": "\"(717) 393-7576\"",
                "shop": "\"car_parts\"",
                "website": "\"https://stores.advanceautoparts.com/pa/lancaster/1003-e-king-st\""
            },
            {
                "__id": 4066,
                "address_city": "\"Topeka\"",
                "address_country": "\"US\"",
                "address_full": "\"3118 SW Topeka Blvd\"",
                "address_postcode": "\"66611\"",
                "address_state": "\"KS\"",
                "latitude": 39.011664468583746,
                "longitude": -95.68684293904874,
                "name": "\"Advance Auto Parts #6806 Topeka\"",
                "opening_hours": "\"Mo-Sa 07:30-21:00; Su 09:00-20:00\"",
                "phone": "\"(785) 267-3811\"",
                "shop": "\"car_parts\"",
                "website": "\"https://stores.advanceautoparts.com/ks/topeka/3118-sw-topeka-blvd\""
            },
            {
                "__id": 1269,
                "address_city": "\"Rincon\"",
                "address_country": "\"US\"",
                "address_full": "\"418 S Columbia Ave\"",
                "address_postcode": "\"31326\"",
                "address_state": "\"GA\"",
                "latitude": 32.2756900423809,
                "longitude": -81.22840805916894,
                "name": "\"Advance Auto Parts #6730 Rincon\"",
                "opening_hours": "\"Mo-Sa 07:30-21:00; Su 09:00-20:00\"",
                "phone": "\"(912) 826-2446\"",
                "shop": "\"car_parts\"",
                "website": "\"https://stores.advanceautoparts.com/ga/rincon/418-s-columbia-ave\""
            },
            {
                "__id": 2121,
                "address_city": "\"Sarasota\"",
                "address_country": "\"US\"",
                "address_full": "\"2035 N Washington Blvd\"",
                "address_postcode": "\"34234\"",
                "address_state": "\"FL\"",
                "latitude": 27.355674008919973,
                "longitude": -82.53126293420792,
                "name": "\"Advance Auto Parts #9516 Sarasota\"",
                "opening_hours": "\"Mo-Sa 07:30-21:00; Su 09:00-20:00\"",
                "phone": "\"(941) 365-7320\"",
                "shop": "\"car_parts\"",
                "website": "\"https://stores.advanceautoparts.com/fl/sarasota/2035-n-washington-blvd\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "bar_and_block_gb": {
        "__id": 156,
        "id": "bar_and_block_gb",
        "name": "Bar & Block Great Britain",
        "columnNames": "['address_country', 'address_full', 'address_postcode', 'contact_email', 'latitude', 'longitude', 'name', 'phone', 'website']",
        "rowLength": 17,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763566,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of bar_and_block_gb locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 7,
                "address_country": "\"GB\"",
                "address_full": "\"7 Chapel Quarter, Chapel Bar, Nottingham, NG1 6JA\"",
                "address_postcode": "\"NG1 6JA\"",
                "contact_email": "\"barandblock.nottingham@whitbread.com%20\"",
                "latitude": 52.9541106,
                "longitude": -1.1568683,
                "name": "\"Nottingham\"",
                "phone": "\"01156 973973\"",
                "website": "\"https://www.barandblock.co.uk/en-gb/locations/nottingham\""
            },
            {
                "__id": 2,
                "address_country": "\"GB\"",
                "address_full": "\"Reading Road, Winnersh, Wokingham, RG41 5HQ\"",
                "address_postcode": "\"RG41 5HQ\"",
                "contact_email": "\"barandblock.winnershGM@whitbread.com\"",
                "latitude": 51.4359364,
                "longitude": -0.8947807,
                "name": "\"Winnersh\"",
                "phone": "\"0118 3380 946\"",
                "website": "\"https://www.barandblock.co.uk/en-gb/locations/winnersh\""
            },
            {
                "__id": 13,
                "address_country": "\"GB\"",
                "address_full": "\"24 Howard Street, Glasgow, Scotland, G1 4AG\"",
                "address_postcode": "\"G1 4AG\"",
                "contact_email": "\"barandblock.glasgow@whitbread.com\"",
                "latitude": 55.8568582,
                "longitude": -4.257138,
                "name": "\"Glasgow\"",
                "phone": "\"01417 329430\"",
                "website": "\"https://www.barandblock.co.uk/en-gb/locations/glasgow\""
            },
            {
                "__id": 15,
                "address_country": "\"GB\"",
                "address_full": "\"King Street, Bristol, BS1 4HQ\"",
                "address_postcode": "\"BS1 4HQ\"",
                "contact_email": "\"barandblock.bristolkingstreet@whitbread.com\"",
                "latitude": 51.4520856,
                "longitude": -2.5948468,
                "name": "\"Bristol\"",
                "phone": "\"0117 9261650\"",
                "website": "\"https://www.barandblock.co.uk/en-gb/locations/bristol\""
            },
            {
                "__id": 12,
                "address_country": "\"GB\"",
                "address_full": "\"26-30 York Way, Kings Cross, London, N1 9AA\"",
                "address_postcode": "\"N1 9AA\"",
                "contact_email": "\"barandblock.kingscross@whitbread.com%20\"",
                "latitude": 51.5318297,
                "longitude": -0.1244974,
                "name": "\"Kings Cross\"",
                "phone": "\"0203 8898888\"",
                "website": "\"https://www.barandblock.co.uk/en-gb/locations/kings-cross\""
            },
            {
                "__id": 17,
                "address_country": "\"GB\"",
                "address_full": "\"2-6 Waring Street, Belfast, BT1 2DX\"",
                "address_postcode": "\"BT1 2DX\"",
                "contact_email": "\"barandblockGM.belfastcitycathedralquarter@whitbread.com\"",
                "latitude": 54.6009759,
                "longitude": -5.9296913,
                "name": "\"Belfast\"",
                "phone": "\"0289 6931227\"",
                "website": "\"https://www.barandblock.co.uk/en-gb/locations/belfast\""
            },
            {
                "__id": 11,
                "address_country": "\"GB\"",
                "address_full": "\"148-150 Parade, Leamington Spa, CV32 4AG\"",
                "address_postcode": "\"CV32 4AG\"",
                "contact_email": "\"barandblock.leamingtonspa@whitbread.com%20%20\"",
                "latitude": 52.2884592,
                "longitude": -1.5371166,
                "name": "\"Leamington Spa\"",
                "phone": "\"01926 563772\"",
                "website": "\"https://www.barandblock.co.uk/en-gb/locations/leamington-spa\""
            },
            {
                "__id": 9,
                "address_country": "\"GB\"",
                "address_full": "\"New Marlborough Yard, Ufford Street, London, SE1 8LE\"",
                "address_postcode": "\"SE1 8LE\"",
                "contact_email": "\"barandblock.southwarkstation@whitbread.com\"",
                "latitude": 51.508673,
                "longitude": -0.1057474,
                "name": "\"London Southwark\"",
                "phone": "\"020 3928 8288\"",
                "website": "\"https://www.barandblock.co.uk/en-gb/locations/london-southwark\""
            },
            {
                "__id": 5,
                "address_country": "\"GB\"",
                "address_full": "\"St. Nicholas Way, Sutton, London, SM1 1AT\"",
                "address_postcode": "\"SM1 1AT\"",
                "contact_email": "\"barandblock.sutton@whitbread.com\"",
                "latitude": 51.3635207,
                "longitude": -0.1963281,
                "name": "\"Sutton\"",
                "phone": "\"0203 727 0227\"",
                "website": "\"https://www.barandblock.co.uk/en-gb/locations/sutton\""
            },
            {
                "__id": 8,
                "address_country": "\"GB\"",
                "address_full": "\"95 Newgate Street, Newcastle, Newcastle, NE1 5RF\"",
                "address_postcode": "\"NE1 5RF\"",
                "contact_email": "\"BARANDBLOCK.NEWCASTLE@whitbread.com\"",
                "latitude": 54.9721576,
                "longitude": -1.6194459,
                "name": "\"Newcastle\"",
                "phone": "\"0191 814 1888\"",
                "website": "\"https://www.barandblock.co.uk/en-gb/locations/newcastle\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "commerzbank_de": {
        "__id": 157,
        "id": "commerzbank_de",
        "name": "Commerzbank Germany",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'barriere_type', 'cash_register', 'cashback', 'cashgroup', 'email', 'fax', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'vault']",
        "rowLength": 457,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763567,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of commerzbank_de locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 356,
                "address_city": "\"D\u00c3\u00bcsseldorf\"",
                "address_country": "\"DE\"",
                "address_full": "\"Nordstr. 72\"",
                "address_postcode": "\"40300\"",
                "barriere_type": "\"NICHTFREI\"",
                "cash_register": 0,
                "cashback": 0,
                "cashgroup": 0,
                "email": "\"Filiale.Duesseldorf-Nordstrasse@commerzbank.com\"",
                "fax": "\"069 4056-50299\"",
                "latitude": 51.2386849,
                "longitude": 6.779787,
                "name": "\"UVV19-Beratungsfiliale ohne Kasse UVV19\"",
                "opening_hours": "\"Mo-Th 10:00-13:00; Fr 10:00-14:00\"",
                "phone": "\"069 58000-8000\"",
                "vault": 0
            },
            {
                "__id": 370,
                "address_city": "\"Dresden\"",
                "address_country": "\"DE\"",
                "address_full": "\"Altmarkt 7\"",
                "address_postcode": "\"01053\"",
                "barriere_type": "\"FREI\"",
                "cash_register": 0,
                "cashback": 0,
                "cashgroup": 0,
                "email": "\"Filiale.Dresden-Altmarkt@commerzbank.com\"",
                "fax": "\"069 4056-50299\"",
                "latitude": 51.0489609,
                "longitude": 13.7381611,
                "name": "\"BFIL - Beratungsfiliale mit Kasse\"",
                "opening_hours": "\"Mo 09:30-14:00; Tu 09:30-12:30; We 09:30-14:00; Th 09:30-12:30; Fr 09:30-14:00\"",
                "phone": "\"069 58000-8000\"",
                "vault": 0
            },
            {
                "__id": 321,
                "address_city": "\"Frankfurt am Main\"",
                "address_country": "\"DE\"",
                "address_full": "\"Ro\u00c3\u009fmarkt 14\"",
                "address_postcode": "\"60613\"",
                "barriere_type": "\"FREI\"",
                "cash_register": 0,
                "cashback": 0,
                "cashgroup": 0,
                "email": "\"Filiale.Frankfurt-Hauptwache@commerzbank.com\"",
                "fax": "\"069 4056-50299\"",
                "latitude": 50.1125459,
                "longitude": 8.6771462,
                "name": "\"BFIL - Beratungsfiliale mit Kasse\"",
                "opening_hours": "\"Mo-Fr 09:00-13:00\"",
                "phone": "\"069 58000-8000\"",
                "vault": 0
            },
            {
                "__id": 407,
                "address_city": "\"Bocholt\"",
                "address_country": "\"DE\"",
                "address_full": "\"Osterstr. 15 - 17\"",
                "address_postcode": "\"45062\"",
                "barriere_type": "\"FREI\"",
                "cash_register": 0,
                "cashback": 0,
                "cashgroup": 0,
                "email": "\"Filiale.Bocholt@commerzbank.com\"",
                "fax": "\"02871 9504-80\"",
                "latitude": 51.83864,
                "longitude": 6.61679,
                "name": "\"UVV19-Beratungsfiliale ohne Kasse UVV19\"",
                "opening_hours": "\"Mo-Fr 09:00-13:00\"",
                "phone": "\"069 58000-8000\"",
                "vault": 0
            },
            {
                "__id": 211,
                "address_city": "\"K\u00c3\u00b6ln\"",
                "address_country": "\"DE\"",
                "address_full": "\"Maternusstr. 8 - 10\"",
                "address_postcode": "\"40300\"",
                "barriere_type": "\"FREI\"",
                "cash_register": 0,
                "cashback": 0,
                "cashgroup": 0,
                "email": "\"Filiale.Koeln-Rodenkirchen@commerzbank.com\"",
                "fax": "\"069 4056-50299\"",
                "latitude": 50.8930156,
                "longitude": 6.994028699999999,
                "name": "\"UVV19-Beratungsfiliale ohne Kasse UVV19\"",
                "opening_hours": "\"Mo-Th 09:00-13:00; Fr 09:00-14:00\"",
                "phone": "\"069 58000-8000\"",
                "vault": 0
            },
            {
                "__id": 352,
                "address_city": "\"Eckernf\u00c3\u00b6rde\"",
                "address_country": "\"DE\"",
                "address_full": "\"Kieler Str. 2\"",
                "address_postcode": "\"20349\"",
                "barriere_type": "\"FREI\"",
                "cash_register": 0,
                "cashback": 0,
                "cashgroup": 0,
                "email": "\"Filiale.Eckernfoerde@commerzbank.com\"",
                "fax": "\"04351 7118-33\"",
                "latitude": 54.4725092,
                "longitude": 9.8362844,
                "name": "\"UVV19-Beratungsfiliale ohne Kasse UVV19\"",
                "opening_hours": "\"Mo-Fr 00:00-00:01\"",
                "phone": "\"04351 7118-0\"",
                "vault": 0
            },
            {
                "__id": 403,
                "address_city": "\"Bottrop\"",
                "address_country": "\"DE\"",
                "address_full": "\"Berliner Platz 2\"",
                "address_postcode": "\"45062\"",
                "barriere_type": "\"FREI\"",
                "cash_register": 0,
                "cashback": 0,
                "cashgroup": 0,
                "email": "\"Filiale.Bottrop@commerzbank.com\"",
                "fax": "\"069 4056-50299\"",
                "latitude": 51.52162149999999,
                "longitude": 6.9269799,
                "name": "\"UVV19-Beratungsfiliale ohne Kasse UVV19\"",
                "opening_hours": "\"Mo-Th 09:00-13:00; Fr 09:00-14:00\"",
                "phone": "\"069 58000-8000\"",
                "vault": 0
            },
            {
                "__id": 78,
                "address_city": "\"Schwerin\"",
                "address_country": "\"DE\"",
                "address_full": "\"Schlo\u00c3\u009fstr. 32 - 34\"",
                "address_postcode": "\"20349\"",
                "barriere_type": "\"FREI\"",
                "cash_register": 0,
                "cashback": 0,
                "cashgroup": 0,
                "email": "\"Filiale.Schwerin@commerzbank.com\"",
                "fax": "\"0385 59000-66\"",
                "latitude": 53.6277437,
                "longitude": 11.4119814,
                "name": "\"UVV19-Beratungsfiliale ohne Kasse UVV19\"",
                "opening_hours": "\"Mo-Fr 09:00-13:00\"",
                "phone": "\"0385 59000-0\"",
                "vault": 0
            },
            {
                "__id": 54,
                "address_city": "\"T\u00c3\u00bcbingen\"",
                "address_country": "\"DE\"",
                "address_full": "\"Karlstr. 4\"",
                "address_postcode": "\"70140\"",
                "barriere_type": "\"NICHTFREI\"",
                "cash_register": 0,
                "cashback": 0,
                "cashgroup": 0,
                "email": "\"Filiale.Tuebingen@commerzbank.com\"",
                "fax": "\"07071 1597-19\"",
                "latitude": 48.5174727,
                "longitude": 9.0580686,
                "name": "\"UVV19-Beratungsfiliale ohne Kasse UVV19\"",
                "opening_hours": "\"Tu-Th 09:00-13:00\"",
                "phone": "\"069 58000-8000\"",
                "vault": 0
            },
            {
                "__id": 305,
                "address_city": "\"Garbsen\"",
                "address_country": "\"DE\"",
                "address_full": "\"Rote Reihe 2\"",
                "address_postcode": "\"30126\"",
                "barriere_type": "\"TEILFREI\"",
                "cash_register": 0,
                "cashback": 0,
                "cashgroup": 0,
                "email": "\"Filiale.Garbsen@commerzbank.com\"",
                "fax": "\"069 4056-50299\"",
                "latitude": 52.43669209999999,
                "longitude": 9.611305699999999,
                "name": "\"UVV19-Beratungsfiliale ohne Kasse UVV19\"",
                "opening_hours": "\"Mo-Fr 09:00-13:00\"",
                "phone": "\"069 58000-8000\"",
                "vault": 0
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "orangetheory_fitness": {
        "__id": 158,
        "id": "orangetheory_fitness",
        "name": "OrangeTheory Fitness",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'latitude', 'leisure', 'longitude', 'name', 'phone']",
        "rowLength": 1562,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763568,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of orangetheory_fitness locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 836,
                "address_city": "\"Wilmington\"",
                "address_country": "\"US\"",
                "address_full": "\"1412 Barclay Pointe Blvd, Suite 1002\"",
                "address_postcode": "\"28412\"",
                "address_state": "\"North Carolina\"",
                "latitude": 34.18058777,
                "leisure": "\"fitness_centre\"",
                "longitude": -77.91805267,
                "name": "\"South Wilmington, NC\"",
                "phone": "\"9105502009\""
            },
            {
                "__id": 306,
                "address_city": "\"Knoxville\"",
                "address_country": "\"US\"",
                "address_full": "\"11674 Parkside Drive, Suite 674\"",
                "address_postcode": "\"37934\"",
                "address_state": "\"Tennessee\"",
                "latitude": 35.89287949,
                "leisure": "\"fitness_centre\"",
                "longitude": -84.17075348,
                "name": "\"Farragut, TN\"",
                "phone": "\"8652236699\""
            },
            {
                "__id": 1069,
                "address_city": "\"Ammon\"",
                "address_country": "\"US\"",
                "address_full": "\"2674 E Sunnyside Road\"",
                "address_postcode": "\"83406\"",
                "address_state": "\"Idaho\"",
                "latitude": 43.46630478,
                "leisure": "\"fitness_centre\"",
                "longitude": -111.98046112,
                "name": "\"Idaho Falls, ID\"",
                "phone": "\"2085411702\""
            },
            {
                "__id": 527,
                "address_city": "\"Geneva\"",
                "address_country": "\"US\"",
                "address_full": "\"2401 Kaneville Road\"",
                "address_postcode": "\"60134\"",
                "address_state": "\"Illinois\"",
                "latitude": 41.8836441,
                "leisure": "\"fitness_centre\"",
                "longitude": -88.33911133,
                "name": "\"Geneva, IL\"",
                "phone": "\"6305036818\""
            },
            {
                "__id": 205,
                "address_city": "\"Clarksburg\"",
                "address_country": "\"US\"",
                "address_full": "\"22750 Newcut Road, Suite 4\"",
                "address_postcode": "\"20871\"",
                "address_state": "\"Maryland\"",
                "latitude": 39.2293396,
                "leisure": "\"fitness_centre\"",
                "longitude": -77.24763489,
                "name": "\"Clarksburg, MD\"",
                "phone": "\"4439279100\""
            },
            {
                "__id": 765,
                "address_city": "\"Cleveland\"",
                "address_country": "\"US\"",
                "address_full": "\"740 Prospect Avenue East\"",
                "address_postcode": "\"44115\"",
                "address_state": "\"Ohio\"",
                "latitude": 41.4985199,
                "leisure": "\"fitness_centre\"",
                "longitude": -81.68721008,
                "name": "\"Downtown Cleveland, OH\"",
                "phone": "\"2168161105\""
            },
            {
                "__id": 1096,
                "address_city": "\"Portland-South\"",
                "address_country": "\"US\"",
                "address_full": "\"364 Maine Mall Road, Space W-165\"",
                "address_postcode": "\"04106\"",
                "address_state": "\"Maine\"",
                "latitude": 43.63421249,
                "leisure": "\"fitness_centre\"",
                "longitude": -70.33678436,
                "name": "\"Portland-South, ME\"",
                "phone": "\"2073769100\""
            },
            {
                "__id": 133,
                "address_city": "\"Bogota\"",
                "address_country": "\"CO\"",
                "address_full": "\"Calle 116 #16-09 Piso 3\"",
                "address_postcode": "\"110111\"",
                "address_state": "\"Bogot\u00e1\"",
                "latitude": 4.69697189,
                "leisure": "\"fitness_centre\"",
                "longitude": -74.04537964,
                "name": "\"Bogota 116\"",
                "phone": "\"5717023438\""
            },
            {
                "__id": 72,
                "address_city": "\"Oshawa\"",
                "address_country": "\"CA\"",
                "address_full": "\"789 Taunton Road East\"",
                "address_postcode": "\"L1H 7K5\"",
                "address_state": "\"Ontario\"",
                "latitude": 43.93831253,
                "leisure": "\"fitness_centre\"",
                "longitude": -78.85049438,
                "name": "\"Oshawa, CAN\"",
                "phone": "\"905.335.3076\""
            },
            {
                "__id": 654,
                "address_city": "\"Las Vegas\"",
                "address_country": "\"US\"",
                "address_full": "\"5990 S Rainbow Blvd, Suite 200\"",
                "address_postcode": "\"89118\"",
                "address_state": "\"Nevada\"",
                "latitude": 36.08011627,
                "leisure": "\"fitness_centre\"",
                "longitude": -115.24222565,
                "name": "\"Las Vegas Spring Valley, NV\"",
                "phone": "\"7028688300\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "lidl_ie": {
        "__id": 159,
        "id": "lidl_ie",
        "name": "Lidl Ireland",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'shop', 'street_address', 'toilets', 'toilets_access']",
        "rowLength": 174,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763657,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of lidl_ie locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 102,
                "address_city": "\"\"",
                "address_country": "\"IE\"",
                "address_full": "\"Boghall Road, Bray, A98 C971, Ireland\"",
                "address_postcode": "\"A98 C971\"",
                "address_state": "\"Bray\"",
                "latitude": 53.18953,
                "longitude": -6.12352,
                "name": "\"\"",
                "opening_hours": "\"\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Boghall Road\"",
                "toilets": "\"\"",
                "toilets_access": "\"\""
            },
            {
                "__id": 149,
                "address_city": "\"Waterford\"",
                "address_country": "\"IE\"",
                "address_full": "\"Dunmore Road, Waterford, Waterford, X91 FY51, Ireland\"",
                "address_postcode": "\"X91 FY51\"",
                "address_state": "\"Waterford\"",
                "latitude": 52.24697,
                "longitude": -7.08316,
                "name": "\"Waterford\"",
                "opening_hours": "\"Mo-Sa 08:00-22:00; Su 09:00-21:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Dunmore Road\"",
                "toilets": "\"\"",
                "toilets_access": "\"\""
            },
            {
                "__id": 173,
                "address_city": "\"Balbriggan\"",
                "address_country": "\"IE\"",
                "address_full": "\"Old Market Green, Balbriggan, Dublin, K32 EF83, Ireland\"",
                "address_postcode": "\"K32 EF83\"",
                "address_state": "\"Dublin\"",
                "latitude": 53.60616,
                "longitude": -6.18003,
                "name": "\"Balbriggan\"",
                "opening_hours": "\"Mo-Sa 08:00-22:00; Su 09:00-21:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Old Market Green\"",
                "toilets": "\"\"",
                "toilets_access": "\"\""
            },
            {
                "__id": 133,
                "address_city": "\"Dublin\"",
                "address_country": "\"IE\"",
                "address_full": "\"New Nangor Road, Dublin, Dublin, D22 Y588, Ireland\"",
                "address_postcode": "\"D22 Y588\"",
                "address_state": "\"Dublin\"",
                "latitude": 53.3284,
                "longitude": -6.39123,
                "name": "\"Clondalkin\"",
                "opening_hours": "\"Mo-Sa 08:00-22:00; Su 09:00-21:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"New Nangor Road\"",
                "toilets": "\"\"",
                "toilets_access": "\"\""
            },
            {
                "__id": 18,
                "address_city": "\"Clifden\"",
                "address_country": "\"IE\"",
                "address_full": "\"Galway Road, Clifden, Galway, H71 XA32, Ireland\"",
                "address_postcode": "\"H71 XA32\"",
                "address_state": "\"Galway\"",
                "latitude": 53.48819,
                "longitude": -10.01363,
                "name": "\"Clifden\"",
                "opening_hours": "\"Mo-Sa 08:00-22:00; Su 09:00-21:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Galway Road\"",
                "toilets": "\"\"",
                "toilets_access": "\"\""
            },
            {
                "__id": 23,
                "address_city": "\"Tullow\"",
                "address_country": "\"IE\"",
                "address_full": "\"The Green, Tullow, Carlow, R93 AX25, Ireland\"",
                "address_postcode": "\"R93 AX25\"",
                "address_state": "\"Carlow\"",
                "latitude": 52.80075,
                "longitude": -6.73997,
                "name": "\"Tullow\"",
                "opening_hours": "\"Mo-Sa 08:00-22:00; Su 09:00-21:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"The Green\"",
                "toilets": "\"\"",
                "toilets_access": "\"\""
            },
            {
                "__id": 100,
                "address_city": "\"\"",
                "address_country": "\"IE\"",
                "address_full": "\"Station Road, Lusk, K45 RX92, Ireland\"",
                "address_postcode": "\"K45 RX92\"",
                "address_state": "\"Lusk\"",
                "latitude": 53.52433,
                "longitude": -6.15898,
                "name": "\"\"",
                "opening_hours": "\"Mo-Sa 08:00-22:00; Su 09:00-21:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Station Road\"",
                "toilets": "\"\"",
                "toilets_access": "\"\""
            },
            {
                "__id": 153,
                "address_city": "\"Galway\"",
                "address_country": "\"IE\"",
                "address_full": "\"Headford Road, Galway, Galway, H91 RX58, Ireland\"",
                "address_postcode": "\"H91 RX58\"",
                "address_state": "\"Galway\"",
                "latitude": 53.27861,
                "longitude": -9.05011,
                "name": "\"Galway-Headford\"",
                "opening_hours": "\"Mo-Sa 08:00-22:00; Su 09:00-21:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Headford Road\"",
                "toilets": "\"\"",
                "toilets_access": "\"\""
            },
            {
                "__id": 75,
                "address_city": "\"\"",
                "address_country": "\"IE\"",
                "address_full": "\"Cranmore Road, Sligo, Ireland\"",
                "address_postcode": "\"\"",
                "address_state": "\"Sligo\"",
                "latitude": 54.26752,
                "longitude": -8.46015,
                "name": "\"\"",
                "opening_hours": "\"Mo-Sa 08:00-22:00; Su 09:00-21:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Cranmore Road\"",
                "toilets": "\"\"",
                "toilets_access": "\"\""
            },
            {
                "__id": 136,
                "address_city": "\"Bundoran\"",
                "address_country": "\"IE\"",
                "address_full": "\"Station Road, Bundoran, Donegal, F94 V586, Ireland\"",
                "address_postcode": "\"F94 V586\"",
                "address_state": "\"Donegal\"",
                "latitude": 54.47764,
                "longitude": -8.27482,
                "name": "\"Bundoran\"",
                "opening_hours": "\"Mo-Sa 08:00-21:00; Su 09:00-21:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Station Road\"",
                "toilets": "\"\"",
                "toilets_access": "\"\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "lidl_us": {
        "__id": 160,
        "id": "lidl_us",
        "name": "Lidl United States",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'phone', 'shop']",
        "rowLength": 177,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763660,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of lidl_us locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 110,
                "address_city": "\"Glassboro\"",
                "address_country": "\"US\"",
                "address_full": "\"779 Delsea Dr\"",
                "address_postcode": "\"08028\"",
                "address_state": "\"NJ\"",
                "latitude": 39.721924,
                "longitude": -75.11543,
                "name": "\"Glassboro NJ - Delsea Dr.\"",
                "phone": "\"844.747.5435\"",
                "shop": "\"supermarket\""
            },
            {
                "__id": 172,
                "address_city": "\"Takoma Park\"",
                "address_country": "\"US\"",
                "address_full": "\"6881 New Hampshire Ave\"",
                "address_postcode": "\"20912\"",
                "address_state": "\"MD\"",
                "latitude": 38.974224,
                "longitude": -76.99546,
                "name": "\"Takoma Park MD - New Hampshire Ave\"",
                "phone": "\"844.747.5435\"",
                "shop": "\"supermarket\""
            },
            {
                "__id": 151,
                "address_city": "\"East Rockaway\"",
                "address_country": "\"US\"",
                "address_full": "\"492 Atlantic Avenue\"",
                "address_postcode": "\"11518\"",
                "address_state": "\"NY\"",
                "latitude": 40.64199,
                "longitude": -73.66544,
                "name": "\"East Rockaway NY - Atlantic Ave\"",
                "phone": "\"844.747.5435\"",
                "shop": "\"supermarket\""
            },
            {
                "__id": 128,
                "address_city": "\"North Charleston\"",
                "address_country": "\"US\"",
                "address_full": "\"8849 Dorchester Road\"",
                "address_postcode": "\"29420\"",
                "address_state": "\"SC\"",
                "latitude": 32.9223,
                "longitude": -80.12079,
                "name": "\"North Charleston SC - Cedar Grove\"",
                "phone": "\"844.747.5435\"",
                "shop": "\"supermarket\""
            },
            {
                "__id": 91,
                "address_city": "\"Ashburn\"",
                "address_country": "\"US\"",
                "address_full": "\"44175  Ashbrook Marketplace Plaza\"",
                "address_postcode": "\"20147\"",
                "address_state": "\"VA\"",
                "latitude": 39.060333,
                "longitude": -77.47347,
                "name": "\"Ashburn VA - Ashbrook Marketplace\"",
                "phone": "\"844.747.5435\"",
                "shop": "\"supermarket\""
            },
            {
                "__id": 5,
                "address_city": "\"Winston Salem\"",
                "address_country": "\"US\"",
                "address_full": "\"3315 Sides Branch Rd\"",
                "address_postcode": "\"27127\"",
                "address_state": "\"NC\"",
                "latitude": 36.031605,
                "longitude": -80.26134,
                "name": "\"Winston Salem NC - Sides Branch Rd\"",
                "phone": "\"844.747.5435\"",
                "shop": "\"supermarket\""
            },
            {
                "__id": 21,
                "address_city": "\"Raleigh\"",
                "address_country": "\"US\"",
                "address_full": "\"4308 Wake Forest Road\"",
                "address_postcode": "\"27609\"",
                "address_state": "\"NC\"",
                "latitude": 35.839794,
                "longitude": -78.611626,
                "name": "\"Raleigh NC - Wake Forest Rd\"",
                "phone": "\"844.747.5435\"",
                "shop": "\"supermarket\""
            },
            {
                "__id": 165,
                "address_city": "\"Lake Grove\"",
                "address_country": "\"US\"",
                "address_full": "\"2900 Middle Country Rd\"",
                "address_postcode": "\"11755\"",
                "address_state": "\"NY\"",
                "latitude": 40.859497,
                "longitude": -73.11538,
                "name": "\"Lake Grove NY - Middle Country Rd\"",
                "phone": "\"844.747.5435\"",
                "shop": "\"supermarket\""
            },
            {
                "__id": 161,
                "address_city": "\"East Northport\"",
                "address_country": "\"US\"",
                "address_full": "\"560 Larkfield Rd\"",
                "address_postcode": "\"11731\"",
                "address_state": "\"NY\"",
                "latitude": 40.858395,
                "longitude": -73.31972,
                "name": "\"East Northport NY - Larkfield Rd\"",
                "phone": "\"844.747.5435\"",
                "shop": "\"supermarket\""
            },
            {
                "__id": 8,
                "address_city": "\"Rock Hill\"",
                "address_country": "\"US\"",
                "address_full": "\"1260 Herrons Ferry Rd\"",
                "address_postcode": "\"29730\"",
                "address_state": "\"SC\"",
                "latitude": 34.97869,
                "longitude": -80.98097,
                "name": "\"Rock Hill SC - Herrons Ferry Rd\"",
                "phone": "\"844.747.5435\"",
                "shop": "\"supermarket\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "costacoffee_gb": {
        "__id": 161,
        "id": "costacoffee_gb",
        "name": "Costa Coffee Great Britain",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'amenity', 'changing_table', 'contact_email', 'cuisine', 'delivery', 'drive_through', 'email', 'internet_access', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'street_address', 'toilets', 'toilets_wheelchair', 'vending', 'wheelchair']",
        "rowLength": 13859,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763662,
        "runTime": 2,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of costacoffee_gb locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 4103,
                "address_city": "\"Liverpool\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"L9 0EF\"",
                "address_state": "\"Lancashire\"",
                "amenity": "\"vending_machine\"",
                "changing_table": "\"no\"",
                "contact_email": "\"\"",
                "cuisine": "\"\"",
                "delivery": "\"no\"",
                "drive_through": "\"no\"",
                "email": "\"\"",
                "internet_access": "\"no\"",
                "latitude": 53.4645,
                "longitude": -2.95654,
                "name": "\"Tesco Express @ Longmoor Lane Lpool 5156\"",
                "opening_hours": "\"Mo-Su 07:00-23:00\"",
                "phone": "\"\"",
                "street_address": "\"Longmoor Lane\"",
                "toilets": "\"\"",
                "toilets_wheelchair": "\"no\"",
                "vending": "\"coffee\"",
                "wheelchair": "\"no\""
            },
            {
                "__id": 3369,
                "address_city": "\"Waterhays\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"ST5 7SB\"",
                "address_state": "\"Staffordshire\"",
                "amenity": "\"vending_machine\"",
                "changing_table": "\"no\"",
                "contact_email": "\"\"",
                "cuisine": "\"\"",
                "delivery": "\"no\"",
                "drive_through": "\"no\"",
                "email": "\"\"",
                "internet_access": "\"no\"",
                "latitude": 53.051,
                "longitude": -2.2567,
                "name": "\"Co Op @ Waterhays\"",
                "opening_hours": "\"Mo-Su 07:00-22:00\"",
                "phone": "\"\"",
                "street_address": "\"Unit 1-3, Barbridge Road, Waterhayes Village Chesterton\"",
                "toilets": "\"\"",
                "toilets_wheelchair": "\"no\"",
                "vending": "\"coffee\"",
                "wheelchair": "\"no\""
            },
            {
                "__id": 12312,
                "address_city": "\"Chesterfield\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"S41 0UB\"",
                "address_state": "\"Derbyshire\"",
                "amenity": "\"vending_machine\"",
                "changing_table": "\"no\"",
                "contact_email": "\"\"",
                "cuisine": "\"\"",
                "delivery": "\"no\"",
                "drive_through": "\"no\"",
                "email": "\"\"",
                "internet_access": "\"no\"",
                "latitude": 53.252378,
                "longitude": -1.417631,
                "name": "\"Sainsburys Superstore Costa @ Chesterfield 0849\"",
                "opening_hours": "\"Mo-Fr 07:00-23:00; Sa 07:00-22:00; Su 10:00-16:00\"",
                "phone": "\"\"",
                "street_address": "\"Rother Way\"",
                "toilets": "\"\"",
                "toilets_wheelchair": "\"no\"",
                "vending": "\"coffee\"",
                "wheelchair": "\"no\""
            },
            {
                "__id": 10570,
                "address_city": "\"London\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"SW1P 4LY\"",
                "address_state": "\"Middlesex\"",
                "amenity": "\"vending_machine\"",
                "changing_table": "\"no\"",
                "contact_email": "\"\"",
                "cuisine": "\"\"",
                "delivery": "\"no\"",
                "drive_through": "\"no\"",
                "email": "\"\"",
                "internet_access": "\"no\"",
                "latitude": 51.4946,
                "longitude": -0.129231,
                "name": "\"Sainsburys Local Costa @Westminster Marsham St4369\"",
                "opening_hours": "\"Mo-Su 07:00-23:00\"",
                "phone": "\"\"",
                "street_address": "\"78-98 Marsham St\"",
                "toilets": "\"\"",
                "toilets_wheelchair": "\"no\"",
                "vending": "\"coffee\"",
                "wheelchair": "\"no\""
            },
            {
                "__id": 7110,
                "address_city": "\"Wellingborough\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"NN8 1EY\"",
                "address_state": "\"Northamptonshire\"",
                "amenity": "\"cafe\"",
                "changing_table": "\"yes\"",
                "contact_email": "\"Wellingboroughc@3dcoffee.co.uk\"",
                "cuisine": "\"coffee_shop\"",
                "delivery": "\"no\"",
                "drive_through": "\"no\"",
                "email": "\"Wellingboroughc@3dcoffee.co.uk\"",
                "internet_access": "\"wlan\"",
                "latitude": 52.3013,
                "longitude": -0.691677,
                "name": "\"Wellingborough 2\"",
                "opening_hours": "\"Mo-Su 08:00-16:00\"",
                "phone": "\"01933 270113\"",
                "street_address": "\"Mall Cafe, Swansgate Shopping Centre\"",
                "toilets": "\"yes\"",
                "toilets_wheelchair": "\"yes\"",
                "vending": "\"coffee\"",
                "wheelchair": "\"yes\""
            },
            {
                "__id": 7714,
                "address_city": "\"London\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"SE20 7TY\"",
                "address_state": "\"Surrey\"",
                "amenity": "\"vending_machine\"",
                "changing_table": "\"no\"",
                "contact_email": "\"\"",
                "cuisine": "\"\"",
                "delivery": "\"no\"",
                "drive_through": "\"no\"",
                "email": "\"\"",
                "internet_access": "\"no\"",
                "latitude": 51.4086,
                "longitude": -0.059524,
                "name": "\"Tesco Express Costa @ Penge Croydon Rd 5311\"",
                "opening_hours": "\"Mo-Su 07:00-23:00\"",
                "phone": "\"\"",
                "street_address": "\"Unit 1 169/175 Croydo, Penge\"",
                "toilets": "\"\"",
                "toilets_wheelchair": "\"no\"",
                "vending": "\"coffee\"",
                "wheelchair": "\"no\""
            },
            {
                "__id": 6983,
                "address_city": "\"North Shields\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"NE29 0TA\"",
                "address_state": "\"Northumberland\"",
                "amenity": "\"cafe\"",
                "changing_table": "\"yes\"",
                "contact_email": "\"costanorthshields@cuppacoff.co.uk\"",
                "cuisine": "\"coffee_shop\"",
                "delivery": "\"no\"",
                "drive_through": "\"no\"",
                "email": "\"costanorthshields@cuppacoff.co.uk\"",
                "internet_access": "\"wlan\"",
                "latitude": 55.0084,
                "longitude": -1.44664,
                "name": "\"North Shields\"",
                "opening_hours": "\"Mo-Sa 07:00-18:00; Su 09:00-17:00\"",
                "phone": "\"01912 577266\"",
                "street_address": "\"92 Bedford Street,The Beacon Shopping Centre\"",
                "toilets": "\"yes\"",
                "toilets_wheelchair": "\"yes\"",
                "vending": "\"coffee\"",
                "wheelchair": "\"yes\""
            },
            {
                "__id": 6123,
                "address_city": "\"West Midlands\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"B70 7PP\"",
                "address_state": "\"Staffordshire\"",
                "amenity": "\"cafe\"",
                "changing_table": "\"no\"",
                "contact_email": "\"43036295@costacoffee.co.uk\"",
                "cuisine": "\"coffee_shop\"",
                "delivery": "\"yes\"",
                "drive_through": "\"no\"",
                "email": "\"43036295@costacoffee.co.uk\"",
                "internet_access": "\"wlan\"",
                "latitude": 52.519844,
                "longitude": -1.991656,
                "name": "\"West Bromwich\"",
                "opening_hours": "\"Mo-Fr 08:00-19:00; Sa 08:00-18:00; Su 09:00-17:00\"",
                "phone": "\"01218 171689\"",
                "street_address": "\"8 New Square, West Bromwich\"",
                "toilets": "\"\"",
                "toilets_wheelchair": "\"no\"",
                "vending": "\"coffee\"",
                "wheelchair": "\"no\""
            },
            {
                "__id": 282,
                "address_city": "\"Toddington\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"LU5 6HR\"",
                "address_state": "\"Bedfordshire\"",
                "amenity": "\"vending_machine\"",
                "changing_table": "\"no\"",
                "contact_email": "\"\"",
                "cuisine": "\"\"",
                "delivery": "\"no\"",
                "drive_through": "\"no\"",
                "email": "\"\"",
                "internet_access": "\"no\"",
                "latitude": 51.947585,
                "longitude": -0.500999,
                "name": "\"Moto Retail Costa @ Toddington South PS\"",
                "opening_hours": "\"Mo-Su 05:00-23:00\"",
                "phone": "\"\"",
                "street_address": "\"M1 Junction 11/12 Northbound\"",
                "toilets": "\"\"",
                "toilets_wheelchair": "\"no\"",
                "vending": "\"coffee\"",
                "wheelchair": "\"no\""
            },
            {
                "__id": 3018,
                "address_city": "\"Essex\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"CM20 9HH\"",
                "address_state": "\"Essex\"",
                "amenity": "\"vending_machine\"",
                "changing_table": "\"no\"",
                "contact_email": "\"\"",
                "cuisine": "\"\"",
                "delivery": "\"no\"",
                "drive_through": "\"no\"",
                "email": "\"\"",
                "internet_access": "\"no\"",
                "latitude": 51.7709,
                "longitude": 0.09086,
                "name": "\"MFG @ Linkway\"",
                "opening_hours": "\"Mo-Su 05:00-23:00\"",
                "phone": "\"\"",
                "street_address": "\"Kitson Way, Harlow\"",
                "toilets": "\"\"",
                "toilets_wheelchair": "\"no\"",
                "vending": "\"coffee\"",
                "wheelchair": "\"no\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "costacoffee_pl": {
        "__id": 162,
        "id": "costacoffee_pl",
        "name": "Costa Coffee Poland",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'delivery', 'latitude', 'longitude', 'name', 'store_type', 'street_address']",
        "rowLength": 493,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763664,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of costacoffee_pl locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 426,
                "address_city": "\"Gdynia\"",
                "address_country": "\"PL\"",
                "address_full": "\"ul. G\u00f3rskiego 2, Gdynia, 81-304, Poland\"",
                "address_postcode": "\"81-304\"",
                "delivery": "\"yes\"",
                "latitude": 54.504849,
                "longitude": 18.531808,
                "name": "\"Riviera\"",
                "store_type": "\"store\"",
                "street_address": "\"ul. G\u00f3rskiego 2\""
            },
            {
                "__id": 475,
                "address_city": "\"Olsztyn\"",
                "address_country": "\"PL\"",
                "address_full": "\"Pi\u0142sudskiego 16, Olsztyn, 10-576, Poland\"",
                "address_postcode": "\"10-576\"",
                "delivery": "\"yes\"",
                "latitude": 53.7773251,
                "longitude": 20.4826285,
                "name": "\"Centrum Aura Olsztyn\"",
                "store_type": "\"store\"",
                "street_address": "\"Pi\u0142sudskiego 16\""
            },
            {
                "__id": 8,
                "address_city": "\"Piotrkow Trybunalski\"",
                "address_country": "\"PL\"",
                "address_full": "\"Gorna 7, Piotrkow Trybunalski, 97-300, Poland\"",
                "address_postcode": "\"97-300\"",
                "delivery": "\"no\"",
                "latitude": 51.406417,
                "longitude": 19.682611,
                "name": "\"Shell Mobility @ Gorna\"",
                "store_type": "\"express\"",
                "street_address": "\"Gorna 7\""
            },
            {
                "__id": 60,
                "address_city": "\"Swietoszowka\"",
                "address_country": "\"PL\"",
                "address_full": "\"l, Swietoszowka, 43-386, Poland\"",
                "address_postcode": "\"43-386\"",
                "delivery": "\"no\"",
                "latitude": 49.805906,
                "longitude": 18.888456,
                "name": "\"Shell Mobility @ Swietoszowka\"",
                "store_type": "\"express\"",
                "street_address": "\"l\""
            },
            {
                "__id": 6,
                "address_city": "\"Stara Blotnica\"",
                "address_country": "\"PL\"",
                "address_full": "\"Stary Gozd 89 A, Stara Blotnica, 26-806, Poland\"",
                "address_postcode": "\"26-806\"",
                "delivery": "\"no\"",
                "latitude": 51.570753,
                "longitude": 21.020483,
                "name": "\"Shell Mobility @ Gozd\"",
                "store_type": "\"express\"",
                "street_address": "\"Stary Gozd 89 A\""
            },
            {
                "__id": 200,
                "address_city": "\"Lodz\"",
                "address_country": "\"PL\"",
                "address_full": "\"Ul. Traktorowa, Lodz, 91-164, Poland\"",
                "address_postcode": "\"91-164\"",
                "delivery": "\"no\"",
                "latitude": 51.800914,
                "longitude": 19.395139,
                "name": "\"Shell Mobility @ Traktorowa\"",
                "store_type": "\"express\"",
                "street_address": "\"Ul. Traktorowa\""
            },
            {
                "__id": 133,
                "address_city": "\"Bielsko-Biala\"",
                "address_country": "\"PL\"",
                "address_full": "\"Ul. Krysztalowa, Bielsko-Biala, 43-300, Poland\"",
                "address_postcode": "\"43-300\"",
                "delivery": "\"no\"",
                "latitude": 49.841365,
                "longitude": 19.073881,
                "name": "\"Shell Mobility @ Bielsko Biala Krysztalowa\"",
                "store_type": "\"express\"",
                "street_address": "\"Ul. Krysztalowa\""
            },
            {
                "__id": 336,
                "address_city": "\"Szczecin\"",
                "address_country": "\"PL\"",
                "address_full": "\"Ul. Szczanieckiej 1 Maja, Szczecin, 71-630, Poland\"",
                "address_postcode": "\"71-630\"",
                "delivery": "\"no\"",
                "latitude": 53.442159,
                "longitude": 14.564022,
                "name": "\"Shell Mobility @ Gontynka\"",
                "store_type": "\"express\"",
                "street_address": "\"Ul. Szczanieckiej 1 Maja\""
            },
            {
                "__id": 339,
                "address_city": "\"Krosno\"",
                "address_country": "\"PL\"",
                "address_full": "\"Ul. Zrecinska 2A., Krosno, 38-400, Poland\"",
                "address_postcode": "\"38-400\"",
                "delivery": "\"no\"",
                "latitude": 49.691523,
                "longitude": 21.732578,
                "name": "\"Shell Mobility @ Krosno\"",
                "store_type": "\"express\"",
                "street_address": "\"Ul. Zrecinska 2A.\""
            },
            {
                "__id": 440,
                "address_city": "\"Bia\u0142ystok\"",
                "address_country": "\"PL\"",
                "address_full": "\"\u015awi\u0119toja\u0144ska 15, Bia\u0142ystok, 15-277, Poland\"",
                "address_postcode": "\"15-277\"",
                "delivery": "\"yes\"",
                "latitude": 53.125413,
                "longitude": 23.168691,
                "name": "\"Alfa Bialystok\"",
                "store_type": "\"store\"",
                "street_address": "\"\u015awi\u0119toja\u0144ska 15\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "lidl_fr": {
        "__id": 163,
        "id": "lidl_fr",
        "name": "Lidl France",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'latitude', 'longitude', 'name', 'shop', 'street_address']",
        "rowLength": 1576,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763667,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of lidl_fr locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 1070,
                "address_city": "\"CAPESTANG\"",
                "address_country": "\"FR\"",
                "address_full": "\"avenue de Toulouse, CAPESTANG, 34310, France\"",
                "address_postcode": "\"34310\"",
                "latitude": 43.32523,
                "longitude": 3.03493,
                "name": "\"CAPESTANG Toulouse\"",
                "shop": "\"supermarket\"",
                "street_address": "\"avenue de Toulouse\""
            },
            {
                "__id": 740,
                "address_city": "\"PONTOISE\"",
                "address_country": "\"FR\"",
                "address_full": "\"70 rue de Gisors, PONTOISE, 95300, France\"",
                "address_postcode": "\"95300\"",
                "latitude": 49.05448,
                "longitude": 2.09353,
                "name": "\"PONTOISE\"",
                "shop": "\"supermarket\"",
                "street_address": "\"70 rue de Gisors\""
            },
            {
                "__id": 74,
                "address_city": "\"TOULOUSE\"",
                "address_country": "\"FR\"",
                "address_full": "\"84 rue du F\u00e9r\u00e9tra, TOULOUSE, 31400, France\"",
                "address_postcode": "\"31400\"",
                "latitude": 43.57943,
                "longitude": 1.44335,
                "name": "\"TOULOUSE Feretra\"",
                "shop": "\"supermarket\"",
                "street_address": "\"84 rue du F\u00e9r\u00e9tra\""
            },
            {
                "__id": 1203,
                "address_city": "\"SAINT MANVIEU NORREY\"",
                "address_country": "\"FR\"",
                "address_full": "\"2 route de Caen, SAINT MANVIEU NORREY, 14740, France\"",
                "address_postcode": "\"14740\"",
                "latitude": 49.18135,
                "longitude": -0.48978,
                "name": "\"CAEN ST MAN NORREY\"",
                "shop": "\"supermarket\"",
                "street_address": "\"2 route de Caen\""
            },
            {
                "__id": 1089,
                "address_city": "\"NICE\"",
                "address_country": "\"FR\"",
                "address_full": "\"17 boulevard Gorbella, NICE, 06000, France\"",
                "address_postcode": "\"06000\"",
                "latitude": 43.71878,
                "longitude": 7.25666,
                "name": "\"NICE Gorbella\"",
                "shop": "\"supermarket\"",
                "street_address": "\"17 boulevard Gorbella\""
            },
            {
                "__id": 140,
                "address_city": "\"Vieux Cond\u00e9\"",
                "address_country": "\"FR\"",
                "address_full": "\"1 Rue Jean Jaur\u00e9s, Vieux Cond\u00e9, 59690, France\"",
                "address_postcode": "\"59690\"",
                "latitude": 50.45697,
                "longitude": 3.57307,
                "name": "\"\"",
                "shop": "\"supermarket\"",
                "street_address": "\"1 Rue Jean Jaur\u00e9s\""
            },
            {
                "__id": 256,
                "address_city": "\"Corbeil-Essonnes\"",
                "address_country": "\"FR\"",
                "address_full": "\"24 avenue Paul Maintenant, Corbeil-Essonnes, 91100, France\"",
                "address_postcode": "\"91100\"",
                "latitude": 48.60772,
                "longitude": 2.45082,
                "name": "\"\"",
                "shop": "\"supermarket\"",
                "street_address": "\"24 avenue Paul Maintenant\""
            },
            {
                "__id": 213,
                "address_city": "\"MITRY MORY\"",
                "address_country": "\"FR\"",
                "address_full": "\"36 Avenue de Verdun, MITRY MORY, 77290, France\"",
                "address_postcode": "\"77290\"",
                "latitude": 48.95488,
                "longitude": 2.59794,
                "name": "\"MITRY MORY - Verdun\"",
                "shop": "\"supermarket\"",
                "street_address": "\"36 Avenue de Verdun\""
            },
            {
                "__id": 298,
                "address_city": "\"CLERMONT-FERRAND\"",
                "address_country": "\"FR\"",
                "address_full": "\"177 Rue de l'Oradou, CLERMONT-FERRAND, 63000, France\"",
                "address_postcode": "\"63000\"",
                "latitude": 45.76941,
                "longitude": 3.11421,
                "name": "\"CLERMONT-FERRAND Oradou\"",
                "shop": "\"supermarket\"",
                "street_address": "\"177 Rue de l'Oradou\""
            },
            {
                "__id": 188,
                "address_city": "\"HARNES\"",
                "address_country": "\"FR\"",
                "address_full": "\"36 rue Henri Barbusse, HARNES, 62440, France\"",
                "address_postcode": "\"62440\"",
                "latitude": 50.44748,
                "longitude": 2.9027,
                "name": "\"HARNES Barbusse\"",
                "shop": "\"supermarket\"",
                "street_address": "\"36 rue Henri Barbusse\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "target": {
        "__id": 164,
        "id": "target",
        "name": "Target",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'website']",
        "rowLength": 1947,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763670,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of target locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 902,
                "address_city": "\"Addison\"",
                "address_country": "\"US\"",
                "address_full": "\"3730 Belt Line Rd\"",
                "address_postcode": "\"75001-4301\"",
                "address_state": "\"TX\"",
                "latitude": 32.950951,
                "longitude": -96.853854,
                "name": "\"Target Store - addison\"",
                "opening_hours": "\"Mo-Su 08:00-22:00\"",
                "phone": "\"+12144140025\"",
                "website": "\"https://www.target.com/sl/addison/1850\""
            },
            {
                "__id": 410,
                "address_city": "\"Henderson\"",
                "address_country": "\"US\"",
                "address_full": "\"350 W Lake Mead Pkwy\"",
                "address_postcode": "\"89015-7088\"",
                "address_state": "\"NV\"",
                "latitude": 36.038477,
                "longitude": -114.994098,
                "name": "\"Target Store - henderson-lake-mead-pkwy\"",
                "opening_hours": "\"Mo-Sa 08:00-22:00; Su 08:00-21:00\"",
                "phone": "\"+17022161900\"",
                "website": "\"https://www.target.com/sl/henderson-lake-mead-pkwy/2404\""
            },
            {
                "__id": 769,
                "address_city": "\"Bowie\"",
                "address_country": "\"US\"",
                "address_full": "\"10401 Martin Luther King Jr Hwy\"",
                "address_postcode": "\"20720-4201\"",
                "address_state": "\"MD\"",
                "latitude": 38.953917,
                "longitude": -76.824625,
                "name": "\"Target Store - lanham\"",
                "opening_hours": "\"Mo-Su 08:00-22:00\"",
                "phone": "\"+13019550107\"",
                "website": "\"https://www.target.com/sl/lanham/2007\""
            },
            {
                "__id": 301,
                "address_city": "\"Dekalb\"",
                "address_country": "\"US\"",
                "address_full": "\"2555 Sycamore Rd\"",
                "address_postcode": "\"60115-2051\"",
                "address_state": "\"IL\"",
                "latitude": 41.95511,
                "longitude": -88.725,
                "name": "\"Target Store - dekalb\"",
                "opening_hours": "\"Mo-Sa 08:00-22:00; Su 08:00-21:00\"",
                "phone": "\"+18157876970\"",
                "website": "\"https://www.target.com/sl/dekalb/2559\""
            },
            {
                "__id": 1702,
                "address_city": "\"Lawrenceville\"",
                "address_country": "\"US\"",
                "address_full": "\"875 Lawrenceville Suwanee Rd\"",
                "address_postcode": "\"30043-8479\"",
                "address_state": "\"GA\"",
                "latitude": 33.970133,
                "longitude": -84.014927,
                "name": "\"Target Store - lawrenceville\"",
                "opening_hours": "\"Mo-Su 08:00-22:00\"",
                "phone": "\"+17703384460\"",
                "website": "\"https://www.target.com/sl/lawrenceville/747\""
            },
            {
                "__id": 1862,
                "address_city": "\"Madison Heights\"",
                "address_country": "\"US\"",
                "address_full": "\"32001 John R Rd\"",
                "address_postcode": "\"48071-1322\"",
                "address_state": "\"MI\"",
                "latitude": 42.528515,
                "longitude": -83.108536,
                "name": "\"Target Store - madison-heights\"",
                "opening_hours": "\"Mo-Su 08:00-22:00\"",
                "phone": "\"+12485859000\"",
                "website": "\"https://www.target.com/sl/madison-heights/282\""
            },
            {
                "__id": 445,
                "address_city": "\"Davenport\"",
                "address_country": "\"US\"",
                "address_full": "\"5000 Grandview Pkwy\"",
                "address_postcode": "\"33837-2300\"",
                "address_state": "\"FL\"",
                "latitude": 28.232819,
                "longitude": -81.643913,
                "name": "\"Target Store - ne-polk-county\"",
                "opening_hours": "\"Mo-Su 08:00-22:00\"",
                "phone": "\"+18632561051\"",
                "website": "\"https://www.target.com/sl/ne-polk-county/2366\""
            },
            {
                "__id": 1936,
                "address_city": "\"Austin\"",
                "address_country": "\"US\"",
                "address_full": "\"8601 Research Blvd\"",
                "address_postcode": "\"78758-7121\"",
                "address_state": "\"TX\"",
                "latitude": 30.362314,
                "longitude": -97.715779,
                "name": "\"Target Store - austin-north\"",
                "opening_hours": "\"Mo-Su 08:00-22:00\"",
                "phone": "\"+15128375163\"",
                "website": "\"https://www.target.com/sl/austin-north/95\""
            },
            {
                "__id": 633,
                "address_city": "\"Streetsboro\"",
                "address_country": "\"US\"",
                "address_full": "\"1144 State Route 303\"",
                "address_postcode": "\"44241-5266\"",
                "address_state": "\"OH\"",
                "latitude": 41.237455,
                "longitude": -81.352747,
                "name": "\"Target Store - streetsboro\"",
                "opening_hours": "\"Mo-Sa 08:00-22:00; Su 08:00-21:00\"",
                "phone": "\"+13306266400\"",
                "website": "\"https://www.target.com/sl/streetsboro/2157\""
            },
            {
                "__id": 483,
                "address_city": "\"Syracuse\"",
                "address_country": "\"US\"",
                "address_full": "\"3657 W Genesee St\"",
                "address_postcode": "\"13219-2003\"",
                "address_state": "\"NY\"",
                "latitude": 43.04706,
                "longitude": -76.2362,
                "name": "\"Target Store - camillus\"",
                "opening_hours": "\"Mo-Su 07:00-22:00\"",
                "phone": "\"+13152330600\"",
                "website": "\"https://www.target.com/sl/camillus/2324\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "costacoffee_ie": {
        "__id": 165,
        "id": "costacoffee_ie",
        "name": "Costa Coffee Ireland",
        "columnNames": "['address_country', 'address_full', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'vending']",
        "rowLength": 292,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763670,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of costacoffee_ie locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 118,
                "address_country": "\"IE\"",
                "address_full": "\"Rochestown Rd, Cork, Co. Cork\"",
                "amenity": "\"vending_machine\"",
                "cuisine": "\"\"",
                "latitude": 51.87699,
                "longitude": -8.39426,
                "name": "\"DAYBREAK ROCHESTOWN ROAD\"",
                "opening_hours": "\"24/7\"",
                "vending": "\"coffee\""
            },
            {
                "__id": 134,
                "address_country": "\"IE\"",
                "address_full": "\"Green Street, Callan, Co. Kilkenny\"",
                "amenity": "\"vending_machine\"",
                "cuisine": "\"\"",
                "latitude": 52.54325,
                "longitude": -7.39095,
                "name": "\"SUPERVALU CALLAN\"",
                "opening_hours": "\"24/7\"",
                "vending": "\"coffee\""
            },
            {
                "__id": 121,
                "address_country": "\"IE\"",
                "address_full": "\"16 Kylemore Road, Dublin \"",
                "amenity": "\"vending_machine\"",
                "cuisine": "\"\"",
                "latitude": 53.27888,
                "longitude": -6.10105,
                "name": "\"GO GALA SERVICE STATION\"",
                "opening_hours": "\"24/7\"",
                "vending": "\"coffee\""
            },
            {
                "__id": 243,
                "address_country": "\"IE\"",
                "address_full": "\"Costa Coffee, Tesco Arklow Square, Wexford Road, Abbeylands, Arklow, Co. Wicklow, Y14 NP59\"",
                "amenity": "\"cafe\"",
                "cuisine": "\"coffee_shop\"",
                "latitude": 52.79362,
                "longitude": -6.15836,
                "name": "\"COSTA COFFEE - TESCO ARKLOW\"",
                "opening_hours": "\"Mo-Sa 08:30-17:00; Su 09:30-17:00\"",
                "vending": "\"coffee\""
            },
            {
                "__id": 239,
                "address_country": "\"IE\"",
                "address_full": "\"Costa Coffee, Unit 3, Airside Retail Park, Swords, Co. Dublin, K67 A254\"",
                "amenity": "\"cafe\"",
                "cuisine": "\"coffee_shop\"",
                "latitude": 53.44727,
                "longitude": -6.22632,
                "name": "\"COSTA COFFEE - SWORDS TESCO\"",
                "opening_hours": "\"Mo-We 07:30-18:00; Th-Fr 07:30-19:00; Sa-Su 09:00-18:00\"",
                "vending": "\"coffee\""
            },
            {
                "__id": 7,
                "address_country": "\"IE\"",
                "address_full": "\"Costa Coffee, Units 1A + 1B, Hillcrest Shopping Centre, Lucan, Co. Dublin, K78 A799\"",
                "amenity": "\"cafe\"",
                "cuisine": "\"coffee_shop\"",
                "latitude": 53.35305,
                "longitude": -6.4593,
                "name": "\"COSTA COFFEE- HILLCREST\"",
                "opening_hours": "\"\"",
                "vending": "\"coffee\""
            },
            {
                "__id": 64,
                "address_country": "\"IE\"",
                "address_full": "\"Marshmeadow, New Ross, Co Wexford\"",
                "amenity": "\"vending_machine\"",
                "cuisine": "\"\"",
                "latitude": 52.39581,
                "longitude": -6.94597,
                "name": "\"HENNESSY YOUR STOP FILLING STATION\"",
                "opening_hours": "\"24/7\"",
                "vending": "\"coffee\""
            },
            {
                "__id": 13,
                "address_country": "\"IE\"",
                "address_full": "\"Costcutter Suffolk 118 - 120 Stewartstown Road Belfast BT11 9JQ\"",
                "amenity": "\"vending_machine\"",
                "cuisine": "\"\"",
                "latitude": 54.56811,
                "longitude": -6.0091,
                "name": "\"Costcutter Suffolk\"",
                "opening_hours": "\"24/7\"",
                "vending": "\"coffee\""
            },
            {
                "__id": 276,
                "address_country": "\"IE\"",
                "address_full": "\"Costa Coffee, Kilkenny Retail Park, Ring Road, Springhill, Kilkenny, R95 EF68\"",
                "amenity": "\"cafe\"",
                "cuisine": "\"coffee_shop\"",
                "latitude": 52.63463,
                "longitude": -7.24545,
                "name": "\"COSTA COFFEE - KILKENNY RP\"",
                "opening_hours": "\"Mo-We 08:30-18:00; Th-Fr 08:30-19:00; Sa 09:00-18:00; Su 11:00-18:00\"",
                "vending": "\"coffee\""
            },
            {
                "__id": 122,
                "address_country": "\"IE\"",
                "address_full": "\"C/O JJ O'Driscolls Ballinlough Road, Cork, Co. Cork\"",
                "amenity": "\"vending_machine\"",
                "cuisine": "\"\"",
                "latitude": 51.89553,
                "longitude": -8.46441,
                "name": "\"JJ O'DRISCOLL\"",
                "opening_hours": "\"24/7\"",
                "vending": "\"coffee\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "lidl_ni": {
        "__id": 166,
        "id": "lidl_ni",
        "name": "Lidl Northern Ireland",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'latitude', 'longitude', 'name', 'opening_hours', 'shop', 'street_address']",
        "rowLength": 41,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763671,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of lidl_ni locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 9,
                "address_city": "\"Newtownards\"",
                "address_country": "\"NI\"",
                "address_full": "\"Castlebawn Retail Park Unit 8, Newtownards, BT23 8NN, United Kingdom\"",
                "address_postcode": "\"BT23 8NN\"",
                "latitude": 54.58641,
                "longitude": -5.69372,
                "name": "\"Newtownards\"",
                "opening_hours": "\"Mo-Sa 08:00-21:00; Su 13:00-18:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Castlebawn Retail Park Unit 8\""
            },
            {
                "__id": 41,
                "address_city": "\"Belfast\"",
                "address_country": "\"NI\"",
                "address_full": "\"Hi Park Centre High Street, Belfast, Belfast, BT1 2JZ, United Kingdom\"",
                "address_postcode": "\"BT1 2JZ\"",
                "latitude": 54.60016,
                "longitude": -5.92602,
                "name": "\"Belfast - Hi Park Centre High Street\"",
                "opening_hours": "\"Mo-Sa 08:00-21:00; Su 13:00-18:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Hi Park Centre High Street\""
            },
            {
                "__id": 11,
                "address_city": "\"Limavady\"",
                "address_country": "\"NI\"",
                "address_full": "\"83 Main Street / Connell Street, Limavady, Limavady, BT49 0EP, United Kingdom\"",
                "address_postcode": "\"BT49 0EP\"",
                "latitude": 55.05202,
                "longitude": -6.94935,
                "name": "\"Limavady\"",
                "opening_hours": "\"Mo-Sa 08:00-21:00; Su 13:00-18:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"83 Main Street / Connell Street\""
            },
            {
                "__id": 34,
                "address_city": "\"Enniskillen\"",
                "address_country": "\"NI\"",
                "address_full": "\"U.1 Railway J. Park Holyhill Link, Enniskillen, Enniskillen, BT74 6GG, United Kingdom\"",
                "address_postcode": "\"BT74 6GG\"",
                "latitude": 54.34511,
                "longitude": -7.62943,
                "name": "\"Enniskillen\"",
                "opening_hours": "\"Mo-Sa 08:00-21:00; Su 13:00-18:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"U.1 Railway J. Park Holyhill Link\""
            },
            {
                "__id": 31,
                "address_city": "\"Newcastle\"",
                "address_country": "\"NI\"",
                "address_full": "\"2 Railway Street, Newcastle, Newcastle, BT33 0AL, United Kingdom\"",
                "address_postcode": "\"BT33 0AL\"",
                "latitude": 54.21513,
                "longitude": -5.88761,
                "name": "\"Newcastle\"",
                "opening_hours": "\"Mo-Sa 08:00-21:00; Su 13:00-18:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"2 Railway Street\""
            },
            {
                "__id": 26,
                "address_city": "\"Belfast\"",
                "address_country": "\"NI\"",
                "address_full": "\"100 - 116 Stewartstown Road, Belfast, Belfast, BT11 9JQ, United Kingdom\"",
                "address_postcode": "\"BT11 9JQ\"",
                "latitude": 54.56819,
                "longitude": -6.00864,
                "name": "\"Belfast - Stewartstown Rd\"",
                "opening_hours": "\"Mo-Sa 08:00-21:00; Su 13:00-18:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"100 - 116 Stewartstown Road\""
            },
            {
                "__id": 4,
                "address_city": "\"Lurgan\"",
                "address_country": "\"NI\"",
                "address_full": "\"Pinehurst Retail Park, Lurgan, Lurgan, BT66 6AZ, United Kingdom\"",
                "address_postcode": "\"BT66 6AZ\"",
                "latitude": 54.46064,
                "longitude": -6.33527,
                "name": "\"Kitchen Hill - Lurgan\"",
                "opening_hours": "\"Mo-Sa 08:00-21:00; Su 13:00-18:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Pinehurst Retail Park\""
            },
            {
                "__id": 3,
                "address_city": "\"Banbridge\"",
                "address_country": "\"NI\"",
                "address_full": "\"Newry Road 2a, Banbridge, Banbridge, BT32 3HF, United Kingdom\"",
                "address_postcode": "\"BT32 3HF\"",
                "latitude": 54.34513,
                "longitude": -6.27138,
                "name": "\"Banbridge - Newry Road\"",
                "opening_hours": "\"Mo-Sa 08:00-21:00; Su 13:00-18:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Newry Road 2a\""
            },
            {
                "__id": 1,
                "address_city": "\"Belfast\"",
                "address_country": "\"NI\"",
                "address_full": "\"14 Andersonstown Road, Belfast, Belfast, BT11 9AJ, United Kingdom\"",
                "address_postcode": "\"BT11 9AJ\"",
                "latitude": 54.57895,
                "longitude": -5.97788,
                "name": "\"Belfast - 14 Andersonstown Road\"",
                "opening_hours": "\"Mo-Sa 08:00-21:00; Su 13:00-18:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"14 Andersonstown Road\""
            },
            {
                "__id": 33,
                "address_city": "\"Armagh\"",
                "address_country": "\"NI\"",
                "address_full": "\"Moy Road, Armagh, Armagh, BT61 8DB, United Kingdom\"",
                "address_postcode": "\"BT61 8DB\"",
                "latitude": 54.35646,
                "longitude": -6.66051,
                "name": "\"Armagh\"",
                "opening_hours": "\"Mo-Sa 08:00-21:00; Su 13:00-18:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Moy Road\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "atrium_health": {
        "__id": 167,
        "id": "atrium_health",
        "name": "Atrium Health",
        "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'phone', 'website']",
        "rowLength": 718,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763569,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of atrium_health locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 411,
                "address_city": "\"Concord\"",
                "address_full": "\"5350 Vining St., NW \"",
                "address_postcode": "\"28027\"",
                "address_state": "\"NC\"",
                "latitude": 35.415716,
                "longitude": -80.670513,
                "name": "\"Atrium Health Cabarrus Rehabilitation YMCA\"",
                "phone": "\"704-403-2580\"",
                "website": "\"https://atriumhealth.org/locations/detail/atrium-health-cabarrus-rehabilitation-ymca\""
            },
            {
                "__id": 274,
                "address_city": "\"Belmont\"",
                "address_full": "\"420 Park St.  Suite 105\"",
                "address_postcode": "\"28012\"",
                "address_state": "\"NC\"",
                "latitude": 35.24927,
                "longitude": -81.02969,
                "name": "\"Atrium Health Urgent Care Belmont\"",
                "phone": "\"704-631-1820\"",
                "website": "\"https://atriumhealth.org/locations/detail/atrium-health-urgent-care-belmont\""
            },
            {
                "__id": 604,
                "address_city": "\"Kings Mountain\"",
                "address_full": "\"821 East Kings St. \"",
                "address_postcode": "\"28086\"",
                "address_state": "\"NC\"",
                "latitude": 35.2411652,
                "longitude": -81.3258151,
                "name": "\"Atrium Health Women's Care Shelby OB/GYN\"",
                "phone": "\"980-487-2800\"",
                "website": "\"https://atriumhealth.org/locations/detail/atrium-health-womens-care-shelby-obgyn-kings-mountain\""
            },
            {
                "__id": 204,
                "address_city": "\"Charlotte\"",
                "address_full": "\"101 E. W.T. Harris Blvd. Suite 5401B\"",
                "address_postcode": "\"28262\"",
                "address_state": "\"NC\"",
                "latitude": 35.3051385,
                "longitude": -80.746645,
                "name": "\"Atrium Health Gastroenterology and Hepatology University\"",
                "phone": "\"704-355-8850\"",
                "website": "\"https://atriumhealth.org/locations/detail/atrium-health-gastroenterology-and-hepatology-university\""
            },
            {
                "__id": 697,
                "address_city": "\"Mocksville\"",
                "address_full": "\"1188 Yadkinville Road \"",
                "address_postcode": "\"27028\"",
                "address_state": "\"NC\"",
                "latitude": 35.9142743,
                "longitude": -80.5825103,
                "name": "\"Atrium Health Primary Care One Health Family Medicine & Urgent Care Mocksville\"",
                "phone": "\"336-716-7435\"",
                "website": "\"https://atriumhealth.org/locations/detail/atrium-health-primary-care-one-health-family-medicine-and-urgent-care-mocksville\""
            },
            {
                "__id": 433,
                "address_city": "\"Davidson\"",
                "address_full": "\"705 Griffith St. Suite 100\"",
                "address_postcode": "\"28036\"",
                "address_state": "\"NC\"",
                "latitude": 35.50264,
                "longitude": -80.86419,
                "name": "\"Atrium Health Primary Care Davidson Clinic\"",
                "phone": "\"704-801-7900\"",
                "website": "\"https://atriumhealth.org/locations/detail/atrium-health-primary-care-davidson-clinic\""
            },
            {
                "__id": 321,
                "address_city": "\"Charlotte\"",
                "address_full": "\"13640 Steelecroft Parkway Suite 210\"",
                "address_postcode": "\"28278\"",
                "address_state": "\"NC\"",
                "latitude": 35.09826,
                "longitude": -80.9922,
                "name": "\"Atrium Health Levine Children's Charlotte Pediatrics\"",
                "phone": "\"704-512-6100\"",
                "website": "\"https://atriumhealth.org/medical-services/childrens-services/locations/detail/atrium-health-levine-childrens-charlotte-pediatrics-steele-creek\""
            },
            {
                "__id": 316,
                "address_city": "\"Charlotte\"",
                "address_full": "\"13640 Steelecroft Parkway \"",
                "address_postcode": "\"28278\"",
                "address_state": "\"NC\"",
                "latitude": 35.0992771,
                "longitude": -80.9923688,
                "name": "\"Atrium Health Endocrinology Steele Creek\"",
                "phone": "\"980-487-2270\"",
                "website": "\"https://atriumhealth.org/locations/detail/atrium-health-endocrinology-steele-creek\""
            },
            {
                "__id": 580,
                "address_city": "\"Monroe\"",
                "address_full": "\"1423 E. Franklin St. Suite D\"",
                "address_postcode": "\"28112\"",
                "address_state": "\"NC\"",
                "latitude": 34.97407,
                "longitude": -80.52155,
                "name": "\"Atrium Health Primary Care Dove Internal Medicine\"",
                "phone": "\"704-289-6474\"",
                "website": "\"https://atriumhealth.org/locations/detail/atrium-health-primary-care-dove-internal-medicine-monroe\""
            },
            {
                "__id": 179,
                "address_city": "\"Charlotte\"",
                "address_full": "\"4525 Cameron Valley Parkway \"",
                "address_postcode": "\"28211\"",
                "address_state": "\"NC\"",
                "latitude": 35.1476599,
                "longitude": -80.8238967,
                "name": "\"Atrium Health Diabetes & Nutrition Care\"",
                "phone": "\"704-302-9462\"",
                "website": "\"https://atriumhealth.org/locations/detail/atrium-health-diabetes-and-nutrition-care-southpark\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "my_eye_dr": {
        "__id": 168,
        "id": "my_eye_dr",
        "name": "My Eye Dr",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'latitude', 'longitude', 'phone', 'website']",
        "rowLength": 850,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763569,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of my_eye_dr locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 249,
                "address_city": "\"Bartow\"",
                "address_country": "\"US\"",
                "address_full": "\"1695 North Broadway\"",
                "address_postcode": "\"33830\"",
                "address_state": "\"FL\"",
                "latitude": 27.91113778850527,
                "longitude": -81.84425575392487,
                "phone": "\"(863) 534-2020\"",
                "website": "\"https://locations.myeyedr.com/fl/bartow/1695-north-broadway\""
            },
            {
                "__id": 500,
                "address_city": "\"Niles\"",
                "address_country": "\"US\"",
                "address_full": "\"1421 S 11th St.\"",
                "address_postcode": "\"49120\"",
                "address_state": "\"MI\"",
                "latitude": 41.81128537647639,
                "longitude": -86.24760916556322,
                "phone": "\"(269) 684-1330\"",
                "website": "\"https://locations.myeyedr.com/mi/niles/1421-s-11th-st\""
            },
            {
                "__id": 137,
                "address_city": "\"Birmingham\"",
                "address_country": "\"US\"",
                "address_full": "\"2737 Highway 280 South\"",
                "address_postcode": "\"35223\"",
                "address_state": "\"AL\"",
                "latitude": 33.4802069,
                "longitude": -86.7733516,
                "phone": "\"(205) 802-2020\"",
                "website": "\"https://locations.myeyedr.com/al/birmingham/2737-highway-280-south\""
            },
            {
                "__id": 466,
                "address_city": "\"Mt. Pleasant\"",
                "address_country": "\"US\"",
                "address_full": "\"1606 South Jefferson Avenue\"",
                "address_postcode": "\"75455\"",
                "address_state": "\"TX\"",
                "latitude": 33.140492,
                "longitude": -94.968894,
                "phone": "\"(903) 572-4000\"",
                "website": "\"https://locations.myeyedr.com/tx/mt-pleasant/1606-south-jefferson-avenue\""
            },
            {
                "__id": 680,
                "address_city": "\"Cincinnati\"",
                "address_country": "\"US\"",
                "address_full": "\"8340 Colerain Avenue\"",
                "address_postcode": "\"45239\"",
                "address_state": "\"OH\"",
                "latitude": 39.2227154086638,
                "longitude": -84.58642730096261,
                "phone": "\"(513) 245-9099\"",
                "website": "\"https://locations.myeyedr.com/oh/cincinnati/8340-colerain-avenue\""
            },
            {
                "__id": 201,
                "address_city": "\"Palm Harbor\"",
                "address_country": "\"US\"",
                "address_full": "\"36021 US Hwy 19N\"",
                "address_postcode": "\"34684\"",
                "address_state": "\"FL\"",
                "latitude": 28.099899397486173,
                "longitude": -82.7388441562664,
                "phone": "\"(727) 772-1000\"",
                "website": "\"https://locations.myeyedr.com/fl/palm-harbor/36021-us-hwy-19n\""
            },
            {
                "__id": 173,
                "address_city": "\"Monroe\"",
                "address_country": "\"US\"",
                "address_full": "\"535 Monroe Turnpike\"",
                "address_postcode": "\"06468\"",
                "address_state": "\"CT\"",
                "latitude": 41.319276715375544,
                "longitude": -73.2161492866169,
                "phone": "\"(203) 268-7799\"",
                "website": "\"https://locations.myeyedr.com/ct/monroe/535-monroe-turnpike\""
            },
            {
                "__id": 700,
                "address_city": "\"Lancaster\"",
                "address_country": "\"US\"",
                "address_full": "\"180 Good Dr\"",
                "address_postcode": "\"17603\"",
                "address_state": "\"PA\"",
                "latitude": 40.04342630049994,
                "longitude": -76.35948657989508,
                "phone": "\"(717) 397-2020\"",
                "website": "\"https://locations.myeyedr.com/pa/lancaster/180-good-dr\""
            },
            {
                "__id": 631,
                "address_city": "\"Cary\"",
                "address_country": "\"US\"",
                "address_full": "\"2346 Walnut St\"",
                "address_postcode": "\"27518\"",
                "address_state": "\"NC\"",
                "latitude": 35.7546489,
                "longitude": -78.7453504,
                "phone": "\"(919) 851-0093\"",
                "website": "\"https://locations.myeyedr.com/nc/cary/2346-walnut-st\""
            },
            {
                "__id": 451,
                "address_city": "\"Bel Air\"",
                "address_country": "\"US\"",
                "address_full": "\"5 Bel Air South Pkwy\"",
                "address_postcode": "\"21015\"",
                "address_state": "\"MD\"",
                "latitude": 39.49335985724777,
                "longitude": -76.32534877227044,
                "phone": "\"(410) 569-8113\"",
                "website": "\"https://locations.myeyedr.com/md/bel-air/5-bel-air-south-pkwy\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "providence_health_services": {
        "__id": 169,
        "id": "providence_health_services",
        "name": "Providence Health Services",
        "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'phone', 'street_address', 'website']",
        "rowLength": 9,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763570,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of providence_health_services locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 7,
                "address_city": "\"Santa Monica\"",
                "address_postcode": "\"90404\"",
                "address_state": "\"California\"",
                "latitude": 34.0307739,
                "longitude": -118.4796445,
                "name": "\"Providence Saint Johns Health Center\"",
                "phone": "\"310-829-5511\"",
                "street_address": "\"2121 Santa Monica Blvd\"",
                "website": "\"https://www.providence.org/locations/saint-johns-health-center\""
            },
            {
                "__id": 2,
                "address_city": "\"Tarzana\"",
                "address_postcode": "\"91356\"",
                "address_state": "\"California\"",
                "latitude": 34.1710084,
                "longitude": -118.5320475,
                "name": "\"Providence Tarzana Medical Center\"",
                "phone": "\"818-881-0800\"",
                "street_address": "\"18321 Clark St\"",
                "website": "\"https://www.providence.org/locations/tarzana-medical-center\""
            },
            {
                "__id": 4,
                "address_city": "\"Apple Valley\"",
                "address_postcode": "\"92307\"",
                "address_state": "\"California\"",
                "latitude": 34.5418476,
                "longitude": -117.2655636,
                "name": "\"St. Mary Medical Center\"",
                "phone": "\"760-242-2311 \"",
                "street_address": "\"18300 Highway 18\"",
                "website": "\"https://www.providence.org/locations/st-mary-medical-center\""
            },
            {
                "__id": 3,
                "address_city": "\"Orange\"",
                "address_postcode": "\"92868\"",
                "address_state": "\"California\"",
                "latitude": 33.7820441,
                "longitude": -117.8654245,
                "name": "\"St Joseph Hospital Orange\"",
                "phone": "\"714-771-8000\"",
                "street_address": "\"1100 W Stewart Dr\"",
                "website": "\"https://www.providence.org/locations/st-joseph-hospital-orange\""
            },
            {
                "__id": 9,
                "address_city": "\"San Pedro\"",
                "address_postcode": "\"90732\"",
                "address_state": "\"California\"",
                "latitude": 33.7382583,
                "longitude": -118.305098,
                "name": "\"Providence Little Company of Mary Medical Center - San Pedro\"",
                "phone": "\"310-832-3311\"",
                "street_address": "\"1300 W 7th St\"",
                "website": "\"https://www.providence.org/locations/plcm-san-pedro\""
            },
            {
                "__id": 5,
                "address_city": "\"Burbank\"",
                "address_postcode": "\"91505\"",
                "address_state": "\"California\"",
                "latitude": 34.1562138,
                "longitude": -118.3284546,
                "name": "\"Providence Saint Joseph Medical Center\"",
                "phone": "\"818-843-5111\"",
                "street_address": "\"501 S Buena Vista St\"",
                "website": "\"https://www.providence.org/locations/saint-joseph-medical-center\""
            },
            {
                "__id": 6,
                "address_city": "\"Fullerton\"",
                "address_postcode": "\"92835\"",
                "address_state": "\"California\"",
                "latitude": 33.8943451,
                "longitude": -117.9272201,
                "name": "\"St Jude Medical Center\"",
                "phone": "\"714-871-3280\"",
                "street_address": "\"101 E Valencia Mesa Dr\"",
                "website": "\"https://www.providence.org/locations/st-jude-medical-center\""
            },
            {
                "__id": 8,
                "address_city": "\"Torrance\"",
                "address_postcode": "\"90503\"",
                "address_state": "\"California\"",
                "latitude": 33.8384678,
                "longitude": -118.3572436,
                "name": "\"Providence Little Company of Mary Medical Center - Torrance\"",
                "phone": "\"310-540-7676\"",
                "street_address": "\"4101 Torrance Blvd\"",
                "website": "\"https://www.providence.org/locations/plcm-torrance\""
            },
            {
                "__id": 1,
                "address_city": "\"Great Falls\"",
                "address_postcode": "\"59405\"",
                "address_state": "\"Montana\"",
                "latitude": 47.486702,
                "longitude": -111.2997763,
                "name": "\"St Thomas Child and Family Center\"",
                "phone": "\"406-761-6538\"",
                "street_address": "\"1710 Benefis Court\"",
                "website": "\"https://www.providence.org/locations/st-thomas-child-and-family-center\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "vallarta": {
        "__id": 170,
        "id": "vallarta",
        "name": "Vallarta",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'cuisine', 'name', 'official_name', 'opening_hours', 'phone', 'shop', 'website']",
        "rowLength": 53,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763571,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of vallarta locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 2,
                "address_city": "\"Lancaster\"",
                "address_country": "\"US\"",
                "address_full": "\"1801 West Ave. I\"",
                "address_postcode": "\"93534\"",
                "address_state": "\"CA\"",
                "cuisine": "\"latin_american\"",
                "name": "\"Lancaster #14\"",
                "official_name": "\"Vallarta Supermarkets\"",
                "opening_hours": "\"Mo-Su 07:00-22:00\"",
                "phone": "\"(661) 951-0233\"",
                "shop": "\"supermarket\"",
                "website": "\"https://vallartasupermarkets.com/store-locations/lancaster-14/\""
            },
            {
                "__id": 51,
                "address_city": "\"Delano\"",
                "address_country": "\"US\"",
                "address_full": "\"1840 Cecil Ave.\"",
                "address_postcode": "\"93215\"",
                "address_state": "\"CA\"",
                "cuisine": "\"latin_american\"",
                "name": "\"Delano #23\"",
                "official_name": "\"Vallarta Supermarkets\"",
                "opening_hours": "\"Mo-Su 07:00-22:00\"",
                "phone": "\"(661) 725-9283\"",
                "shop": "\"supermarket\"",
                "website": "\"https://vallartasupermarkets.com/store-locations/delano-23/\""
            },
            {
                "__id": 52,
                "address_city": "\"Palmdale\"",
                "address_country": "\"US\"",
                "address_full": "\"1803 E. Palmdale Blvd.\"",
                "address_postcode": "\"93550\"",
                "address_state": "\"CA\"",
                "cuisine": "\"latin_american\"",
                "name": "\"Palmdale #20\"",
                "official_name": "\"Vallarta Supermarkets\"",
                "opening_hours": "\"Mo-Su 07:00-22:00\"",
                "phone": "\"(661) 273-1120\"",
                "shop": "\"supermarket\"",
                "website": "\"https://vallartasupermarkets.com/store-locations/palmdale-20/\""
            },
            {
                "__id": 39,
                "address_city": "\"Porterville\"",
                "address_country": "\"US\"",
                "address_full": "\"305 E. Olive Ave\"",
                "address_postcode": "\"93257\"",
                "address_state": "\"CA\"",
                "cuisine": "\"latin_american\"",
                "name": "\"Porterville #32\"",
                "official_name": "\"Vallarta Supermarkets\"",
                "opening_hours": "\"Mo-Su 07:00-22:00\"",
                "phone": "\"(559) 306-1325\"",
                "shop": "\"supermarket\"",
                "website": "\"https://vallartasupermarkets.com/store-locations/porterville-32/\""
            },
            {
                "__id": 53,
                "address_city": "\"Bakersfield\"",
                "address_country": "\"US\"",
                "address_full": "\"5951 East Niles Ave\"",
                "address_postcode": "\"93306\"",
                "address_state": "\"CA\"",
                "cuisine": "\"latin_american\"",
                "name": "\"Bakersfield #16\"",
                "official_name": "\"Vallarta Supermarkets\"",
                "opening_hours": "\"Mo-Su 07:00-22:00\"",
                "phone": "\"(661) 366-4916\"",
                "shop": "\"supermarket\"",
                "website": "\"https://vallartasupermarkets.com/store-locations/bakersfield-16/\""
            },
            {
                "__id": 5,
                "address_city": "\"Fresno\"",
                "address_country": "\"US\"",
                "address_full": "\"4831 E Butler Ave.\"",
                "address_postcode": "\"93727\"",
                "address_state": "\"CA\"",
                "cuisine": "\"latin_american\"",
                "name": "\"Fresno #10\"",
                "official_name": "\"Vallarta Supermarkets\"",
                "opening_hours": "\"Mo-Su 07:00-22:00\"",
                "phone": "\"(559) 455-0179\"",
                "shop": "\"supermarket\"",
                "website": "\"https://vallartasupermarkets.com/store-locations/fresno-10/\""
            },
            {
                "__id": 8,
                "address_city": "\"Van Nuys\"",
                "address_country": "\"US\"",
                "address_full": "\"13715 Vanowen St\"",
                "address_postcode": "\"91405\"",
                "address_state": "\"CA\"",
                "cuisine": "\"latin_american\"",
                "name": "\"Van Nuys #2\"",
                "official_name": "\"Vallarta Supermarkets\"",
                "opening_hours": "\"Mo-Su 07:00-22:00\"",
                "phone": "\"(818) 786-4703\"",
                "shop": "\"supermarket\"",
                "website": "\"https://vallartasupermarkets.com/store-locations/van-nuys-2/\""
            },
            {
                "__id": 22,
                "address_city": "\"Winnetka\"",
                "address_country": "\"US\"",
                "address_full": "\"19725 Vanowen St.\"",
                "address_postcode": "\"91306\"",
                "address_state": "\"CA\"",
                "cuisine": "\"latin_american\"",
                "name": "\"Winnetka #24\"",
                "official_name": "\"Vallarta Supermarkets\"",
                "opening_hours": "\"Mo-Su 07:00-23:00\"",
                "phone": "\"(818) 716-5704\"",
                "shop": "\"supermarket\"",
                "website": "\"https://vallartasupermarkets.com/store-locations/winnetka-24/\""
            },
            {
                "__id": 34,
                "address_city": "\"Fresno\"",
                "address_country": "\"US\"",
                "address_full": "\"1951 West Clinton Avenue\"",
                "address_postcode": "\"93705\"",
                "address_state": "\"CA\"",
                "cuisine": "\"latin_american\"",
                "name": "\"Fresno #49\"",
                "official_name": "\"Vallarta Supermarkets\"",
                "opening_hours": "\"Mo-Su 07:00-22:00\"",
                "phone": "\"(559) 476-3920\"",
                "shop": "\"supermarket\"",
                "website": "\"https://vallartasupermarkets.com/store-locations/fresno-49/\""
            },
            {
                "__id": 24,
                "address_city": "\"Whittier\"",
                "address_country": "\"US\"",
                "address_full": "\"8510 Painter Ave Suite 'L'\"",
                "address_postcode": "\"90602\"",
                "address_state": "\"CA\"",
                "cuisine": "\"latin_american\"",
                "name": "\"Whittier #50\"",
                "official_name": "\"Vallarta Supermarkets\"",
                "opening_hours": "\"Mo-Su 07:00-22:00\"",
                "phone": "\"(562) 758-6464\"",
                "shop": "\"supermarket\"",
                "website": "\"https://vallartasupermarkets.com/store-locations/whittier-50/\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "rossmann_de": {
        "__id": 171,
        "id": "rossmann_de",
        "name": "Rossmann Germany",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'street_address', 'website']",
        "rowLength": 2146,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763572,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of rossmann_de locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 645,
                "address_city": "\"M\u00fclheim\"",
                "address_country": "\"DE\"",
                "address_postcode": "\"45475\"",
                "latitude": 51.457936694059,
                "longitude": 6.884853529812,
                "name": "\"Rossmann Drogerie-Markt Heifeskamp 6\"",
                "opening_hours": "\"\"",
                "phone": "\"0208-98964993\"",
                "street_address": "\"Heifeskamp 6\"",
                "website": "\"https://www.rossmann.de/de/filialen/nordrhein-westfalen/muelheim/heifeskamp-6.html\""
            },
            {
                "__id": 849,
                "address_city": "\"D\u00fcsseldorf\"",
                "address_country": "\"DE\"",
                "address_postcode": "\"40549\"",
                "latitude": 51.230210883248,
                "longitude": 6.708488660367,
                "name": "\"Rossmann Drogerie-Markt Wiesenstr. 30\"",
                "opening_hours": "\"Mo-Sa 08:00-20:00\"",
                "phone": "\"0211-50653670\"",
                "street_address": "\"Wiesenstr. 30\"",
                "website": "\"https://www.rossmann.de/de/filialen/nordrhein-westfalen/duesseldorf/wiesenstr--30.html\""
            },
            {
                "__id": 1854,
                "address_city": "\"Waldsassen\"",
                "address_country": "\"DE\"",
                "address_postcode": "\"95652\"",
                "latitude": 50.001660507707,
                "longitude": 12.299470487245,
                "name": "\"Rossmann Drogerie-Markt Mitterteicher Str. 1\"",
                "opening_hours": "\"Mo-Sa 08:00-20:00\"",
                "phone": "\"09632-916520\"",
                "street_address": "\"Mitterteicher Str. 1\"",
                "website": "\"https://www.rossmann.de/de/filialen/bayern/waldsassen/mitterteicher-str--1.html\""
            },
            {
                "__id": 1846,
                "address_city": "\"Wendelstein\"",
                "address_country": "\"DE\"",
                "address_postcode": "\"90530\"",
                "latitude": 49.352293978696,
                "longitude": 11.162357171241,
                "name": "\"Rossmann Drogerie-Markt Richtweg 60\"",
                "opening_hours": "\"Mo-Sa 08:00-20:00\"",
                "phone": "\"09129-9060364\"",
                "street_address": "\"Richtweg 60\"",
                "website": "\"https://www.rossmann.de/de/filialen/bayern/wendelstein/richtweg-60.html\""
            },
            {
                "__id": 1352,
                "address_city": "\"Rostock\"",
                "address_country": "\"DE\"",
                "address_postcode": "\"18069\"",
                "latitude": 54.100010231833,
                "longitude": 12.073238017492,
                "name": "\"Rossmann Drogerie-Markt Goerdelerstr. 51\"",
                "opening_hours": "\"Mo-Sa 08:00-20:00\"",
                "phone": "\"0381-8001267\"",
                "street_address": "\"Goerdelerstr. 51\"",
                "website": "\"https://www.rossmann.de/de/filialen/mecklenburg-vorpommern/rostock/goerdelerstr--51.html\""
            },
            {
                "__id": 702,
                "address_city": "\"Langenfeld\"",
                "address_country": "\"DE\"",
                "address_postcode": "\"40764\"",
                "latitude": 51.111434967914,
                "longitude": 6.949199352429,
                "name": "\"Rossmann Drogerie-Markt Galerieplatz 1\"",
                "opening_hours": "\"Mo-Fr 08:00-20:00; Sa 08:00-18:00\"",
                "phone": "\"02173-912295\"",
                "street_address": "\"Galerieplatz 1\"",
                "website": "\"https://www.rossmann.de/de/filialen/nordrhein-westfalen/langenfeld/galerieplatz-1.html\""
            },
            {
                "__id": 1101,
                "address_city": "\"Osnabr\u00fcck\"",
                "address_country": "\"DE\"",
                "address_postcode": "\"49074\"",
                "latitude": 52.274117146346,
                "longitude": 8.047760342319,
                "name": "\"Rossmann Drogerie-Markt Gro\u00dfe Str. 17-19\"",
                "opening_hours": "\"Mo-Sa 09:00-20:00\"",
                "phone": "\"0541-258717\"",
                "street_address": "\"Gro\u00dfe Str. 17-19\"",
                "website": "\"https://www.rossmann.de/de/filialen/niedersachsen/osnabrueck/grosse-str--17-19.html\""
            },
            {
                "__id": 507,
                "address_city": "\"Altenkirchen\"",
                "address_country": "\"DE\"",
                "address_postcode": "\"57610\"",
                "latitude": 50.686876000855,
                "longitude": 7.637556854184,
                "name": "\"Rossmann Drogerie-Markt K\u00f6lner Str. 30\"",
                "opening_hours": "\"Mo-Sa 09:00-19:30\"",
                "phone": "\"02681-7864999\"",
                "street_address": "\"K\u00f6lner Str. 30\"",
                "website": "\"https://www.rossmann.de/de/filialen/rheinland-pfalz/altenkirchen/koelner-str--30.html\""
            },
            {
                "__id": 1186,
                "address_city": "\"Hannover\"",
                "address_country": "\"DE\"",
                "address_postcode": "\"30161\"",
                "latitude": 52.377762081145,
                "longitude": 9.741417890076,
                "name": "\"Rossmann Drogerie-Markt Raschplatz 1\"",
                "opening_hours": "\"Mo-Sa 06:00-24:00; Su 08:00-23:00\"",
                "phone": "\"0511-309072\"",
                "street_address": "\"Raschplatz 1\"",
                "website": "\"https://www.rossmann.de/de/filialen/niedersachsen/hannover/raschplatz-1.html\""
            },
            {
                "__id": 2079,
                "address_city": "\"Ergoldsbach\"",
                "address_country": "\"DE\"",
                "address_postcode": "\"84061\"",
                "latitude": 48.683596899887,
                "longitude": 12.2039321903,
                "name": "\"Rossmann Drogerie-Markt Landshuter Str. 26\"",
                "opening_hours": "\"Mo-Sa 08:00-20:00\"",
                "phone": "\"08771-9679995\"",
                "street_address": "\"Landshuter Str. 26\"",
                "website": "\"https://www.rossmann.de/de/filialen/bayern/ergoldsbach/landshuter-str--26.html\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "holiday_stationstores": {
        "__id": 172,
        "id": "holiday_stationstores",
        "name": "Holiday Stationstores",
        "columnNames": "['address_city', 'address_full', 'address_state', 'amenity_fuel', 'atm', 'car_wash', 'fuel_cng', 'fuel_diesel', 'fuel_e85', 'fuel_propane', 'hgv', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'website']",
        "rowLength": 566,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763573,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of holiday_stationstores locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 304,
                "address_city": "\"Ironwood\"",
                "address_full": "\"805 E. Cloverland Dr.\"",
                "address_state": "\"MI\"",
                "amenity_fuel": 0,
                "atm": 0,
                "car_wash": "\"\"",
                "fuel_cng": "\"\"",
                "fuel_diesel": "True",
                "fuel_e85": "\"\"",
                "fuel_propane": "\"\"",
                "hgv": "\"\"",
                "latitude": 46.4630153,
                "longitude": -90.1560147,
                "name": "\"Holiday #112\"",
                "opening_hours": "\"24/7\"",
                "phone": "\"906-932-1128\"",
                "website": "\"https://www.holidaystationstores.com/Locations/Detail?storeNumber=112\""
            },
            {
                "__id": 397,
                "address_city": "\"Eau Claire\"",
                "address_full": "\"2611 Birch Street\"",
                "address_state": "\"WI\"",
                "amenity_fuel": 0,
                "atm": 0,
                "car_wash": "\"\"",
                "fuel_cng": "\"\"",
                "fuel_diesel": "True",
                "fuel_e85": "\"\"",
                "fuel_propane": "\"\"",
                "hgv": "\"\"",
                "latitude": 44.8208696,
                "longitude": -91.472416,
                "name": "\"Holiday #3736\"",
                "opening_hours": "\"Mo-Su 07:00-20:00\"",
                "phone": "\"715-830-0950\"",
                "website": "\"https://www.holidaystationstores.com/Locations/Detail?storeNumber=3736\""
            },
            {
                "__id": 429,
                "address_city": "\"Duluth\"",
                "address_full": "\"2605 London Road\"",
                "address_state": "\"MN\"",
                "amenity_fuel": 0,
                "atm": 0,
                "car_wash": "\"\"",
                "fuel_cng": "\"\"",
                "fuel_diesel": "True",
                "fuel_e85": "\"\"",
                "fuel_propane": "\"\"",
                "hgv": "\"\"",
                "latitude": 46.8093978,
                "longitude": -92.0613785,
                "name": "\"Holiday #327\"",
                "opening_hours": "\"24/7\"",
                "phone": "\"218-724-7789\"",
                "website": "\"https://www.holidaystationstores.com/Locations/Detail?storeNumber=327\""
            },
            {
                "__id": 350,
                "address_city": "\"Glencoe\"",
                "address_full": "\"2211 E. 11th Street\"",
                "address_state": "\"MN\"",
                "amenity_fuel": 0,
                "atm": 0,
                "car_wash": "\"\"",
                "fuel_cng": "\"\"",
                "fuel_diesel": "\"\"",
                "fuel_e85": "\"\"",
                "fuel_propane": "True",
                "hgv": "\"\"",
                "latitude": 44.7715743,
                "longitude": -94.1333241,
                "name": "\"Holiday #3627\"",
                "opening_hours": "\"24/7\"",
                "phone": "\"\"",
                "website": "\"https://www.holidaystationstores.com/Locations/Detail?storeNumber=3627\""
            },
            {
                "__id": 565,
                "address_city": "\"Aberdeen\"",
                "address_full": "\"702 6th Avenue SE\"",
                "address_state": "\"SD\"",
                "amenity_fuel": 0,
                "atm": 0,
                "car_wash": "True",
                "fuel_cng": "\"\"",
                "fuel_diesel": "True",
                "fuel_e85": "\"\"",
                "fuel_propane": "\"\"",
                "hgv": "\"\"",
                "latitude": 45.4586592,
                "longitude": -98.4773016,
                "name": "\"Holiday #3501\"",
                "opening_hours": "\"24/7\"",
                "phone": "\"605-725-2500\"",
                "website": "\"https://www.holidaystationstores.com/Locations/Detail?storeNumber=3501\""
            },
            {
                "__id": 503,
                "address_city": "\"Bloomington\"",
                "address_full": "\"4567 American Blvd W\"",
                "address_state": "\"MN\"",
                "amenity_fuel": 0,
                "atm": 0,
                "car_wash": "\"\"",
                "fuel_cng": "\"\"",
                "fuel_diesel": "\"\"",
                "fuel_e85": "\"\"",
                "fuel_propane": "\"\"",
                "hgv": "\"\"",
                "latitude": 44.8571073,
                "longitude": -93.3359446,
                "name": "\"Holiday #297\"",
                "opening_hours": "\"24/7\"",
                "phone": "\"\"",
                "website": "\"https://www.holidaystationstores.com/Locations/Detail?storeNumber=297\""
            },
            {
                "__id": 305,
                "address_city": "\"Iron River\"",
                "address_full": "\"19 West Adams\"",
                "address_state": "\"MI\"",
                "amenity_fuel": 0,
                "atm": 0,
                "car_wash": "\"\"",
                "fuel_cng": "\"\"",
                "fuel_diesel": "True",
                "fuel_e85": "\"\"",
                "fuel_propane": "\"\"",
                "hgv": "\"\"",
                "latitude": 46.0921711,
                "longitude": -88.6386193,
                "name": "\"Holiday #152\"",
                "opening_hours": "\"Mo-Su 04:00-23:00\"",
                "phone": "\"906-265-5958\"",
                "website": "\"https://www.holidaystationstores.com/Locations/Detail?storeNumber=152\""
            },
            {
                "__id": 58,
                "address_city": "\"Virginia\"",
                "address_full": "\"1502 South 12th Avenue West\"",
                "address_state": "\"MN\"",
                "amenity_fuel": 0,
                "atm": 0,
                "car_wash": "True",
                "fuel_cng": "\"\"",
                "fuel_diesel": "True",
                "fuel_e85": "\"\"",
                "fuel_propane": "\"\"",
                "hgv": "\"\"",
                "latitude": 47.5107134,
                "longitude": -92.5514668,
                "name": "\"Holiday #41\"",
                "opening_hours": "\"24/7\"",
                "phone": "\"218-749-5985\"",
                "website": "\"https://www.holidaystationstores.com/Locations/Detail?storeNumber=41\""
            },
            {
                "__id": 187,
                "address_city": "\"Pine River\"",
                "address_full": "\"300 Front Street Box 26\"",
                "address_state": "\"MN\"",
                "amenity_fuel": 0,
                "atm": 0,
                "car_wash": "\"\"",
                "fuel_cng": "\"\"",
                "fuel_diesel": "True",
                "fuel_e85": "\"\"",
                "fuel_propane": "\"\"",
                "hgv": "\"\"",
                "latitude": 46.7166337,
                "longitude": -94.4014217,
                "name": "\"Holiday #164\"",
                "opening_hours": "\"24/7\"",
                "phone": "\"218-587-2821\"",
                "website": "\"https://www.holidaystationstores.com/Locations/Detail?storeNumber=164\""
            },
            {
                "__id": 222,
                "address_city": "\"Munising\"",
                "address_full": "\"301 E. Munising\"",
                "address_state": "\"MI\"",
                "amenity_fuel": 0,
                "atm": 0,
                "car_wash": "True",
                "fuel_cng": "\"\"",
                "fuel_diesel": "True",
                "fuel_e85": "\"\"",
                "fuel_propane": "\"\"",
                "hgv": "\"\"",
                "latitude": 46.4104674,
                "longitude": -86.6510319,
                "name": "\"Holiday #155\"",
                "opening_hours": "\"24/7\"",
                "phone": "\"906-387-4872\"",
                "website": "\"https://www.holidaystationstores.com/Locations/Detail?storeNumber=155\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "palm_beach_tan": {
        "__id": 173,
        "id": "palm_beach_tan",
        "name": "Palm Beach Tan",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'beauty', 'latitude', 'longitude', 'name', 'phone', 'shop', 'website']",
        "rowLength": 545,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763574,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of palm_beach_tan locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 108,
                "address_city": "\"Hammond\"",
                "address_country": "\"US\"",
                "address_full": "\"1913 SW Railroad Ave Ste B\"",
                "address_postcode": "\"70403\"",
                "address_state": "\"LA\"",
                "beauty": "\"tanning\"",
                "latitude": 30.4826247,
                "longitude": -90.4563916,
                "name": "\"Hammond\"",
                "phone": "\"985-662-7099\"",
                "shop": "\"beauty\"",
                "website": "\"https://palmbeachtan.com/locations/LA/Hammond-Southwest-Railroad-Avenue/\""
            },
            {
                "__id": 320,
                "address_city": "\"West Chester\"",
                "address_country": "\"US\"",
                "address_full": "\"7737 Cox Lane\"",
                "address_postcode": "\"45069\"",
                "address_state": "\"OH\"",
                "beauty": "\"tanning\"",
                "latitude": 39.3534062,
                "longitude": -84.3665873,
                "name": "\"West Chester\"",
                "phone": "\"513-779-9898\"",
                "shop": "\"beauty\"",
                "website": "\"https://palmbeachtan.com/locations/OH/West-Chester-Cox-Lane/\""
            },
            {
                "__id": 249,
                "address_city": "\"Jonesboro\"",
                "address_country": "\"US\"",
                "address_full": "\"3410 E. Johnson Rd\"",
                "address_postcode": "\"72405\"",
                "address_state": "\"AR\"",
                "beauty": "\"tanning\"",
                "latitude": 35.8582729,
                "longitude": -90.6580161,
                "name": "\"Hilltop\"",
                "phone": "\"870-910-8267\"",
                "shop": "\"beauty\"",
                "website": "\"https://palmbeachtan.com/locations/AR/Jonesboro-Johnson-Road/\""
            },
            {
                "__id": 147,
                "address_city": "\"Hattiesburg\"",
                "address_country": "\"US\"",
                "address_full": "\"6117 US Hwy 98 Suite 50\"",
                "address_postcode": "\"39402\"",
                "address_state": "\"MS\"",
                "beauty": "\"tanning\"",
                "latitude": 31.321093,
                "longitude": -89.3853381,
                "name": "\"Turtle Creek Crossing\"",
                "phone": "\"601-296-2000\"",
                "shop": "\"beauty\"",
                "website": "\"https://palmbeachtan.com/locations/MS/Hattiesburg-US-Highway-98/\""
            },
            {
                "__id": 297,
                "address_city": "\"Morganton\"",
                "address_country": "\"US\"",
                "address_full": "\"208 W Fleming Dr\"",
                "address_postcode": "\"28655\"",
                "address_state": "\"NC\"",
                "beauty": "\"tanning\"",
                "latitude": 35.7372086,
                "longitude": -81.6801675,
                "name": "\"Mull Tower Center \"",
                "phone": "\"828-433-1871\"",
                "shop": "\"beauty\"",
                "website": "\"https://palmbeachtan.com/locations/NC/Morganton-West-Fleming-Drive/\""
            },
            {
                "__id": 268,
                "address_city": "\"Newport Beach\"",
                "address_country": "\"US\"",
                "address_full": "\"4341 MacArthur Blvd\"",
                "address_postcode": "\"92660\"",
                "address_state": "\"CA\"",
                "beauty": "\"tanning\"",
                "latitude": 33.66693,
                "longitude": -117.86447,
                "name": "\"MacArthur\"",
                "phone": "\"949-955-9194\"",
                "shop": "\"beauty\"",
                "website": "\"https://palmbeachtan.com/locations/CA/Newport-Beach-MacArthur-Boulevard/\""
            },
            {
                "__id": 193,
                "address_city": "\"York\"",
                "address_country": "\"US\"",
                "address_full": "\"2523 E Market St\"",
                "address_postcode": "\"17402\"",
                "address_state": "\"PA\"",
                "beauty": "\"tanning\"",
                "latitude": 39.9764437,
                "longitude": -76.6765761,
                "name": "\"East York\"",
                "phone": "\"717-600-8765\"",
                "shop": "\"beauty\"",
                "website": "\"https://palmbeachtan.com/locations/PA/York-East-Market-Street/\""
            },
            {
                "__id": 468,
                "address_city": "\"Houston\"",
                "address_country": "\"US\"",
                "address_full": "\"7710 Cherry Park Dr.\"",
                "address_postcode": "\"77095\"",
                "address_state": "\"TX\"",
                "beauty": "\"tanning\"",
                "latitude": 29.890487,
                "longitude": -95.6401677,
                "name": "\"Copperfield\"",
                "phone": "\"713-748-2663\"",
                "shop": "\"beauty\"",
                "website": "\"https://palmbeachtan.com/locations/TX/Houston-Cherry-Park-Drive/\""
            },
            {
                "__id": 450,
                "address_city": "\"Longview\"",
                "address_country": "\"US\"",
                "address_full": "\"405 W. Loop 281 Suite K\"",
                "address_postcode": "\"75605\"",
                "address_state": "\"TX\"",
                "beauty": "\"tanning\"",
                "latitude": 32.542926,
                "longitude": -94.756049,
                "name": "\"Longview\"",
                "phone": "\"903-371-0655\"",
                "shop": "\"beauty\"",
                "website": "\"https://palmbeachtan.com/locations/TX/Longview-West-Loop-281/\""
            },
            {
                "__id": 125,
                "address_city": "\"Bel Air\"",
                "address_country": "\"US\"",
                "address_full": "\"225 Brierhill Dr\"",
                "address_postcode": "\"21015\"",
                "address_state": "\"MD\"",
                "beauty": "\"tanning\"",
                "latitude": 39.5417843,
                "longitude": -76.3240722,
                "name": "\"Greenbrier Shopping Center\"",
                "phone": "\"410-838-5505\"",
                "shop": "\"beauty\"",
                "website": "\"https://palmbeachtan.com/locations/MD/Bel-Air-Brierhill-Drive/\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "montessori_school": {
        "__id": 174,
        "id": "montessori_school",
        "name": "Montessori School",
        "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name']",
        "rowLength": 14,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763575,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of montessori_school locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 8,
                "address_city": "\"Aurora\"",
                "address_full": "\"3180 N Aurora Road\"",
                "address_postcode": "\"60502\"",
                "address_state": "\"IL\"",
                "latitude": 41.782419,
                "longitude": -88.238082,
                "name": "\"Aurora Montessori School\""
            },
            {
                "__id": 5,
                "address_city": "\"Johns Creek\"",
                "address_full": "\"5815 Medlock Bridge Pkwy\"",
                "address_postcode": "\"30022\"",
                "address_state": "\"GA\"",
                "latitude": 34.028259,
                "longitude": -84.189495,
                "name": "\"Medlock Bridge Montessori\""
            },
            {
                "__id": 10,
                "address_city": "\"West Linn\"",
                "address_full": "\"3100 Parker Road\"",
                "address_postcode": "\"97068\"",
                "address_state": "\"OR\"",
                "latitude": 45.360857,
                "longitude": -122.633379,
                "name": "\"Cascade Summit Montessori\""
            },
            {
                "__id": 3,
                "address_city": "\"Duluth\"",
                "address_full": "\"2270 Meadow Church Road\"",
                "address_postcode": "\"30097\"",
                "address_state": "\"GA\"",
                "latitude": 33.979286,
                "longitude": -84.100649,
                "name": "\"Sugarloaf Montessori\""
            },
            {
                "__id": 9,
                "address_city": "\"Overland Park\"",
                "address_full": "\"11100 W 135th Street\"",
                "address_postcode": "\"66221\"",
                "address_state": "\"KS\"",
                "latitude": 38.88565,
                "longitude": -94.716465,
                "name": "\"Blue Valley Montessori\""
            },
            {
                "__id": 12,
                "address_city": "\"Irvine\"",
                "address_full": "\"4980 Irvine Blvd\"",
                "address_postcode": "\"92620\"",
                "address_state": "\"CA\"",
                "latitude": 33.71206,
                "longitude": -117.758549,
                "name": "\"Great Foundations Montessori - Northwood\""
            },
            {
                "__id": 7,
                "address_city": "\"Naperville\"",
                "address_full": "\"2936 Artesian Road\"",
                "address_postcode": "\"60564\"",
                "address_state": "\"IL\"",
                "latitude": 41.725178,
                "longitude": -88.203923,
                "name": "\"Naperville Montessori School\""
            },
            {
                "__id": 2,
                "address_city": "\"Boca Raton\"",
                "address_full": "\"20400 Cain Blvd\"",
                "address_postcode": "\"33498\"",
                "address_state": "\"FL\"",
                "latitude": 26.372259,
                "longitude": -80.211209,
                "name": "\"West Glades Montessori\""
            },
            {
                "__id": 4,
                "address_city": "\"Alpharetta\"",
                "address_full": "\"3800 North Point Pkwy\"",
                "address_postcode": "\"30005\"",
                "address_state": "\"GA\"",
                "latitude": 34.070076,
                "longitude": -84.258566,
                "name": "\"Preston Ridge Montessori\""
            },
            {
                "__id": 13,
                "address_city": "\"Irvine\"",
                "address_full": "\"6304 Irvine Blvd\"",
                "address_postcode": "\"92620\"",
                "address_state": "\"CA\"",
                "latitude": 33.697985,
                "longitude": -117.741597,
                "name": "\"Great Foundations Montessori - Woodbury\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "regis": {
        "__id": 175,
        "id": "regis",
        "name": "Regis",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'phone', 'shop', 'website']",
        "rowLength": 22,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763576,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of regis locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 8,
                "address_city": "\"Wilkes Barre\"",
                "address_country": "\"US\"",
                "address_full": "\"29 Wyoming Valley Mall\"",
                "address_postcode": "\"18702\"",
                "address_state": "\"PA\"",
                "latitude": 41.246491,
                "longitude": -75.844471,
                "name": "\"\"",
                "phone": "\"+15708225114\"",
                "shop": "\"\"",
                "website": "\"https://www.regissalons.com/locations/pa/wilkes-barre/outlooks-for-hair-wyoming-valley-mall-haircuts-38141.html\""
            },
            {
                "__id": 13,
                "address_city": "\"Virginia\"",
                "address_country": "\"US\"",
                "address_full": "\"1401 S 12Th Ave\"",
                "address_postcode": "\"55792\"",
                "address_state": "\"MN\"",
                "latitude": 47.510839,
                "longitude": -92.554244,
                "name": "\"\"",
                "phone": "\"+12187413387\"",
                "shop": "\"\"",
                "website": "\"https://www.regissalons.com/locations/mn/virginia/regis-salon-thunderbird-mall-haircuts-2385.html\""
            },
            {
                "__id": 6,
                "address_city": "\"Pittsburgh\"",
                "address_country": "\"US\"",
                "address_full": "\"100 Robinson Center Dr # 2010\"",
                "address_postcode": "\"15205\"",
                "address_state": "\"PA\"",
                "latitude": 40.454452,
                "longitude": -80.157122,
                "name": "\"\"",
                "phone": "\"+14124945555\"",
                "shop": "\"\"",
                "website": "\"https://www.regissalons.com/locations/pa/pittsburgh/regis-salon-the-mall-at-robinson-haircuts-38017.html\""
            },
            {
                "__id": 20,
                "address_city": "\"Tampa\"",
                "address_country": "\"US\"",
                "address_full": "\"2223 N Westshore Blvd Ste 151A\"",
                "address_postcode": "\"33607\"",
                "address_state": "\"FL\"",
                "latitude": 27.961326,
                "longitude": -82.512786,
                "name": "\"\"",
                "phone": "\"+18133489119\"",
                "shop": "\"\"",
                "website": "\"https://www.regissalons.com/locations/fl/tampa/regis-signature-salon-international-plaza-haircuts-3981.html\""
            },
            {
                "__id": 4,
                "address_city": "\"Johnson City\"",
                "address_country": "\"US\"",
                "address_full": "\"2011 N Roan St Ste B-1A\"",
                "address_postcode": "\"37601\"",
                "address_state": "\"TN\"",
                "latitude": 36.337811,
                "longitude": -82.377027,
                "name": "\"\"",
                "phone": "\"+14232820109\"",
                "shop": "\"\"",
                "website": "\"https://www.regissalons.com/locations/tn/johnson-city/hair-plus-the-mall-haircuts-4231.html\""
            },
            {
                "__id": 15,
                "address_city": "\"Lansing\"",
                "address_country": "\"US\"",
                "address_full": "\"2717 Preyde Blvd\"",
                "address_postcode": "\"48912\"",
                "address_state": "\"MI\"",
                "latitude": 42.763172,
                "longitude": -84.515665,
                "name": "\"\"",
                "phone": "\"+15173373033\"",
                "shop": "\"\"",
                "website": "\"https://www.regissalons.com/locations/mi/lansing/panopoulos-eastwood-towne-center-haircuts-22061.html\""
            },
            {
                "__id": 9,
                "address_city": "\"Bend\"",
                "address_country": "\"US\"",
                "address_full": "\"63455 N Highway 97 Bldg 1 # 87\"",
                "address_postcode": "\"97701\"",
                "address_state": "\"OR\"",
                "latitude": 44.101207,
                "longitude": -121.302225,
                "name": "\"\"",
                "phone": "\"+15413187160\"",
                "shop": "\"\"",
                "website": "\"https://www.regissalons.com/locations/or/bend/regis-salon-cascade-village-shopping-ctr-haircuts-3703.html\""
            },
            {
                "__id": 1,
                "address_city": "\"Yorkton\"",
                "address_country": "\"CA\"",
                "address_full": "\"277 Broadway St E Unit 50-A\"",
                "address_postcode": "\"S3N 3G7\"",
                "address_state": "\"SK\"",
                "latitude": 51.211817,
                "longitude": -102.444248,
                "name": "\"SmartStyle\"",
                "phone": "\"+13067863383\"",
                "shop": "\"hairdresser\"",
                "website": "\"https://www.regissalons.com/locations/sk/yorkton/regis-salon-parkland-mall-haircuts-5310.html\""
            },
            {
                "__id": 22,
                "address_city": "\"Daly City\"",
                "address_country": "\"US\"",
                "address_full": "\"42 Serramonte Ctr\"",
                "address_postcode": "\"94015\"",
                "address_state": "\"CA\"",
                "latitude": 37.673548,
                "longitude": -122.470884,
                "name": "\"\"",
                "phone": "\"+16509929970\"",
                "shop": "\"\"",
                "website": "\"https://www.regissalons.com/locations/ca/daly-city/regis-salon-serramonte-center-haircuts-6535.html\""
            },
            {
                "__id": 21,
                "address_city": "\"Wellington\"",
                "address_country": "\"US\"",
                "address_full": "\"10300 W Forest Hill Blvd # 152\"",
                "address_postcode": "\"33414\"",
                "address_state": "\"FL\"",
                "latitude": 26.646324,
                "longitude": -80.208445,
                "name": "\"\"",
                "phone": "\"+15613332991\"",
                "shop": "\"\"",
                "website": "\"https://www.regissalons.com/locations/fl/wellington/regis-signature-salon-wellington-green-mall-haircuts-6483.html\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "safelite": {
        "__id": 176,
        "id": "safelite",
        "name": "Safelite",
        "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'service_vehicle_glass', 'shop', 'street_address', 'website']",
        "rowLength": 824,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763577,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of safelite locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 572,
                "address_city": "\"Manhattan\"",
                "address_postcode": "\"66502\"",
                "address_state": "\"KS\"",
                "latitude": 39.1911,
                "longitude": -96.5579,
                "name": "\"Safelite AutoGlass\"",
                "opening_hours": "\"Mo-Fr 08:00-17:00; Sa 08:00-16:00\"",
                "service_vehicle_glass": "\"yes\"",
                "shop": "\"car_repair\"",
                "street_address": "\"1019 Hostetler Dr,  STE A\"",
                "website": "\"https://www.safelite.com/stores/manhattan-ks\""
            },
            {
                "__id": 457,
                "address_city": "\"Midland\"",
                "address_postcode": "\"48642\"",
                "address_state": "\"MI\"",
                "latitude": 43.6682,
                "longitude": -84.2451,
                "name": "\"Safelite AutoGlass\"",
                "opening_hours": "\"Mo-Sa 08:00-17:00\"",
                "service_vehicle_glass": "\"yes\"",
                "shop": "\"car_repair\"",
                "street_address": "\"1609 Commerce Ct\"",
                "website": "\"https://www.safelite.com/stores/midland\""
            },
            {
                "__id": 496,
                "address_city": "\"Quincy\"",
                "address_postcode": "\"02170\"",
                "address_state": "\"MA\"",
                "latitude": 42.2572,
                "longitude": -71.0083,
                "name": "\"Safelite AutoGlass\"",
                "opening_hours": "\"Mo-Sa 08:00-17:00\"",
                "service_vehicle_glass": "\"yes\"",
                "shop": "\"car_repair\"",
                "street_address": "\"1017 Hancock St\"",
                "website": "\"https://www.safelite.com/stores/quincy-ma\""
            },
            {
                "__id": 203,
                "address_city": "\"Williamsport\"",
                "address_postcode": "\"17701\"",
                "address_state": "\"PA\"",
                "latitude": 41.244,
                "longitude": -76.994,
                "name": "\"Safelite AutoGlass\"",
                "opening_hours": "\"Mo-Fr 08:00-17:00; Sa 08:00-24:00\"",
                "service_vehicle_glass": "\"yes\"",
                "shop": "\"car_repair\"",
                "street_address": "\"482 E 3Rd St\"",
                "website": "\"https://www.safelite.com/stores/williamsport\""
            },
            {
                "__id": 298,
                "address_city": "\"High Point\"",
                "address_postcode": "\"27265\"",
                "address_state": "\"NC\"",
                "latitude": 36.0477,
                "longitude": -79.9536,
                "name": "\"Safelite AutoGlass\"",
                "opening_hours": "\"Mo-Su 08:00-17:00\"",
                "service_vehicle_glass": "\"yes\"",
                "shop": "\"car_repair\"",
                "street_address": "\"4160 Mendenhall Oaks Pkwy,  STE 101\"",
                "website": "\"https://www.safelite.com/stores/high-point\""
            },
            {
                "__id": 178,
                "address_city": "\"Sioux Falls\"",
                "address_postcode": "\"57104\"",
                "address_state": "\"SD\"",
                "latitude": 43.5483,
                "longitude": -96.7315,
                "name": "\"Safelite AutoGlass\"",
                "opening_hours": "\"Mo-Fr 08:00-17:00; Sa 08:00-16:00\"",
                "service_vehicle_glass": "\"yes\"",
                "shop": "\"car_repair\"",
                "street_address": "\"129 N Minnesota Ave\"",
                "website": "\"https://www.safelite.com/stores/sioux-falls\""
            },
            {
                "__id": 501,
                "address_city": "\"Northampton\"",
                "address_postcode": "\"01060\"",
                "address_state": "\"MA\"",
                "latitude": 42.3249,
                "longitude": -72.6325,
                "name": "\"Safelite AutoGlass\"",
                "opening_hours": "\"Mo-Sa 08:00-17:00\"",
                "service_vehicle_glass": "\"yes\"",
                "shop": "\"car_repair\"",
                "street_address": "\"143 King St\"",
                "website": "\"https://www.safelite.com/stores/northampton\""
            },
            {
                "__id": 770,
                "address_city": "\"Los Angeles\"",
                "address_postcode": "\"90035\"",
                "address_state": "\"CA\"",
                "latitude": 34.0447,
                "longitude": -118.3764,
                "name": "\"Safelite AutoGlass\"",
                "opening_hours": "\"Mo-Sa 08:00-17:00\"",
                "service_vehicle_glass": "\"yes\"",
                "shop": "\"car_repair\"",
                "street_address": "\"1788 S La Cienega Blvd\"",
                "website": "\"https://www.safelite.com/stores/los-angeles-ca-east-la-cienega\""
            },
            {
                "__id": 356,
                "address_city": "\"Buffalo\"",
                "address_postcode": "\"14206\"",
                "address_state": "\"NY\"",
                "latitude": 42.8724,
                "longitude": -78.8147,
                "name": "\"Safelite AutoGlass\"",
                "opening_hours": "\"Mo-Sa 08:00-17:00\"",
                "service_vehicle_glass": "\"yes\"",
                "shop": "\"car_repair\"",
                "street_address": "\"1672 Clinton Street\"",
                "website": "\"https://www.safelite.com/stores/buffalo\""
            },
            {
                "__id": 90,
                "address_city": "\"Saint Albans\"",
                "address_postcode": "\"05478\"",
                "address_state": "\"VT\"",
                "latitude": 44.8242,
                "longitude": -73.0812,
                "name": "\"Safelite AutoGlass\"",
                "opening_hours": "\"Mo-Fr 08:00-17:00; Sa 08:00-16:00\"",
                "service_vehicle_glass": "\"yes\"",
                "shop": "\"car_repair\"",
                "street_address": "\"284 N Main St\"",
                "website": "\"https://www.safelite.com/stores/st-albans\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "bp": {
        "__id": 177,
        "id": "bp",
        "name": "BP Gas Station",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity_chargingstation', 'amenity_fuel', 'amenity_toilets', 'atm', 'car_wash', 'facilities', 'fuel_HGV_diesel', 'fuel_adblue', 'fuel_diesel', 'fuel_disel_class2', 'fuel_e10', 'fuel_e5', 'fuel_lpg', 'fuel_octane_100', 'fuel_octane_102', 'fuel_octane_91', 'fuel_octane_92', 'fuel_octane_93', 'fuel_octane_95', 'fuel_octane_98', 'fuel_untaxed_diesel', 'hgv', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'products', 'shop', 'website', 'wheelchair']",
        "rowLength": 17437,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763582,
        "runTime": 4,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of bp locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 12641,
                "address_city": "\"Brooklyn\"",
                "address_country": "\"US\"",
                "address_full": "\"3031 Coney Island Ave\"",
                "address_postcode": "\"11235\"",
                "address_state": "\"NY\"",
                "amenity_chargingstation": 0,
                "amenity_fuel": 0,
                "amenity_toilets": 0,
                "atm": 0,
                "car_wash": 0,
                "facilities": "\"['open_24_hours', 'toilet', 'shop', 'loyalty', 'pump_rewards', 'citi_rewards', 'mobile_acceptance', 'mobile_enabled']\"",
                "fuel_HGV_diesel": "\"\"",
                "fuel_adblue": "\"\"",
                "fuel_diesel": 0,
                "fuel_disel_class2": "\"\"",
                "fuel_e10": "\"\"",
                "fuel_e5": "\"\"",
                "fuel_lpg": "\"\"",
                "fuel_octane_100": "\"\"",
                "fuel_octane_102": "\"\"",
                "fuel_octane_91": 0,
                "fuel_octane_92": "\"\"",
                "fuel_octane_93": "\"\"",
                "fuel_octane_95": 0,
                "fuel_octane_98": "\"\"",
                "fuel_untaxed_diesel": "\"\"",
                "hgv": 0,
                "latitude": 40.58234,
                "longitude": -73.95997,
                "name": "\"BP\"",
                "opening_hours": "\"24/7\"",
                "phone": "\"+1 718-646-9700\"",
                "products": "\"[]\"",
                "shop": "\"convenience\"",
                "website": "\"\"",
                "wheelchair": 0
            },
            {
                "__id": 3278,
                "address_city": "\"Vizela\"",
                "address_country": "\"PT\"",
                "address_full": "\"Rua Guilherme Caldas Peixoto\"",
                "address_postcode": "\"4815-433\"",
                "address_state": "\"\"",
                "amenity_chargingstation": 0,
                "amenity_fuel": 0,
                "amenity_toilets": 0,
                "atm": 0,
                "car_wash": 0,
                "facilities": "\"['accepts_routex_cards', 'open_saturday', 'open_sunday']\"",
                "fuel_HGV_diesel": "\"\"",
                "fuel_adblue": "\"\"",
                "fuel_diesel": 0,
                "fuel_disel_class2": "\"\"",
                "fuel_e10": "\"\"",
                "fuel_e5": "\"\"",
                "fuel_lpg": "True",
                "fuel_octane_100": "\"\"",
                "fuel_octane_102": "\"\"",
                "fuel_octane_91": 0,
                "fuel_octane_92": "\"\"",
                "fuel_octane_93": "\"\"",
                "fuel_octane_95": 0,
                "fuel_octane_98": "True",
                "fuel_untaxed_diesel": "\"\"",
                "hgv": 0,
                "latitude": 41.3708,
                "longitude": -8.3118,
                "name": "\"BP VIZELA - ROTUNDA\"",
                "opening_hours": "\"\"",
                "phone": "\"+351 253 567 050\"",
                "products": "\"['auto_gas', 'diesel_on', 'lpg', 'ultimate', 'ultimate_diesel', 'ultimate_unleaded', 'unleaded_95']\"",
                "shop": "\"\"",
                "website": "\"\"",
                "wheelchair": 0
            },
            {
                "__id": 15141,
                "address_city": "\"Joliet\"",
                "address_country": "\"US\"",
                "address_full": "\"6 McDonough St\"",
                "address_postcode": "\"60436\"",
                "address_state": "\"IL\"",
                "amenity_chargingstation": 0,
                "amenity_fuel": 0,
                "amenity_toilets": 0,
                "atm": 0,
                "car_wash": 0,
                "facilities": "\"['toilet', 'shop', 'grocer_reward', 'marianos', 'loyalty', 'pump_rewards', 'citi_rewards', 'mobile_acceptance', 'mobile_enabled']\"",
                "fuel_HGV_diesel": "\"\"",
                "fuel_adblue": "\"\"",
                "fuel_diesel": 0,
                "fuel_disel_class2": "\"\"",
                "fuel_e10": "\"\"",
                "fuel_e5": "\"\"",
                "fuel_lpg": "\"\"",
                "fuel_octane_100": "\"\"",
                "fuel_octane_102": "\"\"",
                "fuel_octane_91": 0,
                "fuel_octane_92": "\"\"",
                "fuel_octane_93": "\"\"",
                "fuel_octane_95": 0,
                "fuel_octane_98": "\"\"",
                "fuel_untaxed_diesel": "\"\"",
                "hgv": 0,
                "latitude": 41.516483,
                "longitude": -88.081595,
                "name": "\"BP\"",
                "opening_hours": "\"\"",
                "phone": "\"+1 815-722-8290\"",
                "products": "\"['regular_diesel']\"",
                "shop": "\"convenience\"",
                "website": "\"\"",
                "wheelchair": 0
            },
            {
                "__id": 9196,
                "address_city": "\"Weeze\"",
                "address_country": "\"DE\"",
                "address_full": "\"Industriestra\u00dfe 43\"",
                "address_postcode": "\"47652\"",
                "address_state": "\"\"",
                "amenity_chargingstation": 0,
                "amenity_fuel": 0,
                "amenity_toilets": 0,
                "atm": 0,
                "car_wash": 0,
                "facilities": "\"['open_24_hours', 'car_wash', 'rewe_to_go', 'shop', 'bistro', 'pay_at_pump_supported', 'outdoor_payment_terminal', 'loyalty', 'open_saturday', 'open_sunday']\"",
                "fuel_HGV_diesel": "True",
                "fuel_adblue": "True",
                "fuel_diesel": 0,
                "fuel_disel_class2": "\"\"",
                "fuel_e10": "True",
                "fuel_e5": "True",
                "fuel_lpg": "True",
                "fuel_octane_100": "\"\"",
                "fuel_octane_102": "\"\"",
                "fuel_octane_91": 0,
                "fuel_octane_92": "\"\"",
                "fuel_octane_93": "\"\"",
                "fuel_octane_95": 0,
                "fuel_octane_98": "\"\"",
                "fuel_untaxed_diesel": "\"\"",
                "hgv": 0,
                "latitude": 51.620177,
                "longitude": 6.209968,
                "name": "\"Wilhelm Janssen&Sohn GmbH&Co. KG\"",
                "opening_hours": "\"24/7\"",
                "phone": "\"+49 2837 962551\"",
                "products": "\"['ultimate', 'ultimate_diesel', 'lpg', 'ad_blue', 'super_e5', 'e10', 'truck_diesel']\"",
                "shop": "\"convenience\"",
                "website": "\"http://tankstelle.aral.de/tankstelle/28642300\"",
                "wheelchair": 0
            },
            {
                "__id": 11289,
                "address_city": "\"Bondurant\"",
                "address_country": "\"US\"",
                "address_full": "\"1111 Lincoln St SE\"",
                "address_postcode": "\"50035\"",
                "address_state": "\"IA\"",
                "amenity_chargingstation": 0,
                "amenity_fuel": 0,
                "amenity_toilets": 0,
                "atm": 0,
                "car_wash": 0,
                "facilities": "\"['car_wash', 'toilet', 'shop', 'loyalty', 'pump_rewards', 'citi_rewards', 'mobile_acceptance', 'mobile_enabled']\"",
                "fuel_HGV_diesel": "\"\"",
                "fuel_adblue": "\"\"",
                "fuel_diesel": 0,
                "fuel_disel_class2": "\"\"",
                "fuel_e10": "\"\"",
                "fuel_e5": "\"\"",
                "fuel_lpg": "\"\"",
                "fuel_octane_100": "\"\"",
                "fuel_octane_102": "\"\"",
                "fuel_octane_91": 0,
                "fuel_octane_92": "\"\"",
                "fuel_octane_93": "\"\"",
                "fuel_octane_95": 0,
                "fuel_octane_98": "\"\"",
                "fuel_untaxed_diesel": "\"\"",
                "hgv": 0,
                "latitude": 41.692482,
                "longitude": -93.457284,
                "name": "\"BP\"",
                "opening_hours": "\"\"",
                "phone": "\"+1 515-967-1900\"",
                "products": "\"['regular_diesel']\"",
                "shop": "\"convenience\"",
                "website": "\"\"",
                "wheelchair": 0
            },
            {
                "__id": 16504,
                "address_city": "\"WELLINGTON\"",
                "address_country": "\"AU\"",
                "address_full": "\"CNR ARTHUR & MAXWELL STREETS\"",
                "address_postcode": "\"2820\"",
                "address_state": "\"NSW\"",
                "amenity_chargingstation": 0,
                "amenity_fuel": 0,
                "amenity_toilets": 0,
                "atm": 0,
                "car_wash": 0,
                "facilities": "\"['gift_card', 'hand_wash', 'bdouble_access', 'rigid_access', 'truck_suitable_site', 'high_flow_diesel', 'toilet', 'bp_rewards_redemption', 'bp_rewards_unlocked', 'loyalty', 'pump_rewards', 'bpmeplus', 'bpme_technically_enabled', 'truck_only', 'high_speed_pump', 'mobile_enabled']\"",
                "fuel_HGV_diesel": "\"\"",
                "fuel_adblue": "True",
                "fuel_diesel": 0,
                "fuel_disel_class2": "\"\"",
                "fuel_e10": "\"\"",
                "fuel_e5": "\"\"",
                "fuel_lpg": "True",
                "fuel_octane_100": "\"\"",
                "fuel_octane_102": "\"\"",
                "fuel_octane_91": 0,
                "fuel_octane_92": "\"\"",
                "fuel_octane_93": "\"\"",
                "fuel_octane_95": 0,
                "fuel_octane_98": "\"\"",
                "fuel_untaxed_diesel": "\"\"",
                "hgv": 0,
                "latitude": -32.558327,
                "longitude": 148.94468,
                "name": "\"BP WELLINGTON\"",
                "opening_hours": "\"\"",
                "phone": "\"+61 2 6345 3000\"",
                "products": "\"['ad_blue_cannister', 'lpg', 'premium_unlead', 'regular_diesel', 'unlead', 'ad_blue']\"",
                "shop": "\"\"",
                "website": "\"\"",
                "wheelchair": 0
            },
            {
                "__id": 12584,
                "address_city": "\"Merrick\"",
                "address_country": "\"US\"",
                "address_full": "\"2030 Sunrise Hwy\"",
                "address_postcode": "\"11566\"",
                "address_state": "\"NY\"",
                "amenity_chargingstation": 0,
                "amenity_fuel": 0,
                "amenity_toilets": 0,
                "atm": 0,
                "car_wash": 0,
                "facilities": "\"['toilet', 'shop', 'loyalty', 'pump_rewards', 'citi_rewards', 'mobile_acceptance', 'mobile_enabled']\"",
                "fuel_HGV_diesel": "\"\"",
                "fuel_adblue": "\"\"",
                "fuel_diesel": 0,
                "fuel_disel_class2": "\"\"",
                "fuel_e10": "\"\"",
                "fuel_e5": "\"\"",
                "fuel_lpg": "\"\"",
                "fuel_octane_100": "\"\"",
                "fuel_octane_102": "\"\"",
                "fuel_octane_91": 0,
                "fuel_octane_92": "\"\"",
                "fuel_octane_93": "\"\"",
                "fuel_octane_95": 0,
                "fuel_octane_98": "\"\"",
                "fuel_untaxed_diesel": "\"\"",
                "hgv": 0,
                "latitude": 40.662757,
                "longitude": -73.551247,
                "name": "\"BP\"",
                "opening_hours": "\"\"",
                "phone": "\"+1 516-442-0901\"",
                "products": "\"[]\"",
                "shop": "\"convenience\"",
                "website": "\"\"",
                "wheelchair": 0
            },
            {
                "__id": 2988,
                "address_city": "\"RODES\"",
                "address_country": "\"GR\"",
                "address_full": "\"2nd km RODES-LINDOS\"",
                "address_postcode": "\"85100\"",
                "address_state": "\"\"",
                "amenity_chargingstation": 0,
                "amenity_fuel": 0,
                "amenity_toilets": 0,
                "atm": 0,
                "car_wash": 0,
                "facilities": "\"['car_wash', 'wifi', 'shop', 'accepts_routex_cards', 'open_saturday', 'open_sunday']\"",
                "fuel_HGV_diesel": "\"\"",
                "fuel_adblue": "\"\"",
                "fuel_diesel": 0,
                "fuel_disel_class2": "\"\"",
                "fuel_e10": "\"\"",
                "fuel_e5": "\"\"",
                "fuel_lpg": "\"\"",
                "fuel_octane_100": "\"\"",
                "fuel_octane_102": "\"\"",
                "fuel_octane_91": 0,
                "fuel_octane_92": "\"\"",
                "fuel_octane_93": "\"\"",
                "fuel_octane_95": 0,
                "fuel_octane_98": "True",
                "fuel_untaxed_diesel": "\"\"",
                "hgv": 0,
                "latitude": 36.41657222,
                "longitude": 28.21156111,
                "name": "\"CALIPSO K.E.A. S.A. 076\"",
                "opening_hours": "\"\"",
                "phone": "\"+30 2241 062050\"",
                "products": "\"['ultimate', 'ultimate_unleaded', 'unleaded_95']\"",
                "shop": "\"convenience\"",
                "website": "\"\"",
                "wheelchair": 0
            },
            {
                "__id": 10884,
                "address_city": "\"PATRAS\"",
                "address_country": "\"GR\"",
                "address_full": "\"OVRIA\"",
                "address_postcode": "\"26332\"",
                "address_state": "\"\"",
                "amenity_chargingstation": 0,
                "amenity_fuel": 0,
                "amenity_toilets": 0,
                "atm": 0,
                "car_wash": 0,
                "facilities": "\"['wifi', 'shop', 'accepts_routex_cards', 'open_saturday', 'open_sunday']\"",
                "fuel_HGV_diesel": "\"\"",
                "fuel_adblue": "\"\"",
                "fuel_diesel": 0,
                "fuel_disel_class2": "\"\"",
                "fuel_e10": "\"\"",
                "fuel_e5": "\"\"",
                "fuel_lpg": "\"\"",
                "fuel_octane_100": "\"\"",
                "fuel_octane_102": "\"\"",
                "fuel_octane_91": 0,
                "fuel_octane_92": "\"\"",
                "fuel_octane_93": "\"\"",
                "fuel_octane_95": 0,
                "fuel_octane_98": "True",
                "fuel_untaxed_diesel": "\"\"",
                "hgv": 0,
                "latitude": 38.198973,
                "longitude": 21.713004,
                "name": "\"SCHINA P. STAMATIA&SIA O.E.\"",
                "opening_hours": "\"\"",
                "phone": "\"\"",
                "products": "\"['ultimate', 'ultimate_diesel', 'ultimate_unleaded', 'unleaded_95']\"",
                "shop": "\"convenience\"",
                "website": "\"\"",
                "wheelchair": 0
            },
            {
                "__id": 12717,
                "address_city": "\"South Ozone Park\"",
                "address_country": "\"US\"",
                "address_full": "\"130-11 N Conduit Ave\"",
                "address_postcode": "\"11420\"",
                "address_state": "\"NY\"",
                "amenity_chargingstation": 0,
                "amenity_fuel": 0,
                "amenity_toilets": 0,
                "atm": 0,
                "car_wash": 0,
                "facilities": "\"['open_24_hours', 'toilet', 'shop', 'loyalty', 'pump_rewards', 'citi_rewards', 'mobile_acceptance', 'mobile_enabled']\"",
                "fuel_HGV_diesel": "\"\"",
                "fuel_adblue": "\"\"",
                "fuel_diesel": 0,
                "fuel_disel_class2": "\"\"",
                "fuel_e10": "\"\"",
                "fuel_e5": "\"\"",
                "fuel_lpg": "\"\"",
                "fuel_octane_100": "\"\"",
                "fuel_octane_102": "\"\"",
                "fuel_octane_91": 0,
                "fuel_octane_92": "\"\"",
                "fuel_octane_93": "\"\"",
                "fuel_octane_95": 0,
                "fuel_octane_98": "\"\"",
                "fuel_untaxed_diesel": "\"\"",
                "hgv": 0,
                "latitude": 40.667275,
                "longitude": -73.809823,
                "name": "\"BP\"",
                "opening_hours": "\"24/7\"",
                "phone": "\"+1 718-845-4594\"",
                "products": "\"[]\"",
                "shop": "\"convenience\"",
                "website": "\"\"",
                "wheelchair": 0
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "bassett_furniture": {
        "__id": 178,
        "id": "bassett_furniture",
        "name": "Bassett Furniture",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'phone', 'website']",
        "rowLength": 93,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763583,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of bassett_furniture locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 52,
                "address_city": "\"Paramus\"",
                "address_country": "\"US\"",
                "address_full": "\"240 Route 17 North\"",
                "address_postcode": "\"07652\"",
                "address_state": "\"NJ\"",
                "latitude": 40.9357221,
                "longitude": -74.0692401,
                "name": "\"bassett home furnishings paramus\"",
                "phone": "\"201.265.4400\"",
                "website": "\"https://stores.bassettfurniture.com/nj/paramus/1105513/\""
            },
            {
                "__id": 83,
                "address_city": "\"Carolina\"",
                "address_country": "\"PR\"",
                "address_full": "\"JC Penney Plaza Carolina Mall\"",
                "address_postcode": "\"983\"",
                "address_state": "\"\"",
                "latitude": 18.3937337,
                "longitude": -65.9763196,
                "name": "\"bassett home furnishings carolina\"",
                "phone": "\"787.292.4100\"",
                "website": "\"https://stores.bassettfurniture.com/carolina/carolina/322929/\""
            },
            {
                "__id": 93,
                "address_city": "\"Fayetteville\"",
                "address_country": "\"US\"",
                "address_full": "\"3505 North Steele Blvd.\"",
                "address_postcode": "\"72703\"",
                "address_state": "\"AR\"",
                "latitude": 36.1188825,
                "longitude": -94.156892,
                "name": "\"bassett home furnishings fayetteville\"",
                "phone": "\"479.571.0200\"",
                "website": "\"https://stores.bassettfurniture.com/ar/fayetteville/621422/\""
            },
            {
                "__id": 35,
                "address_city": "\"Chattanooga\"",
                "address_country": "\"US\"",
                "address_full": "\"2100 Hamilton Place Boulevard\"",
                "address_postcode": "\"37421\"",
                "address_state": "\"TN\"",
                "latitude": 35.0340089,
                "longitude": -85.1587186,
                "name": "\"bassett home furnishings chattanooga\"",
                "phone": "\"423.296.8393\"",
                "website": "\"https://stores.bassettfurniture.com/tn/chattanooga/156977/\""
            },
            {
                "__id": 72,
                "address_city": "\"Louisville\"",
                "address_country": "\"US\"",
                "address_full": "\"12941 Shelbyville Road\"",
                "address_postcode": "\"40243\"",
                "address_state": "\"KY\"",
                "latitude": 38.2441301,
                "longitude": -85.5130225,
                "name": "\"bassett home furnishings louisville\"",
                "phone": "\"502.254.7250\"",
                "website": "\"https://stores.bassettfurniture.com/ky/louisville/1104588/\""
            },
            {
                "__id": 31,
                "address_city": "\"Fort Worth\"",
                "address_country": "\"US\"",
                "address_full": "\"4630 South Hulen St.\"",
                "address_postcode": "\"76132\"",
                "address_state": "\"TX\"",
                "latitude": 32.6789593,
                "longitude": -97.3951353,
                "name": "\"bassett home furnishings fort worth\"",
                "phone": "\"817.292.7779\"",
                "website": "\"https://stores.bassettfurniture.com/tx/fort-worth/1109241/\""
            },
            {
                "__id": 17,
                "address_city": "\"Redmond\"",
                "address_country": "\"US\"",
                "address_full": "\"2233 Bel Red Road\"",
                "address_postcode": "\"98052\"",
                "address_state": "\"WA\"",
                "latitude": 47.630452,
                "longitude": -122.1349613,
                "name": "\"bassett home furnishings redmond\"",
                "phone": "\"425.968.4663\"",
                "website": "\"https://stores.bassettfurniture.com/wa/redmond/399439/\""
            },
            {
                "__id": 7,
                "address_city": "\"Sarasota\"",
                "address_country": "\"US\"",
                "address_full": "\"210 N. Cattlemen Rd. Unit #1\"",
                "address_postcode": "\"34243\"",
                "address_state": "\"FL\"",
                "latitude": 27.3840378,
                "longitude": -82.4561473,
                "name": "\"bassett home furnishings sarasota\"",
                "phone": "\"941.888.6055\"",
                "website": "\"https://stores.bassettfurniture.com/fl/sarasota/1137257/\""
            },
            {
                "__id": 6,
                "address_city": "\"Atlanta\"",
                "address_country": "\"US\"",
                "address_full": "\"120 Perimeter Center West, Suite 400\"",
                "address_postcode": "\"30346\"",
                "address_state": "\"GA\"",
                "latitude": 33.9263384,
                "longitude": -84.3432054,
                "name": "\"bassett home furnishings perimeter center\"",
                "phone": "\"678.731.9706\"",
                "website": "\"https://stores.bassettfurniture.com/ga/atlanta/1104513/\""
            },
            {
                "__id": 3,
                "address_city": "\"Meridian\"",
                "address_country": "\"US\"",
                "address_full": "\"1250 Eagle Rd.\"",
                "address_postcode": "\"83642\"",
                "address_state": "\"ID\"",
                "latitude": 43.5947475,
                "longitude": -116.3545649,
                "name": "\"bassett home furnishings boise\"",
                "phone": "\"208.893.2053\"",
                "website": "\"https://stores.bassettfurniture.com/id/meridian/1135607/\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "hofer_si": {
        "__id": 179,
        "id": "hofer_si",
        "name": "Hofer Slovenia",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'latitude', 'longitude', 'name', 'opening_hours', 'shop', 'street_address']",
        "rowLength": 90,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763585,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of hofer_si locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 11,
                "address_city": "\"Velenje\"",
                "address_country": "\"SI\"",
                "address_full": "\"Selo 7a, Velenje, 3320\"",
                "address_postcode": "\"3320\"",
                "latitude": 46.37012,
                "longitude": 15.13224,
                "name": "\"LJU-25\"",
                "opening_hours": "\"Mo-Sa 08:00-21:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Selo 7a\""
            },
            {
                "__id": 48,
                "address_city": "\"Ljubljana\"",
                "address_country": "\"SI\"",
                "address_full": "\"Sloven\u010deva ulica 91, Ljubljana, 1000\"",
                "address_postcode": "\"1000\"",
                "latitude": 46.084637,
                "longitude": 14.506618,
                "name": "\"LJU-89\"",
                "opening_hours": "\"Mo-Sa 08:00-21:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Sloven\u010deva ulica 91\""
            },
            {
                "__id": 1,
                "address_city": "\"Roga\u0161ka Slatina\"",
                "address_country": "\"SI\"",
                "address_full": "\"Zgornje Negonje 36b, Roga\u0161ka Slatina, 3250\"",
                "address_postcode": "\"3250\"",
                "latitude": 46.25378,
                "longitude": 15.61647,
                "name": "\"LJU-38\"",
                "opening_hours": "\"Mo-Sa 08:00-20:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Zgornje Negonje 36b\""
            },
            {
                "__id": 87,
                "address_city": "\"Ormo\u017e\"",
                "address_country": "\"SI\"",
                "address_full": "\"Ljutomerska cesta 38c, Ormo\u017e, 2270\"",
                "address_postcode": "\"2270\"",
                "latitude": 46.41165,
                "longitude": 16.1645,
                "name": "\"LJU-35\"",
                "opening_hours": "\"Mo-Sa 08:00-20:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Ljutomerska cesta 38c\""
            },
            {
                "__id": 2,
                "address_city": "\"Maribor\"",
                "address_country": "\"SI\"",
                "address_full": "\"Vodnikov trg 7, Maribor, 2000\"",
                "address_postcode": "\"2000\"",
                "latitude": 46.55776,
                "longitude": 15.64135,
                "name": "\"LJU-82\"",
                "opening_hours": "\"Mo-Sa 08:00-21:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Vodnikov trg 7\""
            },
            {
                "__id": 84,
                "address_city": "\"Ljubljana\"",
                "address_country": "\"SI\"",
                "address_full": "\"Njego\u0161eva ulica 8, Ljubljana, 1000\"",
                "address_postcode": "\"1000\"",
                "latitude": 46.05471,
                "longitude": 14.5199,
                "name": "\"LJU-80\"",
                "opening_hours": "\"Mo-Sa 07:30-21:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Njego\u0161eva ulica 8\""
            },
            {
                "__id": 8,
                "address_city": "\"Radlje ob Dravi\"",
                "address_country": "\"SI\"",
                "address_full": "\"Koro\u0161ka cesta 69b, Radlje ob Dravi, 2360\"",
                "address_postcode": "\"2360\"",
                "latitude": 46.61483,
                "longitude": 15.20781,
                "name": "\"LJU-28\"",
                "opening_hours": "\"Mo-Sa 08:00-20:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Koro\u0161ka cesta 69b\""
            },
            {
                "__id": 90,
                "address_city": "\"Grosuplje\"",
                "address_country": "\"SI\"",
                "address_full": "\"Adami\u010deva  cesta 4, Grosuplje, 1290\"",
                "address_postcode": "\"1290\"",
                "latitude": 45.95965,
                "longitude": 14.64755,
                "name": "\"LJU-44\"",
                "opening_hours": "\"Mo-Sa 08:00-21:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Adami\u010deva  cesta 4\""
            },
            {
                "__id": 32,
                "address_city": "\"Idrija\"",
                "address_country": "\"SI\"",
                "address_full": "\"Vojkova ulica 41, Idrija, 5280\"",
                "address_postcode": "\"5280\"",
                "latitude": 46.01883,
                "longitude": 14.02978,
                "name": "\"LJU-59\"",
                "opening_hours": "\"Mo-Sa 08:00-20:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Vojkova ulica 41\""
            },
            {
                "__id": 53,
                "address_city": "\"\u017dalec\"",
                "address_country": "\"SI\"",
                "address_full": "\"Gotoveljska cesta 18, \u017dalec, 3310\"",
                "address_postcode": "\"3310\"",
                "latitude": 46.25481,
                "longitude": 15.15101,
                "name": "\"LJU-77\"",
                "opening_hours": "\"Mo-Sa 08:00-21:00\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Gotoveljska cesta 18\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "thales_fr": {
        "__id": 180,
        "id": "thales_fr",
        "name": "Thales France",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'latitude', 'longitude', 'name']",
        "rowLength": 84,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763586,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of thales_fr locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 69,
                "address_city": "\"Osny\"",
                "address_country": "\"FR\"",
                "address_full": "\"4 Rue du G\u00e9n\u00e9ral de Gaulle\"",
                "address_postcode": "\"95520\"",
                "latitude": 49.0539505,
                "longitude": 2.0631984,
                "name": "\"THALES SERVICES SA\""
            },
            {
                "__id": 47,
                "address_city": "\"Malakoff\"",
                "address_country": "\"FR\"",
                "address_full": "\"13 Avenue Maurice Thorez\"",
                "address_postcode": "\"92240\"",
                "latitude": 48.817275,
                "longitude": 2.2977599,
                "name": "\"THALES GEODIS FREIGHT & LOGISTICS (JV)\""
            },
            {
                "__id": 34,
                "address_city": "\"Meudon\"",
                "address_country": "\"FR\"",
                "address_full": "\"22 Avenue du Mar\u00e9chal Juin\"",
                "address_postcode": "\"92360\"",
                "latitude": 48.787226,
                "longitude": 2.2186397,
                "name": "\"THALES AVIONICS SA\""
            },
            {
                "__id": 59,
                "address_city": "\"\u00c9lancourt\"",
                "address_country": "\"FR\"",
                "address_full": "\"2 Avenue Gay Lussac\"",
                "address_postcode": "\"78990\"",
                "latitude": 48.7906406,
                "longitude": 1.9719669,
                "name": "\"THALES OPTRONIQUE S.A.S\""
            },
            {
                "__id": 31,
                "address_city": "\"Le Haillan Cedex\"",
                "address_country": "\"FR\"",
                "address_full": "\"3 Rue Toussaint Catros\"",
                "address_postcode": "\"33187\"",
                "latitude": 44.8548662,
                "longitude": -0.6994889,
                "name": "\"THALES AVIONICS SA\""
            },
            {
                "__id": 7,
                "address_city": "\"La Teste-de-Buch\"",
                "address_country": "\"FR\"",
                "address_full": "\"41 Avenue de Biscarrosse\"",
                "address_postcode": "\"33115\"",
                "latitude": 44.6058247,
                "longitude": -1.2016343,
                "name": "\"THALES AIR SYSTEMS S.A.\""
            },
            {
                "__id": 12,
                "address_city": "\"Rungis\"",
                "address_country": "\"FR\"",
                "address_full": "\"3 Avenue Charles Lindbergh\"",
                "address_postcode": "\"94150\"",
                "latitude": 48.751112,
                "longitude": 2.3536508,
                "name": "\"THALES AIR SYSTEMS S.A.\""
            },
            {
                "__id": 52,
                "address_city": "\"V\u00e9lizy-Villacoublay\"",
                "address_country": "\"FR\"",
                "address_full": "\"19 Avenue Morane Saulnier\"",
                "address_postcode": "\"78140\"",
                "latitude": 48.7852825,
                "longitude": 2.2170141,
                "name": "\"Thales Global Services\""
            },
            {
                "__id": 83,
                "address_city": "\"Jouy-en-Josas\"",
                "address_country": "\"FR\"",
                "address_full": "\"67 Rue Charles de Gaulle\"",
                "address_postcode": "\"78350\"",
                "latitude": 48.7688041,
                "longitude": 2.1524117,
                "name": "\"THALES UNIVERSITE S.A.\""
            },
            {
                "__id": 58,
                "address_city": "\"La Seyne-sur-Mer\"",
                "address_country": "\"FR\"",
                "address_full": "\"85 Impasse des Cabliers\"",
                "address_postcode": "\"83500\"",
                "latitude": 43.1056462,
                "longitude": 5.8800131,
                "name": "\"THALES NAVAL France\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "dollartree": {
        "__id": 181,
        "id": "dollartree",
        "name": "Dollar Tree",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'website']",
        "rowLength": 7874,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763588,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of dollartree locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 3557,
                "address_city": "\"Belleville\"",
                "address_country": "\"US\"",
                "address_full": "\"10880 Belleville Rd.\"",
                "address_postcode": "\"48111-5304\"",
                "address_state": "\"MI\"",
                "latitude": 42.2232464966029,
                "longitude": -83.4850606924819,
                "name": "\"Belleville Square\"",
                "opening_hours": "\"Mo-Sa 08:00-22:00; Su 08:00-20:00\"",
                "phone": "\"734-391-2024\"",
                "shop": "\"variety_store\"",
                "website": "\"https://www.dollartree.com/locations/mi/belleville/1570/\""
            },
            {
                "__id": 7151,
                "address_city": "\"San Diego\"",
                "address_country": "\"US\"",
                "address_full": "\"2483 Imperial Avenue\"",
                "address_postcode": "\"92102-3916\"",
                "address_state": "\"CA\"",
                "latitude": 32.7063473829091,
                "longitude": -117.140519753455,
                "name": "\"Imperial Avenue\"",
                "opening_hours": "\"Mo-Sa 08:00-21:00; Su 09:00-20:00\"",
                "phone": "\"619-849-3659\"",
                "shop": "\"variety_store\"",
                "website": "\"https://www.dollartree.com/locations/ca/san-diego/5733/\""
            },
            {
                "__id": 4476,
                "address_city": "\"Hawthorne\"",
                "address_country": "\"US\"",
                "address_full": "\"111 Wagaraw Rd SUITE 1\"",
                "address_postcode": "\"07506\"",
                "address_state": "\"NJ\"",
                "latitude": 40.9411665,
                "longitude": -74.16198,
                "name": "\"Hawthorne Relo\"",
                "opening_hours": "\"Mo-Sa 08:00-22:00; Su 08:00-21:00\"",
                "phone": "\"973-949-9030\"",
                "shop": "\"variety_store\"",
                "website": "\"https://www.dollartree.com/locations/nj/hawthorne/3104/\""
            },
            {
                "__id": 6732,
                "address_city": "\"Wilmington\"",
                "address_country": "\"US\"",
                "address_full": "\"402 North Maryland Ave\"",
                "address_postcode": "\"19804-1303\"",
                "address_state": "\"DE\"",
                "latitude": 39.7318059850686,
                "longitude": -75.5809290955339,
                "name": "\"Maryland Ave FS\"",
                "opening_hours": "\"Mo-Sa 08:00-21:00; Su 10:00-20:00\"",
                "phone": "\"302-502-8958\"",
                "shop": "\"variety_store\"",
                "website": "\"https://www.dollartree.com/locations/de/wilmington/8329/\""
            },
            {
                "__id": 4299,
                "address_city": "\"Somersworth\"",
                "address_country": "\"US\"",
                "address_full": "\"451 High St.\"",
                "address_postcode": "\"03878-1010\"",
                "address_state": "\"NH\"",
                "latitude": 43.23171,
                "longitude": -70.88207,
                "name": "\"TriCity Shopping Center\"",
                "opening_hours": "\"Mo-Sa 08:00-21:00; Su 09:00-20:00\"",
                "phone": "\"603-285-4321\"",
                "shop": "\"variety_store\"",
                "website": "\"https://www.dollartree.com/locations/nh/somersworth/3064/\""
            },
            {
                "__id": 1059,
                "address_city": "\"Philadelphia\"",
                "address_country": "\"US\"",
                "address_full": "\"4160  Monument Road\"",
                "address_postcode": "\"19131-1726\"",
                "address_state": "\"PA\"",
                "latitude": 40.00407,
                "longitude": -75.21435,
                "name": "\"4160 Monument Road\"",
                "opening_hours": "\"Mo-Sa 08:00-21:00; Su 09:00-20:00\"",
                "phone": "\"215-594-4711\"",
                "shop": "\"variety_store\"",
                "website": "\"https://www.dollartree.com/locations/pa/philadelphia/7151/\""
            },
            {
                "__id": 2262,
                "address_city": "\"Dallas\"",
                "address_country": "\"US\"",
                "address_full": "\"9208 E. RL Thornton Frwy\"",
                "address_postcode": "\"75228-6104\"",
                "address_state": "\"TX\"",
                "latitude": 32.7962990704351,
                "longitude": -96.6881706579816,
                "name": "\"Buckner Commons\"",
                "opening_hours": "\"Mo-Sa 08:00-22:00; Su 08:00-21:00\"",
                "phone": "\"469-547-4607\"",
                "shop": "\"variety_store\"",
                "website": "\"https://www.dollartree.com/locations/tx/dallas/1280/\""
            },
            {
                "__id": 3157,
                "address_city": "\"Eagle River\"",
                "address_country": "\"US\"",
                "address_full": "\"910 East Pine St.\"",
                "address_postcode": "\"54521-8025\"",
                "address_state": "\"WI\"",
                "latitude": 45.913751977155,
                "longitude": -89.2404438400848,
                "name": "\"East Pine St\"",
                "opening_hours": "\"Mo-Sa 09:00-21:00; Su 10:00-20:00\"",
                "phone": "\"715-679-5697\"",
                "shop": "\"variety_store\"",
                "website": "\"https://www.dollartree.com/locations/wi/eagle-river/6901/\""
            },
            {
                "__id": 6890,
                "address_city": "\"Sun City\"",
                "address_country": "\"US\"",
                "address_full": "\"10730 W. Bell Road\"",
                "address_postcode": "\"85351-1073\"",
                "address_state": "\"AZ\"",
                "latitude": 33.6381138861952,
                "longitude": -112.290361142321,
                "name": "\"Bell Camino\"",
                "opening_hours": "\"Mo-Sa 08:00-20:00; Su 10:00-20:00\"",
                "phone": "\"623-300-7316\"",
                "shop": "\"variety_store\"",
                "website": "\"https://www.dollartree.com/locations/az/sun-city/5815/\""
            },
            {
                "__id": 2263,
                "address_city": "\"Dallas\"",
                "address_country": "\"US\"",
                "address_full": "\"904 Ft Worth Ave\"",
                "address_postcode": "\"75208-1709\"",
                "address_state": "\"TX\"",
                "latitude": 32.7682723,
                "longitude": -96.8369843,
                "name": "\"F/S 904 Ft Worth Ave\"",
                "opening_hours": "\"Mo-Sa 08:00-20:00; Su 10:00-20:00\"",
                "phone": "\"214-765-8242\"",
                "shop": "\"variety_store\"",
                "website": "\"https://www.dollartree.com/locations/tx/dallas/6996/\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "wendys": {
        "__id": 182,
        "id": "wendys",
        "name": "Wendy's",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'image', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'street_address', 'takeaway', 'website']",
        "rowLength": 6299,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763590,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of wendys locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 1676,
                "address_city": "\"Westlake\"",
                "address_country": "\"US\"",
                "address_postcode": "\"44145\"",
                "address_state": "\"OH\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "image": "\"https://dynl.mktgcdn.com/p/IqIuwfyfi12qOxK54MtSfQYHxfpxRFtNL8AMbSeNACk/1900x1068.jpg\"",
                "latitude": 41.4643419,
                "longitude": -81.9505556,
                "name": "\"Wendy's 659 Westlake in Westlake, OH\"",
                "opening_hours": "\"Mo-Sa 06:30-10:25,09:00-21:00; Su 08:00-10:25,09:00-21:00\"",
                "phone": "\"(440) 892-8946\"",
                "street_address": "\"29778 Detroit Road\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.wendys.com/united-states/oh/westlake/29778-detroit-road\""
            },
            {
                "__id": 574,
                "address_city": "\"Sugar Land\"",
                "address_country": "\"US\"",
                "address_postcode": "\"77479\"",
                "address_state": "\"TX\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "image": "\"https://dynl.mktgcdn.com/p/IqIuwfyfi12qOxK54MtSfQYHxfpxRFtNL8AMbSeNACk/1900x1068.jpg\"",
                "latitude": 29.549523,
                "longitude": -95.585069,
                "name": "\"Wendy's 076 Riverstone in Sugar Land, TX\"",
                "opening_hours": "\"Mo-Fr 06:30-10:30,09:00-21:00; Sa-Su 07:00-10:30,09:00-21:00\"",
                "phone": "\"(346) 279-0204\"",
                "street_address": "\"18911 University Blvd\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.wendys.com/united-states/tx/sugar-land/18911-university-blvd\""
            },
            {
                "__id": 4029,
                "address_city": "\"Chesterton\"",
                "address_country": "\"US\"",
                "address_postcode": "\"46304\"",
                "address_state": "\"IN\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "image": "\"https://dynl.mktgcdn.com/p/IqIuwfyfi12qOxK54MtSfQYHxfpxRFtNL8AMbSeNACk/1900x1068.jpg\"",
                "latitude": 41.61654805110097,
                "longitude": -87.04042509959436,
                "name": "\"Wendy's Chesterton in Chesterton, IN\"",
                "opening_hours": "\"Mo-Su 06:30-10:30,09:00-22:00\"",
                "phone": "\"(219) 250-1454\"",
                "street_address": "\"748 Indian Boundry Pike\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.wendys.com/united-states/in/chesterton/748-indian-boundry-pike\""
            },
            {
                "__id": 1185,
                "address_city": "\"Pickens\"",
                "address_country": "\"US\"",
                "address_postcode": "\"29671\"",
                "address_state": "\"SC\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "image": "\"https://dynl.mktgcdn.com/p/IqIuwfyfi12qOxK54MtSfQYHxfpxRFtNL8AMbSeNACk/1900x1068.jpg\"",
                "latitude": 34.8931947,
                "longitude": -82.7108343,
                "name": "\"Wendy's Pickens in Pickens, SC\"",
                "opening_hours": "\"Mo-Su 06:30-10:30,09:00-22:00\"",
                "phone": "\"(864) 878-7975\"",
                "street_address": "\"505 Ann Street\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.wendys.com/united-states/sc/pickens/505-ann-street\""
            },
            {
                "__id": 4973,
                "address_city": "\"Jacksonville\"",
                "address_country": "\"US\"",
                "address_postcode": "\"32256\"",
                "address_state": "\"FL\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "image": "\"https://dynl.mktgcdn.com/p/IqIuwfyfi12qOxK54MtSfQYHxfpxRFtNL8AMbSeNACk/1900x1068.jpg\"",
                "latitude": 30.22112497484891,
                "longitude": -81.57898585195659,
                "name": "\"Wendy's Baymeadows in Jacksonville, FL\"",
                "opening_hours": "\"Mo-We 06:30-10:30,09:00-22:00; Th 06:30-10:30; Fr-Su 06:30-10:30,09:00-22:00\"",
                "phone": "\"(904) 731-8595\"",
                "street_address": "\"8625 Baymeadows Road\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.wendys.com/united-states/fl/jacksonville/8625-baymeadows-road\""
            },
            {
                "__id": 623,
                "address_city": "\"Round Rock\"",
                "address_country": "\"US\"",
                "address_postcode": "\"78664\"",
                "address_state": "\"TX\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "image": "\"https://dynl.mktgcdn.com/p/IqIuwfyfi12qOxK54MtSfQYHxfpxRFtNL8AMbSeNACk/1900x1068.jpg\"",
                "latitude": 30.482381007536272,
                "longitude": -97.65801519155502,
                "name": "\"Wendy's 117 Greenlawn in Round Rock, TX\"",
                "opening_hours": "\"Mo-Su 06:30-10:30,07:00-23:00\"",
                "phone": "\"(512) 596-3276\"",
                "street_address": "\"607 Louis Henna Blvd\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.wendys.com/united-states/tx/round-rock/607-louis-henna-blvd\""
            },
            {
                "__id": 6291,
                "address_city": "\"Calgary\"",
                "address_country": "\"CA\"",
                "address_postcode": "\"T3N 1A7\"",
                "address_state": "\"AB\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "image": "\"https://dynl.mktgcdn.com/p/IqIuwfyfi12qOxK54MtSfQYHxfpxRFtNL8AMbSeNACk/1900x1068.jpg\"",
                "latitude": 51.155856659553045,
                "longitude": -113.99849761286772,
                "name": "\"Wendy's Stonegate in Calgary, AB\"",
                "opening_hours": "\"Mo-Su 06:30-10:30,09:00-22:00\"",
                "phone": "\"(587) 350-9680\"",
                "street_address": "\"2626 Country Hills Blvd Ne\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.wendys.com/canada/ab/calgary/2626-country-hills-blvd-ne\""
            },
            {
                "__id": 579,
                "address_city": "\"Spring\"",
                "address_country": "\"US\"",
                "address_postcode": "\"77380\"",
                "address_state": "\"TX\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "image": "\"https://dynl.mktgcdn.com/p/IqIuwfyfi12qOxK54MtSfQYHxfpxRFtNL8AMbSeNACk/1900x1068.jpg\"",
                "latitude": 30.12633315530675,
                "longitude": -95.44982515278207,
                "name": "\"Wendy's 082 Sawdust in Spring, TX\"",
                "opening_hours": "\"Mo-Fr 06:30-10:30,09:00-21:00; Sa-Su 07:00-10:30,09:00-21:00\"",
                "phone": "\"(832) 284-7382\"",
                "street_address": "\"505 Sawdust Road\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.wendys.com/united-states/tx/spring/505-sawdust-road\""
            },
            {
                "__id": 3941,
                "address_city": "\"Kokomo\"",
                "address_country": "\"US\"",
                "address_postcode": "\"46902\"",
                "address_state": "\"IN\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "image": "\"https://dynl.mktgcdn.com/p/IqIuwfyfi12qOxK54MtSfQYHxfpxRFtNL8AMbSeNACk/1900x1068.jpg\"",
                "latitude": 40.45069349290147,
                "longitude": -86.12755971844308,
                "name": "\"Wendy's La Fountain 1 in Kokomo, IN\"",
                "opening_hours": "\"Mo-Su 06:00-10:30,09:00-22:00\"",
                "phone": "\"(765) 453-3400\"",
                "street_address": "\"3115 S Lafountain St\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.wendys.com/united-states/in/kokomo/3115-s-lafountain-st\""
            },
            {
                "__id": 5509,
                "address_city": "\"Los Angeles\"",
                "address_country": "\"US\"",
                "address_postcode": "\"90027\"",
                "address_state": "\"CA\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "image": "\"https://dynl.mktgcdn.com/p/IqIuwfyfi12qOxK54MtSfQYHxfpxRFtNL8AMbSeNACk/1900x1068.jpg\"",
                "latitude": 34.095883225128915,
                "longitude": -118.29216293524951,
                "name": "\"Wendy's Vermont in Los Angeles, CA\"",
                "opening_hours": "\"Mo-Su 06:30-10:30,08:00-22:00\"",
                "phone": "\"(323) 663-7387\"",
                "street_address": "\"1305 N. Vermont Ave.\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.wendys.com/united-states/ca/los-angeles/1305-n-vermont-ave\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "bashas": {
        "__id": 183,
        "id": "bashas",
        "name": "Bashas",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'website']",
        "rowLength": 55,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763593,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of bashas locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 43,
                "address_city": "\"KAYENTA\"",
                "address_country": "\"US\"",
                "address_postcode": "\"86033\"",
                "address_state": "\"AZ\"",
                "latitude": 36.707343,
                "longitude": -110.249669,
                "name": "\"Bashas\u2019 Din\u00e9 Market: Hwy 160 & 163\"",
                "opening_hours": "\"Mo-Su 07:00-08:00\"",
                "phone": "\"928-697-8176\"",
                "shop": "\"supermarket\"",
                "street_address": "\"HWY 160/161 P.O. BOX 1810\"",
                "website": "\"https://www.bashas.com/stores/bashas-dine-market-hwy-160-163/\""
            },
            {
                "__id": 23,
                "address_city": "\"BAGDAD\"",
                "address_country": "\"US\"",
                "address_postcode": "\"86321\"",
                "address_state": "\"AZ\"",
                "latitude": 34.573388,
                "longitude": -113.178959,
                "name": "\"Bashas\u2019 Supermarket:\"",
                "opening_hours": "\"Mo-Su 05:00-09:00\"",
                "phone": "\"928-633-4599\"",
                "shop": "\"supermarket\"",
                "street_address": "\"105 MAIN STREET PO BOX 337\"",
                "website": "\"https://www.bashas.com/stores/bashas-supermarket/\""
            },
            {
                "__id": 10,
                "address_city": "\"SCOTTSDALE\"",
                "address_country": "\"US\"",
                "address_postcode": "\"85250\"",
                "address_state": "\"AZ\"",
                "latitude": 33.522706,
                "longitude": -111.899074,
                "name": "\"Bashas\u2019 Supermarket: McDonald & Granite Reef\"",
                "opening_hours": "\"Mo-Su 06:00-11:00\"",
                "phone": "\"480-948-8110\"",
                "shop": "\"supermarket\"",
                "street_address": "\"8423 E. MCDONALD DR.\"",
                "website": "\"https://www.bashas.com/stores/bashas-supermarket-mcdonald-granite-reef/\""
            },
            {
                "__id": 37,
                "address_city": "\"SELLS\"",
                "address_country": "\"US\"",
                "address_postcode": "\"85634\"",
                "address_state": "\"AZ\"",
                "latitude": 31.914795,
                "longitude": -111.8662,
                "name": "\"Bashas\u2019 Supermarket: 27 Hwy 86 & Rte 19\"",
                "opening_hours": "\"Mo-Su 07:00-07:30\"",
                "phone": "\"520-383-2546\"",
                "shop": "\"supermarket\"",
                "street_address": "\"111 MAIN ST\"",
                "website": "\"https://www.bashas.com/stores/bashas-supermarket-27-hwy-86-rte-19/\""
            },
            {
                "__id": 29,
                "address_city": "\"TUCSON\"",
                "address_country": "\"US\"",
                "address_postcode": "\"85712\"",
                "address_state": "\"AZ\"",
                "latitude": 32.266526,
                "longitude": -110.894036,
                "name": "\"Bashas\u2019 Supermarket: Camp Lowell & Swan\"",
                "opening_hours": "\"Mo-Su 06:00-10:00\"",
                "phone": "\"520-323-5820\"",
                "shop": "\"supermarket\"",
                "street_address": "\"3275 N. SWAN RD\"",
                "website": "\"https://www.bashas.com/stores/bashas-supermarket-camp-lowell-swan/\""
            },
            {
                "__id": 46,
                "address_city": "\"LAKE HAVASU CITY\"",
                "address_country": "\"US\"",
                "address_postcode": "\"86406\"",
                "address_state": "\"AZ\"",
                "latitude": 34.459156,
                "longitude": -114.278975,
                "name": "\"Bashas\u2019 Supermarket\"",
                "opening_hours": "\"Mo-Su 06:00-11:00\"",
                "phone": "\"928-854-9550\"",
                "shop": "\"supermarket\"",
                "street_address": "\"3269 MARICOPA AVE SUITE 120\"",
                "website": "\"https://www.bashas.com/stores/bashas-supermarket-lake-havasu-city/\""
            },
            {
                "__id": 38,
                "address_city": "\"CROWNPOINT\"",
                "address_country": "\"US\"",
                "address_postcode": "\"87313\"",
                "address_state": "\"NM\"",
                "latitude": 35.744572,
                "longitude": -108.114546,
                "name": "\"Bashas\u2019 Din\u00e9 Market\"",
                "opening_hours": "\"Mo-Su 07:00-08:00\"",
                "phone": "\"505-786-5814\"",
                "shop": "\"supermarket\"",
                "street_address": "\"HWY. 57 & INDIAN SERVICE RD 9\"",
                "website": "\"https://www.bashas.com/stores/bashas-dine-market/\""
            },
            {
                "__id": 25,
                "address_city": "\"GILBERT\"",
                "address_country": "\"US\"",
                "address_postcode": "\"85296\"",
                "address_state": "\"AZ\"",
                "latitude": 33.348557,
                "longitude": -111.71941,
                "name": "\"Bashas\u2019 Supermarket: Higley & Elliot\"",
                "opening_hours": "\"Mo-Su 06:00-11:00\"",
                "phone": "\"480-926-5220\"",
                "shop": "\"supermarket\"",
                "street_address": "\"99 S. HIGLEY RD\"",
                "website": "\"https://www.bashas.com/stores/bashas-supermarket-higley-elliot/\""
            },
            {
                "__id": 11,
                "address_city": "\"PHOENIX\"",
                "address_country": "\"US\"",
                "address_postcode": "\"85028\"",
                "address_state": "\"AZ\"",
                "latitude": 33.583434,
                "longitude": -112.013067,
                "name": "\"Bashas\u2019 Supermarket: 32nd St. & E. Shea Blvd.\"",
                "opening_hours": "\"Mo-Su 06:00-11:00\"",
                "phone": "\"602-996-1040\"",
                "shop": "\"supermarket\"",
                "street_address": "\"10631 N. 32ND ST\"",
                "website": "\"https://www.bashas.com/stores/bashas-supermarket-32nd-st-e-shea-blvd/\""
            },
            {
                "__id": 8,
                "address_city": "\"KINGMAN\"",
                "address_country": "\"US\"",
                "address_postcode": "\"86401\"",
                "address_state": "\"AZ\"",
                "latitude": 35.221286,
                "longitude": -114.004621,
                "name": "\"Bashas\u2019 Supermarket: Armour & Andy Devine\"",
                "opening_hours": "\"Mo-Su 06:00-09:00\"",
                "phone": "\"928-757-7162\"",
                "shop": "\"supermarket\"",
                "street_address": "\"3360 ANDY DEVINE DR\"",
                "website": "\"https://www.bashas.com/stores/bashas-supermarket-armour-andy-devine/\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "elpolloloco": {
        "__id": 184,
        "id": "elpolloloco",
        "name": "El Pollo Loco",
        "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'phone', 'takeaway', 'website']",
        "rowLength": 489,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763594,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of elpolloloco locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 46,
                "address_city": "\"Mcallen\"",
                "address_full": "\"601 W Nolana Ave\"",
                "address_postcode": "\"78501\"",
                "address_state": "\"TX\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"mexican\"",
                "latitude": 26.23842,
                "longitude": -98.21976,
                "name": "\"nolana-loop-6th-st\"",
                "phone": "\"956-971-8314\"",
                "takeaway": "\"yes\"",
                "website": "\"https://www.elpolloloco.com/locations/locations_json\""
            },
            {
                "__id": 98,
                "address_city": "\"Buena Park\"",
                "address_full": "\"6041 La Palma Ave\"",
                "address_postcode": "\"90620\"",
                "address_state": "\"CA\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"mexican\"",
                "latitude": 33.84668,
                "longitude": -118.02748,
                "name": "\"la-palma-ave-valley-view-st\"",
                "phone": "\"714-523-7180 \"",
                "takeaway": "\"yes\"",
                "website": "\"https://www.elpolloloco.com/locations/locations_json\""
            },
            {
                "__id": 276,
                "address_city": "\"Las Vegas\"",
                "address_full": "\"5688 S Rainbow Blvd\"",
                "address_postcode": "\"89118\"",
                "address_state": "\"NV\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"mexican\"",
                "latitude": 36.085516,
                "longitude": -115.242286,
                "name": "\"\"",
                "phone": "\"725-210-6082\"",
                "takeaway": "\"yes\"",
                "website": "\"https://www.elpolloloco.com/locations/locations_json\""
            },
            {
                "__id": 200,
                "address_city": "\"Humble\"",
                "address_full": "\"9540 FM 1960 Bypass Rd E\"",
                "address_postcode": "\"77338\"",
                "address_state": "\"TX\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"mexican\"",
                "latitude": 30.00427,
                "longitude": -95.27887,
                "name": "\"fm-1960-bypass-rd-e-whitaker-rd\"",
                "phone": "\"281-540-7404\"",
                "takeaway": "\"yes\"",
                "website": "\"https://www.elpolloloco.com/locations/locations_json\""
            },
            {
                "__id": 442,
                "address_city": "\"Apple Valley\"",
                "address_full": "\"19380 Bear Valley Rd\"",
                "address_postcode": "\"92308\"",
                "address_state": "\"CA\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"mexican\"",
                "latitude": 34.47158,
                "longitude": -117.23914,
                "name": "\"bear-valley-rd-apple-valley-rd\"",
                "phone": "\"760-247-5595\"",
                "takeaway": "\"yes\"",
                "website": "\"https://www.elpolloloco.com/locations/locations_json\""
            },
            {
                "__id": 83,
                "address_city": "\"Carson\"",
                "address_full": "\"111 E Carson St\"",
                "address_postcode": "\"90745\"",
                "address_state": "\"CA\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"mexican\"",
                "latitude": 33.83234,
                "longitude": -118.27795,
                "name": "\"e-carson-st-main-st\"",
                "phone": "\"310-830-3491\"",
                "takeaway": "\"yes\"",
                "website": "\"https://www.elpolloloco.com/locations/locations_json\""
            },
            {
                "__id": 384,
                "address_city": "\"Stockton\"",
                "address_full": "\"678 North Wilson Way\"",
                "address_postcode": "\"95205\"",
                "address_state": "\"CA\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"mexican\"",
                "latitude": 37.96292,
                "longitude": -121.2732,
                "name": "\"north-wilson-way-oak-st\"",
                "phone": "\"209-942-2144 \"",
                "takeaway": "\"yes\"",
                "website": "\"https://www.elpolloloco.com/locations/locations_json\""
            },
            {
                "__id": 117,
                "address_city": "\"Bakersfield\"",
                "address_full": "\"2661 Mt Vernon Ave\"",
                "address_postcode": "\"93306\"",
                "address_state": "\"CA\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"mexican\"",
                "latitude": 35.39349,
                "longitude": -118.96579,
                "name": "\"mt-vernon-ave-mall-view-rd\"",
                "phone": "\"661-872-5526\"",
                "takeaway": "\"yes\"",
                "website": "\"https://www.elpolloloco.com/locations/locations_json\""
            },
            {
                "__id": 266,
                "address_city": "\"Los Angeles\"",
                "address_full": "\"2000 Cesar E Chavez Ave\"",
                "address_postcode": "\"90033\"",
                "address_state": "\"CA\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"mexican\"",
                "latitude": 34.04884,
                "longitude": -118.21265,
                "name": "\"\"",
                "phone": "\"323-265-0179\"",
                "takeaway": "\"yes\"",
                "website": "\"https://www.elpolloloco.com/locations/locations_json\""
            },
            {
                "__id": 163,
                "address_city": "\"San Jacinto\"",
                "address_full": "\"1881 S San Jacinto Ave\"",
                "address_postcode": "\"92583\"",
                "address_state": "\"CA\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"mexican\"",
                "latitude": 33.767,
                "longitude": -116.9584,
                "name": "\"s-san-jacinto-ave-e-commonwealth-ave\"",
                "phone": "\"951-487-6622\"",
                "takeaway": "\"yes\"",
                "website": "\"https://www.elpolloloco.com/locations/locations_json\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "barnesnoblecollege": {
        "__id": 185,
        "id": "barnesnoblecollege",
        "name": "Barnes & Noble College",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'name', 'website']",
        "rowLength": 306,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763595,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of barnesnoblecollege locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 225,
                "address_city": "\"York\"",
                "address_country": "\"US\"",
                "address_full": "\"100 East End Ave\"",
                "address_postcode": "\"10028\"",
                "address_state": "\"NY\"",
                "name": "\"The Chapin School\"",
                "website": "\"https://www.bncollege.com/campus-stores/\""
            },
            {
                "__id": 36,
                "address_city": "\"Angeles\"",
                "address_country": "\"US\"",
                "address_full": "\"919 Albany St\"",
                "address_postcode": "\"90015\"",
                "address_state": "\"CA\"",
                "name": "\"Loyola Marymount University\"",
                "website": "\"https://www.bncollege.com/campus-stores/\""
            },
            {
                "__id": 252,
                "address_city": "\"Grove\"",
                "address_country": "\"US\"",
                "address_full": "\"2043 College Way\"",
                "address_postcode": "\"97116\"",
                "address_state": "\"OR\"",
                "name": "\"Pacific University\"",
                "website": "\"https://www.bncollege.com/campus-stores/\""
            },
            {
                "__id": 126,
                "address_city": "\"Orleans\"",
                "address_country": "\"US\"",
                "address_full": "\"4950 Dauphine St\"",
                "address_postcode": "\"70117\"",
                "address_state": "\"LA\"",
                "name": "\"Holy Cross School\"",
                "website": "\"https://www.bncollege.com/campus-stores/\""
            },
            {
                "__id": 79,
                "address_city": "\"Haven\"",
                "address_country": "\"US\"",
                "address_full": "\"77 Broadway\"",
                "address_postcode": "\"06511\"",
                "address_state": "\"CT\"",
                "name": "\"Yale University\"",
                "website": "\"https://www.bncollege.com/campus-stores/\""
            },
            {
                "__id": 269,
                "address_city": "\"Gap\"",
                "address_country": "\"US\"",
                "address_full": "\"6965 Cumberland Gap Pkwy\"",
                "address_postcode": "\"37724\"",
                "address_state": "\"TN\"",
                "name": "\"Lincoln Memorial University\"",
                "website": "\"https://www.bncollege.com/campus-stores/\""
            },
            {
                "__id": 173,
                "address_city": "\"Louis\"",
                "address_country": "\"US\"",
                "address_full": "\"755 S Price Rd\"",
                "address_postcode": "\"63124\"",
                "address_state": "\"MO\"",
                "name": "\"John Burroughs School\"",
                "website": "\"https://www.bncollege.com/campus-stores/\""
            },
            {
                "__id": 15,
                "address_city": "\"Jose\"",
                "address_country": "\"US\"",
                "address_full": "\"960 W Hedding St\"",
                "address_postcode": "\"95126\"",
                "address_state": "\"CA\"",
                "name": "\"Bellarmine College Preparatory\"",
                "website": "\"https://www.bncollege.com/campus-stores/\""
            },
            {
                "__id": 300,
                "address_city": "\"Beach\"",
                "address_country": "\"US\"",
                "address_full": "\"1700 College Crescent\"",
                "address_postcode": "\"23453\"",
                "address_state": "\"VA\"",
                "name": "\"Tidewater Community College\"",
                "website": "\"https://www.bncollege.com/campus-stores/\""
            },
            {
                "__id": 203,
                "address_city": "\"Vegas\"",
                "address_country": "\"US\"",
                "address_full": "\"366 Luna Dr\"",
                "address_postcode": "\"87701\"",
                "address_state": "\"NM\"",
                "name": "\"Luna Community College\"",
                "website": "\"https://www.bncollege.com/campus-stores/\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "coen_markets": {
        "__id": 186,
        "id": "coen_markets",
        "name": "Coen Markets",
        "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone']",
        "rowLength": 57,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763596,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of coen_markets locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 3,
                "address_city": "\"Norvelt\"",
                "address_full": "\"2400 Mt. Pleasant Road\"",
                "address_postcode": "\"15674\"",
                "address_state": "\"PA\"",
                "latitude": 40.202371,
                "longitude": -79.49759,
                "name": "\"Coen\"",
                "opening_hours": "\"MON-SUN: 5AM - 10PM\"",
                "phone": "\"724-343-3074\""
            },
            {
                "__id": 21,
                "address_city": "\"Waynesburg\"",
                "address_full": "\"331 East Roy Furman Highway\"",
                "address_postcode": "\"15370\"",
                "address_state": "\"PA\"",
                "latitude": 39.885823,
                "longitude": -80.072613,
                "name": "\"Coen\"",
                "opening_hours": "\"MON-SUN: 5AM - 10PM\"",
                "phone": "\"724-638-9315\""
            },
            {
                "__id": 51,
                "address_city": "\"Bentleyville\"",
                "address_full": "\"204 Wilson Road\"",
                "address_postcode": "\"15314\"",
                "address_state": "\"PA\"",
                "latitude": 40.130895,
                "longitude": -80.000327,
                "name": "\"Coen\"",
                "opening_hours": "\"MON-SUN: 5AM - 9PM\"",
                "phone": "\"724-300-8037\""
            },
            {
                "__id": 36,
                "address_city": "\"Powhatan Point\"",
                "address_full": "\"288 OH-7\"",
                "address_postcode": "\"43942\"",
                "address_state": "\"OH\"",
                "latitude": 39.864473,
                "longitude": -80.804425,
                "name": "\"Coen\"",
                "opening_hours": "\"MON-SUN: 5AM - 10PM\"",
                "phone": "\" 740-340-0954\""
            },
            {
                "__id": 30,
                "address_city": "\"South Park\"",
                "address_full": "\"2880 BROWNSVILLE ROAD\"",
                "address_postcode": "\"15129\"",
                "address_state": "\"PA\"",
                "latitude": 40.287652,
                "longitude": -79.997005,
                "name": "\"Coen\"",
                "opening_hours": "\"Mon-Sun: 5A -11PM\"",
                "phone": "\"412-851-5470\""
            },
            {
                "__id": 12,
                "address_city": "\"LEVEL GREEN\"",
                "address_full": "\"561 ST ROUTE 130\"",
                "address_postcode": "\"15085\"",
                "address_state": "\"PA\"",
                "latitude": 40.387483,
                "longitude": -79.764874,
                "name": "\"Coen\"",
                "opening_hours": "\"MON-SUN: 5AM - 9PM\"",
                "phone": "\"724-947-3669\""
            },
            {
                "__id": 6,
                "address_city": "\"Mt. Washington Bailey Ave\"",
                "address_full": "\"20 BAILEY AVENUE PITTSBURGH\"",
                "address_postcode": "\"15211\"",
                "address_state": "\"PA\"",
                "latitude": 40.426793,
                "longitude": -80.005436,
                "name": "\"Coen\"",
                "opening_hours": "\"MON-SUN: 5AM - 11PM\"",
                "phone": "\"412-431-6722\""
            },
            {
                "__id": 28,
                "address_city": "\"Southside 24th St.\"",
                "address_full": "\"2401 E CARSON ST PITTSBURGH\"",
                "address_postcode": "\"15203\"",
                "address_state": "\"PA\"",
                "latitude": 40.428332,
                "longitude": -79.970923,
                "name": "\"Coen\"",
                "opening_hours": "\"MON-SUN: 5AM - 11PM\"",
                "phone": "\"412-431-3550\""
            },
            {
                "__id": 26,
                "address_city": "\"Toronto\"",
                "address_full": "\"1860 Franklin Street\"",
                "address_postcode": "\"43964\"",
                "address_state": "\"OH\"",
                "latitude": 40.450545,
                "longitude": -80.615701,
                "name": "\"Coen\"",
                "opening_hours": "\"MON-SUN: 5AM - 11PM\"",
                "phone": "\"740-537-9591\""
            },
            {
                "__id": 9,
                "address_city": "\"MONACA\"",
                "address_full": "\"1400 PENNSYLVANIA AVE\"",
                "address_postcode": "\"15061\"",
                "address_state": "\"PA\"",
                "latitude": 40.688769,
                "longitude": -80.274992,
                "name": "\"Coen\"",
                "opening_hours": "\"MON-SUN: 5AM - 11PM\"",
                "phone": "\"724-709-8612\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "gen_rehab": {
        "__id": 187,
        "id": "gen_rehab",
        "name": "Genesis Rehab",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name']",
        "rowLength": 229,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763597,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of gen_rehab locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 190,
                "address_city": "\"Charles Town\"",
                "address_country": "\"US\"",
                "address_full": "\"50 Mulberry Tree Street\"",
                "address_postcode": "\"25414\"",
                "address_state": "\"WV\"",
                "latitude": 39.293591,
                "longitude": -77.845928,
                "name": "\"Genesis Healthcare\""
            },
            {
                "__id": 91,
                "address_city": "\"Wyncote\"",
                "address_country": "\"US\"",
                "address_full": "\"1245 Church Road\"",
                "address_postcode": "\"19095\"",
                "address_state": "\"PA\"",
                "latitude": 40.087083,
                "longitude": -75.148422,
                "name": "\"Genesis Healthcare\""
            },
            {
                "__id": 198,
                "address_city": "\"Trumbull\"",
                "address_country": "\"US\"",
                "address_full": "\"6448 Main Street\"",
                "address_postcode": "\"06611-2075\"",
                "address_state": "\"CT\"",
                "latitude": 41.275291,
                "longitude": -73.226897,
                "name": "\"Genesis Healthcare\""
            },
            {
                "__id": 187,
                "address_city": "\"Farmington\"",
                "address_country": "\"US\"",
                "address_full": "\"119 Livermore Falls Road\"",
                "address_postcode": "\"04938\"",
                "address_state": "\"ME\"",
                "latitude": 44.620475,
                "longitude": -70.160051,
                "name": "\"Genesis Healthcare\""
            },
            {
                "__id": 19,
                "address_city": "\"Cedar Grove\"",
                "address_country": "\"US\"",
                "address_full": "\"25 East Lindsley Road\"",
                "address_postcode": "\"07009\"",
                "address_state": "\"NJ\"",
                "latitude": 40.875163,
                "longitude": -74.238261,
                "name": "\"Genesis Healthcare\""
            },
            {
                "__id": 1,
                "address_city": "\"Garden Grove\"",
                "address_country": "\"US\"",
                "address_full": "\"13075 Blackbird\"",
                "address_postcode": "\"92843\"",
                "address_state": "\"CA\"",
                "latitude": 33.773436,
                "longitude": -117.913515,
                "name": "\"Genesis Healthcare\""
            },
            {
                "__id": 132,
                "address_city": "\"Mesa\"",
                "address_country": "\"US\"",
                "address_full": "\"255 West Brown Road\"",
                "address_postcode": "\"85201\"",
                "address_state": "\"AZ\"",
                "latitude": 33.434661,
                "longitude": -111.8394379,
                "name": "\"Genesis Healthcare\""
            },
            {
                "__id": 136,
                "address_city": "\"Spencer\"",
                "address_country": "\"US\"",
                "address_full": "\"825 Summit Street\"",
                "address_postcode": "\"25276\"",
                "address_state": "\"WV\"",
                "latitude": 38.809334,
                "longitude": -81.364375,
                "name": "\"Genesis Healthcare\""
            },
            {
                "__id": 51,
                "address_city": "\"Monroe Township\"",
                "address_country": "\"US\"",
                "address_full": "\"292 Applegarth Rd.\"",
                "address_postcode": "\"08831\"",
                "address_state": "\"NJ\"",
                "latitude": 40.31255,
                "longitude": -74.472399,
                "name": "\"Genesis Healthcare\""
            },
            {
                "__id": 82,
                "address_city": "\"Lewisport\"",
                "address_country": "\"US\"",
                "address_full": "\"8005 U. S. Highway 60 West\"",
                "address_postcode": "\"42351-7079\"",
                "address_state": "\"KY\"",
                "latitude": 37.925735,
                "longitude": -86.887024,
                "name": "\"Genesis Healthcare\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "woods_coffee": {
        "__id": 188,
        "id": "woods_coffee",
        "name": "Woods Coffee",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'street_address']",
        "rowLength": 19,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763598,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of woods_coffee locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 15,
                "address_city": "\"Bothell\"",
                "address_country": "\"US\"",
                "address_full": "\"24118 Bothell Everett Hwy Suite 500, Bothell, WA, 98021, United States\"",
                "address_postcode": "\"98021\"",
                "address_state": "\"WA\"",
                "latitude": 47.77880952,
                "longitude": -122.21908035,
                "name": "\"Lakeside-Bothell - Woods Coffee\"",
                "opening_hours": "\"Mo-Fr 05:00-18:00; Sa-Su 06:00-18:00\"",
                "phone": "\"(425) 217-2802\"",
                "street_address": "\"24118 Bothell Everett Hwy Suite 500\""
            },
            {
                "__id": 8,
                "address_city": "\"Edmonds\"",
                "address_country": "\"US\"",
                "address_full": "\"22019 Highway 99 Suite #100, Edmonds, WA, 98026, United States\"",
                "address_postcode": "\"98026\"",
                "address_state": "\"WA\"",
                "latitude": 47.79892,
                "longitude": -122.33158,
                "name": "\"Edmonds - Woods Coffee\"",
                "opening_hours": "\"Mo-Fr 05:00-18:00; Sa-Su 06:00-18:00\"",
                "phone": "\"(425) 977-0258\"",
                "street_address": "\"22019 Highway 99 Suite #100\""
            },
            {
                "__id": 2,
                "address_city": "\"Bellingham\"",
                "address_country": "\"US\"",
                "address_full": "\"3008 Cinema Place, Bellingham, WA, 98273, United States\"",
                "address_postcode": "\"98273\"",
                "address_state": "\"WA\"",
                "latitude": 48.77002459,
                "longitude": -122.44832609,
                "name": "\"Barkley Village - Woods Coffee\"",
                "opening_hours": "\"Mo-Su 05:30-18:00\"",
                "phone": "\"(360) 933-1695\"",
                "street_address": "\"3008 Cinema Place\""
            },
            {
                "__id": 16,
                "address_city": "\"Bellingham\"",
                "address_country": "\"US\"",
                "address_full": "\"813 Lakeway Dr., Bellingham, WA, 98229, United States\"",
                "address_postcode": "\"98229\"",
                "address_state": "\"WA\"",
                "latitude": 48.74521,
                "longitude": -122.46414,
                "name": "\"Lakeway - Woods Coffee\"",
                "opening_hours": "\"Mo-Sa 04:30-20:00; Su 05:30-19:00\"",
                "phone": "\"(360) 325-7837\"",
                "street_address": "\"813 Lakeway Dr.\""
            },
            {
                "__id": 9,
                "address_city": "\"Everett\"",
                "address_country": "\"US\"",
                "address_full": "\"10318 Evergreen Way, Everett, WA, 98204, United States\"",
                "address_postcode": "\"98204\"",
                "address_state": "\"WA\"",
                "latitude": 47.90413401,
                "longitude": -122.24522104,
                "name": "\"Evergreen Way - Woods Coffee\"",
                "opening_hours": "\"Mo-Fr 04:30-18:00; Sa-Su 05:00-18:00\"",
                "phone": "\"(360) 815-8034\"",
                "street_address": "\"10318 Evergreen Way\""
            },
            {
                "__id": 4,
                "address_city": "\"Lynden\"",
                "address_country": "\"US\"",
                "address_full": "\"8874 Bender Rd., Lynden, WA, 98264, United States\"",
                "address_postcode": "\"98264\"",
                "address_state": "\"WA\"",
                "latitude": 48.96346656,
                "longitude": -122.43997353,
                "name": "\"Bender Plaza - Woods Coffee\"",
                "opening_hours": "\"Mo-Fr 05:30-18:00; Sa-Su 06:00-18:00\"",
                "phone": "\"(360) 354-0900\"",
                "street_address": "\"8874 Bender Rd.\""
            },
            {
                "__id": 19,
                "address_city": "\"Bellingham\"",
                "address_country": "\"US\"",
                "address_full": "\"1135 Railroad Ave., Bellingham, WA, 98225, United States\"",
                "address_postcode": "\"98225\"",
                "address_state": "\"WA\"",
                "latitude": 48.74802361,
                "longitude": -122.47973251,
                "name": "\"Railroad - Woods Coffee\"",
                "opening_hours": "\"Mo-Su 06:30-17:00\"",
                "phone": "\"(360) 306-8291\"",
                "street_address": "\"1135 Railroad Ave.\""
            },
            {
                "__id": 13,
                "address_city": "\"Bellevue\"",
                "address_country": "\"US\"",
                "address_full": "\"625 116th Ave. NE, Bellevue, WA, 98004, United States\"",
                "address_postcode": "\"98004\"",
                "address_state": "\"WA\"",
                "latitude": 47.61606,
                "longitude": -122.18635,
                "name": "\"Hotel 116 - Woods Coffee\"",
                "opening_hours": "\"Mo-Fr 04:30-20:00; Sa 05:30-20:00; Su 06:00-20:00\"",
                "phone": "\"(425) 800-4426\"",
                "street_address": "\"625 116th Ave. NE\""
            },
            {
                "__id": 3,
                "address_city": "\"Bellevue\"",
                "address_country": "\"US\"",
                "address_full": "\"2002 Bellevue Square, Bellevue, WA, 98004, United States\"",
                "address_postcode": "\"98004\"",
                "address_state": "\"WA\"",
                "latitude": 47.61620644,
                "longitude": -122.20496587,
                "name": "\"Bellevue Square - Woods Coffee\"",
                "opening_hours": "\"Mo-Sa 08:00-21:00; Su 09:00-19:00\"",
                "phone": "\"(425) 429-7276\"",
                "street_address": "\"2002 Bellevue Square\""
            },
            {
                "__id": 18,
                "address_city": "\"Bellingham\"",
                "address_country": "\"US\"",
                "address_full": "\"102 W. Stuart Rd., Bellingham, WA, 98226, United States\"",
                "address_postcode": "\"98226\"",
                "address_state": "\"WA\"",
                "latitude": 48.80084738,
                "longitude": -122.48644975,
                "name": "\"Meridian - Woods Coffee\"",
                "opening_hours": "\"Mo-Fr 05:30-19:00; Sa 06:00-19:00; Su 06:00-18:00\"",
                "phone": "\"(360) 733-9570\"",
                "street_address": "\"102 W. Stuart Rd.\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "gnc": {
        "__id": 189,
        "id": "gnc",
        "name": "GNC",
        "columnNames": "['address_country', 'address_postcode', 'address_state', 'image', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'website']",
        "rowLength": 2307,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763599,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of gnc locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 821,
                "address_country": "\"US\"",
                "address_postcode": "\"28403\"",
                "address_state": "\"NC\"",
                "image": "\"https://stores.gnc.com/all-stores-north-carolina/wilmington/412-south-college-road\"",
                "latitude": 34.23424334785003,
                "longitude": -77.87863816711562,
                "name": "\"GNC University Centre\"",
                "opening_hours": "\"Mo-Sa 10:00-20:00; Su 12:00-17:00\"",
                "phone": "\"(910) 452-1828\"",
                "shop": "\"nutrition_supplements\"",
                "street_address": "\"412 South College Road Suite 59\"",
                "website": "\"https://stores.gnc.com/all-stores-north-carolina/wilmington/412-south-college-road\""
            },
            {
                "__id": 1921,
                "address_country": "\"US\"",
                "address_postcode": "\"33073\"",
                "address_state": "\"FL\"",
                "image": "\"https://stores.gnc.com/all-stores-florida/coral-springs/4336-n-state-road-7\"",
                "latitude": 26.28304905330209,
                "longitude": -80.20348330111173,
                "name": "\"GNC Turtle Crossing\"",
                "opening_hours": "\"Mo-Sa 09:00-21:00; Su 10:00-19:00\"",
                "phone": "\"(954) 752-2803\"",
                "shop": "\"nutrition_supplements\"",
                "street_address": "\"4336 N State Road 7\"",
                "website": "\"https://stores.gnc.com/all-stores-florida/coral-springs/4336-n-state-road-7\""
            },
            {
                "__id": 226,
                "address_country": "\"US\"",
                "address_postcode": "\"77479\"",
                "address_state": "\"TX\"",
                "image": "\"https://stores.gnc.com/all-stores-texas/sugarland/16535-south-west-freeway\"",
                "latitude": 29.591079267075667,
                "longitude": -95.6251732948894,
                "name": "\"GNC First Colony\"",
                "opening_hours": "\"Mo-Fr 11:00-20:00; Sa 11:00-21:00; Su 12:00-18:00\"",
                "phone": "\"(281) 494-2100\"",
                "shop": "\"nutrition_supplements\"",
                "street_address": "\"16535 South West Freeway\"",
                "website": "\"https://stores.gnc.com/all-stores-texas/sugarland/16535-south-west-freeway\""
            },
            {
                "__id": 430,
                "address_country": "\"US\"",
                "address_postcode": "\"78610\"",
                "address_state": "\"TX\"",
                "image": "\"https://stores.gnc.com/all-stores-texas/buda/640-old-san-antonio-rd\"",
                "latitude": 30.08634953889726,
                "longitude": -97.82277182464854,
                "name": "\"GNC Buda Crossing\"",
                "opening_hours": "\"Mo-Sa 10:00-20:00; Su 12:00-18:00\"",
                "phone": "\"(512) 295-1950\"",
                "shop": "\"nutrition_supplements\"",
                "street_address": "\"640 Old San Antonio Rd\"",
                "website": "\"https://stores.gnc.com/all-stores-texas/buda/640-old-san-antonio-rd\""
            },
            {
                "__id": 1448,
                "address_country": "\"US\"",
                "address_postcode": "\"66502\"",
                "address_state": "\"KS\"",
                "image": "\"https://stores.gnc.com/all-stores-kansas/manhattan/100-manhattan-town-center\"",
                "latitude": 39.178991,
                "longitude": -96.5591829,
                "name": "\"GNC Manhattan Town Center\"",
                "opening_hours": "\"Mo-Sa 10:00-18:00; Su 12:00-18:00\"",
                "phone": "\"(785) 539-5561\"",
                "shop": "\"nutrition_supplements\"",
                "street_address": "\"100 Manhattan Town Center\"",
                "website": "\"https://stores.gnc.com/all-stores-kansas/manhattan/100-manhattan-town-center\""
            },
            {
                "__id": 937,
                "address_country": "\"US\"",
                "address_postcode": "\"10033\"",
                "address_state": "\"NY\"",
                "image": "\"https://stores.gnc.com/all-stores-new-york/new-york/620-west-181st-street\"",
                "latitude": 40.849554949096344,
                "longitude": -73.93472408283598,
                "name": "\"GNC 620 West 181st Street\"",
                "opening_hours": "\"Mo-Sa 10:00-20:00; Su 10:00-19:00\"",
                "phone": "\"(212) 927-0629\"",
                "shop": "\"nutrition_supplements\"",
                "street_address": "\"620 West 181st Street\"",
                "website": "\"https://stores.gnc.com/all-stores-new-york/new-york/620-west-181st-street\""
            },
            {
                "__id": 514,
                "address_country": "\"US\"",
                "address_postcode": "\"37421\"",
                "address_state": "\"TN\"",
                "image": "\"https://stores.gnc.com/all-stores-tennessee/chattanooga/2100-hamilton-place-blvd\"",
                "latitude": 35.033512261353636,
                "longitude": -85.15805170851152,
                "name": "\"GNC Hamilton Place\"",
                "opening_hours": "\"Mo-Sa 10:00-19:00; Su 12:00-18:00\"",
                "phone": "\"(423) 894-9954\"",
                "shop": "\"nutrition_supplements\"",
                "street_address": "\"2100 Hamilton Place Blvd Suite 252\"",
                "website": "\"https://stores.gnc.com/all-stores-tennessee/chattanooga/2100-hamilton-place-blvd\""
            },
            {
                "__id": 1766,
                "address_country": "\"US\"",
                "address_postcode": "\"33710\"",
                "address_state": "\"FL\"",
                "image": "\"https://stores.gnc.com/all-stores-florida/saint-petersburg/6901-22nd-avenue-north\"",
                "latitude": 27.794179347599087,
                "longitude": -82.73431889967435,
                "name": "\"GNC Tyrone Square Mall\"",
                "opening_hours": "\"Mo-Sa 10:00-20:00; Su 11:00-18:00\"",
                "phone": "\"(727) 347-7027\"",
                "shop": "\"nutrition_supplements\"",
                "street_address": "\"6901 22nd Avenue North\"",
                "website": "\"https://stores.gnc.com/all-stores-florida/saint-petersburg/6901-22nd-avenue-north\""
            },
            {
                "__id": 541,
                "address_country": "\"US\"",
                "address_postcode": "\"29575\"",
                "address_state": "\"SC\"",
                "image": "\"https://stores.gnc.com/all-stores-south-carolina/myrtle-beach/1042-coastal-grand-circle\"",
                "latitude": 33.703054020901085,
                "longitude": -78.92151751775005,
                "name": "\"GNC Coastal Grand Mall\"",
                "opening_hours": "\"Mo-Th 11:00-20:00; Fr-Sa 11:00-21:00; Su 12:00-18:00\"",
                "phone": "\"(843) 839-3265\"",
                "shop": "\"nutrition_supplements\"",
                "street_address": "\"1042 Coastal Grand Circle Space 363\"",
                "website": "\"https://stores.gnc.com/all-stores-south-carolina/myrtle-beach/1042-coastal-grand-circle\""
            },
            {
                "__id": 481,
                "address_country": "\"US\"",
                "address_postcode": "\"37129\"",
                "address_state": "\"TN\"",
                "image": "\"https://stores.gnc.com/all-stores-tennessee/murfreesboro/1720-old-fort-parkway\"",
                "latitude": 35.84857395106446,
                "longitude": -86.41863433833369,
                "name": "\"GNC Stones River Mall\"",
                "opening_hours": "\"Mo-Sa 10:00-19:00; Su 12:00-18:00\"",
                "phone": "\"(615) 896-8662\"",
                "shop": "\"nutrition_supplements\"",
                "street_address": "\"1720 Old Fort Parkway Suite 125\"",
                "website": "\"https://stores.gnc.com/all-stores-tennessee/murfreesboro/1720-old-fort-parkway\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "harborfreight": {
        "__id": 190,
        "id": "harborfreight",
        "name": "Harbor Freight",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'contact_email', 'image', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'website']",
        "rowLength": 1392,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763600,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of harborfreight locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 126,
                "address_city": "\"Springville\"",
                "address_country": "\"US\"",
                "address_postcode": "\"14141\"",
                "address_state": "\"NY\"",
                "contact_email": "\"cs@harborfreight.com\"",
                "image": "\"https://images.harborfreight.com/hftweb/stores/images/smallImages/store_3158.png\"",
                "latitude": 42.503218,
                "longitude": -78.689291,
                "name": "\"Harbor Freight Tools Springville, NY\"",
                "opening_hours": "\"Mo-Sa 08:00-20:00; Su 09:00-18:00\"",
                "phone": "\"716-427-5800\"",
                "shop": "\"hardware\"",
                "street_address": "\"204 South Cascade Drive TEST\"",
                "website": "\"https://www.harborfreight.com/storelocator/204-south-cascade-drive-springville-14141?number=3158\""
            },
            {
                "__id": 264,
                "address_city": "\"Lancaster\"",
                "address_country": "\"US\"",
                "address_postcode": "\"43130\"",
                "address_state": "\"OH\"",
                "contact_email": "\"cs@harborfreight.com\"",
                "image": "\"https://images.harborfreight.com/hftweb/stores/images/smallImages/store_2987.png\"",
                "latitude": 39.746498,
                "longitude": -82.653709,
                "name": "\"Harbor Freight Tools Lancaster, OH\"",
                "opening_hours": "\"Mo-Sa 08:00-20:00; Su 09:00-18:00\"",
                "phone": "\"740-918-8222\"",
                "shop": "\"hardware\"",
                "street_address": "\"2805 Columbus Lancaster Road NW TEST\"",
                "website": "\"https://www.harborfreight.com/storelocator/2805-columbus-lancaster-road-nw-lancaster-43130?number=2987\""
            },
            {
                "__id": 553,
                "address_city": "\"Ridgeland\"",
                "address_country": "\"US\"",
                "address_postcode": "\"39157\"",
                "address_state": "\"MS\"",
                "contact_email": "\"cs@harborfreight.com\"",
                "image": "\"https://images.harborfreight.com/hftweb/stores/images/smallImages/store_508.png\"",
                "latitude": 32.40058,
                "longitude": -90.133913,
                "name": "\"Harbor Freight Tools Ridgeland, MS\"",
                "opening_hours": "\"Mo-Sa 08:00-20:00; Su 11:00-18:00\"",
                "phone": "\"601-957-5743\"",
                "shop": "\"hardware\"",
                "street_address": "\"826 S Wheatley Street TEST\"",
                "website": "\"https://www.harborfreight.com/storelocator/826-s-wheatley-street-ridgeland-39157?number=508\""
            },
            {
                "__id": 729,
                "address_city": "\"Vernal\"",
                "address_country": "\"US\"",
                "address_postcode": "\"84078\"",
                "address_state": "\"UT\"",
                "contact_email": "\"cs@harborfreight.com\"",
                "image": "\"https://images.harborfreight.com/hftweb/stores/images/smallImages/store_886.png\"",
                "latitude": 40.450463,
                "longitude": -109.547961,
                "name": "\"Harbor Freight Tools Vernal, UT\"",
                "opening_hours": "\"Mo-Sa 08:00-20:00; Su 09:00-18:00\"",
                "phone": "\"435-222-9200\"",
                "shop": "\"hardware\"",
                "street_address": "\"1149 W Highway 40 TEST\"",
                "website": "\"https://www.harborfreight.com/storelocator/1149-w-highway-40-vernal-84078?number=886\""
            },
            {
                "__id": 1095,
                "address_city": "\"New Port Richey\"",
                "address_country": "\"US\"",
                "address_postcode": "\"34652\"",
                "address_state": "\"FL\"",
                "contact_email": "\"cs@harborfreight.com\"",
                "image": "\"https://images.harborfreight.com/hftweb/stores/images/smallImages/store_256.png\"",
                "latitude": 28.251807,
                "longitude": -82.727906,
                "name": "\"Harbor Freight Tools New Port Richey, FL\"",
                "opening_hours": "\"Mo-Sa 08:00-20:00; Su 09:00-18:00\"",
                "phone": "\"727-849-4904\"",
                "shop": "\"hardware\"",
                "street_address": "\"6435 US Hwy 19 TEST\"",
                "website": "\"https://www.harborfreight.com/storelocator/6435-us-hwy-19-new-port-richey-34652?number=256\""
            },
            {
                "__id": 232,
                "address_city": "\"Bismarck\"",
                "address_country": "\"US\"",
                "address_postcode": "\"58503\"",
                "address_state": "\"ND\"",
                "contact_email": "\"cs@harborfreight.com\"",
                "image": "\"https://images.harborfreight.com/hftweb/stores/images/smallImages/store_620.png\"",
                "latitude": 46.83635,
                "longitude": -100.777151,
                "name": "\"Harbor Freight Tools Bismarck, ND\"",
                "opening_hours": "\"Mo-Sa 08:00-20:00; Su 09:00-18:00\"",
                "phone": "\"701-258-1551\"",
                "shop": "\"hardware\"",
                "street_address": "\"2700 State St Ste B-1 TEST\"",
                "website": "\"https://www.harborfreight.com/storelocator/2700-state-st-ste-b-1-bismarck-58503?number=620\""
            },
            {
                "__id": 1086,
                "address_city": "\"Palm Harbor\"",
                "address_country": "\"US\"",
                "address_postcode": "\"34684\"",
                "address_state": "\"FL\"",
                "contact_email": "\"cs@harborfreight.com\"",
                "image": "\"https://images.harborfreight.com/hftweb/stores/images/smallImages/store_474.png\"",
                "latitude": 28.052945,
                "longitude": -82.738844,
                "name": "\"Harbor Freight Tools Palm Harbor, FL\"",
                "opening_hours": "\"Mo-Sa 08:00-20:00; Su 09:00-18:00\"",
                "phone": "\"727-772-5724\"",
                "shop": "\"hardware\"",
                "street_address": "\"30990 US Highway 19 N TEST\"",
                "website": "\"https://www.harborfreight.com/storelocator/30990-us-highway-19-n-palm-harbor-34684?number=474\""
            },
            {
                "__id": 1199,
                "address_city": "\"Ponderay\"",
                "address_country": "\"US\"",
                "address_postcode": "\"83852\"",
                "address_state": "\"ID\"",
                "contact_email": "\"cs@harborfreight.com\"",
                "image": "\"https://images.harborfreight.com/hftweb/stores/images/smallImages/store_3145.png\"",
                "latitude": 48.301749,
                "longitude": -116.541979,
                "name": "\"Harbor Freight Tools Ponderay, ID\"",
                "opening_hours": "\"Mo-Sa 08:00-20:00; Su 09:00-18:00\"",
                "phone": "\"208-920-5999\"",
                "shop": "\"hardware\"",
                "street_address": "\"300 Bonner Mall Way #102 TEST\"",
                "website": "\"https://www.harborfreight.com/storelocator/300-bonner-mall-way-102-ponderay-83852?number=3145\""
            },
            {
                "__id": 261,
                "address_city": "\"Marion\"",
                "address_country": "\"US\"",
                "address_postcode": "\"43302\"",
                "address_state": "\"OH\"",
                "contact_email": "\"cs@harborfreight.com\"",
                "image": "\"https://images.harborfreight.com/hftweb/stores/images/smallImages/store_550.png\"",
                "latitude": 40.579401,
                "longitude": -83.087067,
                "name": "\"Harbor Freight Tools Marion, OH\"",
                "opening_hours": "\"Mo-Sa 08:00-20:00; Su 09:00-18:00\"",
                "phone": "\"740-386-2927\"",
                "shop": "\"hardware\"",
                "street_address": "\"1581 Marion Mt Gilead Rd TEST\"",
                "website": "\"https://www.harborfreight.com/storelocator/1581-marion-mt-gilead-rd-marion-43302?number=550\""
            },
            {
                "__id": 1326,
                "address_city": "\"Campbellsville\"",
                "address_country": "\"US\"",
                "address_postcode": "\"42718\"",
                "address_state": "\"KY\"",
                "contact_email": "\"cs@harborfreight.com\"",
                "image": "\"https://images.harborfreight.com/hftweb/stores/images/smallImages/store_3264.png\"",
                "latitude": 37.345977,
                "longitude": -85.370129,
                "name": "\"Harbor Freight Tools Campbellsville, KY\"",
                "opening_hours": "\"Mo-Sa 08:00-20:00; Su 09:00-18:00\"",
                "phone": "\"270-937-1212\"",
                "shop": "\"hardware\"",
                "street_address": "\"600 Campbellsville Byp TEST\"",
                "website": "\"https://www.harborfreight.com/storelocator/600-campbellsville-byp-campbellsville-42718?number=3264\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "hopdoddy_burger_bar": {
        "__id": 191,
        "id": "hopdoddy_burger_bar",
        "name": "Hopdoddy Burger Bar",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'phone']",
        "rowLength": 37,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763601,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of hopdoddy_burger_bar locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 30,
                "address_city": "\"Houston\"",
                "address_country": "\"US\"",
                "address_full": "\"449 West 19th Street, Suite D-100\"",
                "address_postcode": "\"77008\"",
                "address_state": "\"TX\"",
                "latitude": 29.803006,
                "longitude": -95.4055768,
                "name": "\"Houston Heights\"",
                "phone": "\"(346) 250-2100\""
            },
            {
                "__id": 15,
                "address_city": "\"Round Rock\"",
                "address_country": "\"US\"",
                "address_full": "\"2600 Hoppe Trail\"",
                "address_postcode": "\"78681\"",
                "address_state": "\"TX\"",
                "latitude": 30.5384857,
                "longitude": -97.6930626,
                "name": "\"Round Rock\"",
                "phone": "\"(512) 310-2337\""
            },
            {
                "__id": 11,
                "address_city": "\"San Antonio\"",
                "address_country": "\"US\"",
                "address_full": "\"17623 La Cantera PARKWAY SUITE 101\"",
                "address_postcode": "\"78257\"",
                "address_state": "\"TX\"",
                "latitude": 29.6094694,
                "longitude": -98.5986326,
                "name": "\"The Rim\"",
                "phone": "\"(210) 434-2337\""
            },
            {
                "__id": 2,
                "address_city": "\"El Segundo\"",
                "address_country": "\"US\"",
                "address_full": "\"830 South Pacific Coast Highway Boulevard, Suite 116\"",
                "address_postcode": "\"90245\"",
                "address_state": "\"CA\"",
                "latitude": 33.9018058,
                "longitude": -118.3960659,
                "name": "\"El Segundo\"",
                "phone": "\"(310) 414-2337\""
            },
            {
                "__id": 6,
                "address_city": "\"Scottsdale\"",
                "address_country": "\"US\"",
                "address_full": "\"11055 North SCOTTSDALE ROAD\"",
                "address_postcode": "\"85254\"",
                "address_state": "\"AZ\"",
                "latitude": 33.586665,
                "longitude": -111.92466,
                "name": "\"Scottsdale\"",
                "phone": "\"(480) 348-2337\""
            },
            {
                "__id": 5,
                "address_city": "\"Phoenix\"",
                "address_country": "\"US\"",
                "address_full": "\"2033 East Camelback Road, Suite A13\"",
                "address_postcode": "\"85016\"",
                "address_state": "\"AZ\"",
                "latitude": 33.5083289,
                "longitude": -112.0375069,
                "name": "\"Camelback\"",
                "phone": "\"(602) 242-2337\""
            },
            {
                "__id": 19,
                "address_city": "\"Austin\"",
                "address_country": "\"US\"",
                "address_full": "\"1400 SOUTH Congress Ave, SUITE A190\"",
                "address_postcode": "\"78704\"",
                "address_state": "\"TX\"",
                "latitude": 30.2497657,
                "longitude": -97.7501222,
                "name": "\"South Congress\"",
                "phone": "\"(512) 243-7505\""
            },
            {
                "__id": 4,
                "address_city": "\"Tustin\"",
                "address_country": "\"US\"",
                "address_full": "\"3030 El Camino Real\"",
                "address_postcode": "\"92782\"",
                "address_state": "\"CA\"",
                "latitude": 33.722342,
                "longitude": -117.793607,
                "name": "\"Tustin\"",
                "phone": "\"(714) 505-2337\""
            },
            {
                "__id": 20,
                "address_city": "\"Fort Worth\"",
                "address_country": "\"US\"",
                "address_full": "\"2300 West 7th Street SUITE 140\"",
                "address_postcode": "\"76107\"",
                "address_state": "\"TX\"",
                "latitude": 32.7513287,
                "longitude": -97.3504998,
                "name": "\"Fort Worth Left Bank\"",
                "phone": "\"(817) 270-2337\""
            },
            {
                "__id": 22,
                "address_city": "\"Dallas\"",
                "address_country": "\"US\"",
                "address_full": "\"6030 LUTHER LANE, SUITE 100\"",
                "address_postcode": "\"75225\"",
                "address_state": "\"TX\"",
                "latitude": 32.8631978,
                "longitude": -96.8070369,
                "name": "\"Preston\"",
                "phone": "\"(214) 363-2337\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "konagrill": {
        "__id": 192,
        "id": "konagrill",
        "name": "Kona Grill",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'amenity', 'contact_email', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'street_address', 'website']",
        "rowLength": 24,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763603,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of konagrill locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 17,
                "address_city": "\"Meridian\"",
                "address_country": "\"US\"",
                "address_postcode": "\"83646\"",
                "address_state": "\"ID\"",
                "amenity": "\"restaurant\"",
                "contact_email": "\"boise@konagrill.com\"",
                "cuisine": "\"sushi;cocktails\"",
                "latitude": 43.6214301,
                "longitude": -116.3495101,
                "name": "\"Boise, ID\"",
                "opening_hours": "\"Mo-Th 11:00-23:00; Fr 11:00-00:00; Sa 10:00-00:00; Su 10:00-22:00\"",
                "phone": "\"208-922-6511\"",
                "street_address": "\"3573 E Longwing Lane #140\"",
                "website": "\"https://konagrill.olo.com/menu/kona-grill-boise\""
            },
            {
                "__id": 7,
                "address_city": "\"Las Vegas\"",
                "address_country": "\"US\"",
                "address_postcode": "\"89145\"",
                "address_state": "\"NV\"",
                "amenity": "\"restaurant\"",
                "contact_email": "\"bocapark@konagrill.com\"",
                "cuisine": "\"sushi;cocktails\"",
                "latitude": 36.1643423,
                "longitude": -115.2897075,
                "name": "\"Las Vegas, NV - Boca Park \"",
                "opening_hours": "\"Mo-Th 11:00-23:00; Fr 11:00-00:00; Sa 10:00-00:00; Su 10:00-22:00\"",
                "phone": "\"702-547-5552\"",
                "street_address": "\"750 S. Rampart Blvd.\"",
                "website": "\"https://konagrill.olo.com/menu/kona-grill-boca-park\""
            },
            {
                "__id": 22,
                "address_city": "\"Scottsdale\"",
                "address_country": "\"US\"",
                "address_postcode": "\"85251\"",
                "address_state": "\"AZ\"",
                "amenity": "\"restaurant\"",
                "contact_email": "\"scottsdale@konagrill.com\"",
                "cuisine": "\"sushi;cocktails\"",
                "latitude": 33.5028371,
                "longitude": -111.9294198,
                "name": "\"Scottsdale, AZ - Fashion Square Mall\"",
                "opening_hours": "\"Mo-Th 11:00-23:00; Fr 11:00-00:00; Sa 10:00-00:00; Su 10:00-22:00\"",
                "phone": "\"480-429-1100\"",
                "street_address": "\"7014 E. Camelback Rd.\"",
                "website": "\"https://konagrill.olo.com/menu/kona-grill-scottsdale\""
            },
            {
                "__id": 23,
                "address_city": "\"Gilbert\"",
                "address_country": "\"US\"",
                "address_postcode": "\"85295\"",
                "address_state": "\"AZ\"",
                "amenity": "\"restaurant\"",
                "contact_email": "\"gilbert@konagrill.com\"",
                "cuisine": "\"sushi;cocktails\"",
                "latitude": 33.3078754,
                "longitude": -111.7425841,
                "name": "\"Gilbert, AZ\"",
                "opening_hours": "\"Mo-Th 11:00-23:00; Fr 11:00-00:00; Sa 10:00-00:00; Su 10:00-22:00\"",
                "phone": "\"480-289-4500\"",
                "street_address": "\"2224 E. Williams Field Rd.\"",
                "website": "\"\t https://konagrill.olo.com/menu/kona-grill-gilbert\""
            },
            {
                "__id": 21,
                "address_city": "\"Denver\"",
                "address_country": "\"US\"",
                "address_postcode": "\"80206\"",
                "address_state": "\"CO\"",
                "amenity": "\"restaurant\"",
                "contact_email": "\"denver@konagrill.com\"",
                "cuisine": "\"sushi;cocktails\"",
                "latitude": 39.7175014,
                "longitude": -104.952671,
                "name": "\"Denver, CO\"",
                "opening_hours": "\"Mo-Th 11:00-23:00; Fr 11:00-00:00; Sa 10:00-00:00; Su 10:00-22:00\"",
                "phone": "\"720-974-1300\"",
                "street_address": "\"3000 East 1st Ave #184\"",
                "website": "\"https://konagrill.olo.com/menu/kona-grill-denver\""
            },
            {
                "__id": 20,
                "address_city": "\"Tampa\"",
                "address_country": "\"US\"",
                "address_postcode": "\"33607\"",
                "address_state": "\"FL\"",
                "amenity": "\"restaurant\"",
                "contact_email": "\"tampa@konagrill.com\"",
                "cuisine": "\"sushi;cocktails\"",
                "latitude": 27.964515,
                "longitude": -82.5136519,
                "name": "\"Tampa, FL\"",
                "opening_hours": "\"Mo-Th 11:00-23:00; Fr 11:00-00:00; Sa 10:00-00:00; Su 10:00-22:00\"",
                "phone": "\"813-877-5938\"",
                "street_address": "\"4134 W. Boy Scout Blvd. #b-1\"",
                "website": "\"https://konagrill.olo.com/menu/kona-grill-tampa\""
            },
            {
                "__id": 3,
                "address_city": "\"El Paso\"",
                "address_country": "\"US\"",
                "address_postcode": "\"79925\"",
                "address_state": "\"TX\"",
                "amenity": "\"restaurant\"",
                "contact_email": "\"elpaso@konagrill.com\"",
                "cuisine": "\"sushi;cocktails\"",
                "latitude": 31.7705258,
                "longitude": -106.371798,
                "name": "\"El Paso, TX\"",
                "opening_hours": "\"Mo-Th 11:00-23:00; Fr 11:00-00:00; Sa 10:00-00:00; Su 10:00-22:00\"",
                "phone": "\"915-249-3803\"",
                "street_address": "\"8889 Gateway Blvd West, Ste. 1740\"",
                "website": "\"https://konagrill.olo.com/menu/kona-grill-el-paso\""
            },
            {
                "__id": 24,
                "address_city": "\"Huntsville\"",
                "address_country": "\"US\"",
                "address_postcode": "\"35806\"",
                "address_state": "\"AL\"",
                "amenity": "\"restaurant\"",
                "contact_email": "\"huntsville@konagrill.com\"",
                "cuisine": "\"sushi;cocktails\"",
                "latitude": 34.7150417,
                "longitude": -86.6732173,
                "name": "\"Huntsville, AL\"",
                "opening_hours": "\"Mo-Th 11:00-23:00; Fr 11:00-00:00; Sa 10:00-00:00; Su 10:00-22:00\"",
                "phone": "\"256-808-2540\"",
                "street_address": "\"435 The Bridge Street NW, Ste. 150\"",
                "website": "\"https://konagrill.olo.com/menu/kona-grill-huntsville\""
            },
            {
                "__id": 14,
                "address_city": "\"Baltimore\"",
                "address_country": "\"US\"",
                "address_postcode": "\"21202\"",
                "address_state": "\"MD\"",
                "amenity": "\"restaurant\"",
                "contact_email": "\"baltimore@konagrill.com\"",
                "cuisine": "\"sushi;cocktails\"",
                "latitude": 39.2858926,
                "longitude": -76.6141683,
                "name": "\"Baltimore, MD\"",
                "opening_hours": "\"Mo-Th 11:00-23:00; Fr 11:00-00:00; Sa 10:00-00:00; Su 10:00-22:00\"",
                "phone": "\"410-244-8994\"",
                "street_address": "\"1 E Pratt St. Bldg #103\"",
                "website": "\"https://konagrill.olo.com/menu/kona-grill-baltimore\""
            },
            {
                "__id": 4,
                "address_city": "\"Dallas\"",
                "address_country": "\"US\"",
                "address_postcode": "\"75225\"",
                "address_state": "\"TX\"",
                "amenity": "\"restaurant\"",
                "contact_email": "\"dallas@konagrill.com\"",
                "cuisine": "\"sushi;cocktails\"",
                "latitude": 32.8685678,
                "longitude": -96.7744952,
                "name": "\"Dallas, TX\"",
                "opening_hours": "\"Mo-Th 11:00-23:00; Fr 11:00-00:00; Sa 10:00-00:00; Su 10:00-22:00\"",
                "phone": "\"214-369-7600\"",
                "street_address": "\"8687 North Central Expy\"",
                "website": "\"https://konagrill.olo.com/menu/kona-grill-dallas\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "caesars": {
        "__id": 193,
        "id": "caesars",
        "name": "Caesars",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'phone', 'website']",
        "rowLength": 57,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763604,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of caesars locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 44,
                "address_city": "\"Black Hawk\"",
                "address_country": "\"US\"",
                "address_full": "\"340 Main St\"",
                "address_postcode": "\"80422\"",
                "address_state": "\"CO\"",
                "latitude": 39.79876,
                "longitude": -105.48652,
                "name": "\"Lady Luck Casino Black Hawk\"",
                "phone": "\"(303) 998-7777\"",
                "website": "\"https://ladyluckblackhawk.com/\""
            },
            {
                "__id": 9,
                "address_city": "\"Reno\"",
                "address_country": "\"US\"",
                "address_full": "\"345 N Virginia Street\"",
                "address_postcode": "\"89501\"",
                "address_state": "\"NV\"",
                "latitude": 39.52916,
                "longitude": -119.81464,
                "name": "\"Eldorado Reno Resort Casino\"",
                "phone": "\"(775) 786-5700\"",
                "website": "\"https://www.caesars.com/eldorado-reno\""
            },
            {
                "__id": 31,
                "address_city": "\"Black Hawk\"",
                "address_country": "\"US\"",
                "address_full": "\"401 Main St\"",
                "address_postcode": "\"80422\"",
                "address_state": "\"CO\"",
                "latitude": 39.79802,
                "longitude": -105.48345,
                "name": "\"Horseshoe Black Hawk\"",
                "phone": "\"(800) 843-4753\"",
                "website": "\"https://www.caesars.com/horseshoe-black-hawk\""
            },
            {
                "__id": 34,
                "address_city": "\"Hammond\"",
                "address_country": "\"US\"",
                "address_full": "\"777 Casino Center Drive\"",
                "address_postcode": "\"46320\"",
                "address_state": "\"IN\"",
                "latitude": 41.693536,
                "longitude": -87.508083,
                "name": "\"Horseshoe Hammond\"",
                "phone": "\"(866) 711-SHOE/7463\"",
                "website": "\"https://www.caesars.com/horseshoe-hammond\""
            },
            {
                "__id": 19,
                "address_city": "\"Lake Tahoe\"",
                "address_country": "\"US\"",
                "address_full": "\"15 Highway 50\"",
                "address_postcode": "\"89449\"",
                "address_state": "\"NV\"",
                "latitude": 38.959538,
                "longitude": -119.941279,
                "name": "\"Harrah's Lake Tahoe\"",
                "phone": "\"(800) 427-7247\"",
                "website": "\"https://www.caesars.com/harrahs-tahoe\""
            },
            {
                "__id": 41,
                "address_city": "\"Pompano Beach\"",
                "address_country": "\"US\"",
                "address_full": "\"777 Isle of Capri Circle, Pompano Beach, FL 33069\"",
                "address_postcode": "\"33069\"",
                "address_state": "\"FL\"",
                "latitude": 26.22135,
                "longitude": -80.15136,
                "name": "\"Isle Casino Pompano Park\"",
                "phone": "\"\"",
                "website": "\"https://www.islepompanopark.com/\""
            },
            {
                "__id": 30,
                "address_city": "\"Baltimore\"",
                "address_country": "\"US\"",
                "address_full": "\"1525 Russell Street\"",
                "address_postcode": "\"21230\"",
                "address_state": "\"MD\"",
                "latitude": 39.290385,
                "longitude": -76.612189,
                "name": "\"Horseshoe Baltimore\"",
                "phone": "\"(844) 777-SHOE\"",
                "website": "\"https://www.caesars.com/horseshoe-baltimore\""
            },
            {
                "__id": 40,
                "address_city": "\"Waterloo\"",
                "address_country": "\"US\"",
                "address_full": "\"777 Isle of Capri Blvd\"",
                "address_postcode": "\"50701\"",
                "address_state": "\"IA\"",
                "latitude": 42.44865,
                "longitude": -92.31044,
                "name": "\"Isle Casino Hotel Waterloo\"",
                "phone": "\"(877) 475-3946\"",
                "website": "\"https://islewaterloo.com/\""
            },
            {
                "__id": 36,
                "address_city": "\"Westlake\"",
                "address_country": "\"US\"",
                "address_full": "\"100 Westlake Ave\"",
                "address_postcode": "\"70669\"",
                "address_state": "\"LA\"",
                "latitude": 30.23542,
                "longitude": -93.25007,
                "name": "\"Horseshoe Lake Charles\"",
                "phone": "\"(337) 430-2300\"",
                "website": "\"https://www.islelakecharles.com/\""
            },
            {
                "__id": 42,
                "address_city": "\"Boonville\"",
                "address_country": "\"US\"",
                "address_full": "\"100 Isle of Capri Blvd\"",
                "address_postcode": "\"65233\"",
                "address_state": "\"MO\"",
                "latitude": 38.97738,
                "longitude": -92.75,
                "name": "\"Isle of Capri Casino Hotel Boonville\"",
                "phone": "\"(660) 882-1489\"",
                "website": "\"https://isleofcapriboonville.com/\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "costcutter_gb": {
        "__id": 194,
        "id": "costcutter_gb",
        "name": "Costcutter Great Britain",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'image', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'website']",
        "rowLength": 1435,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763606,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of costcutter_gb locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 480,
                "address_city": "\"Hampton\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"TW12 2JW\"",
                "address_state": "\"\"",
                "image": "\"https://a.mktgcdn.com/p/WgP1HqrBbp6ZN6CEhQ9eBVArXryRomz_GbViOR2MnFo/1000x1000.png\"",
                "latitude": 51.4222521441628,
                "longitude": -0.37538120124436913,
                "name": "\"Costcutter - Percy Road, Hampton\"",
                "opening_hours": "\"Mo 06:00-22:00; Tu-Su 07:00-21:00\"",
                "phone": "\"+442089417695\"",
                "shop": "\"convenience\"",
                "street_address": "\"132 Percy Road\"",
                "website": "\"https://store-locator.costcutter.co.uk/costcutter-abc2c9055dd2\""
            },
            {
                "__id": 1122,
                "address_city": "\"Hoyland\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"S74 0PY\"",
                "address_state": "\"\"",
                "image": "\"https://a.mktgcdn.com/p/WgP1HqrBbp6ZN6CEhQ9eBVArXryRomz_GbViOR2MnFo/1000x1000.png\"",
                "latitude": 53.499110401814,
                "longitude": -1.4689200846557693,
                "name": "\"Costcutter\"",
                "opening_hours": "\"Mo-Su 07:00-21:00\"",
                "phone": "\"+441226743331\"",
                "shop": "\"convenience\"",
                "street_address": "\"Sk Fuel Ltd\"",
                "website": "\"https://store-locator.costcutter.co.uk/costcutter-3a22b83314c6\""
            },
            {
                "__id": 938,
                "address_city": "\"Redditch\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"B98 7RY\"",
                "address_state": "\"\"",
                "image": "\"https://a.mktgcdn.com/p/WgP1HqrBbp6ZN6CEhQ9eBVArXryRomz_GbViOR2MnFo/1000x1000.png\"",
                "latitude": 52.28778561208246,
                "longitude": -1.9155202874904864,
                "name": "\"Costcutter\"",
                "opening_hours": "\"Mo-Su 06:00-22:00\"",
                "phone": "\"+441527526300\"",
                "shop": "\"convenience\"",
                "street_address": "\"Unit 31\"",
                "website": "\"https://store-locator.costcutter.co.uk/costcutter-59e847cad514\""
            },
            {
                "__id": 1284,
                "address_city": "\"Aldington\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"TN25 7DA\"",
                "address_state": "\"\"",
                "image": "\"https://a.mktgcdn.com/p/WgP1HqrBbp6ZN6CEhQ9eBVArXryRomz_GbViOR2MnFo/1000x1000.png\"",
                "latitude": 51.0944912,
                "longitude": 0.9397936000000001,
                "name": "\"Costcutter\"",
                "opening_hours": "\"Mo-Su 06:00-23:00\"",
                "phone": "\"+441233720246\"",
                "shop": "\"convenience\"",
                "street_address": "\"Roman Road\"",
                "website": "\"https://store-locator.costcutter.co.uk/costcutter-1ecd9134d233\""
            },
            {
                "__id": 747,
                "address_city": "\"Oxford\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"OX3 7RP\"",
                "address_state": "\"\"",
                "image": "\"https://a.mktgcdn.com/p/WgP1HqrBbp6ZN6CEhQ9eBVArXryRomz_GbViOR2MnFo/1000x1000.png\"",
                "latitude": 51.760308439267305,
                "longitude": -1.2154799534635785,
                "name": "\"Costcutter\"",
                "opening_hours": "\"Mo 06:00-22:00; Tu-Su 07:00-21:00\"",
                "phone": "\"\"",
                "shop": "\"convenience\"",
                "street_address": "\"Nuffield Health Manor Hospital\"",
                "website": "\"https://store-locator.costcutter.co.uk/costcutter-7d2fa047e36b\""
            },
            {
                "__id": 818,
                "address_city": "\"Sandy\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"SG19 1BN\"",
                "address_state": "\"\"",
                "image": "\"https://a.mktgcdn.com/p/WgP1HqrBbp6ZN6CEhQ9eBVArXryRomz_GbViOR2MnFo/1000x1000.png\"",
                "latitude": 52.13573035368292,
                "longitude": -0.2949484168719252,
                "name": "\"Costcutter\"",
                "opening_hours": "\"Mo-Su 07:00-22:00\"",
                "phone": "\"+441767683246\"",
                "shop": "\"convenience\"",
                "street_address": "\"5/7 Engayne Avenue\"",
                "website": "\"https://store-locator.costcutter.co.uk/costcutter-721b117247f7\""
            },
            {
                "__id": 617,
                "address_city": "\"Brighouse\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"HD6 4DY\"",
                "address_state": "\"\"",
                "image": "\"https://a.mktgcdn.com/p/WgP1HqrBbp6ZN6CEhQ9eBVArXryRomz_GbViOR2MnFo/1000x1000.png\"",
                "latitude": 53.72329539595373,
                "longitude": -1.7763451221854942,
                "name": "\"Costcutter\"",
                "opening_hours": "\"Mo-Su 08:00-21:30\"",
                "phone": "\"+441484712981\"",
                "shop": "\"convenience\"",
                "street_address": "\"647/649 Bradford Road\"",
                "website": "\"https://store-locator.costcutter.co.uk/costcutter-95621dc89831\""
            },
            {
                "__id": 764,
                "address_city": "\"Newcastle\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"NE3 2PE\"",
                "address_state": "\"\"",
                "image": "\"https://a.mktgcdn.com/p/WgP1HqrBbp6ZN6CEhQ9eBVArXryRomz_GbViOR2MnFo/1000x1000.png\"",
                "latitude": 55.01393362082768,
                "longitude": -1.6461409533729654,
                "name": "\"Costcutter\"",
                "opening_hours": "\"Mo 07:30-22:00; Tu-Su 07:00-21:00\"",
                "phone": "\"+441912850273\"",
                "shop": "\"convenience\"",
                "street_address": "\"29 Fawdon Park Road\"",
                "website": "\"https://store-locator.costcutter.co.uk/costcutter-79ff0d76be3a\""
            },
            {
                "__id": 1256,
                "address_city": "\"Armadale\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"IV45 8RS\"",
                "address_state": "\"\"",
                "image": "\"https://a.mktgcdn.com/p/WgP1HqrBbp6ZN6CEhQ9eBVArXryRomz_GbViOR2MnFo/1000x1000.png\"",
                "latitude": 57.0622547,
                "longitude": -5.9032282,
                "name": "\"Costcutter\"",
                "opening_hours": "\"Mo-Su 07:30-20:30\"",
                "phone": "\"+441471844249\"",
                "shop": "\"convenience\"",
                "street_address": "\"Armadales Stores\"",
                "website": "\"https://store-locator.costcutter.co.uk/costcutter-2356431a0b21\""
            },
            {
                "__id": 1272,
                "address_city": "\"Dungannon\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"BT70 1RY\"",
                "address_state": "\"\"",
                "image": "\"https://a.mktgcdn.com/p/WgP1HqrBbp6ZN6CEhQ9eBVArXryRomz_GbViOR2MnFo/1000x1000.png\"",
                "latitude": 54.48705879456171,
                "longitude": -6.876501857228097,
                "name": "\"Costcutter\"",
                "opening_hours": "\"Mo-Su 05:00-22:00\"",
                "phone": "\"+442887761219\"",
                "shop": "\"convenience\"",
                "street_address": "\"185 Ballygawley Road\"",
                "website": "\"https://store-locator.costcutter.co.uk/costcutter-1fb3a374dd28\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "dreams_gb": {
        "__id": 195,
        "id": "dreams_gb",
        "name": "Dreams Great Britain",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'image', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'website']",
        "rowLength": 218,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763607,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of dreams_gb locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 95,
                "address_city": "\"York\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"YO30 4WZ\"",
                "address_state": "\"North Yorkshire\"",
                "image": "\"https://img.dreams.co.uk/i/dreams/Dreams_Store_Staples_York-6807b\"",
                "latitude": 53.993,
                "longitude": -1.099,
                "name": "\"York - Clifton Moor Centre Bed Store\"",
                "opening_hours": "\"Mo-Tu 10:00-20:00; We 10:00-18:00; Th 10:00-20:00; Fr 10:00-18:00; Sa 09:00-18:00; Su 10:30-16:30\"",
                "phone": "\"01904 479017\"",
                "shop": "\"bed\"",
                "street_address": "\"Clifton Moor Centre, Stirling Road\"",
                "website": "\"https://dreams.co.uk/store/york-clifton-moor-centre\""
            },
            {
                "__id": 78,
                "address_city": "\"\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"TN39 3JD\"",
                "address_state": "\"Bexhill on Sea\"",
                "image": "\"https://img.dreams.co.uk/i/dreams/Bexhill-MG_0551-RT\"",
                "latitude": 50.838,
                "longitude": 0.47,
                "name": "\"Bexhill on Sea Bed Store\"",
                "opening_hours": "\"Mo-Fr 10:00-18:00; Sa 09:00-18:00; Su 10:30-16:30\"",
                "phone": "\"01424 211431\"",
                "shop": "\"bed\"",
                "street_address": "\"The Landmark, 66-68 Sackville Road\"",
                "website": "\"https://dreams.co.uk/store/bexhill-on-sea\""
            },
            {
                "__id": 32,
                "address_city": "\"\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"FK7 7SY\"",
                "address_state": "\"Stirling\"",
                "image": "\"https://img.dreams.co.uk/i/dreams/Stirling1\"",
                "latitude": 56.113,
                "longitude": -3.916,
                "name": "\"Stirling\"",
                "opening_hours": "\"Mo-Fr 10:00-18:00; Sa 09:00-18:00; Su 11:00-17:00\"",
                "phone": "\"01786 641982\"",
                "shop": "\"bed\"",
                "street_address": "\"Unit 3C, 19 Kerse Road,\"",
                "website": "\"https://dreams.co.uk/store/stirling\""
            },
            {
                "__id": 119,
                "address_city": "\"\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"SO15 1QJ\"",
                "address_state": "\"Southampton\"",
                "image": "\"https://img.dreams.co.uk/i/dreams/Southampton-MG_0244-RT\"",
                "latitude": 50.906,
                "longitude": -1.413,
                "name": "\"Southampton Bed Store\"",
                "opening_hours": "\"Mo-Fr 10:00-19:00; Sa 09:00-18:00; Su 11:00-17:00\"",
                "phone": "\"023 8033 8871\"",
                "shop": "\"bed\"",
                "street_address": "\"Mountbatten Retail Park, Western Esplanade\"",
                "website": "\"https://dreams.co.uk/store/southampton\""
            },
            {
                "__id": 55,
                "address_city": "\"\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"HA9 6AZ\"",
                "address_state": "\"\"",
                "image": "\"https://img.dreams.co.uk/i/dreams/Wembley_0M7A1621\"",
                "latitude": 51.554,
                "longitude": -0.289,
                "name": "\"Wembley Bed Store\"",
                "opening_hours": "\"Mo-Fr 10:00-19:00; Sa 09:00-18:00; Su 11:00-17:00\"",
                "phone": "\"02089039746\"",
                "shop": "\"bed\"",
                "street_address": "\"336-342 High Road, Wembley\"",
                "website": "\"https://dreams.co.uk/store/wembley\""
            },
            {
                "__id": 106,
                "address_city": "\"Royal Tunbridge Wells\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"TN2 3UY\"",
                "address_state": "\"Kent\"",
                "image": "\"https://img.dreams.co.uk/i/dreams/Tunbridge Wells-MG_0824-RT\"",
                "latitude": 51.157,
                "longitude": 0.287,
                "name": "\"Tunbridge Wells Bed Store\"",
                "opening_hours": "\"Mo-Tu 10:00-20:00; We-Fr 10:00-19:00; Sa 09:00-18:00; Su 10:00-16:00\"",
                "phone": "\"01892 546031\"",
                "shop": "\"bed\"",
                "street_address": "\"Unit 1, The Fountains Retail Park, Dowding Way\"",
                "website": "\"https://dreams.co.uk/store/tunbridge-wells\""
            },
            {
                "__id": 103,
                "address_city": "\"Waterlooville\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"PO7 7UL\"",
                "address_state": "\"Hampshire\"",
                "image": "\"https://img.dreams.co.uk/i/dreams/Waterlooville 1\"",
                "latitude": 50.883,
                "longitude": -1.036,
                "name": "\"Waterlooville Bed Store\"",
                "opening_hours": "\"Mo-Fr 10:00-18:00; Sa 09:00-18:00; Su 10:30-16:30\"",
                "phone": "\"023 9223 3365\"",
                "shop": "\"bed\"",
                "street_address": "\"Gallagher Retail Park, Hambledon Road\"",
                "website": "\"https://dreams.co.uk/store/waterlooville\""
            },
            {
                "__id": 168,
                "address_city": "\"\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"HP1 1BT\"",
                "address_state": "\"Herts\"",
                "image": "\"https://img.dreams.co.uk/i/dreams/2208 Dreams_093\"",
                "latitude": 51.747,
                "longitude": -0.473,
                "name": "\"Hemel Hempstead Bed Store\"",
                "opening_hours": "\"Mo-Fr 10:00-18:00; Sa 09:00-18:00; Su 10:30-16:00\"",
                "phone": "\"01442 251886\"",
                "shop": "\"bed\"",
                "street_address": "\"Unit C3, Riverside Shopping Centre, Hemel Hempstead\"",
                "website": "\"https://dreams.co.uk/store/hemel-hempstead\""
            },
            {
                "__id": 174,
                "address_city": "\"Bristol Road\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"GL1 5SF\"",
                "address_state": "\"Gloucester\"",
                "image": "\"https://img.dreams.co.uk/i/dreams/Glocester-MG-2595-RT-small (2)\"",
                "latitude": 51.858,
                "longitude": -2.254,
                "name": "\"Gloucester Bed Store\"",
                "opening_hours": "\"Mo-Tu 10:00-19:00; We-Fr 10:00-18:00; Sa 09:00-18:00; Su 10:30-16:30\"",
                "phone": "\"01452 305619\"",
                "shop": "\"bed\"",
                "street_address": "\"The Peel Centre, St Ann Way\"",
                "website": "\"https://dreams.co.uk/store/gloucester\""
            },
            {
                "__id": 21,
                "address_city": "\"Lockerbie Road\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"DG1 3PF\"",
                "address_state": "\"Dumfries\"",
                "image": "\"https://img.dreams.co.uk/i/dreams/B408-Dumfries-01\"",
                "latitude": 55.074,
                "longitude": -3.584,
                "name": "\"Dumfries Bed Store\"",
                "opening_hours": "\"Mo-Tu 10:00-20:00; We 10:00-18:00; Th 10:00-20:00; Fr 10:00-18:00; Sa 09:00-18:00; Su 11:00-17:00\"",
                "phone": "\"01387 247786\"",
                "shop": "\"bed\"",
                "street_address": "\"Unit 2, The Peel Centre\"",
                "website": "\"https://dreams.co.uk/store/dumfries-new\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "cvs": {
        "__id": 196,
        "id": "cvs",
        "name": "CVS",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'name', 'opening_hours', 'phone', 'website']",
        "rowLength": 9623,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763609,
        "runTime": 2,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of cvs locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 8659,
                "address_city": "\"HARPER WOODS\"",
                "address_country": "\"US\"",
                "address_full": "\"at 19818 Kelly Road Harper Woods\"",
                "address_postcode": "\"Menu\"",
                "address_state": "\"MI\"",
                "name": "\"CVS Pharmacy\u00ae & Drug Store at  at 19818 Kelly Road Harper Woods HARPER WOODS, MI Menu\"",
                "opening_hours": "\"\"",
                "phone": "\"3135267900\"",
                "website": "\"https://www.cvs.com/store-locator/harper-woods-mi-pharmacies/19818-kelly-road-harper-woods-mi-48225/storeid=8052\""
            },
            {
                "__id": 8415,
                "address_city": "\"DORCHESTER\"",
                "address_country": "\"US\"",
                "address_full": "\"at 468 Blue Hill Ave Dorchester\"",
                "address_postcode": "\"Menu\"",
                "address_state": "\"MA\"",
                "name": "\"CVS Pharmacy\u00ae & Drug Store at  at 468 Blue Hill Ave Dorchester DORCHESTER, MA Menu\"",
                "opening_hours": "\"\"",
                "phone": "\"6174277806\"",
                "website": "\"https://www.cvs.com/store-locator/dorchester-ma-pharmacies/468-blue-hill-ave-dorchester-ma-02121/storeid=2592\""
            },
            {
                "__id": 2001,
                "address_city": "\"SAINT PETERSBURG\"",
                "address_country": "\"US\"",
                "address_full": "\"at 845 4th St. North Saint Petersburg\"",
                "address_postcode": "\"Menu\"",
                "address_state": "\"FL\"",
                "name": "\"CVS Pharmacy\u00ae & Drug Store at  at 845 4th St. North Saint Petersburg SAINT PETERSBURG, FL Menu\"",
                "opening_hours": "\"Mo-Sa 00:00-24:00\"",
                "phone": "\"7278211172\"",
                "website": "\"https://www.cvs.com/store-locator/saint-petersburg-fl-pharmacies/845-4th-st-north-saint-petersburg-fl-33701/storeid=2759\""
            },
            {
                "__id": 4505,
                "address_city": "\"PHILADELPHIA\"",
                "address_country": "\"US\"",
                "address_full": "\"at 2320-26 Fairmount Avenue Philadelphia\"",
                "address_postcode": "\"Menu\"",
                "address_state": "\"PA\"",
                "name": "\"CVS Pharmacy\u00ae & Drug Store at  at 2320-26 Fairmount Avenue Philadelphia PHILADELPHIA, PA Menu\"",
                "opening_hours": "\"\"",
                "phone": "\"2152325262\"",
                "website": "\"https://www.cvs.com/store-locator/philadelphia-pa-pharmacies/2320-26-fairmount-avenue-philadelphia-pa-19130/storeid=2159\""
            },
            {
                "__id": 7749,
                "address_city": "\"CHARLOTTE\"",
                "address_country": "\"US\"",
                "address_full": "\"at 5100 Beatties Ford Rd. Charlotte\"",
                "address_postcode": "\"Menu\"",
                "address_state": "\"NC\"",
                "name": "\"CVS Pharmacy\u00ae & Drug Store at  at 5100 Beatties Ford Rd. Charlotte CHARLOTTE, NC Menu\"",
                "opening_hours": "\"\"",
                "phone": "\"7043919341\"",
                "website": "\"https://www.cvs.com/store-locator/charlotte-nc-pharmacies/5100-beatties-ford-rd-charlotte-nc-28216/storeid=5445\""
            },
            {
                "__id": 1018,
                "address_city": "\"INDIO\"",
                "address_country": "\"US\"",
                "address_full": "\"at 49980 Jefferson St Indio\"",
                "address_postcode": "\"Menu\"",
                "address_state": "\"CA\"",
                "name": "\"CVS Pharmacy\u00ae & Drug Store at  at 49980 Jefferson St Indio INDIO, CA Menu\"",
                "opening_hours": "\"\"",
                "phone": "\"7607718848\"",
                "website": "\"https://www.cvs.com/store-locator/indio-ca-pharmacies/49980-jefferson-st-indio-ca-92201/storeid=9134\""
            },
            {
                "__id": 3381,
                "address_city": "\"CHICAGO\"",
                "address_country": "\"US\"",
                "address_full": "\"at 2427 W Chicago Ave Chicago\"",
                "address_postcode": "\"Menu\"",
                "address_state": "\"IL\"",
                "name": "\"CVS Pharmacy\u00ae & Drug Store at  at 2427 W Chicago Ave Chicago CHICAGO, IL Menu\"",
                "opening_hours": "\"Mo-Sa 00:00-24:00\"",
                "phone": "\"7733426060\"",
                "website": "\"https://www.cvs.com/store-locator/chicago-il-pharmacies/2427-w-chicago-ave-chicago-il-60622/storeid=8740\""
            },
            {
                "__id": 9616,
                "address_city": "\"BATON ROUGE\"",
                "address_country": "\"US\"",
                "address_full": "\"at 2001 Millerville Rd Baton Rouge\"",
                "address_postcode": "\"Menu\"",
                "address_state": "\"LA\"",
                "name": "\"CVS Pharmacy\u00ae & Drug Store at  at 2001 Millerville Rd Baton Rouge BATON ROUGE, LA Menu\"",
                "opening_hours": "\"\"",
                "phone": "\"2252752109\"",
                "website": "\"https://www.cvs.com/store-locator/baton-rouge-la-pharmacies/2001-millerville-rd-baton-rouge-la-70816/storeid=17177\""
            },
            {
                "__id": 7645,
                "address_city": "\"HENDERSONVILLE\"",
                "address_country": "\"US\"",
                "address_full": "\"at 522 S. Main St. Hendersonville\"",
                "address_postcode": "\"Menu\"",
                "address_state": "\"NC\"",
                "name": "\"CVS Pharmacy\u00ae & Drug Store at  at 522 S. Main St. Hendersonville HENDERSONVILLE, NC Menu\"",
                "opening_hours": "\"\"",
                "phone": "\"8286972231\"",
                "website": "\"https://www.cvs.com/store-locator/hendersonville-nc-pharmacies/522-s-main-st-hendersonville-nc-28792/storeid=5537\""
            },
            {
                "__id": 1577,
                "address_city": "\"FORT SMITH\"",
                "address_country": "\"US\"",
                "address_full": "\"at 4001 Phoenix Ave Fort Smith\"",
                "address_postcode": "\"Menu\"",
                "address_state": "\"AR\"",
                "name": "\"CVS Pharmacy\u00ae & Drug Store at  at 4001 Phoenix Ave Fort Smith FORT SMITH, AR Menu\"",
                "opening_hours": "\"\"",
                "phone": "\"4797098941\"",
                "website": "\"https://www.cvs.com/store-locator/fort-smith-ar-pharmacies/4001-phoenix-ave-fort-smith-ar-72903/storeid=17357\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "greatclips": {
        "__id": 197,
        "id": "greatclips",
        "name": "Great Clips",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'image', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'website']",
        "rowLength": 4434,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763610,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of greatclips locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 2458,
                "address_city": "\"Beverly Hills\"",
                "address_country": "\"US\"",
                "address_postcode": "\"48025\"",
                "address_state": "\"MI\"",
                "image": "\"https://dynl.mktgcdn.com/p/pLXhMkveoKX0Oa2in3Vh8ZeeK3rOMnPpfYV1HrLh4Cw/3144x3623.jpg\"",
                "latitude": 42.51789625449285,
                "longitude": -83.22100907564163,
                "name": "\"The Corners\"",
                "opening_hours": "\"Mo-Sa 09:00-19:00; Su 10:00-18:00\"",
                "phone": "\"(248) 593-9020\"",
                "shop": "\"hairdresser\"",
                "street_address": "\"17650 W 13 Mile Rd\"",
                "website": "\"https://salons.greatclips.com/us/mi/beverly-hills/17650-w-13-mile-rd\""
            },
            {
                "__id": 2714,
                "address_city": "\"Terre Haute\"",
                "address_country": "\"US\"",
                "address_postcode": "\"47802\"",
                "address_state": "\"IN\"",
                "image": "\"https://dynl.mktgcdn.com/p/pLXhMkveoKX0Oa2in3Vh8ZeeK3rOMnPpfYV1HrLh4Cw/3144x3623.jpg\"",
                "latitude": 39.39729487644739,
                "longitude": -87.40115940570831,
                "name": "\"Honey Creek Commons\"",
                "opening_hours": "\"Mo-Fr 09:00-20:00; Sa 09:00-18:00; Su 09:00-17:00\"",
                "phone": "\"(812) 298-9400\"",
                "shop": "\"hairdresser\"",
                "street_address": "\"5783 S US Hwy 41\"",
                "website": "\"https://salons.greatclips.com/us/in/terre-haute/5783-s-us-hwy-41\""
            },
            {
                "__id": 1073,
                "address_city": "\"Shippensburg\"",
                "address_country": "\"US\"",
                "address_postcode": "\"17257\"",
                "address_state": "\"PA\"",
                "image": "\"https://dynl.mktgcdn.com/p/pLXhMkveoKX0Oa2in3Vh8ZeeK3rOMnPpfYV1HrLh4Cw/3144x3623.jpg\"",
                "latitude": 40.0578829,
                "longitude": -77.5001158,
                "name": "\"Shippensburg Shopping Center\"",
                "opening_hours": "\"Mo-Fr 09:00-20:00; Sa 09:00-18:00; Su 10:00-17:00\"",
                "phone": "\"(717) 477-2973\"",
                "shop": "\"hairdresser\"",
                "street_address": "\"40 Shippensburg SC Ste 16\"",
                "website": "\"https://salons.greatclips.com/us/pa/shippensburg/40-shippensburg-sc\""
            },
            {
                "__id": 4118,
                "address_city": "\"Mesa\"",
                "address_country": "\"US\"",
                "address_postcode": "\"85201\"",
                "address_state": "\"AZ\"",
                "image": "\"https://dynl.mktgcdn.com/p/pLXhMkveoKX0Oa2in3Vh8ZeeK3rOMnPpfYV1HrLh4Cw/3144x3623.jpg\"",
                "latitude": 33.4329158,
                "longitude": -111.8687694,
                "name": "\"Mesa Riverview\"",
                "opening_hours": "\"Mo-Fr 08:00-20:00; Sa 08:00-18:00; Su 10:00-18:00\"",
                "phone": "\"(480) 834-2953\"",
                "shop": "\"hairdresser\"",
                "street_address": "\"849 N Dobson Rd Ste 102\"",
                "website": "\"https://salons.greatclips.com/us/az/mesa/849-n-dobson-rd\""
            },
            {
                "__id": 3673,
                "address_city": "\"Parker\"",
                "address_country": "\"US\"",
                "address_postcode": "\"80134\"",
                "address_state": "\"CO\"",
                "image": "\"https://dynl.mktgcdn.com/p/pLXhMkveoKX0Oa2in3Vh8ZeeK3rOMnPpfYV1HrLh4Cw/3144x3623.jpg\"",
                "latitude": 39.516804444150736,
                "longitude": -104.76321637630463,
                "name": "\"Parker and Main\"",
                "opening_hours": "\"Mo-Fr 10:00-18:00; Sa 09:00-17:00; Su 10:00-17:00\"",
                "phone": "\"(303) 841-4505\"",
                "shop": "\"hairdresser\"",
                "street_address": "\"10970 S Parker Rd Ste A15\"",
                "website": "\"https://salons.greatclips.com/us/co/parker/10970-s-parker-rd\""
            },
            {
                "__id": 1022,
                "address_city": "\"Clemson\"",
                "address_country": "\"US\"",
                "address_postcode": "\"29631\"",
                "address_state": "\"SC\"",
                "image": "\"https://dynl.mktgcdn.com/p/pLXhMkveoKX0Oa2in3Vh8ZeeK3rOMnPpfYV1HrLh4Cw/3144x3623.jpg\"",
                "latitude": 34.69022226704076,
                "longitude": -82.81092077493662,
                "name": "\"Clemson Bi Lo Center\"",
                "opening_hours": "\"Mo-Fr 08:00-20:00; Sa 09:00-18:00; Su 13:00-18:00\"",
                "phone": "\"(864) 654-1407\"",
                "shop": "\"hairdresser\"",
                "street_address": "\"501 Old Greenville Hwy\"",
                "website": "\"https://salons.greatclips.com/us/sc/clemson/501-old-greenville-hwy\""
            },
            {
                "__id": 2179,
                "address_city": "\"St Louis Park\"",
                "address_country": "\"US\"",
                "address_postcode": "\"55426\"",
                "address_state": "\"MN\"",
                "image": "\"https://dynl.mktgcdn.com/p/pLXhMkveoKX0Oa2in3Vh8ZeeK3rOMnPpfYV1HrLh4Cw/3144x3623.jpg\"",
                "latitude": 44.9368843892547,
                "longitude": -93.38335990905762,
                "name": "\"Knollwood\"",
                "opening_hours": "\"Mo-Fr 10:00-18:00; Sa 09:00-17:00; Su 09:00-15:00\"",
                "phone": "\"(952) 933-7525\"",
                "shop": "\"hairdresser\"",
                "street_address": "\"8136 Hwy 7\"",
                "website": "\"https://salons.greatclips.com/us/mn/st-louis-park/8136-hwy-7\""
            },
            {
                "__id": 4129,
                "address_city": "\"Mesa\"",
                "address_country": "\"US\"",
                "address_postcode": "\"85212\"",
                "address_state": "\"AZ\"",
                "image": "\"https://dynl.mktgcdn.com/p/pLXhMkveoKX0Oa2in3Vh8ZeeK3rOMnPpfYV1HrLh4Cw/3144x3623.jpg\"",
                "latitude": 33.336502,
                "longitude": -111.6026077,
                "name": "\"The Point\"",
                "opening_hours": "\"Mo-Fr 08:00-20:00; Sa 09:00-18:00; Su 10:00-17:00\"",
                "phone": "\"(480) 590-4351\"",
                "shop": "\"hairdresser\"",
                "street_address": "\"10706 E. Point Twenty-Two Blvd Ste 103\"",
                "website": "\"https://salons.greatclips.com/us/az/mesa/10706-e.-point-twenty-two-blvd\""
            },
            {
                "__id": 3758,
                "address_city": "\"Boulder\"",
                "address_country": "\"US\"",
                "address_postcode": "\"80302\"",
                "address_state": "\"CO\"",
                "image": "\"https://dynl.mktgcdn.com/p/pLXhMkveoKX0Oa2in3Vh8ZeeK3rOMnPpfYV1HrLh4Cw/3144x3623.jpg\"",
                "latitude": 40.01499229951039,
                "longitude": -105.25924265384674,
                "name": "\"Boulder\"",
                "opening_hours": "\"Mo-Fr 09:00-19:00; Sa 08:00-18:00; Su 09:00-18:00\"",
                "phone": "\"(303) 442-4854\"",
                "shop": "\"hairdresser\"",
                "street_address": "\"2711 Arapahoe Ave\"",
                "website": "\"https://salons.greatclips.com/us/co/boulder/2711-arapahoe-ave\""
            },
            {
                "__id": 4175,
                "address_city": "\"Casa Grande\"",
                "address_country": "\"US\"",
                "address_postcode": "\"85122\"",
                "address_state": "\"AZ\"",
                "image": "\"https://dynl.mktgcdn.com/p/pLXhMkveoKX0Oa2in3Vh8ZeeK3rOMnPpfYV1HrLh4Cw/3144x3623.jpg\"",
                "latitude": 32.89492719098369,
                "longitude": -111.74146345817462,
                "name": "\"Cottonwood Ranch\"",
                "opening_hours": "\"Mo-Fr 08:00-19:00; Sa 08:00-18:00; Su 09:00-18:00\"",
                "phone": "\"(520) 421-2126\"",
                "shop": "\"hairdresser\"",
                "street_address": "\"992 E Cottonwood Ln Ste 105\"",
                "website": "\"https://salons.greatclips.com/us/az/casa-grande/992-e-cottonwood-ln\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "staples": {
        "__id": 198,
        "id": "staples",
        "name": "Staples",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'shop', 'website']",
        "rowLength": 1019,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763612,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of staples locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 37,
                "address_city": "\"Van Nuys\"",
                "address_country": "\"US\"",
                "address_full": "\"6104 Sepulveda Blvd.\"",
                "address_postcode": "\"91411\"",
                "address_state": "\"CA\"",
                "latitude": 34.181064,
                "longitude": -118.464992,
                "name": "\"Staples Van Nuys, CA\"",
                "opening_hours": "\"Mo-Fr 08:00-09:00; Sa 09:00-09:00; Su 10:00-06:00\"",
                "shop": "\"stationery\"",
                "website": "\"https://stores.staples.com/ca/van-nuys/6104-sepulveda-blvd\""
            },
            {
                "__id": 434,
                "address_city": "\"Dedham\"",
                "address_country": "\"US\"",
                "address_full": "\"450 Providence Hwy\"",
                "address_postcode": "\"02026\"",
                "address_state": "\"MA\"",
                "latitude": 42.2464387,
                "longitude": -71.1714311,
                "name": "\"Staples Dedham, MA\"",
                "opening_hours": "\"Mo-Fr 08:00-09:00; Sa 09:00-08:00; Su 10:00-06:00\"",
                "shop": "\"stationery\"",
                "website": "\"https://stores.staples.com/ma/dedham/450-providence-hwy\""
            },
            {
                "__id": 246,
                "address_city": "\"Naples\"",
                "address_country": "\"US\"",
                "address_full": "\"12632 East Tamiami Trail Ste 12\"",
                "address_postcode": "\"34113\"",
                "address_state": "\"FL\"",
                "latitude": 26.0637732,
                "longitude": -81.7032744,
                "name": "\"Staples Naples, FL\"",
                "opening_hours": "\"Mo-Fr 09:00-08:00; Sa 10:00-07:00; Su 11:00-06:00\"",
                "shop": "\"stationery\"",
                "website": "\"https://stores.staples.com/fl/naples/12632-east-tamiami-trail\""
            },
            {
                "__id": 502,
                "address_city": "\"Pottsville\"",
                "address_country": "\"US\"",
                "address_full": "\"6104 Cressona Mall\"",
                "address_postcode": "\"17901\"",
                "address_state": "\"PA\"",
                "latitude": 40.64112129999999,
                "longitude": -76.18570969999999,
                "name": "\"Staples Pottsville, PA\"",
                "opening_hours": "\"Mo-Fr 08:00-09:00; Sa 09:00-08:00; Su 10:00-06:00\"",
                "shop": "\"stationery\"",
                "website": "\"https://stores.staples.com/pa/pottsville/6104-cressona-mall\""
            },
            {
                "__id": 199,
                "address_city": "\"Norwalk\"",
                "address_country": "\"US\"",
                "address_full": "\"420-440 Westport Avenue\"",
                "address_postcode": "\"06851\"",
                "address_state": "\"CT\"",
                "latitude": 41.12599515656553,
                "longitude": -73.38884674315581,
                "name": "\"Staples Norwalk, CT\"",
                "opening_hours": "\"Mo-Fr 08:00-08:00; Sa 09:00-08:00; Su 10:00-07:00\"",
                "shop": "\"stationery\"",
                "website": "\"https://stores.staples.com/ct/norwalk/420-440-westport-avenue\""
            },
            {
                "__id": 920,
                "address_city": "\"Astoria\"",
                "address_country": "\"US\"",
                "address_full": "\"24-41 31st Street\"",
                "address_postcode": "\"11102\"",
                "address_state": "\"NY\"",
                "latitude": 40.7713241,
                "longitude": -73.9161682,
                "name": "\"Staples Astoria, NY\"",
                "opening_hours": "\"Mo-Fr 08:00-09:00; Sa 09:00-09:00; Su 10:00-06:00\"",
                "shop": "\"stationery\"",
                "website": "\"https://stores.staples.com/ny/astoria/24-41-31st-street\""
            },
            {
                "__id": 469,
                "address_city": "\"Sumter\"",
                "address_country": "\"US\"",
                "address_full": "\"1121 Broad St Suite B\"",
                "address_postcode": "\"29150\"",
                "address_state": "\"SC\"",
                "latitude": 33.953896,
                "longitude": -80.374173,
                "name": "\"Staples Sumter, SC\"",
                "opening_hours": "\"Mo-Fr 08:00-09:00; Sa 09:00-09:00; Su 11:00-06:00\"",
                "shop": "\"stationery\"",
                "website": "\"https://stores.staples.com/sc/sumter/1121-broad-st\""
            },
            {
                "__id": 74,
                "address_city": "\"Palm Desert\"",
                "address_country": "\"US\"",
                "address_full": "\"72-811 Highway 111 Building 3\"",
                "address_postcode": "\"92260\"",
                "address_state": "\"CA\"",
                "latitude": 33.7204929,
                "longitude": -116.3937624,
                "name": "\"Staples Palm Desert, CA\"",
                "opening_hours": "\"Mo-Fr 08:00-09:00; Sa 09:00-09:00; Su 10:00-06:00\"",
                "shop": "\"stationery\"",
                "website": "\"https://stores.staples.com/ca/palm-desert/72-811-highway-111\""
            },
            {
                "__id": 855,
                "address_city": "\"Albuquerque\"",
                "address_country": "\"US\"",
                "address_full": "\"10242 Coors Bypass Blvd. N.W.\"",
                "address_postcode": "\"87114\"",
                "address_state": "\"NM\"",
                "latitude": 35.1942022,
                "longitude": -106.6570779,
                "name": "\"Staples Albuquerque, NM\"",
                "opening_hours": "\"Mo-Fr 08:00-09:00; Sa 09:00-09:00; Su 10:00-06:00\"",
                "shop": "\"stationery\"",
                "website": "\"https://stores.staples.com/nm/albuquerque/10242-coors-bypass-blvd-nw\""
            },
            {
                "__id": 425,
                "address_city": "\"Hadley\"",
                "address_country": "\"US\"",
                "address_full": "\"125 Westgate Center Drive\"",
                "address_postcode": "\"01035\"",
                "address_state": "\"MA\"",
                "latitude": 42.360899,
                "longitude": -72.543061,
                "name": "\"Staples Hadley, MA\"",
                "opening_hours": "\"Mo-Fr 08:00-08:00; Sa 09:00-07:00; Su 10:00-06:00\"",
                "shop": "\"stationery\"",
                "website": "\"https://stores.staples.com/ma/hadley/125-westgate-center-drive\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "thebigbiscuit": {
        "__id": 199,
        "id": "thebigbiscuit",
        "name": "The Big Biscuit",
        "columnNames": "['address_city', 'address_postcode', 'address_state', 'address_street', 'name', 'phone', 'website']",
        "rowLength": 22,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763613,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of thebigbiscuit locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 7,
                "address_city": "\"Lawrence\"",
                "address_postcode": "\"66049\"",
                "address_state": "\"Kansas KS\"",
                "address_street": "\"4821 West 6th St\"",
                "name": "\"Lawrence, Kansas\"",
                "phone": "\"(785) 856-0496\"",
                "website": "\"https://bigbiscuit.com/location/lawrence/\""
            },
            {
                "__id": 1,
                "address_city": "\"Blue Springs\"",
                "address_postcode": "\"64014\"",
                "address_state": "\"Missouri MO\"",
                "address_street": "\"530 North West Highway 7\"",
                "name": "\"Blue Springs, Missouri\"",
                "phone": "\"(816) 229-3108\"",
                "website": "\"https://bigbiscuit.com/location/bluesprings/\""
            },
            {
                "__id": 15,
                "address_city": "\"Raymore\"",
                "address_postcode": "\"64083\"",
                "address_state": "\"Missouri MO\"",
                "address_street": "\"311 N Dean Ave\"",
                "name": "\"Raymore, Missouri\"",
                "phone": "\"(816) 318-9480\"",
                "website": "\"https://bigbiscuit.com/location/raymore/\""
            },
            {
                "__id": 17,
                "address_city": "\"Springfield\"",
                "address_postcode": "\"65804\"",
                "address_state": "\"Missouri MO\"",
                "address_street": "\"2920 S Glenstone Avenue\"",
                "name": "\"Springfield, Missouri (Battlefield & Glenstone)\"",
                "phone": "\"(417) 360-1298\"",
                "website": "\"https://bigbiscuit.com/location/brentwood-springfield/\""
            },
            {
                "__id": 22,
                "address_city": "\"Kansas City\"",
                "address_postcode": "\"64111\"",
                "address_state": "\"Missouri MO\"",
                "address_street": "\"4039 Mill Street\"",
                "name": "\"Westport, Kansas City, MO\"",
                "phone": "\"(816) 886-9300\"",
                "website": "\"https://bigbiscuit.com/location/westport/\""
            },
            {
                "__id": 18,
                "address_city": "\"Springfield\"",
                "address_postcode": "\"65807\"",
                "address_state": "\"Missouri MO\"",
                "address_street": "\"3620 S Campbell\"",
                "name": "\"Springfield, MO (S Campbell & W Powell)\"",
                "phone": "\"(417) 360-1294\"",
                "website": "\"https://bigbiscuit.com/location/kickapoo-springfield/\""
            },
            {
                "__id": 10,
                "address_city": "\"Liberty\"",
                "address_postcode": "\"64068\"",
                "address_state": "\"Missouri MO\"",
                "address_street": "\"840 South Missouri 291\"",
                "name": "\"Liberty, Missouri\"",
                "phone": "\"(816) 429-5314\"",
                "website": "\"https://bigbiscuit.com/location/liberty/\""
            },
            {
                "__id": 12,
                "address_city": "\"Overland Park\"",
                "address_postcode": "\"66223\"",
                "address_state": "\"Kansas KS\"",
                "address_street": "\"8520 W 135th Street\"",
                "name": "\"Overland Park, Kansas (135th & Antioch)\"",
                "phone": "\"(913) 544-1707\"",
                "website": "\"https://bigbiscuit.com/location/overlandpark-135/\""
            },
            {
                "__id": 20,
                "address_city": "\"Topeka\"",
                "address_postcode": "\"66614\"",
                "address_state": "\"Kansas KS\"",
                "address_street": "\"2815 SW Wanamaker Rd\"",
                "name": "\"Topeka, Kansas\"",
                "phone": "\"(785) 215-8130\"",
                "website": "\"https://bigbiscuit.com/location/topeka/\""
            },
            {
                "__id": 5,
                "address_city": "\"Independence\"",
                "address_postcode": "\"64055\"",
                "address_state": "\"Missouri MO\"",
                "address_street": "\"16506 East US Highway 40\"",
                "name": "\"Independence, Missouri\"",
                "phone": "\"(816) 478-6958\"",
                "website": "\"https://bigbiscuit.com/location/independence/\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "new_look_gb": {
        "__id": 200,
        "id": "new_look_gb",
        "name": "New Look Great Britain",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'image', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'website']",
        "rowLength": 422,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763614,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of new_look_gb locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 123,
                "address_city": "\"Romford\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"RM1 3RL\"",
                "address_state": "\"\"",
                "image": "\"https://media2.newlookassets.com/i/newlook/2019_RP16_STORE_LOCATORSTORE_LOCATOR?w=1500\"",
                "latitude": 51.5783567,
                "longitude": 0.1830199,
                "name": "\"Romford The Liberty\"",
                "opening_hours": "\"Mo-Sa 09:00-18:00; Su 11:00-17:00\"",
                "phone": "\"01708 436 720\"",
                "shop": "\"clothes\"",
                "street_address": "\"GL11 Liberty Shopping Centre\"",
                "website": "\"https://www.newlook.com/uk/store/Romford-The-Liberty-Romford-GB-2143\""
            },
            {
                "__id": 66,
                "address_city": "\"Thanet\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"CT10 2QN\"",
                "address_state": "\"\"",
                "image": "\"https://media2.newlookassets.com/i/newlook/2019_RP16_STORE_LOCATORSTORE_LOCATOR?w=1500\"",
                "latitude": 51.3591698,
                "longitude": 1.3932032,
                "name": "\"Thanet\"",
                "opening_hours": "\"Mo-Fr 09:30-19:30; Sa 09:00-18:30; Su 10:00-16:00\"",
                "phone": "\"01843 608870\"",
                "shop": "\"clothes\"",
                "street_address": "\"Unit T4 Westwood X Shopping Centre\"",
                "website": "\"https://www.newlook.com/uk/store/Thanet-Thanet-GB-1779\""
            },
            {
                "__id": 192,
                "address_city": "\"St Albans, Hertfordshire\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"AL2 1AB\"",
                "address_state": "\"\"",
                "image": "\"https://media2.newlookassets.com/i/newlook/2019_RP16_STORE_LOCATORSTORE_LOCATOR?w=1500\"",
                "latitude": 51.7161032,
                "longitude": -0.2823186,
                "name": "\"London Colney\"",
                "opening_hours": "\"Mo-Fr 09:00-20:00; Sa 09:00-19:00; Su 11:00-17:00\"",
                "phone": "\"01727 820 750\"",
                "shop": "\"clothes\"",
                "street_address": "\"1 Colney Fields Shopping Park\"",
                "website": "\"https://www.newlook.com/uk/store/London-Colney-St-Albans-Hertfordshire-GB-2081\""
            },
            {
                "__id": 259,
                "address_city": "\"Grimsby\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"DN32 9DL\"",
                "address_state": "\"\"",
                "image": "\"https://media2.newlookassets.com/i/newlook/2019_RP16_STORE_LOCATORSTORE_LOCATOR?w=1500\"",
                "latitude": 53.56818,
                "longitude": -0.07644,
                "name": "\"Grimsby - find us in Asda\"",
                "opening_hours": "\"Mo 07:00-20:00; Tu 07:00-24:00; We-Fr 00:00-24:00; Sa 00:00-22:00; Su 10:00-16:00\"",
                "phone": "\"01472 361381\"",
                "shop": "\"clothes\"",
                "street_address": "\"Holles Street\"",
                "website": "\"https://www.newlook.com/uk/store/Grimsby-find-us-in-Asda-Grimsby--3007\""
            },
            {
                "__id": 45,
                "address_city": "\"Welwyn Garden City\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"AL8 6HA\"",
                "address_state": "\"\"",
                "image": "\"https://media2.newlookassets.com/i/newlook/2019_RP16_STORE_LOCATORSTORE_LOCATOR?w=1500\"",
                "latitude": 51.80202,
                "longitude": -0.20441,
                "name": "\"Welwyn Garden City\"",
                "opening_hours": "\"Mo-Sa 09:00-17:30; Su 11:00-17:00\"",
                "phone": "\"01707 376324\"",
                "shop": "\"clothes\"",
                "street_address": "\"Unit 11 The Howard Centre\"",
                "website": "\"https://www.newlook.com/uk/store/Welwyn-Garden-City-Welwyn-Garden-City-GB-1389\""
            },
            {
                "__id": 308,
                "address_city": "\"Doncaster\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"DN1 1LF\"",
                "address_state": "\"\"",
                "image": "\"https://media2.newlookassets.com/i/newlook/2019_RP16_STORE_LOCATORSTORE_LOCATOR?w=1500\"",
                "latitude": 53.523719,
                "longitude": -1.1390223,
                "name": "\"Doncaster\"",
                "opening_hours": "\"Mo-Sa 09:00-17:30; Su 10:00-16:00\"",
                "phone": "\"01302 309890\"",
                "shop": "\"clothes\"",
                "street_address": "\"Unit 22A/B Frenchgate Centre\"",
                "website": "\"https://www.newlook.com/uk/store/Doncaster-Doncaster-GB-2125\""
            },
            {
                "__id": 394,
                "address_city": "\"Blackburn\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"BB1 7JG\"",
                "address_state": "\"\"",
                "image": "\"https://media2.newlookassets.com/i/newlook/2019_RP16_STORE_LOCATORSTORE_LOCATOR?w=1500\"",
                "latitude": 53.7491263,
                "longitude": -2.4816442,
                "name": "\"Blackburn\"",
                "opening_hours": "\"Mo-Sa 09:00-17:30; Su 10:30-16:30\"",
                "phone": "\"01254 266 440\"",
                "shop": "\"clothes\"",
                "street_address": "\"Unit 41 (Cobden Court) The Mall\"",
                "website": "\"https://www.newlook.com/uk/store/Blackburn-Blackburn-GB-1963\""
            },
            {
                "__id": 220,
                "address_city": "\"Grays\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"RM20 2ZQ\"",
                "address_state": "\"\"",
                "image": "\"https://media2.newlookassets.com/i/newlook/2019_RP16_STORE_LOCATORSTORE_LOCATOR?w=1500\"",
                "latitude": 51.489185,
                "longitude": 0.282847,
                "name": "\"Lakeside\"",
                "opening_hours": "\"Mo-Fr 10:00-21:00; Sa 09:30-21:00; Su 11:00-17:00\"",
                "phone": "\"017086 80009\"",
                "shop": "\"clothes\"",
                "street_address": "\"114 And 252/253 Lakeside Shopping Centre Thurrock\"",
                "website": "\"https://www.newlook.com/uk/store/Lakeside-Grays-GB-1600\""
            },
            {
                "__id": 293,
                "address_city": "\"Eastbourne\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"BN21 3NW\"",
                "address_state": "\"['East Sussex']\"",
                "image": "\"https://media2.newlookassets.com/i/newlook/2019_RP16_STORE_LOCATORSTORE_LOCATOR?w=1500\"",
                "latitude": 50.768905,
                "longitude": 0.2863946,
                "name": "\"Eastbourne - Beacon Centre\"",
                "opening_hours": "\"Mo-Sa 09:30-17:30; Su 10:00-16:00\"",
                "phone": "\"01323 736356\"",
                "shop": "\"clothes\"",
                "street_address": "\"Unit 73\"",
                "website": "\"https://www.newlook.com/uk/store/Eastbourne-Beacon-Centre-Eastbourne-GB-2164\""
            },
            {
                "__id": 6,
                "address_city": "\"Ayr\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"KA7 1TP\"",
                "address_state": "\"\"",
                "image": "\"https://media2.newlookassets.com/i/newlook/2019_RP16_STORE_LOCATORSTORE_LOCATOR?w=1500\"",
                "latitude": 55.4599524,
                "longitude": -4.6262091,
                "name": "\"Ayr Central\"",
                "opening_hours": "\"Mo-Sa 09:00-18:00; Su 11:00-17:00\"",
                "phone": "\"01292 291940\"",
                "shop": "\"clothes\"",
                "street_address": "\"Unit 13 Skye Square Ayr Central\"",
                "website": "\"https://www.newlook.com/uk/store/Ayr-Central-Ayr-GB-2155\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "noahsnybagels": {
        "__id": 201,
        "id": "noahsnybagels",
        "name": "Noah's NY Bagels",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'official_name', 'opening_hours', 'phone', 'takeaway', 'website']",
        "rowLength": 55,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763615,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of noahsnybagels locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 25,
                "address_city": "\"Stockton\"",
                "address_country": "\"US\"",
                "address_full": "\"123 Lincoln Ctr Ste 400\"",
                "address_postcode": "\"95207\"",
                "address_state": "\"California\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"bagel\"",
                "latitude": 38.011186265754304,
                "longitude": -121.32225600859829,
                "name": "\"Noah's NY Bagels Stockton\"",
                "official_name": "\"Noah's New York Bagels\"",
                "opening_hours": "\"Mo-Fr 06:00-02:00; Sa-Su 07:00-02:00\"",
                "phone": "\"(209) 951-2284\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.noahs.com/us/ca/stockton/123-lincoln-ctr\""
            },
            {
                "__id": 33,
                "address_city": "\"Rolling Hills Estates\"",
                "address_country": "\"US\"",
                "address_full": "\"895 Silver Spur Rd Ste C\"",
                "address_postcode": "\"90274\"",
                "address_state": "\"California\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"bagel\"",
                "latitude": 33.76933510542941,
                "longitude": -118.3668427169323,
                "name": "\"Noah's NY Bagels Palos Verdes\"",
                "official_name": "\"Noah's New York Bagels\"",
                "opening_hours": "\"Mo-Fr 05:00-02:00; Sa-Su 06:00-02:00\"",
                "phone": "\"(310) 541-7824\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.noahs.com/us/ca/rolling-hills-estates/895-silver-spur-rd\""
            },
            {
                "__id": 29,
                "address_city": "\"El Segundo\"",
                "address_country": "\"US\"",
                "address_full": "\"2231 Rosecrans Ave Ste C\"",
                "address_postcode": "\"90245\"",
                "address_state": "\"California\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"bagel\"",
                "latitude": 33.90236089273689,
                "longitude": -118.38346436619759,
                "name": "\"Noah's NY Bagels El Segundo\"",
                "official_name": "\"Noah's New York Bagels\"",
                "opening_hours": "\"Mo-Fr 05:00-02:00; Sa-Su 06:00-02:00\"",
                "phone": "\"(310) 725-9160\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.noahs.com/us/ca/el-segundo/2231-rosecrans-ave\""
            },
            {
                "__id": 1,
                "address_city": "\"Petaluma\"",
                "address_country": "\"US\"",
                "address_full": "\"375 S. McDowell Blvd.\"",
                "address_postcode": "\"94954\"",
                "address_state": "\"California\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"bagel\"",
                "latitude": 38.24964191603374,
                "longitude": -122.62258037924767,
                "name": "\"Noah's NY Bagels Petaluma\"",
                "official_name": "\"Noah's New York Bagels\"",
                "opening_hours": "\"Mo-Fr 05:00-02:00; Sa-Su 06:00-02:00\"",
                "phone": "\"(707) 766-9651\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.noahs.com/us/ca/petaluma/375-s-mcdowell-blvd\""
            },
            {
                "__id": 31,
                "address_city": "\"Walnut Creek\"",
                "address_country": "\"US\"",
                "address_full": "\"730 Bancroft Rd Unit B\"",
                "address_postcode": "\"94598\"",
                "address_state": "\"California\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"bagel\"",
                "latitude": 37.92918215790386,
                "longitude": -122.03995361924171,
                "name": "\"Noah's NY Bagels Walnut Creek\"",
                "official_name": "\"Noah's New York Bagels\"",
                "opening_hours": "\"Mo-Fr 05:00-02:00; Sa-Su 05:30-02:00\"",
                "phone": "\"(925) 256-8284\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.noahs.com/us/ca/walnut-creek/730-bancroft-rd\""
            },
            {
                "__id": 8,
                "address_city": "\"Lafayette\"",
                "address_country": "\"US\"",
                "address_full": "\"3518 Mt Diablo Blvd Unit B\"",
                "address_postcode": "\"94549\"",
                "address_state": "\"California\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"bagel\"",
                "latitude": 37.89317346208929,
                "longitude": -122.11816281080246,
                "name": "\"Noah's NY Bagels Lafayette CA\"",
                "official_name": "\"Noah's New York Bagels\"",
                "opening_hours": "\"Mo-Fr 05:00-02:00; Sa 05:30-02:00; Su 06:00-02:00\"",
                "phone": "\"(925) 299-0716\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.noahs.com/us/ca/lafayette/3518-mt-diablo-blvd\""
            },
            {
                "__id": 9,
                "address_city": "\"Greenbrae\"",
                "address_country": "\"US\"",
                "address_full": "\"170 Bon Air Ctr Ste 102\"",
                "address_postcode": "\"94904\"",
                "address_state": "\"California\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"bagel\"",
                "latitude": 37.94622490812506,
                "longitude": -122.52535432577133,
                "name": "\"Noah's NY Bagels Bon Air\"",
                "official_name": "\"Noah's New York Bagels\"",
                "opening_hours": "\"Mo-Su 05:30-02:00\"",
                "phone": "\"(415) 925-9971\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.noahs.com/us/ca/greenbrae/170-bon-air-ctr\""
            },
            {
                "__id": 24,
                "address_city": "\"Thousand Oaks\"",
                "address_country": "\"US\"",
                "address_full": "\"33 N Moorpark Rd Ste I\"",
                "address_postcode": "\"91360\"",
                "address_state": "\"California\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"bagel\"",
                "latitude": 34.17989107821387,
                "longitude": -118.87721747159958,
                "name": "\"Noah's NY Bagels Thousand Oaks\"",
                "official_name": "\"Noah's New York Bagels\"",
                "opening_hours": "\"Mo-Su 05:30-02:00\"",
                "phone": "\"(805) 381-9893\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.noahs.com/us/ca/thousand-oaks/33-n-moorpark-rd\""
            },
            {
                "__id": 22,
                "address_city": "\"Los Angeles\"",
                "address_country": "\"US\"",
                "address_full": "\"250 N Larchmont Blvd Ste B\"",
                "address_postcode": "\"90004\"",
                "address_state": "\"California\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"bagel\"",
                "latitude": 34.076030062093785,
                "longitude": -118.32341104745865,
                "name": "\"Noah's NY Bagels Larchmont\"",
                "official_name": "\"Noah's New York Bagels\"",
                "opening_hours": "\"Mo-Su 05:00-02:00\"",
                "phone": "\"(323) 466-2924\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.noahs.com/us/ca/los-angeles/250-n-larchmont-blvd\""
            },
            {
                "__id": 48,
                "address_city": "\"Sacramento\"",
                "address_country": "\"US\"",
                "address_full": "\"2918 Fulton Ave Ste B\"",
                "address_postcode": "\"95821\"",
                "address_state": "\"California\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"bagel\"",
                "latitude": 38.61893051575151,
                "longitude": -121.40083685517311,
                "name": "\"Noah's NY Bagels Town & Country\"",
                "official_name": "\"Noah's New York Bagels\"",
                "opening_hours": "\"Mo-Fr 06:00-02:00; Sa-Su 07:00-02:00\"",
                "phone": "\"(916) 973-1365\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.noahs.com/us/ca/sacramento/2918-fulton-ave\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "coop": {
        "__id": 202,
        "id": "coop",
        "name": "Coop",
        "columnNames": "['address_country', 'address_full', 'latitude', 'longitude', 'name', 'phone']",
        "rowLength": 703,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763616,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of coop locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 15,
                "address_country": "\"NO\"",
                "address_full": "\"VALHALLVEIEN 1, 3518 H\u00d8NEFOSS\"",
                "latitude": 60.1782727,
                "longitude": 10.203834,
                "name": "\"Heradsbygda\"",
                "phone": "\"32110220\""
            },
            {
                "__id": 664,
                "address_country": "\"NO\"",
                "address_full": "\"ALTAH\u00d8YDEN 1, 9513 ALTA\"",
                "latitude": 69.9520482,
                "longitude": 23.239728,
                "name": "\"Alta\"",
                "phone": "\"97577448\""
            },
            {
                "__id": 26,
                "address_country": "\"NO\"",
                "address_full": "\"HOLMEJORDETVEIEN 48, 3267 LARVIK\"",
                "latitude": 59.0171926,
                "longitude": 10.0188341,
                "name": "\"Holmejordet\"",
                "phone": "\"94142400\""
            },
            {
                "__id": 462,
                "address_country": "\"NO\"",
                "address_full": "\"FAKSDAL, 7856 J\u00d8A\"",
                "latitude": 64.6582194,
                "longitude": 11.2661825,
                "name": "\"J\u00f8a\"",
                "phone": "\"74286836\""
            },
            {
                "__id": 226,
                "address_country": "\"NO\"",
                "address_full": "\"BIBLIOTEKSGATA 30, 1473 L\u00d8RENSKOG\"",
                "latitude": 59.9275934,
                "longitude": 10.9583564,
                "name": "\"Metro\"",
                "phone": "\"67980890\""
            },
            {
                "__id": 274,
                "address_country": "\"NO\"",
                "address_full": "\"SULFATVEIEN 2, 1658 TORP\"",
                "latitude": 59.2423,
                "longitude": 11.0177,
                "name": "\"Torp\"",
                "phone": "\"69345611\""
            },
            {
                "__id": 526,
                "address_country": "\"NO\"",
                "address_full": "\"\u00d8RNES, 8150 \u00d8RNES\"",
                "latitude": 66.8578,
                "longitude": 13.7229,
                "name": "\"\u00d8rnes\"",
                "phone": "\"75752795\""
            },
            {
                "__id": 25,
                "address_country": "\"NO\"",
                "address_full": "\"NANSETGT. 11, 3256 LARVIK\"",
                "latitude": 59.05447,
                "longitude": 10.02953,
                "name": "\"Larvik\"",
                "phone": "\"99494500\""
            },
            {
                "__id": 422,
                "address_country": "\"NO\"",
                "address_full": "\"ROANSVEIEN 9, 7180 ROAN\"",
                "latitude": 64.17265,
                "longitude": 10.2304385,
                "name": "\"Roan\"",
                "phone": "\"97575684\""
            },
            {
                "__id": 371,
                "address_country": "\"NO\"",
                "address_full": "\"\u00d8STRE ROSTEN 28, 7075 TILLER\"",
                "latitude": 63.3619,
                "longitude": 10.3789,
                "name": "\"City Syd\"",
                "phone": "\"40001202\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "spar_gb": {
        "__id": 203,
        "id": "spar_gb",
        "name": "Spar Great Britain",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'latitude', 'longitude', 'name', 'street_address', 'website']",
        "rowLength": 2098,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763617,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of spar_gb locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 1398,
                "address_city": "\"Largs \"",
                "address_country": "\"GB\"",
                "address_postcode": "\"KA30 8HS\"",
                "latitude": 55.7903462,
                "longitude": -4.86110800000006,
                "name": "\"SPAR Certas Energy UK Ltd Largs \"",
                "street_address": "\"Certas Energy UK Ltd \"",
                "website": "\"https://www.spar.co.uk/store-locator/lan19530-spar-certas-energy-uk-ltd-largs/\""
            },
            {
                "__id": 1413,
                "address_city": "\"Freuchie\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"KY15 7HY\"",
                "latitude": 56.2512579,
                "longitude": -3.1484896,
                "name": "\"SPAR Abbiecraig Services Ltd \"",
                "street_address": "\"Bridgend Filling Station \"",
                "website": "\"https://www.spar.co.uk/store-locator/lan17055-spar-abbiecraig-services-ltd/\""
            },
            {
                "__id": 878,
                "address_city": "\"Doncaster\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"DN6 0EP\"",
                "latitude": 53.6214981,
                "longitude": -1.1531717,
                "name": "\"SPAR Askern\"",
                "street_address": "\"Selby Road\"",
                "website": "\"https://www.spar.co.uk/store-locator/hal41688-spar-askern/\""
            },
            {
                "__id": 537,
                "address_city": "\"Llanharry\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"CF72 9LH\"",
                "latitude": 51.5135839543834,
                "longitude": -3.4334634769513,
                "name": "\"SPAR Llanharry\"",
                "street_address": "\"SPAR\"",
                "website": "\"https://www.spar.co.uk/store-locator/afb15948-spar-llanharry/\""
            },
            {
                "__id": 1621,
                "address_city": "\"Manchester\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"M1 5QA\"",
                "latitude": 53.4728926632523,
                "longitude": -2.23995507405339,
                "name": "\"SPAR Oxford Road\"",
                "street_address": "\"2\"",
                "website": "\"https://www.spar.co.uk/store-locator/hal14372-spar-oxford-road/\""
            },
            {
                "__id": 132,
                "address_city": "\"Stourbridge\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"DY9 8EE\"",
                "latitude": 52.4596191,
                "longitude": -2.1169799,
                "name": "\"SPAR - Mill Service Station\"",
                "street_address": "\"Mill Service Station\"",
                "website": "\"https://www.spar.co.uk/store-locator/afb28647-spar-mill-service-station/\""
            },
            {
                "__id": 1445,
                "address_city": "\"Southport\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"PR8 6PU\"",
                "latitude": 53.6415117148891,
                "longitude": -2.99803525144159,
                "name": "\"SPAR  Scarisbrick\"",
                "street_address": "\"Scarisbrick New Road\"",
                "website": "\"https://www.spar.co.uk/store-locator/hal25626-spar-scarisbrick/\""
            },
            {
                "__id": 1503,
                "address_city": "\"Wrexham \"",
                "address_country": "\"GB\"",
                "address_postcode": "\"LL11 4EN\"",
                "latitude": 53.0756635796563,
                "longitude": -3.02746954670403,
                "name": "\"SPAR - Gwersyllt\"",
                "street_address": "\"95 First Avenue\"",
                "website": "\"https://www.spar.co.uk/store-locator/afb15589-spar-gwersyllt/\""
            },
            {
                "__id": 192,
                "address_city": "\"Brent Knoll\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"TA9 4HH\"",
                "latitude": 51.24398,
                "longitude": -2.9454,
                "name": "\"SPAR Brent Knoll Garage\"",
                "street_address": "\"Brent Knoll Garage\"",
                "website": "\"https://www.spar.co.uk/store-locator/awl22749-spar-brent-knoll-garage/\""
            },
            {
                "__id": 995,
                "address_city": "\"Dunfermline\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"KY12 8QU\"",
                "latitude": 56.0601002040698,
                "longitude": -3.53506405756583,
                "name": "\"SPAR Cairneyhill\"",
                "street_address": "\"82\"",
                "website": "\"https://www.spar.co.uk/store-locator/lan51906-spar-cairneyhill/\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "acme_markets": {
        "__id": 204,
        "id": "acme_markets",
        "name": "Acme Markets",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'phone', 'shop', 'website']",
        "rowLength": 161,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763618,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of acme_markets locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 67,
                "address_city": "\"Yorktown Heights\"",
                "address_country": "\"US\"",
                "address_full": "\"100 Triangle Center\"",
                "address_postcode": "\"10598\"",
                "address_state": "\"NY\"",
                "latitude": 41.27755909272709,
                "longitude": -73.77917620422973,
                "name": "\"Yorktown Heights\"",
                "phone": "\"(914) 455-3810\"",
                "shop": "\"supermarket\"",
                "website": "\"https://local.acmemarkets.com/ny/yorktown-heights/100-triangle-center.html\""
            },
            {
                "__id": 20,
                "address_city": "\"Upper Darby\"",
                "address_country": "\"US\"",
                "address_full": "\"815 Lansdowne Ave\"",
                "address_postcode": "\"19082\"",
                "address_state": "\"PA\"",
                "latitude": 39.96105049825003,
                "longitude": -75.28727758213957,
                "name": "\"Upper Darby\"",
                "phone": "\"(610) 789-0898\"",
                "shop": "\"supermarket\"",
                "website": "\"https://local.acmemarkets.com/pa/upper-darby/815-lansdowne-ave.html\""
            },
            {
                "__id": 58,
                "address_city": "\"Clifton Heights\"",
                "address_country": "\"US\"",
                "address_full": "\"5300 Baltimore Pike\"",
                "address_postcode": "\"19018\"",
                "address_state": "\"PA\"",
                "latitude": 39.92447801136082,
                "longitude": -75.30694484710693,
                "name": "\"Clifton Heights\"",
                "phone": "\"(610) 284-6800\"",
                "shop": "\"supermarket\"",
                "website": "\"https://local.acmemarkets.com/pa/clifton-heights/5300-baltimore-pike.html\""
            },
            {
                "__id": 74,
                "address_city": "\"Patterson\"",
                "address_country": "\"US\"",
                "address_full": "\"3101 Route 22\"",
                "address_postcode": "\"12563\"",
                "address_state": "\"NY\"",
                "latitude": 41.516964,
                "longitude": -73.5928317,
                "name": "\"Patterson\"",
                "phone": "\"(845) 878-2044\"",
                "shop": "\"supermarket\"",
                "website": "\"https://local.acmemarkets.com/ny/patterson/3101-route-22.html\""
            },
            {
                "__id": 83,
                "address_city": "\"Woodstown\"",
                "address_country": "\"US\"",
                "address_full": "\"857 Route 45\"",
                "address_postcode": "\"08098\"",
                "address_state": "\"NJ\"",
                "latitude": 39.64497182672322,
                "longitude": -75.34119532273508,
                "name": "\"Woodstown\"",
                "phone": "\"(856) 769-8000\"",
                "shop": "\"supermarket\"",
                "website": "\"https://local.acmemarkets.com/nj/woodstown/857-route-45.html\""
            },
            {
                "__id": 5,
                "address_city": "\"Blairstown\"",
                "address_country": "\"US\"",
                "address_full": "\"152 Route 94\"",
                "address_postcode": "\"07825\"",
                "address_state": "\"NJ\"",
                "latitude": 40.9837031,
                "longitude": -74.9969887,
                "name": "\"Blairstown\"",
                "phone": "\"(908) 362-1790\"",
                "shop": "\"supermarket\"",
                "website": "\"https://local.acmemarkets.com/nj/blairstown/152-route-94.html\""
            },
            {
                "__id": 65,
                "address_city": "\"Bala Cynwyd\"",
                "address_country": "\"US\"",
                "address_full": "\"121 E City Ave\"",
                "address_postcode": "\"19004\"",
                "address_state": "\"PA\"",
                "latitude": 40.0037118,
                "longitude": -75.223673,
                "name": "\"Bala Cynwyd\"",
                "phone": "\"(610) 667-7021\"",
                "shop": "\"supermarket\"",
                "website": "\"https://local.acmemarkets.com/pa/bala-cynwyd/121-e-city-ave.html\""
            },
            {
                "__id": 109,
                "address_city": "\"Old Bridge\"",
                "address_country": "\"US\"",
                "address_full": "\"3500 Route 9\"",
                "address_postcode": "\"08857\"",
                "address_state": "\"NJ\"",
                "latitude": 40.3827557,
                "longitude": -74.3095876,
                "name": "\"Old Bridge\"",
                "phone": "\"(732) 607-9275\"",
                "shop": "\"supermarket\"",
                "website": "\"https://local.acmemarkets.com/nj/old-bridge/3500-route-9.html\""
            },
            {
                "__id": 50,
                "address_city": "\"Havertown\"",
                "address_country": "\"US\"",
                "address_full": "\"1305 W Chester Pike\"",
                "address_postcode": "\"19083\"",
                "address_state": "\"PA\"",
                "latitude": 39.97407867835323,
                "longitude": -75.32050561534422,
                "name": "\"Havertown\"",
                "phone": "\"(610) 446-1191\"",
                "shop": "\"supermarket\"",
                "website": "\"https://local.acmemarkets.com/pa/havertown/1305-w-chester-pike.html\""
            },
            {
                "__id": 123,
                "address_city": "\"Maple Shade\"",
                "address_country": "\"US\"",
                "address_full": "\"41 W Main St\"",
                "address_postcode": "\"08052\"",
                "address_state": "\"NJ\"",
                "latitude": 39.95545767060727,
                "longitude": -75.0037873539673,
                "name": "\"Maple Shade\"",
                "phone": "\"(856) 779-9891\"",
                "shop": "\"supermarket\"",
                "website": "\"https://local.acmemarkets.com/nj/maple-shade/41-w-main-st.html\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "a1": {
        "__id": 205,
        "id": "a1",
        "name": "A1",
        "columnNames": "['address_country', 'address_full', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop']",
        "rowLength": 203,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763619,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of a1 locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 81,
                "address_country": "\"BG\"",
                "address_full": "\"\u0443\u043b. '\u0426\u0430\u0440 \u0411\u043e\u0440\u0438\u0441 III' 11\"",
                "latitude": 41.393477,
                "longitude": 23.208355,
                "name": "\"\u04101 \u043c\u0430\u0433\u0430\u0437\u0438\u043d \u041f\u0435\u0442\u0440\u0438\u0447, \u0411\u043e\u0440\u0438\u0441 III 11\"",
                "opening_hours": "\"Mo-Fr 09:00-18:30\"",
                "phone": "\"+359882207530\"",
                "shop": "\"telecommunication\""
            },
            {
                "__id": 96,
                "address_country": "\"BG\"",
                "address_full": "\"\u0423\u043b. \u041e\u043f\u044a\u043b\u0447\u0435\u043d\u0441\u043a\u0430 2\"",
                "latitude": 42.150317,
                "longitude": 24.746819,
                "name": "\"\u04101 \u043c\u0430\u0433\u0430\u0437\u0438\u043d \u041f\u043b\u043e\u0432\u0434\u0438\u0432, \u041e\u043f\u044a\u043b\u0447\u0435\u043d\u0441\u043a\u0430 2\"",
                "opening_hours": "\"Mo-Fr 09:00-19:00\"",
                "phone": "\"+359882207374\"",
                "shop": "\"telecommunication\""
            },
            {
                "__id": 11,
                "address_country": "\"BG\"",
                "address_full": "\"\u0443\u043b. \u0422\u043e\u0434\u043e\u0440 \u0410\u043b\u0435\u043a\u0441\u0430\u043d\u0434\u0440\u043e\u0432 3\"",
                "latitude": 42.020207,
                "longitude": 23.100365,
                "name": "\"\u04101 \u043c\u0430\u0433\u0430\u0437\u0438\u043d \u0411\u043b\u0430\u0433\u043e\u0435\u0432\u0433\u0440\u0430\u0434, T. \u0410\u043b\u0435\u043a\u0441\u0430\u043d\u0434\u0440\u043e\u0432, 3\"",
                "opening_hours": "\"Mo-Fr 09:00-19:30\"",
                "phone": "\"+359882207522\"",
                "shop": "\"telecommunication\""
            },
            {
                "__id": 18,
                "address_country": "\"BG\"",
                "address_full": "\"\u0436.\u043a. \u0421\u043b\u0430\u0432\u0435\u0439\u043a\u043e\u0432, \u0431\u0443\u043b.\u0422\u0440\u0430\u043a\u0438\u044f 125\"",
                "latitude": 42.519934,
                "longitude": 27.451553,
                "name": "\"\u04101 \u043c\u0430\u0433\u0430\u0437\u0438\u043d \u0411\u0443\u0440\u0433\u0430\u0441, \u0422\u0440\u0430\u043a\u0438\u044f 125\"",
                "opening_hours": "\"Mo-Fr 09:00-19:30\"",
                "phone": "\"+359882207421\"",
                "shop": "\"telecommunication\""
            },
            {
                "__id": 56,
                "address_country": "\"BG\"",
                "address_full": "\"\u0443\u043b. \u0410\u043b\u0435\u043a\u0441\u0430\u043d\u0434\u044a\u0440 \u0421\u0442\u0430\u043c\u0431\u043e\u043b\u0438\u0439\u0441\u043a\u0438 47\"",
                "latitude": 43.360413,
                "longitude": 25.142463,
                "name": "\"\u04101 \u043c\u0430\u0433\u0430\u0437\u0438\u043d \u041b\u0435\u0432\u0441\u043a\u0438, \u0421\u0442\u0430\u043c\u0431\u043e\u043b\u0438\u0439\u0441\u043a\u0438 47\"",
                "opening_hours": "\"Mo-Fr 09:00-18:00\"",
                "phone": "\"+359882207485\"",
                "shop": "\"telecommunication\""
            },
            {
                "__id": 5,
                "address_country": "\"BG\"",
                "address_full": "\"\u0443\u043b. \u0414\u0438\u043c\u0438\u0442\u044a\u0440 \u0411\u043b\u0430\u0433\u043e\u0435\u0432 79\"",
                "latitude": 43.470448,
                "longitude": 23.942806,
                "name": "\"\u04101 \u043c\u0430\u0433\u0430\u0437\u0438\u043d \u0411. \u0421\u043b\u0430\u0442\u0438\u043d\u0430, \u0414. \u0411\u043b\u0430\u0433\u043e\u0435\u0432 79 \"",
                "opening_hours": "\"Mo-Fr 09:00-18:00\"",
                "phone": "\"+359882207536\"",
                "shop": "\"telecommunication\""
            },
            {
                "__id": 25,
                "address_country": "\"BG\"",
                "address_full": "\"\u0431\u0443\u043b. \u0411\u044a\u043b\u0433\u0430\u0440\u0438\u044f 7\"",
                "latitude": 42.059955,
                "longitude": 25.592549,
                "name": "\"\u04101 \u043c\u0430\u0433\u0430\u0437\u0438\u043d \u0414\u0438\u043c\u0438\u0442\u0440\u043e\u0432\u0433\u0440\u0430\u0434, \u0411\u044a\u043b\u0433\u0430\u0440\u0438\u044f 7\"",
                "opening_hours": "\"Mo-Fr 09:00-18:00\"",
                "phone": "\"+359882207388\"",
                "shop": "\"telecommunication\""
            },
            {
                "__id": 154,
                "address_country": "\"BG\"",
                "address_full": "\"\u0436.\u043a. \u0414\u0440\u0443\u0436\u0431\u0430 2, \u0431\u0443\u043b. \u0426\u0432. \u041b\u0430\u0437\u0430\u0440\u043e\u0432, \u0441/\u0443 \u0431\u043b. 209\"",
                "latitude": 42.654617,
                "longitude": 23.400474,
                "name": "\"\u04101 \u043c\u0430\u0433\u0430\u0437\u0438\u043d \u0421\u043e\u0444\u0438\u044f, \u0426\u0432. \u041b\u0430\u0437\u0430\u0440\u043e\u0432\"",
                "opening_hours": "\"Mo-Fr 09:00-19:00\"",
                "phone": "\"+359882207336\"",
                "shop": "\"telecommunication\""
            },
            {
                "__id": 61,
                "address_country": "\"BG\"",
                "address_full": "\"\u0443\u043b. \u201e\u041b\u0435\u043d\u0438\u043d\u0441\u043a\u0438 \u043f\u0440\u043e\u0441\u043f\u0435\u043a\u0442\u201c 7\"",
                "latitude": 42.606874,
                "longitude": 23.102855,
                "name": "\"\u04101 \u043c\u0430\u0433\u0430\u0437\u0438\u043d \u041f\u0435\u0440\u043d\u0438\u043a, \u041b\u0435\u043d\u0438\u043d\u0441\u043a\u0438 \u043f\u0440-\u043a\u04427\"",
                "opening_hours": "\"Mo-Fr 09:00-19:00\"",
                "phone": "\"+359882207529\"",
                "shop": "\"telecommunication\""
            },
            {
                "__id": 138,
                "address_country": "\"BG\"",
                "address_full": "\"\u0431\u0443\u043b. \u0411\u044a\u043b\u0433\u0430\u0440\u0438\u044f 69\"",
                "latitude": 42.664088,
                "longitude": 23.288653,
                "name": "\"\u04101 \u043c\u0430\u0433\u0430\u0437\u0438\u043d \u0421\u043e\u0444\u0438\u044f, \u041c\u041e\u041b \u0411\u044a\u043b\u0433\u0430\u0440\u0438\u044f\"",
                "opening_hours": "\"Mo-Su 10:00-21:00\"",
                "phone": "\"+359882207340\"",
                "shop": "\"telecommunication\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "wegmans": {
        "__id": 206,
        "id": "wegmans",
        "name": "Wegmans",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'phone', 'shop', 'website']",
        "rowLength": 109,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763620,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of wegmans locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 90,
                "address_city": "\"Scranton\"",
                "address_country": "\"US\"",
                "address_full": "\"1315 Scranton Carbondale Hwy\"",
                "address_postcode": "\"18508\"",
                "address_state": "\"PA\"",
                "latitude": 41.4755,
                "longitude": -75.63299,
                "name": "\"Scranton\"",
                "phone": "\"570-383-8721\"",
                "shop": "\"supermarket\"",
                "website": "\"https://www.wegmans.com/stores/scranton-pa\""
            },
            {
                "__id": 27,
                "address_city": "\"Depew\"",
                "address_country": "\"US\"",
                "address_full": "\"651 Dick Road\"",
                "address_postcode": "\"14043\"",
                "address_state": "\"NY\"",
                "latitude": 42.92066,
                "longitude": -78.73723,
                "name": "\"Dick Rd.\"",
                "phone": "\"716-685-8100\"",
                "shop": "\"supermarket\"",
                "website": "\"https://www.wegmans.com/stores/dick-rd-ny\""
            },
            {
                "__id": 59,
                "address_city": "\"Leesburg\"",
                "address_country": "\"US\"",
                "address_full": "\"101 Crosstrail Blvd SE\"",
                "address_postcode": "\"20175\"",
                "address_state": "\"VA\"",
                "latitude": 39.08925,
                "longitude": -77.52173,
                "name": "\"Leesburg\"",
                "phone": "\"703-669-2044\"",
                "shop": "\"supermarket\"",
                "website": "\"https://www.wegmans.com/stores/leesburg-va\""
            },
            {
                "__id": 86,
                "address_city": "\"Princeton\"",
                "address_country": "\"US\"",
                "address_full": "\"240 Nassau Park Boulevard\"",
                "address_postcode": "\"08540\"",
                "address_state": "\"NJ\"",
                "latitude": 40.30464,
                "longitude": -74.67749,
                "name": "\"Princeton\"",
                "phone": "\"609-919-9300\"",
                "shop": "\"supermarket\"",
                "website": "\"https://www.wegmans.com/stores/princeton-nj\""
            },
            {
                "__id": 32,
                "address_city": "\"Elmira\"",
                "address_country": "\"US\"",
                "address_full": "\"1100 Clemens Center Pkwy\"",
                "address_postcode": "\"14901\"",
                "address_state": "\"NY\"",
                "latitude": 42.10536,
                "longitude": -76.80888,
                "name": "\"Elmira\"",
                "phone": "\"607-737-0770\"",
                "shop": "\"supermarket\"",
                "website": "\"https://www.wegmans.com/stores/elmira-ny\""
            },
            {
                "__id": 47,
                "address_city": "\"Hornell\"",
                "address_country": "\"US\"",
                "address_full": "\"1000 Highway 36 North\"",
                "address_postcode": "\"14843\"",
                "address_state": "\"NY\"",
                "latitude": 42.35052,
                "longitude": -77.6682,
                "name": "\"Hornell\"",
                "phone": "\"607-324-0600\"",
                "shop": "\"supermarket\"",
                "website": "\"https://www.wegmans.com/stores/hornell-ny\""
            },
            {
                "__id": 60,
                "address_city": "\"Depew\"",
                "address_country": "\"US\"",
                "address_full": "\"4960 Transit Road\"",
                "address_postcode": "\"14043\"",
                "address_state": "\"NY\"",
                "latitude": 42.88142,
                "longitude": -78.70021,
                "name": "\"Losson Rd.\"",
                "phone": "\"716-685-7300\"",
                "shop": "\"supermarket\"",
                "website": "\"https://www.wegmans.com/stores/losson-rd-ny\""
            },
            {
                "__id": 79,
                "address_city": "\"Syracuse\"",
                "address_country": "\"US\"",
                "address_full": "\"4722 Onondaga Boulevard\"",
                "address_postcode": "\"13219\"",
                "address_state": "\"NY\"",
                "latitude": 43.03174,
                "longitude": -76.19028,
                "name": "\"Onondaga\"",
                "phone": "\"315-478-3313\"",
                "shop": "\"supermarket\"",
                "website": "\"https://www.wegmans.com/stores/onondaga-ny\""
            },
            {
                "__id": 34,
                "address_city": "\"Fairfax\"",
                "address_country": "\"US\"",
                "address_full": "\"11620 Monument Drive\"",
                "address_postcode": "\"22030\"",
                "address_state": "\"VA\"",
                "latitude": 38.85153,
                "longitude": -77.34781,
                "name": "\"Fairfax\"",
                "phone": "\"703-653-1600\"",
                "shop": "\"supermarket\"",
                "website": "\"https://www.wegmans.com/stores/fairfax-va\""
            },
            {
                "__id": 55,
                "address_city": "\"King of Prussia\"",
                "address_country": "\"US\"",
                "address_full": "\"1 Village Drive\"",
                "address_postcode": "\"19406\"",
                "address_state": "\"PA\"",
                "latitude": 40.08353,
                "longitude": -75.40201,
                "name": "\"King Of Prussia\"",
                "phone": "\"484-690-3500\"",
                "shop": "\"supermarket\"",
                "website": "\"https://www.wegmans.com/stores/king-of-prussia-pa\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "greene_king_inns": {
        "__id": 207,
        "id": "greene_king_inns",
        "name": "Greene King Inns",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'contact_email', 'image', 'latitude', 'longitude', 'name', 'phone', 'street_address', 'website']",
        "rowLength": 117,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763621,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of greene_king_inns locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 48,
                "address_city": "\"Forest Row\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"RH18 5JL\"",
                "address_state": "\"East Sussex\"",
                "contact_email": "\"6499@greeneking.co.uk\"",
                "image": "\"https://www.greenekinginns.co.uk/media/2858/roebuck_external.jpg\"",
                "latitude": 51.068904,
                "longitude": 0.024999,
                "name": "\"The Roebuck Hotel\"",
                "phone": "\"01342 823811\"",
                "street_address": "\"Wych Cross\"",
                "website": "\"https://www.greenekinginns.co.uk/hotels/roebuck-hotel/\""
            },
            {
                "__id": 71,
                "address_city": "\"Wimborne\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"BH21 1JG\"",
                "address_state": "\"Dorset\"",
                "contact_email": "\"6474@greeneking.co.uk\"",
                "image": "\"https://www.greenekinginns.co.uk/media/1458/kings_head_external.jpg\"",
                "latitude": 50.800165,
                "longitude": -1.988379,
                "name": "\"Kings Head (Wimborne)\"",
                "phone": "\"01202 880101\"",
                "street_address": "\"The Square\"",
                "website": "\"https://www.greenekinginns.co.uk/hotels/kings-head-wimborne/\""
            },
            {
                "__id": 49,
                "address_city": "\"Wentworth\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"S62 7TL\"",
                "address_state": "\"South Yorkshire\"",
                "contact_email": "\"6940@greeneking.co.uk\"",
                "image": "\"https://www.greenekinginns.co.uk/media/2576/rockingham_arms_external.jpg\"",
                "latitude": 53.4780565,
                "longitude": -1.4182223,
                "name": "\"Rockingham Arms Hotel\"",
                "phone": "\"O1226742075\"",
                "street_address": "\"8 Main Street\"",
                "website": "\"https://www.greenekinginns.co.uk/hotels/rockingham-arms-hotel/\""
            },
            {
                "__id": 6,
                "address_city": "\"Marlborough\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"SN8 1LZ\"",
                "address_state": "\"Wiltshire\"",
                "contact_email": "\"6422@greeneking.co.uk\"",
                "image": "\"https://www.greenekinginns.co.uk/media/1256/castle_ball_external.jpg\"",
                "latitude": 51.420285,
                "longitude": -1.732024,
                "name": "\"Castle & Ball Hotel\"",
                "phone": "\"01672 515201\"",
                "street_address": "\"High Street\"",
                "website": "\"https://www.greenekinginns.co.uk/hotels/castle-ball-marlborough/\""
            },
            {
                "__id": 40,
                "address_city": "\"Towcester\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"NN12 6BX\"",
                "address_state": "\"Northamptonshire\"",
                "contact_email": "\"6509@greeneking.co.uk\"",
                "image": "\"https://www.greenekinginns.co.uk/media/1933/saracens_head_external.jpg\"",
                "latitude": 52.133374,
                "longitude": -0.990508,
                "name": "\"Saracens Head Hotel\"",
                "phone": "\"01327 350414\"",
                "street_address": "\"219 Watling Street\"",
                "website": "\"https://www.greenekinginns.co.uk/hotels/saracens-head-hotel/\""
            },
            {
                "__id": 66,
                "address_city": "\"Nuneaton\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"CV11 6JH\"",
                "address_state": "\"Warwickshire\"",
                "contact_email": "\"6351@greeneking.co.uk\"",
                "image": "\"https://www.greenekinginns.co.uk/media/2970/longshoot_external.jpg\"",
                "latitude": 52.536547,
                "longitude": -1.420197,
                "name": "\"The Longshoot\"",
                "phone": "\"02476 329711\"",
                "street_address": "\"Watling Street\"",
                "website": "\"https://www.greenekinginns.co.uk/hotels/longshoot/\""
            },
            {
                "__id": 30,
                "address_city": "\"Centre Park\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"WA1 1PR\"",
                "address_state": "\"Warrington\"",
                "contact_email": "\"4904@greeneking.co.uk\"",
                "image": "\"https://www.greenekinginns.co.uk/media/3549/waterside-exterior-full.jpg\"",
                "latitude": 53.38447,
                "longitude": -2.593864,
                "name": "\"The Waterside Hotel\"",
                "phone": "\"01925 242692\"",
                "street_address": "\"1430 Park Boulevard\"",
                "website": "\"https://www.greenekinginns.co.uk/hotels/waterside-hotel/\""
            },
            {
                "__id": 26,
                "address_city": "\"Cleethorpes\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"DN35 0AQ\"",
                "address_state": "\"North East Lincolnshire\"",
                "contact_email": "\"8021@greeneking.co.uk\"",
                "image": "\"https://www.greenekinginns.co.uk/media/3532/the-wellow-hotel-exterior.jpg\"",
                "latitude": 53.547831,
                "longitude": -0.015455,
                "name": "\"The Wellow Hotel\"",
                "phone": "\"01472 695589\"",
                "street_address": "\"Kings Road\"",
                "website": "\"https://www.greenekinginns.co.uk/hotels/the-wellow-hotel/\""
            },
            {
                "__id": 38,
                "address_city": "\"Southbourne\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"BH6 3PW\"",
                "address_state": "\"Dorset\"",
                "contact_email": "\"6944@greeneking.co.uk\"",
                "image": "\"https://www.greenekinginns.co.uk/media/2657/spyglass_kettle_beer_garden.jpg\"",
                "latitude": 50.722008,
                "longitude": -1.810792,
                "name": "\"Spyglass & Kettle\"",
                "phone": "\"O1202424888\"",
                "street_address": "\"25-27 Stourwood Avenue\"",
                "website": "\"https://www.greenekinginns.co.uk/hotels/spyglass-kettle/\""
            },
            {
                "__id": 102,
                "address_city": "\"Newcastle-Upon-Tyne\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"NE6 5RP\"",
                "address_state": "\"Tyne and Wear\"",
                "contact_email": "\"6782@greeneking.co.uk\"",
                "image": "\"https://www.greenekinginns.co.uk/media/2283/corner_house_external.jpg\"",
                "latitude": 54.990856,
                "longitude": -1.583928,
                "name": "\"Corner House Hotel\"",
                "phone": "\"O191 265 9602\"",
                "street_address": "\"Heaton Road\"",
                "website": "\"https://www.greenekinginns.co.uk/hotels/corner-house-hotel/\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "iceland_foods": {
        "__id": 208,
        "id": "iceland_foods",
        "name": "Iceland Foods",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'image', 'latitude', 'longitude', 'name', 'phone', 'shop', 'street_address', 'website']",
        "rowLength": 902,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763622,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of iceland_foods locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 646,
                "address_city": "\"Shrewsbgury\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"SY1 3AZ\"",
                "address_state": "\"Brixton Way\"",
                "image": "\"https://www.iceland.co.uk/on/demandware.static/Sites-icelandfoodsuk-Site/-/default/dw635ee01d/images/email/dual-logo.png\"",
                "latitude": 52.7372,
                "longitude": -2.72981,
                "name": "\"SHREWSBURY\"",
                "phone": "\"01743450043\"",
                "shop": "\"frozen_food\"",
                "street_address": "\"Lancaster Retail Park\"",
                "website": "\"https://www.iceland.co.uk/store-finder/store?StoreID=568&StoreName=SHREWSBURY\""
            },
            {
                "__id": 199,
                "address_city": "\"West Midlands\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"DY2 7BJ\"",
                "address_state": "\"Dudley\"",
                "image": "\"https://www.iceland.co.uk/on/demandware.static/Sites-icelandfoodsuk-Site/-/default/dw635ee01d/images/email/dual-logo.png\"",
                "latitude": 52.5099,
                "longitude": -2.08062,
                "name": "\"DUDLEY\"",
                "phone": "\"01384459934\"",
                "shop": "\"frozen_food\"",
                "street_address": "\"32 Churchill Shopping Cnt\"",
                "website": "\"https://www.iceland.co.uk/store-finder/store?StoreID=1295&StoreName=DUDLEY\""
            },
            {
                "__id": 313,
                "address_city": "\"Bristol\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"BS48 1RD\"",
                "address_state": "\"Nailsea\"",
                "image": "\"https://www.iceland.co.uk/on/demandware.static/Sites-icelandfoodsuk-Site/-/default/dw635ee01d/images/email/dual-logo.png\"",
                "latitude": 51.4323,
                "longitude": -2.75859,
                "name": "\"NAILSEA\"",
                "phone": "\"01275853959\"",
                "shop": "\"frozen_food\"",
                "street_address": "\"1/3 Crown Glass Place\"",
                "website": "\"https://www.iceland.co.uk/store-finder/store?StoreID=763&StoreName=NAILSEA\""
            },
            {
                "__id": 258,
                "address_city": "\"West Yorkshire\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"WF12 8EB\"",
                "address_state": "\"Dewsbury\"",
                "image": "\"https://www.iceland.co.uk/on/demandware.static/Sites-icelandfoodsuk-Site/-/default/dw635ee01d/images/email/dual-logo.png\"",
                "latitude": 53.6892,
                "longitude": -1.62396,
                "name": "\"DEWSBURY\"",
                "phone": "\"01924462962\"",
                "shop": "\"frozen_food\"",
                "street_address": "\"Unit C Railway Street\"",
                "website": "\"https://www.iceland.co.uk/store-finder/store?StoreID=1380&StoreName=DEWSBURY\""
            },
            {
                "__id": 120,
                "address_city": "\"Greater Manchester\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"WN7 4NP\"",
                "address_state": "\"Leigh\"",
                "image": "\"https://www.iceland.co.uk/on/demandware.static/Sites-icelandfoodsuk-Site/-/default/dw635ee01d/images/email/dual-logo.png\"",
                "latitude": 53.4959,
                "longitude": -2.51446,
                "name": "\"LEIGH\"",
                "phone": "\"01942262033\"",
                "shop": "\"frozen_food\"",
                "street_address": "\"116 Bradshaw Gate\"",
                "website": "\"https://www.iceland.co.uk/store-finder/store?StoreID=268&StoreName=LEIGH\""
            },
            {
                "__id": 257,
                "address_city": "\"Devon\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"TQ4 5DN\"",
                "address_state": "\"Paignton\"",
                "image": "\"https://www.iceland.co.uk/on/demandware.static/Sites-icelandfoodsuk-Site/-/default/dw635ee01d/images/email/dual-logo.png\"",
                "latitude": 50.4355,
                "longitude": -3.56651,
                "name": "\"PAIGNTON\"",
                "phone": "\"01803558777\"",
                "shop": "\"frozen_food\"",
                "street_address": "\"18/20 Victoria Street\"",
                "website": "\"https://www.iceland.co.uk/store-finder/store?StoreID=221&StoreName=PAIGNTON\""
            },
            {
                "__id": 159,
                "address_city": "\"Kent\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"BR6 0LN\"",
                "address_state": "\"Orpington\"",
                "image": "\"https://www.iceland.co.uk/on/demandware.static/Sites-icelandfoodsuk-Site/-/default/dw635ee01d/images/email/dual-logo.png\"",
                "latitude": 51.3775,
                "longitude": 0.101038,
                "name": "\"ORPINGTON\"",
                "phone": "\"01689832467\"",
                "shop": "\"frozen_food\"",
                "street_address": "\"157/159 High Street\"",
                "website": "\"https://www.iceland.co.uk/store-finder/store?StoreID=779&StoreName=ORPINGTON\""
            },
            {
                "__id": 689,
                "address_city": "\"Holyroad Drive\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"DN15 8NN\"",
                "address_state": "\"Skippingdale Retail Park\"",
                "image": "\"https://www.iceland.co.uk/on/demandware.static/Sites-icelandfoodsuk-Site/-/default/dw635ee01d/images/email/dual-logo.png\"",
                "latitude": 53.6087,
                "longitude": -0.67683,
                "name": "\"SCUNTHORPE 2\"",
                "phone": "\"01724862950\"",
                "shop": "\"frozen_food\"",
                "street_address": "\"Unit 1a\"",
                "website": "\"https://www.iceland.co.uk/store-finder/store?StoreID=1322&StoreName=SCUNTHORPE%202\""
            },
            {
                "__id": 117,
                "address_city": "\"Gwent\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"NP4 6LR\"",
                "address_state": "\"George Street\"",
                "image": "\"https://www.iceland.co.uk/on/demandware.static/Sites-icelandfoodsuk-Site/-/default/dw635ee01d/images/email/dual-logo.png\"",
                "latitude": 51.7034,
                "longitude": -3.04245,
                "name": "\"PONTYPOOL\"",
                "phone": "\"01495762753\"",
                "shop": "\"frozen_food\"",
                "street_address": "\"Crowns Buildings\"",
                "website": "\"https://www.iceland.co.uk/store-finder/store?StoreID=1239&StoreName=PONTYPOOL\""
            },
            {
                "__id": 259,
                "address_city": "\"Bedfordshire\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"LU6 1LE\"",
                "address_state": "\"Dunstable\"",
                "image": "\"https://www.iceland.co.uk/on/demandware.static/Sites-icelandfoodsuk-Site/-/default/dw635ee01d/images/email/dual-logo.png\"",
                "latitude": 51.8881,
                "longitude": -0.523245,
                "name": "\"DUNSTABLE\"",
                "phone": "\"01582606722\"",
                "shop": "\"frozen_food\"",
                "street_address": "\"66 High Street North\"",
                "website": "\"https://www.iceland.co.uk/store-finder/store?StoreID=676&StoreName=DUNSTABLE\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "quiktrip": {
        "__id": 209,
        "id": "quiktrip",
        "name": "QuikTrip",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity_fuel', 'atm', 'fuel_HGV_diesel', 'fuel_diesel', 'fuel_e15', 'hgv', 'latitude', 'longitude', 'phone']",
        "rowLength": 853,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763623,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of quiktrip locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 325,
                "address_city": "\"Garland\"",
                "address_country": "\"US\"",
                "address_full": "\"2010 S Shiloh Rd\"",
                "address_postcode": "\"75042\"",
                "address_state": "\"TX\"",
                "amenity_fuel": 0,
                "atm": 0,
                "fuel_HGV_diesel": 0,
                "fuel_diesel": 0,
                "fuel_e15": 0,
                "hgv": 0,
                "latitude": 32.8939,
                "longitude": -96.664868,
                "phone": "\"972-278-1236\""
            },
            {
                "__id": 490,
                "address_city": "\"Live Oak\"",
                "address_country": "\"US\"",
                "address_full": "\"7501 N Loop 1604 E\"",
                "address_postcode": "\"78233\"",
                "address_state": "\"TX\"",
                "amenity_fuel": 0,
                "atm": 0,
                "fuel_HGV_diesel": 0,
                "fuel_diesel": 0,
                "fuel_e15": 0,
                "hgv": 0,
                "latitude": 29.578435,
                "longitude": -98.341296,
                "phone": "\"210-653-9415\""
            },
            {
                "__id": 319,
                "address_city": "\"Euless\"",
                "address_country": "\"US\"",
                "address_full": "\"700 S Industrial Blvd\"",
                "address_postcode": "\"76040\"",
                "address_state": "\"TX\"",
                "amenity_fuel": 0,
                "atm": 0,
                "fuel_HGV_diesel": 0,
                "fuel_diesel": 0,
                "fuel_e15": 0,
                "hgv": 0,
                "latitude": 32.827773,
                "longitude": -97.100208,
                "phone": "\"817-283-2730\""
            },
            {
                "__id": 345,
                "address_city": "\"Fort Worth\"",
                "address_country": "\"US\"",
                "address_full": "\"2800 Alta Mere Dr\"",
                "address_postcode": "\"76116\"",
                "address_state": "\"TX\"",
                "amenity_fuel": 0,
                "atm": 0,
                "fuel_HGV_diesel": 0,
                "fuel_diesel": 0,
                "fuel_e15": 0,
                "hgv": 0,
                "latitude": 32.733267,
                "longitude": -97.441574,
                "phone": "\"817-732-4646\""
            },
            {
                "__id": 376,
                "address_city": "\"Fort Worth\"",
                "address_country": "\"US\"",
                "address_full": "\"1900 Alta Mesa Blvd\"",
                "address_postcode": "\"76134\"",
                "address_state": "\"TX\"",
                "amenity_fuel": 0,
                "atm": 0,
                "fuel_HGV_diesel": 0,
                "fuel_diesel": 0,
                "fuel_e15": 0,
                "hgv": 0,
                "latitude": 32.649451,
                "longitude": -97.347758,
                "phone": "\"817-615-8609\""
            },
            {
                "__id": 38,
                "address_city": "\"Derby\"",
                "address_country": "\"US\"",
                "address_full": "\"2801 N Rock Rd\"",
                "address_postcode": "\"67037\"",
                "address_state": "\"KS\"",
                "amenity_fuel": 0,
                "atm": 0,
                "fuel_HGV_diesel": 0,
                "fuel_diesel": 0,
                "fuel_e15": 0,
                "hgv": 0,
                "latitude": 37.577695,
                "longitude": -97.244999,
                "phone": "\"316-788-3311\""
            },
            {
                "__id": 463,
                "address_city": "\"St Louis Downtown\"",
                "address_country": "\"US\"",
                "address_full": "\"904 S Vandeventer Ave\"",
                "address_postcode": "\"63110\"",
                "address_state": "\"MO\"",
                "amenity_fuel": 0,
                "atm": 0,
                "fuel_HGV_diesel": 0,
                "fuel_diesel": 0,
                "fuel_e15": 0,
                "hgv": 0,
                "latitude": 38.628443,
                "longitude": -90.246373,
                "phone": "\"314-533-4333\""
            },
            {
                "__id": 550,
                "address_city": "\"Mesa\"",
                "address_country": "\"US\"",
                "address_full": "\"414 S Stapley Dr\"",
                "address_postcode": "\"85204\"",
                "address_state": "\"AZ\"",
                "amenity_fuel": 0,
                "atm": 0,
                "fuel_HGV_diesel": 0,
                "fuel_diesel": 0,
                "fuel_e15": 0,
                "hgv": 0,
                "latitude": 33.407364,
                "longitude": -111.806014,
                "phone": "\"480-890-3031\""
            },
            {
                "__id": 649,
                "address_city": "\"Phoenix\"",
                "address_country": "\"US\"",
                "address_full": "\"1915 W Southern Ave\"",
                "address_postcode": "\"85041\"",
                "address_state": "\"AZ\"",
                "amenity_fuel": 0,
                "atm": 0,
                "fuel_HGV_diesel": 0,
                "fuel_diesel": 0,
                "fuel_e15": 0,
                "hgv": 0,
                "latitude": 33.391751,
                "longitude": -112.099868,
                "phone": "\"602-268-0629\""
            },
            {
                "__id": 226,
                "address_city": "\"Des Moines\"",
                "address_country": "\"US\"",
                "address_full": "\"901 SW 63rd St\"",
                "address_postcode": "\"50312\"",
                "address_state": "\"IA\"",
                "amenity_fuel": 0,
                "atm": 0,
                "fuel_HGV_diesel": 0,
                "fuel_diesel": 0,
                "fuel_e15": 0,
                "hgv": 0,
                "latitude": 41.569806,
                "longitude": -93.702561,
                "phone": "\"515-274-2914\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "adeg": {
        "__id": 210,
        "id": "adeg",
        "name": "Adeg",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'website']",
        "rowLength": 263,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763624,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of adeg locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 224,
                "address_city": "\"Sommerein\"",
                "address_country": "\"AT\"",
                "address_postcode": "\"2453\"",
                "latitude": 47.99131,
                "longitude": 16.65795,
                "name": "\"ADEG WALTER SCHMUTTERER GMBH\"",
                "opening_hours": "\"Mo-Fr 06:00-18:00; Sa 07:00-12:00\"",
                "phone": "\"+43216863472\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Markt 4\"",
                "website": "\"https://www.adeg.at/services/maerkte-oeffnungszeiten/adeg-walter-schmutterer-gmbh-2\""
            },
            {
                "__id": 73,
                "address_city": "\"Hopfgarten/Defereggen\"",
                "address_country": "\"AT\"",
                "address_postcode": "\"9961\"",
                "latitude": 46.9215757,
                "longitude": 12.5200374,
                "name": "\"ADEG HINTNERS\"",
                "opening_hours": "\"Mo-We 07:30-12:00,15:00-18:30; Th 07:30-12:00; Fr 07:30-12:00,15:00-18:30; Sa 07:30-12:00\"",
                "phone": "\"+4348725342\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Nr.11\"",
                "website": "\"https://www.adeg.at/services/maerkte-oeffnungszeiten/adeg-hintners\""
            },
            {
                "__id": 48,
                "address_city": "\"M\u00f6llbr\u00fccke\"",
                "address_country": "\"AT\"",
                "address_postcode": "\"9813\"",
                "latitude": 46.83703,
                "longitude": 13.37315,
                "name": "\"ADEG STRAUSS\"",
                "opening_hours": "\"Mo-Fr 06:30-18:30; Sa 06:30-17:00\"",
                "phone": "\"+43476920849\"",
                "shop": "\"supermarket\"",
                "street_address": "\"M\u00f6lltalstrasse 18\"",
                "website": "\"https://www.adeg.at/services/maerkte-oeffnungszeiten/adeg-strauss\""
            },
            {
                "__id": 189,
                "address_city": "\"Hollenthon\"",
                "address_country": "\"AT\"",
                "address_postcode": "\"2812\"",
                "latitude": 47.589712,
                "longitude": 16.259459,
                "name": "\"ADEG LECHNER\"",
                "opening_hours": "\"Mo-Tu 06:00-12:00,16:00-18:00; We 06:00-12:00,15:00-18:00; Th 06:00-12:00; Fr 06:00-12:00,15:00-18:00; Sa 07:00-12:00\"",
                "phone": "\"+4326457234\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Kirchenplatz 2/2\"",
                "website": "\"https://www.adeg.at/services/maerkte-oeffnungszeiten/adeg-lechner-3\""
            },
            {
                "__id": 31,
                "address_city": "\"Spital/Pyhrn\"",
                "address_country": "\"AT\"",
                "address_postcode": "\"4582\"",
                "latitude": 47.66695,
                "longitude": 14.34406,
                "name": "\"ADEG EDTBAUER\"",
                "opening_hours": "\"Mo 07:30-18:30; Tu 07:30-17:00; We-Fr 07:30-18:30; Sa 07:30-17:00\"",
                "phone": "\"+4375638017\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Linzerstrasse 21\"",
                "website": "\"https://www.adeg.at/services/maerkte-oeffnungszeiten/adeg-edtbauer\""
            },
            {
                "__id": 148,
                "address_city": "\"Stainz\"",
                "address_country": "\"AT\"",
                "address_postcode": "\"8510\"",
                "latitude": 46.89416,
                "longitude": 15.26408,
                "name": "\"ADEG HUBMANN\"",
                "opening_hours": "\"Mo-Fr 08:00-18:30; Sa 08:00-17:00\"",
                "phone": "\"+4334632106\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Grazer Strasse 1\"",
                "website": "\"https://www.adeg.at/services/maerkte-oeffnungszeiten/adeg-hubmann-2\""
            },
            {
                "__id": 116,
                "address_city": "\"St. Gertraud\"",
                "address_country": "\"AT\"",
                "address_postcode": "\"9413\"",
                "latitude": 46.86838,
                "longitude": 14.87637,
                "name": "\"ADEG WOLFSBERG\"",
                "opening_hours": "\"\"",
                "phone": "\"+43435271607\"",
                "shop": "\"supermarket\"",
                "street_address": "\"St. Gertraud Nr. 87\"",
                "website": "\"https://www.adeg.at/services/maerkte-oeffnungszeiten/adeg-wolfsberg-12\""
            },
            {
                "__id": 85,
                "address_city": "\"St.Lorenzen\"",
                "address_country": "\"AT\"",
                "address_postcode": "\"9654\"",
                "latitude": 46.70195,
                "longitude": 12.78471,
                "name": "\"ADEG GUGGENBERGER\"",
                "opening_hours": "\"Mo-Fr 07:30-12:00,15:00-17:30; Sa 07:30-12:00\"",
                "phone": "\"+43471622171\"",
                "shop": "\"supermarket\"",
                "street_address": "\"St.Lorenzen 33\"",
                "website": "\"https://www.adeg.at/services/maerkte-oeffnungszeiten/adeg-guggenberger\""
            },
            {
                "__id": 132,
                "address_city": "\"Ruden\"",
                "address_country": "\"AT\"",
                "address_postcode": "\"9113\"",
                "latitude": 46.65863,
                "longitude": 14.77086,
                "name": "\"ADEG WOLFSBERG\"",
                "opening_hours": "\"\"",
                "phone": "\"+43423424564\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Obermitterdorf 45\"",
                "website": "\"https://www.adeg.at/services/maerkte-oeffnungszeiten/adeg-wolfsberg-1\""
            },
            {
                "__id": 72,
                "address_city": "\"Greifenburg\"",
                "address_country": "\"AT\"",
                "address_postcode": "\"9761\"",
                "latitude": 46.74976,
                "longitude": 13.17808,
                "name": "\"ADEG ANTO\"",
                "opening_hours": "\"Mo-Fr 06:30-18:30; Sa 07:00-17:00\"",
                "phone": "\"+4347128397\"",
                "shop": "\"supermarket\"",
                "street_address": "\"Schulstrasse 320\"",
                "website": "\"https://www.adeg.at/services/maerkte-oeffnungszeiten/adeg-anto\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "primark": {
        "__id": 211,
        "id": "primark",
        "name": "Primark",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'facebook', 'latitude', 'longitude', 'name', 'opening_hours', 'payment_american_express', 'payment_apple_pay', 'payment_cash', 'payment_google_pay', 'payment_maestro', 'payment_mastercard', 'payment_visa', 'phone', 'shop', 'street_address', 'website']",
        "rowLength": 215,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763625,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of primark locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 200,
                "address_city": "\"Metz\"",
                "address_country": "\"FR\"",
                "address_postcode": "\"57000\"",
                "address_state": "\"\"",
                "facebook": "\"https://www.facebook.com/396536757524605\"",
                "latitude": 49.104729,
                "longitude": 6.1820602,
                "name": "\"Primark\"",
                "opening_hours": "\"Mo-Sa 10:00-20:00\"",
                "payment_american_express": "\"\"",
                "payment_apple_pay": "\"\"",
                "payment_cash": "\"yes\"",
                "payment_google_pay": "\"yes\"",
                "payment_maestro": "\"\"",
                "payment_mastercard": "\"yes\"",
                "payment_visa": "\"yes\"",
                "phone": "\"+33387746968\"",
                "shop": "\"clothes\"",
                "street_address": "\"Centre commercial Muse, 2 Rue des Messageries\"",
                "website": "\"https://stores.primark.com/fr_be/france/metz/centre-commercial-muse\""
            },
            {
                "__id": 17,
                "address_city": "\"S\u00e9ville\"",
                "address_country": "\"ES\"",
                "address_postcode": "\"41092\"",
                "address_state": "\"\"",
                "facebook": "\"https://www.facebook.com/2248290328776170\"",
                "latitude": 37.39111,
                "longitude": -6.01033,
                "name": "\"Primark\"",
                "opening_hours": "\"Mo-Sa 10:00-22:00\"",
                "payment_american_express": "\"\"",
                "payment_apple_pay": "\"yes\"",
                "payment_cash": "\"yes\"",
                "payment_google_pay": "\"yes\"",
                "payment_maestro": "\"\"",
                "payment_mastercard": "\"yes\"",
                "payment_visa": "\"yes\"",
                "phone": "\"+34955329382\"",
                "shop": "\"clothes\"",
                "street_address": "\"Torre Sevilla, Calle Gonzalo Jim\u00e9nez de Quesada,\u00a02\"",
                "website": "\"https://stores.primark.com/fr_fr/espagne/seville/torre-sevilla\""
            },
            {
                "__id": 119,
                "address_city": "\"Coimbra\"",
                "address_country": "\"PT\"",
                "address_postcode": "\"3040-389\"",
                "address_state": "\"\"",
                "facebook": "\"https://www.facebook.com/238101402994011\"",
                "latitude": 40.21224613096671,
                "longitude": -8.443752229213715,
                "name": "\"Primark\"",
                "opening_hours": "\"Mo-Th 10:00-23:00; Fr-Sa 10:00-24:00; Su 10:00-23:00\"",
                "payment_american_express": "\"\"",
                "payment_apple_pay": "\"\"",
                "payment_cash": "\"yes\"",
                "payment_google_pay": "\"yes\"",
                "payment_maestro": "\"\"",
                "payment_mastercard": "\"yes\"",
                "payment_visa": "\"yes\"",
                "phone": "\"+351239800916\"",
                "shop": "\"clothes\"",
                "street_address": "\"Centre commercial Forum Coimbra, Av. Jose Bonificacio de Andrade e Silva 1, Almegue Santa Clara, Coimbra\"",
                "website": "\"https://stores.primark.com/fr_be/portugal/coimbra/centre-commercial-forum-coimbra\""
            },
            {
                "__id": 38,
                "address_city": "\"Madrid\"",
                "address_country": "\"ES\"",
                "address_postcode": "\"28051\"",
                "address_state": "\"\"",
                "facebook": "\"https://www.facebook.com/183928059029353\"",
                "latitude": 40.36836026138855,
                "longitude": -3.5963082711639345,
                "name": "\"Primark\"",
                "opening_hours": "\"Mo-Su 10:00-22:00\"",
                "payment_american_express": "\"\"",
                "payment_apple_pay": "\"yes\"",
                "payment_cash": "\"yes\"",
                "payment_google_pay": "\"yes\"",
                "payment_maestro": "\"\"",
                "payment_mastercard": "\"yes\"",
                "payment_visa": "\"yes\"",
                "phone": "\"+34914255412\"",
                "shop": "\"clothes\"",
                "street_address": "\"Centre commercial La Gavia, Calle Adolfo Bioy Casares 2, local B-17\"",
                "website": "\"https://stores.primark.com/fr_fr/espagne/madrid/centre-commercial-la-gavia\""
            },
            {
                "__id": 76,
                "address_city": "\"Innsbruck\"",
                "address_country": "\"AT\"",
                "address_postcode": "\"6020\"",
                "address_state": "\"\"",
                "facebook": "\"https://www.facebook.com/249433418525524\"",
                "latitude": 47.266203153289744,
                "longitude": 11.404256740806622,
                "name": "\"Primark\"",
                "opening_hours": "\"Mo-We 09:00-19:00; Th-Fr 09:00-20:00; Sa 09:00-18:00\"",
                "payment_american_express": "\"\"",
                "payment_apple_pay": "\"yes\"",
                "payment_cash": "\"yes\"",
                "payment_google_pay": "\"yes\"",
                "payment_maestro": "\"yes\"",
                "payment_mastercard": "\"yes\"",
                "payment_visa": "\"yes\"",
                "phone": "\"+43512551307\"",
                "shop": "\"clothes\"",
                "street_address": "\"Centre commercial Sillpark, Museum Strase 38\"",
                "website": "\"https://stores.primark.com/fr_fr/autriche/innsbruck/centre-commercial-sillpark\""
            },
            {
                "__id": 121,
                "address_city": "\"Almancil\"",
                "address_country": "\"PT\"",
                "address_postcode": "\"8135-014\"",
                "address_state": "\"Loul\u00e9\"",
                "facebook": "\"https://www.facebook.com/1427063397420249\"",
                "latitude": 37.099224,
                "longitude": -7.9976882,
                "name": "\"Primark\"",
                "opening_hours": "\"Mo-Su 10:00-23:00\"",
                "payment_american_express": "\"\"",
                "payment_apple_pay": "\"\"",
                "payment_cash": "\"yes\"",
                "payment_google_pay": "\"yes\"",
                "payment_maestro": "\"\"",
                "payment_mastercard": "\"yes\"",
                "payment_visa": "\"yes\"",
                "phone": "\"+351289543414\"",
                "shop": "\"clothes\"",
                "street_address": "\"Centre commercial MAR Shopping, Loja 0.19\"",
                "website": "\"https://stores.primark.com/fr_be/portugal/almancil/centre-commercial-mar-shopping\""
            },
            {
                "__id": 159,
                "address_city": "\"Tralee\"",
                "address_country": "\"IE\"",
                "address_postcode": "\"V92 CX99\"",
                "address_state": "\"Kerry\"",
                "facebook": "\"https://www.facebook.com/142905253049210\"",
                "latitude": 52.26977363367397,
                "longitude": -9.705213495700832,
                "name": "\"Penneys\"",
                "opening_hours": "\"Mo-We 09:00-18:00; Th 09:00-20:00; Fr 09:00-21:00; Sa 09:00-18:30; Su 11:30-18:00\"",
                "payment_american_express": "\"yes\"",
                "payment_apple_pay": "\"yes\"",
                "payment_cash": "\"yes\"",
                "payment_google_pay": "\"yes\"",
                "payment_maestro": "\"\"",
                "payment_mastercard": "\"yes\"",
                "payment_visa": "\"yes\"",
                "phone": "\"+353667117506\"",
                "shop": "\"clothes\"",
                "street_address": "\"23 - 24 The Mall\"",
                "website": "\"https://stores.primark.com/fr_be/irlande/tralee/23-24-the-mall\""
            },
            {
                "__id": 30,
                "address_city": "\"Marbella\"",
                "address_country": "\"ES\"",
                "address_postcode": "\"29600\"",
                "address_state": "\"\"",
                "facebook": "\"https://www.facebook.com/105865441788544\"",
                "latitude": 36.52256281142036,
                "longitude": -4.87240619046021,
                "name": "\"Primark\"",
                "opening_hours": "\"Mo-Sa 10:00-22:00\"",
                "payment_american_express": "\"\"",
                "payment_apple_pay": "\"\"",
                "payment_cash": "\"\"",
                "payment_google_pay": "\"\"",
                "payment_maestro": "\"\"",
                "payment_mastercard": "\"\"",
                "payment_visa": "\"\"",
                "phone": "\"+34851920010\"",
                "shop": "\"clothes\"",
                "street_address": "\"La Ca\u00f1ada Parque Comercial, Carretera de Oj\u00e9n s/n\"",
                "website": "\"https://stores.primark.com/fr_fr/espagne/marbella/la-canada-parque-comercial\""
            },
            {
                "__id": 208,
                "address_city": "\"Creteil\"",
                "address_country": "\"FR\"",
                "address_postcode": "\"94000\"",
                "address_state": "\"\"",
                "facebook": "\"https://www.facebook.com/378641875866651\"",
                "latitude": 48.78180463273082,
                "longitude": 2.4551638920547703,
                "name": "\"Primark\"",
                "opening_hours": "\"Mo-Sa 10:00-20:30; Su 11:00-19:00\"",
                "payment_american_express": "\"\"",
                "payment_apple_pay": "\"\"",
                "payment_cash": "\"yes\"",
                "payment_google_pay": "\"yes\"",
                "payment_maestro": "\"\"",
                "payment_mastercard": "\"yes\"",
                "payment_visa": "\"yes\"",
                "phone": "\"+33148536602\"",
                "shop": "\"clothes\"",
                "street_address": "\"Avenue de la France Libre\"",
                "website": "\"https://stores.primark.com/fr_be/france/creteil/avenue-de-la-france-libre\""
            },
            {
                "__id": 56,
                "address_city": "\"Barcelona\"",
                "address_country": "\"ES\"",
                "address_postcode": "\"08940\"",
                "address_state": "\"\"",
                "facebook": "\"https://www.facebook.com/314016585353460\"",
                "latitude": 41.347461214528856,
                "longitude": 2.077598149111939,
                "name": "\"Primark\"",
                "opening_hours": "\"Mo-Sa 09:00-21:00\"",
                "payment_american_express": "\"\"",
                "payment_apple_pay": "\"yes\"",
                "payment_cash": "\"yes\"",
                "payment_google_pay": "\"yes\"",
                "payment_maestro": "\"\"",
                "payment_mastercard": "\"yes\"",
                "payment_visa": "\"yes\"",
                "phone": "\"+34934719279\"",
                "shop": "\"clothes\"",
                "street_address": "\"Centre commercial Splau, Avda. del Baix Llobregat\"",
                "website": "\"https://stores.primark.com/fr_fr/espagne/barcelona/centre-commercial-splau\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "jim_n_nicks": {
        "__id": 212,
        "id": "jim_n_nicks",
        "name": "Jim 'N Nick's",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'name', 'phone', 'website']",
        "rowLength": 40,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763626,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of jim_n_nicks locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 16,
                "address_city": "\"Pooler\"",
                "address_country": "\"US\"",
                "address_full": "\"147 Traders Way\"",
                "address_postcode": "\"31322\"",
                "address_state": "\"GA\"",
                "name": "\"Pooler\"",
                "phone": "\"912-330-4130\"",
                "website": "\"https://www.jimnnicks.com/locations/ga/pooler/147-traders-way\""
            },
            {
                "__id": 17,
                "address_city": "\"Alpharetta\"",
                "address_country": "\"US\"",
                "address_full": "\"5150 Windward Parkway\"",
                "address_postcode": "\"30004\"",
                "address_state": "\"GA\"",
                "name": "\"Alpharetta / Milton - Coming Soon\"",
                "phone": "\"\"",
                "website": "\"https://www.jimnnicks.com/locations/ga/alpharetta/5150-windward-parkway\""
            },
            {
                "__id": 28,
                "address_city": "\"Cullman\"",
                "address_country": "\"US\"",
                "address_full": "\"1851 Cherokee Ave SW\"",
                "address_postcode": "\"35055\"",
                "address_state": "\"AL\"",
                "name": "\"Cullman\"",
                "phone": "\"256-727-5421\"",
                "website": "\"https://www.jimnnicks.com/locations/al/cullman/1851-cherokee-ave-sw\""
            },
            {
                "__id": 3,
                "address_city": "\"Nashville\"",
                "address_country": "\"US\"",
                "address_full": "\"7004 Charlotte Pike\"",
                "address_postcode": "\"37209\"",
                "address_state": "\"TN\"",
                "name": "\"Nashville / Charlotte Pike\"",
                "phone": "\"615-352-5777\"",
                "website": "\"https://www.jimnnicks.com/locations/tn/nashville/7004-charlotte-pike\""
            },
            {
                "__id": 6,
                "address_city": "\"Cordova\"",
                "address_country": "\"US\"",
                "address_full": "\"2359 N. Germantown Pkwy\"",
                "address_postcode": "\"38016\"",
                "address_state": "\"TN\"",
                "name": "\"Cordova / Memphis\"",
                "phone": "\"901-388-0998\"",
                "website": "\"https://www.jimnnicks.com/locations/tn/cordova/2359-n-germantown-pkwy\""
            },
            {
                "__id": 29,
                "address_city": "\"Birmingham\"",
                "address_country": "\"US\"",
                "address_full": "\"1908 11th Ave S\"",
                "address_postcode": "\"35205\"",
                "address_state": "\"AL\"",
                "name": "\"Birmingham / 5 Points\"",
                "phone": "\"205-320-1060\"",
                "website": "\"https://www.jimnnicks.com/locations/al/birmingham/1908-11th-ave-s\""
            },
            {
                "__id": 32,
                "address_city": "\"McDonough\"",
                "address_country": "\"US\"",
                "address_full": "\"1480 HWY 20 West\"",
                "address_postcode": "\"30253\"",
                "address_state": "\"GA\"",
                "name": "\"McDonough\"",
                "phone": "\"678-619-5098\"",
                "website": "\"https://www.jimnnicks.com/locations/ga/mcdonough/1480-hwy-20-west\""
            },
            {
                "__id": 24,
                "address_city": "\"Huntsville\"",
                "address_country": "\"US\"",
                "address_full": "\"4935 University Drive NW\"",
                "address_postcode": "\"35816\"",
                "address_state": "\"AL\"",
                "name": "\"Huntsville\"",
                "phone": "\"256-963-9656\"",
                "website": "\"https://www.jimnnicks.com/locations/al/huntsville/4935-university-drive-nw\""
            },
            {
                "__id": 37,
                "address_city": "\"Conyers\"",
                "address_country": "\"US\"",
                "address_full": "\"2275 Georgia Highway 20\"",
                "address_postcode": "\"30013\"",
                "address_state": "\"GA\"",
                "name": "\"Conyers\"",
                "phone": "\"770-785-4453\"",
                "website": "\"https://www.jimnnicks.com/locations/ga/conyers/2275-georgia-highway-20\""
            },
            {
                "__id": 18,
                "address_city": "\"Oxford\"",
                "address_country": "\"US\"",
                "address_full": "\"50 Oxford Exchange Boulevard\"",
                "address_postcode": "\"36203\"",
                "address_state": "\"AL\"",
                "name": "\"Oxford\"",
                "phone": "\"256-745-9300\"",
                "website": "\"https://www.jimnnicks.com/locations/al/oxford/50-oxford-exchange-boulevard\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "huntingtonbank": {
        "__id": 213,
        "id": "huntingtonbank",
        "name": "Huntington Bank",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'fax', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'website']",
        "rowLength": 1057,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763627,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of huntingtonbank locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 132,
                "address_city": "\"Cincinnati\"",
                "address_country": "\"US\"",
                "address_full": "\"2692 Madison Road Ste H4\"",
                "address_postcode": "\"45208\"",
                "address_state": "\"OH\"",
                "fax": "\"513-841-4733\"",
                "latitude": 39.14751,
                "longitude": -84.444363,
                "name": "\"Rookwood \"",
                "opening_hours": "\"Mo-Fr 09:00-17:00; Sa 09:00-12:00; Su 00:00-00:00\"",
                "phone": "\"513-841-2800\"",
                "website": "\"https://www.huntington.com/Community/branch-info?locationId=786\""
            },
            {
                "__id": 747,
                "address_city": "\"Elyria\"",
                "address_country": "\"US\"",
                "address_full": "\"105 Court St\"",
                "address_postcode": "\"44035\"",
                "address_state": "\"OH\"",
                "fax": "\"440-329-3296\"",
                "latitude": 41.36752825,
                "longitude": -82.108157138,
                "name": "\"Broad and Court Streets\"",
                "opening_hours": "\"Mo-Fr 09:00-17:00; Sa 09:00-12:00; Su 00:00-00:00\"",
                "phone": "\"440-329-3218\"",
                "website": "\"https://www.huntington.com/Community/branch-info?locationId=23776\""
            },
            {
                "__id": 813,
                "address_city": "\"Mount Vernon\"",
                "address_country": "\"US\"",
                "address_full": "\"901 Coshocton Ave\"",
                "address_postcode": "\"43050\"",
                "address_state": "\"OH\"",
                "fax": "\"740-392-5349\"",
                "latitude": 40.400255515,
                "longitude": -82.456571126,
                "name": "\"Coshocton Ave\"",
                "opening_hours": "\"Mo-Fr 09:00-17:00; Sa 09:00-12:00; Su 00:00-00:00\"",
                "phone": "\"740-397-6407\"",
                "website": "\"https://www.huntington.com/Community/branch-info?locationId=23508\""
            },
            {
                "__id": 72,
                "address_city": "\"Pittsburgh\"",
                "address_country": "\"US\"",
                "address_full": "\"650 Smithfield St.\"",
                "address_postcode": "\"15222\"",
                "address_state": "\"PA\"",
                "fax": "\"412-471-4334\"",
                "latitude": 40.442482,
                "longitude": -79.996702,
                "name": "\"Centre City \"",
                "opening_hours": "\"Mo-Th 09:00-16:00; Fr 09:00-17:00; Sa-Su 00:00-00:00\"",
                "phone": "\"412-471-7010\"",
                "website": "\"https://www.huntington.com/Community/branch-info?locationId=9826\""
            },
            {
                "__id": 85,
                "address_city": "\"Bexley\"",
                "address_country": "\"US\"",
                "address_full": "\"2631 E. Main St.\"",
                "address_postcode": "\"43209\"",
                "address_state": "\"OH\"",
                "fax": "\"614-237-3537\"",
                "latitude": 39.956661,
                "longitude": -82.928275,
                "name": "\"Bexley \"",
                "opening_hours": "\"Mo-Fr 09:00-17:00; Sa 09:00-12:00; Su 00:00-00:00\"",
                "phone": "\"614-480-0027\"",
                "website": "\"https://www.huntington.com/Community/branch-info?locationId=9782\""
            },
            {
                "__id": 605,
                "address_city": "\"Inver Grove Heights\"",
                "address_country": "\"US\"",
                "address_full": "\"7850 Cahill Ave\"",
                "address_postcode": "\"55076\"",
                "address_state": "\"MN\"",
                "fax": "\"651-457-7156\"",
                "latitude": 44.83509,
                "longitude": -93.0413,
                "name": "\"Inver Grove Heights Cub\"",
                "opening_hours": "\"Mo-Fr 10:00-18:00; Sa 10:00-15:00; Su 00:00-00:00\"",
                "phone": "\"612-460-4167\"",
                "website": "\"https://www.huntington.com/Community/branch-info?locationId=29526\""
            },
            {
                "__id": 514,
                "address_city": "\"Niles\"",
                "address_country": "\"US\"",
                "address_full": "\"7759 N Milwaukee Ave\"",
                "address_postcode": "\"60714\"",
                "address_state": "\"IL\"",
                "fax": "\"847-966-7354\"",
                "latitude": 42.0222509095762,
                "longitude": -87.8102201866599,
                "name": "\"Niles Milwaukee\"",
                "opening_hours": "\"Mo-Fr 09:00-17:00; Sa-Su 00:00-00:00\"",
                "phone": "\"847-928-6850\"",
                "website": "\"https://www.huntington.com/Community/branch-info?locationId=29712\""
            },
            {
                "__id": 639,
                "address_city": "\"Allegan\"",
                "address_country": "\"US\"",
                "address_full": "\"236 Hubbard St\"",
                "address_postcode": "\"49010-1321\"",
                "address_state": "\"MI\"",
                "fax": "\"269-673-8030\"",
                "latitude": 42.52785,
                "longitude": -85.85062,
                "name": "\"Allegan\"",
                "opening_hours": "\"Mo-Fr 09:00-17:00; Sa 09:00-12:00; Su 00:00-00:00\"",
                "phone": "\"269-673-6691\"",
                "website": "\"https://www.huntington.com/Community/branch-info?locationId=29458\""
            },
            {
                "__id": 238,
                "address_city": "\"Springboro\"",
                "address_country": "\"US\"",
                "address_full": "\"736 North Main Street\"",
                "address_postcode": "\"45066\"",
                "address_state": "\"OH\"",
                "fax": "\"937-748-2582\"",
                "latitude": 39.576912,
                "longitude": -84.23026,
                "name": "\"Springboro \"",
                "opening_hours": "\"Mo-Fr 09:00-17:00; Sa 09:00-12:00; Su 00:00-00:00\"",
                "phone": "\"937-748-1421\"",
                "website": "\"https://www.huntington.com/Community/branch-info?locationId=346\""
            },
            {
                "__id": 335,
                "address_city": "\"Saline\"",
                "address_country": "\"US\"",
                "address_full": "\"1301 E Michigan Ave\"",
                "address_postcode": "\"48176\"",
                "address_state": "\"MI\"",
                "fax": "\"734-429-4749\"",
                "latitude": 42.17692,
                "longitude": -83.7596,
                "name": "\"Saline\"",
                "opening_hours": "\"Mo-Fr 09:00-17:00; Sa 09:00-12:00; Su 00:00-00:00\"",
                "phone": "\"734-741-2618\"",
                "website": "\"https://www.huntington.com/Community/branch-info?locationId=30074\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "dish": {
        "__id": 214,
        "id": "dish",
        "name": "Dish",
        "columnNames": "['address_full', 'latitude', 'longitude', 'name']",
        "rowLength": 429,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763627,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of dish locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 315,
                "address_full": "\"1210 US HIGHWAY 1, SEBASTIAN, FL 32958\"",
                "latitude": 27.8166812,
                "longitude": -80.4689148,
                "name": "\"VIDEO TECH SERVICES\""
            },
            {
                "__id": 133,
                "address_full": "\"13800 W WARREN AVE, DEARBORN, MI 48126\"",
                "latitude": 42.3442311,
                "longitude": -83.1794684,
                "name": "\"DEARBORN SATELLITE & DISH\""
            },
            {
                "__id": 11,
                "address_full": "\"267 WHITTEN RD, HALLOWELL, ME 04347\"",
                "latitude": 44.2996173,
                "longitude": -69.8095292,
                "name": "\"S&C SATELLITE ENTERTAINMENT\""
            },
            {
                "__id": 236,
                "address_full": "\"360 W JUDICIAL ST, BLACKFOOT, ID 83221\"",
                "latitude": 43.1899913,
                "longitude": -112.3495619,
                "name": "\"WINKLER TV\""
            },
            {
                "__id": 404,
                "address_full": "\"2400 SPENARD RD STE 300, ANCHORAGE, AK 99503\"",
                "latitude": 61.1988214,
                "longitude": -149.9060264,
                "name": "\"SATELLITE GUY INC\""
            },
            {
                "__id": 400,
                "address_full": "\"2208 CLEVELAND AVE, ANCHORAGE, AK 99517\"",
                "latitude": 61.1844446,
                "longitude": -149.9245618,
                "name": "\"SATELLITE ALASKA\""
            },
            {
                "__id": 369,
                "address_full": "\"105 S 3RD AVE, SANDPOINT, ID 83864\"",
                "latitude": 48.2728172,
                "longitude": -116.5502787,
                "name": "\"HESSTRONICS INC\""
            },
            {
                "__id": 418,
                "address_full": "\"205 W HATCH RD, MODESTO, CA 95351\"",
                "latitude": 37.6098226,
                "longitude": -120.9952123,
                "name": "\"AZTECA SATELLITE\""
            },
            {
                "__id": 39,
                "address_full": "\"414 HWY 43 S, TUSCUMBIA, AL 35674\"",
                "latitude": 30.8123642,
                "longitude": -88.0707671,
                "name": "\"SHOALS SATELLITE SLS & SVC INC\""
            },
            {
                "__id": 263,
                "address_full": "\"2221 N MCKENZIE ST, FOLEY, AL 36535\"",
                "latitude": 30.4328221,
                "longitude": -87.6837078,
                "name": "\"SUNSHINE SATELLITES INC\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "lloyds_bank_gb": {
        "__id": 215,
        "id": "lloyds_bank_gb",
        "name": "Lloyds Bank Great Britain",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'image', 'latitude', 'longitude', 'name', 'phone', 'street_address', 'website']",
        "rowLength": 685,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763628,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of lloyds_bank_gb locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 431,
                "address_city": "\"Hunslet\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"LS10 2AP\"",
                "image": "\"https://dynl.mktgcdn.com/p/Xpsc0X8Cq9USpnm7wTmDJ_M35gUtEcLmbBLPvbG64Ow/150x150.png\"",
                "latitude": 53.779429,
                "longitude": -1.52587,
                "name": "\"Lloyds Bank Leeds Hunslet\"",
                "phone": "\"0345 602 1997\"",
                "street_address": "\"Hunslet District Centre Church Street\"",
                "website": "\"https://branches.lloydsbank.com/hunslet/hunslet-district-centre\""
            },
            {
                "__id": 532,
                "address_city": "\"Doncaster\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"DN6 7HN\"",
                "image": "\"https://dynl.mktgcdn.com/p/Xpsc0X8Cq9USpnm7wTmDJ_M35gUtEcLmbBLPvbG64Ow/150x150.png\"",
                "latitude": 53.5639351,
                "longitude": -1.1952126,
                "name": "\"Lloyds Bank Doncaster Woodlands\"",
                "phone": "\"0345 602 1997\"",
                "street_address": "\"276 Great North Road Woodlands\"",
                "website": "\"https://branches.lloydsbank.com/doncaster/276-great-north-road\""
            },
            {
                "__id": 291,
                "address_city": "\"Newcastle-upon-Tyne\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"NE7 7EE\"",
                "image": "\"https://dynl.mktgcdn.com/p/Xpsc0X8Cq9USpnm7wTmDJ_M35gUtEcLmbBLPvbG64Ow/150x150.png\"",
                "latitude": 55.00756830284936,
                "longitude": -1.5785750331178683,
                "name": "\"Lloyds Bank Newcastle-upon-Tyne Benton\"",
                "phone": "\"0345 602 1997\"",
                "street_address": "\"331 Benton Road Benton\"",
                "website": "\"https://branches.lloydsbank.com/newcastle-upon-tyne/331-benton-road\""
            },
            {
                "__id": 338,
                "address_city": "\"London\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"WC2N 5LL\"",
                "image": "\"https://dynl.mktgcdn.com/p/Xpsc0X8Cq9USpnm7wTmDJ_M35gUtEcLmbBLPvbG64Ow/150x150.png\"",
                "latitude": 51.50908653025536,
                "longitude": -0.1240234157393405,
                "name": "\"Lloyds Bank London The Strand\"",
                "phone": "\"0345 602 1997\"",
                "street_address": "\"Villiers House 48-49 Strand\"",
                "website": "\"https://branches.lloydsbank.com/london/villiers-house\""
            },
            {
                "__id": 430,
                "address_city": "\"Huntingdon\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"PE29 3DU\"",
                "image": "\"https://dynl.mktgcdn.com/p/Xpsc0X8Cq9USpnm7wTmDJ_M35gUtEcLmbBLPvbG64Ow/150x150.png\"",
                "latitude": 52.33126219256656,
                "longitude": -0.18525740913767796,
                "name": "\"Lloyds Bank Huntingdon\"",
                "phone": "\"0345 602 1997\"",
                "street_address": "\"99 High Street\"",
                "website": "\"https://branches.lloydsbank.com/huntingdon/99-high-street\""
            },
            {
                "__id": 397,
                "address_city": "\"Leeds\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"LS15 8ET\"",
                "image": "\"https://dynl.mktgcdn.com/p/Xpsc0X8Cq9USpnm7wTmDJ_M35gUtEcLmbBLPvbG64Ow/150x150.png\"",
                "latitude": 53.8062737,
                "longitude": -1.4508235,
                "name": "\"Lloyds Bank Leeds Crossgates\"",
                "phone": "\"0345 602 1997\"",
                "street_address": "\"1 Cross Gates Centre\"",
                "website": "\"https://branches.lloydsbank.com/leeds/1-cross-gates-centre\""
            },
            {
                "__id": 127,
                "address_city": "\"Street\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"BA16 0ED\"",
                "image": "\"https://dynl.mktgcdn.com/p/Xpsc0X8Cq9USpnm7wTmDJ_M35gUtEcLmbBLPvbG64Ow/150x150.png\"",
                "latitude": 51.1270399251975,
                "longitude": -2.7396576834323927,
                "name": "\"Lloyds Bank Street\"",
                "phone": "\"0345 602 1997\"",
                "street_address": "\"64 High Street\"",
                "website": "\"https://branches.lloydsbank.com/street/64-high-street\""
            },
            {
                "__id": 654,
                "address_city": "\"Birmingham\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"B37 5TS\"",
                "image": "\"https://dynl.mktgcdn.com/p/Xpsc0X8Cq9USpnm7wTmDJ_M35gUtEcLmbBLPvbG64Ow/150x150.png\"",
                "latitude": 52.48027578363367,
                "longitude": -1.7393391351795406,
                "name": "\"Lloyds Bank Birmingham Chelmsley Wood\"",
                "phone": "\"0345 602 1997\"",
                "street_address": "\"3 Maple Walk Chelmsley Wood\"",
                "website": "\"https://branches.lloydsbank.com/birmingham/3-maple-walk\""
            },
            {
                "__id": 350,
                "address_city": "\"London\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"W1C 2BU\"",
                "image": "\"https://dynl.mktgcdn.com/p/Xpsc0X8Cq9USpnm7wTmDJ_M35gUtEcLmbBLPvbG64Ow/150x150.png\"",
                "latitude": 51.51409117698771,
                "longitude": -0.15048578955262792,
                "name": "\"Lloyds Bank London 399 Oxford Street\"",
                "phone": "\"0345 602 1997\"",
                "street_address": "\"399 Oxford Street\"",
                "website": "\"https://branches.lloydsbank.com/london/399-oxford-street\""
            },
            {
                "__id": 383,
                "address_city": "\"Lincoln\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"LN6 8HW\"",
                "image": "\"https://dynl.mktgcdn.com/p/Xpsc0X8Cq9USpnm7wTmDJ_M35gUtEcLmbBLPvbG64Ow/150x150.png\"",
                "latitude": 53.19390357388022,
                "longitude": -0.5809439895586643,
                "name": "\"Lloyds Bank Lincoln North Hykeham\"",
                "phone": "\"0345 602 1997\"",
                "street_address": "\"29-30 The Forum North Hykeham\"",
                "website": "\"https://branches.lloydsbank.com/lincoln/29-30-the-forum\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "erieinsurance": {
        "__id": 216,
        "id": "erieinsurance",
        "name": "Erie Insurance",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'fax', 'latitude', 'longitude', 'name', 'office', 'opening_hours', 'phone', 'street_address', 'website']",
        "rowLength": 3084,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763630,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of erieinsurance locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 1276,
                "address_city": "\"Morristown\"",
                "address_country": "\"\"",
                "address_postcode": "\"37814\"",
                "address_state": "\"TN\"",
                "fax": "\"4235873900\"",
                "latitude": 36.207689,
                "longitude": -83.308026,
                "name": "\"Alliance Insurance\"",
                "office": "\"insurance\"",
                "opening_hours": "\"Mo-Fr 09:00-17:00\"",
                "phone": "\"4235850698\"",
                "street_address": "\"2737 W Andrew Johnson Hwy\"",
                "website": "\"http://www.allianceinsurancemorristown.com\""
            },
            {
                "__id": 1881,
                "address_city": "\"Cicero\"",
                "address_country": "\"\"",
                "address_postcode": "\"46034\"",
                "address_state": "\"IN\"",
                "fax": "\"3179843338\"",
                "latitude": 40.131842,
                "longitude": -86.013432,
                "name": "\"Penwell Insurance LLC\"",
                "office": "\"insurance\"",
                "opening_hours": "\"Mo-Fr 09:00-17:00\"",
                "phone": "\"3179843300\"",
                "street_address": "\"30 S Peru Street\"",
                "website": "\"http://www.penwellinsurance.com\""
            },
            {
                "__id": 2787,
                "address_city": "\"Reedsville\"",
                "address_country": "\"\"",
                "address_postcode": "\"17084\"",
                "address_state": "\"PA\"",
                "fax": "\"7176673450\"",
                "latitude": 40.663348,
                "longitude": -77.595902,
                "name": "\"The Stewardship Group Inc.\"",
                "office": "\"insurance\"",
                "opening_hours": "\"Mo-Fr 09:00-17:00\"",
                "phone": "\"7176673994\"",
                "street_address": "\"31 S Main Street\"",
                "website": "\"\""
            },
            {
                "__id": 338,
                "address_city": "\"Fulton\"",
                "address_country": "\"\"",
                "address_postcode": "\"13069\"",
                "address_state": "\"NY\"",
                "fax": "\"3155922900\"",
                "latitude": 43.320851,
                "longitude": -76.413555,
                "name": "\"The Insurance Place Agency LLC\"",
                "office": "\"insurance\"",
                "opening_hours": "\"Mo-Fr 09:00-17:00\"",
                "phone": "\"3155922961\"",
                "street_address": "\"184 S 3rd Street\"",
                "website": "\"http://www.theinsuranceplaceagency.com\""
            },
            {
                "__id": 482,
                "address_city": "\"Lockport\"",
                "address_country": "\"\"",
                "address_postcode": "\"60441\"",
                "address_state": "\"IL\"",
                "fax": "\"8158387902\"",
                "latitude": 41.596536,
                "longitude": -88.03629,
                "name": "\"Assurity Insurance Agency Inc.\"",
                "office": "\"insurance\"",
                "opening_hours": "\"Mo-Fr 09:00-17:00\"",
                "phone": "\"8158387900\"",
                "street_address": "\"1112 E 9th Street\"",
                "website": "\"http://www.assurityagency.com\""
            },
            {
                "__id": 2846,
                "address_city": "\"Murrysville\"",
                "address_country": "\"\"",
                "address_postcode": "\"15668\"",
                "address_state": "\"PA\"",
                "fax": "\"4123494824\"",
                "latitude": 40.425454,
                "longitude": -79.690858,
                "name": "\"David S Tyson Insurance Agency\"",
                "office": "\"insurance\"",
                "opening_hours": "\"Mo-Fr 08:30-16:30\"",
                "phone": "\"4123490998\"",
                "street_address": "\"4071 Hart Court\"",
                "website": "\"http://www.sebakinsurance.com\""
            },
            {
                "__id": 1525,
                "address_city": "\"Genoa\"",
                "address_country": "\"\"",
                "address_postcode": "\"43430\"",
                "address_state": "\"OH\"",
                "fax": "\"\"",
                "latitude": 41.529204,
                "longitude": -83.35811,
                "name": "\"Aaron Grob Agency Inc.\"",
                "office": "\"insurance\"",
                "opening_hours": "\"Mo-Fr 09:00-17:00\"",
                "phone": "\"4198559089\"",
                "street_address": "\"1515 Main Street\"",
                "website": "\"http://www.aarongrobagency.com/\""
            },
            {
                "__id": 3016,
                "address_city": "\"Clearfield\"",
                "address_country": "\"\"",
                "address_postcode": "\"16830\"",
                "address_state": "\"PA\"",
                "fax": "\"8147651788\"",
                "latitude": 41.027255,
                "longitude": -78.439451,
                "name": "\"Boyles Insurance Agency\"",
                "office": "\"insurance\"",
                "opening_hours": "\"Mo-Fr 08:00-16:30\"",
                "phone": "\"8147658152\"",
                "street_address": "\"11 Nichols Street\"",
                "website": "\"http://www.stmarysagency.com\""
            },
            {
                "__id": 676,
                "address_city": "\"Louisville\"",
                "address_country": "\"\"",
                "address_postcode": "\"40241\"",
                "address_state": "\"KY\"",
                "fax": "\"5022333319\"",
                "latitude": 38.312329,
                "longitude": -85.56921,
                "name": "\"Aspen Ridge Insurance LLC\"",
                "office": "\"insurance\"",
                "opening_hours": "\"Mo-Fr 09:00-17:00\"",
                "phone": "\"5022332272\"",
                "street_address": "\"9850 Von Allmen Court Suite 201\"",
                "website": "\"http://www.aspenridgeinsurance.com\""
            },
            {
                "__id": 592,
                "address_city": "\"Fairview Heights\"",
                "address_country": "\"\"",
                "address_postcode": "\"62208\"",
                "address_state": "\"IL\"",
                "fax": "\"6183987475\"",
                "latitude": 38.596383,
                "longitude": -90.012006,
                "name": "\"Community Brokers Insurance Group Inc.\"",
                "office": "\"insurance\"",
                "opening_hours": "\"Mo-Fr 08:00-17:00\"",
                "phone": "\"6183985800\"",
                "street_address": "\"10041 Bunkum Road\"",
                "website": "\"\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "homegoods": {
        "__id": 217,
        "id": "homegoods",
        "name": "HomeGoods",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'website']",
        "rowLength": 890,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763631,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of homegoods locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 136,
                "address_city": "\"Fredericksburg\"",
                "address_country": "\"US\"",
                "address_postcode": "\"22401\"",
                "address_state": "\"VA\"",
                "latitude": 38.294605255127,
                "longitude": -77.5100708007813,
                "name": "\"Fredericksburg\"",
                "opening_hours": "\"Mo-Sa 09:30-21:30; Su 10:00-20:00\"",
                "phone": "\"540-785-0970\"",
                "shop": "\"houseware\"",
                "street_address": "\"3102 Plank Rd.\"",
                "website": "\"https://www.homegoods.com/us/store/stores/Fredericksburg-VA-22401/559/aboutstore\""
            },
            {
                "__id": 659,
                "address_city": "\"Jersey City\"",
                "address_country": "\"US\"",
                "address_postcode": "\"07304\"",
                "address_state": "\"NJ\"",
                "latitude": 40.723560333252,
                "longitude": -74.0901565551758,
                "name": "\"Jersey City\"",
                "opening_hours": "\"Mo-Sa 09:30-21:30; Su 10:00-20:00\"",
                "phone": "\"201-209-0505\"",
                "shop": "\"houseware\"",
                "street_address": "\"701 Route 440\"",
                "website": "\"https://www.homegoods.com/us/store/stores/Jersey+City-NJ-07304/459/aboutstore\""
            },
            {
                "__id": 97,
                "address_city": "\"Rochester\"",
                "address_country": "\"US\"",
                "address_postcode": "\"48307\"",
                "address_state": "\"MI\"",
                "latitude": 42.6640396118164,
                "longitude": -83.1331024169922,
                "name": "\"Rochester\"",
                "opening_hours": "\"Mo-Sa 09:30-21:30; Su 10:00-20:00\"",
                "phone": "\"248-651-0950\"",
                "shop": "\"houseware\"",
                "street_address": "\"1154 S. Rochester Rd\"",
                "website": "\"https://www.homegoods.com/us/store/stores/Rochester-MI-48307/288/aboutstore\""
            },
            {
                "__id": 264,
                "address_city": "\"Bradenton\"",
                "address_country": "\"US\"",
                "address_postcode": "\"34210\"",
                "address_state": "\"FL\"",
                "latitude": 27.4636821746826,
                "longitude": -82.6342468261719,
                "name": "\"Bradenton\"",
                "opening_hours": "\"Mo-Su 09:00-22:00\"",
                "phone": "\"941-761-4050\"",
                "shop": "\"houseware\"",
                "street_address": "\"7134 Cortez Road W\"",
                "website": "\"https://www.homegoods.com/us/store/stores/Bradenton-FL-34210/833/aboutstore\""
            },
            {
                "__id": 374,
                "address_city": "\"Burnsville\"",
                "address_country": "\"US\"",
                "address_postcode": "\"55337\"",
                "address_state": "\"MN\"",
                "latitude": 44.7480163574219,
                "longitude": -93.2859115600586,
                "name": "\"Burnsville\"",
                "opening_hours": "\"Mo-Sa 09:30-21:30; Su 10:00-20:00\"",
                "phone": "\"952-435-8206\"",
                "shop": "\"houseware\"",
                "street_address": "\"14041 Aldrich Avenue South\"",
                "website": "\"https://www.homegoods.com/us/store/stores/Burnsville-MN-55337/221/aboutstore\""
            },
            {
                "__id": 304,
                "address_city": "\"Bee Cave\"",
                "address_country": "\"US\"",
                "address_postcode": "\"78738\"",
                "address_state": "\"TX\"",
                "latitude": 30.3060207366943,
                "longitude": -97.9406661987305,
                "name": "\"The Galleria\"",
                "opening_hours": "\"Mo-Su 09:00-22:00\"",
                "phone": "\"512-263-7837\"",
                "shop": "\"houseware\"",
                "street_address": "\"12700 Shops Parkway\"",
                "website": "\"https://www.homegoods.com/us/store/stores/Bee+Cave-TX-78738/537/aboutstore\""
            },
            {
                "__id": 755,
                "address_city": "\"Osage Beach\"",
                "address_country": "\"US\"",
                "address_postcode": "\"65065\"",
                "address_state": "\"MO\"",
                "latitude": 38.1568908691406,
                "longitude": -92.6039733886719,
                "name": "\"Osage Beach\"",
                "opening_hours": "\"Mo-Sa 09:30-21:30; Su 10:00-20:00\"",
                "phone": "\"573-302-4810\"",
                "shop": "\"houseware\"",
                "street_address": "\"3942 Jr. Prewitt Pwy\"",
                "website": "\"https://www.homegoods.com/us/store/stores/Osage+Beach-MO-65065/332/aboutstore\""
            },
            {
                "__id": 837,
                "address_city": "\"Highland Village\"",
                "address_country": "\"US\"",
                "address_postcode": "\"75077\"",
                "address_state": "\"TX\"",
                "latitude": 33.0747909545898,
                "longitude": -97.0806045532227,
                "name": "\"Highland Village\"",
                "opening_hours": "\"Mo-Sa 09:30-21:30; Su 10:00-20:00\"",
                "phone": "\"972-317-4869\"",
                "shop": "\"houseware\"",
                "street_address": "\"2830 Village Parkway\"",
                "website": "\"https://www.homegoods.com/us/store/stores/Highland+Village-TX-75077/413/aboutstore\""
            },
            {
                "__id": 833,
                "address_city": "\"Noblesville\"",
                "address_country": "\"US\"",
                "address_postcode": "\"46060\"",
                "address_state": "\"IN\"",
                "latitude": 40.0375785827637,
                "longitude": -85.9918212890625,
                "name": "\"Noblesville\"",
                "opening_hours": "\"Mo-Sa 09:30-21:30; Su 10:00-20:00\"",
                "phone": "\"317-776-5987\"",
                "shop": "\"houseware\"",
                "street_address": "\"17120 Mercantile Blvd\"",
                "website": "\"https://www.homegoods.com/us/store/stores/Noblesville-IN-46060/622/aboutstore\""
            },
            {
                "__id": 403,
                "address_city": "\"Mashpee\"",
                "address_country": "\"US\"",
                "address_postcode": "\"02649\"",
                "address_state": "\"MA\"",
                "latitude": 41.6117248535156,
                "longitude": -70.4889526367188,
                "name": "\"Mashpee\"",
                "opening_hours": "\"Mo-Sa 09:30-21:30; Su 10:00-20:00\"",
                "phone": "\"508-477-0952\"",
                "shop": "\"houseware\"",
                "street_address": "\"15 Commercial Street\"",
                "website": "\"https://www.homegoods.com/us/store/stores/Mashpee-MA-02649/801/aboutstore\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "bmo_harris": {
        "__id": 218,
        "id": "bmo_harris",
        "name": "BMO Harris",
        "columnNames": "['address_city', 'address_country', 'address_housenumber', 'address_postcode', 'address_state', 'address_street', 'amenity', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'street_address', 'website']",
        "rowLength": 4187,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763632,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of bmo_harris locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 2809,
                "address_city": "\"Delson\"",
                "address_country": "\"CA\"",
                "address_housenumber": "\"58\"",
                "address_postcode": "\"J5B 0A1\"",
                "address_state": "\"QC\"",
                "address_street": "\"Route 132\"",
                "amenity": "\"atm\"",
                "latitude": 45.3869415,
                "longitude": -73.5468552,
                "name": "\"IGA #8070\"",
                "opening_hours": "\"\"",
                "phone": "\"\"",
                "street_address": "\"58 Route 132\"",
                "website": "\"https://branches.bmo.com/qc/delson/SY008070/\""
            },
            {
                "__id": 2567,
                "address_city": "\"Selbyville\"",
                "address_country": "\"US\"",
                "address_housenumber": "\"\"",
                "address_postcode": "\"19975\"",
                "address_state": "\"DE\"",
                "address_street": "\"\"",
                "amenity": "\"atm\"",
                "latitude": 38.4626881,
                "longitude": -75.2344833,
                "name": "\"Royal Farms\"",
                "opening_hours": "\"\"",
                "phone": "\"\"",
                "street_address": "\"38166 Dupont Blvd\"",
                "website": "\"https://branches.bmoharris.com/de/selbyville/RF0458/\""
            },
            {
                "__id": 1115,
                "address_city": "\"Abilene\"",
                "address_country": "\"US\"",
                "address_housenumber": "\"\"",
                "address_postcode": "\"79605\"",
                "address_state": "\"TX\"",
                "address_street": "\"\"",
                "amenity": "\"atm\"",
                "latitude": 32.4449702,
                "longitude": -99.8082762,
                "name": "\"Alon 7-Eleven\"",
                "opening_hours": "\"\"",
                "phone": "\"\"",
                "street_address": "\"6265 S 7st St\"",
                "website": "\"https://branches.bmoharris.com/tx/abilene/AB000023/\""
            },
            {
                "__id": 3757,
                "address_city": "\"KELOWNA\"",
                "address_country": "\"CA\"",
                "address_housenumber": "\"\"",
                "address_postcode": "\"V1Y9T1\"",
                "address_state": "\"0\"",
                "address_street": "\"1875 DILWORTH DRIVE\"",
                "amenity": "\"bank\"",
                "latitude": 49.879778,
                "longitude": -119.436813,
                "name": "\"ORCHARD PLACE\"",
                "opening_hours": "\"Mo-We 09:30-17:00; Th-Fr 09:30-19:00; Sa 09:00-16:00\"",
                "phone": "\"250-861-1626\"",
                "street_address": "\"1875 DILWORTH DRIVE\"",
                "website": "\"https://branches.bmo.com/0/kelowna/b0809/\""
            },
            {
                "__id": 2321,
                "address_city": "\"Shamokin\"",
                "address_country": "\"US\"",
                "address_housenumber": "\"\"",
                "address_postcode": "\"17872\"",
                "address_state": "\"PA\"",
                "address_street": "\"\"",
                "amenity": "\"atm\"",
                "latitude": 40.7907799,
                "longitude": -76.5570645,
                "name": "\"CVS\"",
                "opening_hours": "\"\"",
                "phone": "\"\"",
                "street_address": "\"12 W Independence St\"",
                "website": "\"https://branches.bmoharris.com/pa/shamokin/VV081894/\""
            },
            {
                "__id": 1349,
                "address_city": "\"Port Allen\"",
                "address_country": "\"US\"",
                "address_housenumber": "\"\"",
                "address_postcode": "\"70767\"",
                "address_state": "\"LA\"",
                "address_street": "\"\"",
                "amenity": "\"atm\"",
                "latitude": 30.4470987,
                "longitude": -91.2442104,
                "name": "\"Circle K\"",
                "opening_hours": "\"\"",
                "phone": "\"\"",
                "street_address": "\"133 Lobdell Hwy 415\"",
                "website": "\"https://branches.bmoharris.com/la/port-allen/CK026805/\""
            },
            {
                "__id": 2126,
                "address_city": "\"BRAMPTON\"",
                "address_country": "\"CA\"",
                "address_housenumber": "\"9505\"",
                "address_postcode": "\"L6X0B3\"",
                "address_state": "\"ON\"",
                "address_street": "\"MISSISSAUGA RD\"",
                "amenity": "\"atm\"",
                "latitude": 43.6578829,
                "longitude": -79.8167611,
                "name": "\"MISSISSAUGA WILLIAMS PARK\"",
                "opening_hours": "\"\"",
                "phone": "\"\"",
                "street_address": "\"9505 MISSISSAUGA RD\"",
                "website": "\"https://branches.bmo.com/on/brampton/abm3369/\""
            },
            {
                "__id": 1784,
                "address_city": "\"SCARBOROUGH\"",
                "address_country": "\"CA\"",
                "address_housenumber": "\"4271\"",
                "address_postcode": "\"M1S4G4\"",
                "address_state": "\"ON\"",
                "address_street": "\"SHEPPARD AVENUE EAST\"",
                "amenity": "\"atm\"",
                "latitude": 43.7855303,
                "longitude": -79.2756898,
                "name": "\"CATHAY PLAZA\"",
                "opening_hours": "\"\"",
                "phone": "\"\"",
                "street_address": "\"4271 SHEPPARD AVENUE EAST\"",
                "website": "\"https://branches.bmo.com/on/scarborough/abm3992/\""
            },
            {
                "__id": 2946,
                "address_city": "\"Shelbourne Falls\"",
                "address_country": "\"US\"",
                "address_housenumber": "\"\"",
                "address_postcode": "\"01370\"",
                "address_state": "\"MA\"",
                "address_street": "\"\"",
                "amenity": "\"atm\"",
                "latitude": 42.61389479,
                "longitude": -72.7390784,
                "name": "\"Shelbourne Falls Neighbors\"",
                "opening_hours": "\"\"",
                "phone": "\"\"",
                "street_address": "\"195 State St\"",
                "website": "\"https://branches.bmoharris.com/ma/shelbourne-falls/CD001894/\""
            },
            {
                "__id": 760,
                "address_city": "\"Nogales\"",
                "address_country": "\"US\"",
                "address_housenumber": "\"\"",
                "address_postcode": "\"85621\"",
                "address_state": "\"AZ\"",
                "address_street": "\"\"",
                "amenity": "\"atm\"",
                "latitude": 31.3642748,
                "longitude": -110.9404964,
                "name": "\"Safeway\"",
                "opening_hours": "\"\"",
                "phone": "\"\"",
                "street_address": "\"465 W Mariposa Rd\"",
                "website": "\"https://branches.bmoharris.com/az/nogales/SW001092/\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "manhattanbagels": {
        "__id": 219,
        "id": "manhattanbagels",
        "name": "Manhattan Bagel",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'takeaway', 'website']",
        "rowLength": 61,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763633,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of manhattanbagels locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 56,
                "address_city": "\"Feasterville Trevose\"",
                "address_country": "\"US\"",
                "address_full": "\"313 E Street Rd\"",
                "address_postcode": "\"19053\"",
                "address_state": "\"Pennsylvania\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"bagel\"",
                "latitude": 40.14552918185073,
                "longitude": -74.99456137418741,
                "name": "\"Manhattan Bagel Feasterville\"",
                "opening_hours": "\"Mo-Fr 05:30-02:00; Sa-Su 06:00-02:00\"",
                "phone": "\"(215) 942-9505\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.manhattanbagel.com/us/pa/feasterville-trevose/313-e-street-rd\""
            },
            {
                "__id": 2,
                "address_city": "\"Coral Springs\"",
                "address_country": "\"US\"",
                "address_full": "\"4610 N University Dr\"",
                "address_postcode": "\"33067\"",
                "address_state": "\"Florida\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"bagel\"",
                "latitude": 26.287523338258424,
                "longitude": -80.25203838944441,
                "name": "\"Manhattan Bagel Coral Springs\"",
                "opening_hours": "\"Mo-Su 06:00-02:00\"",
                "phone": "\"(954) 340-3233\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.manhattanbagel.com/us/fl/coral-springs/4610-n-university-dr\""
            },
            {
                "__id": 37,
                "address_city": "\"Mays Landing\"",
                "address_country": "\"US\"",
                "address_full": "\"2300 Wrangleboro Rd\"",
                "address_postcode": "\"08330\"",
                "address_state": "\"New Jersey\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"bagel\"",
                "latitude": 39.4516997,
                "longitude": -74.6333273,
                "name": "\"Manhattan Bagel Mays Landing\"",
                "opening_hours": "\"Mo-Su 07:00-06:00\"",
                "phone": "\"(609) 484-1366\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.manhattanbagel.com/us/nj/mays-landing/2300-wrangleboro-rd\""
            },
            {
                "__id": 44,
                "address_city": "\"Toms River\"",
                "address_country": "\"US\"",
                "address_full": "\"1822A Hooper Ave\"",
                "address_postcode": "\"08753\"",
                "address_state": "\"New Jersey\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"bagel\"",
                "latitude": 40.0107691,
                "longitude": -74.1476089,
                "name": "\"Manhattan Bagel Silverton\"",
                "opening_hours": "\"Mo-Fr 06:00-03:00; Sa-Su 06:00-02:00\"",
                "phone": "\"(732) 864-0200\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.manhattanbagel.com/us/nj/toms-river/1822a-hooper-ave\""
            },
            {
                "__id": 61,
                "address_city": "\"Allentown\"",
                "address_country": "\"US\"",
                "address_full": "\"3058 W Tilghman St\"",
                "address_postcode": "\"18104\"",
                "address_state": "\"Pennsylvania\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"bagel\"",
                "latitude": 40.5968154,
                "longitude": -75.5245442,
                "name": "\"Manhattan Bagel Allentown\"",
                "opening_hours": "\"Mo-Su 06:00-02:00\"",
                "phone": "\"(610) 433-8555\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.manhattanbagel.com/us/pa/allentown/3058-w-tilghman-st\""
            },
            {
                "__id": 1,
                "address_city": "\"Charlotte\"",
                "address_country": "\"US\"",
                "address_full": "\"8040 Providence Rd Ste 200\"",
                "address_postcode": "\"28277\"",
                "address_state": "\"North Carolina\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"bagel\"",
                "latitude": 35.0969583,
                "longitude": -80.7788431,
                "name": "\"Manhattan Bagel The Arboretum\"",
                "opening_hours": "\"Mo-Fr 06:00-03:00; Sa-Su 07:00-02:00\"",
                "phone": "\"(704) 541-0833\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.manhattanbagel.com/us/nc/charlotte/8040-providence-rd\""
            },
            {
                "__id": 60,
                "address_city": "\"Chalfont\"",
                "address_country": "\"US\"",
                "address_full": "\"5 E Butler Ave Ste B6\"",
                "address_postcode": "\"18914\"",
                "address_state": "\"Pennsylvania\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"bagel\"",
                "latitude": 40.28610201466019,
                "longitude": -75.20777821540827,
                "name": "\"Manhattan Bagel Chalfont\"",
                "opening_hours": "\"Mo-Su 06:00-02:00\"",
                "phone": "\"(215) 997-8100\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.manhattanbagel.com/us/pa/chalfont/5-e-butler-ave\""
            },
            {
                "__id": 40,
                "address_city": "\"Freehold\"",
                "address_country": "\"US\"",
                "address_full": "\"562 Park Ave\"",
                "address_postcode": "\"07728\"",
                "address_state": "\"New Jersey\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"bagel\"",
                "latitude": 40.24941854784276,
                "longitude": -74.27162021398544,
                "name": "\"Manhattan Bagel Freehold\"",
                "opening_hours": "\"Mo-Su 06:00-02:00\"",
                "phone": "\"(732) 294-9500\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.manhattanbagel.com/us/nj/freehold/562-park-ave\""
            },
            {
                "__id": 25,
                "address_city": "\"Philadelphia\"",
                "address_country": "\"US\"",
                "address_full": "\"13032 Bustleton Ave\"",
                "address_postcode": "\"19116\"",
                "address_state": "\"Pennsylvania\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"bagel\"",
                "latitude": 40.12461284614196,
                "longitude": -75.01553624868393,
                "name": "\"Manhattan Bagel Trevose/Bustleton Ave\"",
                "opening_hours": "\"Mo-Su 05:30-02:00\"",
                "phone": "\"(215) 677-6066\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.manhattanbagel.com/us/pa/philadelphia/13032-bustleton-ave\""
            },
            {
                "__id": 17,
                "address_city": "\"West Orange\"",
                "address_country": "\"US\"",
                "address_full": "\"581 Northfield Ave\"",
                "address_postcode": "\"07052\"",
                "address_state": "\"New Jersey\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"bagel\"",
                "latitude": 40.77001758981662,
                "longitude": -74.28234779038837,
                "name": "\"Manhattan Bagel West Orange\"",
                "opening_hours": "\"Mo-Su 06:00-03:00\"",
                "phone": "\"(973) 669-0160\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.manhattanbagel.com/us/nj/west-orange/581-northfield-ave\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "ihop": {
        "__id": 220,
        "id": "ihop",
        "name": "IHOP",
        "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'website']",
        "rowLength": 1675,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763634,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of ihop locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 487,
                "address_city": "\"Chesapeake\"",
                "address_full": "\"641 Battlefield Blvd N \"",
                "address_postcode": "\"23320\"",
                "address_state": "\"VA\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"breakfast;pancake\"",
                "latitude": 36.74087,
                "longitude": -76.24228,
                "name": "\"IHOP Breakfast Restaurants Near You at 641 Battlefield Blvd N\"",
                "opening_hours": "\"\"",
                "phone": "\"(757) 549-0030\"",
                "website": "\"https://restaurants.ihop.com/en-us/va/chesapeake/breakfast-641-battlefield-blvd-n-493\""
            },
            {
                "__id": 964,
                "address_city": "\"Douglasville\"",
                "address_full": "\"7401 Douglas Blvd \"",
                "address_postcode": "\"30135\"",
                "address_state": "\"GA\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"breakfast;pancake\"",
                "latitude": 33.72451,
                "longitude": -84.76614,
                "name": "\"IHOP Breakfast Restaurants Near You at 7401 Douglas Blvd\"",
                "opening_hours": "\"\"",
                "phone": "\"(770) 577-8272\"",
                "website": "\"https://restaurants.ihop.com/en-us/ga/douglasville/breakfast-7401-douglas-blvd-4428\""
            },
            {
                "__id": 745,
                "address_city": "\"Huntington Station\"",
                "address_full": "\"259B Old Walt Whitman Rd \"",
                "address_postcode": "\"11746\"",
                "address_state": "\"NY\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"breakfast;pancake\"",
                "latitude": 40.81904,
                "longitude": -73.41269,
                "name": "\"IHOP Breakfast Restaurants Near You at 259B Old Walt Whitman Rd\"",
                "opening_hours": "\"\"",
                "phone": "\"(631) 423-8760\"",
                "website": "\"https://restaurants.ihop.com/en-us/ny/huntington-station/breakfast-259b-old-walt-whitman-rd-4633\""
            },
            {
                "__id": 851,
                "address_city": "\"Cincinnati\"",
                "address_full": "\"4825 Marburg Ave \"",
                "address_postcode": "\"45209\"",
                "address_state": "\"OH\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"breakfast;pancake\"",
                "latitude": 39.164,
                "longitude": -84.42543,
                "name": "\"IHOP Breakfast Restaurants Near You at 4825 Marburg Ave\"",
                "opening_hours": "\"\"",
                "phone": "\"(513) 731-3666\"",
                "website": "\"https://restaurants.ihop.com/en-us/oh/cincinnati/breakfast-4825-marburg-ave-5515\""
            },
            {
                "__id": 963,
                "address_city": "\"Duluth\"",
                "address_full": "\"2178 Pleasant Hill Rd \"",
                "address_postcode": "\"30096\"",
                "address_state": "\"GA\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"breakfast;pancake\"",
                "latitude": 33.96024,
                "longitude": -84.135,
                "name": "\"IHOP Breakfast Restaurants Near You at 2178 Pleasant Hill Rd\"",
                "opening_hours": "\"\"",
                "phone": "\"(770) 623-9626\"",
                "website": "\"https://restaurants.ihop.com/en-us/ga/duluth/breakfast-2178-pleasant-hill-rd-483\""
            },
            {
                "__id": 701,
                "address_city": "\"Los Lunas\"",
                "address_full": "\"1580 Main St NW \"",
                "address_postcode": "\"87031\"",
                "address_state": "\"NM\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"breakfast;pancake\"",
                "latitude": 34.814784,
                "longitude": -106.75189,
                "name": "\"IHOP Breakfast Restaurants Near You at 1580 Main St NW\"",
                "opening_hours": "\"\"",
                "phone": "\"(505) 866-9280\"",
                "website": "\"https://restaurants.ihop.com/en-us/nm/los-lunas/breakfast-1580-main-st-nw-3470\""
            },
            {
                "__id": 1556,
                "address_city": "\"Riverview\"",
                "address_full": "\"11350 Bloomingdale Avenue \"",
                "address_postcode": "\"33578\"",
                "address_state": "\"FL\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"breakfast;pancake\"",
                "latitude": 27.8937649,
                "longitude": -82.3118771,
                "name": "\"IHOP Breakfast Restaurants Near You at 11350 Bloomingdale Avenue\"",
                "opening_hours": "\"\"",
                "phone": "\"(813) 436-0185\"",
                "website": "\"https://restaurants.ihop.com/en-us/fl/riverview/breakfast-11350-bloomingdale-avenue-360234\""
            },
            {
                "__id": 1475,
                "address_city": "\"Alhambra\"",
                "address_full": "\"701 S Fremont Ave \"",
                "address_postcode": "\"91803\"",
                "address_state": "\"CA\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"breakfast;pancake\"",
                "latitude": 34.08627,
                "longitude": -118.15217,
                "name": "\"IHOP Breakfast Restaurants Near You at 701 S Fremont Ave\"",
                "opening_hours": "\"\"",
                "phone": "\"(626) 284-0458\"",
                "website": "\"https://restaurants.ihop.com/en-us/ca/alhambra/breakfast-701-s-fremont-ave-742\""
            },
            {
                "__id": 164,
                "address_city": "\"Victoria\"",
                "address_full": "\"7606 Ne Zac Lentz Pkwy \"",
                "address_postcode": "\"77904\"",
                "address_state": "\"TX\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"breakfast;pancake\"",
                "latitude": 28.86436,
                "longitude": -96.99891,
                "name": "\"IHOP Breakfast Restaurants Near You at 7606 Ne Zac Lentz Pkwy\"",
                "opening_hours": "\"\"",
                "phone": "\"(361) 573-3360\"",
                "website": "\"https://restaurants.ihop.com/en-us/tx/victoria/breakfast-7606-ne-zac-lentz-pkwy-1452\""
            },
            {
                "__id": 298,
                "address_city": "\"Ennis\"",
                "address_full": "\"111 S. Interstate Hwy 45 \"",
                "address_postcode": "\"75119\"",
                "address_state": "\"TX\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"breakfast;pancake\"",
                "latitude": 32.33307,
                "longitude": -96.61567,
                "name": "\"IHOP Breakfast Restaurants Near You at 111 S. Interstate Hwy 45\"",
                "opening_hours": "\"\"",
                "phone": "\"(972) 878-0692\"",
                "website": "\"https://restaurants.ihop.com/en-us/tx/ennis/breakfast-111-s.-interstate-hwy-45-3004\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "xfinity": {
        "__id": 221,
        "id": "xfinity",
        "name": "Xfinity",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'shop', 'website']",
        "rowLength": 242,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763635,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of xfinity locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 170,
                "address_city": "\"North Wales\"",
                "address_country": "\"US\"",
                "address_full": "\"782 Bethlehem Pike\"",
                "address_postcode": "\"19454\"",
                "address_state": "\"PA\"",
                "latitude": 40.2394498,
                "longitude": -75.2441688,
                "name": "\"Xfinity Store by Comcast\"",
                "opening_hours": "\"Mo 11:00-17:00; Tu 9:00-19:00; We-Fr 10:00-19:00; Sa-Su 9:00-19:00\"",
                "shop": "\"telecommunication\"",
                "website": "\"https://www.xfinity.com/local/pa/north-wales/782-bethlehem-pike.html?geo=40.7127753,-74.0059728\""
            },
            {
                "__id": 205,
                "address_city": "\"Chattanooga\"",
                "address_country": "\"US\"",
                "address_full": "\"1926 Gunbarrel Rd\"",
                "address_postcode": "\"37421\"",
                "address_state": "\"TN\"",
                "latitude": 35.02916,
                "longitude": -85.15547,
                "name": "\"Xfinity Store by Comcast\"",
                "opening_hours": "\"Mo 12:00-17:00; Tu-Su 10:00-19:00\"",
                "shop": "\"telecommunication\"",
                "website": "\"https://www.xfinity.com/local/tn/chattanooga/1926-gunbarrel-rd.html?geo=35.5174913,-86.5804473\""
            },
            {
                "__id": 224,
                "address_city": "\"Bellevue\"",
                "address_country": "\"US\"",
                "address_full": "\"3630 Factoria Blvd SE\"",
                "address_postcode": "\"98006\"",
                "address_state": "\"WA\"",
                "latitude": 47.5777914,
                "longitude": -122.1675439,
                "name": "\"Xfinity Store by Comcast\"",
                "opening_hours": "\"Mo 11:00-18:00; Tu-Su 10:00-20:00\"",
                "shop": "\"telecommunication\"",
                "website": "\"https://www.xfinity.com/local/wa/bellevue/3630-factoria-blvd-se.html?geo=47.7510741,-120.7401386\""
            },
            {
                "__id": 41,
                "address_city": "\"Silver Spring\"",
                "address_country": "\"US\"",
                "address_full": "\"13529 Connecticut Ave\"",
                "address_postcode": "\"20906\"",
                "address_state": "\"MD\"",
                "latitude": 39.0772893,
                "longitude": -77.0797924,
                "name": "\"Xfinity Store by Comcast\"",
                "opening_hours": "\"Mo 12:00-17:00; Tu-Sa 10:00-19:00; Su 10:00-18:00\"",
                "shop": "\"telecommunication\"",
                "website": "\"https://www.xfinity.com/local/md/silver-spring/13529-connecticut-ave.html?geo=38.90477753,-77.01628876\""
            },
            {
                "__id": 13,
                "address_city": "\"Los Banos\"",
                "address_country": "\"US\"",
                "address_full": "\"115 West G St, Suite A\"",
                "address_postcode": "\"93635\"",
                "address_state": "\"CA\"",
                "latitude": 37.067603,
                "longitude": -120.856174,
                "name": "\"Comcast Service Center - Closed\"",
                "opening_hours": "\"Mo \"",
                "shop": "\"telecommunication\"",
                "website": "\"https://www.xfinity.com/local/ca/los-banos/115-west-g-st--suite-a.html?geo=36.778261,-119.4179324\""
            },
            {
                "__id": 68,
                "address_city": "\"Deptford\"",
                "address_country": "\"US\"",
                "address_full": "\"1750 Deptford Center Road\"",
                "address_postcode": "\"08096\"",
                "address_state": "\"NJ\"",
                "latitude": 39.829645007283126,
                "longitude": -75.09817642392228,
                "name": "\"Xfinity Store by Comcast\"",
                "opening_hours": "\"Mo 11:00-17:00; Tu-Su 10:00-20:00\"",
                "shop": "\"telecommunication\"",
                "website": "\"https://www.xfinity.com/local/nj/deptford/1750-deptford-center-road.html?geo=38.9108325,-75.52766989999999\""
            },
            {
                "__id": 216,
                "address_city": "\"Warrenton\"",
                "address_country": "\"US\"",
                "address_full": "\"245 East Street\"",
                "address_postcode": "\"20186\"",
                "address_state": "\"VA\"",
                "latitude": 38.710887,
                "longitude": -77.787687,
                "name": "\"Comcast Service Center\"",
                "opening_hours": "\"Mo 9:00-11:30; Tu 12:30-18:00; We 9:00-11:30; Th 12:30-18:00; Fr 9:00-11:30; Sa 12:30-18:00; Su 9:00-11:30\"",
                "shop": "\"telecommunication\"",
                "website": "\"https://www.xfinity.com/local/va/warrenton/245-east-street.html?geo=37.51290894,-78.69762421\""
            },
            {
                "__id": 101,
                "address_city": "\"Lafayette\"",
                "address_country": "\"US\"",
                "address_full": "\"3465 South St\"",
                "address_postcode": "\"47905\"",
                "address_state": "\"IN\"",
                "latitude": 40.4169726945096,
                "longitude": -86.85316303797373,
                "name": "\"Xfinity Store by Comcast\"",
                "opening_hours": "\"Mo 12:00-17:00; Tu-Su 9:00-19:00\"",
                "shop": "\"telecommunication\"",
                "website": "\"https://www.xfinity.com/local/in/lafayette/3465-south-st.html?geo=39.9199791,-86.28180695\""
            },
            {
                "__id": 119,
                "address_city": "\"Boston\"",
                "address_country": "\"US\"",
                "address_full": "\"350 Washington Street\"",
                "address_postcode": "\"02108\"",
                "address_state": "\"MA\"",
                "latitude": 42.3564376,
                "longitude": -71.0589754,
                "name": "\"Xfinity Store by Comcast\"",
                "opening_hours": "\"Mo 11:00-16:00; Tu-Su 9:00-19:00\"",
                "shop": "\"telecommunication\"",
                "website": "\"https://www.xfinity.com/local/ma/boston/350-washington-street.html?geo=42.17235565,-71.60500336\""
            },
            {
                "__id": 18,
                "address_city": "\"Farmington\"",
                "address_country": "\"US\"",
                "address_full": "\"500 Westfarms Mall\"",
                "address_postcode": "\"06032\"",
                "address_state": "\"CT\"",
                "latitude": 41.72318,
                "longitude": -72.7630599,
                "name": "\"Xfinity Kiosk by Comcast\"",
                "opening_hours": "\"Mo-Su 11:00-19:00\"",
                "shop": "\"telecommunication\"",
                "website": "\"https://www.xfinity.com/local/ct/farmington/500-westfarms-mall.html?geo=41.6032207,-73.087749\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "shake_shack": {
        "__id": 222,
        "id": "shake_shack",
        "name": "Shake Shack",
        "columnNames": "['address_full', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'takeaway', 'website']",
        "rowLength": 403,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763636,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of shake_shack locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 306,
                "address_full": "\"1st floor 360 Mall; Jassem Mohamed All Kharafi Road, Block 7; Al Zahraa South Surra,  13151  Kuwait\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 29.272222748639,
                "longitude": 47.992601512498,
                "name": "\"Kuwait, 360 Mall\"",
                "opening_hours": "\"\"",
                "phone": "\"\"",
                "takeaway": "\"yes\"",
                "website": "\"https://shakeshack.com/location/kuwait-360-mall\""
            },
            {
                "__id": 293,
                "address_full": "\"3 Gateway Drive #01-20/21/22/33 Westgate Singapore 608532; Singapore,    Singapore\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 1.29027,
                "longitude": 103.851959,
                "name": "\"Singapore, Westgate\"",
                "opening_hours": "\"\"",
                "phone": "\"\"",
                "takeaway": "\"yes\"",
                "website": "\"https://shakeshack.com/location/singapore-westgate\""
            },
            {
                "__id": 294,
                "address_full": "\"Corte de las Palmas, Alabang Town Center, Muntinlupa City; Alabang, Metro Manila,  1780  Philippines\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 14.408133,
                "longitude": 121.041466,
                "name": "\"Manila, Alabang Town Center\"",
                "opening_hours": "\"\"",
                "phone": "\"\"",
                "takeaway": "\"yes\"",
                "website": "\"https://shakeshack.com/location/manila-alabang-town-center\""
            },
            {
                "__id": 292,
                "address_full": "\"1400 Edwards Mill Rd,; Raleigh, North Carolina 27607\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 35.803329011455,
                "longitude": -78.7218018,
                "name": "\"PNC Arena\"",
                "opening_hours": "\"\"",
                "phone": "\"\"",
                "takeaway": "\"yes\"",
                "website": "\"https://shakeshack.com/location/pnc-arena-north-carolina\""
            },
            {
                "__id": 33,
                "address_full": "\"Seo-gu, \ub3d9\uad00 Daedeok-daero, 211 1\uce35; Daejeon,    South Korea\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 0.0,
                "longitude": 0.0,
                "name": "\"\"",
                "opening_hours": "\"yes\"",
                "phone": "\"https://shakeshack.com/location/daejeon-daejeon-dunsan\"",
                "takeaway": "\"\"",
                "website": "\"\""
            },
            {
                "__id": 77,
                "address_full": "\"2597 SW Cedar Hills Blvd; Beaverton, OR 97005\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 45.5014112,
                "longitude": -122.8058431,
                "name": "\"Cedar Hills\"",
                "opening_hours": "\"Mo-Th 11:00-22:00; Fr-Sa 11:00-23:00; Su 11:00-22:00\"",
                "phone": "\"9712048477\"",
                "takeaway": "\"yes\"",
                "website": "\"https://shakeshack.com/location/cedar-hills-or\""
            },
            {
                "__id": 398,
                "address_full": "\"Grand Avenue, The Avenues  Al-Rai, 5th Ring Road; (Bet. Ghazali & Mohammed Bin Alqasem); Kuwait City,    Kuwait\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 29.302725283384,
                "longitude": 47.936071898039,
                "name": "\"Kuwait, Avenues P3\"",
                "opening_hours": "\"\"",
                "phone": "\"(+965) 22283051\"",
                "takeaway": "\"yes\"",
                "website": "\"https://shakeshack.com/location/kuwait-city-grand-avenue\""
            },
            {
                "__id": 295,
                "address_full": "\"1F, Sillimro 330, Kwanak-Gu; Seoul,    South Korea\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 37.517235,
                "longitude": 127.047325,
                "name": "\"Seoul, Sillim\"",
                "opening_hours": "\"\"",
                "phone": "\"\"",
                "takeaway": "\"yes\"",
                "website": "\"https://shakeshack.com/location/seoul-sillim\""
            },
            {
                "__id": 396,
                "address_full": "\"Dalma Mall, Mussafah; Abu Dhabi,    UAE\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 24.33336752614,
                "longitude": 54.52401152864,
                "name": "\"Abu Dhabi, Dalma Mall\"",
                "opening_hours": "\"\"",
                "phone": "\"(+971) 26120750\"",
                "takeaway": "\"yes\"",
                "website": "\"https://shakeshack.com/location/abu-dhabi-dalma-mall\""
            },
            {
                "__id": 375,
                "address_full": "\"1955 Goyang-daero; Deogyang-gu, Goyang-si, Gyeonggi-do,    South Korea\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 37.652996540526,
                "longitude": 126.89592445044,
                "name": "\"Seoul, Starfield Goyang\"",
                "opening_hours": "\"\"",
                "phone": "\"82 031-5173-1068\"",
                "takeaway": "\"yes\"",
                "website": "\"https://shakeshack.com/location/seoul-south-korea-starfield-goyang\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "moneygram": {
        "__id": 223,
        "id": "moneygram",
        "name": "MoneyGram",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'is_in', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'website']",
        "rowLength": 66902,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763640,
        "runTime": 4,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of moneygram locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 5887,
                "address_city": "\"CIUDAD DE MEXICO\"",
                "address_country": "\"MX\"",
                "address_full": "\"TAXQUENA 2480\"",
                "address_postcode": "\"MoneyGram\"",
                "address_state": "\"\"",
                "amenity": "\"money_transfer\"",
                "is_in": "\"CADENA COMERCIAL OXXO SA DE CV - #10CUE5\"",
                "latitude": 19.3384304,
                "longitude": -99.1092803,
                "name": "\"MoneyGram\"",
                "opening_hours": "\"Mo-Su 03:00-24:00\"",
                "phone": "\"83892121\"",
                "website": "\"https://locations.moneygram.com/ciudad-de-mexico/ciudad-de-mexico/291508514\""
            },
            {
                "__id": 22708,
                "address_city": "\"TECOLUTLA\"",
                "address_country": "\"MX\"",
                "address_full": "\"CALLE ARCADIA S/N\"",
                "address_postcode": "\"MoneyGram\"",
                "address_state": "\"\"",
                "amenity": "\"money_transfer\"",
                "is_in": "\"OXXO - ARCADIA PAZ\"",
                "latitude": 20.46114,
                "longitude": -97.01545,
                "name": "\"MoneyGram\"",
                "opening_hours": "\"Mo-Su 03:00-24:00\"",
                "phone": "\"8183892121\"",
                "website": "\"https://locations.moneygram.com/vc/tecolutla/291258290\""
            },
            {
                "__id": 33263,
                "address_city": "\"MORELIA\"",
                "address_country": "\"MX\"",
                "address_full": "\"AV MADERO ORIENTE 524\"",
                "address_postcode": "\"MoneyGram\"",
                "address_state": "\"\"",
                "amenity": "\"money_transfer\"",
                "is_in": "\"ELEKTRA - #8442 - MORELIA - BA SYR MOREL\"",
                "latitude": 19.70358,
                "longitude": -101.18793,
                "name": "\"MoneyGram\"",
                "opening_hours": "\"Mo-Su 09:00-21:00\"",
                "phone": "\"5517207000\"",
                "website": "\"https://locations.moneygram.com/mc/morelia/291236930\""
            },
            {
                "__id": 1530,
                "address_city": "\"WACO\"",
                "address_country": "\"US\"",
                "address_full": "\"820 S 5TH ST\"",
                "address_postcode": "\"MoneyGram\"",
                "address_state": "\"TX\"",
                "amenity": "\"money_transfer\"",
                "is_in": "\"CVS - #10818\"",
                "latitude": 31.5508795,
                "longitude": -97.1243708,
                "name": "\"MoneyGram\"",
                "opening_hours": "\"Mo-Su 07:00-24:00\"",
                "phone": "\"(254) 296-0175\"",
                "website": "\"https://locations.moneygram.com/tx/waco/291128067\""
            },
            {
                "__id": 59517,
                "address_city": "\"TUPELO\"",
                "address_country": "\"US\"",
                "address_full": "\"702 W MAIN ST\"",
                "address_postcode": "\"MoneyGram\"",
                "address_state": "\"MS\"",
                "amenity": "\"money_transfer\"",
                "is_in": "\"CVS - #8992\"",
                "latitude": 34.2579428,
                "longitude": -88.7150342,
                "name": "\"MoneyGram\"",
                "opening_hours": "\"Mo-Su 08:00-21:00\"",
                "phone": "\"(662) 840-4818\"",
                "website": "\"https://locations.moneygram.com/ms/tupelo/633163197\""
            },
            {
                "__id": 58022,
                "address_city": "\"CHAMBERLAIN\"",
                "address_country": "\"CA\"",
                "address_full": "\"211 FIRST ST\"",
                "address_postcode": "\"MoneyGram\"",
                "address_state": "\"\"",
                "amenity": "\"money_transfer\"",
                "is_in": "\"CHAMBERLAIN PO\"",
                "latitude": 50.94018,
                "longitude": -104.49349,
                "name": "\"MoneyGram\"",
                "opening_hours": "\"Mo-We 08:30-15:00; Th 08:30-15:30; Fr 08:30-15:00; Sa-Su 00:00-24:00\"",
                "phone": "\"(306) 638-6330\"",
                "website": "\"https://locations.moneygram.com/sk/chamberlain/574285892\""
            },
            {
                "__id": 15516,
                "address_city": "\"CULIACAN\"",
                "address_country": "\"MX\"",
                "address_full": "\"BLV PASEO DE LOS GANADEROS 1216-A\"",
                "address_postcode": "\"MoneyGram\"",
                "address_state": "\"\"",
                "amenity": "\"money_transfer\"",
                "is_in": "\"OXXO - IBERICA CUL\"",
                "latitude": 24.76005,
                "longitude": -107.40635,
                "name": "\"MoneyGram\"",
                "opening_hours": "\"Mo-Su 03:00-24:00\"",
                "phone": "\"8183892121\"",
                "website": "\"https://locations.moneygram.com/si/culiacan/291246745\""
            },
            {
                "__id": 52794,
                "address_city": "\"BROOKLYN\"",
                "address_country": "\"US\"",
                "address_full": "\"552 ATLANTIC AVE\"",
                "address_postcode": "\"MoneyGram\"",
                "address_state": "\"NY\"",
                "amenity": "\"money_transfer\"",
                "is_in": "\"ATLANTIC SHIPPING CENTER INC\"",
                "latitude": 40.6846404,
                "longitude": -73.9795129,
                "name": "\"MoneyGram\"",
                "opening_hours": "\"Mo-Sa 09:00-20:00; Su 09:00-19:00\"",
                "phone": "\"(718) 643-1409\"",
                "website": "\"https://locations.moneygram.com/ny/brooklyn/291204575\""
            },
            {
                "__id": 35692,
                "address_city": "\"METEPEC\"",
                "address_country": "\"MX\"",
                "address_full": "\"AV. MANUEL J CLOUTHIER 620\"",
                "address_postcode": "\"MoneyGram\"",
                "address_state": "\"\"",
                "amenity": "\"money_transfer\"",
                "is_in": "\"DINERO EXPRESS - METEPEC - PPR CLOUTHIER\"",
                "latitude": 19.2665407,
                "longitude": -99.5992344,
                "name": "\"MoneyGram\"",
                "opening_hours": "\"Mo-Su 09:00-21:00\"",
                "phone": "\"5517208177\"",
                "website": "\"https://locations.moneygram.com/mx/metepec/291427502\""
            },
            {
                "__id": 57408,
                "address_city": "\"LEVIS\"",
                "address_country": "\"CA\"",
                "address_full": "\"105-6700 RUE SAINT-GEORGES\"",
                "address_postcode": "\"MoneyGram\"",
                "address_state": "\"\"",
                "amenity": "\"money_transfer\"",
                "is_in": "\"GESTION DANIEL DUBE PHARMA INC  - CANADA\"",
                "latitude": 46.8153499,
                "longitude": -71.1723039,
                "name": "\"MoneyGram\"",
                "opening_hours": "\"Mo-Fr 09:00-19:00; Sa 12:00-17:00; Su 00:00-24:00\"",
                "phone": "\"(418) 837-9363\"",
                "website": "\"https://locations.moneygram.com/qc/levis/489301320\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "smilebrands": {
        "__id": 224,
        "id": "smilebrands",
        "name": "Smile Brands",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'website']",
        "rowLength": 654,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763642,
        "runTime": 2,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of smilebrands locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 441,
                "address_city": "\"Orlando\"",
                "address_country": "\"US\"",
                "address_full": "\"785 N Alafaya Trail\"",
                "address_postcode": "\"32828\"",
                "address_state": "\"FL\"",
                "latitude": 28.557182,
                "longitude": -81.20704,
                "name": "\"Bright Now! Dental Waterford Lakes\"",
                "website": "\"https://www.brightnow.com/dentist-near-me/orlando-dentist/27030/\""
            },
            {
                "__id": 360,
                "address_city": "\"San Antonio\"",
                "address_country": "\"US\"",
                "address_full": "\"4400 Fredericksburg Road\"",
                "address_postcode": "\"78201\"",
                "address_state": "\"TX\"",
                "latitude": 29.4888316,
                "longitude": -98.5481544,
                "name": "\"Monarch Dental San Antonio &#8211; 4400 Fredericksburg Road\"",
                "website": "\"https://www.monarchdental.com/dentist-near-me/san-antonio-dentist/32020/\""
            },
            {
                "__id": 542,
                "address_city": "\"Kennewick\"",
                "address_country": "\"US\"",
                "address_full": "\"1220 N Columbia Center Boulevard\"",
                "address_postcode": "\"99336\"",
                "address_state": "\"WA\"",
                "latitude": 46.2241587,
                "longitude": -119.2214792,
                "name": "\"Bright Now! Dental Kennewick\"",
                "website": "\"https://www.brightnow.com/dentist-near-me/kennewick-dentist/17050\""
            },
            {
                "__id": 472,
                "address_city": "\"Henderson\"",
                "address_country": "\"US\"",
                "address_full": "\"1001 Whitney Ranch Drive\"",
                "address_postcode": "\"89014\"",
                "address_state": "\"NV\"",
                "latitude": 36.0787337,
                "longitude": -115.053596,
                "name": "\"Whitney Ranch Dental\"",
                "website": "\"https://whitneyranchdental.com\""
            },
            {
                "__id": 89,
                "address_city": "\"Schaumburg\"",
                "address_country": "\"US\"",
                "address_full": "\"929 W Wise Road\"",
                "address_postcode": "\"60193\"",
                "address_state": "\"IL\"",
                "latitude": 42.004463,
                "longitude": -88.102875,
                "name": "\"One Good Smile\"",
                "website": "\"https://onegoodsmile.com\""
            },
            {
                "__id": 263,
                "address_city": "\"Huber Heights\"",
                "address_country": "\"US\"",
                "address_full": "\"6188 Chambersburg Road\"",
                "address_postcode": "\"30510\"",
                "address_state": "\"OH\"",
                "latitude": 39.847126,
                "longitude": -84.11795,
                "name": "\"Bright Now! Dental Huber Heights\"",
                "website": "\"https://www.brightnow.com/dentist-near-me/huber-heights-dentist/24030/\""
            },
            {
                "__id": 445,
                "address_city": "\"Raritan\"",
                "address_country": "\"US\"",
                "address_full": "\"64 E Somerset St\"",
                "address_postcode": "\"08869\"",
                "address_state": "\"NJ\"",
                "latitude": 40.569065,
                "longitude": -74.628172,
                "name": "\"Mondovi Dental Raritan\"",
                "website": "\"https://mondovidental.com/locations/new-jersey-dental-centers/raritan-nj/\""
            },
            {
                "__id": 543,
                "address_city": "\"San Juan Capistrano\"",
                "address_country": "\"US\"",
                "address_full": "\"31876 Del Obispo Street\"",
                "address_postcode": "\"92675\"",
                "address_state": "\"CA\"",
                "latitude": 33.49787,
                "longitude": -117.666595,
                "name": "\"Aspire Dental San Juan Capistrano\"",
                "website": "\"https://aspiredentalsjc.com\""
            },
            {
                "__id": 412,
                "address_city": "\"Palm Harbor\"",
                "address_country": "\"US\"",
                "address_full": "\"324 E Lake Road\"",
                "address_postcode": "\"34685\"",
                "address_state": "\"FL\"",
                "latitude": 28.061384,
                "longitude": -82.7094668,
                "name": "\"Bright Now! Dental Palm Harbor\"",
                "website": "\"https://www.brightnow.com/dentist-near-me/palm-harbor-dentist/21070/\""
            },
            {
                "__id": 253,
                "address_city": "\"Castle Rock\"",
                "address_country": "\"US\"",
                "address_full": "\"188 Founders Parkway\"",
                "address_postcode": "\"80104\"",
                "address_state": "\"CO\"",
                "latitude": 39.38073,
                "longitude": -104.82879,
                "name": "\"Bright Now! Dental Castle Rock\"",
                "website": "\"https://www.brightnow.com/dentist-near-me/castle-rock-dentist/41030/\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "hardees_carlsjr": {
        "__id": 225,
        "id": "hardees_carlsjr",
        "name": "Hardee's / Carl's Jr.",
        "columnNames": "['address_city', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'street_address', 'takeaway', 'website']",
        "rowLength": 2785,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763643,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of hardees_carlsjr locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 207,
                "address_city": "\"Fredericksburg\"",
                "address_postcode": "\"22408\"",
                "address_state": "\"VA\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 38.28166647301467,
                "longitude": -77.45313605220956,
                "name": "\"11825 Tidewater Trail | Fredericksburg\"",
                "opening_hours": "\"Mo-Sa 05:00-22:00; Su 06:00-22:00\"",
                "phone": "\"(540) 374-1358\"",
                "street_address": "\"11825 Tidewater Trail\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.hardees.com/va/fredericksburg/11825-tidewater-trail\""
            },
            {
                "__id": 1296,
                "address_city": "\"Harrisburg\"",
                "address_postcode": "\"62946\"",
                "address_state": "\"IL\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 37.73861861879452,
                "longitude": -88.53326954607479,
                "name": "\"700 E Poplar | Harrisburg\"",
                "opening_hours": "\"Mo-We 05:00-24:00; Th 05:00-23:00; Fr-Sa 00:00-24:00; Su 06:00-23:00\"",
                "phone": "\"(618) 252-6990\"",
                "street_address": "\"700 E Poplar\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.hardees.com/il/harrisburg/700-e-poplar\""
            },
            {
                "__id": 1535,
                "address_city": "\"Pensacola\"",
                "address_postcode": "\"32506\"",
                "address_state": "\"FL\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 30.397369873458324,
                "longitude": -87.39990201351446,
                "name": "\"12275 Lillian Hwy | Pensacola\"",
                "opening_hours": "\"Mo 04:00-23:00; Tu-Th 04:00-22:00; Fr-Sa 04:00-23:00; Su 05:00-22:00\"",
                "phone": "\"(850) 912-4140\"",
                "street_address": "\"12275 Lillian Hwy\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.hardees.com/fl/pensacola/12275-lillian-hwy\""
            },
            {
                "__id": 364,
                "address_city": "\"Hillsboro\"",
                "address_postcode": "\"37342\"",
                "address_state": "\"TN\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 35.4189727,
                "longitude": -86.0149396,
                "name": "\"1165 Aedc Road | Hillsboro\"",
                "opening_hours": "\"Mo-Tu 00:00-24:00; We-Su 06:00-23:00\"",
                "phone": "\"(931) 253-6001\"",
                "street_address": "\"1165 Aedc Road\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.hardees.com/tn/hillsboro/1165-aedc-road\""
            },
            {
                "__id": 2628,
                "address_city": "\"Citrus Heights\"",
                "address_postcode": "\"95610\"",
                "address_state": "\"CA\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 38.70368193165172,
                "longitude": -121.27103296180184,
                "name": "\"7570 Sunrise Blvd | Citrus Heights\"",
                "opening_hours": "\"Mo-Th 06:00-24:00; Fr-Sa 06:00-01:00; Su 06:00-24:00\"",
                "phone": "\"(916) 726-0800\"",
                "street_address": "\"7570 Sunrise Blvd\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.carlsjr.com/ca/citrus-heights/7570-sunrise-blvd\""
            },
            {
                "__id": 1061,
                "address_city": "\"South Williamson\"",
                "address_postcode": "\"41503\"",
                "address_state": "\"KY\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 37.65587881187656,
                "longitude": -82.2710009435092,
                "name": "\"378 Southside Mall | South Williamson\"",
                "opening_hours": "\"Mo 05:00-23:00; Tu-Sa 05:00-21:00; Su 07:00-21:00\"",
                "phone": "\"(606) 237-1026\"",
                "street_address": "\"378 Southside Mall\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.hardees.com/ky/south-williamson/378-southside-mall\""
            },
            {
                "__id": 1283,
                "address_city": "\"Moline\"",
                "address_postcode": "\"61265\"",
                "address_state": "\"IL\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 41.4893899322268,
                "longitude": -90.51395087594949,
                "name": "\"2301 16th Street | Moline\"",
                "opening_hours": "\"Mo-Fr 06:00-22:00; Sa-Su 06:00-16:00\"",
                "phone": "\"(309) 797-5610\"",
                "street_address": "\"2301 16th Street\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.hardees.com/il/moline/2301-16th-street\""
            },
            {
                "__id": 1523,
                "address_city": "\"Santa Rosa\"",
                "address_postcode": "\"32459\"",
                "address_state": "\"FL\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 30.374497107352767,
                "longitude": -86.22799163252351,
                "name": "\"3000 US Highway 98 W | Santa Rosa\"",
                "opening_hours": "\"Mo 05:00-23:00; Tu-Su 05:00-22:00\"",
                "phone": "\"(850) 660-6572\"",
                "street_address": "\"3000 US Highway 98 W\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.hardees.com/fl/santa-rosa/3000-us-highway-98-w\""
            },
            {
                "__id": 2337,
                "address_city": "\"Oxnard\"",
                "address_postcode": "\"93036\"",
                "address_state": "\"CA\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 34.226766737671745,
                "longitude": -119.17814288587294,
                "name": "\"2301 N Oxnard Blvd | Oxnard\"",
                "opening_hours": "\"Mo-Tu 00:00-24:00; We-Su 06:00-23:00\"",
                "phone": "\"(805) 485-0202\"",
                "street_address": "\"2301 N Oxnard Blvd\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.carlsjr.com/ca/oxnard/2301-n-oxnard-blvd\""
            },
            {
                "__id": 290,
                "address_city": "\"Rutledge\"",
                "address_postcode": "\"37861\"",
                "address_state": "\"TN\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "latitude": 36.2785370264244,
                "longitude": -83.52779542270184,
                "name": "\"7685 Rutledge Pike | Rutledge\"",
                "opening_hours": "\"Mo 06:00-23:00; Tu-Fr 06:00-21:00; Sa 04:00-24:00; Su 05:00-22:00\"",
                "phone": "\"(865) 828-8424\"",
                "street_address": "\"7685 Rutledge Pike\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.hardees.com/tn/rutledge/7685-rutledge-pike\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "dsw": {
        "__id": 226,
        "id": "dsw",
        "name": "DSW",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'website']",
        "rowLength": 535,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763644,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of dsw locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 244,
                "address_city": "\"Matthews\"",
                "address_country": "\"\"",
                "address_full": "\"10015 E. Independence Blvd.\"",
                "address_postcode": "\"28105\"",
                "address_state": "\"NC\"",
                "latitude": 35.13201806787896,
                "longitude": -80.7079535722732,
                "name": "\"DSW\"",
                "opening_hours": "\"Mo-Sa 10:00-20:00; Su 12:00-18:00\"",
                "phone": "\"(704) 321-8165\"",
                "shop": "\"shoes\"",
                "website": "\"https://stores.dsw.com/usa/nc/matthews/dsw-designer-shoe-warehouse-windsor-square.html\""
            },
            {
                "__id": 204,
                "address_city": "\"Bronx\"",
                "address_country": "\"\"",
                "address_full": "\"2134 Bartow Ave.\"",
                "address_postcode": "\"10475\"",
                "address_state": "\"NY\"",
                "latitude": 40.86716854167582,
                "longitude": -73.82527112960815,
                "name": "\"DSW\"",
                "opening_hours": "\"Mo-Sa 10:00-21:00; Su 10:00-19:00\"",
                "phone": "\"(347) 614-1490\"",
                "shop": "\"shoes\"",
                "website": "\"https://stores.dsw.com/usa/ny/bronx/dsw-designer-shoe-warehouse-bay-plaza.html\""
            },
            {
                "__id": 288,
                "address_city": "\"Kalamazoo\"",
                "address_country": "\"\"",
                "address_full": "\"5110 West Main Street\"",
                "address_postcode": "\"49009\"",
                "address_state": "\"MI\"",
                "latitude": 42.298051,
                "longitude": -85.651521,
                "name": "\"DSW\"",
                "opening_hours": "\"Mo-Sa 10:00-21:00; Su 10:00-19:00\"",
                "phone": "\"(269) 344-3757\"",
                "shop": "\"shoes\"",
                "website": "\"https://stores.dsw.com/usa/mi/kalamazoo/dsw-designer-shoe-warehouse-maple-hill-mall.html\""
            },
            {
                "__id": 503,
                "address_city": "\"Costa Mesa\"",
                "address_country": "\"\"",
                "address_full": "\"901-B South Coast Drive\"",
                "address_postcode": "\"92626\"",
                "address_state": "\"CA\"",
                "latitude": 33.688958,
                "longitude": -117.895744,
                "name": "\"DSW\"",
                "opening_hours": "\"Mo-Sa 10:00-20:00; Su 11:00-18:00\"",
                "phone": "\"(714) 668-9631\"",
                "shop": "\"shoes\"",
                "website": "\"https://stores.dsw.com/usa/ca/costamesa/dsw-designer-shoe-warehouse-metro-point-at-south-coast.html\""
            },
            {
                "__id": 349,
                "address_city": "\"Plainfield\"",
                "address_country": "\"\"",
                "address_full": "\"314 Metropolis Mile\"",
                "address_postcode": "\"46168\"",
                "address_state": "\"IN\"",
                "latitude": 39.713046,
                "longitude": -86.358985,
                "name": "\"DSW\"",
                "opening_hours": "\"Mo-Sa 10:00-21:00; Su 10:00-19:00\"",
                "phone": "\"(317) 838-8379\"",
                "shop": "\"shoes\"",
                "website": "\"https://stores.dsw.com/usa/in/plainfield/dsw-designer-shoe-warehouse-the-shops-at-perry-crossing.html\""
            },
            {
                "__id": 428,
                "address_city": "\"Miami\"",
                "address_country": "\"\"",
                "address_full": "\"11401 Northwest 12th Street\"",
                "address_postcode": "\"33172\"",
                "address_state": "\"FL\"",
                "latitude": 25.7881747,
                "longitude": -80.3830841,
                "name": "\"DSW\"",
                "opening_hours": "\"Mo-Sa 10:00-21:00; Su 11:00-20:00\"",
                "phone": "\"(305) 418-2002\"",
                "shop": "\"shoes\"",
                "website": "\"https://stores.dsw.com/usa/fl/miami/dsw-designer-shoe-warehouse-dolphin-mall.html\""
            },
            {
                "__id": 63,
                "address_city": "\"Spring\"",
                "address_country": "\"\"",
                "address_full": "\"6545 N. Grand Parkway W.\"",
                "address_postcode": "\"77389\"",
                "address_state": "\"TX\"",
                "latitude": 30.0879766,
                "longitude": -95.5192768,
                "name": "\"DSW\"",
                "opening_hours": "\"Mo-Sa 10:00-21:00; Su 10:00-20:00\"",
                "phone": "\"(832) 637-1002\"",
                "shop": "\"shoes\"",
                "website": "\"https://stores.dsw.com/usa/tx/spring/dsw-designer-shoe-warehouse-grand-parkway-marketplace.html\""
            },
            {
                "__id": 507,
                "address_city": "\"Brentwood\"",
                "address_country": "\"\"",
                "address_full": "\"2555 Sand Creek Road\"",
                "address_postcode": "\"94513\"",
                "address_state": "\"CA\"",
                "latitude": 37.94665957190666,
                "longitude": -121.73776924610138,
                "name": "\"DSW\"",
                "opening_hours": "\"Mo-Sa 10:00-20:00; Su 10:00-18:00\"",
                "phone": "\"(925) 513-8752\"",
                "shop": "\"shoes\"",
                "website": "\"https://stores.dsw.com/usa/ca/brentwood/dsw-designer-shoe-warehouse-streets-of-brentwood.html\""
            },
            {
                "__id": 497,
                "address_city": "\"Fremont\"",
                "address_country": "\"\"",
                "address_full": "\"43530 Christy Street\"",
                "address_postcode": "\"94538\"",
                "address_state": "\"CA\"",
                "latitude": 37.5052406,
                "longitude": -121.969166,
                "name": "\"DSW\"",
                "opening_hours": "\"Mo-Sa 10:00-20:00; Su 11:00-19:00\"",
                "phone": "\"(510) 623-0310\"",
                "shop": "\"shoes\"",
                "website": "\"https://stores.dsw.com/usa/ca/fremont/dsw-designer-shoe-warehouse-pacific-commons.html\""
            },
            {
                "__id": 459,
                "address_city": "\"Longmont\"",
                "address_country": "\"\"",
                "address_full": "\"180 Ken Pratt Boulevard\"",
                "address_postcode": "\"80501\"",
                "address_state": "\"CO\"",
                "latitude": 40.153798,
                "longitude": -105.095625,
                "name": "\"DSW\"",
                "opening_hours": "\"Mo-Sa 10:00-20:00; Su 10:00-19:00\"",
                "phone": "\"(303) 702-5191\"",
                "shop": "\"shoes\"",
                "website": "\"https://stores.dsw.com/usa/co/longmont/dsw-designer-shoe-warehouse-harvest-junction.html\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "freebirds": {
        "__id": 227,
        "id": "freebirds",
        "name": "Freebirds World Burrito",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'phone', 'street_address', 'takeaway']",
        "rowLength": 60,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763645,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of freebirds locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 36,
                "address_city": "\"Dallas\"",
                "address_country": "\"US\"",
                "address_postcode": "\"75206\"",
                "address_state": "\"TX\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"mexican\"",
                "latitude": 32.852267,
                "longitude": -96.769165,
                "name": "\"Old Town (OT)\"",
                "phone": "\"(214) 265-9992\"",
                "street_address": "\"5500 Greenville Ave., Ste. 209\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 58,
                "address_city": "\"Allen\"",
                "address_country": "\"US\"",
                "address_postcode": "\"75002\"",
                "address_state": "\"TX\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"mexican\"",
                "latitude": 33.1266803,
                "longitude": -96.6592219,
                "name": "\"Villages at Allen (VA)\"",
                "phone": "\"(972) 678-4040\"",
                "street_address": "\"190 E Stacy Rd., Ste. 1400\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 41,
                "address_city": "\"Fort Worth\"",
                "address_country": "\"US\"",
                "address_postcode": "\"76132\"",
                "address_state": "\"TX\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"mexican\"",
                "latitude": 32.6760809,
                "longitude": -97.4028506,
                "name": "\"Ridge Rock (RR)\"",
                "phone": "\"(817) 294-7241\"",
                "street_address": "\"4965 Overton Ridge Blvd, Ste. 300\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 54,
                "address_city": "\"Temple\"",
                "address_country": "\"US\"",
                "address_postcode": "\"76504\"",
                "address_state": "\"TX\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"mexican\"",
                "latitude": 31.077713,
                "longitude": -97.3669357,
                "name": "\"Temple\"",
                "phone": "\"(254) 773-8374\"",
                "street_address": "\"2320 S. 31st street Ste 100\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 23,
                "address_city": "\"Plano\"",
                "address_country": "\"US\"",
                "address_postcode": "\"75024\"",
                "address_state": "\"TX\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"mexican\"",
                "latitude": 33.0904799,
                "longitude": -96.8047695,
                "name": "\"Headquarters Village (HV)\"",
                "phone": "\"(214) 705-6808\"",
                "street_address": "\"8240 Preston Rd, Ste. 115\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 38,
                "address_city": "\"Hurst\"",
                "address_country": "\"US\"",
                "address_postcode": "\"76053\"",
                "address_state": "\"TX\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"mexican\"",
                "latitude": 32.8233102,
                "longitude": -97.1994438,
                "name": "\"Pipeline Village (PV)\"",
                "phone": "\"(817) 282-8100\"",
                "street_address": "\"1301 W Pipeline Rd\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 9,
                "address_city": "\"Lewisville\"",
                "address_country": "\"US\"",
                "address_postcode": "\"75056\"",
                "address_state": "\"TX\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"mexican\"",
                "latitude": 33.0625229,
                "longitude": -96.8876515,
                "name": "\"Castle Hills - Josey Lane\"",
                "phone": "\"(214) 469-1449\"",
                "street_address": "\"6225 N. Josey Lane, #124\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 59,
                "address_city": "\"Waco\"",
                "address_country": "\"US\"",
                "address_postcode": "\"76710\"",
                "address_state": "\"TX\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"mexican\"",
                "latitude": 31.5242891,
                "longitude": -97.1710501,
                "name": "\"Waco (707)\"",
                "phone": "\"(254) 741-0060\"",
                "street_address": "\"120 N New Road\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 51,
                "address_city": "\"Spring\"",
                "address_country": "\"US\"",
                "address_postcode": "\"77386\"",
                "address_state": "\"TX\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"mexican\"",
                "latitude": 30.1122872,
                "longitude": -95.38735,
                "name": "\"Spring Rayford\"",
                "phone": "\"(281) 205-0131\"",
                "street_address": "\"2825 Riley Fuzzel Road\"",
                "takeaway": "\"yes\""
            },
            {
                "__id": 49,
                "address_city": "\"Austin\"",
                "address_country": "\"US\"",
                "address_postcode": "\"78704\"",
                "address_state": "\"TX\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"mexican\"",
                "latitude": 30.2559773,
                "longitude": -97.7469354,
                "name": "\"South Congress (SC)\"",
                "phone": "\"(512) 462-3512\"",
                "street_address": "\"515 South Congress, Ste. 101\"",
                "takeaway": "\"yes\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "chickfila": {
        "__id": 228,
        "id": "chickfila",
        "name": "Chick-fil-A",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'image', 'latitude', 'longitude', 'name', 'street_address', 'takeaway', 'website']",
        "rowLength": 138,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763646,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of chickfila locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 103,
                "address_city": "\"Manhattan\"",
                "address_country": "\"US\"",
                "address_postcode": "\"66506\"",
                "address_state": "\"KS\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"chicken\"",
                "image": "\"https://www.chick-fil-a.com/-/media/images/cfacom/default-images/seo/history_freestanding.ashx\"",
                "latitude": 39.18809,
                "longitude": -96.58266985,
                "name": "\"Kansas State University\"",
                "street_address": "\"918 N 17th St 121 K- State Student Union\"",
                "takeaway": "\"yes\"",
                "website": "\"https://www.chick-fil-a.com/locations/ks/kansas-state-university\""
            },
            {
                "__id": 1,
                "address_city": "\"Canton\"",
                "address_country": "\"US\"",
                "address_postcode": "\"30114\"",
                "address_state": "\"GA\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"chicken\"",
                "image": "\"https://www.chick-fil-a.com/-/media/images/cfacom/default-images/seo/history_freestanding.ashx\"",
                "latitude": 34.236141,
                "longitude": -84.453449,
                "name": "\"Canton Marketplace\"",
                "street_address": "\"2048 Cumming Hwy\"",
                "takeaway": "\"yes\"",
                "website": "\"https://www.chick-fil-a.com/locations/ga/canton-marketplace\""
            },
            {
                "__id": 45,
                "address_city": "\"Bangor\"",
                "address_country": "\"US\"",
                "address_postcode": "\"04401\"",
                "address_state": "\"ME\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"chicken\"",
                "image": "\"https://www.chick-fil-a.com/-/media/images/cfacom/default-images/seo/history_freestanding.ashx\"",
                "latitude": 44.83,
                "longitude": -68.7533,
                "name": "\"Bangor\"",
                "street_address": "\"605 Stillwater Ave\"",
                "takeaway": "\"yes\"",
                "website": "\"https://www.chick-fil-a.com/locations/me/bangor\""
            },
            {
                "__id": 108,
                "address_city": "\"Hutchinson\"",
                "address_country": "\"US\"",
                "address_postcode": "\"67501\"",
                "address_state": "\"KS\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"chicken\"",
                "image": "\"https://www.chick-fil-a.com/-/media/images/cfacom/default-images/seo/history_freestanding.ashx\"",
                "latitude": 38.071746,
                "longitude": -97.894305,
                "name": "\"Hutchinson\"",
                "street_address": "\"1809 E 17th Ave\"",
                "takeaway": "\"yes\"",
                "website": "\"https://www.chick-fil-a.com/locations/ks/hutchinson\""
            },
            {
                "__id": 29,
                "address_city": "\"Carson City\"",
                "address_country": "\"US\"",
                "address_postcode": "\"89703\"",
                "address_state": "\"NV\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"chicken\"",
                "image": "\"https://www.chick-fil-a.com/-/media/images/cfacom/default-images/seo/history_freestanding.ashx\"",
                "latitude": 39.124562,
                "longitude": -119.772344,
                "name": "\"Carson Valley\"",
                "street_address": "\"4751 Cochise Street\"",
                "takeaway": "\"yes\"",
                "website": "\"https://www.chick-fil-a.com/locations/nv/carson-valley\""
            },
            {
                "__id": 33,
                "address_city": "\"Las Vegas\"",
                "address_country": "\"US\"",
                "address_postcode": "\"89109\"",
                "address_state": "\"NV\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"chicken\"",
                "image": "\"https://www.chick-fil-a.com/-/media/images/cfacom/default-images/seo/history_freestanding.ashx\"",
                "latitude": 36.109442,
                "longitude": -115.172411,
                "name": "\"Strip at Harmon In-Line\"",
                "street_address": "\"3667 Las Vegas Blvd S\"",
                "takeaway": "\"yes\"",
                "website": "\"https://www.chick-fil-a.com/locations/nv/strip-at-harmon-in-line\""
            },
            {
                "__id": 76,
                "address_city": "\"Indianapolis\"",
                "address_country": "\"US\"",
                "address_postcode": "\"46250\"",
                "address_state": "\"IN\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"chicken\"",
                "image": "\"https://www.chick-fil-a.com/-/media/images/cfacom/default-images/seo/history_freestanding.ashx\"",
                "latitude": 39.90923,
                "longitude": -86.063842,
                "name": "\"Castleton Square\"",
                "street_address": "\"6020 E 82nd St Ste 1012\"",
                "takeaway": "\"yes\"",
                "website": "\"https://www.chick-fil-a.com/locations/in/castleton-square\""
            },
            {
                "__id": 136,
                "address_city": "\"Chicago\"",
                "address_country": "\"US\"",
                "address_postcode": "\"60606\"",
                "address_state": "\"IL\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"chicken\"",
                "image": "\"https://www.chick-fil-a.com/-/media/images/cfacom/default-images/seo/history_freestanding.ashx\"",
                "latitude": 41.88,
                "longitude": -87.644,
                "name": "\"Chicago Union Station\"",
                "street_address": "\"225 S Canal St\"",
                "takeaway": "\"yes\"",
                "website": "\"https://www.chick-fil-a.com/locations/il/chicago-union-station\""
            },
            {
                "__id": 53,
                "address_city": "\"Indianapolis\"",
                "address_country": "\"US\"",
                "address_postcode": "\"46204\"",
                "address_state": "\"IN\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"chicken\"",
                "image": "\"https://www.chick-fil-a.com/-/media/images/cfacom/default-images/seo/history_freestanding.ashx\"",
                "latitude": 39.764,
                "longitude": -86.156,
                "name": "\"Gainbridge Fieldhouse\"",
                "street_address": "\"125 S. Pennsylvania Street\"",
                "takeaway": "\"yes\"",
                "website": "\"https://www.chick-fil-a.com/locations/in/gainbridge-fieldhouse\""
            },
            {
                "__id": 80,
                "address_city": "\"Evansville\"",
                "address_country": "\"US\"",
                "address_postcode": "\"47715\"",
                "address_state": "\"IN\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"chicken\"",
                "image": "\"https://www.chick-fil-a.com/-/media/images/cfacom/default-images/seo/history_freestanding.ashx\"",
                "latitude": 37.984665,
                "longitude": -87.495096,
                "name": "\"Eastland Mall (IN)\"",
                "street_address": "\"800 N Green River Rd\"",
                "takeaway": "\"yes\"",
                "website": "\"https://www.chick-fil-a.com/locations/in/eastland-mall-in\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "wrenkitchens_gb": {
        "__id": 229,
        "id": "wrenkitchens_gb",
        "name": "Wren Kitchens Great Britain",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'image', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'website', 'latitude', 'longitude']",
        "rowLength": 106,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763647,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of wrenkitchens_gb locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 20,
                "address_city": "\"Milton Keynes\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"MK6 1AJ\"",
                "address_state": "\"Buckinghamshire\"",
                "image": "\"https://image.wrenkitchens.com/location/milton_keynes.jpg?q=100&amp;w=0&amp;h=0&amp;fit=contain&amp;s=6c792b3a0bfa8824ed7621d526178736\"",
                "name": "52.03117032688494",
                "opening_hours": "-0.7660782675794436",
                "phone": "\"Wren Kitchens Milton Keynes showroom\"",
                "shop": "\"Mo-Fr 09:00-20:00; Sa 09:00-18:00; Su 10:30-17:00\"",
                "street_address": "\"01908 768330\"",
                "website": "\"kitchen\"",
                "latitude": 0.0,
                "longitude": 0.0
            },
            {
                "__id": 30,
                "address_city": "\"York\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"YO30 4XZ\"",
                "address_state": "\"Yorkshire\"",
                "image": "\"https://image.wrenkitchens.com/location/york.jpg?q=100&amp;w=0&amp;h=0&amp;fit=contain&amp;s=337cc8d3d4a329a9a567c8ff933ec043\"",
                "name": "53.99224149059244",
                "opening_hours": "-1.102189396477323",
                "phone": "\"Wren Kitchens York showroom\"",
                "shop": "\"Mo-Fr 09:00-20:00; Sa 09:00-18:00; Su 10:30-17:00\"",
                "street_address": "\"01904 232313\"",
                "website": "\"kitchen\"",
                "latitude": 0.0,
                "longitude": 0.0
            },
            {
                "__id": 91,
                "address_city": "\"Thurrock\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"RM20 1WN\"",
                "address_state": "\"Essex\"",
                "image": "\"https://image.wrenkitchens.com/location/lakeside.jpg?q=100&amp;w=0&amp;h=0&amp;fit=contain&amp;s=e3c3556338e62b11c6a3e40712873eae\"",
                "name": "51.48729747369266",
                "opening_hours": "0.2763976917372115",
                "phone": "\"Wren Kitchens Lakeside showroom\"",
                "shop": "\"Mo-Fr 09:00-20:00; Sa 09:00-18:00; Su 10:30-17:00\"",
                "street_address": "\"01708 209209\"",
                "website": "\"kitchen\"",
                "latitude": 0.0,
                "longitude": 0.0
            },
            {
                "__id": 14,
                "address_city": "\"Enfield\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"EN1 3RW\"",
                "address_state": "\"Middlesex\"",
                "image": "\"https://image.wrenkitchens.com/location/enfield.jpg?q=70&amp;w=0&amp;h=0&amp;s=da2999df6b1bdf1892dae38f03c442cb\"",
                "name": "51.65432149999999",
                "opening_hours": "-0.0582188",
                "phone": "\"Wren Kitchens Enfield showroom\"",
                "shop": "\"Mo-Fr 09:00-20:00; Sa 09:00-18:00; Su 10:30-17:00\"",
                "street_address": "\"02032 920045\"",
                "website": "\"kitchen\"",
                "latitude": 0.0,
                "longitude": 0.0
            },
            {
                "__id": 24,
                "address_city": "\"North Shields\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"NE289PD\"",
                "address_state": "\"Tyne and Wear\"",
                "image": "\"https://image.wrenkitchens.com/location/north_shields.jpg?q=100&amp;w=0&amp;h=0&amp;fit=contain&amp;s=6d2df022788c2b0d54d2e9eee1658201\"",
                "name": "\"Wren Kitchens North Shields showroom\"",
                "opening_hours": "\"Mo-Fr 09:00-20:00; Sa 09:00-18:00; Su 10:30-17:00\"",
                "phone": "\"01913 009019\"",
                "shop": "\"kitchen\"",
                "street_address": "\"Silverlink Point Retail Park,\"",
                "website": "\"https://www.wrenkitchens.com/showrooms/north-shields\"",
                "latitude": 0.0,
                "longitude": 0.0
            },
            {
                "__id": 48,
                "address_city": "\"Erdington\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"B24 9QB\"",
                "address_state": "\"West Midlands\"",
                "image": "\"https://image.wrenkitchens.com/location/erdington.jpg?q=100&amp;w=0&amp;h=0&amp;fit=contain&amp;s=c971243661fdf7d7de76dba6ea43af6d\"",
                "name": "52.51774462358135",
                "opening_hours": "-1.803511441378239",
                "phone": "\"Wren Kitchens Erdington showroom\"",
                "shop": "\"Mo-Fr 09:00-20:00; Sa 09:00-18:00; Su 10:30-17:00\"",
                "street_address": "\"01216 474445\"",
                "website": "\"kitchen\"",
                "latitude": 0.0,
                "longitude": 0.0
            },
            {
                "__id": 98,
                "address_city": "\"Basildon\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"SS14 3AF\"",
                "address_state": "\"Essex\"",
                "image": "\"https://image.wrenkitchens.com/location/basildon.jpg?q=100&amp;w=0&amp;h=0&amp;fit=contain&amp;s=9931aaa36fc88cee7682bdba2306021c\"",
                "name": "51.58111388008034",
                "opening_hours": "0.4438098970802301",
                "phone": "\"Wren Kitchens Basildon showroom\"",
                "shop": "\"Mo-Fr 09:00-20:00; Sa 09:00-18:00; Su 10:30-17:00\"",
                "street_address": "\"01268 921846\"",
                "website": "\"kitchen\"",
                "latitude": 0.0,
                "longitude": 0.0
            },
            {
                "__id": 13,
                "address_city": "\"Greenwich\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"SE77FR\"",
                "address_state": "\"London\"",
                "image": "\"https://image.wrenkitchens.com/location/greenwich.jpg?q=70&amp;w=0&amp;h=0&amp;s=48fbe601e70ed17f45e0779daaace650\"",
                "name": "\"Wren Kitchens Greenwich showroom\"",
                "opening_hours": "\"Mo-Fr 09:00-20:00; Sa 09:00-18:00; Su 10:30-17:00\"",
                "phone": "\"02080 903898\"",
                "shop": "\"kitchen\"",
                "street_address": "\"Unit B, Brocklebank Retail Park, Brocklebank Road\"",
                "website": "\"https://www.wrenkitchens.com/showrooms/greenwich\"",
                "latitude": 0.0,
                "longitude": 0.0
            },
            {
                "__id": 5,
                "address_city": "\"Ashton-under-Lyne\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"OL7 0DN\"",
                "address_state": "\"Greater Manchester\"",
                "image": "\"https://image.wrenkitchens.com/location/ashton_under_lyne.jpg?q=100&amp;w=0&amp;h=0&amp;fit=contain&amp;s=e87b90b0065fba01b5d9cd05b2c06a0d\"",
                "name": "53.47702769966748",
                "opening_hours": "-2.126791810650708",
                "phone": "\"Wren Kitchens Ashton-under-Lyne showroom\"",
                "shop": "\"Mo-Fr 09:00-20:00; Sa 09:00-18:00; Su 10:30-17:00\"",
                "street_address": "\"01618 709706\"",
                "website": "\"kitchen\"",
                "latitude": 0.0,
                "longitude": 0.0
            },
            {
                "__id": 104,
                "address_city": "\"Leicester\"",
                "address_country": "\"GB\"",
                "address_postcode": "\"LE11SG\"",
                "address_state": "\"Leicestershire\"",
                "image": "\"https://image.wrenkitchens.com/location/leicester.jpg?q=100&amp;w=0&amp;h=0&amp;fit=contain&amp;s=dc8891fa971c25a685ef0659aae5683e\"",
                "name": "52.63672585482485",
                "opening_hours": "-1.121236879538361",
                "phone": "\"Wren Kitchens Leicester showroom\"",
                "shop": "\"Mo-Fr 09:00-20:00; Sa 09:00-18:00; Su 10:30-17:00\"",
                "street_address": "\"01163 667920\"",
                "website": "\"kitchen\"",
                "latitude": 0.0,
                "longitude": 0.0
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "half_price_books": {
        "__id": 230,
        "id": "half_price_books",
        "name": "Half Price Books",
        "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'phone', 'shop', 'street_address', 'website']",
        "rowLength": 130,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763647,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of half_price_books locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 74,
                "address_city": "\"Nashville\"",
                "address_full": "\"21 White Bridge Pike, Nashville, TN 37205\"",
                "address_postcode": "\"37205\"",
                "address_state": "\"TN\"",
                "latitude": 36.128207,
                "longitude": -86.853745,
                "name": "\"HPB Belle Meade\"",
                "phone": "\"(615) 352-4005\"",
                "shop": "\"books\"",
                "street_address": "\"21 White Bridge Pike\"",
                "website": "\"https://www.hpb.com/133\""
            },
            {
                "__id": 93,
                "address_city": "\"Cincinnati\"",
                "address_full": "\"8118 Montgomery Rd, Cincinnati, OH 45236\"",
                "address_postcode": "\"45236\"",
                "address_state": "\"OH\"",
                "latitude": 39.2035046,
                "longitude": -84.3690051,
                "name": "\"HPB Kenwood Galleria\"",
                "phone": "\"(513) 891-7170\"",
                "shop": "\"books\"",
                "street_address": "\"8118 Montgomery Rd\"",
                "website": "\"https://www.hpb.com/045\""
            },
            {
                "__id": 27,
                "address_city": "\"Everett\"",
                "address_full": "\"1321 SE Everett Mall Way, Everett, WA 98208\"",
                "address_postcode": "\"98208\"",
                "address_state": "\"WA\"",
                "latitude": 47.91285,
                "longitude": -122.215212,
                "name": "\"HPB Everett\"",
                "phone": "\"(425) 290-9924\"",
                "shop": "\"books\"",
                "street_address": "\"1321 SE Everett Mall Way\"",
                "website": "\"https://www.hpb.com/064\""
            },
            {
                "__id": 118,
                "address_city": "\"Bloomingdale\"",
                "address_full": "\"366 W Army Trail Rd, Bloomingdale, IL 60108\"",
                "address_postcode": "\"60108\"",
                "address_state": "\"IL\"",
                "latitude": 41.9390559,
                "longitude": -88.1120095,
                "name": "\"HPB Bloomingdale\"",
                "phone": "\"(630) 893-8907\"",
                "shop": "\"books\"",
                "street_address": "\"366 W Army Trail Rd\"",
                "website": "\"https://www.hpb.com/090\""
            },
            {
                "__id": 119,
                "address_city": "\"Algonquin\"",
                "address_full": "\"2226 S Randall Rd, Algonquin, IL 60102\"",
                "address_postcode": "\"60102\"",
                "address_state": "\"IL\"",
                "latitude": 42.1475256,
                "longitude": -88.3370692,
                "name": "\"HPB Algonquin\"",
                "phone": "\"(847) 458-2669\"",
                "shop": "\"books\"",
                "street_address": "\"2226 S Randall Rd\"",
                "website": "\"https://www.hpb.com/117\""
            },
            {
                "__id": 98,
                "address_city": "\"St Charles\"",
                "address_full": "\"2107 Zumbehl Rd, St Charles, MO 63303\"",
                "address_postcode": "\"63303\"",
                "address_state": "\"MO\"",
                "latitude": 38.7839224,
                "longitude": -90.5355995,
                "name": "\"HPB St Charles\"",
                "phone": "\"(636) 724-1005\"",
                "shop": "\"books\"",
                "street_address": "\"2107 Zumbehl Rd\"",
                "website": "\"https://www.hpb.com/127\""
            },
            {
                "__id": 82,
                "address_city": "\"Reynoldsburg\"",
                "address_full": "\"8107 E Broad St, Reynoldsburg, OH 43068\"",
                "address_postcode": "\"43068\"",
                "address_state": "\"OH\"",
                "latitude": 39.9859358,
                "longitude": -82.7849096,
                "name": "\"HPB Reynoldsburg\"",
                "phone": "\"(614) 755-4110\"",
                "shop": "\"books\"",
                "street_address": "\"8107 E Broad St\"",
                "website": "\"https://www.hpb.com/019\""
            },
            {
                "__id": 120,
                "address_city": "\"Meridian\"",
                "address_full": "\"3677 E. Fairview Ave., Meridian, ID 83642\"",
                "address_postcode": "\"83642\"",
                "address_state": "\"ID\"",
                "latitude": 43.6179107,
                "longitude": -116.3483086,
                "name": "\"HPB Meridian Crossroads\"",
                "phone": "\"(208) 846-8997\"",
                "shop": "\"books\"",
                "street_address": "\"3677 E. Fairview Ave.\"",
                "website": "\"https://www.hpb.com/132\""
            },
            {
                "__id": 35,
                "address_city": "\"San Antonio\"",
                "address_full": "\"125 NW Loop 410, San Antonio, TX 78216\"",
                "address_postcode": "\"78216\"",
                "address_state": "\"TX\"",
                "latitude": 29.5233448,
                "longitude": -98.4946962,
                "name": "\"HPB North Central\"",
                "phone": "\"(210) 349-1429\"",
                "shop": "\"books\"",
                "street_address": "\"125 NW Loop 410\"",
                "website": "\"https://www.hpb.com/029\""
            },
            {
                "__id": 48,
                "address_city": "\"Irving\"",
                "address_full": "\"7631 N MacArthur Blvd, Irving, TX 75063\"",
                "address_postcode": "\"75063\"",
                "address_state": "\"TX\"",
                "latitude": 32.909839,
                "longitude": -96.9588359,
                "name": "\"HPB Las Colinas\"",
                "phone": "\"(469) 420-9978\"",
                "shop": "\"books\"",
                "street_address": "\"7631 N MacArthur Blvd\"",
                "website": "\"https://www.hpb.com/046\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "albertsons": {
        "__id": 231,
        "id": "albertsons",
        "name": "Albertsons",
        "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'address_unit', 'latitude', 'location_name', 'longitude', 'name', 'opening_hours', 'phone', 'website']",
        "rowLength": 1334,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763648,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of albertsons locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 226,
                "address_city": "\"Shrub Oak\"",
                "address_full": "\"1366 E Main St\"",
                "address_postcode": "\"10588\"",
                "address_state": "\"NY\"",
                "address_unit": "\"\"",
                "latitude": 41.32765881175091,
                "location_name": "\"\"",
                "longitude": -73.83672282860971,
                "name": "\"ACME Markets\"",
                "opening_hours": "\"Mo-Sa 07:00-23:00; Su 07:00-21:00\"",
                "phone": "\"+19145283620\"",
                "website": "\"https://local.acmemarkets.com/ny/shrub-oak/1366-e-main-st.html\""
            },
            {
                "__id": 480,
                "address_city": "\"Pearland\"",
                "address_full": "\"10228 W Broadway St\"",
                "address_postcode": "\"77584\"",
                "address_state": "\"TX\"",
                "address_unit": "\"\"",
                "latitude": 29.554314966171145,
                "location_name": "\"\"",
                "longitude": -95.37921712563735,
                "name": "\"Randalls\"",
                "opening_hours": "\"Mo-Su 06:00-23:00\"",
                "phone": "\"+12815040273\"",
                "website": "\"https://local.randalls.com/tx/pearland/10228-w-broadway-st.html\""
            },
            {
                "__id": 825,
                "address_city": "\"Newark\"",
                "address_full": "\"1 University Plaza\"",
                "address_postcode": "\"19702\"",
                "address_state": "\"DE\"",
                "address_unit": "\"\"",
                "latitude": 39.66692063486427,
                "location_name": "\"\"",
                "longitude": -75.67192912101746,
                "name": "\"ACME Markets\"",
                "opening_hours": "\"Mo-Su 06:00-22:00\"",
                "phone": "\"+13027389509\"",
                "website": "\"https://local.acmemarkets.com/de/newark/1-university-plaza.html\""
            },
            {
                "__id": 506,
                "address_city": "\"Montpelier\"",
                "address_full": "\"2 Main St\"",
                "address_postcode": "\"05602\"",
                "address_state": "\"VT\"",
                "address_unit": "\"\"",
                "latitude": 44.2588542,
                "location_name": "\"\"",
                "longitude": -72.5775085,
                "name": "\"Shaw's\"",
                "opening_hours": "\"Mo-Su 07:00-21:00\"",
                "phone": "\"+18022237587\"",
                "website": "\"https://local.shaws.com/vt/montpelier/2-main-st.html\""
            },
            {
                "__id": 353,
                "address_city": "\"Pampa\"",
                "address_full": "\"1420 N Hobart St\"",
                "address_postcode": "\"79065\"",
                "address_state": "\"TX\"",
                "address_unit": "\"\"",
                "latitude": 35.550845120324745,
                "location_name": "\"\"",
                "longitude": -100.97048431634903,
                "name": "\"United Supermarkets\"",
                "opening_hours": "\"Mo-Su 07:00-22:00\"",
                "phone": "\"+18066696115\"",
                "website": "\"https://local.unitedsupermarkets.com/tx/pampa/1420-n-hobart-st.html\""
            },
            {
                "__id": 1277,
                "address_city": "\"Livingston\"",
                "address_full": "\"2120 W Park St\"",
                "address_postcode": "\"59047\"",
                "address_state": "\"MT\"",
                "address_unit": "\"\"",
                "latitude": 45.64225909360213,
                "location_name": "\"\"",
                "longitude": -110.5757275785736,
                "name": "\"Albertsons\"",
                "opening_hours": "\"Mo-Su 06:00-23:00\"",
                "phone": "\"+14062221177\"",
                "website": "\"https://local.albertsons.com/mt/livingston/2120-w-park-st.html\""
            },
            {
                "__id": 72,
                "address_city": "\"Temescal Valley\"",
                "address_full": "\"11800 De Palma Rd\"",
                "address_postcode": "\"92883\"",
                "address_state": "\"CA\"",
                "address_unit": "\"\"",
                "latitude": 33.74743948601739,
                "location_name": "\"\"",
                "longitude": -117.45317371221972,
                "name": "\"Vons\"",
                "opening_hours": "\"Mo-Su 06:00-23:00\"",
                "phone": "\"+19516030809\"",
                "website": "\"https://local.vons.com/ca/temescal-valley/11800-de-palma-rd.html\""
            },
            {
                "__id": 1102,
                "address_city": "\"Las Vegas\"",
                "address_full": "\"5500 Boulder Hwy\"",
                "address_postcode": "\"89122\"",
                "address_state": "\"NV\"",
                "address_unit": "\"\"",
                "latitude": 36.1016909,
                "location_name": "\"\"",
                "longitude": -115.0546281,
                "name": "\"Albertsons\"",
                "opening_hours": "\"Mo-Su 06:00-22:00\"",
                "phone": "\"+17024356777\"",
                "website": "\"https://local.albertsons.com/nv/las-vegas/5500-boulder-hwy.html\""
            },
            {
                "__id": 192,
                "address_city": "\"Los Angeles\"",
                "address_full": "\"1430 S Fairfax Ave\"",
                "address_postcode": "\"90019\"",
                "address_state": "\"CA\"",
                "address_unit": "\"\"",
                "latitude": 34.05051526037608,
                "location_name": "\"\"",
                "longitude": -118.36625526745911,
                "name": "\"Vons\"",
                "opening_hours": "\"Mo-Su 06:00-23:00\"",
                "phone": "\"+13239399335\"",
                "website": "\"https://local.vons.com/ca/los-angeles/1430-s-fairfax-ave.html\""
            },
            {
                "__id": 1244,
                "address_city": "\"Payette\"",
                "address_full": "\"405 S Main St\"",
                "address_postcode": "\"83661\"",
                "address_state": "\"ID\"",
                "address_unit": "\"\"",
                "latitude": 44.07016242405431,
                "location_name": "\"\"",
                "longitude": -116.9340991973877,
                "name": "\"Albertsons\"",
                "opening_hours": "\"Mo-Su 07:00-23:00\"",
                "phone": "\"+12086429075\"",
                "website": "\"https://local.albertsons.com/id/payette/405-s-main-st.html\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "deltaco": {
        "__id": 232,
        "id": "deltaco",
        "name": "Delta Co.",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'image', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'street_address', 'takeaway', 'website']",
        "rowLength": 591,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763649,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of deltaco locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 248,
                "address_city": "\"Temecula\"",
                "address_country": "\"US\"",
                "address_postcode": "\"92591\"",
                "address_state": "\"CA\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"mexican\"",
                "image": "\"https://dynl.mktgcdn.com/p/_TPSI3CWDz_xigNgboH2NluTNQbVW6LQ2pPEIUXhiFI/200x200.png\"",
                "latitude": 33.50536106432953,
                "longitude": -117.15052602468602,
                "name": "\"Del Taco Temecula  27453 Ynez Rd\"",
                "opening_hours": "\"Mo-Sa 06:00-01:00; Su 08:00-01:00\"",
                "phone": "\"(951) 676-9373\"",
                "street_address": "\"27453 Ynez Rd\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.deltaco.com/us/ca/temecula/27453-ynez-rd\""
            },
            {
                "__id": 464,
                "address_city": "\"Glendora\"",
                "address_country": "\"US\"",
                "address_postcode": "\"91740\"",
                "address_state": "\"CA\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"mexican\"",
                "image": "\"https://dynl.mktgcdn.com/p/_TPSI3CWDz_xigNgboH2NluTNQbVW6LQ2pPEIUXhiFI/200x200.png\"",
                "latitude": 34.13625304107115,
                "longitude": -117.87174358414984,
                "name": "\"Del Taco Glendora  445 W Foothill Blvd\"",
                "opening_hours": "\"Mo-Su 06:00-01:00\"",
                "phone": "\"(626) 335-5515\"",
                "street_address": "\"445 W Foothill Blvd\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.deltaco.com/us/ca/glendora/445-w-foothill-blvd\""
            },
            {
                "__id": 399,
                "address_city": "\"Mission Viejo\"",
                "address_country": "\"US\"",
                "address_postcode": "\"92692\"",
                "address_state": "\"CA\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"mexican\"",
                "image": "\"https://dynl.mktgcdn.com/p/_TPSI3CWDz_xigNgboH2NluTNQbVW6LQ2pPEIUXhiFI/200x200.png\"",
                "latitude": 33.547599961655585,
                "longitude": -117.671974181657,
                "name": "\"Del Taco Mission Viejo  26241 Avery Pkwy\"",
                "opening_hours": "\"Mo-Su 04:00-03:59\"",
                "phone": "\"(949) 347-0776\"",
                "street_address": "\"26241 Avery Pkwy\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.deltaco.com/us/ca/mission-viejo/26241-avery-pkwy\""
            },
            {
                "__id": 455,
                "address_city": "\"Hesperia\"",
                "address_country": "\"US\"",
                "address_postcode": "\"92345\"",
                "address_state": "\"CA\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"mexican\"",
                "image": "\"https://dynl.mktgcdn.com/p/_TPSI3CWDz_xigNgboH2NluTNQbVW6LQ2pPEIUXhiFI/200x200.png\"",
                "latitude": 34.423782000139404,
                "longitude": -117.31877239508916,
                "name": "\"Del Taco Hesperia  15797 Main St\"",
                "opening_hours": "\"Mo-Su 04:00-03:59\"",
                "phone": "\"(760) 244-8931\"",
                "street_address": "\"15797 Main St\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.deltaco.com/us/ca/hesperia/15797-main-st\""
            },
            {
                "__id": 588,
                "address_city": "\"Chandler\"",
                "address_country": "\"US\"",
                "address_postcode": "\"85286\"",
                "address_state": "\"AZ\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"mexican\"",
                "image": "\"https://dynl.mktgcdn.com/p/_TPSI3CWDz_xigNgboH2NluTNQbVW6LQ2pPEIUXhiFI/200x200.png\"",
                "latitude": 33.29094848714742,
                "longitude": -111.83971716395149,
                "name": "\"Del Taco Chandler  1035 S. Arizona Avenue\"",
                "opening_hours": "\"Mo-Th 07:00-01:00; Fr 07:00-03:00; Sa 08:00-03:00; Su 08:00-01:00\"",
                "phone": "\"(480) 855-7141\"",
                "street_address": "\"1035 S. Arizona Avenue\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.deltaco.com/us/az/chandler/1035-s-arizona-avenue\""
            },
            {
                "__id": 228,
                "address_city": "\"Victorville\"",
                "address_country": "\"US\"",
                "address_postcode": "\"92395\"",
                "address_state": "\"CA\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"mexican\"",
                "image": "\"https://dynl.mktgcdn.com/p/_TPSI3CWDz_xigNgboH2NluTNQbVW6LQ2pPEIUXhiFI/200x200.png\"",
                "latitude": 34.52587492009377,
                "longitude": -117.30355350868759,
                "name": "\"Del Taco Victorville  15070 7th St\"",
                "opening_hours": "\"Mo-Su 04:00-03:59\"",
                "phone": "\"(760) 245-1900\"",
                "street_address": "\"15070 7th St\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.deltaco.com/us/ca/victorville/15070-7th-st\""
            },
            {
                "__id": 365,
                "address_city": "\"Orange\"",
                "address_country": "\"US\"",
                "address_postcode": "\"92865\"",
                "address_state": "\"CA\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"mexican\"",
                "image": "\"https://dynl.mktgcdn.com/p/_TPSI3CWDz_xigNgboH2NluTNQbVW6LQ2pPEIUXhiFI/200x200.png\"",
                "latitude": 33.832465985905294,
                "longitude": -117.83589977422238,
                "name": "\"Del Taco Orange  2607 N Tustin St\"",
                "opening_hours": "\"Mo 05:00-04:00; Tu-Fr 04:00-03:59; Sa 04:00-24:00; Su 05:00-24:00\"",
                "phone": "\"(714) 974-5575\"",
                "street_address": "\"2607 N Tustin St\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.deltaco.com/us/ca/orange/2607-n-tustin-st\""
            },
            {
                "__id": 22,
                "address_city": "\"West Valley City\"",
                "address_country": "\"US\"",
                "address_postcode": "\"84128\"",
                "address_state": "\"UT\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"mexican\"",
                "image": "\"https://dynl.mktgcdn.com/p/_TPSI3CWDz_xigNgboH2NluTNQbVW6LQ2pPEIUXhiFI/200x200.png\"",
                "latitude": 40.70628711649022,
                "longitude": -112.02447962042157,
                "name": "\"Del Taco West Valley City  2963 S. 5600 West\"",
                "opening_hours": "\"Mo-Su 04:00-03:59\"",
                "phone": "\"(801) 966-8050\"",
                "street_address": "\"2963 S. 5600 West\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.deltaco.com/us/ut/west-valley-city/2963-s-5600-west\""
            },
            {
                "__id": 591,
                "address_city": "\"Anthem\"",
                "address_country": "\"US\"",
                "address_postcode": "\"85086\"",
                "address_state": "\"AZ\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"mexican\"",
                "image": "\"https://dynl.mktgcdn.com/p/_TPSI3CWDz_xigNgboH2NluTNQbVW6LQ2pPEIUXhiFI/200x200.png\"",
                "latitude": 33.87046160557544,
                "longitude": -112.14794772826156,
                "name": "\"Del Taco Anthem  4115 W Anthem Way\"",
                "opening_hours": "\"Mo-Th 08:00-21:30; Fr-Sa 08:00-22:00; Su 08:00-21:30\"",
                "phone": "\"(623) 465-9820\"",
                "street_address": "\"4115 W Anthem Way\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.deltaco.com/us/az/anthem/4115-w-anthem-way\""
            },
            {
                "__id": 71,
                "address_city": "\"Oklahoma City\"",
                "address_country": "\"US\"",
                "address_postcode": "\"73127\"",
                "address_state": "\"OK\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"mexican\"",
                "image": "\"https://dynl.mktgcdn.com/p/_TPSI3CWDz_xigNgboH2NluTNQbVW6LQ2pPEIUXhiFI/200x200.png\"",
                "latitude": 35.46475338262187,
                "longitude": -97.61848997805897,
                "name": "\"Del Taco Oklahoma City  5845 W. Reno Ave.\"",
                "opening_hours": "\"Mo-Th 07:00-02:00; Fr-Sa 07:00-03:00; Su 07:00-02:00\"",
                "phone": "\"(405) 945-6526\"",
                "street_address": "\"5845 W. Reno Ave.\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.deltaco.com/us/ok/oklahoma-city/5845-w-reno-ave\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "cheddars_scratch_kitchen": {
        "__id": 233,
        "id": "cheddars_scratch_kitchen",
        "name": "Cheddar's Scratch Kitchen",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'official_name', 'phone', 'website']",
        "rowLength": 183,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763650,
        "runTime": 0,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of cheddars_scratch_kitchen locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 105,
                "address_city": "\"Hendersonville\"",
                "address_country": "\"US\"",
                "address_full": "\"210 N. Anderson Lane\"",
                "address_postcode": "\"370756926\"",
                "address_state": "\"TN\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american\"",
                "latitude": 36.32142,
                "longitude": -86.59857,
                "name": "\"Hendersonville\"",
                "official_name": "\"Cheddar's Scratch Kitchen\"",
                "phone": "\"(615) 822-5537\"",
                "website": "\"https://www.cheddars.com/web-api/restaurants\""
            },
            {
                "__id": 112,
                "address_city": "\"Huntsville\"",
                "address_country": "\"US\"",
                "address_full": "\"6125 University Dr\"",
                "address_postcode": "\"358061757\"",
                "address_state": "\"AL\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american\"",
                "latitude": 34.74301,
                "longitude": -86.67608,
                "name": "\"Huntsville\"",
                "official_name": "\"Cheddar's Scratch Kitchen\"",
                "phone": "\"(256) 971-2000\"",
                "website": "\"https://www.cheddars.com/web-api/restaurants\""
            },
            {
                "__id": 49,
                "address_city": "\"Independence\"",
                "address_country": "\"US\"",
                "address_full": "\"4675 South Bass Pro Dr\"",
                "address_postcode": "\"640555456\"",
                "address_state": "\"MO\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american\"",
                "latitude": 39.034473,
                "longitude": -94.36408,
                "name": "\"Independence\"",
                "official_name": "\"Cheddar's Scratch Kitchen\"",
                "phone": "\"(816) 795-5520\"",
                "website": "\"https://www.cheddars.com/web-api/restaurants\""
            },
            {
                "__id": 36,
                "address_city": "\"Austin\"",
                "address_country": "\"US\"",
                "address_full": "\"13301 North Hwy 183\"",
                "address_postcode": "\"787507155\"",
                "address_state": "\"TX\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american\"",
                "latitude": 30.44603,
                "longitude": -97.78773,
                "name": "\"Austin - Hwy. 183\"",
                "official_name": "\"Cheddar's Scratch Kitchen\"",
                "phone": "\"(512) 336-8855\"",
                "website": "\"https://www.cheddars.com/web-api/restaurants\""
            },
            {
                "__id": 88,
                "address_city": "\"Paducah\"",
                "address_country": "\"US\"",
                "address_full": "\"5002 Hinkleville Road\"",
                "address_postcode": "\"420018005\"",
                "address_state": "\"KY\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american\"",
                "latitude": 37.077205,
                "longitude": -88.682118,
                "name": "\"Paducah\"",
                "official_name": "\"Cheddar's Scratch Kitchen\"",
                "phone": "\"(270) 908-3352\"",
                "website": "\"https://www.cheddars.com/web-api/restaurants\""
            },
            {
                "__id": 140,
                "address_city": "\"Asheville\"",
                "address_country": "\"US\"",
                "address_full": "\"106 Bleachery Blvd\"",
                "address_postcode": "\"288058209\"",
                "address_state": "\"NC\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american\"",
                "latitude": 35.576273,
                "longitude": -82.51254,
                "name": "\"Asheville\"",
                "official_name": "\"Cheddar's Scratch Kitchen\"",
                "phone": "\"(828) 299-1168\"",
                "website": "\"https://www.cheddars.com/web-api/restaurants\""
            },
            {
                "__id": 58,
                "address_city": "\"Joplin\"",
                "address_country": "\"US\"",
                "address_full": "\"3120 Hammons Blvd\"",
                "address_postcode": "\"648044467\"",
                "address_state": "\"MO\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american\"",
                "latitude": 37.048032,
                "longitude": -94.475398,
                "name": "\"Joplin\"",
                "official_name": "\"Cheddar's Scratch Kitchen\"",
                "phone": "\"(417) 624-6000\"",
                "website": "\"https://www.cheddars.com/web-api/restaurants\""
            },
            {
                "__id": 5,
                "address_city": "\"Albuquerque\"",
                "address_country": "\"US\"",
                "address_full": "\"4865 Pan American West Freeway\"",
                "address_postcode": "\"871092238\"",
                "address_state": "\"NM\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american\"",
                "latitude": 35.139429,
                "longitude": -106.600926,
                "name": "\"Albuquerque\"",
                "official_name": "\"Cheddar's Scratch Kitchen\"",
                "phone": "\"(505) 345-0829\"",
                "website": "\"https://www.cheddars.com/web-api/restaurants\""
            },
            {
                "__id": 52,
                "address_city": "\"Garland\"",
                "address_country": "\"US\"",
                "address_full": "\"5445 North President George Bush Hwy\"",
                "address_postcode": "\"750444801\"",
                "address_state": "\"TX\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american\"",
                "latitude": 32.96614,
                "longitude": -96.64454,
                "name": "\"Garland\"",
                "official_name": "\"Cheddar's Scratch Kitchen\"",
                "phone": "\"(972) 495-1200\"",
                "website": "\"https://www.cheddars.com/web-api/restaurants\""
            },
            {
                "__id": 39,
                "address_city": "\"Grand Prairie\"",
                "address_country": "\"US\"",
                "address_full": "\"4106 South Carrier Parkway\"",
                "address_postcode": "\"750523214\"",
                "address_state": "\"TX\"",
                "amenity": "\"restaurant\"",
                "cuisine": "\"american\"",
                "latitude": 32.673928,
                "longitude": -97.007521,
                "name": "\"Grand Prairie\"",
                "official_name": "\"Cheddar's Scratch Kitchen\"",
                "phone": "\"(972) 264-0794\"",
                "website": "\"https://www.cheddars.com/web-api/restaurants\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "under_armour": {
        "__id": 234,
        "id": "under_armour",
        "name": "Under Armour",
        "columnNames": "['address_city', 'address_country', 'address_postcode', 'address_state', 'image', 'latitude', 'longitude', 'name', 'phone', 'street_address', 'website']",
        "rowLength": 195,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763651,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of under_armour locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 88,
                "address_city": "\"Central Valley\"",
                "address_country": "\"US\"",
                "address_postcode": "\"10917\"",
                "address_state": "\"NY\"",
                "image": "\"https://store-locations.underarmour.com/images/store-img.jpg\"",
                "latitude": 41.317091,
                "longitude": -74.129695,
                "name": "\"Under Armour Factory House\"",
                "phone": "\"845-928-3013\"",
                "street_address": "\"185 Niagara Lane\"",
                "website": "\"https://store-locations.underarmour.com/ny/central-valley/14\""
            },
            {
                "__id": 56,
                "address_city": "\"Tannersville\"",
                "address_country": "\"US\"",
                "address_postcode": "\"18372\"",
                "address_state": "\"PA\"",
                "image": "\"https://store-locations.underarmour.com/images/store-img.jpg\"",
                "latitude": 41.0475603,
                "longitude": -75.3127018,
                "name": "\"Under Armour Factory House\"",
                "phone": "\"570-629-3610\"",
                "street_address": "\"1000 Premium Outlets Drive\"",
                "website": "\"https://store-locations.underarmour.com/pa/tannersville/6\""
            },
            {
                "__id": 37,
                "address_city": "\"Grapevine\"",
                "address_country": "\"US\"",
                "address_postcode": "\"76051\"",
                "address_state": "\"TX\"",
                "image": "\"https://store-locations.underarmour.com/images/store-img.jpg\"",
                "latitude": 32.96823,
                "longitude": -97.0415897,
                "name": "\"Under Armour Factory House\"",
                "phone": "\"972-539-0541\"",
                "street_address": "\"3000 Grapevine Mills Pkwy\"",
                "website": "\"https://store-locations.underarmour.com/tx/grapevine/93\""
            },
            {
                "__id": 93,
                "address_city": "\"Tinton Falls\"",
                "address_country": "\"US\"",
                "address_postcode": "\"07753\"",
                "address_state": "\"NJ\"",
                "image": "\"https://store-locations.underarmour.com/images/store-img.jpg\"",
                "latitude": 40.2229261,
                "longitude": -74.095164,
                "name": "\"Under Armour Factory House\"",
                "phone": "\"732-493-2411\"",
                "street_address": "\"4001 Premium Outlet Blvd\"",
                "website": "\"https://store-locations.underarmour.com/nj/tinton-falls/25\""
            },
            {
                "__id": 39,
                "address_city": "\"Fort Bliss\"",
                "address_country": "\"US\"",
                "address_postcode": "\"79906\"",
                "address_state": "\"TX\"",
                "image": "\"https://store-locations.underarmour.com/images/store-img.jpg\"",
                "latitude": 31.8177135,
                "longitude": -106.4281186,
                "name": "\"Under Armour Factory House\"",
                "phone": "\"915-566-0976\"",
                "street_address": "\"1612 Pleasonton Rd.\"",
                "website": "\"https://store-locations.underarmour.com/tx/fort-bliss/96\""
            },
            {
                "__id": 26,
                "address_city": "\"Tysons Corner\"",
                "address_country": "\"US\"",
                "address_postcode": "\"22102\"",
                "address_state": "\"VA\"",
                "image": "\"http://hosted.where2getit.com/underarmour/images/storefronts/Tysons.png\"",
                "latitude": 38.9178791,
                "longitude": -77.2220859,
                "name": "\"Under Armour Brand House\"",
                "phone": "\"703-356-0954\"",
                "street_address": "\"7977 Tysons Corner Center\"",
                "website": "\"https://store-locations.underarmour.com/va/tysons-corner/UBHTC-7977TCTCV\""
            },
            {
                "__id": 148,
                "address_city": "\"Aurora\"",
                "address_country": "\"US\"",
                "address_postcode": "\"60502-2910\"",
                "address_state": "\"IL\"",
                "image": "\"https://store-locations.underarmour.com/images/store-img.jpg\"",
                "latitude": 41.799798,
                "longitude": -88.2740881,
                "name": "\"Under Armour Factory House\"",
                "phone": "\"630-978-3821\"",
                "street_address": "\"1650 Premium Outlets Blvd.\"",
                "website": "\"https://store-locations.underarmour.com/il/aurora/81\""
            },
            {
                "__id": 177,
                "address_city": "\"Mashantucket\"",
                "address_country": "\"US\"",
                "address_postcode": "\"06338\"",
                "address_state": "\"CT\"",
                "image": "\"https://store-locations.underarmour.com/images/store-img.jpg\"",
                "latitude": 41.4741541,
                "longitude": -71.9593989,
                "name": "\"Under Armour Factory House\"",
                "phone": "\"860-237-6031\"",
                "street_address": "\"455 Trolley Line Blvd\"",
                "website": "\"https://store-locations.underarmour.com/ct/mashantucket/228\""
            },
            {
                "__id": 86,
                "address_city": "\"Lake George\"",
                "address_country": "\"US\"",
                "address_postcode": "\"12845\"",
                "address_state": "\"NY\"",
                "image": "\"https://store-locations.underarmour.com/images/store-img.jpg\"",
                "latitude": 43.3656812,
                "longitude": -73.6983179,
                "name": "\"Under Armour Factory House\"",
                "phone": "\"518-761-6787\"",
                "street_address": "\"1444 U.S. 9\"",
                "website": "\"https://store-locations.underarmour.com/ny/lake-george/92\""
            },
            {
                "__id": 193,
                "address_city": "\"Livermore\"",
                "address_country": "\"US\"",
                "address_postcode": "\"94551\"",
                "address_state": "\"CA\"",
                "image": "\"https://store-locations.underarmour.com/images/store-img.jpg\"",
                "latitude": 37.698588,
                "longitude": -121.8429906,
                "name": "\"Under Armour Factory House\"",
                "phone": "\"925-455-1976\"",
                "street_address": "\"3190 Paragon Outlets Drive\"",
                "website": "\"https://store-locations.underarmour.com/ca/livermore/UASCS-LC\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "checkers": {
        "__id": 235,
        "id": "checkers",
        "name": "Checkers",
        "columnNames": "['address_city', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'image', 'latitude', 'longitude', 'name', 'opening_hours', 'street_address', 'takeaway', 'website']",
        "rowLength": 561,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763652,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of checkers locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 48,
                "address_city": "\"Houston Houston, TX\"",
                "address_postcode": "\"77045\"",
                "address_state": "\"TX\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "image": "\"https://dynl.mktgcdn.com/p/ozT2cxSHNgDJ54qcTWtNvn7POx_8gtT_sUESQHljiak/150x150.jpg\"",
                "latitude": 29.62989,
                "longitude": -95.464858,
                "name": "\"Checkers - Closed S Post Oak Rd\"",
                "opening_hours": "\"\"",
                "street_address": "\"13500 S. Post Oak Rd.\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.checkers.com/tx/houston/13500-s.-post-oak-rd.\""
            },
            {
                "__id": 121,
                "address_city": "\"New York New York, NY\"",
                "address_postcode": "\"10003\"",
                "address_state": "\"NY\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "image": "\"https://dynl.mktgcdn.com/p/LS7dB1yDi2WCBfDkoG3uVyZYNXW6eYJ4zEUEdsZoshY/150x150.jpg\"",
                "latitude": 40.73100583819546,
                "longitude": -73.98311376571655,
                "name": "\"Checkers - Closed 1st Ave\"",
                "opening_hours": "\"\"",
                "street_address": "\"225 1st Ave.\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.checkers.com/ny/new-york/225-1st-ave.\""
            },
            {
                "__id": 554,
                "address_city": "\"Mobile Mobile, AL\"",
                "address_postcode": "\"36606\"",
                "address_state": "\"AL\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "image": "\"https://dynl.mktgcdn.com/p/LS7dB1yDi2WCBfDkoG3uVyZYNXW6eYJ4zEUEdsZoshY/150x150.jpg\"",
                "latitude": 30.676161785135097,
                "longitude": -88.09316530823708,
                "name": "\"Checkers Airport Blvd.\"",
                "opening_hours": "\"Mo-Su 10:00-24:00\"",
                "street_address": "\"2213 Airport Blvd.\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.checkers.com/al/mobile/2213-airport-blvd.\""
            },
            {
                "__id": 536,
                "address_city": "\"Wilmington Wilmington, DE\"",
                "address_postcode": "\"19805\"",
                "address_state": "\"DE\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "image": "\"https://dynl.mktgcdn.com/p/ozT2cxSHNgDJ54qcTWtNvn7POx_8gtT_sUESQHljiak/150x150.jpg\"",
                "latitude": 39.74905115049737,
                "longitude": -75.57180359959602,
                "name": "\"Checkers West 4th St\"",
                "opening_hours": "\"Mo-Su 10:00-04:00\"",
                "street_address": "\"1901 West 4th St\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.checkers.com/de/wilmington/1901-west-4th-st\""
            },
            {
                "__id": 47,
                "address_city": "\"Houston Houston, TX\"",
                "address_postcode": "\"77083\"",
                "address_state": "\"TX\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "image": "\"https://dynl.mktgcdn.com/p/ozT2cxSHNgDJ54qcTWtNvn7POx_8gtT_sUESQHljiak/150x150.jpg\"",
                "latitude": 29.68903489415024,
                "longitude": -95.62280729413027,
                "name": "\"Checkers Beechnut St\"",
                "opening_hours": "\"Mo-Th 10:30-24:00; Fr-Sa 10:30-02:00; Su 10:30-24:00\"",
                "street_address": "\"13600 Beechnut St.\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.checkers.com/tx/houston/13600-beechnut-st.\""
            },
            {
                "__id": 368,
                "address_city": "\"Dalton Dalton, GA\"",
                "address_postcode": "\"30721\"",
                "address_state": "\"GA\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "image": "\"https://dynl.mktgcdn.com/p/LS7dB1yDi2WCBfDkoG3uVyZYNXW6eYJ4zEUEdsZoshY/150x150.jpg\"",
                "latitude": 34.79692009789575,
                "longitude": -84.96171981096268,
                "name": "\"Checkers Cleveland Hwy.\"",
                "opening_hours": "\"Mo-Su 10:00-24:00\"",
                "street_address": "\"1300 N. Glenwood Ave.\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.checkers.com/ga/dalton/1300-n.-glenwood-ave.\""
            },
            {
                "__id": 530,
                "address_city": "\"Dania Dania, FL\"",
                "address_postcode": "\"33004\"",
                "address_state": "\"FL\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "image": "\"https://dynl.mktgcdn.com/p/LS7dB1yDi2WCBfDkoG3uVyZYNXW6eYJ4zEUEdsZoshY/150x150.jpg\"",
                "latitude": 26.04129900300947,
                "longitude": -80.14310843777821,
                "name": "\"Checkers - Closed S. Federal Highway\"",
                "opening_hours": "\"\"",
                "street_address": "\"645 S. Federal Highway\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.checkers.com/fl/dania/645-s.-federal-highway\""
            },
            {
                "__id": 11,
                "address_city": "\"Bradenton Bradenton, FL\"",
                "address_postcode": "\"34203\"",
                "address_state": "\"FL\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "image": "\"https://dynl.mktgcdn.com/p/LS7dB1yDi2WCBfDkoG3uVyZYNXW6eYJ4zEUEdsZoshY/150x150.jpg\"",
                "latitude": 27.449107200834476,
                "longitude": -82.54709601402283,
                "name": "\"Checkers 15th Street E.\"",
                "opening_hours": "\"Mo-Su 10:00-03:00\"",
                "street_address": "\"5220 15th Street E.\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.checkers.com/fl/bradenton/5220-15th-street-e.\""
            },
            {
                "__id": 428,
                "address_city": "\"St. Cloud St. Cloud, FL\"",
                "address_postcode": "\"34769\"",
                "address_state": "\"FL\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "image": "\"https://dynl.mktgcdn.com/p/LS7dB1yDi2WCBfDkoG3uVyZYNXW6eYJ4zEUEdsZoshY/150x150.jpg\"",
                "latitude": 28.249099516370098,
                "longitude": -81.3092377781868,
                "name": "\"Checkers U.S. Highway 192\"",
                "opening_hours": "\"Mo-We 10:00-01:00; Th-Sa 10:00-02:00; Su 10:00-01:00\"",
                "street_address": "\"3554 13th St\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.checkers.com/fl/st.-cloud/3554-13th-st\""
            },
            {
                "__id": 528,
                "address_city": "\"Daytona Beach Daytona Beach, FL\"",
                "address_postcode": "\"32114\"",
                "address_state": "\"FL\"",
                "amenity": "\"fast_food\"",
                "cuisine": "\"burger\"",
                "image": "\"https://dynl.mktgcdn.com/p/LS7dB1yDi2WCBfDkoG3uVyZYNXW6eYJ4zEUEdsZoshY/150x150.jpg\"",
                "latitude": 29.18761176154299,
                "longitude": -81.07663911556756,
                "name": "\"Checkers International Speedway Blvd.\"",
                "opening_hours": "\"Mo-Th 10:00-01:00; Fr-Sa 10:00-02:00; Su 10:00-01:00\"",
                "street_address": "\"2210 International Speedway Blvd.\"",
                "takeaway": "\"yes\"",
                "website": "\"https://locations.checkers.com/fl/daytona-beach/2210-international-speedway-blvd.\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    },
    "jdwetherspoon": {
        "__id": 236,
        "id": "jdwetherspoon",
        "name": "JD Wetherspoon",
        "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'latitude', 'longitude', 'name', 'phone', 'website']",
        "rowLength": 419,
        "salePrice": 49.99,
        "retailPrice": 75.0,
        "lastUpdated": 1669763653,
        "runTime": 1,
        "logoUrl": null,
        "locations": null,
        "description": "This dataset includes a full collection of jdwetherspoon locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate.",
        "sampleData": [
            {
                "__id": 171,
                "address_city": "\"Newcastle upon Tyne\"",
                "address_country": "\"GB\"",
                "address_full": "\"19\u201325 Westgate Road & Grainger Street Newcastle upon Tyne Tyne and Wear NE1 5XU\"",
                "address_postcode": "\"NE1 5XU\"",
                "address_state": "\"Tyne and Wear\"",
                "amenity": "\"pub\"",
                "latitude": 54.970068,
                "longitude": -1.616518,
                "name": "\"The Mile Castle\"",
                "phone": "\"0191 211 1160\"",
                "website": "\"https://www.jdwetherspoon.com/pubs/all-pubs/england/tyne-and-wear/the-mile-castle-newcastle-upon-tyne\""
            },
            {
                "__id": 263,
                "address_city": "\"Yate\"",
                "address_country": "\"GB\"",
                "address_full": "\"11\u201317 South Parade Yate Gloucestershire BS37 4BB\"",
                "address_postcode": "\"BS37 4BB\"",
                "address_state": "\"Gloucestershire\"",
                "amenity": "\"pub\"",
                "latitude": 51.539258,
                "longitude": -2.41326,
                "name": "\"Thorn\u2019s Farm\"",
                "phone": "\"01454 272500\"",
                "website": "\"https://www.jdwetherspoon.com/pubs/all-pubs/england/gloucestershire/thorns-farm-yate\""
            },
            {
                "__id": 382,
                "address_city": "\"Keighley\"",
                "address_country": "\"GB\"",
                "address_full": "\"89\u201397 North Street Keighley West Yorkshire BD21 3AA\"",
                "address_postcode": "\"BD21 3AA\"",
                "address_state": "\"West Yorkshire\"",
                "amenity": "\"pub\"",
                "latitude": 53.868726,
                "longitude": -1.909999,
                "name": "\"The Livery Rooms \"",
                "phone": "\"01535 682950\"",
                "website": "\"https://www.jdwetherspoon.com/pubs/all-pubs/england/west-yorkshire/the-livery-rooms-keighley\""
            },
            {
                "__id": 61,
                "address_city": "\"Cardiff\"",
                "address_country": "\"GB\"",
                "address_full": "\"243\u2013249 Cowbridge Road East Cardiff Cardiff CF11 9AN\"",
                "address_postcode": "\"CF11 9AN\"",
                "address_state": "\"Cardiff\"",
                "amenity": "\"pub\"",
                "latitude": 51.48133,
                "longitude": -3.200316,
                "name": "\"The Ivor Davies\"",
                "phone": "\"029 2066 7615\"",
                "website": "\"https://www.jdwetherspoon.com/pubs/all-pubs/wales/cardiff/the-ivor-davies-cardiff\""
            },
            {
                "__id": 85,
                "address_city": "\"Dunfermline\"",
                "address_country": "\"GB\"",
                "address_full": "\"79\u201383 High Street Dunfermline Fife KY12 7DR\"",
                "address_postcode": "\"KY12 7DR\"",
                "address_state": "\"Fife\"",
                "amenity": "\"pub\"",
                "latitude": 56.071339,
                "longitude": -3.461366,
                "name": "\"The Guildhall & Linen Exchange\"",
                "phone": "\"01383 724060\"",
                "website": "\"https://www.jdwetherspoon.com/hotels/scotland/fife/the-guildhall--linen-exchange\""
            },
            {
                "__id": 26,
                "address_city": "\"Colwyn Bay\"",
                "address_country": "\"GB\"",
                "address_full": "\"24\u201326 Prince\u2019s Drive Colwyn Bay Conwy LL29 8LA\"",
                "address_postcode": "\"LL29 8LA\"",
                "address_state": "\"Conwy\"",
                "amenity": "\"pub\"",
                "latitude": 53.296712,
                "longitude": -3.727801,
                "name": "\"The Picture House\"",
                "phone": "\"01492 535286\"",
                "website": "\"https://www.jdwetherspoon.com/pubs/all-pubs/wales/conwy/the-picture-house-colwyn-bay\""
            },
            {
                "__id": 400,
                "address_city": "\"Eltham\"",
                "address_country": "\"GB\"",
                "address_full": "\"80 High Street Eltham London SE9 1BW\"",
                "address_postcode": "\"SE9 1BW\"",
                "address_state": "\"London\"",
                "amenity": "\"pub\"",
                "latitude": 51.450679,
                "longitude": 0.052272,
                "name": "\"The Banker\u2019s Draft\"",
                "phone": "\"020 8294 2578\"",
                "website": "\"https://www.jdwetherspoon.com/pubs/all-pubs/england/london/the-bankers-draft-eltham\""
            },
            {
                "__id": 309,
                "address_city": "\"Farnborough\"",
                "address_country": "\"GB\"",
                "address_full": "\"Units 2\u20135, The Meads, Victoria Road Farnborough Hampshire GU14 7PG\"",
                "address_postcode": "\"GU14 7PG\"",
                "address_state": "\"Hampshire\"",
                "amenity": "\"pub\"",
                "latitude": 51.293438,
                "longitude": -0.756707,
                "name": "\"The Tilly Shilling\"",
                "phone": "\"01252 893560\"",
                "website": "\"https://www.jdwetherspoon.com/pubs/all-pubs/england/hampshire/the-tilly-shilling-farnborough\""
            },
            {
                "__id": 388,
                "address_city": "\"Welling\"",
                "address_country": "\"GB\"",
                "address_full": "\"55 Bellegrove Road Welling London DA16 3PB\"",
                "address_postcode": "\"DA16 3PB\"",
                "address_state": "\"London\"",
                "amenity": "\"pub\"",
                "latitude": 51.462947,
                "longitude": 0.103909,
                "name": "\"The New Cross Turnpike\"",
                "phone": "\"020 8304 1600\"",
                "website": "\"https://www.jdwetherspoon.com/pubs/all-pubs/england/london/the-new-cross-turnpike-welling\""
            },
            {
                "__id": 332,
                "address_city": "\"Wombwell\"",
                "address_country": "\"GB\"",
                "address_full": "\"30 High Street Wombwell South Yorkshire S73 0AA\"",
                "address_postcode": "\"S73 0AA\"",
                "address_state": "\"South Yorkshire\"",
                "amenity": "\"pub\"",
                "latitude": 53.522573,
                "longitude": -1.398634,
                "name": "\"The Horseshoe\"",
                "phone": "\"01226 273820\"",
                "website": "\"https://www.jdwetherspoon.com/pubs/all-pubs/england/south-yorkshire/the-horseshoe-wombwell\""
            }
        ],
        "relatedItems": [
            {
                "__id": 4,
                "id": "jerseymikes",
                "name": "Jersey Mike's",
                "columnNames": "['address_city', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'short_name', 'takeaway', 'website']",
                "rowLength": 494,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654131,
                "runTime": 0,
                "description": "This dataset includes a full collection of jerseymikes locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 8,
                "id": "84lumber",
                "name": "84 Lumber",
                "columnNames": "['address_city', 'address_postcode', 'address_state', 'latitude', 'longitude', 'name', 'opening_hours', 'phone', 'shop', 'street_address', 'trade', 'website']",
                "rowLength": 231,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654149,
                "runTime": 1,
                "description": "This dataset includes a full collection of 84lumber locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            },
            {
                "__id": 9,
                "id": "cookout",
                "name": "Cook Out",
                "columnNames": "['address_city', 'address_country', 'address_full', 'address_postcode', 'address_state', 'amenity', 'cuisine', 'latitude', 'longitude', 'name', 'opening_hours', 'takeaway', 'website']",
                "rowLength": 326,
                "salePrice": 49.99,
                "retailPrice": 75.0,
                "lastUpdated": 1668654168,
                "runTime": 0,
                "description": "This dataset includes a full collection of cookout locations. For a list of columns available, please view the sample data below. This dataset is an approximation of the actual data and may not be 100% accurate."
            }
        ]
    }
}