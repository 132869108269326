import { useParams } from "react-router-dom";
import { Header } from "../header/Header";
import { Footer } from "../footer/Footer";
import { DatasetCard } from "../productsPage/DatasetCard";
import { useEffect, useState } from "react";
import { getFriendlyName, pxFetch } from "../../utils/utils";
import SyncLoader from "react-spinners/SyncLoader";
import { _data } from "../../data";
import AnimatedNumbers from "react-animated-numbers";
import storage from '../../utils/firebaseConfig';
import { getDownloadURL, ref } from "firebase/storage";

export function ProductPage({ props }: any) {
    const params = useParams();

    const productId: any = params?.productId ?? '';
    const [product, setProduct] = useState<any>(_data?.[productId]);

    // useEffect(() => {
    //     setLoading(true);
    //     const getData = async () => {
    //         const response = await pxFetch(
    //             'GET',
    //             `/product/${productId}`,
    //         );

    //         const json = await response.json();

    //         if (response.status === 200) {
    //             setLoading(false);
    //             let product = json?.product;
    //             product['columnNames'] = JSON.parse(product?.columnNames.replaceAll("'", '"'));
    //             setProduct(product);
    //         } else {
    //             setLoading(false);
    //             setError(json?.message);
    //         }
    //     }
    //     getData();
    // }, []);

    const storageRef = ref(storage.storage, `logos/${product?.id}/logo.png`);
    const [logoUrl, setLogoUrl] = useState('');

    useEffect(() => {
        const getLogoUrl = async () => {
            const url = await getDownloadURL(storageRef);
            setLogoUrl(url);
        }
        getLogoUrl();

    }, [])

    return (
        <div
            style={{
                width: '100%',
            }}
        >

            <Header page={'product'} />

            <div
                style={{
                    paddingTop: '175px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-evenly',
                        width: '100%',
                        maxWidth: '1200px',
                        marginBottom: '109px'
                    }}
                >

                    <div
                        style={{
                            border: '2px solid #2a9d8f',
                            borderRadius: '10px',
                            width: '430px',
                            height: '600px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            overflow: 'hidden',
                        }}
                    >
                        {
                            // loading ?
                            //     <SyncLoader color="#36d7b7" />
                            //     :
                            <img
                                src={logoUrl}
                                style={{
                                    width: '430px',
                                    height: '600px',
                                    objectFit: 'contain',
                                }}
                                alt={`${productId}-logo`}
                            />
                        }
                    </div>

                    <div
                        style={{
                            borderRadius: '10px',
                            width: '430px',
                            height: '600px',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            flexDirection: 'column',
                        }}
                    >
                        <h1
                            style={{
                                marginTop: '48px',
                                fontSize: '50px',
                                color: '#2a9d8f',
                                fontWeight: 'bold',
                                marginBottom: '0px',
                                paddingBottom: '0px',
                            }}
                        >
                            {/* {loading ? <SyncLoader color="#36d7b7" /> : product?.name} */}
                            {product?.name}
                        </h1>
                        <div
                            style={{
                                fontSize: '30px',
                                color: '#BABABA',
                                fontWeight: 'medium',
                                display: 'flex',
                                flexDirection: 'row',
                                marginBottom: '64px',
                            }}
                        >
                            {/* {loading ? <SyncLoader color="#36d7b7" /> : 'Locations: '} */}
                            {/* Locations: <div style={{ color: '#343434', marginLeft: '5px' }}>{product?.locations}</div> */}
                            {/* {!loading && <div style={{ color: '#343434', marginLeft: '5px' }}>{product?.locations}</div>} */}
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginBottom: '118px',
                            }}
                        >
                            <div
                                style={{
                                    fontSize: '40px',
                                }}
                            >
                                {/* {loading ? <SyncLoader color="#36d7b7" /> : `$${product?.retailPrice}`} */}
                                {`$${product?.retailPrice}`}
                            </div>
                            {
                                // loading ?
                                //     <SyncLoader color="#36d7b7" />
                                //     :
                                <div
                                    style={{
                                        fontSize: '30px',
                                        borderRadius: '10px',
                                        border: '2px solid #2a9d8f',
                                        color: '#2a9d8f',
                                        paddingLeft: '30px',
                                        paddingRight: '30px',
                                        marginLeft: '36px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    {/* {loading ? <SyncLoader color="#36d7b7" /> : `$${product?.salePrice}`} */}
                                    {`$${product?.salePrice}`}
                                </div>
                            }
                        </div>

                        <div
                            style={{
                                fontSize: '20px',
                                marginBottom: '118px'
                            }}
                        >
                            {/* {loading ? <SyncLoader color="#36d7b7" /> : `This data set was last updated on ${new Date(product?.lastUpdated * 1000).toLocaleDateString()}.`} */}
                            This data set was last updated on
                            <div
                                style={{
                                    fontWeight: '700',
                                }}
                            >
                                {new Date(product?.lastUpdated * 1000).toDateString()}.
                            </div>
                        </div>

                        <div
                            style={{
                                fontSize: '20px',
                                backgroundColor: '#2a9d8f',
                                fontWeight: 'medium',
                                color: '#FFFFFF',
                                padding: '20px 50px',
                                borderRadius: '10px',
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                window.location.href = '/checkout/' + product?.id;
                            }}
                        >
                            Buy now
                        </div>

                    </div>
                </div>


                <div
                    style={{
                        border: '0.5px solid #707070',
                        width: '60%',
                        marginBottom: '109px',
                        opacity: '0.20000000298023224',
                    }}
                />


                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '1200px',
                        justifyContent: 'flex-end',
                    }}
                >
                    <div
                        style={{
                            width: '550px',
                            // marginBottom: '94px',
                            // marginTop: '200px',
                        }}
                    >
                        <div
                            style={{
                                fontSize: '35px',
                                fontWeight: '500',
                                color: '#343434',
                                marginBottom: '18px',
                            }}
                        >
                            How many {product?.name} locations are there?
                        </div>
                        <div
                            style={{
                                fontSize: '18px',
                                display: 'flex',
                                alignItems: 'center',
                                // textAlign: 'center',
                            }}
                        >
                            {/* {product?.description} */}
                            <AnimatedNumbers
                                includeComma
                                animateToNumber={product?.rowLength}
                                fontStyle={{ fontSize: 40 }}
                                configs={[
                                    { mass: 1, tension: 220, friction: 100 },
                                    { mass: 1, tension: 180, friction: 130 },
                                    { mass: 1, tension: 280, friction: 90 },
                                    { mass: 1, tension: 180, friction: 135 },
                                    { mass: 1, tension: 260, friction: 100 },
                                    { mass: 1, tension: 210, friction: 180 },
                                ]}
                            ></AnimatedNumbers>
                            <div style={{ marginLeft: '25px' }}>locations found.</div>
                        </div>
                    </div>

                    <div
                        style={{
                            width: '550px',
                            marginBottom: '94px'
                        }}
                    >
                        <div
                            style={{
                                fontSize: '35px',
                                fontWeight: '500',
                                color: '#343434',
                                marginBottom: '18px',
                            }}
                        >
                            Description
                        </div>
                        <div
                            style={{
                                fontSize: '18px',
                                marginBottom: '18px',
                                textDecoration: 'italic',
                            }}
                        >
                            {product?.description}
                        </div>

                        <i>
                            CSV is our default format for all datasets. Please contact us if you need {product?.name} data in a different format.
                        </i>
                    </div>
                </div>

                <div
                    style={{
                        border: '0.5px solid #707070',
                        width: '60%',
                        marginBottom: '109px',
                        opacity: '0.20000000298023224',
                    }}
                />

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '1200px',
                        justifyContent: 'center',
                    }}
                >

                    <div
                        style={{
                            width: '550px',
                            marginBottom: '94px'
                        }}
                    >
                        <div
                            style={{
                                fontSize: '35px',
                                fontWeight: '500',
                                color: '#343434',
                                marginBottom: '18px',
                            }}
                        >
                            Fields included in the {product?.name} dataset
                        </div>
                        <div
                            style={{
                                fontSize: '18px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                            }}
                        >
                            {
                                // loading ?
                                //     <SyncLoader color="#36d7b7" />
                                //     :
                                JSON.parse(product?.columnNames?.replace(/'/g, '"')).map((field: string, index: number) => {
                                    return (
                                        <div
                                            key={index}
                                            style={{
                                                fontSize: '18px',
                                                marginBottom: '18px',
                                                width: '200px',
                                            }}
                                        >
                                            {/* {field} */}
                                            {getFriendlyName(field)}
                                        </div>
                                    )
                                })
                            }
                        </div>

                    </div>

                </div>

                <div
                    style={{
                        border: '0.5px solid #707070',
                        width: '60%',
                        marginBottom: '109px',
                        opacity: '0.20000000298023224',
                    }}
                />

                {/* Sample table */}
                <div
                    style={{
                        fontSize: '35px',
                        fontWeight: '500',
                        color: '#343434',
                        marginBottom: '18px',
                    }}
                >
                    Sample data
                </div>
                {
                    // loading ?
                    //     <div
                    //         style={{
                    //             display: 'flex',
                    //             justifyContent: 'center',
                    //             alignItems: 'center',
                    //             height: '250px',
                    //             padding: '50px',
                    //         }}
                    //     >
                    //         <SyncLoader color="#36d7b7" />
                    //     </div>
                    //     :
                    <div
                        style={{
                            border: '1px solid #2a9d8f',
                            width: '1200px',
                            // height: '300px',
                            borderRadius: '10px',
                            borderBottomRightRadius: '0px',
                            borderBottomLeftRadius: '0px',
                            overflowX: 'scroll',
                            display: 'flex',
                            flexDirection: 'column',
                            marginBottom: '211px',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            {
                                JSON.parse(product?.columnNames?.replace(/'/g, '"'))?.map((column: string, index: number) => {
                                    return (
                                        <div
                                            key={`column-${index}`}
                                            style={{
                                                minWidth: '150px',
                                                width: '150px',
                                                height: '30px',
                                                backgroundColor: '#2a9d8f',
                                                color: '#FFFFFF',
                                                padding: '15px',
                                                wordWrap: 'break-word',
                                                // wordBreak: 'break-all',
                                                textOverflow: 'ellipsis',
                                                display: 'flex',
                                                // justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            {getFriendlyName(column)}
                                        </div>
                                    )
                                })
                            }
                        </div>

                        {
                            product.sampleData.map((row: any, index: number) => {

                                return (
                                    <>
                                        <div
                                            key={`row-${index}`}
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}
                                        >
                                            {
                                                Object.keys(row).map((column: string, index: number) => {
                                                    if (column === '__id') {
                                                        return null
                                                    }
                                                    return (
                                                        <div
                                                            key={`column2-${index}`}
                                                            style={{
                                                                minWidth: '150px',
                                                                width: '150px',
                                                                padding: '15px',
                                                                maxHeight: '50px',
                                                                // height: '50px',
                                                                // display: 'flex',
                                                                // alignItems: 'center',
                                                                overflow: 'hidden',
                                                                textAlign: 'justify',
                                                                paddingTop: '5px',
                                                                paddingBottom: '5px',
                                                                // border: '5px solid red',
                                                                wordWrap: 'break-word',
                                                                wordBreak: 'break-all',
                                                                textOverflow: 'ellipsis',
                                                            }}
                                                        >
                                                            {
                                                                typeof (row[column]) === 'string' ?
                                                                    row[column]?.replaceAll('"', '')
                                                                    :
                                                                    row[column]
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        {/* <div style={{
                                            border: '0.5px solid #707070',
                                            // width: `${(JSON.parse(product?.columnNames?.replace(/'/g, '"')).length) * 177}px`,
                                            // width: '100%',
                                            marginLeft: '10px',
                                        }}
                                        /> */}
                                    </>
                                )
                            })
                        }
                    </div>
                }


                <div
                    style={{
                        width: '1200px',
                        fontSize: '35px',
                        fontWeight: '500',
                        marginBottom: '67px',
                    }}
                >
                    You might also like…
                </div>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '1200px',
                        justifyContent: 'space-around',
                        marginBottom: '100px'
                    }}
                >

                    {/* {
                        loading ?
                            <SyncLoader color="#36d7b7" />
                            :
                            (product?.relatedProducts ?? []).map((product: any, index: number) => {
                                return <DatasetCard productData={product} />
                            })
                    } */}
                    {
                        product?.relatedItems?.[0] && <DatasetCard productData={product?.relatedItems[0]} />
                    }
                    {
                        product?.relatedItems?.[1] && <DatasetCard productData={product?.relatedItems[1]} />
                    }
                    {
                        product?.relatedItems?.[2] && <DatasetCard productData={product?.relatedItems[2]} />
                    }
                </div>

            </div>

            <Footer />

        </div >
    );
}
