import { getDownloadURL, ref } from 'firebase/storage';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SyncLoader from 'react-spinners/SyncLoader';
import { _data } from '../../data';
import { pxFetch } from '../../utils/utils';
import { Footer } from '../footer/Footer';
import { Header } from '../header/Header';
import storage from '../../utils/firebaseConfig';

export function OrderPage({ props }: any) {

    const params = useParams();

    const orderId: any = params?.orderId ?? '';
    const fileName: any = params?.fileName ?? '';
    const [downloadURL, setDownloadURL] = useState("");
    const [invalidFile, setInvalidFile] = useState(false)

    useEffect(() => {
        const getData = async () => {
            try {
                const response = await pxFetch(
                    'GET',
                    `/getDownload/${orderId}/${fileName}`,
                );

                const json = await response.json();

                if (![200, 400].includes(response.status)) {
                    throw new Error("");
                } else if (response.status === 400) {
                    setInvalidFile(true);
                } else {
                    if (process.env.REACT_APP_ENVIRONMENT === 'local') {
                        setDownloadURL(`localhost:9199/storage/data-website-backend.appspot.com/${json?.fileName}`);
                    } else {
                        const storageRef = ref(storage.storage, json?.fileName);
                        setDownloadURL(await getDownloadURL(storageRef))
                    }
                }
            } catch (error: any) {
                console.log('error', error);
                alert('Something went wrong. Please contact us if you have not recieved your order in your email.')
            }
        }
        getData();
    }, [])


    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                // justifyContent: 'center',
                alignItems: 'center',
            }}
        >

            <Header page={'product'} />

            {
                invalidFile ?
                    <div
                        style={{
                            width: '817px',
                            paddingTop: '250px',
                            paddingBottom: '150px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <div
                            style={{
                                fontSize: '30px',
                                fontWeight: 'bold',
                            }}
                        >
                            Invalid order.
                        </div>

                        <div
                            style={{
                                marginTop: '30px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                textAlign: 'center',
                            }}
                        >
                            This file is no longer available for download. Our download links expire after 24 hours. If you need to download the file again, please contact us.
                        </div>

                        <div
                            style={{
                                fontSize: '20px',
                                backgroundColor: '#2a9d8f',
                                fontWeight: 'medium',
                                color: '#FFFFFF',
                                padding: '20px 50px',
                                borderRadius: '10px',
                                cursor: 'pointer',
                                marginTop: '100px',
                            }}
                            onClick={() => {
                                window.location.href = '/products';
                            }}
                        >
                            Browse datasets
                        </div>
                    </div>
                    :
                    <div
                        style={{
                            width: '817px',
                            paddingTop: '250px',
                            paddingBottom: '100px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <div
                            style={{
                                fontSize: '30px',
                                fontWeight: 'bold',
                            }}
                        >
                            Thank you for your order!
                        </div>

                        <div
                            style={{
                                marginTop: '30px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                textAlign: 'center',
                            }}
                        >
                            Below is a button that will download your new dataset. If you have any questions, please contact us. Your download will expire in 24 hours.
                            {/* <div style={{ marginTop: '20px', display: 'flex' }} >
                        <div style={{ fontWeight: 'bold' }}>Email:</div>{" "}{orderDetails.payer?.email_address}
                    </div> */}
                            <div style={{ marginTop: '10px', display: 'flex' }} >
                                <div style={{ fontWeight: 'bold' }}>Order confirmation number:</div>{" "} {orderId}
                            </div>
                        </div>

                        <div
                            style={{
                                ...{
                                    fontSize: '20px',
                                    backgroundColor: '#2a9d8f',
                                    fontWeight: 'medium',
                                    color: '#FFFFFF',
                                    padding: '20px 50px',
                                    borderRadius: '10px',
                                    cursor: 'pointer',
                                    marginTop: '50px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    flexDirection: 'row',

                                },
                                ...(downloadURL === "" ? {
                                    pointerEvents: 'none',
                                    opacity: 0.7
                                }
                                    : {})
                            }}
                            onClick={() => {
                                window.open(downloadURL, '_blank');
                                // console.log('downloadURL', downloadURL);
                            }}

                        >
                            {
                                downloadURL === "" ? 'Loading dataset...' : 'Download dataset'
                            }
                            {
                                downloadURL === "" &&
                                <SyncLoader color={"#FFFFFF"} loading={true} size={10} style={{ marginLeft: '10px' }} />
                            }
                        </div>

                        <div
                            style={{
                                fontSize: '20px',
                                backgroundColor: '#2a9d8f',
                                fontWeight: 'medium',
                                color: '#FFFFFF',
                                padding: '20px 50px',
                                borderRadius: '10px',
                                cursor: 'pointer',
                                marginTop: '100px',
                            }}
                            onClick={() => {
                                window.location.href = '/products';
                            }}
                        >
                            Browse datasets
                        </div>
                    </div>
            }


            <Footer />
        </div>
    );
}
