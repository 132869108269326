import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { _data } from '../../data';
import { Footer } from '../footer/Footer';
import { Header } from '../header/Header';
import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { pxFetch } from '../../utils/utils';
import storage from '../../utils/firebaseConfig';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import SyncLoader from 'react-spinners/SyncLoader';

export function CheckOutPage({ props }: any) {

    const params = useParams();

    const productId: any = params?.productId ?? '';
    const [product, setProduct] = useState<any>(_data?.[productId] ?? {});
    const [orderConfirmed, setOrderConfirmed] = useState(false);
    const [orderDetails, setOrderDetails] = useState<any>({});
    const [downloadURL, setDownloadURL] = useState("");

    console.log('product', product);
    console.log('productId', productId);
    console.log("test", (!productId || !product))

    if (!productId || !product) {
        return (
            <div>error404</div>
        )
    }

    const ButtonWrapper = ({ currency, showSpinner, salePrice }: any) => {
        // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
        // This is the main reason to wrap the PayPalButtons in a new component
        const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

        useEffect(() => {
            dispatch({
                type: "resetOptions",
                value: {
                    ...options,
                    currency: currency,
                },
            });
        }, [currency, showSpinner]);


        return (

            <>
                {(showSpinner && isPending) && <div className="spinner" />}
                <PayPalButtons
                    style={{ layout: "vertical" }}
                    disabled={false}
                    forceReRender={[salePrice, currency, { layout: "vertical" }]}
                    fundingSource={undefined}
                    createOrder={(data, actions) => {
                        return actions.order
                            .create({
                                purchase_units: [
                                    {
                                        amount: {
                                            currency_code: currency,
                                            value: salePrice,
                                        },
                                        reference_id: productId
                                    },

                                ],
                                application_context: {
                                    shipping_preference: "NO_SHIPPING"
                                }

                            })
                            .then((orderId) => {
                                // Your code here after create the order
                                return orderId;
                            });
                    }}
                    onApprove={function (data: any, actions: any) {
                        return actions.order.capture().then(async function (details: any) {
                            // Your code here after capture the order 
                            setOrderConfirmed(true)
                            setOrderDetails(details)
                            try {
                                const response = await pxFetch(
                                    'GET',
                                    `/order/${details?.id}`,
                                );

                                const json = await response.json();

                                if (response.status !== 200) {
                                    throw new Error("");
                                } else {
                                    const storageRef = ref(storage.storage, json?.storageUrl);
                                    setDownloadURL(await getDownloadURL(storageRef))
                                }

                            } catch (error: any) {
                                alert('Something went wrong. Please contact us if you have not recieved your order in your email.')
                            }
                        });
                    }}
                />
            </>
        );
    }

    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                // justifyContent: 'center',
                alignItems: 'center',
            }}
        >

            <Header page={'product'} />

            {/* <div
                style={{
                    paddingTop: '220px',
                }}
                onClick={async () => {
                    const storageRef = ref(storage.storage, "71F080005M816493T/7Eleven.csv");
                    console.log('storage', storageRef?.fullPath);
                    const downloadURL = await getDownloadURL(storageRef)
                    window.open(downloadURL, '_blank');
                }}
            >
                tester
            </div> */}

            {
                !orderConfirmed ? (
                    <div
                        style={{
                            width: '817px',
                            paddingTop: '250px',
                            paddingBottom: '100px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        }}
                    >
                        <div
                            style={{
                                fontSize: '30px',
                                fontWeight: 'bold',
                                marginBottom: '35px',
                                color: '#2a9d8f'
                            }}
                        >
                            You order
                        </div>

                        <div
                            style={{
                                width: '100%',
                                // padding: '20px',
                                display: 'flex',
                                flexDirection: 'row',
                                backgroundColor: '#2a9d8f',
                                borderRadius: '10px',
                                flex: 1,
                                justifyContent: 'space-around'
                            }}
                        >
                            <div
                                style={{ padding: '20px', color: 'white', width: '100%', }}
                            >Product</div>
                            <div
                                style={{
                                    padding: '20px', color: 'white', width: '100%', display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center'
                                }}
                            >Subtotal</div>

                        </div>
                        <div
                            style={{
                                marginTop: '10px',
                                backgroundColor: '#f3f3f3',
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                // padding: '20px',
                                borderRadius: '10px',
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    // padding: '20px'
                                    margin: '20px',
                                    marginTop: '50px',
                                    marginBottom: '50px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <div>
                                    {product?.name} dataset
                                </div>
                                <div>
                                    Locations: {product?.locations}
                                </div>
                            </div>
                            <div
                                style={{
                                    width: '100%',
                                    // padding: '20px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderLeft: '1px solid #707070',
                                    margin: '20px',
                                }}
                            >
                                ${product?.salePrice}
                            </div>
                        </div>

                        <div
                            style={{
                                backgroundColor: '#f3f3f3',
                                border: '1px solid #2a9d8f',
                                borderRadius: '10px',
                                width: '100%',
                                marginTop: '10px'
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    borderRadius: '10px',
                                    flex: 1,
                                    justifyContent: 'space-around',
                                }}
                            >
                                <div
                                    style={{ padding: '10px', paddingLeft: '20px', color: '#2a9d8f', width: '100%', fontSize: 'medium' }}
                                >
                                    Subtotal
                                </div>
                                <div
                                    style={{
                                        padding: '10px', paddingRight: '15px', color: '#2a9d8f', width: '100%', display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                    }}
                                >
                                    ${product?.salePrice}
                                </div>

                            </div>

                            <div
                                style={{
                                    width: '100%',
                                    // padding: '20px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    backgroundColor: '#2a9d8f',
                                    borderRadius: '10px',
                                    flex: 1,
                                    justifyContent: 'space-around',
                                }}
                            >
                                <div
                                    style={{ padding: '20px', color: 'white', width: '100%', }}
                                >
                                    Total
                                </div>
                                <div
                                    style={{
                                        padding: '20px', color: 'white', width: '100%', display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    ${product?.salePrice}
                                </div>

                            </div>
                        </div>

                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginTop: '40px',
                                minHeight: "200px"
                            }}
                        >
                            <PayPalScriptProvider
                                options={{
                                    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_KEY as string,
                                    components: "buttons",
                                    currency: "USD"
                                }}

                            >
                                <div
                                    style={{
                                        width: '100%',
                                        maxWidth: "645px",
                                    }}
                                >
                                    <ButtonWrapper
                                        currency={"USD"}
                                        showSpinner={false}
                                        salePrice={product?.salePrice}
                                    />
                                </div>
                            </PayPalScriptProvider>
                        </div>
                    </div>
                )
                    :
                    <div
                        style={{
                            width: '817px',
                            paddingTop: '250px',
                            paddingBottom: '100px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <div
                            style={{
                                fontSize: '30px',
                                fontWeight: 'bold',
                            }}
                        >
                            Thank you {orderDetails.payer.name.given_name}! Your order has been placed.
                        </div>

                        <div
                            style={{
                                marginTop: '30px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}
                        >
                            Below is a button that will download your new dataset. If you have any questions, please contact us.

                            <div style={{ marginTop: '20px', display: 'flex' }} >
                                <div style={{ fontWeight: 'bold' }}>Email:</div>{" "}{orderDetails.payer?.email_address}
                            </div>
                            <div style={{ marginTop: '5px', display: 'flex' }} >
                                <div style={{ fontWeight: 'bold' }}>Order confirmation number:</div>{" "} {orderDetails.id}
                            </div>
                        </div>

                        <div
                            style={{
                                ...{
                                    fontSize: '20px',
                                    backgroundColor: '#2a9d8f',
                                    fontWeight: 'medium',
                                    color: '#FFFFFF',
                                    padding: '20px 50px',
                                    borderRadius: '10px',
                                    cursor: 'pointer',
                                    marginTop: '50px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    flexDirection: 'row',

                                },
                                ...(downloadURL === "" ? {
                                    pointerEvents: 'none',
                                    opacity: 0.7
                                }
                                    : {})
                            }}
                            onClick={() => {
                                window.open(downloadURL, '_blank');
                                // console.log('downloadURL', downloadURL);
                            }}

                        >
                            {
                                downloadURL === "" ? 'Loading dataset...' : 'Download dataset'
                            }
                            {
                                downloadURL === "" &&
                                <SyncLoader color={"#FFFFFF"} loading={true} size={10} style={{ marginLeft: '10px' }} />
                            }
                        </div>

                        <div
                            style={{
                                fontSize: '20px',
                                backgroundColor: '#2a9d8f',
                                fontWeight: 'medium',
                                color: '#FFFFFF',
                                padding: '20px 50px',
                                borderRadius: '10px',
                                cursor: 'pointer',
                                marginTop: '100px',
                            }}
                            onClick={() => {
                                window.location.href = '/products';
                            }}
                        >
                            Browse datasets
                        </div>
                    </div>
            }


            <Footer />
        </div>
    );
}
