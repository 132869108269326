import { getDownloadURL, ref } from "firebase/storage";
import { useEffect, useState } from "react";
import storage from '../../utils/firebaseConfig';

type DatasetProps = {
    productData: any
};

export const DatasetCard = ({ productData }: DatasetProps) => {

    const storageRef = ref(storage.storage, `logos/${productData?.id}/logo.png`);
    const [logoUrl, setLogoUrl] = useState('');

    const [hover, setHover] = useState(false)

    useEffect(() => {
        const getLogoUrl = async () => {
            const url = await getDownloadURL(storageRef);
            setLogoUrl(url);
        }
        getLogoUrl();

    }, [])


    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginBottom: '50px',
                borderRadius: '10px',
                background: hover ? '#f5f5f5' : 'white',
                transition: 'background-color 0.3s ease'
            }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <div
                style={{
                    padding: '10px',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    borderRadius: '10px',
                    margin: '15px',
                    width: '300px',
                    height: '175px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <img
                    src={logoUrl}
                    style={{
                        width: '300px',
                        height: '175px',
                        objectFit: 'contain',
                    }}
                    alt="dataset logo"
                />
            </div>
            <div
                style={{
                    height: '50px',
                    width: '325px',
                }}
            >
                <div style={{
                    fontSize: '30px',
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}>{productData.name}</div>
            </div>
            {/* <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ color: '#BABABA' }} >Locations: </div> <div style={{ color: '#343434', marginLeft: '5px' }}>{productData.locations}</div>
            </div> */}
            <div style={{
                marginTop: '15px',
                fontSize: '30px',
            }}>{`$${productData.salePrice}`}</div>

            <div
                style={{
                    width: '100px',
                    padding: '10px',
                    backgroundColor: '#2a9d8f',
                    color: 'white',
                    textAlign: 'center',
                    marginTop: '15px',
                    borderRadius: '10px',
                    cursor: 'pointer',
                    marginBottom: '15px',
                }}
                onClick={() => {
                    window.location.href = `/product/${productData.id}`;
                }}
            >
                View
            </div>
        </div >
    )
}