import { useState } from 'react';
import SyncLoader from 'react-spinners/SyncLoader';
import { pxFetch } from '../../utils/utils';
import { Footer } from '../footer/Footer';
import { Header } from '../header/Header';
import contactPhoto from './contactPhoto.jpg';

export function ContactPage({ props }: any) {

    const [contactName, setContactName] = useState("")
    const [contactEmail, setContactEmail] = useState("")
    const [contactMessage, setContactMessage] = useState("")

    const [loading, setLoading] = useState(false)

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >

            <Header page={'product'} />

            <img
                src={contactPhoto}
                alt="contact us image"
                style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    objectPosition: 'center',
                }}
            />

            <div
                style={{
                    position: 'absolute',
                    top: '344px',
                    left: '0px',
                    right: '0px',
                    width: '100%',
                    // border: '1px solid red',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '70px',
                    fontWeight: 'bold',
                    color: '#2a9d8f',
                    flexDirection: 'column',
                }}
            >
                Contact the Data Store Team

                <div
                    style={{
                        fontSize: '25px',
                        color: '#343434',
                    }}
                >
                    We are happy to help you with any questions about the Data Store
                </div>
            </div>

            <div
                style={{
                    width: '718px',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        // width: '100%',
                        flexDirection: 'row',

                    }}
                >
                    <input
                        type="text"
                        placeholder="Name *"
                        style={{
                            width: '100%',
                            padding: '12px',
                            border: '2px solid #2a9d8f',
                            borderRadius: '10px',
                            outline: 'none',
                        }}
                        onChange={(e) => setContactName(e.target.value)}
                        value={contactName}
                    />
                    <input
                        type="text"
                        placeholder="Email *"
                        style={{
                            width: '100%',
                            padding: '12px',
                            border: '2px solid #2a9d8f',
                            borderRadius: '10px',
                            outline: 'none',
                            marginLeft: '30px',
                        }}
                        onChange={(e) => setContactEmail(e.target.value)}
                        value={contactEmail}
                    />
                </div>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flex: 1
                    }}
                >
                    <textarea
                        // type="text"
                        placeholder="How can we help you? *"
                        style={{
                            width: '100%',
                            height: '250px',
                            padding: '12px',
                            border: '2px solid #2a9d8f',
                            borderRadius: '10px',
                            outline: 'none',
                            marginTop: '20px',
                            verticalAlign: 'top',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            alignContent: 'flex-start',
                            fontFamily: 'inherit',
                        }}
                        onChange={(e) => setContactMessage(e.target.value)}
                        value={contactMessage}
                    />
                </div>
            </div>

            <div
                style={{
                    backgroundColor: '#2a9d8f',
                    color: 'white',
                    padding: '12px',
                    width: '100px',
                    textAlign: 'center',
                    borderRadius: '10px',
                    marginTop: '25px',
                    marginBottom: '180px',
                    cursor: 'pointer',
                }}
                onClick={async () => {
                    // If any of the fields are missing alert and return
                    if (contactName === "" || contactEmail === "" || contactMessage === "") {
                        alert("Please fill out all fields")
                        return
                    }

                    setLoading(true)

                    const response = await pxFetch(
                        'POST',
                        `/contact`,
                        {
                            contactName: contactName,
                            contactEmail: contactEmail,
                            contactMessage: contactMessage,
                        }
                    );

                    // const json = await response.json();

                    if (response.status !== 200) {
                        alert('Something went wrong. Please contact us at insomniacodellc@gmail.com.')
                        console.log('Error sending contact email', response);
                        setLoading(false)
                    } else {
                        setContactName("")
                        setContactEmail("")
                        setContactMessage("")
                        setLoading(false)
                        alert('Thank you for contacting us! We will get back to you shortly.')
                    }
                }}
            >
                {loading ?
                    <SyncLoader color={"#FFFFFF"} loading={true} size={5} style={{}} />
                    :
                    'Send'}
            </div>

            <Footer />

        </div>
    );
}
