import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

// const firebaseConfigLocal = {
//     apiKey: "AIzaSyAu7hhk4T9PPnzCUXMPA4OPNaXPt6LJ2Wo",
//     authDomain: "data-website-backend.firebaseapp.com",
//     projectId: "data-website-backend",
//     storageBucket: "data-website-backend.appspot.com",
//     messagingSenderId: "1076290618986",
//     appId: "1:1076290618986:web:e9240b3995f886d9c1ac13",
//     measurementId: "G-DVWB2R28W4"
// };

const firebaseConfigProd = {
    apiKey: "AIzaSyAu7hhk4T9PPnzCUXMPA4OPNaXPt6LJ2Wo",
    authDomain: "data-website-backend.firebaseapp.com",
    projectId: "data-website-backend",
    storageBucket: "data-website-backend.appspot.com",
    messagingSenderId: "1076290618986",
    appId: "1:1076290618986:web:e9240b3995f886d9c1ac13",
    measurementId: "G-DVWB2R28W4"
};


// Initialize Firebase
const app = initializeApp(firebaseConfigProd);
const storage = getStorage(app);
// const analytics = getAnalytics(app);

export default { storage };