import React from 'react';
import './App.css';
import { BrowserRouter, Router, Routes, Route } from 'react-router-dom';
import { HomePage } from './components/homePage/HomePage';
import { ProductPage } from './components/productPage/ProductPage';
import { ContactPage } from './components/contactPage/ContactPage';
import { ProductsPage } from './components/productsPage/ProductsPage';
import { CheckOutPage } from './components/checkoutPage/CheckOutPage';
import { OrderPage } from './components/orderPage/OrderPage';

function App() {
  return (

    <BrowserRouter >
      {/* <Router> */}

      <Routes>
        <Route path="*" element={<div>error404</div>} />
        <Route path="/error404" element={<div>error404</div>} />

        <Route
          path="/"
          element={
            <HomePage />
          }
        />

        <Route
          path="/product/:productId"
          element={
            <ProductPage />
          }
        />

        <Route
          path="/contact"
          element={
            <ContactPage />
          }
        />

        <Route
          path="/products"
          element={
            <ProductsPage />
          }
        />

        <Route
          path="/checkout/:productId"
          element={
            <CheckOutPage />
          }
        />

        <Route
          path="/order/:orderId/:fileName"
          element={
            <OrderPage />
          }
        />

      </Routes>
      {/* </Router> */}
    </BrowserRouter>

  );
}

export default App;
