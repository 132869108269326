import logo from './insomnia-data--logo-transparent-background.png';
import logoWhite from './insomnia-data-low-resolution-logo-white-on-transparent-background.png';


export function Header({ page }: any) {

    return (
        <>
            <div
                style={{
                    height: '100px',
                    // width: '100%',
                    backgroundColor: page === 'product' ? '#2a9d8f' : 'white',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 1,
                    right: 0,

                    fillRule: 'evenodd',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingRight: '143px',
                    paddingLeft: '143px',
                }
                }
            >
                <img
                    style={{
                        cursor: 'pointer',
                        height: '50px',
                    }}
                    onClick={() => {
                        window.location.href = '/';
                    }}
                    alt="logo"
                    src={page === 'product' ? logoWhite : logo}
                />
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '300px',
                    }}
                >
                    <div
                        style={{
                            width: '150px',
                            height: '60px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginRight: '10px',
                            color: page === 'product' ? 'white' : '#707070',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            window.location.href = '/contact';
                        }}
                    >
                        Contact Us
                    </div>
                    <div
                        style={{
                            width: '150px',
                            height: '60px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: page === 'product' ? '2px solid white' : '2px solid #2a9d8f',
                            borderRadius: '10px',
                            color: page === 'product' ? 'white' : '#2a9d8f',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            window.location.href = '/products';
                        }}
                    >
                        Data Store
                    </div>
                </div>

            </div>
        </>
    );
}
