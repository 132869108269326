import { Footer } from "../footer/Footer";
import { Header } from "../header/Header";
import { DatasetCard } from "./DatasetCard";
import { useEffect, useState } from "react";
import { pxFetch } from "../../utils/utils";
import SyncLoader from "react-spinners/SyncLoader";
import { _data } from "../../data";

export function ProductsPage({ page }: any) {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [products, setProducts] = useState(Object.values(_data));

    // useEffect(() => {
    //     setLoading(true);
    //     const getData = async () => {
    //         const response = await pxFetch(
    //             'GET',
    //             `/products`,
    //         );

    //         const json = await response.json();

    //         if (response.status === 200) {
    //             setLoading(false);
    //             setProducts(json?.products);
    //         } else {
    //             setLoading(false);
    //             setError(json?.message);
    //         }
    //     }
    //     getData();
    // }, []);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                // border: '5px solid red',
                overflowX: 'hidden',
            }}
        >

            <Header page={'product'} />

            <h1
                style={{
                    paddingTop: '150px',
                    fontSize: '70px',
                    maxWidth: '1200px',
                    width: '100%',
                    fontWeight: '600',
                }}
            >
                Our datasets...
            </h1>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    maxWidth: '1200px',
                    width: '100%',
                    flexWrap: 'wrap',
                    marginTop: '50px',
                    marginBottom: '100px',
                    padding: '20px',
                }}
            >
                {
                    (products?.length > 0) ?
                        products.map((product: any, index: number) => {

                            return <div key={`productKey=${index}`}><DatasetCard productData={product} /></div>
                        })
                        :
                        <SyncLoader color="#36d7b7" />
                }
            </div>

            <Footer />

        </div>
    );
}
