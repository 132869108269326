export const pxFetch = async (
    method: string,
    route: string,
    data?: any,
) => {
    try {
        const payloadBody = data
            ? JSON.stringify(data)
            : undefined;
        const reactAppUrl = process.env.REACT_APP_FUNCTIONS_URL;
        const response = await fetch(reactAppUrl + route, {
            method: method,
            headers: {
                "Content-Type": "application/json",
                // Authorization: `Bearer ${bearerToken}`,
            },
            body: payloadBody,
        });
        return response;
    } catch (e: any) {
        console.error(e);
        return e;
    }
};

export const getFriendlyName = (rowName: string) => {
    return rowName
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
}

export { }
